import { useAccountWithBillsAndPaymentsOverview } from '@melio/platform-api';
import { Account } from '@melio/platform-api';

import { AccountCard } from '@/cl/components/AccountCard/AccountCard.component';
import { useSwitchAccount } from '@/hooks/useSwitchAccount.hooks';

type AccountCardWidgetProps = {
  account: Account<never, 'logoUrl'>;
  isSelected: boolean;
};

export const AccountCardWidget = ({ account, isSelected }: AccountCardWidgetProps) => {
  const { data: accountWithPaymentsOverview, isFetching: isPaymentsOverviewLoading } =
    useAccountWithBillsAndPaymentsOverview({ id: account.id });

  const { switchAccount } = useSwitchAccount();

  const onClickHandler = async () => {
    await switchAccount(account.id);
  };

  return (
    <AccountCard
      companyName={account.company.name || ''}
      companyId={account.id}
      companyLogo={account.logoUrl}
      isSelected={isSelected}
      onClick={onClickHandler}
      isPaymentsOverviewLoading={isPaymentsOverviewLoading}
      paymentsOverview={accountWithPaymentsOverview?.billsAndPaymentsOverview}
    />
  );
};
