import { ReactElement } from 'react';
import { NakedButton } from '@melio/penny';
import { AccountingPlatformSlug } from '@melio/platform-api';

import { AccountingPlatformCard as AccountingPlatformCardCl } from '@/cl/components/AccountingPlatformCard/AccountingPlatformCard.component';
import { usePlatformIntl } from '@/translations/Intl';
import { useZendesk } from '@/utils/zendesk';

type Props = {
  logo: ReactElement | null;
  userName: string;
  syncDate?: string;
  errorText?: string;
  isLoading: boolean;
  accountingCompanyName?: string | '';
  accountingPlatformName: string;
  accountingPlatformSlug?: AccountingPlatformSlug;
  onReconnectedClicked: () => void;
};

export const AccountingPlatformUnlinkedCard = ({
  logo,
  userName,
  syncDate,
  errorText,
  isLoading,
  accountingCompanyName,
  accountingPlatformName,
  accountingPlatformSlug,
  onReconnectedClicked,
}: Props) => {
  const { formatMessage } = usePlatformIntl();
  const { showZendesk } = useZendesk();
  const buttons = [
    {
      text: formatMessage('widgets.accountingPlatform.card.buttons.reconnect'),
      onClicked: onReconnectedClicked,
      isLoading,
      dataTestId: 'accounting-platform-connect-card-reconnect-button',
    },
  ];

  const description = [
    formatMessage('widgets.accountingPlatform.card.type.unlinked.description', {
      userName,
      accountingCompanyName,
      accountingPlatformName,
    }),
    formatMessage('widgets.accountingPlatform.card.type.unlinked.description2', {
      chat: (
        <NakedButton
          variant="secondary"
          onClick={showZendesk}
          size="large"
          label={formatMessage('widgets.accountingPlatform.card.links.chat')}
        />
      ),
    }),
  ];

  return (
    <AccountingPlatformCardCl
      title={accountingPlatformName}
      descriptionList={description}
      buttons={buttons}
      logo={logo}
      errorText={errorText}
      lastSync={syncDate ? formatMessage('widgets.accountingPlatform.date.sync', { syncDate: syncDate }) : undefined}
      accountingPlatformSlug={accountingPlatformSlug}
    />
  );
};
