/* eslint-disable react-hooks/exhaustive-deps */
import { isEbill } from '@melio/ap-domain';
import { Container, Divider, Group, useBreakpoint, useDisclosure } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useBillAttachments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { Big } from 'big.js';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';

import { FileAttachmentWidget } from '../FileAttachment';
import { getBillPaidAmount } from '../utils/bill-utils';
import { BillAmountLine } from './BillAmountLine/BillAmountLine';
import { BillDetailsForm } from './BillDetailsForm';
import { BillDetailsHeader } from './BillDetailsHeader';
import { BillWillBeFullyPaidModal } from './BillWillBeFullyPaidModal.widget';
import { EBillAttachment } from './EBillAttachment/EBillAttachment';
import { BillDetailsFormFields, BillDetailsWidgetProps } from './types';

/**
 * @deprecated Use packages/ap/widgets/src/components/BillDetailsAccessibleNew/BillDetails.widget.tsx instead
 */
export const BillDetailsWidget = forwardRef<BillDetailsWidgetProps>(
  (
    {
      isActionable = false,
      showOpenBalance = false,
      defaultIsOpen = false,
      activeAccountingPlatform,
      isEditMode,
      onMarkAsPaid,
      onDelete,
      bill,
      isSaving,
      onSubmit,
      onSubmissionStateChange,
      onInvoiceChange,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const { isExtraSmallScreen } = useBreakpoint();
    const { formatMessage } = useMelioIntl();
    const [isViewMode, setIsViewMode] = useState(true);
    const [newBillData, setNewBillData] = useState<BillDetailsFormFields>();
    const [showActions, setshowActions] = useState(isActionable);
    const [isFileAttached, setIsFileAttached] = useState<boolean>(Boolean(bill?.invoice.fileId));
    const isQboSyncedBill = !!bill.originId && bill.originId !== '-1';
    const { data: attachments, isLoading: isLoadingBillAttachments } = useBillAttachments({
      id: bill.id,
      enabled: (!bill.invoice.fileId && isQboSyncedBill) || isEbill(bill),
    });

    const enableEditBill = () => {
      setIsViewMode(false);
      setshowActions(false);
    };

    useEffect(() => {
      setIsViewMode(!isEditMode);
    }, [isEditMode]);

    useEffect(() => {
      isActionable && setIsViewMode(!isSaving);
      isActionable && setshowActions(!isSaving);
    }, [isSaving]);

    const {
      isOpen: shouldOpenBillFullyPaidModal,
      onOpen: onOpenBillFullyPaidModal,
      onClose: onCloseBillFullyPaidModal,
    } = useDisclosure();

    const handleChange = (fileBlob?: File | null) => {
      if (!fileBlob) {
        track('Bill', 'Click', {
          Intent: 'delete-file',
          Cta: 'delete-file',
        });
      }
      setIsFileAttached(Boolean(fileBlob));
      onInvoiceChange(fileBlob);
    };

    const billHasPayments = !isNil(bill?.payments) && bill?.payments.length > 0;
    const hasRemainingAmount = bill?.balance > 0;

    const handleSubmit = (data: BillDetailsFormFields) => {
      const paidAmount = bill ? getBillPaidAmount(bill) : 0;
      const newBalance = bill ? new Big(data.amount).minus(paidAmount).toNumber() : data.amount;
      if (newBalance === 0) {
        setNewBillData(data);
        onOpenBillFullyPaidModal();
      } else {
        onSubmit(data);
      }
    };

    const onConfirmNewAmount = () => {
      if (newBillData) {
        onSubmit(newBillData);
        onCloseBillFullyPaidModal();
      }
    };
    const onCancelBillNewAmount = () => {
      setNewBillData(undefined);
      onCloseBillFullyPaidModal();
    };
    return (
      <>
        <Container ref={ref} border="regular" data-component="BillDetailsWidget" {...props}>
          <Group hasDivider variant="vertical" spacing="none">
            <Container paddingX={isExtraSmallScreen ? 's' : 'xl'} paddingY="l">
              <Group variant="vertical" spacing="m">
                <BillDetailsHeader
                  shouldShowActions={showActions}
                  onMarkAsPaid={onMarkAsPaid}
                  enableEditBill={enableEditBill}
                  onDelete={onDelete}
                  defaultIsOpen={defaultIsOpen}
                />
                <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} spacing="l" alignItems="flex-start">
                  <BillDetailsForm
                    billInfo={bill}
                    isViewMode={isViewMode}
                    onSubmit={(data) => handleSubmit(data)}
                    onSubmissionStateChange={onSubmissionStateChange}
                    isFileAttached={Boolean(isFileAttached)}
                    isSaving={isSaving}
                    activeAccountingPlatform={activeAccountingPlatform}
                  />
                  {isEbill(bill) ? (
                    <EBillAttachment isLoading={isLoadingBillAttachments} url={attachments?.[0]?.url ?? ''} />
                  ) : (
                    <FileAttachmentWidget
                      fileId={bill.invoice.fileId}
                      aria-label={
                        isFileAttached ? formatMessage('widgets.fileAttachment.downloadAriaLabel') : undefined
                      }
                      externalFileUrl={attachments?.[0]?.url}
                      isLoading={isLoadingBillAttachments}
                      isViewMode={isViewMode}
                      onChange={handleChange}
                      onViewModeClick={(fileUrl) => {
                        if (fileUrl) {
                          track('Bill', 'Click', { Intent: 'download-file', Cta: 'download-file' });
                          window.open(fileUrl);
                        }
                      }}
                    />
                  )}
                </Group>
              </Group>
            </Container>
            {showOpenBalance && bill?.amount && (
              <Container backgroundColor="light" paddingX={isExtraSmallScreen ? 's' : 'xl'} paddingY="l">
                {billHasPayments ? (
                  <Group variant="vertical">
                    {hasRemainingAmount && (
                      <>
                        <BillAmountLine
                          amount={bill.balance}
                          size="large"
                          type="balance"
                          data-testid="remaining-bill-amount"
                          currency={bill.currency}
                        />
                        <Divider variant="horizontal" />
                      </>
                    )}
                    <BillAmountLine
                      amount={bill.amount}
                      size="small"
                      type="total-amount"
                      data-testid="total-bill-amount-partial"
                      currency={bill.currency}
                    />
                  </Group>
                ) : (
                  <BillAmountLine
                    amount={bill.amount}
                    size="large"
                    type="total-amount"
                    data-testid="total-bill-amount"
                    currency={bill.currency}
                  />
                )}
              </Container>
            )}
          </Group>
        </Container>
        {newBillData && (
          <BillWillBeFullyPaidModal
            newAmount={+newBillData.amount}
            isOpen={shouldOpenBillFullyPaidModal}
            onClose={onCancelBillNewAmount}
            isSubmitting={isSaving}
            onSubmit={onConfirmNewAmount}
          />
        )}
      </>
    );
  }
);

BillDetailsWidget.displayName = 'BillDetailsWidget';
