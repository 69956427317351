import { Button, Container, Form, Group } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { StepFormProps, SubscriptionBillingPayor } from '../../types';

export const BillingDetailsStep: React.FC<StepFormProps> = ({ submitLabel, registerField, nextStep }) => {
  const { formatMessage } = useMelioIntl();

  const verifyBillingDetails = () => {
    nextStep?.();
  };

  return (
    <Group spacing="m" variant="vertical" width="full">
      <Form.RadioGroup
        options={[
          {
            mainLabelProps: {
              label: formatMessage('activities.accountants.newClient.form.billingDetails.fields.clientPays.label'),
            },
            descriptionProps: {
              label: formatMessage(
                'activities.accountants.newClient.form.billingDetails.fields.clientPays.description.text'
              ),
            },
            value: SubscriptionBillingPayor.Client,
          },
          {
            mainLabelProps: {
              label: formatMessage('activities.accountants.newClient.form.billingDetails.fields.firmPays.label'),
            },
            descriptionProps: {
              label: formatMessage(
                'activities.accountants.newClient.form.billingDetails.fields.firmPays.description.text'
              ),
            },
            value: SubscriptionBillingPayor.Firm,
          },
        ]}
        variant="vertical"
        {...registerField('whoPays')}
      />
      <Container justifyContent="flex-end">
        <Button variant="primary" size="medium" label={submitLabel} onClick={verifyBillingDetails} />
      </Container>
    </Group>
  );
};
