import { useVendorNotPermitted } from '@melio/ap-domain';
import { LockedVendorIcon } from '@melio/ap-widgets';
import { Badge, Container, Group, Icon, Text, Typography } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { isPaymentCompletedRefund } from '../../../../../utils/pay-flow/Payment.utils';
import { BillListItemOriginSection } from '../../BillsTab/components/BillsTabListItem';
import { usePaymentAmountDetails } from './PaymentsTabAmountCell';
import { PaymentMultipleBillsOrigin } from './PaymentsTabBillsOriginCell';
import { usePaymentDeliveryMethod } from './PaymentsTabDeliveryMethodCell';
import { usePaymentStatus } from './PaymentsTabStatusCell';

export type PaymentsTabListItemProps = { payment: Payment };
export const PaymentsTabListItem = ({ payment }: PaymentsTabListItemProps) => {
  const { formatCurrency, formatDate, formatMessage } = useMelioIntl();
  const { deliveryMethod } = usePaymentDeliveryMethod(payment);
  const getPaymentStatus = usePaymentStatus();
  const { label: statusLabel, status } = getPaymentStatus(payment);
  const [isFinancingEnabled] = useFeature<boolean>(FeatureFlags.PlatformFinancing, false);
  const isVendorNotPermitted = useVendorNotPermitted(payment.vendor);

  const formattedAmount = formatCurrency(payment.amount, payment.currency);
  const amountDescription = usePaymentAmountDetails(payment);
  const isCompletedRefundPayment = isPaymentCompletedRefund(payment);
  const deliveryDate = isCompletedRefundPayment
    ? formatMessage('activities.payDashboard.paymentsTab.table.cells.notAvailable')
    : formatMessage('activities.payDashboard.paymentsTab.listItem.deliveryDate', {
        deliveryDate: formatDate(payment.estimatedDelivery, { dateStyle: 'medium' }),
      });

  return (
    <Container data-testid="payments-tab-list-item" overflow="initial" paddingX="m">
      <Group variant="vertical" spacing="xxs">
        <Group justifyContent="space-between">
          <Group variant="horizontal" spacing="xxs" alignItems="center">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}
            <Typography.MainLabel label={payment.vendor?.name ?? ''} variant="bold" />
          </Group>
          <Group variant="horizontal" alignItems="center" spacing="xxxs">
            <Text textStyle="body2Semi">{formattedAmount}</Text>
            <Icon type="chevron-right" size="small" />
          </Group>
        </Group>
        <Group justifyContent="space-between">
          {payment.estimatedDelivery && (
            <Text color="neutral.darkest" textStyle="body3">
              {deliveryDate}
            </Text>
          )}
          {amountDescription}
        </Group>
        <Group variant="vertical" spacing="xxs">
          <Text color="neutral.darkest" textStyle="body3">
            {formatMessage('activities.payDashboard.paymentsTab.listItem.debitDate', {
              debitDate: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
            })}
          </Text>
          <PaymentBillsOriginRow payment={payment} />
          {isFinancingEnabled && payment?.loan && (
            <Container width="full">
              <Text textStyle="body3" color="neutral.darkest">
                {formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.installments', {
                  installmentNumber: payment.loan.nextInstallmentNumber ?? 1,
                  totalInstallments: payment.loan.installments.length,
                })}
              </Text>
            </Container>
          )}
          {deliveryMethod && (
            <Group
              justifyContent="flex-start"
              alignItems="center"
              spacing="xs"
              data-testid="payments-tab-list-item-delivery-method"
            >
              <Text color="neutral.darker">
                <Group alignItems="center" justifyContent="center">
                  <Icon type="bank-out" size="small" color="inherit" />
                </Group>
              </Text>
              <Text color="neutral.darkest" textStyle="body3" shouldSupportEllipsis>
                {formatMessage('activities.payDashboard.paymentsTab.table.cells.deliveryMethod.title')}:
              </Text>
              <Text color="neutral.darkest" textStyle="body3" shouldSupportEllipsis>
                {deliveryMethod.type}
              </Text>
              {deliveryMethod.details ? (
                <Group alignItems="center" spacing="none" data-testid="payments-tab-list-item-delivery-method-details">
                  <Text color="neutral.darkest" textStyle="body3" shouldSupportEllipsis>
                    ({deliveryMethod.details}
                  </Text>
                  <Text color="neutral.darkest" textStyle="body3">
                    )
                  </Text>
                </Group>
              ) : null}
            </Group>
          )}
          <Container paddingTop="xxs">
            <Badge type="secondary" label={statusLabel} status={status} />
          </Container>
        </Group>
      </Group>
    </Container>
  );
};

const PaymentBillsOriginRow = ({ payment }: { payment: Payment }) => {
  const { bills } = payment;
  if (!bills || bills?.length === 0) {
    return null;
  }

  if (bills.length > 1) {
    return <PaymentMultipleBillsOrigin bills={bills} showIcon />;
  }

  const [bill] = bills;
  if (!bill) {
    return null;
  }

  return <BillListItemOriginSection billsTabItem={{ type: 'bill', payload: bill }} />;
};
