import { Modal, ModalProps, useFormSubmissionController } from '@melio/penny';
import { FxCurrency } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { InternationalPaymentPurposeFormFields, PaymentPurposeForm } from './payment-purpose-form';

export type PaymentPurposeModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  isLoading: boolean;
  isSaving: boolean;
  onSubmit: (data: InternationalPaymentPurposeFormFields) => void;
  shouldShowInvoice: boolean;
  defaultValues?: Partial<InternationalPaymentPurposeFormFields>;
  currency?: FxCurrency;
  shouldShowDescription: boolean;
  shouldShowPaymentPurpose: boolean;
};

export const InternationalPaymentPurposeModalScreen: React.VFC<PaymentPurposeModalProps> = ({
  onSubmit,
  shouldShowInvoice,
  shouldShowDescription = true,
  shouldShowPaymentPurpose = true,
  defaultValues,
  isSaving,
  currency,
  ...rest
}: PaymentPurposeModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { submitButtonProps, onSubmissionStateChange } =
    useFormSubmissionController<InternationalPaymentPurposeFormFields>();

  return (
    <Modal
      {...rest}
      data-testid="international-payment-purpose-activity-international-payment-purpose-modal-screen"
      header={formatMessage('activities.internationalPaymentPurposeModal.header')}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage('activities.internationalPaymentPurposeModal.save'),
      }}
    >
      <PaymentPurposeForm
        defaultValues={defaultValues}
        onSubmissionStateChange={onSubmissionStateChange}
        onSubmit={onSubmit}
        shouldShowInvoice={shouldShowInvoice}
        shouldShowDescription={shouldShowDescription}
        shouldShowPaymentPurpose={shouldShowPaymentPurpose}
        isSaving={isSaving}
        currency={currency}
      />
    </Modal>
  );
};

InternationalPaymentPurposeModalScreen.displayName =
  'InternationalPaymentPurposeActivity.InternationalPaymentPurposeModalScreen';
