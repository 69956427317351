import {
  AccountingPlatformConnectActivity,
  AccountingPlatformSyncConnectionErrorEnum,
  SyncFlowEnum,
} from '@melio/ap-activities';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useRouter } from '@/hooks/router.hooks';
import { getPartnerNameFromUrl } from '@/utils/partner.utils';

export const AccountingPlatformAuthCallbackScreen = () => {
  const { toast } = useToast();
  const { track } = useAnalytics();
  const { goToAccountingPlatform, goToPayDashboard } = useRouter();
  const { formatMessage } = useMelioIntl();
  const analyticsProps = { PageName: 'accounting-software-sync', Status: 'failure' };
  const partnerName = getPartnerNameFromUrl();

  const onDone = (flow: SyncFlowEnum, accountingPlatformName: string) => {
    toast({
      type: 'success',
      title: formatMessage('activities.accountingPlatformConnectCallback.success.toast', { accountingPlatformName }),
    });

    if (flow === SyncFlowEnum.Setting) {
      track('SyncAccountingSoftware', 'Status', analyticsProps);
      goToAccountingPlatform({});
    } else {
      track('Dashboard', 'Status', analyticsProps);
      goToPayDashboard({ keepToast: true });
    }
  };
  const onError = (error: AccountingPlatformSyncConnectionErrorEnum, accountingPlatformId?: string) => {
    track('SyncAccountingSoftware', 'Status', {
      PageName: 'accounting-software-sync',
      Status: 'failure',
    });

    if (!accountingPlatformId) {
      toast({
        type: 'error',
        title: formatMessage('activities.accountingPlatformConnectCallback.authError.toast'),
      });
    }

    goToAccountingPlatform({ syncError: error, accountingPlatformId }, { keepToast: true });
  };

  const onClose = (flow: SyncFlowEnum) => {
    if (flow === SyncFlowEnum.Setting) {
      goToAccountingPlatform({});
    } else {
      goToPayDashboard();
    }
  };

  return (
    <AccountingPlatformConnectActivity partnerName={partnerName} onDone={onDone} onError={onError} onClose={onClose} />
  );
};

AccountingPlatformAuthCallbackScreen.displayName = 'AccountingPlatformAuthCallbackScreen';
