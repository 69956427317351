import { Link, Text, TextProps } from '@melio/penny';

export type TextStyleType = TextProps['textStyle'];
export const BrandColoredLink: React.FC<{
  href: string;
  label: string;
  textStyle?: TextStyleType;
  'data-testid'?: string;
}> = ({ href, label, textStyle, 'data-testid': dataTestId }) => (
  <Text color="brand.main" textStyle={textStyle} data-testid={dataTestId}>
    <Link href={href} label={label} variant="inline" color="inherit" newTab />
  </Text>
);
