import { Modal, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { ApprovalWorkflow, useApprovalWorkflows } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useEffect } from 'react';

import { MonitoredAction } from '../../../../monitoring';

type Props = {
  workflowId: ApprovalWorkflow['id'];
  isOpen: boolean;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  onFailure?: VoidFunction;
};

export const DeleteWorkflowModal = withAnalyticsContext<Props>(
  ({ isOpen, onClose, workflowId, onSuccess, onFailure, setAnalyticsProperties }) => {
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const { formatMessage } = useMelioIntl();
    const { delete: deleteWorkflow, isMutating } = useApprovalWorkflows({ enabled: false });
    const { toast } = useToast();
    const { track } = useAnalytics();

    setAnalyticsProperties({
      PageName: 'delete-this-approval-workflow',
      Flow: 'approval-workflow',
      Intent: 'delete-approval-workflow',
      WorkflowId: workflowId,
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const onCancelClick = () => {
      track('User', 'Click', { Cta: 'cancel' });
      onClose();
    };

    const onCloseClick = () => {
      track('User', 'Click', { Cta: 'close' });
      onClose();
    };

    const handleDeleteClick = async () => {
      startAction('approval_workflow_delete');
      track('User', 'Click', { Cta: 'delete' });
      try {
        await deleteWorkflow(workflowId);
        toast({
          type: 'informative',
          title: formatMessage('activities.settings.approval-workflow-settings.deleteModal.toast.success'),
        });
        track('Settings', 'Status', {
          PageName: 'workflow-deleted',
          Flow: 'approval-workflow',
          Intent: 'approval-workflow',
          Status: 'success',
        });
        onSuccess?.();
        endAction('approval_workflow_delete');
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('activities.settings.approval-workflow-settings.deleteModal.toast.error'),
        });
        track('Settings', 'Status', {
          PageName: 'workflow-deleted',
          Flow: 'approval-workflow',
          Intent: 'approval-workflow',
          Status: 'failure',
        });
        onFailure?.();
      } finally {
        onClose();
      }
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={onCloseClick}
        data-testid="approval-workflow-delete-modal"
        header={formatMessage('activities.settings.approval-workflow-settings.deleteModal.header')}
        description={formatMessage('activities.settings.approval-workflow-settings.deleteModal.description')}
        primaryButton={{
          label: formatMessage('activities.settings.approval-workflow-settings.deleteModal.primaryButton'),
          variant: 'critical',
          onClick: handleDeleteClick,
          isLoading: isMutating,
        }}
        secondaryButton={{
          label: formatMessage('activities.settings.approval-workflow-settings.deleteModal.secondaryButton'),
          variant: 'tertiary',
          onClick: onCancelClick,
        }}
      ></Modal>
    );
  }
);
