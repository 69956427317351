import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { DemoRequestButtonActivity, OneTimeMessageActivity } from '@melio/ap-activities';
import { usePayments } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useDevice } from '@melio/platform-utils';

import { ScreenSplitLayout } from '@/cl/layouts/screen-split-layout/screenSplitLayout.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useInboxItemLink, usePayDashboardItems } from '@/hooks/payDashboard.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useIsMobile } from '@/hooks/useIsMobile';
import { PayDashboardDetailsScreen } from '@/screens/pay-dashboard/PayDashboardDetails.screen';
import {
  payDashboardItemSelectionsSelector,
  payDashboardSelectedCardSelector,
} from '@/store/PayDashboard/PayDashboards.model';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';
import { ActionsFooter } from '@/widgets/pay-dashboard/actions-footer/ActionsFooter.widget';
import { DashboardMonitoringProvider } from './DashboardMonitoring';
import { PayDashboardBillListScreen } from './PayDashboardBillList.screen';
import { PayDashboardDetailsCarouselScreen } from './PayDashboardDetailsCarousel.screen';

export const PayDashboardScreen = () => {
  const {
    isLoading: isPayDashboardGroupsLoading,
    isError: isPayDashboardGroupsError,
    error: payDashboardGroupsError,
  } = usePayDashboardItems({ refetchGroupsOnMount: true });
  const { generateErrorLink } = useRouter();
  const selectedItemIds = useRecoilValue(payDashboardItemSelectionsSelector);
  const { selectedInboxItemId } = useRecoilValue(payDashboardSelectedCardSelector);
  const [isBatchPaymentsEnabled] = useFeature(FeatureFlags.BatchPayments, false);
  const displayBatchSecondScreen = isBatchPaymentsEnabled && selectedItemIds.length > 0;
  const accessTokenData = getAccessTokenData();
  const isSmallScreen = useIsMobile();
  const { isDesktop } = useDevice();
  const { data: payments, isLoading } = usePayments({ params: { limit: 1, expand: 'none' } });
  const { partnerConfig } = usePartnerConfig();
  const { inboxItemLink, isLoading: isInboxItemLinkLoading } = useInboxItemLink();

  const userHasPayments = React.useMemo(() => (payments ? payments.length : true), [payments]);

  if (isPayDashboardGroupsError) {
    return <Navigate to={generateErrorLink(payDashboardGroupsError)} replace={false} />;
  }

  if (inboxItemLink) {
    return <Navigate to={inboxItemLink} replace={true} />;
  }

  return (
    <DashboardMonitoringProvider>
      <ScreenSplitLayout
        {...(isSmallScreen && {
          showScreenMobile: selectedInboxItemId ? 'second' : 'first',
          sticky: isDesktop,
        })}
        firstScreen={<PayDashboardBillListScreen isLoading={isInboxItemLinkLoading} />}
        secondScreen={
          <WithLoading isLoading={isPayDashboardGroupsLoading || isInboxItemLinkLoading} isAbsoluteCenter>
            {displayBatchSecondScreen ? (
              <PayDashboardDetailsCarouselScreen />
            ) : (
              <>
                <PayDashboardDetailsScreen />
                {accessTokenData?.organization && !userHasPayments && !isLoading && (
                  <Box
                    position={isSmallScreen ? 'absolute' : 'fixed'}
                    right={0}
                    bottom={0}
                    pr={10}
                    pb={isSmallScreen ? 5 : 20}
                  >
                    <DemoRequestButtonActivity
                      organizationId={accessTokenData?.organization?.id}
                      email={accessTokenData?.user.email}
                      helpCenterLink={partnerConfig.config.links.helpCenter}
                      hideAfterFirstUse
                    />
                  </Box>
                )}
              </>
            )}
          </WithLoading>
        }
        secondScreenFooter={selectedItemIds.length > 0 && <ActionsFooter />}
      />
      {accessTokenData?.user.id && <OneTimeMessageActivity userId={accessTokenData.user.id.toString()} />}
    </DashboardMonitoringProvider>
  );
};
