import { CompanyLegalInfoField, Vendor, VendorManagedByEnum } from '@melio/platform-api';

export const shouldCollectLegalInfoOnPaymentFlow = ({
  vendors,
  missingKycComplianceInformation,
  missingCompanyLegalInfoFields,
}: {
  vendors: Vendor[];
  missingKycComplianceInformation: boolean;
  missingCompanyLegalInfoFields: CompanyLegalInfoField[];
}): boolean =>
  vendors.some((vendor) => vendor.managedBy !== VendorManagedByEnum.Shared) &&
  (missingCompanyLegalInfoFields?.length > 0 || missingKycComplianceInformation);
