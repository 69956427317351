import { StatusModal } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  companyName: string;
  isLoading: boolean;
  isOpen: boolean;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const CancelConfirmationModalScreen = ({ companyName, isLoading, isOpen, onDone, onClose }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <StatusModal
      isOpen={isOpen}
      variant="warning"
      header={formatMessage(`activities.cancelJoinOrganizationRequestModal.title`, {
        companyName,
      })}
      description={formatMessage(`activities.cancelJoinOrganizationRequestModal.description`)}
      primaryButton={{
        label: formatMessage(`activities.cancelJoinOrganizationRequestModal.confirm`),
        onClick: () => onDone(),
        variant: 'primary',
        isLoading,
      }}
      onClose={onClose}
      data-testid="cancel-join-organization-request-modal-screen"
      data-component="CancelJoinOrganizationRequestModalActivity.CancelConfirmationModalScreen"
    />
  );
};

CancelConfirmationModalScreen.displayName = 'CancelJoinOrganizationRequestModalActivity.CancelConfirmationModalScreen';
