import { Container, Form, Group, Select, Text } from '@melio/penny';
import { Collaborator, useApprovalWorkflowRoles, WorkflowActions } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact, union } from 'lodash';

import { getDefaultValuesV2 } from '../../../CreateApprovalWorkflow/utils';
import { useFormContext } from '../../../FormContext';
import { ApprovalActionByRoles } from './ApprovalActionByRoles';
import { ApprovalActionByUsers } from './ApprovalActionByUsers';
import { DeleteAction } from './DeleteAction';

type Props = {
  approvers: Collaborator<'user'>[];
  approvalType: WorkflowActions['type'];
  onUserApprovalTypeChange: (value: string[]) => void;
  isDisabled: boolean;
  index: number;
};
export const ApprovalAction = ({ approvers, approvalType, onUserApprovalTypeChange, isDisabled, index }: Props) => {
  const { setValue, getValues, unregister, resetField } = useFormContext();
  const { formatMessage } = useMelioIntl();

  const { data } = useApprovalWorkflowRoles({ suspense: true });

  const isFirstAction = index === 0;

  const options = [
    {
      label: formatMessage('activities.create-approval-workflows.actions.approval.type.roles.title'),
      description: formatMessage('activities.create-approval-workflows.actions.approval.type.roles.description'),
      value: 'role',
    },
    {
      label: formatMessage('activities.create-approval-workflows.actions.approval.type.users.title'),
      description: formatMessage('activities.create-approval-workflows.actions.approval.type.users.description'),
      value: 'user',
    },
  ];

  const handleApprovalTypeChange = (e: React.ChangeEvent<HTMLInputElement>, index = 0) => {
    const { actions: defaultValues } = getDefaultValuesV2({ roles: data?.paymentApprovalRoles.approvers || [] });
    const selectedApprovalType = e.target.value as 'role' | 'user';
    if (selectedApprovalType === 'role') {
      setValue(`actions`, defaultValues, { shouldValidate: true });
    } else if (selectedApprovalType === 'user') {
      setValue(`actions.${index}`, { type: 'user', configuration: { ids: [] } }, { shouldValidate: true });
    }
  };

  const handleDeleteAction = () => {
    unregister(`actions.${index}`);
    resetField('actions', { defaultValue: compact(getValues('actions')), keepError: true });
  };

  const filterApproverByUsersStep = (index: number) => {
    const userActionsIndexes = getValues('actions').reduce<number[]>(
      (acc, { type }, index) => (type === 'user' ? [...acc, index] : acc),
      []
    );
    const selectedUsersIds = union(
      ...userActionsIndexes.map((v) => (v === index ? [] : getValues(`actions.${v}.configuration.ids`)))
    );
    return approvers.filter(({ userId }) => !selectedUsersIds.includes(userId));
  };

  const renderAction = (index: number) =>
    approvalType === 'role' ? (
      <ApprovalActionByRoles approvers={approvers} isDisabled={isDisabled} />
    ) : (
      <ApprovalActionByUsers
        approvers={filterApproverByUsersStep(index)}
        onChange={onUserApprovalTypeChange}
        isDisabled={isDisabled}
        index={index}
      />
    );

  if (isFirstAction) {
    return (
      <>
        <Form.ContentBox colSpan={3}>
          <Group alignItems="flex-start" width="full" height="full">
            <Container paddingTop="xs-s">
              <Text>{formatMessage('activities.create-approval-workflows.actions.approval.label')}</Text>
            </Container>
          </Group>
        </Form.ContentBox>
        <Form.ContentBox colSpan={4}>
          <Select
            aria-label="require approval type"
            data-testid="require-approval-type"
            formatSelectedValue={(option) => option.label.toLowerCase()}
            value={approvalType}
            onChange={handleApprovalTypeChange}
            options={options}
            emptyState={undefined}
          />
        </Form.ContentBox>
        <Form.ContentBox colSpan={4}>{renderAction(index)}</Form.ContentBox>
        <Form.ContentBox colSpan={1} />
      </>
    );
  }

  return (
    <>
      <Form.ContentBox colSpan={3}>
        <Group alignItems="flex-start" width="full" height="full">
          <Container paddingTop="xs-s">
            <Text>{formatMessage('activities.create-approval-workflows.actions.approval.label')}</Text>
          </Container>
        </Group>
      </Form.ContentBox>
      <Form.ContentBox colSpan={4}>{renderAction(index)}</Form.ContentBox>
      <Form.ContentBox colSpan={4} />
      <Form.ContentBox colSpan={1}>
        <DeleteAction onClick={handleDeleteAction} index={index} />
      </Form.ContentBox>
    </>
  );
};
