import { useEffect, useState } from 'react';
import { Form, Group, LoadingContainer, StatusModal, Text, useMelioForm, useToast } from '@melio/penny';
import {
  ModelError,
  useMelioQueryClient,
  useVendor,
  useVendorEditableFields,
  useVendorUnmaskedAccountNumber,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { DataComponentEnum } from '@/types/vendors.types';
import { preventFormSubmissionOnEnter } from '../utils';
import { VendorDetailsFormInnerProps, VendorDetailsMangedByFiservFormFields } from './types';
import { formErrors, useFormSchema } from './validation';

export const NewVendorDetailsMangedByFiservForm = forwardRef<VendorDetailsFormInnerProps, 'form'>(
  ({ vendorId, defaultValues, isSaving, onSubmit, onSubmissionStateChange, isEditable, ...props }, ref) => {
    const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
    const { toast } = useToast();
    const queryClient = useMelioQueryClient();
    const config = useConfig();
    const shouldShowTooltips = config.settings.vendor.forms.shouldUseTooltipsForManagedVendorForm;
    const { formatMessage } = useMelioIntl();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { data: vendor } = useVendor({ id: vendorId, enabled: false });

    const { data: editableFields, isLoading: isLoadingEditableFields } = useVendorEditableFields({
      disabled: !isEditable,
      vendorId,
    });

    const {
      data: unmaskedAccountNumber,
      isLoading: isLoadingUnmaskedAccountNumber,
      queryKey: unmaskedQueryKey,
    } = useVendorUnmaskedAccountNumber({
      disabled: !isEditable,
      vendorId,
    });

    const submitForm = async () => {
      const data = getValues();
      try {
        setIsSubmitting(true);
        await onSubmit(
          {
            accountNumber: data.accountNumber || '',
            nickname: data.nickname || '',
          },
          true,
        );
        // @ts-expect-error bad typing, the queryKey is correct
        queryClient.setQueriesData<typeof unmaskedAccountNumber>(unmaskedQueryKey, {
          ...unmaskedAccountNumber,
          accountNumber: data.accountNumber || '',
        });
        toggleConfirmModal(false);
      } catch (err) {
        const e = err as ModelError;
        const errorType = formErrors[e.code];
        const knownError = !!errorType;
        if (!knownError || errorType === 'toast') {
          toast({
            type: 'error',
            title: formatMessage(`widgets.vendorDetails.form.errors.${knownError ? e.code : 'default'}` as MessageKey),
            id: 'edit-vendor-error',
          });
        } else {
          setError(errorType, {
            message: formatMessage(`widgets.vendorDetails.form.errors.${e.code}` as MessageKey),
          });
          toggleConfirmModal(false);
        }
      } finally {
        setIsSubmitting(false);
      }
    };

    const { getFieldState, formProps, formState, registerField, reset, getValues, setError } =
      useMelioForm<VendorDetailsMangedByFiservFormFields>({
        onSubmit: () => {
          const { isDirty } = getFieldState('accountNumber', formState);
          if (isDirty) {
            toggleConfirmModal(true);
          } else {
            submitForm();
          }
        },
        schema: useFormSchema(),
        isSaving,
        defaultValues,
        onSubmissionStateChange,
      });

    useEffect(() => {
      if (unmaskedAccountNumber?.accountNumber) {
        if (isEditable) {
          reset({
            ...defaultValues,
            confirmAccountNumber: unmaskedAccountNumber.accountNumber,
            accountNumber: unmaskedAccountNumber.accountNumber,
          });
        } else {
          reset({ ...defaultValues });
        }
      }
    }, [unmaskedAccountNumber?.accountNumber, defaultValues, isEditable, reset]);

    return (
      <>
        <LoadingContainer isLoading={isLoadingEditableFields || isLoadingUnmaskedAccountNumber}>
          <Group variant="vertical" spacing="m">
            <Group variant="vertical" spacing="xs">
              {isEditable && (
                <Text color="neutral.darkest" textStyle="body4">
                  {formatMessage('widgets.vendorDetails.form.business.requiredFields')}
                </Text>
              )}
              <Text as="h3" textStyle="heading3Semi">
                {formatMessage('widgets.vendorDetails.form.business.title')}
              </Text>
            </Group>
            <Form
              data-component={DataComponentEnum.VENDOR_DETAILS_FORM}
              {...props}
              {...formProps}
              ref={ref}
              columns={2}
              isReadOnly={!isEditable}
              isViewMode={!isEditable}
              {...preventFormSubmissionOnEnter()}
            >
              <Form.TextField
                labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
                isReadOnly
                isTruncated={!isEditable}
                colSpan={2}
                {...registerField('companyName')}
              />
              <Form.TextField
                labelProps={{ label: formatMessage('widgets.vendorDetails.form.nickname.label') }}
                viewModePlaceholder={formatMessage('widgets.vendorDetails.form.nickname.viewModePlaceholder')}
                autoFocus={isEditable}
                isTruncated={!isEditable}
                colSpan={2}
                {...registerField('nickname')}
              />
              <Form.TextField
                labelProps={{
                  label: formatMessage('widgets.vendorDetails.form.accountNumber.label'),
                  tooltipProps:
                    isEditable && shouldShowTooltips
                      ? {
                          label: formatMessage('widgets.vendors.accountNumber.labelTooltip'),
                          triggerAriaLabel: formatMessage('widgets.vendors.accountNumber.ariaLabelTooltip'),
                        }
                      : undefined,
                }}
                helperTextProps={{
                  label: formatMessage('widgets.vendors.accountNumber.helperText'),
                }}
                isDisabled={isEditable && !editableFields?.includes('accountNumber')}
                isTruncated={!isEditable}
                colSpan={isEditable ? 1 : 2}
                {...registerField('accountNumber')}
              />
              {isEditable && (
                <Form.TextField
                  labelProps={{ label: formatMessage('widgets.vendorDetails.form.confirmAccountNumber.label') }}
                  isDisabled={isEditable && !editableFields?.includes('accountNumber')}
                  isTruncated={!isEditable}
                  colSpan={1}
                  {...registerField('confirmAccountNumber')}
                />
              )}
            </Form>
          </Group>
        </LoadingContainer>
        <StatusModal
          variant="warning"
          header={formatMessage(`widgets.vendorDetails.form.confirmModal.header`)}
          description={formatMessage(`widgets.vendorDetails.form.confirmModal.description`, {
            vendorName: vendor?.name,
          })}
          primaryButton={{
            variant: 'primary',
            label: formatMessage(`widgets.vendorDetails.form.confirmModal.primaryButton`),
            isLoading: isSubmitting,
            onClick: submitForm,
          }}
          secondaryButton={{
            variant: 'secondary',
            label: formatMessage(`widgets.vendorDetails.form.confirmModal.secondaryButton`),
            isDisabled: isSubmitting,
            onClick: () => {
              toggleConfirmModal(false);
            },
          }}
          onClose={() => toggleConfirmModal(false)}
          isOpen={isConfirmModalOpen}
        />
      </>
    );
  },
);
