import { useEffect } from 'react';
import { Modal, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useTaxPayerInfoDetailsRequest, Vendor } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';

type CancelVendorTaxDetailsModalProps = {
  vendor: Vendor;
  isOpen: boolean;
  onClose: () => void;
};

export const CancelRequestVendorTaxDetailsModal = ({ vendor, isOpen, onClose }: CancelVendorTaxDetailsModalProps) => {
  const { formatMessage } = usePlatformIntl();
  const { cancel: cancelTaxPayerInfoRequest, isMutating } = useTaxPayerInfoDetailsRequest({
    vendorId: vendor.id,
  });
  const { toast } = useToast();
  const { track, createTrackHandler } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');

  const onCancelRequest = async () => {
    trackActionClick({ Cta: 'cancel-request' });
    try {
      await cancelTaxPayerInfoRequest(vendor.lastTaxpayerIdentificationRequest?.id as string);
      onClose();
      toast({
        type: 'informative',
        title: formatMessage('widgets.taxPayerInfo.cancelModal.toast.success'),
        id: 'vendor-tax-details-cancel-request-success',
      });
      track('Vendor', 'Status', {
        Intent: 'cancel-w9-request',
        TaxId: null,
        Status: 'success',
        PageName: 'cancel-w9-request',
      });
    } catch (e) {
      toast({
        type: 'error',
        title: formatMessage('widgets.taxPayerInfo.cancelModal.toast.fail'),
        id: 'vendor-tax-details-cancel-request-error',
      });
      track('Vendor', 'Status', {
        PageName: 'cancel-w9-request',
        Intent: 'cancel-w9-request',
        TaxId: null,
        Status: 'fail',
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View', {
        Flow: 'dashboard-vendors',
        PageName: 'cancel-w9-request',
        Intent: 'cancel-w9-request',
        TaxId: 'request-sent',
      });
    }
  }, [isOpen, track]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        trackActionClick({ Cta: 'exit' }, () => onClose());
      }}
      isLoading={isMutating}
      data-testid="vendor-cancel-request-tax-details-modal"
      header={formatMessage('widgets.taxPayerInfo.cancelModal.header')}
      description={formatMessage('widgets.taxPayerInfo.cancelModal.description')}
      secondaryButton={{
        label: formatMessage('widgets.taxPayerInfo.cancelModal.actions.keep'),
        variant: 'tertiary',
        onClick: () => {
          trackActionClick({ Cta: 'keep-request' }, () => onClose());
        },
        isLoading: isMutating,
      }}
      primaryButton={{
        label: formatMessage('widgets.taxPayerInfo.cancelModal.actions.cancel'),
        variant: 'critical',
        isLoading: isMutating,
        onClick: onCancelRequest,
      }}
    />
  );
};
