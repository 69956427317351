import { useToast } from '@melio/penny';
import { useAccountingPlatform } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type Params = {
  accountingPlatformId?: string;
  authParams?: Record<string, unknown>;
};

export const useRegisterOrganization = ({ accountingPlatformId, authParams = {} }: Params) => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const {
    registerOrganization: registerOrganizationAction,
    isRegisteringOrganization,
    isOrganizationRegistered,
  } = useAccountingPlatform({
    id: accountingPlatformId,
    enabled: false,
  });
  const registerOrganization = async (externalOrganizationId: string) => {
    try {
      await registerOrganizationAction({ externalOrganizationId, authData: authParams });
    } catch (e) {
      toast({
        type: 'error',
        title: formatMessage('activities.accountingPlatformConnectCallback.screens.selectOrganization.registerError'),
      });
      throw e;
    }
  };

  return {
    isRegisteringOrganization,
    registerOrganization,
    isOrganizationRegistered,
  };
};
