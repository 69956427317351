import { Drawer, Text } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useTodos } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { TodosDrawerBody } from './components/TodosDrawerBody';

export const TodosDrawerActivity = withAnalyticsContext(({ onClose }: { onClose: VoidFunction }) => {
  const { formatMessage } = useMelioIntl();
  const [isClosing, setIsClosing] = useState(false);
  const { data: todos, isLoading, totalCount } = useTodos({ refetchOnMount: 'always' });

  const handleClose = () => {
    setIsClosing(true);
  };

  const handleCloseComplete = () => {
    onClose();
  };

  return (
    <Drawer
      isOpen={!isClosing}
      onClose={handleClose}
      onCloseComplete={handleCloseComplete}
      data-testid="todos-drawer"
      closeButtonAriaLabel={formatMessage('widgets.todosDrawer.closeButtonAriaLabel')}
      header={
        <Text as="h2" textStyle="heading2Semi" data-testid="todos-drawer-header-title">
          {formatMessage('widgets.todosDrawer.header.title')}
        </Text>
      }
      body={<TodosDrawerBody todos={todos} isLoading={isLoading} totalCount={totalCount} />}
    />
  );
});
