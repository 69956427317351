// eslint-disable-next-line import/no-extraneous-dependencies
import {
  NaicsIndustrySourceEnum,
  TaxIdTypeEnum,
  validateBusinessIndustry,
  validateCompanyBusinessAddress,
  validateCompanyName,
  validateContactFirstName,
  validateContactLastName,
  validateContactPhone,
  validateDateOfBirth,
  validateEmailAddress,
  validateLegalCompanyName,
  validateTaxIdType,
  ValidationResponse,
} from '@melio/compliance-validator';
import { Company, Industry, OrganizationBusinessType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { BaseSchema, number, object, string, TestFunction } from 'yup';

import { useMtlMessages } from './useMtlMessages';
import { DATE_FORMAT, DATE_REGEX, TAX_ID_FORMAT } from './useMtlSchemaValidation';

type Validator<T> = (value: T) => ValidationResponse;

export type MtlValidationFields =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'contactFirstName'
  | 'contactLastName'
  | 'companyName'
  | 'dateOfBirth'
  | 'address'
  | 'legalCompanyName'
  | 'phoneNumber'
  | 'legalAddress'
  | 'industry'
  | 'businessType'
  | 'taxInfoType'
  | 'taxInfoIdentifier'
  | 'existingTaxIdIsEin'
  | 'taxIdEinOverride';

export type MtlMandatoryValidationFields = Partial<Record<MtlValidationFields, boolean>>;

export const useRiskMtlSchemaValidations = (mandatoryFields: MtlMandatoryValidationFields) => {
  const { isValidPastDate, parseDate } = useDateUtils(DATE_FORMAT);
  const { formatMessage } = useMelioIntl();

  const {
    validations: {
      required: {
        company: { einOnlyValidationText },
      },
    },
  } = useMtlMessages();

  const customTest = <T>(validator: Validator<T>): TestFunction<T> =>
    function (value: T) {
      const { isValid, errors } = validator(value);

      if (!isValid) {
        const error = errors?.[0];
        return this.createError({
          path: this.path,
          message: error && formatMessage(`app.mtl.risk.validations.${error}`),
        });
      }

      return true;
    };

  const addressTest = (fieldName: MtlValidationFields, isLegal: boolean) =>
    object().when(
      isNotOptional(
        fieldName,
        object({
          line1: string(),
          line2: string(),
          city: string(),
          postalCode: string(),
          state: string(),
        }).test(
          'address',
          customTest<Partial<Company['address']>>(({ line1, city, state, postalCode }) =>
            validateCompanyBusinessAddress(
              {
                addressLine1: line1,
                city,
                state,
                zipCode: postalCode,
                countryCode: 'US',
              },
              isLegal
            )
          )
        )
      )
    );

  const taxInfoIdentifier = () =>
    string()
      .required()
      .when('taxInfoType', (taxIdType: TaxIdTypeEnum) =>
        taxIdType
          ? string().when('businessType', (businessType: OrganizationBusinessType) =>
              businessType
                ? string()
                    .required(
                      taxIdType === TaxIdTypeEnum.Ein && businessType
                        ? einOnlyValidationText(businessType)
                        : formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType}.required`)
                    )
                    .matches(
                      TAX_ID_FORMAT,
                      formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType}.invalid`)
                    )
                : string().when(
                    isNotOptional(
                      'taxInfoIdentifier',
                      string()
                        .required(formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType}.required`))
                        .matches(
                          TAX_ID_FORMAT,
                          formatMessage(`app.mtl.validations.taxInfo.identifier.${taxIdType}.invalid`)
                        )
                    )
                  )
            )
          : string().nullable()
      );

  const isNotOptional = (fieldName: MtlValidationFields, schema: BaseSchema) => (value: unknown) =>
    !mandatoryFields[fieldName] && !value ? string().nullable().optional() : schema;
  const withOptional = <T = string>(fieldName: MtlValidationFields, schema: BaseSchema, validator: Validator<T>) =>
    schema.when(isNotOptional(fieldName, schema.test(customTest(validator))));

  return {
    firstName: () => withOptional('firstName', string(), validateContactFirstName),
    lastName: () => withOptional('lastName', string(), validateContactLastName),
    email: () => withOptional('email', string(), validateEmailAddress),
    contactFirstName: () => withOptional('contactFirstName', string(), validateContactFirstName),
    contactLastName: () => withOptional('contactLastName', string(), validateContactLastName),
    companyName: () => withOptional('companyName', string(), validateCompanyName),
    dateOfBirth: () =>
      withOptional(
        'dateOfBirth',
        string()
          .required(formatMessage('app.mtl.validations.dateOfBirth.required'))
          .test(
            'isValidPastDate',
            formatMessage('app.mtl.validations.dateOfBirth.format', { format: DATE_FORMAT.toLocaleUpperCase() }),
            (value) => !!value && DATE_REGEX.test(value) && isValidPastDate(parseDate(value))
          ),
        (value) => validateDateOfBirth(parseDate(value))
      ),
    address: () => addressTest('address', false),
    legalCompanyName: () => withOptional('legalCompanyName', string(), validateLegalCompanyName),
    phoneNumber: () => withOptional('phoneNumber', string(), validateContactPhone),
    legalAddress: () => addressTest('legalAddress', true),
    industry: () =>
      withOptional<Industry | null>(
        'industry',
        object({
          name: string(),
          naicsCode: number().nullable(),
        }).nullable(),
        (value) =>
          validateBusinessIndustry(
            value
              ? [
                  {
                    naicsCode: value.naicsCode,
                    source: NaicsIndustrySourceEnum.USER_PROVIDED,
                    industryText: value.name,
                  },
                ]
              : []
          )
      ),
    businessType: () =>
      string().when(
        isNotOptional(
          'businessType',
          string()
            .nullable()
            .required(formatMessage('app.mtl.validations.businessType.required.risk'))
            .oneOf(Object.values(OrganizationBusinessType))
        )
      ),
    taxInfoType: () => withOptional('taxInfoType', string(), validateTaxIdType),
    taxInfoIdentifier,
    existingTaxIdIsEin: () => string().required(),
    taxIdEinOverride: () =>
      string()
        .optional()
        .when('existingTaxIdIsEin', {
          is: (existingTaxIdIsEin: string) => existingTaxIdIsEin === 'No',
          then: taxInfoIdentifier(),
          otherwise: string().optional(),
        }),
  };
};
