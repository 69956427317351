import { _TableHeaderCellProps, Table } from '@melio/penny';
import { VFC } from 'react';

import { Order, SortFields } from '../../types';

export type SortableHeaderCellProps = {
  label: string;
  order?: Order;
  field?: SortFields;
  selectedField?: SortFields;
  textAlign?: _TableHeaderCellProps['textAlign'];
  onClick: (field: SortFields, order: Order) => void;
};

const getSortOrder = (order?: Order) => (order === 'Desc' ? 'desc' : 'asc');

export const SortableHeaderCell: VFC<SortableHeaderCellProps> = ({
  label,
  order,
  field,
  selectedField,
  onClick,
  textAlign,
  ...props
}) => {
  const isSameField = !!field && field === selectedField;
  const targetOrder = isSameField ? (order == 'Asc' ? 'Desc' : 'Asc') : 'Desc';
  return (
    <Table.HeaderSortableCell
      data-component={SortableHeaderCell.displayName}
      textAlign={textAlign}
      onClick={field && (() => onClick(field, targetOrder))}
      sortDirection={isSameField && getSortOrder(order)}
      {...props}
    >
      {label}
    </Table.HeaderSortableCell>
  );
};
SortableHeaderCell.displayName = 'SortableHeaderCell';
