import { useToast } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { PlaidAccountData, useAccountingPlatforms, useDeliveryMethods } from '@melio/platform-api';
import { useParams } from 'react-router-dom';

import { LinkBankAccountActivity } from '../../link-bank-account';
import { BankAccountCreatedScreen, BankIntegrationPlaidLinkingScreen } from './screens';
import { AddBankAccountPlaidActivityProps } from './types';
import { useAddBankAccountPlaidStep } from './useAddBankAccountPlaidStep';
import { useBankAccountPlaid } from './useBankAccountPlaid';

export const AddBankAccountPlaidActivity = withAnalyticsContext<AddBankAccountPlaidActivityProps>(
  ({ onBack: onFirstStepBack, onClose, onError: _onError, onDone, isReceivingMethodFlow, fundingSourceId }) => {
    const { currentStep, goToStep } = useAddBankAccountPlaidStep({ onFirstStepBack });
    const { hasAccountingPlatform } = useAccountingPlatforms();

    const { deliveryMethodId: updateDeliveryMethodId } = useParams();
    const { refetch: refetchDeliveryMethods } = useDeliveryMethods({ isGetOwn: true });
    const { toast } = useToast();

    const onError = (error: PlatformError) => {
      if (_onError) {
        _onError(error);
      }
      toast({
        type: 'error',
        title: error.message,
      });
    };
    const { fundingSource, deliveryMethod, onSuccess, handleFail, isLoading } = useBankAccountPlaid({
      onError,
      onClose,
      isReceivingMethodFlow,
      updateDeliveryMethodId,
      fundingSourceId,
    });

    const onPlaidDone = async (data: PlaidAccountData) => {
      const verificationFlowCompleted = fundingSource && data.account.verification_status === 'manually_verified';
      if (verificationFlowCompleted) {
        onDone(fundingSource);
      } else {
        await onSuccess(data);
        goToStep('ADD_BANK_ACCOUNT_PLAID_CONFIRMATION');
      }
    };

    useAnalyticsContext({ globalProperties: { NewFundingSource: 'bank-account' } });

    switch (currentStep) {
      case 'ADD_BANK_ACCOUNT_PLAID':
      default:
        return (
          <BankIntegrationPlaidLinkingScreen
            onClose={onClose}
            onSuccess={onPlaidDone}
            onError={handleFail}
            isLoading={isLoading}
            fundingSourceId={fundingSourceId}
          />
        );

      case 'LINK_BANK_ACCOUNT':
        return (
          <LinkBankAccountActivity
            onClose={() => {
              refetchDeliveryMethods().catch((err) => handleFail(err));
              deliveryMethod && onDone(deliveryMethod);
            }}
            onDone={() => {
              refetchDeliveryMethods().catch((err) => handleFail(err));
              deliveryMethod && onDone(deliveryMethod);
            }}
            onBack={() => goToStep('ADD_BANK_ACCOUNT_PLAID_CONFIRMATION')}
            onError={handleFail}
            deliveryMethodId={deliveryMethod?.id}
          />
        );

      case 'ADD_BANK_ACCOUNT_PLAID_CONFIRMATION':
        return (
          <BankAccountCreatedScreen
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onDone={() => {
              if (fundingSource?.id) {
                onDone(fundingSource);
              } else if (deliveryMethod?.id) {
                if (isReceivingMethodFlow && hasAccountingPlatform) {
                  goToStep('LINK_BANK_ACCOUNT');
                } else {
                  refetchDeliveryMethods().catch((err) => handleFail(err));
                  onDone(deliveryMethod);
                }
              }
            }}
            fundingSource={fundingSource}
            isLoading={isLoading}
            onClose={onClose}
            isReceivingMethodFlow={isReceivingMethodFlow}
          />
        );
    }
  }
);

AddBankAccountPlaidActivity.displayName = 'AddBankAccountPlaidActivity';
