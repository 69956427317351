import { PayorPaymentFee, sumPaymentFees } from '@melio/ap-domain';
import { Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { FeeItem } from './FeeItem';

export const PaymentFees = ({ fees }: { fees: PayorPaymentFee[] }) => {
  const { formatMessage } = useMelioIntl();

  const totalFeesAmount = sumPaymentFees(fees);
  const formatFeeValue = (fee: PayorPaymentFee) => {
    const { type } = fee;

    return formatMessage(`widgets.paymentDrawer.body.paymentFees.feeTypes.${type}`);
  };

  return (
    <Container data-component="PaymentFees">
      <Group variant="vertical" spacing="m">
        <Group.Item>
          <Text textStyle="heading3Semi">{formatMessage('widgets.paymentDrawer.body.paymentFees.title')}</Text>
        </Group.Item>

        <Group.Item>
          <Group variant="vertical" spacing="xxs">
            <Group variant="vertical">
              {fees.map((fee, index) => (
                <FeeItem key={index} label={formatFeeValue(fee)} amount={fee.amount} />
              ))}
              <FeeItem
                isBold
                data-testid="payment-fees-total"
                label={formatMessage('widgets.paymentDrawer.body.paymentFees.totalFees')}
                amount={totalFeesAmount}
              />
            </Group>

            <Text textStyle="body4" color="neutral.darker">
              {formatMessage('widgets.paymentDrawer.body.paymentFees.description')}
            </Text>
          </Group>
        </Group.Item>
      </Group>
    </Container>
  );
};
