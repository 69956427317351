import { FxCurrency, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../../NewSinglePaymentStepLayout';

type NonEditableScreenTitleProps = {
  vendorName: Vendor['name'];
  paymentAmount: number;
  currency?: FxCurrency;
  isRecurringPayment?: boolean;
};

export const NonEditableScreenTitle = ({
  vendorName,
  paymentAmount,
  currency,
  isRecurringPayment,
  ...props
}: NonEditableScreenTitleProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout.Title {...props}>
      {isRecurringPayment
        ? formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.titleRecurring')
        : formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.title', {
            total: formatCurrency(paymentAmount, currency),
            name: vendorName,
          })}
    </NewSinglePaymentStepLayout.Title>
  );
};
