import { BillSubscription, BillSubscriptionStatus, Vendor } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';

const mutableBillSubscriptionStatuses: BillSubscriptionStatus[] = [
  BillSubscriptionStatus.Created,
  BillSubscriptionStatus.InProgress,
];

export const useBillSubscriptionActions = (billSubscription?: BillSubscription, vendor?: Vendor) => {
  const { can } = usePermissions();

  if (!billSubscription || !vendor) {
    return {
      isEditBillSubscriptionAllowed: false,
      isCancelBillSubscriptionAllowed: false,
    };
  }

  const canEditBillSubscription = can({
    subject: 'billSubscription',
    action: 'update',
    subjectData: {
      vendor: {
        createdById: vendor.createdById,
        managedBy: vendor.managedBy,
      },
      billSubscription: {
        managedBy: billSubscription.managedBy,
        createdById: billSubscription.createdById,
      },
    },
  });

  const canCancelBillSubscription = can({
    subject: 'billSubscription',
    action: 'cancel',
    subjectData: {
      vendor: {
        createdById: vendor.createdById,
        managedBy: vendor.managedBy,
      },
      billSubscription: {
        managedBy: billSubscription.managedBy,
        createdById: billSubscription.createdById,
      },
    },
  });
  const isBillSubscriptionMutable = mutableBillSubscriptionStatuses.includes(billSubscription.status);

  const isEditBillSubscriptionAllowed = isBillSubscriptionMutable && canEditBillSubscription;

  const isCancelBillSubscriptionAllowed = isBillSubscriptionMutable && canCancelBillSubscription;

  return {
    isEditBillSubscriptionAllowed,
    isCancelBillSubscriptionAllowed,
  };
};
