import { InboxItem } from '@melio/javascript-sdk';
import {
  Todo,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPayment,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItems,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApprove,
  TodoPaymentsToApproveTypeEnum,
} from '@melio/platform-api-axios-client';

import { convertInboxItemCentsToDollars } from '../../entities/inbox-item/utils';
import { convertPaymentsCentsToDollars } from '../payment/utils';

export const todosOrder: Partial<Todo['type']>[] = [
  TodoFailedPaymentTypeEnum.FailedPayments,
  TodoActivateCollaboratorsTypeEnum.ActivateCollaborators,
  TodoPaymentsToApproveTypeEnum.PaymentsToApprove,
  TodoOverdueInboxItemsTypeEnum.OverdueInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems,
];

export const getTotalCount = (data: Todo[] | undefined): number =>
  data?.reduce((count, todo) => {
    const curAmount = todo.items.pagination.totalCount || 0;
    if (todo.type === TodoActivateCollaboratorsTypeEnum.ActivateCollaborators && curAmount > 0) {
      return count + 1;
    }
    return count + curAmount;
  }, 0) ?? 0;

export const convertTodosCentsToDollars = (data: Todo[] | undefined): Todo[] | undefined => {
  if (!data) {
    return undefined;
  }

  return data.map((todo) => {
    switch (todo.type) {
      case TodoFailedPaymentTypeEnum.FailedPayments:
      case TodoPaymentsToApproveTypeEnum.PaymentsToApprove: {
        const paymentTodo: TodoFailedPayment | TodoPaymentsToApprove = todo;
        return {
          ...paymentTodo,
          items: { ...paymentTodo.items, data: convertPaymentsCentsToDollars(paymentTodo.items.data) },
        };
      }
      case TodoOverdueInboxItemsTypeEnum.OverdueInboxItems:
      case TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems:
      case TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems: {
        const typesTodo: TodoOverdueInboxItems | TodoOverdueIn7DaysInboxItems | TodoOverdueInMoreThan7DaysInboxItems =
          todo;
        return {
          ...typesTodo,
          items: {
            ...typesTodo.items,
            data: typesTodo.items.data.map((d) => convertInboxItemCentsToDollars(d as InboxItem)),
          },
        };
      }
      default:
        return todo;
    }
  });
};
