import { ARInvoice, InvoiceExpandedSummary, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Button, ButtonProps, Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { Big } from 'big.js';
import { useState } from 'react';

import {
  getActionLabel,
  getActionParameterValue,
  getActionVariant,
  getInvoiceAction,
  getInvoiceStatusActions,
  InvoiceActions,
  InvoiceActionsHandlers,
} from '../../../utils';

function getPrimaryButtonActionVariant(status: ARInvoice['displayStatus']): ButtonProps['variant'] {
  switch (status) {
    case 'canceled':
      return 'secondary';
    default:
      return 'primary';
  }
}

const displayStatusToPrimaryActionMap: Record<ARInvoice['displayStatus'], InvoiceActions> = {
  inProcess: 'preview',
  open: 'send',
  overdue: 'send',
  paid: 'preview',
  draft: 'edit',
  canceled: 'preview',
};

export const DrawerFooter = forwardRef<{
  expandedInvoice: InvoiceExpandedSummary;
  invoiceActions: InvoiceActionsHandlers;
}>(({ expandedInvoice, invoiceActions }, ref) => {
  const actions = getInvoiceStatusActions(expandedInvoice.displayStatus);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
  const { formatMessage } = useMelioIntl();
  const isMarkedAsPaid =
    expandedInvoice.displayStatus === 'paid' && expandedInvoice.receivablePaymentDetails?.source === 'manual';

  const primaryAction = displayStatusToPrimaryActionMap[expandedInvoice.displayStatus];

  const dropdownActions = [
    ...actions.filter((action) => action !== primaryAction),
    isMarkedAsPaid && 'markAsUnpaid',
  ].filter(Boolean) as InvoiceActions[];

  const { track } = useAnalytics();

  const actionItems = dropdownActions
    .map((action) => {
      const actionFn = getInvoiceAction(action, invoiceActions);
      const actionParameterValue = getActionParameterValue({ action, invoice: expandedInvoice });

      return actionFn
        ? {
            label: getActionLabel(action),
            variant: getActionVariant(action),
            onClick: () => {
              void actionFn(actionParameterValue);
              track('Invoice', 'Click', {
                ProductName: 'ar',
                PageName: 'dashboard-drawer',
                TabName: 'invoices',
                Flow: 'manage-invoice',
                Intent: action,
                Cta: action,
                InvoiceId: expandedInvoice.id,
                InvoiceStatus: expandedInvoice.displayStatus,
                DueDate: expandedInvoice.dueDate,
                Amount: new Big(expandedInvoice.totalAmount).div(100).toNumber(),
              });
            },
          }
        : null;
    })
    .filter(Boolean) as ActionsDropdownMenuItemProps[];

  const actionParameterValue = getActionParameterValue({ action: primaryAction, invoice: expandedInvoice });
  const primaryActionFn = getInvoiceAction(primaryAction, invoiceActions);
  const primaryActionItem = primaryActionFn
    ? {
        label: getActionLabel(primaryAction),
        variant: getPrimaryButtonActionVariant(expandedInvoice.displayStatus),
        onClick: () => void primaryActionFn(actionParameterValue),
      }
    : null;

  return (
    <Group width="full" justifyContent="space-between" spacing="s" ref={ref}>
      <ActionsDropdownMenu
        isOpen={isActionsMenuOpen}
        onOpenChange={setIsActionsMenuOpen}
        label={formatMessage('ar.dashboard.activities.InvoiceDrawer.footer.actionsMenu.label')}
        items={actionItems}
      />
      <Button {...primaryActionItem} data-testid="drawer-footer-primary-button" />
    </Group>
  );
});
