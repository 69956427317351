import { defaults } from 'lodash';
import { date, object, SchemaOf, string } from 'yup';
import {
  AccountingPlatformCategorySelectWidget,
  FormWidgetProps,
  useAccountingPlatformBillCategoryLabel,
  VendorSelectWidget,
} from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { AccountingPlatform } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';

export type BillDetailsFormProps = FormWidgetProps<BillDetailsFormFields> & {
  editMode?: boolean;
  activeAccountingPlatform?: AccountingPlatform;
};

export type BillDetailsFormFields = {
  vendorId: string;
  totalAmount: string; // it should be number but platform does not support number input
  dueDate: Date | null;
  billNumber: string | null;
  noteToSelf: string;
  categoryId?: string | null;
};

export const useSchema = () => {
  const { formatMessage } = usePlatformIntl();

  return object().shape({
    vendorId: string().required(formatMessage('widgets.billDetails.form.vendor.validation.required')),
    totalAmount: string()
      .required(formatMessage('widgets.billDetails.form.totalAmount.validation.required'))
      .test(
        'validMoreThenZero',
        formatMessage('widgets.billDetails.form.totalAmount.validation.gtZero'),
        (value?: string) => {
          const number = Number(value);
          return number > 0;
        },
      ),
    dueDate: date().nullable().required(formatMessage('widgets.billDetails.form.dueDate.validation.required')),
    billNumber: string().nullable(),
    noteToSelf: string(),
    categoryId: string().nullable(),
  }) as SchemaOf<BillDetailsFormFields>;
};

export const BillDetailsForm = forwardRef<BillDetailsFormProps, 'form'>(
  (
    {
      onSubmit,
      defaultValues: _defaultValues,
      onSubmissionStateChange,
      isDisabled,
      editMode,
      activeAccountingPlatform,
      ...props
    },
    ref,
  ) => {
    const accountingPlatformBillCategoryLabel = useAccountingPlatformBillCategoryLabel(
      activeAccountingPlatform?.accountingSlug,
    );
    const defaultValues = defaults(
      { ..._defaultValues },
      {
        vendorId: '',
        totalAmount: 0,
        dueDate: null,
        billNumber: '',
        noteToSelf: '',
        categoryId: null,
      },
    );

    const { formatMessage } = usePlatformIntl();

    const { formProps, registerField } = useMelioForm<BillDetailsFormFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues,
      onSubmissionStateChange,
    });

    const shouldShowCategorySelect = !!activeAccountingPlatform && (defaultValues.categoryId || editMode);

    return (
      <Form size="small" isViewMode={!editMode} ref={ref} data-testid="bill-details-form" {...props} {...formProps}>
        <VendorSelectWidget
          placeholder={formatMessage('widgets.vendorSelect.placeholder')}
          {...registerField('vendorId')}
          labelProps={{ label: formatMessage('widgets.billDetails.form.vendor.label') }}
          eventContextName="Bill"
        />
        <Form.AmountField
          {...registerField('totalAmount')}
          labelProps={{ label: formatMessage('widgets.billDetails.form.totalAmount.label') }}
        />
        <Form.DateField
          {...registerField('dueDate')}
          labelProps={{ label: formatMessage('widgets.billDetails.form.dueDate.label') }}
          placeholder={formatMessage('widgets.billDetails.form.dueDate.placeholder')}
        />
        <Form.TextField
          {...registerField('billNumber')}
          labelProps={{ label: formatMessage('widgets.billDetails.form.billNumber.label') }}
          placeholder={formatMessage('widgets.billDetails.form.billNumber.placeholder')}
          viewModePlaceholder={formatMessage('widgets.billDetails.form.billNumber.viewModePlaceholder')}
        />
        {shouldShowCategorySelect && (
          <AccountingPlatformCategorySelectWidget
            {...registerField('categoryId')}
            activeAccountingPlatform={activeAccountingPlatform}
            labelProps={{
              label: accountingPlatformBillCategoryLabel,
            }}
          />
        )}
        <Form.TextField
          {...registerField('noteToSelf')}
          labelProps={{ label: formatMessage('widgets.billDetails.form.noteToSelf.label') }}
          placeholder={formatMessage('widgets.billDetails.form.noteToSelf.placeholder')}
          viewModePlaceholder={formatMessage('widgets.billDetails.form.noteToSelf.viewModePlaceholder')}
        />
      </Form>
    );
  },
);
