import { isFXCurrency } from '@melio/ap-domain';
import { Tooltip } from '@melio/penny';
import { FxCurrency, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

type Props = {
  children: React.ReactNode;
  vendor: Vendor;
};
export const VendorCurrencyTooltip = ({ children, vendor }: Props) => {
  const { getCurrencyName, formatMessage } = useMelioIntl();
  const currencyName = getCurrencyName(vendor.currency ?? FxCurrency.Usd);

  const isEnabled = vendor.currency && isFXCurrency(vendor.currency);
  return (
    <Tooltip
      data-testid={`vendor-currency-tooltip-${vendor.id}`}
      isEnabled={!!isEnabled}
      label={formatMessage('activities.payDashboard.vendorsTab.table.headers.openBalance.currency-helper', {
        currencySymbol: vendor.currency,
        currencyName,
      })}
    >
      {children}
    </Tooltip>
  );
};
