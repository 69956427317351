/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useBill } from '@melio/platform-api';

import { AsyncModalDialog } from '@/cl/components/AsyncModalDialog/AsyncModalDialog.component';
import { useAppToast } from '@/hooks/useAppToast.hooks';
import { usePlatformIntl } from '@/translations/Intl';

type DeleteBillDialogProps = {
  billId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: VoidFunction;
};

export const DeleteBillDialog = withAnalyticsContext<DeleteBillDialogProps>(
  ({ billId, isOpen, onClose, onSuccess }) => {
    const { formatMessage } = usePlatformIntl();
    const { infoToast, errorToast } = useAppToast();

    const { data: bill, delete: deleteBill } = useBill({ id: billId });

    const { track } = useAnalytics();

    useAnalyticsContext({
      globalProperties: {
        BillId: billId,
      },
    });

    useEffect(() => {
      if (isOpen) {
        track('PayDashboardDeleteBill', 'Viewed');
      }
    }, [isOpen]);

    const handleDialogClose = () => {
      track('PayDashboardDeleteBil' /** Do not fix typo */, 'Cancel');
      onClose();
    };

    const handleDialogConfirm = async () => {
      try {
        track('PayDashboardDeleteBil' /** Do not fix typo */, 'Submitted');

        await deleteBill();
        onSuccess?.();

        infoToast(formatMessage('widgets.billDetails.deleteBillDialog.toast.success'));
        track('PayDashboardBillDeleted', 'Viewed');
      } catch (error) {
        errorToast(formatMessage('widgets.billDetails.deleteBillDialog.toast.error'));
      } finally {
        onClose();
      }
    };

    return (
      <AsyncModalDialog
        isOpen={isOpen}
        type="cancel"
        title={formatMessage('widgets.billDetails.deleteBillDialog.title', {
          invoiceNumber: bill?.invoice.number || '',
          vendorName: bill?.vendor?.name || '',
        })}
        body={formatMessage(
          bill?.payments?.length
            ? 'widgets.billDetails.deleteBillDialog.warning.withPayments'
            : 'widgets.billDetails.deleteBillDialog.warning.general',
        )}
        confirmText={formatMessage('widgets.billDetails.deleteBillDialog.confirm')}
        cancelText={formatMessage('widgets.billDetails.deleteBillDialog.cancel')}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    );
  },
);
