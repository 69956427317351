import { Icon } from '@melio/penny';
import { TodoActivateCollaborators, TodoActivateCollaboratorsTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../utils';
import { TodoSection } from '../TodoSection';

export type TodoSectionActivateCollaboratorsProps = {
  todo?: TodoActivateCollaborators;
};

export const TodoSectionActivateCollaborators = ({ todo }: TodoSectionActivateCollaboratorsProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToSettingsCollaborators } = useActivitiesNavigate();

  if (!todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null) {
    return null;
  }

  const title = formatMessage('widgets.todosDrawer.section.activateCollaborators.title');
  const description = [{ label: formatMessage('widgets.todosDrawer.section.activateCollaborators.description') }];

  const action = {
    label: formatMessage('widgets.todosDrawer.section.action.manageCollaborators'),
    onClick: navigateToSettingsCollaborators,
  };

  return (
    <TodoSection
      key={`section-${TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}`}
      icon={<Icon type="user-add" color="brand" />}
      title={title}
      description={description}
      action={action}
      type={TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}
    />
  );
};
