import { FormattedMessage, Navigate } from '@melio/ar-domain';
import { Container, Group, Illustration, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { TabsLayout } from '../../layouts';
import { CustomersTableFlow } from './CustomersTable.flow';
import { InvoicesTableFlow } from './InvoicesTable.flow';
import { useArDashboardTabs, useDashboardFlowNavigation } from './utils';

export type DashboardFlowProps = {
  onError?: ARErrorFunction;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  onCreateInvoice: (props?: { customerId?: string }) => unknown;
  onPreviewInvoice: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onIssueInvoice: (id: string) => unknown;
  createdInvoiceId?: string;
  onVisitSupportSettingsPage: VoidFunction;
};

export const DashboardFlow = forwardRef<DashboardFlowProps>(
  (
    {
      onVisitSupportSettingsPage,
      onEditInvoice,
      onError,
      onCreateInvoice,
      onPreviewInvoice,
      onIssueInvoice,
      createdInvoiceId,
      ...props
    },
    ref
  ) => {
    const { tabs, activeTab, handleTabChange } = useArDashboardTabs();

    const { Paths, DefaultPath, goToInvoicesDashboard } = useDashboardFlowNavigation();

    return (
      <Routes>
        <Route
          element={
            <TabsLayout
              onVisitSupportSettingsPage={onVisitSupportSettingsPage}
              onCreateInvoice={onCreateInvoice}
              tabs={tabs}
              activeTab={activeTab}
              onChange={handleTabChange}
            />
          }
        >
          <Route index element={<Navigate to={DefaultPath} withSearchparams replace closeToast={false} />} />

          <Route
            path={Paths.Invoices}
            element={
              <InvoicesTableFlow
                onPreviewInvoice={onPreviewInvoice}
                createdInvoiceId={createdInvoiceId}
                onEditInvoice={onEditInvoice}
                onIssueInvoice={onIssueInvoice}
                onError={onError}
                {...props}
                ref={ref}
              />
            }
          />

          <Route
            path={Paths.Customers}
            element={
              <CustomersTableFlow
                onCreateInvoice={(customerId: string) => onCreateInvoice({ customerId })}
                onDisplayCustomerInvoices={goToInvoicesDashboard}
                onError={onError}
                {...props}
                ref={ref}
              />
            }
          />

          <Route path={Paths.Estimates} element={<TempEstimatesComp />} />
        </Route>
      </Routes>
    );
  }
);

const TempEstimatesComp = () => (
  <Container data-testid="pay-dashboard-no-search-result" paddingY="xxxl">
    <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical">
      <Illustration type="no-items" size="small" />
      <Text>
        <FormattedMessage id="ar.dashboard.activities.invoiceTable.tabs.estimates.comingSoon" />
      </Text>
    </Group>
  </Container>
);
