import React from 'react';
import { Box } from '@chakra-ui/react';
import { Button, Icon, Text } from '@melio/penny';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { IconType } from '@/partnersConfig.types';
import { EmptyStateListTheme } from './EmptyStateList.component.theme';

type EmptyStateListProps = {
  icon?: IconType;
  title?: string;
  description?: string;
  button?: {
    text: string;
    onClick: VoidFunction;
  };
  secondaryButton?: React.ReactNode;
  testId?: string;
};

export const EmptyStateList = ({
  title,
  description,
  icon: InternalIcon,
  button,
  secondaryButton,
  testId,
}: EmptyStateListProps) => {
  const styles = useAppTheme(EmptyStateListTheme, {});

  const hasButton = button || secondaryButton;

  return (
    <Box sx={styles['container']} data-testid={testId}>
      <Box sx={styles['iconContainer']}>
        {InternalIcon && <InternalIcon />}
        <Box sx={styles['textContainer']}>
          {title && <Text textStyle="body2Semi">{title}</Text>}
          {description && (
            <Text textStyle="body3" color="neutral.darkest">
              {description}
            </Text>
          )}
        </Box>
      </Box>
      {hasButton && (
        <Box sx={styles['buttonsContainer']}>
          {button && (
            <Button
              leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
              variant="primary"
              size={'large'}
              onClick={button.onClick}
              isFullWidth
              label={button.text}
            />
          )}
          {secondaryButton}
        </Box>
      )}
    </Box>
  );
};
