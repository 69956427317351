import { Button, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, useSubscription, useSubscriptionPlan } from '../../../hooks';
import { SUBSCRIPTION_SETTINGS_PLANS_ROUTE } from '../../constants';
import { useSubscriptionRouter } from '../../utils';

export const FreePlanCardHeader = () => {
  const { reactivatePlan, isReactivatedPlan } = useSubscriptionPlan();
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const { planName } = usePlanInfo(subscription?.planId);
  const shouldShowPlanReactivation = isReactivatedPlan(subscription?.planId);

  const freePlanUpgradeTextKey = shouldShowPlanReactivation
    ? 'activities.settings.subscriptionPlanSettings.reactivatePlan.button'
    : 'activities.settings.subscriptionPlanSettings.upgradePlan';

  const onClickHandler = () => {
    if (shouldShowPlanReactivation) {
      // cnacel plan change
    } else {
      goToSubscriptionPlans({ returnUrl: SUBSCRIPTION_SETTINGS_PLANS_ROUTE });
    }
  };

  return (
    <Group width="full" justifyContent="space-between" alignItems="center" spacing="s">
      <Container alignItems="center">
        <Group variant="vertical" spacing="xxxs">
          <Text textStyle="body2Semi" as="h4">
            {planName}
          </Text>
          {shouldShowPlanReactivation && (
            <Text textStyle="body3" color="neutral.darker">
              {formatMessage('activities.settings.subscriptionPlanSettings.reactivatePlan.expireText', {
                date: reactivatePlan.expiredDate,
              })}
            </Text>
          )}
        </Group>
      </Container>

      <Button
        variant="primary"
        data-testid="subscription-basic-plan-upgrade"
        label={formatMessage(freePlanUpgradeTextKey)}
        onClick={onClickHandler}
      />
    </Group>
  );
};
