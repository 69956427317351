import { ActionsDropdownMenu, Card, Group, Icon, Text } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';

import { PaymentFlowOnChangeHandlers } from '../../../../../../types';
import { useDeliveryMethodCardMenuItems } from './useDeliveryMethodCardMenuItems';
import { useDeliveryMethodCardProps } from './useDeliveryMethodCardProps';

type SelectedDeliveryMethodCardProps = {
  selectedVendor: Vendor;
  selectedDeliveryMethod: DeliveryMethod;
  isRecurring: boolean;
  isDisabled: boolean;
  onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'];
  onEditDeliveryMethod: (deliveryMethodType: DeliveryMethodType) => void;
};

export const SelectedDeliveryMethodCard = ({
  selectedVendor,
  selectedDeliveryMethod,
  isRecurring,
  isDisabled,
  onDeliveryMethodChange,
  onEditDeliveryMethod,
}: SelectedDeliveryMethodCardProps) => {
  const { formatMessage } = useMelioIntl();
  const [isActionMenuOpen, actionMenu] = useBoolean(false);
  const { title, description, icon } = useDeliveryMethodCardProps({
    vendor: selectedVendor,
    deliveryMethod: selectedDeliveryMethod,
  });

  const menuItems = useDeliveryMethodCardMenuItems({
    selectedDeliveryMethod,
    deliveryMethods: selectedVendor.deliveryMethods,
    isRecurring,
    onDeliveryMethodChange,
    onEditDeliveryMethod,
  });

  return (
    <Card
      data-testid={`payment-flow-form-delivery-method-card-${selectedDeliveryMethod.id}`}
      variant="flat"
      paddingX="s"
      paddingY="s"
      isDisabled={isDisabled}
    >
      <Group width="full" variant="horizontal" alignItems="center">
        <Icon type={icon} />
        <Group width="full" variant="vertical" spacing="xxxs">
          <Text textStyle="body2Semi" data-testid="payment-flow-form-delivery-method-card-title">
            {title}
          </Text>
          <Text
            data-testid="payment-flow-form-delivery-method-card-description"
            textStyle="body3"
            color="neutral.darker"
          >
            {description}
          </Text>
        </Group>
        {!isDisabled && (
          <ActionsDropdownMenu
            size="large"
            variant="default"
            isOpen={isActionMenuOpen}
            onOpenChange={actionMenu.toggle}
            items={menuItems}
            trigger={
              <Icon
                data-testid="payment-flow-form-delivery-method-card-actions-icon"
                aria-label={formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.actions.aria')}
                type="more-vertical"
                size="small"
              />
            }
          />
        )}
      </Group>
    </Card>
  );
};
