import { Container, Group, Layout } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { FlowHeader } from '../../components/FlowHeader/FlowHeader.component';
import { NewFirmClientForm } from './components/NewClientForm/NewClientForm';
import { NewFirmClientFormFields } from './types';

export const NewFirmClient = () => {
  const { formatMessage } = useMelioIntl();
  const onSuccess = (data: NewFirmClientFormFields) => {
    // TODO: call submit
    // eslint-disable-next-line no-console
    console.log('success', data);
  };

  const onClose = () => {
    // TODO: return back to dashboard
  };

  return (
    <Layout variant="full">
      <Container justifyContent="center" height="full">
        <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '660px' } as never}>
          <FlowHeader title={formatMessage('activities.accountants.newClient.form.header.text')} onClose={onClose} />
          <NewFirmClientForm onSuccess={onSuccess} />
        </Group>
      </Container>
    </Layout>
  );
};
