import { Organization, PaymentRequest } from '@melio/platform-api';

export const getAutoSelectedOrganizationId = (
  organizations: Organization[],
  paymentRequest: PaymentRequest,
): string | undefined => {
  const hasOneOrganization = organizations.length === 1;

  if (hasOneOrganization) {
    return (organizations[0] as Organization).id;
  }

  const isPaymentRequestMatchedToUserOrg =
    !!paymentRequest.organizationId && organizations.some((org) => org.id === paymentRequest.organizationId);

  if (isPaymentRequestMatchedToUserOrg) {
    return paymentRequest.organizationId as string;
  }

  return undefined;
};
