/// <reference types='google.maps' />
import { Form, FormSearchProps } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import usePlacesAutocompleteService from '@melio/react-google-autocomplete/lib/usePlacesAutocompleteService';
import React from 'react';

import { parseGoogleAddress } from './GoogleAddress.utils';

export type GoogleAddressInputProps = Omit<FormSearchProps, 'emptyState' | 'onInputChange' | 'options'>;
export const GoogleAddressInput = forwardRef<GoogleAddressInputProps, 'input'>((props, ref) => {
  const [isPlatformGoogleAutocompleteAddressOnly] = useFeature(FeatureFlags.PlatformFinancingApplicationFlow, false);
  const config = useConfig();
  const { formatMessage } = useMelioIntl();
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesAutocompleteService({
      apiKey: config.services.googleMaps.apiKey,
    });

  return (
    // The ariaLabel and labelProps props are mutually exclusive, meaning that either labelProps or ariaLabel exists, never both simultaneously.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <Form.Search
      {...props}
      data-component="GoogleAddressInput"
      ref={ref}
      onInputChange={(v) =>
        getPlacePredictions({
          input: v,
          types: isPlatformGoogleAutocompleteAddressOnly ? ['address'] : undefined,
        })
      }
      isLoading={isPlacePredictionsLoading}
      emptyState={{ label: formatMessage('form.googleAddress.emptyState') }}
      onChange={(event) => {
        if (!event.target.value) {
          event.target.value = parseGoogleAddress({}) as unknown as string;
          props.onChange?.(event);
          return;
        }
        placesService?.getDetails({ placeId: event.target.value }, (placeResult) => {
          if (placeResult) {
            // a temporary workaround until DS provide a better solution
            event.target.value = (parseGoogleAddress(placeResult) || {}) as unknown as string;
            props.onChange?.(event);
          }
        });
      }}
      options={(placePredictions || []).map((p) => ({ label: p.description || '', value: p.place_id }))}
      filterOptions={(options) => options}
    />
  );
});

GoogleAddressInput.displayName = 'GoogleAddressInput';
