import { InboxItemsApiGetInboxItemsRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { PayDashboardSortingType } from '../../types';
import { getInboxItemVendorName } from './BillsTab.utils';
import { getBillsTabAmountCellInfo } from './components/BillsTabAmountCell';
import { useBillsTabStatusCell } from './components/BillsTabStatusCell';
import { BillsTabItem } from './types';

export const convertBillsTabSortToApiSort = ({
  id,
  sortDirection,
}: NonNullable<PayDashboardSortingType['sortingState']>): InboxItemsApiGetInboxItemsRequest['sort'] => {
  if (id === 'Bill') {
    return [
      { field: 'invoiceNumber', order: sortDirection },
      { field: 'vendor.name', order: 'asc' },
    ];
  }
  if (id === 'amount') {
    return [
      { field: 'amount', order: sortDirection },
      { field: 'vendor.name', order: 'asc' },
    ];
  }
  if (id === 'vendorName') {
    return [{ field: 'vendor.name', order: sortDirection }];
  }
  if (id === 'createdAt') {
    return [
      { field: 'createdAt', order: sortDirection },
      { field: 'vendor.name', order: 'asc' },
    ];
  }
  return [
    { field: 'dueDate', order: sortDirection },
    { field: 'vendor.name', order: 'asc' },
  ];
};

export const useBillsTabAriaLabelContextForVendor = (billsTabItem: BillsTabItem) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const getuseBillsTabStatus = useBillsTabStatusCell();
  const { label: status } = getuseBillsTabStatus({ billsTabItem });

  const { isPartiallyPaid, totalAmount, balance } = getBillsTabAmountCellInfo(billsTabItem);
  const vendorName = getInboxItemVendorName(billsTabItem);
  const amount = formatCurrency(isPartiallyPaid ? balance || 0 : totalAmount || 0);

  return formatMessage('activities.payDashboard.billsTab.table.ariaLabel.context', {
    vendorName,
    status,
    amount,
  });
};
