import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useAutoPayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useEffect } from 'react';

import { MonitoredAction } from '../../../monitoring';
import { AutoPaymentCancellationModalScreen } from './screens/AutoPaymentCancellation.modal-screen';

export type AutoPaymentCancellationModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  vendorName: string;
  vendorId: string;
};

export const AutoPaymentCancellationModalActivity = withAnalyticsContext<AutoPaymentCancellationModalProps>(
  ({ vendorId, vendorName, isOpen, onClose, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { track, createTrackHandler } = useAnalytics();
    const trackAndHandleClick = createTrackHandler<{
      Cta: 'cancel' | 'exit';
    }>('Vendor', 'Click');
    const { startAction, endAction } = useMonitoring<MonitoredAction>();

    const { deleteAutoPayment, isMutating } = useAutoPayment({
      vendorId,
      options: {
        onDelete: () => onDelete(),
        onDeleteError: () => onDeleteError(),
      },
    });

    setAnalyticsProperties({
      PageName: 'cancel-autopay',
      EntryPoint: 'vendor-summary',
      Flow: 'autopay',
      Intent: 'cancel-autopay',
      VendorId: vendorId,
    });

    useEffect(() => {
      if (isOpen) {
        track('Vendor', 'View');
      }
    }, [isOpen, track]);

    const onDelete = () => {
      track('Dashboard', 'Status', { Status: 'success' });
      toast({
        type: 'informative',
        title: formatMessage('activities.payDashboard.autoPaymentCancellationModal.success.toast'),
      });
      endAction('autopay_activation_cancel');
      onClose();
    };

    const onDeleteError = () => {
      track('Dashboard', 'Status', { Status: 'failure' });

      toast({
        type: 'error',
        title: formatMessage('activities.payDashboard.autoPaymentCancellationModal.error.toast'),
      });
      onClose();
    };

    const handleDeleteAutoPayment = () => {
      startAction('autopay_activation_cancel');
      deleteAutoPayment();
    };

    return (
      <AutoPaymentCancellationModalScreen
        isOpen={isOpen}
        onCancel={() => trackAndHandleClick({ Cta: 'cancel' }, handleDeleteAutoPayment)}
        onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
        vendorName={vendorName}
        isLoading={isMutating}
      />
    );
  }
);
