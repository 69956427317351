import { Box } from '@chakra-ui/react';
import { LargeFileUpload } from '@melio/penny';
import { FileType, useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

export type UploadBillWidgetProps = {
  isLoading?: boolean;
  onSelectFile: (file: File | null) => void;
  value?: File | null;
};

export const UploadBillWidget = forwardRef<UploadBillWidgetProps, 'div'>(({ onSelectFile, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { fileSizeLimit, fileAllowedFormats } = useConfig().settings;
  const [invalidState, setInvalidState] = useState<{ isInvalid: boolean; errorMessage?: string }>({
    isInvalid: false,
  });

  const handleSelectFile = (file: File | null) => {
    if (!file) {
      onSelectFile(file);
      return;
    }

    if (file.size > fileSizeLimit) {
      setInvalidState({ isInvalid: true, errorMessage: formatMessage('widgets.uploadBill.validation.fileSize') });
      onSelectFile(null);
      return;
    }

    const fileType = file.type?.split('/')[1] as FileType;
    if (fileType && !fileAllowedFormats.includes(fileType)) {
      setInvalidState({ isInvalid: true, errorMessage: formatMessage('widgets.uploadBill.validation.fileFormat') });
      onSelectFile(null);
      return;
    }

    setInvalidState({ isInvalid: false });
    onSelectFile(file);
  };

  return (
    //TODO: Need to be changed after the Layouts task [ME-25578], for the temp solution we caalculated the size of the container based on (100vh - (toolbarHeight + footerHeight + SplitScreenLayout.SplitContent paddingY * 2 + 1)).
    <Box maxHeight="calc(100vh - 221px)" width="100%" height="100%">
      <LargeFileUpload
        data-component="UploadBillWidget"
        {...props}
        invalid={invalidState}
        onChange={handleSelectFile}
        uploadFileButtonAriaLabel={formatMessage('widgets.uploadBill.browseLabel')}
        placeholder={formatMessage('widgets.uploadBill.browseLabel')}
        deleteFileLabel={formatMessage('widgets.uploadBill.deleteFileLabel')}
        deleteFileButtonAriaLabel={formatMessage('widgets.uploadBill.deleteFileButtonAriaLabel')}
        replaceFileButtonAriaLabel={formatMessage('widgets.uploadBill.replaceFileButtonAriaLabel')}
        ref={ref}
      />
    </Box>
  );
});

UploadBillWidget.displayName = 'UploadBillWidget';
