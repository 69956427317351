import { SectionBanner } from '@melio/penny';
import { BillSubscriptionApprovalDecisionStatusEnum, PaymentFullyExpanded } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';

export const PaymentApprovalPendingAlert = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();

  const isRecurringPaymentPendingApproval =
    payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Pending;

  const alertDescription = formatMessage('widgets.paymentDetails.approvalPending.actionRequired.description', {
    date: formatDate(createDate(payment.scheduledDate.toISOString()), {
      dateStyle: 'medium',
    }),
    recurringPaymentAlert: isRecurringPaymentPendingApproval
      ? formatMessage('widgets.paymentDetails.approvalPending.actionRequired.recurringPayment.description')
      : null,
  });

  return (
    <SectionBanner
      data-testid="payment-pending-approval-alert"
      variant="warning"
      description={alertDescription}
      title={formatMessage('widgets.paymentDetails.approvalPending.actionRequired.title')}
    />
  );
};
