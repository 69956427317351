import { Container, Group, Text } from '@melio/penny';
import { BillInfo, FxCurrency } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

export type BillAmountLineProps = {
  amount: BillInfo['amount'];
  size: 'large' | 'small';
  type: 'balance' | 'total-amount';
  currency?: FxCurrency;
};

export const BillAmountLine = forwardRef<BillAmountLineProps, 'div'>(
  ({ amount, type, size, currency, ...props }, ref) => {
    const { formatCurrency, formatMessage } = useMelioIntl();
    const label =
      type === 'balance'
        ? formatMessage('widgets.billDetails.remainingAmount')
        : formatMessage('widgets.billDetails.totalAmount');
    return (
      <Container data-component="BillAmountLine" ref={ref} {...props}>
        <Group justifyContent="space-between">
          <Text textStyle={size === 'large' ? 'heading2' : 'body2Semi'}>{label}</Text>
          <Text data-testid="bill-amount-line-amount" textStyle={size === 'large' ? 'heading2' : 'body2Semi'}>
            {formatCurrency(amount, currency)}
          </Text>
        </Group>
      </Container>
    );
  }
);

BillAmountLine.displayName = 'BillAmountLine';
