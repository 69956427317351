import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SupportChatIcon = (props: IconProps) => (
  <Icon width="24px" height="22px" viewBox="0 0 24 22" fill="currentColor" {...props}>
    <path
      d="M16 7C16.6 7 17 7.4 17 8C17 8.5 16.6 8.9 16.1 9H8C7.4 9 7 8.6 7 8C7 7.5 7.4 7.1 7.9 7H16Z"
      fill="#212124"
    />
    <path
      d="M13 11C13.6 11 14 11.4 14 12C14 12.5 13.6 12.9 13.1 13H8C7.4 13 7 12.6 7 12C7 11.5 7.4 11.1 7.9 11H13Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 10.0278C24 4.41224 18.6 0 12 0C5.4 0 0 4.41224 0 10.0278C0 15.6434 5.4 20.0556 12 20.0556H12.4C13.3 20.0556 14.2 19.9553 15 19.7548L20.7 21.9609C21.4 22.1615 22.1 21.5598 22 20.8578L21.5 16.1448L21.6 16.0445C23.1 14.3398 24 12.2339 24 10.0278ZM2 10.0278C2 5.61557 6.4 2.00556 12 2.00556C17.6 2.00556 22 5.61557 22 10.0278C22 11.8328 21.2 13.6378 19.7 15.142L19.6 15.2423C19.4 15.4428 19.4 15.6434 19.4 15.9442L19.8 19.4539L15.5 17.6489C15.3 17.5487 15 17.5487 14.8 17.6489C14 17.9498 13.1 18.0501 12 18.0501C6.4 18.0501 2 14.44 2 10.0278Z"
      fill="#212124"
    />
  </Icon>
);
