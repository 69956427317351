import { BadgeProps } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { useMemo } from 'react';

import { UpdatedFields } from '../../../../../single-payment/SinglePaymentFlow/types';

export const useUpdatedFieldsBadge = (updatedFields: string[] = []) => {
  const { formatMessage } = useMelioIntl();

  const updatedBadges: Partial<{ [field in UpdatedFields]: BadgeProps }> = useMemo(
    () =>
      Object.fromEntries(
        updatedFields.map((field) => [
          field,
          {
            label: formatMessage(
              'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.updatedBadge'
            ),
            status: 'success',
          },
        ])
      ),
    [formatMessage, updatedFields]
  );

  return updatedBadges;
};
