import { compact } from 'lodash';

import { getDefaultMemoFromInvoiceNumbers } from '../../../utils/pay-flow/defaultMemo';
import { ExtendedPaymentIntent } from '../../../utils/pay-flow/useExtendedPaymentIntent';

export const getDefaultMemo = (paymentIntent: ExtendedPaymentIntent) => {
  const invoiceNumbers = paymentIntent.billPayments
    ? compact(paymentIntent.billPayments.map((billPayment) => billPayment.invoiceNumber))
    : paymentIntent.billInfo?.invoice.number
    ? [paymentIntent.billInfo.invoice.number]
    : undefined;

  return getDefaultMemoFromInvoiceNumbers(invoiceNumbers || []);
};
