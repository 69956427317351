import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Group, SelectionCard, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { GuestPayorFundingSourceTypes } from '../types';

export type FundingSourceSelectionProps = {
  selectedFundingSource?: GuestPayorFundingSourceTypes;
  onCardSelected: VoidFunction;
  onBankSelected: VoidFunction;
};

export const FundingSourceSelection = forwardRef<FundingSourceSelectionProps>(
  ({ onBankSelected, onCardSelected, selectedFundingSource }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Group variant="vertical" spacing="m" ref={ref}>
        <Text textStyle="body2Semi" color="neutral.black">
          <FormattedMessage id="ar.guestPayment.paymentMethods.tabs.title.text" />
        </Text>
        <Group>
          <SelectionCard
            icon="credit-card"
            data-testid="card-funding-source-tab"
            mainLabelProps={{
              label: formatMessage('ar.guestPayment.paymentMethods.tabs.card.label'),
            }}
            onClick={onCardSelected}
            isSelected={selectedFundingSource === 'card'}
          />
          <SelectionCard
            icon="bank"
            data-testid="bank-funding-source-tab"
            mainLabelProps={{
              label: formatMessage('ar.guestPayment.paymentMethods.tabs.bank.label'),
            }}
            onClick={onBankSelected}
            isSelected={selectedFundingSource === 'bank-account'}
          />
        </Group>
      </Group>
    );
  }
);

FundingSourceSelection.displayName = 'SelectFundingSource';
