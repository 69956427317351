import { Box } from '@chakra-ui/react';
import { AddPlaidAccountWidget } from '@melio/ap-widgets';
import { LoadingContainer } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { PlaidAccountData } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type BankIntegrationPlaidLinkingScreenProps = {
  onClose: VoidFunction;
  onError?: ErrorFunction;
  onSuccess: (data: PlaidAccountData) => void;
  isLoading: boolean;
  fundingSourceId?: string;
};

export const BankIntegrationPlaidLinkingScreen = forwardRef<BankIntegrationPlaidLinkingScreenProps, 'iframe'>(
  ({ onClose, onError, onSuccess, isLoading: isRequestLoading, fundingSourceId, ...props }, ref) => {
    const [isPlaidUiLoading, loading] = useBoolean(true);
    const isCreatingBankAccountMode = !fundingSourceId;
    const isLoading = isCreatingBankAccountMode && (isRequestLoading || isPlaidUiLoading);
    useAnalyticsView('BankFundingPlaid', !isLoading);

    return (
      <LoadingContainer isLoading={isLoading}>
        <NewSinglePaymentStepLayout
          data-component="AddBankAccountPlaidActivity.BankIntegrationPlaidLinkingScreen"
          data-testid="add-bank-account-plaid-activity-bank-integration-plaid-linking-screen"
          paddingContent={{ lineHeight: 'none' }}
          {...props}
          ref={ref}
        >
          <NewSinglePaymentStepLayout.Content>
            <Box hidden={isLoading}>
              <AddPlaidAccountWidget
                onSuccess={onSuccess}
                onExit={onClose}
                onLoad={loading.off}
                onError={onError}
                fundingSourceId={fundingSourceId}
              />
            </Box>
          </NewSinglePaymentStepLayout.Content>
        </NewSinglePaymentStepLayout>
      </LoadingContainer>
    );
  }
);

BankIntegrationPlaidLinkingScreen.displayName = 'AddBankAccountPlaidActivity.BankIntegrationPlaidLinkingScreen';
