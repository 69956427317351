import { Badge, Container, Group, Text, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';
import { useLoanStatus } from './FinancingTabStatusCell';

export type FinancingTabListItemProps = {
  loansTabItem: LoansTabItem;
};

export const FinancingTabListItem = ({ loansTabItem }: FinancingTabListItemProps) => {
  const { formatDate, formatMessage, formatCurrency } = useMelioIntl();
  const {
    loan: {
      status,
      nextInstallmentDate,
      nextInstallmentAmount,
      fees: { totalAmount },
      installments,
      nextInstallmentNumber,
    },
    vendor,
  } = loansTabItem;
  const { label, status: displayStatus } = useLoanStatus(loansTabItem.loan);

  const shouldShowNextInstallmentDetails = status === 'active' || status === 'failed';

  return (
    <Container paddingX="m" overflow="initial" data-testid="financing-tab-list-item">
      <Group variant="horizontal" justifyContent="space-between">
        <Group variant="vertical" spacing="xs">
          <Typography.MainLabel label={vendor.name} variant="bold" />
          {shouldShowNextInstallmentDetails && nextInstallmentDate && (
            <Text color="neutral.darkest" textStyle="body4">
              {formatMessage('activities.payDashboard.financingTab.table.headers.nextDebit.mobile', {
                nextDebitDate: formatDate(nextInstallmentDate, { dateStyle: 'medium' }),
              })}
            </Text>
          )}
          <Badge type="secondary" label={label} status={displayStatus} />
        </Group>
        <Group variant="vertical" spacing="xs" alignItems="flex-end">
          <Text textStyle="body2Semi">{formatCurrency(totalAmount || 0)}</Text>
          {shouldShowNextInstallmentDetails && (
            <>
              <Text textStyle="body4">
                {formatMessage('activities.payDashboard.financingTab.table.cells.amount.mobile.nextInstallment', {
                  nextInstallmentAmount: formatCurrency(nextInstallmentAmount),
                })}
              </Text>
              <Typography.Description
                label={formatMessage('activities.payDashboard.financingTab.table.cells.nextInstallment.description', {
                  installmentNumber: nextInstallmentNumber,
                  totalInstallments: installments.length,
                })}
              />
            </>
          )}
        </Group>
      </Group>
    </Container>
  );
};
