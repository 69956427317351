import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { CalendarLegendProps, Container, Group, Text, Tooltip, UseMelioFormResults } from '@melio/penny';
import { DeliveryMethod, DeliveryPreference, FundingSource, Vendor } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../../../types';
import { DateField } from '../../../components/DateField';
import { useDeliveryDateTooltipText } from './useDeliveryDateTooltipText';

type DeliveryDateInputProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  vendor: Vendor | undefined;
  fundingSource: FundingSource | undefined;
  deliveryMethod: DeliveryMethod | undefined;
  deliveryPreference: DeliveryPreference | undefined;
  onChange: PaymentFlowOnChangeHandlers['onDeliveryDateChange'];
  isLoading: boolean;
};

export const DeliveryDateInput = ({
  form,
  vendor,
  fundingSource,
  deliveryMethod,
  deliveryPreference,
  onChange,
  isLoading,
}: DeliveryDateInputProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { maxDeliveryDate } = usePaymentSchedulingPreference();
  const { tooltipText } = useDeliveryDateTooltipText({ vendor, deliveryMethod, fundingSource });
  const { registerField, watch } = form;

  const [deliveryDate, scheduleDate] = watch(['deliveryDate', 'scheduleDate']);

  const legendItems: CalendarLegendProps[] = [
    {
      label: formatMessage(`activities.paymentFlow.form.content.deliveryDate.legend.today`),
      variant: 'today',
    },
    {
      label: formatMessage(`activities.paymentFlow.form.content.deliveryDate.legend.secondary`),
      variant: 'secondary',
    },
    {
      label: formatMessage(`activities.paymentFlow.form.content.deliveryDate.legend.primary`),
      variant: 'primary',
    },
  ];

  const labelKey: MessageKey =
    deliveryMethod?.type === 'paper-check'
      ? 'activities.paymentFlow.form.content.deliveryDate.label.check'
      : 'activities.paymentFlow.form.content.deliveryDate.label';

  return (
    <Tooltip label={tooltipText} isEnabled={!!tooltipText} shouldAddTriggerFocus>
      <Container width="full" overflow="visible">
        <Group variant="vertical" width="full" spacing="xs-s">
          <DateField
            {...registerField('deliveryDate')}
            secondarySelectedDate={scheduleDate}
            labelProps={{ label: formatMessage(labelKey) }}
            placeholder={formatMessage('activities.paymentFlow.form.content.deliveryDate.placeholder')}
            legendItems={legendItems}
            minDate={deliveryPreference?.earliestDeliveryDate}
            maxDate={maxDeliveryDate}
            isDisabled={!!tooltipText}
            isLoading={isLoading}
            value={deliveryDate}
            onChange={onChange}
            excludeHolidays
            hideClear
            showToday
            selectedDateAriaLabel={formatMessage('activities.paymentFlow.form.content.deliveryDate.selected.aria')}
          />
          {deliveryDate && deliveryPreference?.effectiveScheduleDate && (
            <Text textStyle="body2" color="neutral.darker" data-testid="deliveryDate-debitDate-text">
              {formatMessage('activities.paymentFlow.form.content.deliveryDate.helperText', {
                debitDate: <Text>{formatDate(deliveryPreference.effectiveScheduleDate, { dateStyle: 'medium' })}</Text>,
              })}
            </Text>
          )}
        </Group>
      </Container>
    </Tooltip>
  );
};
