import { useNonPositiveAmountsEffect } from '@melio/ap-domain';
import { useToast } from '@melio/penny';
import {
  BillSubscription,
  BillSubscriptionCreateErrorCode,
  Payment,
  PaymentApprovalDecisionStatusEnum,
  PaymentCreateErrorCode,
  useBill,
  usePayments,
  useVendor,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import React, { ComponentProps } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { KycComplianceFormState } from '../../complete-required-details';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { PaymentScheduledActivity } from '../../PaymentScheduled';
import { RecurringPaymentScheduledActivity } from '../../RecurringPaymentScheduled';
import { PaymentFlowDoneAction } from '../../types';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { useCreatePaymentSubmit } from './useCreatePaymentSubmit';

type CreatePaymentActivityProps = {
  billId?: string;
  vendorId?: string;
  deliveryMethodId?: string;
  amountToPay?: string;
  onClose: VoidFunction;
  onDone: (id: (Payment | BillSubscription)['id'], action: PaymentFlowDoneAction, isPendingApproval: boolean) => void;
  missingKycComplianceInformation: boolean;
  setKycComplianceFormState: (state: KycComplianceFormState) => void;
};

export const CreatePaymentActivity = ({
  billId,
  vendorId,
  deliveryMethodId,
  amountToPay,
  onClose,
  onDone,
  missingKycComplianceInformation,
  setKycComplianceFormState,
}: CreatePaymentActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const navigate = useNavigate();
  const {
    submit,
    submitting: isSubmitting,
    result: paymentOrSubscription,
  } = useCreatePaymentSubmit({
    billId,
    onError: (error) => {
      const code = error.errorCode;
      const errorKeyMap: Partial<Record<PaymentCreateErrorCode | BillSubscriptionCreateErrorCode, MessageKey>> = {
        duplicatePayment: 'activities.paymentFlow.errors.duplicatePayment',
        duplicateBillSubscription: 'activities.paymentFlow.errors.duplicatePayment',
      };

      const key: MessageKey = errorKeyMap[code as keyof typeof errorKeyMap] || 'activities.paymentFlow.errors.default';

      toast({
        type: 'error',
        title: formatMessage(key),
      });
    },
  });

  const { data: [payment] = [], isLoading: isPaymentsLoading } = usePayments({
    params: {
      limit: 1,
      expand: 'none',
      sort: [{ field: 'createdAt', order: 'desc' }],
    },
  });

  const { data: bill, isLoading: isBillLoading } = useBill({
    enabled: Boolean(billId),
    id: billId || 'CreatePaymentActivityBillId',
    params: { expand: 'none' },
  });

  const defaultVendorId = bill?.vendorId || vendorId;

  const { data: vendor, isLoading: isVendorLoading } = useVendor({
    enabled: Boolean(defaultVendorId),
    id: defaultVendorId || 'CreatePaymentActivityVendorId',
  });

  const defaultValues: ComponentProps<typeof PaymentFlowActivity>['defaultValues'] = {
    vendorId: vendor?.id,
    vendorEmail: vendor?.contact.email,
    deliveryMethodId,
    amountToPay: bill?.balance.toString() || amountToPay,
    noteToVendor: bill?.invoice.number
      ? formatMessage('activities.paymentFlow.form.content.defaultMemo', {
          invoiceNumber: bill.invoice.number,
        })
      : undefined,
    fundingSourceId: payment?.fundingSourceId,
  };

  useNonPositiveAmountsEffect(onClose, [bill?.balance]);

  if (isPaymentsLoading || isBillLoading || isVendorLoading) {
    return <NewSinglePaymentStepLayout isLoading />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            billId={billId}
            title={formatMessage('activities.paymentFlow.form.header.title.newPayment')}
            isSubmitting={isSubmitting}
            onSubmit={(data) => submit(data).then(() => navigate('success', { replace: true }))}
            onClose={onClose}
            missingKycComplianceInformation={missingKycComplianceInformation}
            setKycComplianceFormState={setKycComplianceFormState}
          />
        }
      />
      <Route
        path="/success"
        element={
          paymentOrSubscription ? (
            isBillSubscription(paymentOrSubscription) ? (
              <RecurringPaymentScheduledActivity
                billSubscriptionId={paymentOrSubscription.id}
                onClose={onClose}
                onDone={(action) =>
                  onDone(
                    paymentOrSubscription.id,
                    action,
                    paymentOrSubscription.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending
                  )
                }
              />
            ) : (
              <PaymentScheduledActivity
                paymentsIds={[paymentOrSubscription.id]}
                onClose={onClose}
                onDone={(action: PaymentFlowDoneAction) =>
                  onDone(
                    paymentOrSubscription.id,
                    action,
                    paymentOrSubscription.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending
                  )
                }
              />
            )
          ) : (
            <Navigate to=".." />
          )
        }
      />
    </Routes>
  );
};

const isBillSubscription = (
  paymentOrBillSubscription: Payment | BillSubscription
): paymentOrBillSubscription is BillSubscription => 'intervalType' in paymentOrBillSubscription;
