import { useMelioQueryClient } from '@melio/api-client';
import { CustomersApiClient, CustomersApiInstance } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseCustomersProps = UseCollectionProps<
  typeof CustomersApiInstance.getCustomers,
  typeof CustomersApiClient.postCustomer,
  typeof CustomersApiClient.updateCustomerById
>;

export const useCustomers = ({ onCreate, onUpdate, ...props }: UseCustomersProps = {}) => {
  const queryClient = useMelioQueryClient();
  return useCollection({
    ...props,
    queryKey: 'CustomersApi',
    queryFn: CustomersApiInstance.getCustomers,
    createFn: CustomersApiClient.postCustomer,
    deleteFn: CustomersApiClient.deleteCustomerCustomerId,
    updateFn: CustomersApiClient.updateCustomerById,
    populateModels: true,
    onUpdate: (...args) => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void onUpdate?.(...args);
    },
    onCreate: (...args) => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void onCreate?.(...args);
    },
  });
};

export type CustomerCollection = ReturnType<typeof useCustomers>;
