/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAccountingPlatformName } from '@melio/ap-widgets';
import {
  AccountingPlatform,
  Bill,
  CardNetwork,
  CountryInternationalDeliveryDetails,
  DeliveryMethod,
  DeliveryMethodType,
  FundingSource,
  FundingSourceType,
  Payment,
  PaymentIntent,
  useDeliveryMethod,
  useInternationalCountriesDetails,
  usePayments,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { groupBy, sumBy, uniq } from 'lodash';
import { useEffect, useState } from 'react';

import { getIsReconciliationNeeded, useReconciliation } from '../../../funding-sources';
import { useVendorDetailsModal } from '../../../funding-sources/FundingSourceSelection/useVendorDetailsModal.hook';
import {
  isPaymentIntentsRequireInvoice,
  usePaymentPurposeShowFields,
} from '../../../international-payment/payment-purpose/PaymentPurpose.utils';
import { SchedulePaymentIntent } from '../../types';

export const getIsMissingPaymentPurpose = (deliveryMethod?: DeliveryMethod, paymentPurpose?: string) =>
  deliveryMethod?.type === DeliveryMethodType.InternationalAccount && (paymentPurpose === undefined || !paymentPurpose);

const getIsMissingBillsInvoices = (
  vendorId: string,
  payments: Payment[],
  bills: Bill[],
  deliveryMethod?: DeliveryMethod,
  countryDetailsData?: CountryInternationalDeliveryDetails[]
) =>
  deliveryMethod?.type === DeliveryMethodType.InternationalAccount &&
  isPaymentIntentsRequireInvoice({
    bills,
    payments,
    internationalCountryCode: deliveryMethod?.details?.identifierDetails.bankCountryCode,
    countryDetailsData,
    vendorId,
  });

export const getNumberOfBills = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  sumBy(paymentIntentsWithDerivatives, ({ bills }) => bills.length);

type GetFundingSourcePopoverProps = {
  selectedFundingSource?: FundingSource;
  vendor: Vendor;
  activeAccountingPlatform?: AccountingPlatform;
  onOpenReconciliationModal: VoidFunction;
  onOpenVendorDetailsModal: VoidFunction;
};

export const useGetFundingSourceWarning = ({
  selectedFundingSource,
  vendor,
  activeAccountingPlatform,
  onOpenReconciliationModal,
  onOpenVendorDetailsModal,
}: GetFundingSourcePopoverProps) => {
  const { formatMessage } = useMelioIntl();

  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const { isVendorHasMissingDetails } = useVendorDetailsModal();

  if (!selectedFundingSource) {
    return { shouldShowWarning: false };
  }

  const shouldShowReconciliationWarning = getIsReconciliationNeeded(activeAccountingPlatform, selectedFundingSource);
  const isVendorMissingDetails = isVendorHasMissingDetails({ vendor, selectedFundingSource });

  if (isVendorMissingDetails) {
    const descriptionMessage =
      selectedFundingSource.type === FundingSourceType.Card &&
      selectedFundingSource.details.network === CardNetwork.Amex
        ? 'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.missingVendorDetails.popoverDescription.amex'
        : 'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.missingVendorDetails.popoverDescription.visa';
    return {
      popoverProps: {
        description: formatMessage(descriptionMessage),
        actionProps: {
          label: formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.missingVendorDetails.popoverActionLabel'
          ),
          onClick: onOpenVendorDetailsModal,
        },
        'data-testid': `missing-vendor-details-funding-source-${
          (selectedFundingSource as unknown as FundingSource).id
        }-cell`,
      },
      shouldShowWarning: true,
    };
  }

  if (shouldShowReconciliationWarning) {
    return {
      popoverProps: {
        description: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.reconciliationNeeded.popoverDescription',
          {
            accountingPlatform: accountingPlatformName,
          }
        ),
        actionProps: {
          label: formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.reconciliationNeeded.popoverActionLabel'
          ),
          onClick: onOpenReconciliationModal,
        },
        'data-testid': `reconciliation-needed-funding-source-${
          (selectedFundingSource as unknown as FundingSource).id
        }-cell`,
      },
      shouldShowWarning: true,
    };
  }
  return { shouldShowWarning: false };
};

type UseGetDeliveryMethodWarningProps = {
  shouldDisplayStatus?: boolean;
  selectedDeliveryMethodId?: string;
  paymentIntent: PaymentIntent;
  onOpenPaymentPurposeModal?: VoidFunction;
};

type UseInvoiceFileWarningProps = {
  bill: Bill;
  onOpenAttachInvoiceModal?: VoidFunction;
  internationalCountryCode?: string;
};

type TooltipProps = {
  title?: string;
  label: string;
};

type PopoverActionProps = {
  label: string;
  onClick: VoidFunction;
};

type PopoverProps = {
  description: string;
  actionProps: PopoverActionProps;
  'data-testid'?: string;
};
type UseGetDeliveryMethodWarningReturnValue = {
  shouldShowWarning: boolean;
  tooltipProps?: TooltipProps;
  popoverProps?: PopoverProps;
};

type VendorPayments = {
  [key: string]: Payment[];
};

export const useGetDeliveryMethodWarning = ({
  shouldDisplayStatus,
  selectedDeliveryMethodId,
  paymentIntent,
  onOpenPaymentPurposeModal,
}: UseGetDeliveryMethodWarningProps) => {
  const componentPrefix = 'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell';

  const { formatMessage } = useMelioIntl();
  const [warningInfo, setWarningInfo] = useState<UseGetDeliveryMethodWarningReturnValue>({
    shouldShowWarning: false,
  });
  const { data: deliveryMethod } = useDeliveryMethod({
    id: selectedDeliveryMethodId,
    enabled: selectedDeliveryMethodId !== undefined,
  });

  const internationalCountryCode =
    deliveryMethod?.type === 'international-account'
      ? deliveryMethod.details?.identifierDetails.bankCountryCode
      : undefined;

  const combinedBillsIntent = (paymentIntent?.billPayments?.length || 1) > 1;

  const { shouldShowInvoice } = usePaymentPurposeShowFields({
    vendorId: paymentIntent.billInfo.vendorId,
    hasInvoice: !!paymentIntent?.billInfo.invoice.fileId,
    internationalCountryCode,
    currency: paymentIntent.billInfo.currency,
  });

  useEffect(() => {
    if (shouldDisplayStatus && !selectedDeliveryMethodId) {
      setWarningInfo({
        shouldShowWarning: true,
        tooltipProps: {
          label: formatMessage(`${componentPrefix}.missingDeliveryMethodTooltip`),
        },
      });
    } else if (deliveryMethod?.isManaged) {
      setWarningInfo({
        shouldShowWarning: true,
        tooltipProps: {
          label: formatMessage(`${componentPrefix}.managedDeliveryMethodTooltip`),
        },
      });
    } else if (
      onOpenPaymentPurposeModal &&
      ((deliveryMethod?.type === DeliveryMethodType.InternationalAccount && !paymentIntent?.paymentPurpose) ||
        (!combinedBillsIntent && shouldShowInvoice))
    ) {
      setWarningInfo({
        shouldShowWarning: true,
        popoverProps: {
          description: formatMessage(
            `${componentPrefix}.missingInternationalDeliveryMethodDetailsPopover.popoverDescription`
          ),
          actionProps: {
            label: formatMessage(
              `${componentPrefix}.missingInternationalDeliveryMethodDetailsPopover.popoverActionLabel`
            ),
            onClick: onOpenPaymentPurposeModal,
          },
          'data-testid': `paymentPurpose-needed-international-delivery-method-cell`,
        },
      });
    } else {
      setWarningInfo({
        shouldShowWarning: false,
      });
    }
  }, [shouldDisplayStatus, selectedDeliveryMethodId, deliveryMethod, paymentIntent]);

  return { warningInfo };
};

export const useUnreadyPaymentIntents = (
  paymentIntentsWithDerivatives: SchedulePaymentIntent[],
  fundingSources: FundingSource[]
) => {
  const { isReconciliationNeeded } = useReconciliation();
  const { data: countryDetailsData } = useInternationalCountriesDetails();
  const { isVendorHasMissingDetails } = useVendorDetailsModal();

  const vendorIds = uniq(paymentIntentsWithDerivatives.map(({ vendor }) => vendor.id));
  let vendorPayments: VendorPayments = {};

  const { data: payments = [], isLoading: isLoadingPayments } = usePayments({
    params: {
      search: {
        'vendor.id': vendorIds,
      },
    },
  });

  if (!isLoadingPayments && payments.length > 0) {
    vendorPayments = groupBy(payments, ({ vendorId }) => vendorId);
  }

  return paymentIntentsWithDerivatives.filter(({ paymentIntent, vendor, bills }) => {
    const selectedFundingSource = fundingSources.find((fs) => fs.id === paymentIntent.fundingSourceId);
    const selectedDeliveryMethod = vendor.deliveryMethods.find((dm) => dm.id === paymentIntent.deliveryMethodId);
    const isVendorMissingDetails = selectedFundingSource
      ? isVendorHasMissingDetails({ vendor, selectedFundingSource })
      : false;

    return (
      !paymentIntent.fundingSourceId ||
      !paymentIntent.deliveryMethodId ||
      (!paymentIntent.scheduledDate && !paymentIntent.deliveryDate) ||
      !paymentIntent.selectedDeliveryPreferenceType ||
      isReconciliationNeeded(selectedFundingSource) ||
      getIsMissingPaymentPurpose(selectedDeliveryMethod, paymentIntent.paymentPurpose) ||
      getIsMissingBillsInvoices(
        vendor.id,
        vendorPayments[vendor.id] || [],
        bills,
        selectedDeliveryMethod,
        countryDetailsData
      ) ||
      isVendorMissingDetails
    );
  });
};

export const useInvoiceFileWarning = ({
  bill,
  internationalCountryCode,
  onOpenAttachInvoiceModal,
}: UseInvoiceFileWarningProps) => {
  const componentPrefix =
    'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodSubCell.missingInvoicePopover';

  const { formatMessage } = useMelioIntl();
  const [warningInfo, setWarningInfo] = useState<UseGetDeliveryMethodWarningReturnValue>({
    shouldShowWarning: false,
  });

  const { shouldShowInvoice } = usePaymentPurposeShowFields({
    vendorId: bill.vendorId,
    hasInvoice: !!bill.invoice.fileId,
    internationalCountryCode,
    currency: bill.currency,
  });

  useEffect(() => {
    if (shouldShowInvoice && onOpenAttachInvoiceModal) {
      setWarningInfo({
        shouldShowWarning: true,
        popoverProps: {
          description: formatMessage(`${componentPrefix}.popoverDescription`),
          actionProps: {
            label: formatMessage(`${componentPrefix}.popoverActionLabel`),
            onClick: onOpenAttachInvoiceModal,
          },
          'data-testid': `invoice-needed-international-delivery-method-sub-cell`,
        },
      });
    } else {
      setWarningInfo({
        shouldShowWarning: false,
      });
    }
  }, [shouldShowInvoice]);

  return warningInfo;
};
