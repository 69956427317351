import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CreatePaymentActivity, PaymentFlowDoneAction } from '@melio/ap-activities';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';
import { useNavigate } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { useIsMissingKycComplianceInformation } from '@/hooks/useIsMissingKycComplianceInformation';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const CreatePaymentScreen = ({
  vendorId,
  deliveryMethodId,
  amountToPay,
  returnUrl,
}: {
  vendorId?: string;
  deliveryMethodId?: string;
  amountToPay?: string;
  returnUrl?: string;
}) => {
  const { replaceToPayDashboardTab, generateNPEDashboardLink } = useRouter();
  const navigate = useNavigate();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { track } = useAnalytics();

  const { missingKycComplianceInformation, onKycComplianceFormDone, setKycComplianceFormState } =
    useIsMissingKycComplianceInformation();

  const [search] = useSearchParams();
  const billId = search.get('billId') ?? undefined;

  const handleClose = useCallback(() => {
    if (returnUrl) {
      return navigate(returnUrl, { state: { keepToast: true } });
    }
    replaceToPayDashboardTab(PayDashboardTabs.Inbox, billId, { keepToast: true });
  }, [billId, replaceToPayDashboardTab, navigate, returnUrl]);

  const handleDone = (paymentId: string, action: PaymentFlowDoneAction, isPendingApproval: boolean) => {
    isPendingApproval
      ? toast({
          type: 'informative',
          title: formatMessage('screens.scheduleSinglePayment.toast.success.single.pending.title'),
          action: {
            type: 'button',
            text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
            onAction: (closeToast) => {
              navigate(generateNPEDashboardLink('payments'));
              track('Dashboard', 'Click', {
                PageName: 'payment-pending-approval',
                Flow: 'dashboard',
                Intent: 'view-approvals',
                Status: 'success',
                Cta: 'view-payment',
                PaymentId: paymentId,
              });
              closeToast();
            },
          },
        })
      : toast({
          type: 'success',
          title: formatMessage('screens.scheduleSinglePayment.toast.success.single.title'),
          action: {
            type: 'button',
            text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
            onAction: (closeToast) => {
              navigate(generateNPEDashboardLink('payments'));
              closeToast();
            },
          },
        });

    onKycComplianceFormDone();
    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, paymentId, { keepToast: true });
  };

  return (
    <CreatePaymentActivity
      billId={billId}
      vendorId={vendorId}
      deliveryMethodId={deliveryMethodId}
      amountToPay={amountToPay}
      onClose={handleClose}
      onDone={handleDone}
      missingKycComplianceInformation={missingKycComplianceInformation}
      setKycComplianceFormState={setKycComplianceFormState}
    />
  );
};
