import { useCallback, useContext } from 'react';

import { Feature } from '../api';
import { SubscriptionContext } from '../context';
import { QuantityBasedFeature } from '../utils/planFeatures.utils';
import { useIsFeatureInSubscription } from './useIsFeatureInSubscription';

type TryUseFeature = (param: { onFeatureIsEligible?: () => unknown; onFeatureIsBlocked?: () => unknown }) => void;

type FeatureEligibilityData = {
  isEligible: boolean;
  tryUseFeature: TryUseFeature;
};

type QuantityBasedFeatureEligibilityData = FeatureEligibilityData & {
  quota: { freeUnitsLimit: number; excessUnitFee?: number };
};

type BooleanBasedFeatureEligibilityData = FeatureEligibilityData;

export function useSubscriptionFeature<T extends Feature>(
  feature: Parameters<typeof useIsFeatureInSubscription<T>>[0]
): T extends QuantityBasedFeature ? QuantityBasedFeatureEligibilityData : BooleanBasedFeatureEligibilityData;
export function useSubscriptionFeature(
  feature: Parameters<typeof useIsFeatureInSubscription>[0]
): QuantityBasedFeatureEligibilityData | BooleanBasedFeatureEligibilityData {
  const {
    contextualPaywall: { showPaywall },
  } = useContext(SubscriptionContext);

  const eligibilityData = useIsFeatureInSubscription(feature);

  const tryUseFeature = useCallback(
    ({
      onFeatureIsEligible,
      onFeatureIsBlocked,
    }: {
      onFeatureIsEligible?: () => unknown;
      onFeatureIsBlocked?: () => unknown;
    }) => {
      if (eligibilityData.isEligible) {
        onFeatureIsEligible?.();
        return;
      }

      showPaywall({ onDismiss: onFeatureIsBlocked, ...feature });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(feature), eligibilityData.isEligible, showPaywall]
  );

  return {
    isEligible: eligibilityData.isEligible,
    ...('quota' in eligibilityData ? { quota: eligibilityData.quota } : {}),
    tryUseFeature,
  };
}
