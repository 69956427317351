import { SelectionCard, Tooltip } from '@melio/penny';
import { Card, FundingSource } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import noop from 'lodash/noop';

import { getFundingSourceIconByType, UnsupportedFundingSourceTypeReasons } from '../../../utils';
import { useAddFundingSourceCardHelperTexts } from './AddFundingSourceCard.utils';

export type AddFundingSourceCardProps = {
  fundingType: FundingSource['type'];
  cardType?: Card['type'];
  onClick?: VoidFunction;
  isDisabled?: boolean;
  disabledReasons?: UnsupportedFundingSourceTypeReasons[];
};

export const AddFundingSourceCard = forwardRef<AddFundingSourceCardProps, 'div'>(
  ({ fundingType, cardType, onClick = noop, isDisabled, disabledReasons, ...props }, ref) => {
    const { settings } = useConfig();
    const { formatMessage } = useMelioIntl();
    const fsIcon = getFundingSourceIconByType({ fundingType, bankIcon: settings.icons.fundingSource.bank, cardType });
    const { getAddFundingSourceHelperTextByType, getAddFundingSourceContentTextByType } =
      useAddFundingSourceCardHelperTexts();

    const tooltip =
      isDisabled && disabledReasons?.length === 1 && disabledReasons[0] === 'internationalPaymentRestrictions'
        ? formatMessage(
            'widgets.fundingSources.selectFundingSourceCard.card.disabledReason.cardFundingSourceNotSupported'
          )
        : false;

    const card = (
      <SelectionCard
        data-component="AddFundingSourceCard"
        data-testid={`add-${fundingType as string}-funding-source-card`}
        {...props}
        ref={ref}
        icon={fsIcon}
        mainLabelProps={{ label: getAddFundingSourceContentTextByType(fundingType, cardType) }}
        descriptionProps={{ label: getAddFundingSourceHelperTextByType(fundingType, cardType) }}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    );

    return tooltip ? (
      <Tooltip
        data-testid={`add-${fundingType as string}-funding-source-card-tooltip`}
        label={tooltip}
        shouldAddTriggerFocus={isDisabled}
      >
        {card}
      </Tooltip>
    ) : (
      card
    );
  }
);

AddFundingSourceCard.displayName = 'AddFundingSourceCard';
