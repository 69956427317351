import { Payment, usePayment } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { ComponentProps } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { KycComplianceFormState } from '../../complete-required-details';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { PaymentScheduledSuccessfullyActivity } from '../../PaymentScheduled';
import { PaymentFlowDoneAction } from '../../types';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { PaymentFlowFormFields } from '../types';

type EditPaymentActivityProps = {
  paymentId: string;
  isLoading: boolean;
  onClose: VoidFunction;
  onDone: (id: Payment['id'], action: PaymentFlowDoneAction, isPendingApproval: boolean) => void;
  missingKycComplianceInformation: boolean;
  setKycComplianceFormState: (state: KycComplianceFormState) => void;
};

export const EditPaymentActivity = ({
  paymentId,
  isLoading,
  onClose,
  onDone,
  missingKycComplianceInformation,
  setKycComplianceFormState,
}: EditPaymentActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const navigate = useNavigate();
  const [addDeliveryDateToCreateAndUpdate] = useFeature<boolean>(
    FeatureFlags.AddDeliveryDateToCreateAndUpdatePayment,
    false
  );

  const {
    data: payment,
    update: updatePayment,
    isMutating: isUpdatingPayment,
    isLoading: isPaymentLoading,
  } = usePayment({ id: paymentId || 'EditPaymentActivityPaymentId' });

  const defaultValues: ComponentProps<typeof PaymentFlowActivity>['defaultValues'] = {
    vendorId: payment?.vendorId,
    deliveryMethodId: payment?.deliveryMethodId,
    fundingSourceId: payment?.fundingSourceId,
    amountToPay: payment?.amount?.toString(),
    deliveryDate: payment?.estimatedDelivery,
    deliveryPreference: payment?.deliveryPreferenceType || undefined,
    vendorEmail: payment?.vendor?.contact.email,
    noteToVendor: payment?.note,
  };

  const onSubmit = (data: PaymentFlowFormFields) =>
    updatePayment({
      amount: Number(data.amountToPay),
      scheduledDate: data.scheduleDate?.toISOString(),
      deliveryDate: addDeliveryDateToCreateAndUpdate ? data.deliveryDate?.toISOString() : undefined,
      fundingSourceId: data.fundingSourceId || void 0,
      deliveryMethodId: data.deliveryMethodId || void 0,
      deliveryPreferenceType: data.deliveryPreference || void 0,
      note: data.noteToVendor || void 0,
      paymentPurpose: data.paymentPurpose,
    }).then(() => navigate('success'));

  if (isLoading || isPaymentLoading) {
    return <NewSinglePaymentStepLayout isLoading />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            payment={payment}
            billId={payment?.bills?.[0]?.id}
            title={formatMessage('activities.paymentFlow.form.header.title.editPayment')}
            isSubmitting={isUpdatingPayment}
            onSubmit={onSubmit}
            onClose={onClose}
            missingKycComplianceInformation={missingKycComplianceInformation}
            setKycComplianceFormState={setKycComplianceFormState}
          />
        }
      />
      <Route
        path="/success"
        element={
          payment && (
            <PaymentScheduledSuccessfullyActivity
              paymentsIds={[payment.id]}
              onClose={onClose}
              onDone={(action: PaymentFlowDoneAction) => onDone(payment.id, action, false)}
              isEditPaymentFlow
            />
          )
        }
      />
    </Routes>
  );
};
