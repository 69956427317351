import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Badge, Button, Text } from '@melio/penny';

export interface SectionHeaderWithButtonProps {
  title: string;
  buttonText?: string;
  badgeText?: React.ReactNode;
  titleAs?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  onClick?: VoidFunction;
}

export const SectionHeaderWithButton = ({
  title,
  onClick,
  badgeText,
  buttonText,
  titleAs = 'h2',
}: SectionHeaderWithButtonProps) => {
  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} height={'40px'}>
      <Flex justifyContent={'left'} alignItems={'center'} my={'2px'} gridGap={'8px'}>
        <Text as={titleAs} textStyle="heading3Semi">
          {title}
        </Text>
        {badgeText && <Badge status={'promotional'} label={badgeText as string} />}
      </Flex>
      {onClick && <Button variant={'tertiary'} onClick={onClick} label={buttonText} />}
    </Flex>
  );
};

SectionHeaderWithButton.displayName = 'SectionHeaderWithButton';
