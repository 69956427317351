import { Icon, IconProps } from '@chakra-ui/react';

export const InvoiceEmailNotificationsIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 17C1 19.51 5.11 21 12 21C18.89 21 23 19.51 23 17C22.9007 15.9132 22.3803 14.9083 21.55 14.2C21.0856 13.8018 20.7082 13.3121 20.4415 12.7615C20.1748 12.2109 20.0245 11.6113 20 11V8C20 5.87827 19.1571 3.84344 17.6569 2.34315C16.1566 0.842855 14.1217 0 12 0C9.87827 0 7.84344 0.842855 6.34315 2.34315C4.84285 3.84344 4 5.87827 4 8V11C3.97548 11.6113 3.82518 12.2109 3.55849 12.7615C3.2918 13.3121 2.91445 13.8018 2.45 14.2C1.61967 14.9083 1.09927 15.9132 1 17ZM7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8V11C18.0212 11.8572 18.2155 12.7013 18.5712 13.4815C18.9269 14.2617 19.4367 14.9619 20.07 15.54C20.65 16.18 21 16.59 21 17C21 17.57 18.32 19 12 19C5.68 19 3 17.57 3 17C3 16.59 3.35 16.18 3.93 15.54C4.56326 14.9619 5.07308 14.2617 5.42881 13.4815C5.78454 12.7013 5.97883 11.8572 6 11V8C6 6.4087 6.63214 4.88258 7.75736 3.75736Z"
        fill="#212124"
      />
      <path
        d="M9.14999 21.9C9.99999 22 11 22 12 22C13 22 14 22 14.86 21.9C14.6651 22.5039 14.2837 23.0304 13.7708 23.4039C13.2578 23.7774 12.6395 23.9786 12.005 23.9786C11.3704 23.9786 10.7522 23.7774 10.2392 23.4039C9.72624 23.0304 9.34489 22.5039 9.14999 21.9Z"
        fill="#212124"
      />
    </svg>
  </Icon>
);
