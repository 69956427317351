import { isCollaboratorBlocked, useRoleText } from '@melio/ap-domain';
import { Badge, Group, Icon, Text, Tooltip } from '@melio/penny';
import { Collaborator, useApprovalWorkflows } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isNull } from 'lodash';

import { useAllowedActions } from '../useAllowedActions';
import { useCollaboratorsApprovalLimit } from './hooks/useCollaboratorsApprovalLimit';

type Props = { actor: Collaborator<'user'>; collaborator: Collaborator<'user'> };
export const CollaboratorListItem = ({ actor, collaborator }: Props) => {
  const { canViewPaymentApprovalLimit } = useAllowedActions();

  const isBlocked = isCollaboratorBlocked(collaborator);
  return (
    <Group justifyContent="space-between" width="full">
      <Group data-testid={`collaborator-list-item-container-${collaborator.id}`} variant="vertical" spacing="xxs">
        <NameSection actor={actor} collaborator={collaborator} isBlocked={isBlocked} />

        <EmailSection collaborator={collaborator} />

        {!isBlocked && canViewPaymentApprovalLimit ? (
          <ApprovalLimitSection collaborator={collaborator} isBlocked={isBlocked} />
        ) : null}

        <RoleSection actor={actor} collaborator={collaborator} />
      </Group>

      <Icon type="chevron-right" size="small" />
    </Group>
  );
};

const NameSection = ({
  actor,
  collaborator,
  isBlocked,
}: Pick<Props, 'actor' | 'collaborator'> & { isBlocked: boolean }) => {
  const { formatMessage } = useMelioIntl();

  const fullName = `${collaborator.user.firstName} ${collaborator.user.lastName}`;
  return (
    <Group spacing="xxxs" data-testid="collaborator-list-item-name-section">
      <Text textStyle="body3Semi" color={isBlocked ? 'neutral.darker' : 'neutral.black'} shouldSupportEllipsis>
        {fullName}
      </Text>

      {collaborator.id === actor.id ? (
        <Text textStyle="body3Semi" color="neutral.black">
          {`${formatMessage('activities.settings.collaborators.table.cells.user.actor')}`}
        </Text>
      ) : null}
    </Group>
  );
};

const EmailSection = ({ collaborator }: Pick<Props, 'collaborator'>) => (
  <Text
    textStyle="body4"
    color="neutral.darker"
    shouldSupportEllipsis
    data-testid="collaborator-list-item-email-section"
  >
    {collaborator.user.email}
  </Text>
);

const ApprovalLimitSection = ({ collaborator, isBlocked }: Pick<Props, 'collaborator'> & { isBlocked: boolean }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const { data: approvalWorkflows } = useApprovalWorkflows();
  const { getApprovalThresholdValue } = useCollaboratorsApprovalLimit({
    approvalWorkflows,
    userId: collaborator.userId,
  });

  const renderContent = () => {
    const thresholdValue = getApprovalThresholdValue();

    if (isBlocked) {
      return null;
    }
    if (isNull(thresholdValue)) {
      return (
        <Text textStyle="body3" color="neutral.700">
          {formatMessage('activities.settings.collaborators.table.cells.approvalLimit.empty')}
        </Text>
      );
    }
    return (
      <Text textStyle="body3" color="neutral.black">
        {formatMessage('activities.settings.collaborators.table.cells.approvalLimit.full', {
          amount: (
            <Text textStyle="body3Semi" color="inherit">
              {formatCurrency(thresholdValue)}
            </Text>
          ),
        })}
      </Text>
    );
  };

  return (
    <Group alignItems="center" width="full" data-testid="collaborator-list-item-approval-limit-section" spacing="xs">
      <Group alignItems="center" textAlign="center" spacing="xxs">
        <Text textStyle="body3" color="neutral.darker">
          {formatMessage('activities.settings.collaborators.table.headers.approvalLimit')}
        </Text>

        <Tooltip label={formatMessage('activities.settings.collaborators.table.headers.approvalLimit.tooltip.content')}>
          <Icon type="info" size="small" />
        </Tooltip>

        <Text textStyle="body3" color="neutral.darker">
          {formatMessage('activities.settings.collaborators.table.headers.approvalLimit.mobile.divider')}
        </Text>
      </Group>

      {renderContent()}
    </Group>
  );
};

const RoleSection = ({ actor, collaborator }: Pick<Props, 'actor' | 'collaborator'>) => {
  const { formatMessage } = useMelioIntl();
  const { getRoleText } = useRoleText();

  return !isCollaboratorBlocked(collaborator) ? (
    <Badge
      type={collaborator.id === actor.id ? 'primary' : 'secondary'}
      status="neutral"
      label={getRoleText(collaborator.roleUniqueName)}
      data-testid="collaborator-list-item-role-section"
    />
  ) : (
    <Text textStyle="body3" color="neutral.700">
      {formatMessage('activities.settings.collaborators.table.cells.role.inactive')}
    </Text>
  );
};
