import { isCollaboratorBlocked } from '@melio/ap-domain';
import { Table, Text } from '@melio/penny';
import { Collaborator, useApprovalWorkflows } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isNull } from 'lodash';

import { useCollaboratorsApprovalLimit } from '../hooks/useCollaboratorsApprovalLimit';

type Props = { collaborator: Collaborator<'user'> };
export const ApprovalLimitCell = ({ collaborator }: Props) => {
  const { data: approvalWorkflows, isLoading: isLoadingApprovalWorkflows } = useApprovalWorkflows({
    refetchOnMount: 'always',
  });
  const { formatCurrency, formatMessage } = useMelioIntl();
  const { userId } = collaborator;
  const { getApprovalThresholdValue } = useCollaboratorsApprovalLimit({ approvalWorkflows, userId });
  const thresholdValue = getApprovalThresholdValue();

  const renderContent = () => {
    if (isCollaboratorBlocked(collaborator)) {
      return null;
    }
    if (isNull(thresholdValue)) {
      return (
        <Text textStyle="body3" color="neutral.700">
          {formatMessage('activities.settings.collaborators.table.cells.approvalLimit.empty')}
        </Text>
      );
    }
    return (
      <Text textStyle="body3" color="neutral.black">
        {formatMessage('activities.settings.collaborators.table.cells.approvalLimit.full', {
          amount: (
            <Text textStyle="body3Semi" color="inherit">
              {formatCurrency(thresholdValue)}
            </Text>
          ),
        })}
      </Text>
    );
  };

  return (
    <Table.Cell
      isLoading={isLoadingApprovalWorkflows}
      textAlign="end"
      data-testid={`collaborators-table-row-${collaborator.id}-approval-limit-cell`}
    >
      {renderContent()}
    </Table.Cell>
  );
};
