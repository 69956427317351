import React, { forwardRef } from 'react';
import { MicroDepositsVerificationModalActivity } from '@melio/ap-activities';

type MicroDepositModalProps = {
  initialValue?: boolean;
  fundingSourceId?: string;
  deliveryMethodId?: string;
  onSuccess?: () => void;
  onError?: (error: PlatformError) => void;
};

export type MicroDepositModalRefHandlers = {
  openModal: () => void;
};
export const MicroDepositModal = forwardRef<MicroDepositModalRefHandlers, MicroDepositModalProps>(
  ({ initialValue, fundingSourceId, deliveryMethodId, onSuccess, onError }, ref) => {
    const [isOpen, setIsOpen] = React.useState(!!initialValue);

    React.useImperativeHandle(ref, () => ({
      openModal: () => setIsOpen(true),
    }));

    return (
      <MicroDepositsVerificationModalActivity
        fundingSourceId={fundingSourceId}
        deliveryMethodId={deliveryMethodId}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDone={() => {
          setIsOpen(false);
        }}
        onSuccessMicroDepositVerified={onSuccess}
        onError={onError}
      />
    );
  },
);

MicroDepositModal.displayName = 'MicroDepositModal';
