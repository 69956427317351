import { Portal, VisuallyHidden } from '@chakra-ui/react';
import { Container, Link, Text } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useRef, useState } from 'react';

export type FocusSkipToComponentProps = {
  text: MessageKey;
  disable?: boolean;
  href: string;
  appendToBody?: boolean;
  dataTestid?: string;
};

export const FocusSkipToComponent = ({
  text,
  disable = false,
  href,
  appendToBody = false,
  dataTestid = '',
}: FocusSkipToComponentProps) => {
  const { formatMessage } = useMelioIntl();
  const [isFocus, setFocus] = useState<boolean>(false);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const containerRef = useRef<HTMLElement>(document.getElementById('partner-app-first-element'));

  useEffect(() => {
    if (isFocus && linkRef) {
      setTimeout(() => {
        linkRef.current?.focus();
      }, 0);
    }
  }, [isFocus, linkRef]);

  const onClick = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.stopPropagation();
  };

  if (disable) {
    return null;
  }

  const renderContent = () => (
    <Text textStyle="body4" color="brand.main" key="focus-skip-to-component-key" shouldSupportEllipsis>
      <Container border="dashed">
        <Link
          data-testid={`focus-skip-to-component-${dataTestid}`}
          ref={linkRef}
          variant="inline"
          href={href}
          color="inherit"
          label={formatMessage(text)}
          onClick={onClick}
          shouldSupportEllipsis
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Container>
    </Text>
  );

  const content = isFocus ? renderContent() : <VisuallyHidden>{renderContent()}</VisuallyHidden>;

  if (appendToBody && containerRef) {
    return <Portal containerRef={containerRef}>{content}</Portal>;
  }

  return content;
};
