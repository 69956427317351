import { AmountInput, AmountInputProps, Container, Group } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import Big from 'big.js';
import { useRef, useState } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../../NewSinglePaymentStepLayout';

export type EditableScreenTitleProps = {
  vendorName: Vendor['name'];
  paymentAmount: number;
  onChangePaymentAmount?: (newAmount: number) => void;
  currency?: string;
  billBalance: number;
  minimumAmount?: number;
  onError?: VoidFunction;
};

export const EditableScreenTitle = ({
  vendorName,
  paymentAmount,
  currency,
  onChangePaymentAmount,
  billBalance,
  minimumAmount,
  onError,
  ...props
}: EditableScreenTitleProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [amountInputErrorMessage, setAmountInputErrorMessage] = useState<string | undefined>(undefined);
  const amountInputRef = useRef<HTMLInputElement>(null);

  const expectedBalance = Big(billBalance).minus(paymentAmount).toNumber();
  const showAmountInputHelperText = 0 < expectedBalance && expectedBalance < billBalance;

  const onAmountChanged: AmountInputProps['onChange'] = (event) => {
    const value = event.target.value === '' ? paymentAmount : +event.target.value.slice(1).replace(/,/g, ''); // Remove currency sign and commas

    if (value === 0) {
      setAmountInputErrorMessage(
        formatMessage(
          'activities.fundingSourceSelection.screens.fundingSourceSelection.partialPayments.amountInput.verification.error.amountTooSmall'
        )
      );
      onError?.();
      amountInputRef.current?.focus();
      return;
    }
    if (value > billBalance) {
      setAmountInputErrorMessage(
        formatMessage(
          'activities.fundingSourceSelection.screens.fundingSourceSelection.partialPayments.amountInput.verification.error.amountTooBig'
        )
      );
      onError?.();
      amountInputRef.current?.focus();
      return;
    }
    if (minimumAmount && value < minimumAmount) {
      setAmountInputErrorMessage(
        formatMessage(
          'activities.fundingSourceSelection.screens.fundingSourceSelection.partialPayments.amountInput.verification.error.lessThanMinimumAmount'
        )
      );
      onError?.();
      amountInputRef.current?.focus();
      return;
    }
    onChangePaymentAmount?.(value);
  };

  return (
    <NewSinglePaymentStepLayout.Title {...props}>
      <Group variant="vertical" spacing="m" alignItems="center">
        <Container>
          {formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.partialPayments.title', {
            name: vendorName,
          })}
        </Container>
        <AmountInput
          data-testid="funding-source-selection-screen-title-amount-input"
          defaultValue={paymentAmount}
          integerLimitMask={billBalance.toFixed(0).length}
          editButtonTooltip={formatMessage(
            'activities.fundingSourceSelection.screens.fundingSourceSelection.partialPayments.amountInput.editButtonTooltip'
          )}
          {...(showAmountInputHelperText && {
            helperText: formatMessage(
              'activities.fundingSourceSelection.screens.fundingSourceSelection.partialPayments.amountInput.helperText',
              { remainingAmount: formatCurrency(expectedBalance) }
            ),
          })}
          onBlur={onAmountChanged}
          onChange={() => setAmountInputErrorMessage(undefined)}
          errorMessage={amountInputErrorMessage}
          ref={amountInputRef}
        />
      </Group>
    </NewSinglePaymentStepLayout.Title>
  );
};
