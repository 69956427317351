import React, { ChangeEventHandler } from 'react';
import { Box } from '@chakra-ui/react';
import { Avatar, AvatarProps, Checkbox, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { HighlightedText } from '@/cl/components/HighlightedText/HighlightedText.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { Size } from '@/cl/theme/theme.types';
import { useIsMobile } from '@/hooks/useIsMobile';
import { ContactSummaryTheme } from './ContactSummary.component.theme';

type ContactSummaryProps = {
  title: string | React.ReactNode;
  avatarName?: string;
  subtitle?: string | React.ReactNode;
  shouldShowCheckbox?: boolean;
  isChecked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  highlightedText?: string;
  size?: AvatarProps['size'];
  noAvatar?: boolean;
};

export const ContactSummary = forwardRef<ContactSummaryProps, 'div'>(
  (
    {
      size = 'medium',
      title,
      avatarName,
      subtitle,
      shouldShowCheckbox = false,
      isChecked,
      onCheckedChange,
      noAvatar,
      highlightedText,
      ...props
    },
    ref,
  ) => {
    const isMobile = useIsMobile();
    const styles = useAppTheme(ContactSummaryTheme, { size: size as Size });
    const isCheckedVisible = shouldShowCheckbox || isChecked;

    const handleChecked: ChangeEventHandler<HTMLInputElement> = (e) => {
      e.preventDefault();
      onCheckedChange?.(!isChecked);
    };

    const avatarOrCheckbox = () => {
      if (noAvatar || isMobile) {
        return null;
      }
      if (isCheckedVisible) {
        return <Checkbox isChecked={isChecked} onChange={handleChecked} data-testid="contact-summary-checkbox" />;
      }
      if (!avatarName && typeof title !== 'string') {
        return null;
      }

      return <Avatar size={size} name={(avatarName as string | undefined) || (title as string)} />;
    };

    return (
      <Box
        data-component="ContactSummary"
        textAlign={isMobile ? 'center' : 'left'}
        sx={styles.container}
        ref={ref}
        {...props}
      >
        {avatarOrCheckbox()}
        <Box sx={styles.contactDetails}>
          {typeof title === 'string' ? (
            <Text shouldSupportEllipsis textStyle="body2Semi" color="inherit" as="p">
              <HighlightedText highlightedText={highlightedText} text={title} />
            </Text>
          ) : (
            title
          )}
          {subtitle && (
            <Text color="neutral.darkest" textStyle="body4" as="p">
              {subtitle}
            </Text>
          )}
        </Box>
      </Box>
    );
  },
);
