import { BillDetailsFormFields, BillDetailsWidget } from '@melio/ap-widgets';
import { AvatarWithDetails, Container, Drawer, useFormSubmissionController, useToast } from '@melio/penny';
import { Bill, useFiles, Vendor } from '@melio/platform-api';
import { useState } from 'react';

export type BillDetailsDrawerScreenProps = {
  bill: Bill;
  vendor: Vendor;
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit?: VoidFunction;
  onError?: ErrorFunction;
};

export const BillDetailsDrawerScreen: React.VFC<BillDetailsDrawerScreenProps> = ({
  vendor,
  bill,
  isOpen,
  onClose,
  onError,
}) => {
  const [fileBlob, setFileBlob] = useState<File | undefined | null>();

  const { onSubmissionStateChange, handleSubmit } = useFormSubmissionController<BillDetailsFormFields>();
  const { create: uploadInvoiceFile } = useFiles({ enabled: false });

  const { toast } = useToast();

  const handleFail = (error: PlatformError) => {
    toast({ type: 'error', title: error.message });
    onError?.(error);
  };

  const onSubmit = () => {
    if (fileBlob) {
      uploadInvoiceFile(fileBlob).catch(handleFail);
    }
    handleSubmit?.();
  };

  return (
    <Drawer
      data-testid="bill-details-drawer-screen"
      isOpen={isOpen}
      onClose={onClose}
      header={<AvatarWithDetails avatarProps={{ name: vendor.name }} mainLabelProps={{ label: vendor.name }} />}
      body={
        <Container border="regular">
          <BillDetailsWidget
            bill={bill}
            onInvoiceChange={setFileBlob}
            isActionable={false}
            onSubmit={onSubmit}
            showOpenBalance
            onSubmissionStateChange={onSubmissionStateChange}
          />
        </Container>
      }
    />
  );
};

BillDetailsDrawerScreen.displayName = 'BatchPaymentsActivity.BillDetailsDrawerScreen';
