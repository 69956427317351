import { Box } from '@chakra-ui/react';
import { BillDetailsWidget, Carousel } from '@melio/ap-widgets';
import { Group, Loader, Text } from '@melio/penny';
import { AccountingPlatform, Bill, Loan, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { noop } from 'lodash';

import { LoanDetails } from './loan-details/LoanDetails';

export const Body = ({
  isLoading,
  payment,
  bills,
  activeAccountingPlatform,
}: {
  isLoading: boolean;
  payment?: Payment;
  bills?: Bill[];
  activeAccountingPlatform?: AccountingPlatform;
}) => {
  const { formatMessage } = useMelioIntl();

  if (isLoading) {
    return <Loader />;
  }

  if (!payment) {
    return (
      <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <Text textStyle="body4" color="neutral.darker">
          {formatMessage('activities.payDashboard.drawer.body.payment.empty.label')}
        </Text>
      </Box>
    );
  }

  const elements = bills?.map((bill) => (
    <BillDetailsWidget
      key={bill.id}
      bill={bill}
      showOpenBalance
      activeAccountingPlatform={activeAccountingPlatform}
      onSubmit={noop}
      onSubmissionStateChange={noop}
      onInvoiceChange={noop}
    />
  ));

  const loan = payment.loan;
  if (!loan) {
    throw new Error(`Loan with id ${payment.id} not found`);
  }

  return (
    <Group data-testid="pay-dashboard-loan" variant="vertical" spacing="xl">
      <LoanDetails payment={payment as Override<Payment, { loan: Loan }>} />
      {elements ? elements.length > 1 ? <Carousel elements={elements} initialElementIndex={0} /> : elements[0] : null}
    </Group>
  );
};
