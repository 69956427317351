/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import { Text, useBreakpoint, useTheme } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';
import {
  VendorDetailsForm,
  VendorDetailsFormProps,
} from '@/widgets/vendorsDashboard/VendorDetails/VendorDetailsForm.widget';

export type VendorDetailsHandler = {
  scrollIntoView: () => void;
};

export const VendorDetails = forwardRef<VendorDetailsHandler, VendorDetailsFormProps>(
  (
    {
      isEditable,
      onSubmit,
      onSubmissionStateChange,
      defaultValues,
      isRPPSVendor,
      managedBy,
      isSaving,
      vendorId,
      loadingContainerId,
    },
    ref,
  ) => {
    const { formatMessage } = usePlatformIntl();
    const theme = useTheme();
    const { track } = useAnalytics();
    const { isExtraSmallScreen } = useBreakpoint();

    const boxRef = React.useRef<HTMLDivElement>(null);
    React.useImperativeHandle(ref, () => ({
      scrollIntoView: () => {
        boxRef.current?.scrollIntoView({ behavior: 'smooth' });
      },
    }));

    React.useEffect(() => {
      if (isEditable) {
        track('EditVendorsDetails', 'Viewed');
      }
    }, [isEditable]);

    return (
      <Box
        scrollMargin="xl"
        padding={isExtraSmallScreen ? '16px' : '32px 40px'}
        border={`1px solid ${theme.colors.neutral.light}`}
        borderRadius={'8px'}
        ref={boxRef}
        data-testid="vendor-details"
        data-component={DataComponentEnum.VENDOR_DETAILS}
      >
        <Box marginBottom="m">
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage('widgets.vendorDetails.title')}
          </Text>
        </Box>
        <VendorDetailsForm
          vendorId={vendorId}
          onSubmit={onSubmit}
          onSubmissionStateChange={onSubmissionStateChange}
          isEditable={isEditable}
          defaultValues={defaultValues}
          isRPPSVendor={isRPPSVendor}
          managedBy={managedBy}
          isSaving={isSaving}
          loadingContainerId={loadingContainerId}
          size="small"
        />
      </Box>
    );
  },
);

VendorDetails.displayName = 'VendorDetails';
