import { roleToVariant, useRoleText } from '@melio/ap-domain';
import { ActionsDropdownMenuItemProps, Container, Group, LoadingContainer } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import type { Collaborator, CollaboratorAction, Invitation, InvitationAction } from '../types';
import { CollaboratorItem } from './components/CollaboratorItem';
import { InvitationItem } from './components/InvitationItem';
import { SectionHeaderWithButton } from './components/SectionHeaderWithButton';
import { getCollaboratorActions } from './utils/getCollaboratorActions';
import { getInvitationActions } from './utils/getInvitationActions';

type CollaboratorActionOptions = { collaborator: Collaborator };
type InvitationActionOptions = { invitation: Invitation };

type Props = {
  collaborators?: Collaborator[];
  invitations?: Invitation[];
  isFetching: boolean;
  actor?: Collaborator;
  onAddCollaboratorClick: VoidFunction;
  onCollaboratorAction: (action: CollaboratorAction, options: CollaboratorActionOptions) => void;
  onInvitationAction: (action: InvitationAction, options: InvitationActionOptions) => void;
  canInvite?: boolean;
};

export const ManageCollaboratorsScreen = ({
  actor,
  invitations,
  isFetching,
  collaborators,
  onAddCollaboratorClick,
  onInvitationAction,
  onCollaboratorAction,
  canInvite,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { getRoleText } = useRoleText();

  const collaboratorActionToDropdownActionsOption = (
    action: CollaboratorAction,
    options: CollaboratorActionOptions
  ): ActionsDropdownMenuItemProps => {
    const onClick = () => onCollaboratorAction(action, options);
    const dataTestId = `actions-dropdown-menu-item-${action}`;
    switch (action) {
      case 'owner':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.collaborator.makeOwner'),
          onClick,
          dataTestId,
        };
      case 'delete':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.collaborator.delete'),
          variant: 'critical',
          onClick,
          dataTestId,
        };
      case 'edit':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.collaborator.edit'),
          onClick,
          dataTestId,
        };
    }
  };

  const invitationActionToDropdownActionsOption = (
    action: InvitationAction,
    options: InvitationActionOptions
  ): ActionsDropdownMenuItemProps => {
    const onClick = () => onInvitationAction(action, options);
    const dataTestId = `actions-dropdown-menu-item-${action}`;
    switch (action) {
      case 'resend':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.invitation.resend'),
          onClick,
          dataTestId,
        };
      case 'cancel':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.invitation.delete'),
          variant: 'critical',
          onClick,
          dataTestId,
        };
    }
  };

  return (
    <Container width="full" paddingX="none" data-testid="manage-collaborators-activity-manage-collaborators-screen">
      <SectionHeaderWithButton
        title={formatMessage('activities.manageCollaborators.screens.main.title')}
        buttonText={formatMessage('activities.manageCollaborators.screens.main.addBtn')}
        buttonIcon="add"
        titleAs="h3"
        onClick={canInvite ? onAddCollaboratorClick : undefined}
        buttonTestId="add-collaborator-button"
      />
      <LoadingContainer isLoading={isFetching} data-testid="manage-collaborators-loading">
        <Group variant="vertical" hasDivider>
          {collaborators?.map((collaborator) => (
            <CollaboratorItem
              key={collaborator.id}
              {...collaborator}
              role={getRoleText(collaborator.roleUniqueName)}
              roleVariant={roleToVariant(collaborator.roleUniqueName)}
              menuItems={getCollaboratorActions({ collaborator, actor }).map((action) =>
                collaboratorActionToDropdownActionsOption(action, { collaborator })
              )}
            />
          ))}
          {invitations?.map((invitation) => (
            <InvitationItem
              key={invitation.id}
              {...invitation}
              isExpired={invitation.status === 'expired'}
              badgeText={
                invitation.status === 'expired'
                  ? formatMessage('activities.manageCollaborators.invitation.status.expired')
                  : formatMessage('activities.manageCollaborators.invitation.status.pending')
              }
              menuItems={getInvitationActions({ invitation, actor }).map((action) =>
                invitationActionToDropdownActionsOption(action, { invitation })
              )}
            />
          ))}
        </Group>
      </LoadingContainer>
    </Container>
  );
};
