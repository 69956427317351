import { isCollaboratorBlocked } from '@melio/ap-domain';
import { Group, Table, Text } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  actor: Collaborator<'user'>;
  collaborator: Collaborator<'user'>;
};
export const UserCell = ({ actor, collaborator }: Props) => {
  const { formatMessage } = useMelioIntl();

  const fullName = `${collaborator.user.firstName} ${collaborator.user.lastName}`;
  return (
    <Table.Cell data-testid={`collaborators-table-row-${collaborator.id}-user-cell`}>
      <Group variant="vertical" spacing="xxs">
        <Group spacing="xxxs" alignItems="center">
          <Text
            textStyle="body3Semi"
            color={isCollaboratorBlocked(collaborator) ? 'neutral.darker' : 'neutral.black'}
            shouldSupportEllipsis
          >
            {fullName}
          </Text>

          {collaborator.id === actor.id ? (
            <Text textStyle="body3Semi" color="neutral.black">
              {`${formatMessage('activities.settings.collaborators.table.cells.user.actor')}`}
            </Text>
          ) : null}
        </Group>

        <Text textStyle="body4" color="neutral.darker" shouldSupportEllipsis>
          {collaborator.user.email}
        </Text>
      </Group>
    </Table.Cell>
  );
};
