import { Container, LoadingContainer, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useApprovalWorkflows } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Suspense, useEffect } from 'react';

import { SectionHeaderWithButton } from '../ManageCollaborators/screens/components/SectionHeaderWithButton';
import { ApprovalWorkflowCreatedModal } from './components/ApprovalWorkflowCreatedModal';
import { ApprovalWorkflowsList, OnActionProps } from './components/ApprovalWorkflowsList';

export const ApprovalWorkflowsSettings = withAnalyticsContext<{
  onAction: (props: OnActionProps) => void;
}>(({ setAnalyticsProperties, onAction }) => {
  const { formatMessage } = useMelioIntl();
  const { data: workflows, isLoading } = useApprovalWorkflows({
    refetchOnMount: 'always',
    useErrorBoundary: true,
  });
  const { track } = useAnalytics();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  setAnalyticsProperties({
    PageName: 'payment-approval-workflow',
    Flow: 'approval-workflow',
    CountInTab: workflows?.length,
    WorkflowId: workflows?.map((workflow) => workflow.id),
  });

  useEffect(() => {
    if (!isLoading) {
      track('Settings', 'View', { Intent: 'approval-workflow', EntryPoint: 'Invite', PageName: 'workflows-setting' });
    }
  }, [isLoading, track]);

  return (
    <Container width="full" paddingX="none" data-testid="workflow-settings-root" data-component="WorkflowsSettings">
      {isMobile ? null : (
        <SectionHeaderWithButton
          title={formatMessage('activities.settings.approval-workflow-settings.title')}
          buttonText={formatMessage('activities.settings.approval-workflow-settings.add-workflow')}
          buttonIcon="add"
          titleAs="h3"
          onClick={() => {
            track('Settings', 'Click', { Intent: 'add-approval-workflow', Cta: 'add-workflow' });
            onAction({ action: 'add' });
          }}
          buttonTestId="add-workflow-button"
        />
      )}
      <Suspense fallback={<LoadingContainer isLoading />}>
        <ApprovalWorkflowsList onAction={onAction} />
      </Suspense>
      <ApprovalWorkflowCreatedModal />
    </Container>
  );
});
