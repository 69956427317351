import { isFXCurrency } from '@melio/ap-domain';
import {
  Currency,
  getTransferRequiredFields,
  TransferRequiredFieldsAlternatives,
} from '@melio/international-payments-utils';
import { FxCurrency } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { FileType, useConfig } from '@melio/platform-provider';
import { pickBy } from 'lodash';
import { mixed, object, SchemaOf, string } from 'yup';

import { InternationalPaymentPurposeFormFields } from './types';
export const PURPOSE_PAYMENT_OPTIONS = ['Goods', 'Services', 'Charitable donations', 'Other'];

export const getOptionsFromTransferRequiredFields = (requireFilesForCurrency: TransferRequiredFieldsAlternatives) =>
  requireFilesForCurrency.alternatives
    .find((alt) => alt.type === 'transfer')
    ?.fields[0]?.group.find((g) => g.key === 'transferPurpose')
    ?.valuesAllowed?.map((val) => ({
      value: val.key,
      label: val.name,
      testId: `payment-purpose-${val.key}`,
    }));

export const useGetPaymentPurposeOptions = (currency?: FxCurrency) => {
  if (currency && isFXCurrency(currency)) {
    const requireFilesForCurrency = getTransferRequiredFields(currency as Currency);
    const options = getOptionsFromTransferRequiredFields(requireFilesForCurrency);
    return { options: options || [] };
  }

  const options = PURPOSE_PAYMENT_OPTIONS.map((option) => ({
    value: option,
    label: option,
    testId: `payment-purpose-${option}`,
  }));
  return { options };
};

export const PaymentPurposeSchema = (
  shouldShowInvoice: boolean,
  shouldShowPaymentPurpose: boolean,
  selectedPaymentPurpose?: string
) => {
  const { formatMessage } = useMelioIntl();
  const { fileSizeLimit, fileAllowedFormats } = useConfig().settings;

  const schemaFields: Array<keyof InternationalPaymentPurposeFormFields> = [];
  if (shouldShowInvoice) {
    schemaFields.push('invoice');
  }
  if (shouldShowPaymentPurpose) {
    schemaFields.push('paymentPurpose');
  }
  if (selectedPaymentPurpose === 'Other') {
    schemaFields.push('purposeDescription');
  }

  return object().shape(
    pickBy(
      {
        paymentPurpose: string()
          .required(
            formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.paymentPurpose.validation.required'
            )
          )
          .typeError(
            formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.paymentPurpose.validation.required'
            )
          ),
        purposeDescription: string()
          .trim()
          .required(
            formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.purposeDescription.validation.required'
            )
          ),
        invoice: mixed<File>()
          .required(
            formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.validation.required'
            )
          )
          .test(
            'is-file-size-below-limit',
            formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.validation.sizelimit'
            ),
            (invoice) => {
              if (invoice) {
                return invoice?.size <= fileSizeLimit;
              }
              return true;
            }
          )
          .test(
            'is-file-format-allowed',
            formatMessage('widgets.addInstantBillForm.invoice.validation.fileFormat'),
            (invoice) => {
              if (invoice) {
                return fileAllowedFormats.includes(invoice.type?.split('/')[1] as FileType);
              }
              return true;
            }
          ),
      },
      (_, key) => schemaFields.includes(key as keyof InternationalPaymentPurposeFormFields)
    )
  ) as unknown as SchemaOf<InternationalPaymentPurposeFormFields>;
};
