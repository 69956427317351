import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';

import { AccountingPlatformConfirmConnectScreen } from '../screens';
import { ExternalOrganizations } from '../types';

type Props = {
  isLoading: boolean;
  companyName?: string | null;
  externalOrganizations: ExternalOrganizations;
  accountingPlatformName: string;
  onCancel: VoidFunction;
  onSubmitOrganization: (externalOrganizationId: string) => void;
};

export const ConfirmConnectStep = ({
  isLoading,
  companyName,
  externalOrganizations = [],
  accountingPlatformName,
  onCancel,
  onSubmitOrganization,
}: Props) => {
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'exit' | 'cancel' | 'connect';
  }>('SyncAccountingSoftware', 'Click');

  useAnalyticsView('connect-to-accounting-software-one-connection');

  const onCloseClick = () => {
    trackAndHandleClick({ Cta: 'exit' }, onCancel);
  };

  const onCancelClick = () => {
    trackAndHandleClick({ Cta: 'cancel' }, onCancel);
  };

  const onSubmitClick = (externalOrganizationId: string) => {
    trackAndHandleClick({ Cta: 'connect' }, onSubmitOrganization(externalOrganizationId));
  };

  return (
    <AccountingPlatformConfirmConnectScreen
      isLoading={isLoading}
      companyName={companyName}
      externalOrganizations={externalOrganizations}
      accountingPlatformName={accountingPlatformName}
      onClose={onCloseClick}
      onCancel={onCancelClick}
      onSubmitOrganization={onSubmitClick}
    />
  );
};
