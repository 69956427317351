/* eslint-disable max-lines */
import { Money } from '@melio/money';
import {
  Container,
  Form,
  FormProps,
  Modal,
  Text,
  useBreakpointValue,
  UseMelioFormResults,
  useWatch,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  AccountingPlatform,
  AccountingPlatformBillLabel,
  AccountingPlatformBillLineItemLabel,
  AccountingPlatformBillLineItemLabelStatusEnum,
  FxCurrency,
  useInternationalRate,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { PageTitle } from '../../../../../PageTitle/PageTitle';
import { AddBillV2FormFrequency, AddBillV2FormValues, FxCurrencyExchangeRate } from '../../../types';
import { calculateTotalLineItemsAmount, getTotalLineItemsLength } from '../../../utils';
import { BillDetailsForm } from './components/BillDetailsForm';
import { FormContentDisplay } from './components/FormContentDisplay';
import { LineItemsForm } from './components/ListItemForm';

type Props = {
  allowRecurring: boolean;
  isMinifiedView: boolean;
  melioFormProps: UseMelioFormResults<AddBillV2FormValues>;
  billLineItemLabels?: AccountingPlatformBillLineItemLabel[];
  billLabel?: AccountingPlatformBillLabel;
  shouldClearVendorIdField?: boolean;
  activeAccountingPlatform?: AccountingPlatform;
  isConnectedToAccountingPlatform: boolean;
  selectedVendor?: Vendor;
  isInternationalFxEnabled: boolean;
  foreignCurrencyRate?: FxCurrencyExchangeRate;
  amountForQuery?: string;
  shouldShowAmountInUSDField?: boolean;
  setIsMinifiedView: Dispatch<SetStateAction<boolean>>;
  onAmountFieldBlur: (amount?: string) => void;
  onClose: VoidFunction;
  setAmountForQuery: Dispatch<SetStateAction<string | undefined>>;
  statusMessageParentSelector?: string | undefined;
};

export const AddBillFormPanel = ({
  allowRecurring,
  isMinifiedView,
  melioFormProps,
  billLineItemLabels,
  billLabel,
  selectedVendor,
  shouldClearVendorIdField,
  activeAccountingPlatform,
  isConnectedToAccountingPlatform,
  foreignCurrencyRate,
  isInternationalFxEnabled,
  shouldShowAmountInUSDField,
  amountForQuery,
  setIsMinifiedView,
  onAmountFieldBlur,
  setAmountForQuery,
  statusMessageParentSelector,
}: Props) => {
  const isTablet = useBreakpointValue({ m: true, l: false });
  const isMobile = useBreakpointValue({ xs: true, s: false }, { ssr: false } as never);
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const [, setAmountFieldHelper] = useState<string | null>();
  const [shouldShowRecurringLineItemWarningModal, showRecurringLineItemWarningModal] = useBoolean(false);
  const [userAgreedToRecurringPaymentWarningMessage, setUserAgreedToRecurringPaymentWarningMessage] = useState(false);
  const formFieldsSize = useBreakpointValue<FormProps['size']>({ xs: 'small', s: 'large' });
  const [isLineItemsEnabledOnMobile] = useFeature<boolean>(FeatureFlags.IsLineItemsEnabledOnMobile, true);

  const isLineItemsAvailable = isMobile ? isLineItemsEnabledOnMobile : true;

  const { control, registerField, formProps, setValue, clearErrors, formState } = melioFormProps;
  const [
    categoryBasedLineItems,
    itemBasedLineItems,
    xeroSyncedLineItems,
    nonSyncedLineItems,
    amount,
    frequency,
    currency,
  ] = useWatch({
    control,
    name: [
      'categoryBasedLineItems',
      'itemBasedLineItems',
      'xeroSyncedLineItems',
      'nonSyncedLineItems',
      'amount',
      'frequency',
      'currency',
    ],
  });

  const totalAmount = calculateTotalLineItemsAmount({
    categoryBasedLineItems: categoryBasedLineItems ?? [],
    itemBasedLineItems: itemBasedLineItems ?? [],
    xeroSyncedLineItems: xeroSyncedLineItems ?? [],
    lineItems: nonSyncedLineItems ?? [],
  });

  const lineItemsLength = getTotalLineItemsLength({
    categoryBasedLineItems: categoryBasedLineItems ?? [],
    itemBasedLineItems: itemBasedLineItems ?? [],
    xeroSyncedLineItems: xeroSyncedLineItems ?? [],
    lineItems: nonSyncedLineItems ?? [],
  });

  const { isLoading: isLoadingCurrencyRate } = useInternationalRate({
    foreignCurrency: selectedVendor?.currency || (currency as FxCurrency),
    foreignAmount: amountForQuery ? Number(amountForQuery) : undefined,
    enabled: shouldShowAmountInUSDField,
  });

  const isMoreThanOneLineItem = !isMinifiedView && lineItemsLength > 1;

  const isHidden = frequency !== AddBillV2FormFrequency.ONE_TIME;

  const onAmountChange = (amount?: string) => {
    if (amount == null) {
      setValue('amount', '', { shouldDirty: true });
    } else {
      setValue('amount', amount, { shouldDirty: true });
      setAmountInUSD(amount);
      clearErrors('amount');
    }
    if (nonSyncedLineItems?.length == 1) {
      setValue('nonSyncedLineItems.0.amount', amount, { shouldDirty: true });
      clearErrors('nonSyncedLineItems.0.amount');
    }

    if (!isMoreThanOneLineItem && !isHidden) {
      if (itemBasedLineItems?.length == 1) {
        setValue('itemBasedLineItems.0.amount', amount, { shouldDirty: true });
      }

      if (categoryBasedLineItems?.length == 1) {
        setValue('categoryBasedLineItems.0.amount', amount, { shouldDirty: true });
      }

      if (xeroSyncedLineItems?.length == 1) {
        setValue('xeroSyncedLineItems.0.amount', amount as string);
      }
    }
  };

  const setAmountInUSD = (amount?: string) => {
    if (shouldShowAmountInUSDField && foreignCurrencyRate) {
      const usdAmount = Money.fromNaturalUnit(amount || '0', foreignCurrencyRate?.foreignCurrency || 'USD')
        .convert('USD', foreignCurrencyRate?.usdToForeignRate)
        .toString();

      setValue('amountInUSD', usdAmount);
    }
  };

  const onChangeBillCurrency = (currency?: FxCurrency) => {
    if (currency) {
      setValue('currency', currency);
      setAmountForQuery(amount);
    }
  };

  useEffect(() => {
    amount && setAmountInUSD(amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foreignCurrencyRate, amount]);

  useEffect(() => {
    foreignCurrencyRate && setAmountInUSD(amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foreignCurrencyRate]);

  const onCancelFrequencyChange = () => {
    showRecurringLineItemWarningModal.off();
    setValue('frequency', AddBillV2FormFrequency.ONE_TIME, { shouldDirty: true });
  };

  useLayoutEffect(() => {
    if (!totalAmount && !lineItemsLength) {
      setValue('amount', amount);
      return;
    }
    if (totalAmount !== parseFloat(amount as string) && (amount !== '' || totalAmount)) {
      setValue('amount', new Number(totalAmount ?? 0).toFixed(2));
      clearErrors('amount');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount]);

  const handleOnPaymentFrequencyChange = (selectedFrequency?: AddBillV2FormFrequency) => {
    setValue('frequency', selectedFrequency, { shouldDirty: true });

    if (
      isMoreThanOneLineItem &&
      selectedFrequency !== AddBillV2FormFrequency.ONE_TIME &&
      !userAgreedToRecurringPaymentWarningMessage
    ) {
      showRecurringLineItemWarningModal.on();
    }

    if (isMoreThanOneLineItem || lineItemsLength) {
      if (amount === totalAmount) {
        setAmountFieldHelper(null);
      } else {
        if (selectedFrequency === AddBillV2FormFrequency.ONE_TIME) {
          setAmountFieldHelper(formatMessage('activities.addBillV2.billForm.billAmount.amountChangedHelperText'));
        }

        if (totalAmount === null) {
          setValue('amount', '0');
        } else {
          setValue('amount', new Number(totalAmount ?? 0).toFixed(2));
        }
        clearErrors('amount');
      }
    }
  };

  const onConfirmFrequencyChangeWarning = () => {
    setUserAgreedToRecurringPaymentWarningMessage(true);
    showRecurringLineItemWarningModal.off();
  };

  const toggleView = () => {
    track('Bill', 'Click', {
      Cta: 'advanced-bill-details',
    });
    setIsMinifiedView((lastView) => !lastView);
  };

  const activeBillLabel = useMemo(
    () =>
      billLabel && {
        name: billLabel.name,
        options: billLabel.options.filter(
          (option) =>
            option.status === AccountingPlatformBillLineItemLabelStatusEnum.Active ||
            formState.defaultValues?.externalLabelId === option.id
        ),
      },
    [billLabel, formState.defaultValues]
  );

  return (
    <>
      {isTablet ? null : (
        <PageTitle textStyle="heading1Semi" aria-live="polite">
          {formatMessage('activities.addBillV2.billForm.title')}
        </PageTitle>
      )}
      <Container paddingTop={isTablet ? undefined : 's-m'} paddingBottom="xs">
        <Text color="neutral.darkest" textStyle="body4">
          {formatMessage('activities.addBillV2.billForm.requiredFields')}
        </Text>
      </Container>
      <Form columns={16} {...formProps} size={formFieldsSize} data-testid="add-bill-v2-form-panel">
        <FormContentDisplay>
          <BillDetailsForm
            formControl={control}
            registerField={registerField}
            selectedVendor={selectedVendor}
            isMinifiedView={isMinifiedView}
            allowRecurring={allowRecurring}
            shouldClearVendorIdField={shouldClearVendorIdField}
            isFormHasLineItems={isMoreThanOneLineItem}
            activeAccountingPlatform={activeAccountingPlatform}
            isLoadingCurrencyRates={isLoadingCurrencyRate}
            shouldShowAmountInUSDField={shouldShowAmountInUSDField}
            onAmountFieldBlur={onAmountFieldBlur}
            currencyRate={foreignCurrencyRate}
            isInternationalFxEnabled={isInternationalFxEnabled}
            onChangeBillCurrency={onChangeBillCurrency}
            isConnectedToAccountingPlatform={isConnectedToAccountingPlatform}
            toggleView={toggleView}
            onAmountChange={onAmountChange}
            onPaymentFrequencyChange={handleOnPaymentFrequencyChange}
            billLabel={activeBillLabel}
            amountForQuery={amountForQuery}
            statusMessageParentSelector={statusMessageParentSelector}
            isSubmitted={formState.isSubmitted}
          />
        </FormContentDisplay>
        <FormContentDisplay>
          <LineItemsForm
            setValue={setValue}
            formDefaultValues={formState.defaultValues}
            formControl={control}
            registerField={registerField}
            shouldRender={frequency === AddBillV2FormFrequency.ONE_TIME && !isMinifiedView && isLineItemsAvailable}
            activeAccountingPlatform={activeAccountingPlatform}
            currency={isInternationalFxEnabled ? currency : 'USD'}
            isConnectedToAccountingPlatform={isConnectedToAccountingPlatform}
            isMinifiedView={isMinifiedView}
            billLineItemLabels={billLineItemLabels}
          />
        </FormContentDisplay>
        <Modal
          isOpen={shouldShowRecurringLineItemWarningModal}
          data-testid="frequency-change-warning-modal"
          header={formatMessage('activities.addBillV2.billForm.frequencyChangeWarningModal.header')}
          description={formatMessage('activities.addBillV2.billForm.frequencyChangeWarningModal.description')}
          primaryButton={{
            variant: 'primary',
            label: formatMessage('activities.addBillV2.billForm.frequencyChangeWarningModal.confirmButton'),
            onClick: onConfirmFrequencyChangeWarning,
          }}
          secondaryButton={{
            variant: 'tertiary',
            label: formatMessage('activities.addBillV2.billForm.frequencyChangeWarningModal.cancelButton'),
            onClick: onCancelFrequencyChange,
          }}
          onClose={showRecurringLineItemWarningModal.off}
        />
      </Form>
    </>
  );
};
