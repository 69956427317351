import { Navigate } from 'react-router-dom';

import {
  useIsSubscriptionsEnabled,
  useRedirects,
  useSubscribeToFreePlan,
  useSubscription,
  useWizardParams,
} from '../../../hooks';
import { WizardLoader } from './components/WizardLoader';
import { SubscriptionWizardScreen } from './screens/SubscriptionWizard.screen';

export const SubscriptionWizardActivity = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();

  const { goToReturnUrlOrDashboard } = useRedirects();
  const { subscribeToFreePlan, isSubscribingToFreePlan } = useSubscribeToFreePlan();

  const { returnUrl, planId } = useWizardParams();

  const handleOnClose = () => {
    if (isSubscribingToFreePlan) {
      return;
    }

    if (!subscription) {
      void subscribeToFreePlan();
    } else {
      goToReturnUrlOrDashboard();
    }
  };

  if (!planId) {
    return <WizardLoader />;
  }

  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  return <SubscriptionWizardScreen returnUrl={returnUrl} planId={planId} onClose={handleOnClose} />;
};
