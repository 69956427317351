import { Divider, Group, Link, Modal, Text, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useEffect } from 'react';

export type FinancingDetailsModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const FinancingDetailsModalWidget: React.VFC<FinancingDetailsModalProps> = ({ isOpen, onClose }) => {
  const { formatMessage } = useMelioIntl();
  const { financingMoreDetails } = useConfig().links;

  const { track, createTrackHandler } = useAnalytics();

  const trackAndHandleClick = createTrackHandler<{
    PageName: 'pay-over-time';
    Cta: 'apply-now' | 'dismiss' | 'exit';
  }>('Payment', 'Click', { PageName: 'pay-over-time' });

  useEffect(() => {
    if (isOpen) {
      track('Payment', 'View', {
        PageName: 'pay-over-time',
      });
    }
  }, [isOpen, track]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
      data-component="FinancingDetailsModal"
      data-testid="financing-details-modal"
      header={formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.title')}
      icon="installments"
      description={formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.description')}
      primaryButton={{
        onClick: () => trackAndHandleClick({ Cta: 'apply-now' }, onClose),
        variant: 'primary',
        label: formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.primaryButton'),
      }}
      secondaryButton={{
        onClick: () => trackAndHandleClick({ Cta: 'dismiss' }, onClose),
        variant: 'secondary',
        label: formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.secondaryButton'),
      }}
    >
      <Group variant="vertical" spacing="m">
        <Divider />

        <Group variant="vertical">
          <Text textStyle="body2Semi">
            {formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.title')}
          </Text>
          <Typography.ParagraphList
            list={[
              {
                content: formatMessage(
                  'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.text1'
                ),
              },
              {
                content: formatMessage(
                  'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.text2'
                ),
              },
              {
                content: formatMessage(
                  'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph1.text3'
                ),
              },
            ]}
            type="unordered"
          />
        </Group>

        <Divider />

        <Group variant="vertical">
          <Text textStyle="body2Semi">
            {formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.title')}
          </Text>
          <Typography.ParagraphList
            list={[
              {
                content: formatMessage(
                  'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text1'
                ),
              },
              {
                content: formatMessage(
                  'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text2'
                ),
              },
              {
                content: formatMessage(
                  'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text3'
                ),
              },
              {
                content: formatMessage(
                  'widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.paragraph2.text4'
                ),
              },
            ]}
            type="ordered"
          />
        </Group>

        <Link
          href={financingMoreDetails}
          label={formatMessage('widgets.fundingSources.selectFundingSourceCard.financing.detailsModal.learnMore')}
          variant="inline"
          newTab
        />
      </Group>
    </Modal>
  );
};

FinancingDetailsModalWidget.displayName = 'FinancingDetailsModalWidget';
