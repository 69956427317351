import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { Button, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { useTaxReportLink } from '@/hooks/useTaxReportLink.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { SectionHeaderWithButton } from '@/widgets/settings-page/Common/SectionHeaderWithButton.widget';

export const TaxReportSection = () => {
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();

  const title = formatMessage('widgets.companySettings.taxFormSection.title');
  const content = formatMessage('widgets.companySettings.taxFormSection.content');
  const btnText = formatMessage('widgets.companySettings.taxFormSection.btnText');

  const taxReportLink = useTaxReportLink();

  const onClick = () => {
    track('Settings', 'Click', {
      PageName: 'company-settings',
      Cta: 'classify-and-report',
    });
  };

  return (
    <Stack flexDirection="column" gridGap={'24px'} spacing={0}>
      <SectionHeaderWithButton title={title} titleAs="h3" />
      <Box>
        <Box mb={6}>
          <Text>{content}</Text>
        </Box>
        <a href={taxReportLink} data-testid="tax-report-link">
          <Button variant="tertiary" label={btnText} data-testid={'tax-report-btn'} onClick={onClick} />
        </a>
      </Box>
    </Stack>
  );
};
