export type KycComplianceState = {
  isCompliance?: boolean;
  isMissingFields?: boolean;
  isSubmitAllMissingFields?: boolean;
  isSubmitted?: boolean;
  isDismissKycLoginBlockerScreen?: boolean;
  isVerificationFailed?: boolean;
  isGracePeriodEnded?: boolean;
  isGracePeriodStarted?: boolean;
  numberOfPaymentsLeftInGracePeriod?: number | null;
};

export type KycComplianceFormState = {
  formState: 'submit' | 'skip' | null;
  submittedAll: boolean;
};

export enum SubmittedFieldsMessageType {
  PERSONAL_ONLY = 'personalOnly',
  COMPANY_ONLY = 'companyOnly',
  GENERIC = 'generic',
}
