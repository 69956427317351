import { useMelioForm } from '@melio/penny';
import { OrganizationVerifiedPersona } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';

import { getCountryCode } from '../../../utils';
import { AccountHolderForm, OwnershipDetails, OwnershipDetailsForm, UBO_FROM_ACCOUNT_HOLDER_ID } from '../types';
import { useOwnershipDetailsFormSchema } from './useOwnershipDetailsFormSchema';

export const useOwnershipDetailsForm = ({ onDone }: { onDone: VoidFunction }) => {
  const ownershipDetailsFormSchema = useOwnershipDetailsFormSchema();

  const form = useMelioForm<OwnershipDetailsForm>({
    onSubmit: onDone,
    schema: ownershipDetailsFormSchema,
  });

  return {
    form,
  };
};

const useVerifiedPersonaToOwnershipDetails = (): ((
  verifiedPersona: OrganizationVerifiedPersona
) => OwnershipDetails) => {
  const { createDate } = useDateUtils();

  return (verifiedPersona) => ({
    uboId: verifiedPersona.id,
    ownerFirstName: verifiedPersona.firstName,
    ownerLastName: verifiedPersona.lastName,
    address: {
      line1: verifiedPersona.address.line1 ?? '',
      line2: verifiedPersona.address.line2 ?? '',
      city: verifiedPersona.address.city ?? '',
      state: verifiedPersona.address.state ?? '',
      countryCode: verifiedPersona.address.countryCode ? getCountryCode(verifiedPersona.address.countryCode) : '',
      postalCode: verifiedPersona.address.postalCode ?? '',
    },
    ssn: verifiedPersona.taxId,
    ownershipPercentage: verifiedPersona.ownershipPercentage?.toString() ?? '',
    dateOfBirth: verifiedPersona.dateOfBirth ? createDate(verifiedPersona.dateOfBirth) : undefined,
  });
};

export const useGetUBOFormValues = () => {
  const verifiedPersonaToOwnershipDetails = useVerifiedPersonaToOwnershipDetails();

  const getUBOFormValues = ({
    accountHolderFormValues,
    verifiedPersonas,
  }: {
    accountHolderFormValues: AccountHolderForm;
    verifiedPersonas?: OrganizationVerifiedPersona[];
  }): OwnershipDetails[] => {
    if (verifiedPersonas?.length) {
      return verifiedPersonas.map(verifiedPersonaToOwnershipDetails);
    }

    if (accountHolderFormValues.isUbo) {
      return [
        {
          uboId: UBO_FROM_ACCOUNT_HOLDER_ID,
          ownerFirstName: accountHolderFormValues.firstName,
          ownerLastName: accountHolderFormValues.lastName,
          address: accountHolderFormValues.address,
          ssn: accountHolderFormValues.taxId,
          ownershipPercentage: '',
          dateOfBirth: accountHolderFormValues.dayOfBirth,
        },
      ];
    }

    return [
      {
        uboId: 'default_ubo',
        ownerFirstName: '',
        ownerLastName: '',
        address: {
          line1: '',
          postalCode: '',
          state: '',
          city: '',
        },
        ssn: '',
        ownershipPercentage: '',
        dateOfBirth: undefined,
      },
    ];
  };

  return getUBOFormValues;
};
