import { useAccount, useGuestPayorFundingSources, useGuestPayorOnboarding } from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PartnerName, PlaidAccountData } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React, { useEffect, useRef } from 'react';

import { AuthenticationModalActivity } from '../authentication-modal';
import { AddBankFundingSourceFormScreen, AddPlaidFundingSourceScreen } from './screens';

type AddBankFundingSourceActivityProps = {
  onDone: (id: string) => void;
  paymentRequestLink: string;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onError?: ARErrorFunction;
};

const plaidAnalytics = {
  PageName: 'verification-code',
  Intent: 'verification',
  AuthenticatorType: 'email',
};

export const AddBankFundingSourceActivity = forwardRef<AddBankFundingSourceActivityProps>(
  ({ onError, onLoggedIn, partnerName, paymentRequestLink, onDone }, ref) => {
    const { onboarding, isLoading: isLoadingOnBoarding } = useGuestPayorOnboarding();
    const { create, isCreating: isCreatingFundingSource } = useGuestPayorFundingSources({ enabled: false });
    const [isPlaidModalOpen, openPlaidModal] = useBoolean(false);
    const [isOTPModalOpen, otpModalOpen] = useBoolean(false);
    const [isLoadingPlaidToken, loadingPlaidToken] = useBoolean(false);
    const [userEmail, setUserEmail] = React.useState<string>('');
    const { toast } = useToast();
    const { track } = useAnalytics();
    const { data: account, error: fetchAccountError } = useAccount({ id: 'me' });
    const prevEmailRef = useRef<string>();

    useEffect(() => {
      const prevEmail = prevEmailRef.current;

      if (userEmail && userEmail !== prevEmail) {
        prevEmailRef.current = userEmail;
        const isAuthenticated = !fetchAccountError && account && account.user.email === userEmail;
        isAuthenticated ? void handleAuthenticationDone() : otpModalOpen.on();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail, account]);

    const createPlaidFundingSource = (data: PlaidAccountData) => {
      openPlaidModal.off();
      void create({
        type: 'plaid',
        details: { plaidAccountId: data.accounts[0]?.id as string, plaidToken: data.public_token },
      })
        .then(({ id }) => {
          track('PaymentRequest', 'Status', {
            StatusType: 'success',
            ...plaidAnalytics,
          });
          onDone(id);
        })
        .catch(plaidErrorHandler);
    };

    const plaidErrorHandler = (error: ARPlatformError) => {
      track('PaymentRequest', 'Status', {
        StatusType: 'failure',
        ErrorType: error.message,
        ...plaidAnalytics,
      });
      openPlaidModal.off();
      toast({ type: 'error', title: error.message });
      onError?.(error);
    };

    const handleOnSubmit = ({ email }: { email: string }) => {
      track('PaymentRequest', 'Click', {
        PaymentMethodType: 'ach',
        Intent: 'connect-bank-account',
        Cta: 'connect-with-plaid',
      });
      setUserEmail(email);
    };

    const handleAuthenticationDone = async () => {
      try {
        otpModalOpen.off();
        await onboarding({ paymentRequestLink });
        openPlaidModal.on();
      } catch (error) {
        toast({ type: 'error', title: (error as ARPlatformError).message });
        onError?.(error as ARPlatformError);
      }
    };

    return (
      <>
        <AddBankFundingSourceFormScreen
          onSubmit={handleOnSubmit}
          isSaving={isLoadingPlaidToken || isLoadingOnBoarding || isCreatingFundingSource}
          ref={ref}
        />
        <AuthenticationModalActivity
          partnerName={partnerName}
          email={userEmail}
          onLoggedIn={onLoggedIn}
          onClose={() => otpModalOpen.off()}
          isOpen={isOTPModalOpen}
          onDone={handleAuthenticationDone}
        />
        {isPlaidModalOpen ? (
          <AddPlaidFundingSourceScreen
            onLoadToken={(isLoading) => {
              if (isLoading) {
                loadingPlaidToken.on();
              } else {
                loadingPlaidToken.off();
              }
            }}
            onSuccess={createPlaidFundingSource}
            onClose={() => openPlaidModal.off()}
            onError={plaidErrorHandler}
          />
        ) : null}
      </>
    );
  }
);
AddBankFundingSourceActivity.displayName = 'AddCardFundingSourceActivity';
