import { Box } from '@chakra-ui/react';
import { Group, Loader } from '@melio/penny';

export const WizardLoader = () => (
  <Box height="full">
    <Group height="full" alignItems="center" justifyContent="center">
      <Loader />
    </Group>
  </Box>
);
