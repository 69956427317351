import { useTbt } from '@melio/form-controls';
import { Container, Group, LoadingContainer, NakedButton, Text, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { CardParams, FundingSource, FundingSourceType, useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FundingSourceCard, FundingSourceGroupList } from '../../../../../components';
import { useSubscriptionRouter } from '../../../../../utils/useSubscriptionRouter';
import { CardFundingSourceFormFields } from '../../CardFundingSourceForm/types';
import { AddNewFundingSourceView } from './AddNewFundingSourceView';

export type SubscriptionCheckoutFundingSourceSelectionProps = {
  fundingSourceId: string | null;
  setFundingSourceId: (id: string) => void;
};

type PaymentMethodView = 'selected' | 'all' | 'add-new';

export const SubscriptionPaymentMethod = ({
  fundingSourceId,
  setFundingSourceId,
}: SubscriptionCheckoutFundingSourceSelectionProps) => {
  const { track } = useAnalytics();
  const { goToAddBankAccount } = useSubscriptionRouter();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { bt, tokenize } = useTbt();
  const location = useLocation();

  const [fundingSourceType, setFundingSourceType] = useState<FundingSourceType>(FundingSourceType.Card);
  const [view, setView] = useState<PaymentMethodView>('add-new');

  const {
    data: fundingSources,
    isFetching: isFetchingFundingSources,
    verifyCard,
    create,
    refetch,
  } = useFundingSources();

  const handleSelectFundingSource = useCallback(
    (fundingSource: FundingSource) => {
      setFundingSourceId(fundingSource.id);
      setView('selected');
    },
    [setFundingSourceId, setView]
  );

  const fundingSource = useMemo(
    () => fundingSources?.find((x) => x.id === fundingSourceId) || fundingSources?.[0],
    [fundingSourceId, fundingSources]
  );

  useEffect(() => {
    if (fundingSource) {
      handleSelectFundingSource(fundingSource);
    }
  }, [fundingSource, handleSelectFundingSource]);

  const [isVerifyingCard, setIsVerifyingCard] = useState(false);

  const createNewFundingSourceAndRefetch = async (fields: CardFundingSourceFormFields, cardParams?: CardParams) => {
    if (cardParams) {
      const { firstName, lastName, postalCode, state, line1, city } = fields;
      const address = { postalCode, state, line1, city };
      const details = { ...cardParams, cardOwner: { firstName, lastName }, address };
      const result = await create({ type: 'card', details });

      setFundingSourceId(result.id);

      await refetch();
    }
  };

  const onCreateCardDetailsDone = async (fields: CardFundingSourceFormFields) => {
    if (bt) {
      try {
        const { cardNumber, cardExpiration, cardVerificationCode } = fields;

        setIsVerifyingCard(true);

        const cardParams = await tokenize({
          cardNumber,
          cardExpiration,
          cardVerificationCode,
        });

        try {
          await verifyCard({
            cardBin: cardParams.cardBin,
            tabapayToken: cardParams.tabapayToken,
            tokenProvider: 'basistheory',
          });

          await createNewFundingSourceAndRefetch(fields, cardParams);
        } catch (error) {
          toast({
            type: 'error',
            title: formatMessage('activities.subscription.checkout.addCardFailed'),
          });
        } finally {
          setIsVerifyingCard(false);
        }
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('activities.subscription.checkout.addCardFailed'),
        });
        setIsVerifyingCard(false);
      }
    }
  };

  const handleNewCardSelection = () => {
    track('Organization', 'Click', {
      Intent: 'choose-new-pm-type',
      Type: 'debit-credit-card',
    });
    setFundingSourceType(FundingSourceType.Card);
    setView('add-new');
  };

  const navigateToAddBankAccount = () => goToAddBankAccount({ returnUrl: location.pathname });

  const handleNewBankAccountSelection = () => {
    track('Organization', 'Click', {
      Intent: 'choose-new-pm-type',
      Type: 'bank-account',
    });
    setFundingSourceType(FundingSourceType.BankAccount);
    navigateToAddBankAccount();
  };

  const onSubmit = (fields: CardFundingSourceFormFields) => {
    onCreateCardDetailsDone(fields).catch((error) => {
      Logger.log(`Error: ${error as string}`, 'error');
    });
  };

  const getView = () => {
    switch (view) {
      case 'all':
        return (
          <Container border="regular" paddingX="m" paddingY="m">
            <Group variant="vertical" width="full">
              <Group justifyContent="flex-end">
                <NakedButton
                  label={formatMessage('activities.subscription.checkout.fundingSourceSelection.button.close')}
                  variant="secondary"
                  onClick={() => setView('selected')}
                />
              </Group>
              <FundingSourceGroupList
                fundingSources={fundingSources || []}
                onSelected={handleSelectFundingSource}
                selectedId={fundingSourceId}
                onAddBank={navigateToAddBankAccount}
                onAddCard={() => setView('add-new')}
              />
            </Group>
          </Container>
        );
      case 'add-new':
        return (
          <AddNewFundingSourceView
            fundingSourceType={fundingSourceType}
            onSubmit={onSubmit}
            onAddBank={handleNewBankAccountSelection}
            onAddCard={handleNewCardSelection}
            onClose={() => setView('selected')}
            hasFundingSources={!!fundingSources?.length}
          />
        );
      default:
        return fundingSource ? (
          <FundingSourceCard fundingSource={fundingSource} onChangeClick={() => setView('all')} />
        ) : null;
    }
  };

  return (
    <Group variant="vertical" spacing="xxxs" data-component="SubscriptionPaymentMethod">
      <Container paddingBottom="s">
        <Text textStyle="body2Semi" color="neutral.darker">
          {formatMessage('activities.subscription.checkout.paymentMethod.title')}
        </Text>
      </Container>
      <LoadingContainer isLoading={isFetchingFundingSources || isVerifyingCard}>{getView()}</LoadingContainer>
    </Group>
  );
};
