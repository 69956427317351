import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Link, StatusIconSolid, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { NotificationBanner } from '../NotificationBanner';

export const CompleteMandatoryDetailsWarningBanner = ({
  onOpenCompleteRequiredDetailsDialog,
}: {
  onOpenCompleteRequiredDetailsDialog: VoidFunction;
}) => {
  const isSmallScreen = useBreakpointValue({ xs: true, s: true, l: false });
  const { formatMessage } = useMelioIntl();

  return (
    <NotificationBanner
      variant="warning"
      data-testid="kyc-warning-banner"
      rounded
      centered={false}
      narrow
      content={
        <Box
          display="flex"
          gap="2"
          flexDirection="row"
          alignItems={isSmallScreen ? 'start' : 'center'}
          textAlign="start"
        >
          <StatusIconSolid variant="warning" size="medium" />
          <Text textStyle="body3" color="neutral.darkest">
            {formatMessage('widget.kyc.warning.banner.text')}{' '}
            <Link
              label={formatMessage('widget.kyc.warning.banner.button')}
              size="medium"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onOpenCompleteRequiredDetailsDialog();
              }}
              isBold
              variant="standalone"
            />
          </Text>
        </Box>
      }
    />
  );
};
