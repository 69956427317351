/* eslint-disable max-lines */
import { Loader, StatusModal, useBreakpointValue, useDisclosure, useToast } from '@melio/penny';
import { Traits, useAnalytics } from '@melio/platform-analytics';
import {
  FileInfo,
  ScannedInvoice,
  useAccountingPlatforms,
  useBill,
  useBills,
  useScannedInvoice,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { getDollarsFromCents, useDateUtils } from '@melio/platform-utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AddBillV2FormActivity } from '../add-bill/AddBillV2Form/AddBillV2Form.activity';
import {
  AddBillV2DFormInitialValues,
  AddBillV2FormValuesResult,
  AddBillV2OriginEnum,
  AddBillV2SubmitType,
} from '../add-bill/AddBillV2Form/types';
import { AmountsMismatchModal } from './components/AmountsMismatchModal';
import { CustomFooter } from './components/ReviewScannedInvoiceFooter';
import { useScannedInvoiceReviewAnalytics } from './hooks/useScannedInvoiceReviewAnalytics';
import {
  convertScannedInvoicesToInitialValues,
  convertToBillLineItems,
  getInitialValueLineItemWithAmount,
} from './utils';

type MarkAsPaidDialogProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onMarkAsPaidSubmit: (accountingPlatformPaymentAccountId?: string) => Promise<void>;
  isLoading: boolean;
  hasAccountingPlatform?: boolean;
  amount: number;
};
type ReviewScannedInvoiceActivityProps = {
  scannedInvoiceId: string;
  goToSchedulePayment: (billId: string) => void;
  invoicePreview: React.ElementType<{ scannedInvoice: ScannedInvoice; fileInfo: FileInfo }>;
  markAsPaidDialog: React.ElementType<MarkAsPaidDialogProps>;
  goToBillsTab: () => void;
  onClose: VoidFunction;
};

export const ReviewScannedInvoiceActivity = ({
  scannedInvoiceId,
  goToSchedulePayment,
  markAsPaidDialog: MarkAsPaidDialog,
  goToBillsTab,
  onClose,
}: ReviewScannedInvoiceActivityProps) => {
  const submittedResult = useRef<AddBillV2FormValuesResult | undefined>();
  const { formatMessage, formatCurrency } = useMelioIntl();
  useSetDocumentTitle(formatMessage('widgets.ReviewScannedInvoice.pageTitle'));
  const { create: createBill, isMutating } = useBills({ enabled: false });
  const { _mutations, isMutating: isBeingMarkedAsPaid } = useBill();
  const {
    isFetching: accountingPlatformIsLoading,
    hasAccountingPlatform,
    activeAccountingPlatform,
  } = useAccountingPlatforms();
  const [isLineItemsEnabledOnMobile] = useFeature<boolean>(FeatureFlags.IsLineItemsEnabledOnMobile, true);
  const { toast } = useToast();
  const isMobile = useBreakpointValue({ xs: true, s: false }, { ssr: false } as never);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const {
    isOpen: isMarkAsPaidModalOpen,
    onOpen: onMarkAsPaidModalOpen,
    onClose: onMarkAsPaidModalClose,
  } = useDisclosure();
  const {
    delete: deleteScannedInvoice,
    isMutating: isUpdating,
    data: scannedInvoice,
  } = useScannedInvoice({ id: scannedInvoiceId });
  const [amountMismatch, setAmountMismatch] = useState<number>(0);
  const [markAsPaidFeatureFlag] = useFeature<boolean>(FeatureFlags.MarkAsPaid, false);
  const { createDate } = useDateUtils();
  const { setTraits, track } = useAnalytics();
  const setBillTraits = () => {
    const traits: Traits = {
      create_a_bill: true,
      last_bill_added_date: createDate().toISOString(),
    };

    setTraits(traits);
  };

  const { eventProperties } = useScannedInvoiceReviewAnalytics({ scannedInvoice });

  const isLineItemsAvailable = isMobile ? isLineItemsEnabledOnMobile : true;

  useEffect(() => {
    if (eventProperties) {
      track('DraftBillReview', 'Viewed', eventProperties);
    }
  }, [eventProperties, track]);

  useEffect(() => {
    if (isDeleteModalOpen && eventProperties) {
      track('DraftBillReviewDeleteApproval', 'Viewed', eventProperties);
    }
  }, [eventProperties, isDeleteModalOpen, track]);

  const { values: originalValues, mismatchedAmount } = useMemo(
    () =>
      scannedInvoice
        ? convertScannedInvoicesToInitialValues(scannedInvoice, isLineItemsAvailable)
        : { values: undefined, mismatchedAmount: 0 },
    [scannedInvoice, isLineItemsAvailable]
  );
  const [initialValues, setInitialValues] = useState<AddBillV2DFormInitialValues | undefined>();

  useEffect(() => {
    if (!scannedInvoice) {
      return;
    }
    if (mismatchedAmount) {
      setAmountMismatch(mismatchedAmount);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mismatchedAmount]);

  const handleSubmit = async ({
    amount,
    dueDate,
    noteToSelf,
    vendorId,
    invoiceNumber,
    lineItems,
    currency,
  }: AddBillV2FormValuesResult) => {
    if (!scannedInvoice || !dueDate) {
      throw Error();
    }
    try {
      const billCreationPayload = {
        invoice: {
          number: invoiceNumber,
          fileId: scannedInvoice.fileId,
        },
        amount: Number(amount),
        dueDate: dueDate?.toISOString(),
        vendorId: vendorId ?? '',
        note: noteToSelf,
        scannedInvoiceId: scannedInvoice.id,
        lineItems: convertToBillLineItems(lineItems, activeAccountingPlatform),
        ...(currency && { currency }),
      };
      return await createBill(billCreationPayload);
    } catch (e) {
      toast({ type: 'error', title: formatMessage('widgets.ReviewScannedInvoice.toast.error') });
      throw e;
    }
  };

  const onContinue = (params: AddBillV2FormValuesResult) => {
    track('DraftBillReviewContinue', 'Submitted', eventProperties);
    handleSubmit(params)
      .then(({ id: billId, amount, vendor, currency }) => {
        track('DraftBillReviewContinue', 'Saved', { ...eventProperties, BillId: billId });
        toast({
          type: 'success',
          title: formatMessage('activities.addBillV2.billForm.success.toast', {
            amount: formatCurrency(getDollarsFromCents(amount), currency || 'USD'),
            vendorName: vendor?.name || '',
          }),
        });
        setBillTraits();
        goToSchedulePayment(billId);
      })
      .catch(() => {
        track('DraftBillReviewContinue', 'Failed', eventProperties);
      });
  };

  const onSaveAndClose = (params: AddBillV2FormValuesResult) => {
    track('DraftBillReviewSaveAndClose', 'Submitted', eventProperties);
    handleSubmit(params)
      .then(({ id, amount, vendor }) => {
        track('DraftBillReviewSaveAndClose', 'Saved', { ...eventProperties, BillId: id });
        toast({
          type: 'success',
          title: formatMessage('activities.addBillV2.billForm.success.toast', {
            amount: formatCurrency(getDollarsFromCents(amount)),
            vendorName: vendor?.name || '',
          }),
        });
        setBillTraits();
        goToBillsTab();
      })
      .catch(() => {
        track('DraftBillReviewSaveAndClose', 'Failed', eventProperties);
      });
  };

  const onSubmit = (formValues: AddBillV2FormValuesResult, saveType: AddBillV2SubmitType) => {
    switch (saveType) {
      case AddBillV2SubmitType.CONTINUE_TO_PAY:
        onContinue(formValues);
        break;
      case AddBillV2SubmitType.MARK_AS_PAID:
        onMarkAsPaid(formValues);
        break;
      case AddBillV2SubmitType.SAVE_AND_CLOSE:
        onSaveAndClose(formValues);
        break;
    }
  };

  const onDeleteModalClose = () => {
    track('DraftBillReviewDeleteApprovalClose', 'Chose', eventProperties);
    setIsDeleteModalOpen(false);
  };

  const onDeleteModalCancel = () => {
    track('DraftBillReviewDeleteApprovalCancel', 'Chose', eventProperties);
    setIsDeleteModalOpen(false);
  };

  const onDeleteButtonClicked = () => {
    track('DraftBillReviewDelete', 'Chose', eventProperties);
    setIsDeleteModalOpen(true);
  };

  const onMarkAsPaid = (params: AddBillV2FormValuesResult) => {
    submittedResult.current = params;
    track('DraftBillReviewMarkAsPaid', 'Chose', eventProperties);
    onMarkAsPaidModalOpen();
  };

  const handleClose = () => {
    track('DraftBillReviewSaveAndClose', 'Click', eventProperties);
    onClose();
  };

  const onMarkAsPaidSubmit = async (accountingPlatformPaymentAccountId?: string) => {
    if (!submittedResult.current) {
      return;
    }
    const bill = await handleSubmit(submittedResult.current);
    await _mutations.markAsPaid.mutateAsync({
      id: bill.id,
      params: { isPaid: true, accountingPlatformPaymentAccountId },
    });
  };

  const onDelete = async () => {
    if (!scannedInvoice) {
      return;
    }
    track('DraftBillReviewDeleteApprovalDelete', 'Submitted', eventProperties);
    try {
      await deleteScannedInvoice();
      track('DraftBillReviewDeleteApprovalDelete', 'Saved', eventProperties);
      toast({ type: 'informative', title: getDeleteToastSuccessTitle() });
      goToBillsTab();
    } catch (e) {
      toast({ type: 'error', title: formatMessage('widgets.ReviewScannedInvoice.toast.delete.error') });
      setIsDeleteModalOpen(false);
    }
  };

  const handleAutoAddLines = () => {
    if (!originalValues) {
      return;
    }
    const values = { ...originalValues };
    values.lineItems = [
      ...(originalValues.lineItems ?? []),
      getInitialValueLineItemWithAmount(
        amountMismatch,
        formatMessage('widgets.ReviewScannedInvoice.amountsMismatch.lineItem.desc')
      ),
    ];
    setInitialValues(values);
    setAmountMismatch(0);
  };

  const handleRemoveLines = () => {
    if (!originalValues) {
      return;
    }
    const values = {
      ...originalValues,
      lineItems: [
        getInitialValueLineItemWithAmount(
          Number(originalValues.amount ?? '') ?? 0,
          formatMessage('widgets.ReviewScannedInvoice.amountsMismatch.lineItem.desc')
        ),
      ],
    };
    setInitialValues(values);
    setAmountMismatch(0);
  };
  if (!scannedInvoice) {
    return null;
  }
  const { file } = scannedInvoice;
  if (accountingPlatformIsLoading) {
    return <Loader />;
  }

  const getDeleteModalDescription = () => {
    if (scannedInvoice?.amount && scannedInvoice?.invoiceNumber) {
      return formatMessage('widgets.ReviewScannedInvoice.deleteModal.contentWithInvoiceNumberAndAmount', {
        invoiceNumber: scannedInvoice.invoiceNumber,
        amount: formatCurrency(Number(scannedInvoice.amount)),
      });
    }

    if (scannedInvoice?.amount) {
      return formatMessage('widgets.ReviewScannedInvoice.deleteModal.contentWithAmount', {
        amount: formatCurrency(Number(scannedInvoice.amount)),
      });
    }

    if (scannedInvoice?.invoiceNumber) {
      return formatMessage('widgets.ReviewScannedInvoice.deleteModal.contentWithInvoiceNumber', {
        invoiceNumber: scannedInvoice.invoiceNumber,
      });
    }

    return formatMessage('widgets.ReviewScannedInvoice.deleteModal.content');
  };

  const getDeleteToastSuccessTitle = () => {
    if (scannedInvoice?.vendorName) {
      return formatMessage('widgets.ReviewScannedInvoice.toast.delete.successWithVendorName', {
        vendorName: scannedInvoice.vendorName,
      });
    }

    return formatMessage('widgets.ReviewScannedInvoice.toast.delete.success');
  };

  const actualInitialValues = initialValues ?? originalValues;
  return (
    <>
      <AddBillV2FormActivity
        onClose={onClose}
        onBack={onClose}
        onDone={onSubmit}
        initialValues={actualInitialValues}
        allowModifyingFile
        fileInfo={file ?? undefined}
        isSaving={isMutating}
        origin={AddBillV2OriginEnum.ScannedInvoice}
        customFooter={({ onSubmitForm, isLoading }) => (
          <CustomFooter
            onSubmitForm={onSubmitForm}
            onDeleteButtonClicked={onDeleteButtonClicked}
            markAsPaidFeatureFlag={markAsPaidFeatureFlag}
            isLoading={isLoading || isMutating}
            onClose={handleClose}
          />
        )}
      />
      <AmountsMismatchModal
        mismatchedValue={`$${amountMismatch}`}
        isOpen={!!amountMismatch && isLineItemsAvailable}
        onAdd={handleAutoAddLines}
        onRemove={handleRemoveLines}
      />
      <StatusModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        variant="cancel"
        header={formatMessage('widgets.ReviewScannedInvoice.deleteModal.title')}
        description={getDeleteModalDescription()}
        primaryButton={{
          label: formatMessage('widgets.ReviewScannedInvoice.deleteModal.confirmText'),
          onClick: onDelete,
          variant: 'primary',
          isLoading: isUpdating,
        }}
        secondaryButton={{
          label: formatMessage('widgets.ReviewScannedInvoice.deleteModal.cancelText'),
          onClick: onDeleteModalCancel,
          variant: 'tertiary',
        }}
      />
      {markAsPaidFeatureFlag && (
        <MarkAsPaidDialog
          isOpen={isMarkAsPaidModalOpen}
          onClose={onMarkAsPaidModalClose}
          onMarkAsPaidSubmit={onMarkAsPaidSubmit}
          isLoading={isMutating || isBeingMarkedAsPaid}
          hasAccountingPlatform={hasAccountingPlatform}
          amount={scannedInvoice.amount ?? 0}
        />
      )}
    </>
  );
};
