import { Box } from '@chakra-ui/react';
import { isEinOnlyBusinessType, masks, useMtlFormValues, useMtlMessages } from '@melio/ap-domain';
import { Form, SectionBanner, useMelioForm } from '@melio/penny';
import { LegalInfoField, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { CompleteLegalInfoFormWidgetFields, ExistingTaxIdIsEinOption } from '../../types';

export type CompleteLegalInfoUseFormReturnType = ReturnType<typeof useMelioForm<CompleteLegalInfoFormWidgetFields>>;

type BusinessTypeAndTaxInfoProps = CompleteLegalInfoUseFormReturnType & {
  missingLegalInfoFields: LegalInfoField[];
  existingTaxInfoIdentifier?: string;
  existingBusinessType?: boolean;
  companyName?: string;
};

const existingTaxIdEinOptions: ExistingTaxIdIsEinOption[] = ['Yes', 'No'];

const buildMaskedTaxIdValue = (input?: string) => (input ? input.slice(-4).padStart(input.length, '•') : '');

type UseTaxFormFieldProps = Pick<CompleteLegalInfoUseFormReturnType, 'watch' | 'setValue' | 'trigger' | 'formState'> &
  Pick<BusinessTypeAndTaxInfoProps, 'existingTaxInfoIdentifier' | 'missingLegalInfoFields'>;

const useTaxFormFields = ({
  watch,
  trigger,
  formState,
  setValue,
  existingTaxInfoIdentifier,
  missingLegalInfoFields,
}: UseTaxFormFieldProps) => {
  const selectedBusinessType = watch('businessType') || null;
  const selectedTaxIdType = watch('taxInfoType') || undefined;
  const existingTaxIdIsEin = watch('existingTaxIdIsEin');
  const einOnlyBusinessType = isEinOnlyBusinessType(selectedBusinessType);
  const watchBusinessType = watch('businessType');

  useEffect(() => {
    if (einOnlyBusinessType) {
      setValue('taxInfoType', TaxIdTypeEnum.Ein);
    } else {
      setValue('existingTaxIdIsEin', 'Yes');
    }
  }, [setValue, einOnlyBusinessType]);

  const isTaxInfoFieldHidden =
    !!existingTaxInfoIdentifier ||
    !selectedBusinessType ||
    einOnlyBusinessType ||
    !missingLegalInfoFields.includes('taxInfoType');

  useEffect(() => {
    const shouldTriggerValidations = formState.isSubmitted;
    if (shouldTriggerValidations && (!isTaxInfoFieldHidden || (einOnlyBusinessType && watchBusinessType))) {
      void trigger('taxInfoIdentifier');
    }
  }, [trigger, selectedTaxIdType, isTaxInfoFieldHidden, formState.isSubmitted, einOnlyBusinessType, watchBusinessType]);

  useEffect(() => {
    if (!isTaxInfoFieldHidden && selectedTaxIdType) {
      setValue('taxInfoType', selectedTaxIdType);
    }
  }, [isTaxInfoFieldHidden, selectedTaxIdType, setValue]);

  useEffect(() => {
    if (selectedTaxIdType) {
      setValue('taxInfoIdentifier', '');
    }
  }, [selectedTaxIdType, setValue]);

  return {
    selectedBusinessType,
    selectedTaxIdType,
    isEinOnlyBusinessType: einOnlyBusinessType,
    userOverridesTaxId: existingTaxIdIsEin === 'No',
    isTaxInfoFieldHidden,
  };
};

export const BusinessTypeAndTaxInfo = ({
  companyName,
  missingLegalInfoFields,
  existingTaxInfoIdentifier,
  existingBusinessType,
  watch,
  setValue,
  registerField,
  formState,
  trigger,
}: BusinessTypeAndTaxInfoProps) => {
  const { selectedBusinessType, selectedTaxIdType, isEinOnlyBusinessType, userOverridesTaxId, isTaxInfoFieldHidden } =
    useTaxFormFields({
      watch,
      setValue,
      formState,
      trigger,
      existingTaxInfoIdentifier,
      missingLegalInfoFields,
    });
  const { formatMessage } = useMelioIntl();
  const {
    labels: { company: companyLabels },
    placeholders,
    emptyState,
  } = useMtlMessages();
  const { businessTypeOptions, taxIdTypeOptions } = useMtlFormValues();

  const taxIdFormFieldCommonProps = {
    helperTextProps: {
      label: isEinOnlyBusinessType && selectedBusinessType ? companyLabels.einOnlyHelperText(selectedBusinessType) : '',
    },
    placeholder: placeholders.taxId(selectedTaxIdType),
  };

  const taxIdTooltipLabel = formatMessage(`widgets.completeLegalInfo.taxInfo.identifier.existing.tooltip`);
  const isReadOnlyTaxIdIdentifierHidden = !existingTaxInfoIdentifier || !selectedBusinessType;

  return (
    <>
      <Form.Select
        {...registerField('businessType')}
        isReadOnly={existingBusinessType}
        isHidden={!missingLegalInfoFields.includes('businessType')}
        labelProps={{
          label: companyLabels.businessType,
          ...(existingBusinessType && {
            tooltipProps: {
              label: formatMessage(`widgets.completeLegalInfo.businessType.existing.tooltip`),
            },
          }),
        }}
        options={businessTypeOptions}
        placeholder={placeholders.businessType}
        emptyState={{ label: emptyState.businessType }}
      />

      {/* No TaxId section */}
      <Box
        data-testid="TaxIdForm-nonExisting"
        style={{ all: 'inherit', ...(existingTaxInfoIdentifier && { display: 'none' }) }}
      >
        <Form.RadioGroup
          {...registerField('taxInfoType')}
          isRequired
          isHidden={isTaxInfoFieldHidden}
          labelProps={{ label: companyLabels.taxIdType }}
          options={taxIdTypeOptions(selectedBusinessType)}
        />
        <Form.SecuredTextField
          {...registerField('taxInfoIdentifier')}
          maskProps={{
            mask: masks.taxId[selectedTaxIdType || 'any'],
          }}
          isHidden={
            !!existingTaxInfoIdentifier ||
            !selectedBusinessType ||
            (!missingLegalInfoFields.includes('taxInfoIdentifier') && !missingLegalInfoFields.includes('taxInfoType'))
          }
          labelProps={{
            label: companyLabels.taxId(selectedTaxIdType),
          }}
          helperTextProps={{
            label:
              isEinOnlyBusinessType && selectedBusinessType
                ? companyLabels.einOnlyHelperText(selectedBusinessType)
                : '',
          }}
          placeholder={placeholders.taxId(selectedTaxIdType)}
        />
      </Box>

      {/* With Tax ID section */}
      <Box
        data-testid="TaxIdForm-existing"
        style={{ all: 'inherit', ...(!existingTaxInfoIdentifier && { display: 'none' }) }}
      >
        {existingTaxInfoIdentifier && selectedBusinessType && (
          <SectionBanner
            description={formatMessage(
              isEinOnlyBusinessType
                ? 'widgets.completeLegalInfo.taxInfo.identifier.banner.EIN'
                : 'widgets.completeLegalInfo.taxInfo.identifier.banner.other'
            )}
            data-testid="taxInfoBanner"
          />
        )}
        <Form.SecuredTextField
          {...registerField('taxInfoIdentifier')}
          {...taxIdFormFieldCommonProps}
          labelProps={{
            label: formatMessage(`widgets.completeLegalInfo.taxInfo.identifier.unknown.label`),
            tooltipProps: taxIdTooltipLabel
              ? {
                  label: taxIdTooltipLabel,
                }
              : undefined,
          }}
          isHidden={isReadOnlyTaxIdIdentifierHidden}
          value={buildMaskedTaxIdValue(existingTaxInfoIdentifier)}
          isReadOnly
          isTextVisible
          isRequired={!isReadOnlyTaxIdIdentifierHidden}
        />
        <Form.RadioGroup
          {...registerField('existingTaxIdIsEin')}
          isHidden={!existingTaxInfoIdentifier || !isEinOnlyBusinessType || !selectedBusinessType}
          labelProps={{
            label: formatMessage('widgets.completeLegalInfo.taxInfo.existingTaxIdIsEin.label', { companyName }),
          }}
          options={existingTaxIdEinOptions.map((value) => ({
            value,
            mainLabelProps: {
              label: formatMessage(`widgets.completeLegalInfo.taxInfo.existingTaxIdIsEin.option.${value}.label`),
            },
          }))}
        />
        <Form.RadioGroup
          {...registerField('taxInfoType')}
          isRequired
          isHidden={!existingTaxInfoIdentifier || isEinOnlyBusinessType || !selectedBusinessType}
          labelProps={{
            label: formatMessage('widgets.completeLegalInfo.taxInfo.type.label.confirm'),
          }}
          options={taxIdTypeOptions(selectedBusinessType)}
        />
        <Form.SecuredTextField
          {...registerField('taxIdEinOverride')}
          {...taxIdFormFieldCommonProps}
          isHidden={!existingTaxInfoIdentifier || !selectedBusinessType || !userOverridesTaxId}
          labelProps={{
            label: companyLabels.taxId(TaxIdTypeEnum.Ein),
          }}
          maskProps={{
            mask: masks.taxId[TaxIdTypeEnum.Ein],
          }}
        />
      </Box>
    </>
  );
};
