import { Group, GroupProps, Link, Text, useBreakpointValue } from '@melio/penny';
import { Loan, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useContext } from 'react';

import { LoanDrawerContext } from '../../../LoanDrawerContext';
import { RepaymentSectionProgress } from './RepaymentSectionProgress';
import { ActiveOrCompletedLoan } from './types';

export const RepaymentSection = ({ payment }: { payment: Override<Payment, { loan: Loan }> }) => {
  const { onViewPayment } = useContext(LoanDrawerContext);
  const { formatMessage, formatCurrency } = useMelioIntl();
  const loanDescriptionGroupVariant: GroupProps['variant'] = useBreakpointValue({ xs: 'vertical', xl: 'horizontal' });

  const {
    loan: {
      status,
      remainingAmount,
      fees: { totalAmount, financingTotalFee, financingTotalFeePercent },
    },
  } = payment;

  return (
    <Group variant="vertical">
      <Group variant="vertical" spacing="s">
        <Text textStyle="heading2Semi">{formatMessage('activities.payDashboard.drawer.body.loan.active.heading')}</Text>
        <Group spacing="xs" variant={loanDescriptionGroupVariant}>
          <Text textStyle="body3">
            {formatMessage('activities.payDashboard.drawer.body.loan.description', {
              amount: formatCurrency(payment.amount),
              vendorName: payment.vendor?.name,
            })}
          </Text>
          <Link
            size="medium"
            label={formatMessage('activities.payDashboard.drawer.body.loan.paymentLink')}
            href="#"
            variant="standalone"
            onClick={() => {
              onViewPayment({ id: payment.id });
            }}
          />
        </Group>
        {(status === 'active' || status === 'completed') && (
          <RepaymentSectionProgress loan={payment.loan as ActiveOrCompletedLoan} />
        )}
      </Group>
      <Group variant="vertical" spacing="xs">
        {(status === 'failed' || status === 'handled-by-credit-key' || status === 'canceled') && (
          <Group width="full" alignItems="stretch" justifyContent="space-between">
            <Text textStyle="body2Semi" color="neutral.darker">
              {formatMessage('activities.payDashboard.drawer.body.loan.unhappy.remainingAmount')}
            </Text>
            <Text textStyle="body2Semi" color="neutral.darker">
              {formatCurrency(remainingAmount)}
            </Text>
          </Group>
        )}
        <Group width="full" alignItems="stretch" justifyContent="space-between">
          <Text textStyle="body2Semi">
            {formatMessage('activities.payDashboard.drawer.body.loan.active.total.label')}
          </Text>
          <Text textStyle="body2Semi">{formatCurrency(totalAmount || 0)}</Text>
        </Group>
        <Group width="full" alignItems="stretch" justifyContent="space-between">
          <Text textStyle="body2" color="neutral.darker">
            {formatMessage('activities.payDashboard.drawer.body.loan.active.total.description', {
              percentage: financingTotalFeePercent,
            })}
          </Text>
          <Text textStyle="body2" color="neutral.darker">
            {formatCurrency(financingTotalFee || 0)}
          </Text>
        </Group>
      </Group>
    </Group>
  );
};
