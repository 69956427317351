import { TaxRate, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, Badge, Group, Icon, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useTaxRateActionsItems } from '../hooks';

export type TaxRatesTableListItemProps = {
  taxRate: TaxRate;
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
};

export const TaxRatesTableListItem = forwardRef<TaxRatesTableListItemProps>(
  ({ taxRate, onEdit, onDelete, ...props }, ref) => {
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const { formatMessage } = useMelioIntl();
    const actions = useTaxRateActionsItems({ taxRate, onEdit, onDelete });

    return (
      <ActionsDropdownMenu
        isOpen={isActionsMenuOpen}
        onOpenChange={setIsActionsMenuOpen}
        trigger={<TaxRatesTableListItemContent taxRate={taxRate} ref={ref} {...props} />}
        label={formatMessage('ar.settings.activities.taxRatesTable.listItem.actionsMenu.label')}
        items={actions}
        ref={ref}
        {...props}
      />
    );
  }
);
TaxRatesTableListItem.displayName = 'TaxRatesTableListItem';

const TaxRatesTableListItemContent = forwardRef<{ taxRate: TaxRate }>(({ taxRate, ...props }, ref) => {
  const { formatPercents, formatMessage } = useMelioIntl();

  return (
    <Group
      spacing="xs"
      variant="vertical"
      {...props}
      ref={ref}
      data-testid={`tax-rate-list-item-${taxRate.id}`}
      data-component={TaxRatesTableListItemContent.displayName}
    >
      <Group spacing="xxs" alignItems="center">
        <Group justifyContent="space-between" width="full">
          <Text textStyle="body2Semi" data-testid="tax-rate-name">
            {taxRate.name}
          </Text>
          <Text textStyle="body2Semi" data-testid="tax-rate-value">
            {formatPercents(taxRate.rate)}
          </Text>
        </Group>

        <Icon type="chevron-right" size="small" />
      </Group>
      {taxRate.isDefault && (
        <Badge
          data-testid="is-default-badge"
          type="secondary"
          status="neutral"
          label={formatMessage('ar.settings.activities.catalogItemsTable.listItem.isDefault.badge.label')}
        />
      )}
    </Group>
  );
});
TaxRatesTableListItemContent.displayName = 'TaxRatesTableListItemContent';
