import { IconKey } from '@melio/penny';
import { FreeChecksData, FundingSource, SupportedDeliveryMethodTypeOption } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { useDeliveryMethodFee } from '../../../utils';

export const useAddDeliveryMethodUtils = ({
  type: deliveryMethodType,
  supported,
  link,
  fundingSourceType,
  freeChecks,
  reason,
  isInternationalFx,
}: {
  link?: React.ReactNode;
  fundingSourceType?: FundingSource['type'];
  freeChecks?: FreeChecksData;
  isInternationalFx?: boolean;
} & SupportedDeliveryMethodTypeOption) => {
  const { getFee, isFetched: isFeeFetched } = useDeliveryMethodFee(fundingSourceType || 'bank-account');
  const { settings } = useConfig();
  const { formatMessage } = useMelioIntl();

  const [isCloverFreeAchPromotion] = useFeature<boolean>(FeatureFlags.CloverFreeAchPromotion, false);

  const isNewDeliveryMethodType = (deliveryMethodType: SupportedDeliveryMethodTypeOption['type']) =>
    ['international-account', 'virtual-card'].includes(deliveryMethodType);

  const getDisabledDescription = (): string | undefined => {
    if (supported) {
      return undefined;
    }
    const isUnsupportedCardFundingSourceInUse = fundingSourceType === 'card';
    const isDisabledDueToBankPayment = reason === 'vendorIsBank';

    if (isDisabledDueToBankPayment) {
      switch (deliveryMethodType) {
        case 'bank-account':
        case 'virtual-account':
        case 'virtual-card':
        case 'international-account':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.disabledReason.bankRestriction');
      }
    }

    if (isUnsupportedCardFundingSourceInUse) {
      switch (deliveryMethodType) {
        case 'international-account':
          return formatMessage(
            'widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.description.disabled.unsupportedCardFundingSource'
          );
      }
    }

    switch (deliveryMethodType) {
      case 'virtual-card':
        return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.description.disabled');
      default:
        return undefined;
    }
  };

  return {
    getIsDisabled: () => !supported,

    getBadgeLabel: (): string | undefined =>
      isNewDeliveryMethodType(deliveryMethodType)
        ? formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.badge.new')
        : undefined,

    getBadgeAriaLabel: (): string | undefined =>
      isNewDeliveryMethodType(deliveryMethodType)
        ? formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.badge.new.ariaLabel')
        : undefined,

    getTooltipLabel: (): string | undefined =>
      settings.eoyCheck.enabled && deliveryMethodType === 'paper-check'
        ? formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.check.eoyTooltipDescription')
        : undefined,

    getLabel: () => {
      switch (deliveryMethodType) {
        case 'bank-account':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.bankAccount.label');
        case 'paper-check':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.check.label');
        case 'domestic-wire-account':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.domesticWire.label');
        case 'virtual-account':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.unilateral.label');
        case 'virtual-card':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.label');
        case 'international-account':
          if (isInternationalFx) {
            return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.internationalFX.label');
          }
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.label');
      }
    },

    getDescription: (): {
      description: string;
      actionLabel?: string;
    } => {
      const unsupportedReason = getDisabledDescription();
      if (unsupportedReason) {
        return { description: unsupportedReason };
      }
      const fee = getFee(deliveryMethodType);

      const fundingSourceMessagePart = fundingSourceType ? 'hasFundingSource' : 'withoutFundingSource';

      switch (deliveryMethodType) {
        case 'bank-account': {
          if (fee) {
            return {
              description: formatMessage(
                `widgets.deliveryMethods.addDeliveryMethodCard.bankAccount.description.withFee.${fundingSourceMessagePart}`,
                { fee }
              ),
            };
          } else {
            if (isCloverFreeAchPromotion) {
              return {
                description: formatMessage(
                  'widgets.deliveryMethods.addDeliveryMethodCard.bankAccount.description.freeAchPromotion'
                ),
              };
            }
            return {
              description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.bankAccount.description.noFee'),
            };
          }
        }
        case 'paper-check': {
          if (fee) {
            const freeChecksPromotionMessagePart = freeChecks?.total ? 'withChecksPromotion' : 'noChecksPromotion';

            return {
              description: formatMessage(
                `widgets.deliveryMethods.addDeliveryMethodCard.check.description.withFee.${fundingSourceMessagePart}.${freeChecksPromotionMessagePart}`,
                { fee, monthlyFreeChecksPromotion: freeChecks?.total, link }
              ),
            };
          } else {
            return {
              description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.check.description.noFee', {
                link,
              }),
            };
          }
        }
        case 'domestic-wire-account':
          if (fee) {
            return {
              description: formatMessage(
                'widgets.deliveryMethods.addDeliveryMethodCard.domesticWire.description.withFee',
                {
                  fee,
                }
              ),
            };
          } else {
            return {
              description: formatMessage(
                'widgets.deliveryMethods.addDeliveryMethodCard.domesticWire.description.noFee'
              ),
            };
          }
        case 'virtual-account':
          return {
            description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.unilateral.description'),
          };
        case 'virtual-card': {
          const actionLabel = formatMessage(
            'widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.description.action'
          );

          return {
            description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.description'),
            actionLabel,
          };
        }
        case 'international-account':
          if (isInternationalFx) {
            return {
              description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.internationalFX.description'),
            };
          }
          return fee
            ? {
                description: formatMessage(
                  'widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.description.withFee',
                  {
                    fee,
                  }
                ),
              }
            : {
                description: formatMessage(
                  'widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.description.noFee'
                ),
              };
      }
    },

    getIconKey: (): IconKey => {
      switch (deliveryMethodType) {
        case 'bank-account':
          return 'bank';
        case 'paper-check':
          return 'paper-check';
        case 'domestic-wire-account':
          return 'bank';
        case 'virtual-account':
          return 'email';
        case 'international-account':
          if (isInternationalFx) {
            return 'foreign-currency';
          }
          return 'international';
        case 'virtual-card':
          return 'single-use-card';
      }
    },

    isLoading: !isFeeFetched,
  };
};
