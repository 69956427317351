/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { Box } from '@chakra-ui/react';
import { isFXCurrency, useNavigationWithQueryParams } from '@melio/ap-domain';
import { Button, Container, Group, Icon, SortingState, useBreakpoint, useDisclosure, useTable } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useActivitiesNavigate, useMonitorTiming } from '../../../../utils';
import { useRecommendedVendors } from '../../../recommended-vendors';
import { emitFocusEvent, FocusEvents } from '../../FocusSkipToComponent';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { APTable } from '../APTable';
import { MobileSortMenu } from '../MobileSortMenu/MobileSortMenu';
import { PayDashboardPagination, PayDashboardPaginationProvider, usePayDashboardPagination } from '../Pagination';
import { EmptySearchResult, SearchBar } from '../Search';
import { DeleteVendorModal } from './components/DeleteVendor/DeleteVendorModal';
import { VendorsBatchPaymentsModalActivity } from './components/VendorsBatchPaymentsModalActivity/VendorsBatchPaymentsModal-activity';
import { VendorsSelectionFooter } from './components/VendorsSelectionFooter';
import { VendorsTabEmptyState } from './components/VendorsTabEmptyState';
import { VendorsTabListItem } from './components/VendorsTabListItem';
import { usePayVendor } from './hooks/usePayVendor';
import { CELLS_IDS, VendorsTableMeta } from './types';
import { useVendorsTabAnalytics } from './useVendorsTabAnalytics';
import { useVendorsTabData } from './useVendorsTabData';
import { useVendorsTableColumns } from './useVendorsTableColumns';
import { useVendorsTabSelection } from './useVendorsTabSelection';
import { useVendorsTabSortableColumns } from './useVendorsTabSortableColumns';
import { useAriaLabelContextForVendor } from './utils';

export type VendorsTabProps = {
  onAddNewPayment: ({ vendorId, amount }?: { vendorId?: Vendor['id']; amount?: string }) => void;
  onAddNewBill: ({ returnUrl, vendorId }: { returnUrl: string; vendorId?: Vendor['id']; amount?: string }) => void;
};

export const VENDORS_TAB_DEFAULT_SORT: SortingState = { id: CELLS_IDS.LAST_PAID, sortDirection: 'desc' };

export const VendorsTabComponent = withAnalyticsContext(({ onAddNewPayment, onAddNewBill }: VendorsTabProps) => {
  const { searchTerm } = useSearchTerm();
  const { isExtraSmallScreen } = useBreakpoint();
  const { getAriaLabelContextForVendor } = useAriaLabelContextForVendor();
  const [isMobileSortEnabled] = useFeature<boolean>(FeatureFlags.NpeSortMobile, false);
  const [isVendorsBatchPaymentsEnabled] = useFeature<boolean>(
    FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled,
    false,
    {
      shouldTrack: true,
    }
  );
  const { navigateToNewVendor } = useActivitiesNavigate();
  const { sortableColumns: vendorsTabSortableItems } = useVendorsTabSortableColumns();
  const sorting = usePayDashboardSorting();
  const { resetToFirstPage } = usePayDashboardPagination();
  const { unpaidBills, ...paginationResponse } = useVendorsTabData({ searchTerm });
  const { data: vendorsResult, isLoading: isLoadingVendors, isFetching, isPreviousData } = paginationResponse;
  const { data: vendors = [], pagination } = vendorsResult ?? {};
  const { isLoading: isRecommendedVendorsLoading } = useRecommendedVendors({ shouldFetch: true });
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
  const {
    isOpen: isVendorsBatchPaymentsAmountsModalOpen,
    onOpen: onVendorsBatchPaymentsAmountsModalOpen,
    onClose: onVendorsBatchPaymentsAmountsModalClose,
  } = useDisclosure();
  const { pathname } = useLocation();
  const { formatMessage } = useMelioIntl();
  const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
  const legacyRouteReady = useMonitorTiming('vendors_tab_ready');
  const { routeReady } = useMonitoring();
  const triggerMonitoring = (el: HTMLDivElement) => {
    legacyRouteReady(el);
    routeReady(el);
  };
  const { track } = useAnalytics();
  const {
    isEmptyState,
    isEmptySearchResult,
    isInitialLoading,
    isTableLoading,
    shouldShowPaginationControls,
    shouldShowTabHeader,
  } = useLoadingState({
    isLoading: isLoadingVendors || (vendors.length > 0 ? false : isRecommendedVendorsLoading),
    searchTerm,
    items: vendors,
    paginationConfig: {
      isFetching,
      isPreviousData,
      totalCount: pagination?.totalCount,
    },
  });

  useEffect(() => {
    if (isTableLoading) {
      return;
    }
    track('Dashboard', 'View', {
      Intent: 'view-vendors',
      IsSuggestedVendorsShown: null,
      isEmptyState,
    });
  }, [isTableLoading]);

  const handleNewPaymentClick = (vendorId: string, amount?: string) => {
    onAddNewPayment({ vendorId, amount });
  };

  const handleAddNewBill = (vendorId: Vendor['id']) => {
    onAddNewBill({ returnUrl: pathname, vendorId });
  };

  const handleDeleteVendorClick = (vendor: Vendor) => {
    setSelectedVendor(vendor);
    onDeleteModalOpen();
  };

  const handleEditVendor = (vendorId: Vendor['id']) => {
    handleNavigationWithQueryParams({ newPath: `vendor/${vendorId}`, newUrlQueryParam: { edit: 'true' } });
  };

  const handleViewVendor = (vendorId: Vendor['id']) => {
    handleNavigationWithQueryParams({ newPath: `vendor/${vendorId}` });
  };

  const handleSelectionFooterReviewAndPay = () => {
    onVendorsBatchPaymentsAmountsModalOpen();
  };

  const columns = useVendorsTableColumns({ onSortChange: resetToFirstPage });
  const mobileSortMenuOptions = Object.values(vendorsTabSortableItems);

  const onClickRow = (rowData: Vendor) => {
    if (rowSelections.length) {
      return;
    }
    track('Dashboard', 'Click', {
      Intent: 'view-vendor',
      Cta: 'vendor-details',
      VendorId: rowData.id,
    });
    handleNavigationWithQueryParams({ newPath: `vendor/${rowData.id}` });
  };

  const mobileRowRenderer = useCallback(
    (row: Vendor, meta?: VendorsTableMeta) => (
      <VendorsTabListItem
        vendor={row}
        unpaidBills={meta?.unpaidBills.filter((bill) => bill.vendorId === row.id) ?? []}
      />
    ),
    []
  );

  const {
    rowSelections,
    selectedRows,
    rowSelectionTooltips,
    onAllRowsSelectionChange,
    onRowSelectionChange,
    areAllSelected,
  } = useVendorsTabSelection(vendors);
  const { amounts, onAmountChange } = usePayVendor({ onRowSelectionChange });

  useVendorsTabAnalytics({ vendors, unpaidBills, areAllSelected });

  const tableProps = useTable({
    isLoading: isTableLoading,
    data: vendors,
    columns,
    getRowId: (row) => row.id,
    onRowClick: ({ rowData }) => onClickRow(rowData),
    rowSelectionTooltips,
    selectedRows,
    onRowSelectionChange,
    disableRowSelection: (row) => isFXCurrency(row.currency),
    onAllRowsSelectionChange,
    ...sorting,
    headerVariant: 'dark',
    mobileRowRenderer,
    getRowSelectionAriaLabel: useCallback(
      (vendor) => getAriaLabelContextForVendor(vendor, { unpaidBills }),
      [unpaidBills]
    ),
    hideHeaderWhileLoading: isInitialLoading,
    allRowsSelectionAriaLabel: '',
    captionId: formatMessage(`activities.payDashboard.tabs.vendors.caption`),
    meta: {
      unpaidBills,
      onNewPaymentClick: handleNewPaymentClick,
      onDeleteVendorClick: handleDeleteVendorClick,
      onAddNewBillClick: handleAddNewBill,
      onEditVendorClick: handleEditVendor,
      onViewVendorClick: handleViewVendor,
      onAmountChange,
      amounts,
      getItemAriaLabelContext: (vendor) => getAriaLabelContextForVendor(vendor, { unpaidBills }),
      rowSelections,
    },
  });

  const handleVendorNewVendor = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-vendor',
      Cta: 'add-vendor',
    });
    navigateToNewVendor({ returnUrl: pathname });
  };
  const handleDeleteModalClose = () => {
    setSelectedVendor(null);
    onDeleteModalClose();
  };
  const renderTableContent = () => (
    <Group alignItems="flex-end" variant="vertical" spacing={shouldShowPaginationControls ? 'm' : undefined}>
      <APTable
        {...tableProps}
        captionLabel={
          shouldShowTabHeader ? formatMessage('activities.payDashboard.tabs.vendors.captionLabel') : undefined
        }
      />
      {isEmptySearchResult && !isFetching ? (
        <EmptySearchResult onClear={() => emitFocusEvent(FocusEvents.TAB_TOP_SEARCH)} />
      ) : null}
      <>
        <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
        {/* Fills space on the DOM equals to gap required by pagination controls in order for ActionBar footer to respect it due to it's fixed position  */}
        {shouldShowPaginationControls ? <Box height={6} /> : null}
      </>
    </Group>
  );

  const renderContent = () => {
    if (isEmptySearchResult || !isEmptyState) {
      return renderTableContent();
    }
    return <VendorsTabEmptyState onAddNewVendor={handleVendorNewVendor} />;
  };
  return (
    <>
      <Container data-testid="pay-dashboard-vendors-tab" overflow="initial">
        {!isLoadingVendors && <span ref={triggerMonitoring} />}
        <Box paddingBottom="xxl">
          <Group variant="vertical" width="full" spacing={isExtraSmallScreen ? 's' : 'm'}>
            <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
              {shouldShowTabHeader && (
                <Group justifyContent="space-between" width="full" alignItems="center">
                  <SearchBar
                    onSearchSubmitted={resetToFirstPage}
                    placeholderOnFocus="activities.payDashboard.vendorsTab.searchPlaceholder"
                    label={
                      isExtraSmallScreen ? undefined : formatMessage('activities.payDashboard.vendorsTab.searchLabel')
                    }
                    pagination={!paginationResponse.isFetching ? paginationResponse.data?.pagination : undefined}
                    focusOnEvent={FocusEvents.TAB_TOP_SEARCH}
                    placeholder={
                      isExtraSmallScreen ? 'activities.payDashboard.vendorsTab.search.placeholder' : undefined
                    }
                  />
                  {!isExtraSmallScreen ? (
                    <Button
                      data-testid="vendors-tab-add-vendor-button"
                      onClick={handleVendorNewVendor}
                      label={formatMessage('activities.payDashboard.vendorsTab.addVendorButton')}
                      variant="tertiary"
                      leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
                    />
                  ) : null}
                  {isExtraSmallScreen && isMobileSortEnabled && (
                    <Container width="fit-content">
                      <MobileSortMenu
                        items={mobileSortMenuOptions}
                        onSortChange={resetToFirstPage}
                        title="activities.payDashboard.vendorsTab.sort.title"
                      />
                    </Container>
                  )}
                </Group>
              )}
            </Container>
            <Container overflow="initial">{renderContent()}</Container>
          </Group>
        </Box>
        {selectedVendor && (
          <DeleteVendorModal vendor={selectedVendor} isOpen={isDeleteModalOpen} onClose={handleDeleteModalClose} />
        )}
      </Container>
      {isVendorsBatchPaymentsEnabled && rowSelections.length > 0 && (
        <VendorsSelectionFooter
          vendorIds={rowSelections}
          totalCount={pagination?.totalCount ?? 0}
          onCancel={() => onAllRowsSelectionChange?.(false)}
          onReviewAndPay={handleSelectionFooterReviewAndPay}
          vendorsAmountsMap={amounts}
        />
      )}
      {isVendorsBatchPaymentsEnabled && rowSelections.length > 0 && (
        <VendorsBatchPaymentsModalActivity
          selectedVendorIds={rowSelections}
          vendorsAmountsMap={amounts}
          isOpen={isVendorsBatchPaymentsAmountsModalOpen}
          onClose={onVendorsBatchPaymentsAmountsModalClose}
        />
      )}
    </>
  );
});

export const VendorsTab = (props: VendorsTabProps) => (
  <PayDashboardPaginationProvider>
    <PayDashboardSortingProvider defaultSort={VENDORS_TAB_DEFAULT_SORT}>
      <VendorsTabComponent {...props} />
    </PayDashboardSortingProvider>
  </PayDashboardPaginationProvider>
);
