/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Container, Form, Group, Text, useBreakpoint } from '@melio/penny';
import { Address, UpdateVendorParams, US_STATES, useVendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';

import { FormWidgetProps } from '../../../../../types';
import { AddressSearchWidget, AddressSearchWidgetProps, ZipCodeField } from '../../../../form-controls';
import { VendorFormInlineApiErrorCode } from '../../../types';
import { useStateSelect } from '../../../useStateSelect';
import { useUnmanagedVendorFormExtendedSchema } from '../../../useUnmanagedVendorFormExtendedSchema';
import { useVendorForm } from '../../../useVendorForm';
import { MissingVendorInfoExtendedUnmanagedFields } from './types';
import { getUpdateVendorPayload } from './utils';

export type MissingVendorInfoExtendedUnmanagedFormProps = Omit<
  FormWidgetProps<MissingVendorInfoExtendedUnmanagedFields>,
  'onSubmit'
> & {
  vendorId: string;
  onSubmit: (data: UpdateVendorParams) => Promise<void>;
  inlineApiErrorCodes?: VendorFormInlineApiErrorCode[];
};

export const MissingVendorInfoExtendedUnmanagedForm = forwardRef<MissingVendorInfoExtendedUnmanagedFormProps, 'form'>(
  ({ vendorId, defaultValues, isSaving, onSubmit, onSubmissionStateChange, inlineApiErrorCodes, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { isExtraSmallScreen } = useBreakpoint();

    const { data: vendor } = useVendor({ id: vendorId });

    const { formProps, registerField, setValue, trigger, formState } =
      useVendorForm<MissingVendorInfoExtendedUnmanagedFields>({
        onSubmit: (data) => onSubmit(getUpdateVendorPayload(data, defaultValues)),
        schema: useUnmanagedVendorFormExtendedSchema({
          showCompanyField: true,
          isEmailRequired: !!defaultValues?.email,
        }),
        isSaving,
        defaultValues,
        onSubmissionStateChange,
        inlineApiErrorCodes,
      });
    const isFormSubmitted = formState.isSubmitted;

    useEffect(() => {
      if (vendor) {
        void trigger();
      }
    }, [trigger, vendor]);

    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address;

      const setFormFieldValue = (field: keyof Omit<Address, 'countryCode'>) => {
        setValue(field, address ? address[field] : '', {
          shouldValidate: true,
        });
      };
      setFormFieldValue('line1');
      setFormFieldValue('line2');
      setFormFieldValue('city');
      setFormFieldValue('state');
      setFormFieldValue('postalCode');
    };

    const stateOptions = useMemo(
      () =>
        US_STATES.map((state) => ({
          value: state,
          label: formatMessage(`local.USA.states.${state}`),
        })),
      [formatMessage]
    );
    const { filteredStateOptions, handleChange: handleStateSearch } = useStateSelect(stateOptions);

    return (
      <Form data-component="MissingVendorInfoExtendedUnmanagedForm" {...props} {...formProps} ref={ref} columns={2}>
        {/* @ts-ignore Form.ContentBox should be changee to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
        <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
          <Group variant="vertical" spacing="xs">
            <Text color="neutral.darkest" textStyle="body4">
              {formatMessage('activities.addBillV2.billForm.requiredFields')}
            </Text>
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('widgets.vendors.vendorDetailsGroup.title')}
            </Text>
          </Group>
        </Form.ContentBox>
        <Form.TextField
          colSpan={2}
          {...registerField('companyName')}
          labelProps={{ label: formatMessage('widgets.vendors.companyName.label') }}
          autoFocus
        />
        <Form.TextField
          colSpan={2}
          {...registerField('nickname')}
          labelProps={{ label: formatMessage('widgets.vendors.nickname.label') }}
          placeholder={formatMessage('widgets.vendors.nickname.placeholder')}
        />
        <Form.TextField
          colSpan={1}
          {...registerField('accountNumber')}
          labelProps={{
            tooltipProps: {
              label: formatMessage('widgets.vendors.accountNumber.labelTooltip'),
              triggerAriaLabel: formatMessage('widgets.vendors.accountNumber.ariaLabelTooltip'),
            },
            label: formatMessage('widgets.vendors.accountNumber.label'),
          }}
          helperTextProps={{
            label: formatMessage('widgets.vendors.accountNumber.helperText'),
          }}
        />
        <Form.TextField
          colSpan={1}
          {...registerField('confirmAccountNumber')}
          labelProps={{
            label: formatMessage('widgets.vendors.confirmAccountNumber.label'),
          }}
        />
        <Form.PhoneField
          colSpan={2}
          {...registerField('phone')}
          labelProps={{ label: formatMessage('widgets.vendors.phone.label') }}
          placeholder={formatMessage('widgets.vendors.phone.placeholder')}
        />
        <Form.TextField
          colSpan={2}
          {...registerField('email')}
          labelProps={{ label: formatMessage('widgets.vendors.email.label') }}
          placeholder={formatMessage('widgets.vendors.email.placeholder')}
        />
        {/* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
        <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
          <Container paddingTop="m">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('widgets.vendors.electronicPaymentDetailsGroup.title')}
            </Text>
          </Container>
        </Form.ContentBox>
        <Form.TextField
          colSpan={1}
          labelProps={{
            label: formatMessage('widgets.vendors.bankRoutingNumber.label'),
          }}
          {...registerField('bankRoutingNumber')}
          onChange={(e) => {
            setValue('bankRoutingNumber', e.target.value, {
              shouldValidate: isFormSubmitted,
            });

            if (isFormSubmitted) {
              void trigger('bankAccountNumber');
            }
          }}
        />
        <Form.TextField
          colSpan={1}
          labelProps={{
            label: formatMessage('widgets.vendors.bankAccountNumber.label'),
          }}
          {...registerField('bankAccountNumber')}
          onChange={(e) => {
            setValue('bankAccountNumber', e.target.value, {
              shouldValidate: isFormSubmitted,
            });

            if (isFormSubmitted) {
              void trigger('bankRoutingNumber');
            }
          }}
        />
        {/* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
        <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
          <Container paddingTop="m">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('widgets.vendors.addressDetailsGroup.title')}
            </Text>
          </Container>
        </Form.ContentBox>
        <AddressSearchWidget
          colSpan={2}
          {...registerField('line1')}
          onChange={handleAddressChange}
          // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
          formatSelectedValue={(option) =>
            (option.value as unknown as Address).line1 || (option.value as unknown as string)
          }
          labelProps={{
            label: formatMessage('widgets.vendors.addressLine1.label'),
          }}
          placeholder={formatMessage('widgets.vendors.addressLine1.placeholder')}
          clearButtonAriaLabel={formatMessage('widgets.vendors.addressLine1.clearButtonAriaLabel')}
        />
        <Form.TextField
          colSpan={2}
          labelProps={{
            label: formatMessage('widgets.vendors.addressLine2.label'),
          }}
          placeholder={formatMessage('widgets.vendors.addressLine2.placeholder')}
          {...registerField('line2')}
        />
        <Form.TextField
          colSpan={1}
          labelProps={{
            label: formatMessage('widgets.vendors.city.label'),
          }}
          {...registerField('city')}
        />
        <Form.SelectNew
          colSpan={1}
          labelProps={{
            label: formatMessage('widgets.vendors.state.label'),
          }}
          placeholder={formatMessage('widgets.vendors.state.placeholder')}
          {...registerField('state')}
          options={US_STATES.map((state) => ({
            value: state,
            label: formatMessage(`local.USA.states.${state}`),
          }))}
          searchBarProps={{
            placeholder: formatMessage('widgets.vendors.state.placeholder'),
            options: filteredStateOptions,
            onChange: handleStateSearch,
          }}
          shouldHideClearButton
        />
        <ZipCodeField
          colSpan={2}
          labelProps={{
            label: formatMessage('widgets.vendors.postalCode.label.unmanaged'),
          }}
          placeholder={formatMessage('widgets.vendorDetails.form.postalCode.placeholder')}
          {...registerField('postalCode')}
          helperTextProps={{
            label: formatMessage('widgets.vendors.postalCode.description'),
          }}
        />
      </Form>
    );
  }
);
