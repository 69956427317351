/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { MonitoredAction } from '@melio/ap-activities';
import { Button, Container, Group, Link, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { usePermissions } from '@melio/platform-permissions';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useAppToast } from '@/hooks/useAppToast.hooks';
import { useConfirmationBarSubmissionController } from '@/hooks/useConfirmationBar';
import { usePlatformIntl } from '@/translations/Intl';
import { getTraits, getUpdatedParams } from '@/utils/CompanySetting.utils';
import { SectionHeaderWithButton } from '@/widgets/settings-page/Common/SectionHeaderWithButton.widget';
import { CompanyForm, CompanyFormDetails } from '@/widgets/settings-page/CompanySettingPage/CompanyForm.widget';
import { PaymentsReportSection } from '@/widgets/settings-page/CompanySettingPage/PaymentsReport.widget';
import { TaxReportSection } from '@/widgets/settings-page/CompanySettingPage/TaxReportSection.widget';
import { CompanyLogoUpload } from './CompanyLogoUpload.widget';
import { PaymentPageSection } from './PaymentPage.widget';

export const CompanySettings = () => {
  const { setTraits, track } = useAnalytics();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { infoToast } = useAppToast();
  const { isLoading: isAccountMeLoading, data, update: updateAccount } = useAccount({ id: 'me' });
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const primaryText = formatMessage('widgets.confirmation.settings.primary');
  const secondaryText = formatMessage('widgets.confirmation.settings.secondary');
  const [multiOrgUi] = useFeature<boolean>(FeatureFlags.MultiOrgUI, false);
  const [mtlKycUpliftEnabled] = useFeature<boolean>(FeatureFlags.MtlKycUplift, false);
  const { can } = usePermissions();
  const showPaymentReportSection = can({ subject: 'report:payment', action: 'create' });
  const showTaxReportSection = can({ subject: 'report:tax', action: 'create' });

  useEffect(() => {
    track('Settings', 'View', { PageName: SettingsCardIdEnum.CompanySettings });
  }, [track]);

  const paymentPageUrl =
    partnerConfig?.generatePaymentPagePath &&
    data?.ownedVendorHandle &&
    partnerConfig.generatePaymentPagePath(data.ownedVendorHandle);

  const {
    isOpen: isConfirmationBarOpen,
    showConfirmationBar,
    onSubmissionStateChange,
    setSubmitButtonState,
    reset,
  } = useConfirmationBarSubmissionController<CompanyFormDetails>(
    {
      submitButtonProps: {
        text: primaryText,
      },
      cancelButtonProps: {
        text: secondaryText,
      },
    },
    { resetOnSecondary: true },
  );

  const onSubmitHandler = async (formData: CompanyFormDetails) => {
    startAction('company_settings_submit');
    setSubmitButtonState({ isLoading: true });
    try {
      if (!mappedData) throw new Error();
      const updatedParams = getUpdatedParams(mappedData, formData);
      await updateAccount(updatedParams);

      const traits = getTraits(formData);
      setTraits(traits);

      infoToast(formatMessage('widgets.companySettings.toast.success'));
      endAction('company_settings_submit');
    } catch (e) {
      reset?.();
    } finally {
      setSubmitButtonState({ isLoading: false });
      showConfirmationBar(false);
    }
  };

  const title = formatMessage('widgets.companySettings.header.title');
  const btnText = formatMessage('widgets.companySettings.header.buttonText');
  const bannerDescription = formatMessage('widgets.companySettings.header.sectionBanner.description', {
    link: (
      <Link
        href={partnerConfig.config.links['widgets.companySettings.header.sectionBanner.linkHref']}
        label={formatMessage('widgets.companySettings.header.sectionBanner.linkText')}
        newTab
      />
    ),
  });

  const mappedData = React.useMemo(() => {
    if (!data) return undefined;

    return {
      ...data,
      company: {
        ...data.company,
        taxInfo: {
          ...data.company.taxInfo,
          identifier: data.company.taxInfo?.identifier.replaceAll('X', '•') ?? '',
          type: data.company.taxInfo?.type ?? null,
        },
      },
    };
  }, [data]);

  const defaultFormValues = React.useMemo(() => {
    const { identifier: taxIdNumber, type: taxIdType } = mappedData?.company?.taxInfo || {};
    return {
      bizName: data?.company?.name || '',
      bizAddress: data?.company?.address,
      suite: data?.company?.address.line2 || '',
      mobileNumber: data?.company?.phoneNumber || '',
      contactFirstName: data?.company?.contactFirstName || '',
      contactLastName: data?.company?.contactLastName || '',
      legalBizName: data?.company.legalName || '',
      legalBizAddress: data?.company?.legalAddress,
      businessType: data?.company.businessType || '',
      taxIdType: taxIdType || undefined,
      taxIdNumber: taxIdNumber,
    };
  }, [data]);

  const onClickEditButton = () => {
    track('Settings', 'Click', {
      PageName: SettingsCardIdEnum.CompanySettings,
      Cta: 'edit-user-details',
    });
    showConfirmationBar(true);
  };

  return (
    <WithLoading isLoading={isAccountMeLoading}>
      <Group variant="vertical" data-component="CompanySettings" spacing="none" data-testid="company-settings">
        <SettingsSectionContainer>
          <Group variant="vertical" spacing="xxl" data-component="CompanyDetails">
            <Group variant="vertical" spacing="m">
              {partnerConfig.allowEditCompanyDetails ? (
                <SectionHeaderWithButton title={title} titleAs="h3" />
              ) : (
                <Group variant="vertical" spacing="l">
                  <SectionBanner hideIcon variant="neutral" description={bannerDescription} />
                  <SectionHeaderWithButton title={title} titleAs="h3" />
                </Group>
              )}
              <CompanyForm
                onSubmit={onSubmitHandler}
                onSubmissionStateChange={onSubmissionStateChange}
                isEditable={isConfirmationBarOpen && partnerConfig.allowEditCompanyDetails}
                defaultValues={defaultFormValues}
                mtlKycUpliftEnabled={mtlKycUpliftEnabled}
              />
              {partnerConfig.allowEditCompanyDetails && !isConfirmationBarOpen ? (
                <Container>
                  <Button
                    variant={'tertiary'}
                    onClick={onClickEditButton}
                    label={btnText}
                    data-testid="company-details-edit-button"
                  />
                </Container>
              ) : null}
            </Group>
          </Group>
        </SettingsSectionContainer>
        {paymentPageUrl && (
          <SettingsSectionContainer>
            <Group spacing="s">
              <PaymentPageSection link={paymentPageUrl} />
            </Group>
          </SettingsSectionContainer>
        )}
        {showPaymentReportSection && (
          <SettingsSectionContainer data-testid="payment-report-section">
            <Group spacing="s">
              <PaymentsReportSection />
            </Group>
          </SettingsSectionContainer>
        )}
        {showTaxReportSection && (
          <SettingsSectionContainer data-testid="tax-report-section">
            <Group spacing="s">
              <TaxReportSection />
            </Group>
          </SettingsSectionContainer>
        )}
        {multiOrgUi && <CompanyLogoUpload />}
      </Group>
    </WithLoading>
  );
};

CompanySettings.displayName = 'CompanySettings';
