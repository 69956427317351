import { Box } from '@chakra-ui/react';
import { isPayorPaymentFee } from '@melio/ap-domain';
import { BillDetailsWidgetAccessibleNew, Carousel } from '@melio/ap-widgets';
import { Group, Loader, Text } from '@melio/penny';
import {
  PaymentFullyExpanded,
  useAccountingPlatforms,
  useBills,
  useFundingSource,
  usePaymentApprovalDecisions,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { noop } from 'lodash';
import { RefObject, useEffect } from 'react';

import { useMonitorTiming } from '../../../utils';
import { usePaymentDrawerData } from '../paymentDrawer.utils';
import { PaymentDetails } from './payment-details/PaymentDetails';
import { PaymentFees } from './payment-fees/PaymentFees';

export const Body = ({
  paymentId,
  billDetailsRef,
}: {
  paymentId: string;
  billDetailsRef: RefObject<HTMLDivElement>;
}) => {
  const [isNPEPaymentFeesEnabled] = useFeature(FeatureFlags.NPEPaymentFees, false);
  const { formatMessage } = useMelioIntl();

  const { data: payment, refetch: refetchPayment, isFetching: isPaymentLoading } = usePaymentDrawerData(paymentId);

  const {
    data: fundingSource,
    isFetching: isFundingSourceLoading,
    isMutating: isFundingSourceUpdating,
    isVerifying: isVerifyingFundingSource,
  } = useFundingSource({
    id: payment?.fundingSourceId,
    enabled: !!payment?.fundingSource,
  });

  useEffect(() => {
    if (fundingSource && payment && payment?.fundingSource?.isVerified !== fundingSource?.isVerified) {
      void refetchPayment();
    }
  }, [refetchPayment, payment, fundingSource]);

  const {
    isMutating: isBillUpdating,
    data: bills,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
  } = useBills({
    params: {
      search: {
        'payment.id': payment?.id,
        'bill.status': {
          $in: ['unpaid', 'paid', 'partially_paid', 'scheduled', 'cancelled'],
        },
      },
      expand: ['vendor', 'payments', 'file'],
    },
    enabled: !!payment?.id,
  });

  const { activeAccountingPlatform, isFetching: isAccountingPlatformLoading } = useAccountingPlatforms();
  const { data: approvalDecisions, isLoading: isApprovalDecisionsLoading } = usePaymentApprovalDecisions({
    paymentId,
  });

  const isLoading =
    isPaymentLoading ||
    isBillUpdating ||
    isApprovalDecisionsLoading ||
    isFundingSourceLoading ||
    isFundingSourceUpdating ||
    isVerifyingFundingSource;

  const isLoadingBillsSection = isLoadingBills || isFetchingBills || isAccountingPlatformLoading;

  const legacyRouteReady = useMonitorTiming('payment_drawer_ready');
  const { routeReady } = useMonitoring();
  const triggerMonitoring = (el: HTMLDivElement) => {
    legacyRouteReady(el);
    routeReady(el);
  };

  if (isLoading) {
    return <Loader />;
  }
  if (!payment || (!!payment?.fundingSource && !fundingSource)) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        data-testid="payment-details-empty-state"
      >
        <Text textStyle="body4" color="neutral.darker">
          {formatMessage('activities.payDashboard.drawer.body.payment.empty.label')}
        </Text>
      </Box>
    );
  }

  const elements = bills?.map((bill) => (
    <BillDetailsWidgetAccessibleNew
      ref={billDetailsRef}
      key={bill.id}
      bill={bill}
      hasBorder={bills.length > 1}
      showOpenBalance
      activeAccountingPlatform={activeAccountingPlatform}
      onSubmit={noop}
      onSubmissionStateChange={noop}
      onInvoiceChange={noop}
      paymentVendor={payment?.vendor?.history?.deletedAt ? payment?.vendor : undefined}
    />
  ));

  const renderBillsSection = () => {
    if (isLoadingBillsSection) {
      return <Loader />;
    }
    if (!elements || elements.length === 0) {
      return null;
    }
    if (elements.length > 1) {
      const prevButtonTooltipLabel = formatMessage(
        'activities.payDashboard.drawer.body.payment.billDetails.carousel.prev.tooltip'
      );
      const nextButtonTooltipLabel = formatMessage(
        'activities.payDashboard.drawer.body.payment.billDetails.carousel.next.tooltip'
      );
      return (
        <Group variant="vertical" spacing="m">
          <Text textStyle="body2Semi">
            {formatMessage('activities.payDashboard.drawer.body.payment.billDetails.label', {
              amount: elements.length,
            })}
          </Text>

          <Carousel
            elements={elements}
            initialElementIndex={0}
            ref={billDetailsRef}
            tooltipLeftButtonLabel={prevButtonTooltipLabel}
            tooltipRightButtonLabel={nextButtonTooltipLabel}
            arrowSpacing="m"
          />
        </Group>
      );
    }
    return elements[0];
  };

  const payorFees = payment.fees?.filter(isPayorPaymentFee) ?? [];

  return (
    <Group hasDivider ref={triggerMonitoring} data-testid="pay-dashboard-payment" variant="vertical" spacing="l">
      <PaymentDetails
        payment={payment as PaymentFullyExpanded}
        approvalDecisions={approvalDecisions}
        billDetailsRef={billDetailsRef}
      />

      {isNPEPaymentFeesEnabled && !payment.isFinanced && payorFees.length > 0 ? <PaymentFees fees={payorFees} /> : null}

      {renderBillsSection()}
    </Group>
  );
};
