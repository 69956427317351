import { FundingSource } from '@melio/platform-api';
import { FormatMessage } from '@melio/platform-i18n';

const addLastFourDigitsPrefix = (lastFourDigits: string) => `...${lastFourDigits}`;

export const getFundingSourceLabel = (fundingSource: FundingSource, formatMessage: FormatMessage): string => {
  switch (fundingSource.type) {
    case 'bank-account':
      return addLastFourDigitsPrefix(fundingSource.details.accountNumber.slice(-4));
    case 'flex-account':
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.types.flex.label'
      );
    case 'card':
      return addLastFourDigitsPrefix(fundingSource.details.lastFourDigits);
  }
};
