import { useRoleText } from '@melio/ap-domain';
import { Form, MultiSelectOption } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { sortBy } from 'lodash';
import { useMemo } from 'react';

import { useFormContext } from '../../../FormContext';

type Props = {
  approvers: Collaborator<'user'>[];
  onChange: (value: string[]) => void;
  isDisabled: boolean;
  index: number;
};
export const ApprovalActionByUsers = ({ approvers, onChange, isDisabled, index }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { registerField, setValue, getValues } = useFormContext();
  const { getRoleText } = useRoleText();

  const fieldName = `actions.${index}.configuration.ids` as const;
  const field = registerField(fieldName);

  const options: MultiSelectOption[] = useMemo(
    () =>
      sortBy(approvers, ['user.firstName', 'user.lastName']).map(({ userId, user, roleUniqueName }) => ({
        label: `${user.firstName} ${user.lastName}`,
        badgeProps: { label: getRoleText(roleUniqueName), status: 'neutral', type: 'primary' },
        value: userId,
        testId: `approval-action-by-users-option-${userId}`,
      })),
    [approvers, getRoleText]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as unknown as string[];
    setValue(fieldName, value);
    onChange(value);
  };

  return (
    <Form.MultiSelect
      {...field}
      value={getValues(fieldName)}
      placeholder={formatMessage('activities.create-approval-workflows.actions.approvalV2.placeholder')}
      aria-label="specific users"
      isDisabled={isDisabled}
      data-testid={`approval-action-by-users-${index}`}
      onChange={handleChange}
      error={
        field.error
          ? { message: formatMessage('activities.create-approval-workflows.actions.approval.users.errors.required') }
          : undefined
      }
      options={options}
    />
  );
};
