import { datadogRum } from '@datadog/browser-rum';
import { Carousel } from '@melio/ap-widgets';
import {
  Group,
  GroupProps,
  Illustration,
  IllustrationProps,
  Link,
  SectionBanner,
  useBreakpointValue,
} from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Vendor, VendorAutoPayStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback, useEffect } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { useIsMobile } from '../../../../../utils/viewport-utils/useIsMobile';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { usePromotionsData } from '../../../../promotions';
import { UpdatedFields } from '../../../../single-payment/SinglePaymentFlow/types';
import { PaymentFlowDoneAction } from '../../../../types';
import { PaymentScheduledPromotions } from '../../../screens/PaymentsScheduled/components/PaymentScheduledPromotions';
import { PaymentScheduledRiskDisclaimer } from '../../../screens/PaymentsScheduled/components/RiskDisclaimer';
import { PaymentScheduledUpsells } from '../../../screens/PaymentsScheduled/components/Upsells';
import { PaymentScheduledActionButtons } from '../../../screens/PaymentsScheduled/PaymentScheduledActionButtons/PaymentScheduledActionButtons';
import {
  getSinglePaymentDetails,
  isRtpDeliveryPreferenceTypePayment,
  isVendorCapableEBillSubscription,
} from '../../../screens/PaymentsScheduled/PaymentsScheduled.utils';
import { PaymentScheduledPaymentDetailsCard } from './components/PaymentDetailsCard';
import { PaymentDetails } from './types';
import { useTitles } from './utils';

export type PaymentsScheduledSuccessfullyScreenProps = {
  paymentsDetails: PaymentDetails[];
  failedPaymentsSchedulingCount?: number;
  currency?: string;
  isLoading?: boolean;
  onClose: VoidFunction;
  onDone: (action: PaymentFlowDoneAction) => void;
  vendorsIdsWithoutEmail: Vendor['id'][];
  onNotifyVendors: VoidFunction;
  externalOrigin?: string | null;
  isEditPaymentFlow?: boolean;
  updatedFields?: UpdatedFields[];
};

export const PaymentsScheduledSuccessfullyScreen = ({
  vendorsIdsWithoutEmail,
  paymentsDetails,
  failedPaymentsSchedulingCount: failedPaymentsSchedulingCountProp,
  currency,
  onClose,
  onDone,
  onNotifyVendors,
  externalOrigin,
  isLoading,
  isEditPaymentFlow = false,
  updatedFields,
  ...props
}: PaymentsScheduledSuccessfullyScreenProps) => {
  const [isEbillsFFEnabled] = useFeature(FeatureFlags.EBills, false);
  const [isAutoPayEnabled] = useFeature(FeatureFlags.AutoPay, false);

  const failedPaymentsSchedulingCount = failedPaymentsSchedulingCountProp || 0;

  const { title, subTitle, paymentFailureTitle, paymentFailureSubTitle, paymentFailureCta } = useTitles(
    paymentsDetails,
    isEditPaymentFlow,
    failedPaymentsSchedulingCount
  );

  const isMobile = useIsMobile();

  const { endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('review_and_confirm');
    endAction('complete_legal_info');
    endAction('batch_payment_confirm');
  }, [endAction]);

  const singlePayment = getSinglePaymentDetails(paymentsDetails) as PaymentDetails;
  const isRtpPayment = isRtpDeliveryPreferenceTypePayment(singlePayment);

  // This is a temporary solution, we should solve the responsive sizing in one of two ways: 1. Component level responsiveness OR 2. Layout level responsiveness for components
  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });
  const illustrationType = isRtpPayment ? 'fast' : 'success';
  const footerGap = useBreakpointValue<GroupProps['spacing']>({ xs: 'm', s: 'm', m: 'm' });
  const actionSectionWidth = useBreakpointValue<GroupProps['width']>({ xs: 'full', s: 'full', m: 'full' });

  const paymentsIds = paymentsDetails.map((payment) => payment.id);
  const flow = paymentsDetails.length > 1 ? 'batch-payments' : 'single-payment';

  const isAutoPaySetupCapable =
    isAutoPayEnabled &&
    singlePayment?.vendor?.autoPayStatus === VendorAutoPayStatusEnum.Capable &&
    singlePayment.bills?.[0]?.origin === 'fiserv';
  const isEBillCapable = isEbillsFFEnabled && !!singlePayment && isVendorCapableEBillSubscription(singlePayment.vendor);

  const {
    isLoading: isPromotionsLoading,
    analyticsData: promotionsAnalyticsData,
    activePromotion,
  } = usePromotionsData();

  useAnalyticsView('Payment', !isPromotionsLoading, true, {
    PaymentId: paymentsIds,
    Feature: promotionsAnalyticsData,
    PageName: 'payment-scheduled',
    Intent: 'payment-scheduled-success',
  });

  useEffect(() => {
    datadogRum.addTiming('payment_scheduled_ready');
  }, []);

  const paymentDetailsCards = paymentsDetails.map((payment) => (
    <PaymentScheduledPaymentDetailsCard key={payment.id} payment={payment} isEditPaymentFlow={isEditPaymentFlow} />
  ));

  const shouldDisplayStatusBanner = failedPaymentsSchedulingCount > 0;
  const shouldDisplayConfirmIllustration = !shouldDisplayStatusBanner;

  return (
    <NewSinglePaymentStepLayout
      data-component="PaymentScheduledActivitySuccessfully.PaymentsScheduledSuccessfullyScreen"
      data-testid="payment-scheduled-successfully-activity-payments-scheduled-screen"
      isLoading={isLoading}
      ref={endActionMonitoring}
      {...props}
      footerContent={
        <NewSinglePaymentStepLayout.Actions borderTop="none">
          <Group
            variant={isMobile ? 'vertical' : 'horizontal'}
            spacing={footerGap}
            width={actionSectionWidth}
            justifyContent="center"
          >
            <PaymentScheduledActionButtons
              onDone={onDone}
              onNotifyVendors={onNotifyVendors}
              vendorsIdsWithoutEmail={vendorsIdsWithoutEmail}
              externalOrigin={externalOrigin}
              autoPayCapable={isAutoPaySetupCapable}
              eBillCapable={isEBillCapable}
              isNewSuccessScreen
              paymentsDetails={paymentsDetails}
              showResolvePaymentsButton={failedPaymentsSchedulingCount > 0}
            />
          </Group>
        </NewSinglePaymentStepLayout.Actions>
      }
      bottomContent={
        <NewSinglePaymentStepLayout.Actions borderTop="none">
          <PaymentScheduledRiskDisclaimer vendors={paymentsDetails.map(({ vendor }) => vendor)} />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <Group alignItems="center" variant="vertical">
        {shouldDisplayConfirmIllustration && <Illustration type={illustrationType} size={illustrationSize} />}
        <Group
          variant="vertical"
          spacing="xs"
          data-testid="payment-scheduled-successfully-activity-payments-scheduled-screen-header"
        >
          <NewSinglePaymentStepLayout.Title textAlign="center">{title}</NewSinglePaymentStepLayout.Title>
          {subTitle ? (
            <NewSinglePaymentStepLayout.Description textAlign="center">
              {subTitle}
            </NewSinglePaymentStepLayout.Description>
          ) : null}
        </Group>
      </Group>
      {shouldDisplayStatusBanner && (
        <SectionBanner
          variant="critical"
          title={paymentFailureTitle}
          description={paymentFailureSubTitle}
          data-testid="payment-failure-error-banner"
          action={
            <Link
              isBold
              variant="standalone"
              size="medium"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onDone('resolveFailedPayments');
              }}
              href="#"
              label={paymentFailureCta}
            />
          }
        />
      )}
      <Carousel elements={paymentDetailsCards} isCyclical={false} arrowSpacing="none" />
      <PaymentScheduledUpsells
        vendorId={singlePayment?.vendor.id || ''}
        isEBillCapable={isEBillCapable}
        isAutoPaySetupCapable={isAutoPaySetupCapable}
      />
      <PaymentScheduledPromotions
        flow={flow}
        promotionName={activePromotion}
        paymentIds={paymentsIds}
        onDone={onDone}
      />
    </NewSinglePaymentStepLayout>
  );
};

PaymentsScheduledSuccessfullyScreen.displayName = 'PaymentScheduledActivity.PaymentsScheduledScreen';
