import { AddBillFormWidgetFields } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { BillSubscriptionEndPolicyEnum, FileInfo, useAccountingPlatforms, useBill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useDateUtils, useLocation } from '@melio/platform-utils';

import { AddBillV2FormActivity } from '../AddBillV2Form/AddBillV2Form.activity';
import {
  AddBillV2DFormInitialValues,
  AddBillV2FormFrequency,
  AddBillV2FormValuesResult,
  AddBillV2SubmitType,
} from '../AddBillV2Form/types';
import { useTrackBillCreation } from '../bill-utils';
import { isSinglePaymentFields, mapFormBillToCreateApi } from './utils';

type AddBillV2ActivityProps = {
  onDone: (bill: AddBillFormWidgetFields, billId?: string, saveType?: AddBillV2SubmitType) => void;
  onClose: VoidFunction;
  onBack: VoidFunction;
  initialValues?: AddBillV2DFormInitialValues;
  initialFileInfo?: FileInfo;
  initialFile?: File;
  hideSecondaryButton?: boolean;
};

export const AddBillV2Activity = withAnalyticsContext<AddBillV2ActivityProps>(
  ({ setAnalyticsProperties, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const { create, isMutating } = useBill();
    const { toast } = useToast();
    const { createDate } = useDateUtils();
    const { trackBillCreation } = useTrackBillCreation();
    const { setTraits, track } = useAnalytics();
    const setBillTraits = () => {
      const traits: Traits = {
        create_a_bill: true,
        last_bill_added_date: createDate().toISOString(),
      };

      setTraits(traits);
    };

    useSetDocumentTitle(
      formatMessage(props.initialFile ? 'activities.addBillV2.uploadBill.pageTitle' : 'activities.addBillV2.pageTitle')
    );

    const { activeAccountingPlatform } = useAccountingPlatforms();

    setAnalyticsProperties({
      Flow: 'bill',
    });
    const { state } = useLocation();
    const { isAdvancedView } = (state as { isAdvancedView?: boolean }) || {};

    const onSubmit = async (formValues: AddBillV2FormValuesResult, saveType?: AddBillV2SubmitType) => {
      try {
        const v1FieldData = transformBillToV1Form(formValues);
        if (isSinglePaymentFields(formValues?.frequency)) {
          const createdBill = await create(mapFormBillToCreateApi(formValues, activeAccountingPlatform));
          trackBillCreation(createdBill, saveType);
          setBillTraits();
          props.onDone(v1FieldData, createdBill.id, saveType);

          return createdBill;
        } else {
          props.onDone(v1FieldData, undefined, saveType);
        }
      } catch (e) {
        toast({ type: 'error', title: (e as Error).message || 'Failed to create the bill' });
      }

      return null;
    };

    const handleClose = () => {
      track('Bill', 'Click', {
        Cta: 'exit',
        PageName: 'bill-details',
      });
      props.onClose();
    };

    return (
      <AddBillV2FormActivity
        onDone={onSubmit}
        onClose={handleClose}
        onBack={props.onBack}
        isSaving={isMutating}
        initialValues={props.initialValues}
        initialFile={props.initialFile}
        fileInfo={props.initialFileInfo}
        isAdvancedView={isAdvancedView}
        hideSecondaryButton={props.hideSecondaryButton}
      />
    );
  }
);

function transformBillToV1Form(formData: AddBillV2FormValuesResult): AddBillFormWidgetFields {
  return {
    billAmount: formData.amount as string,
    lastAmount: formData.lastAmount || undefined,
    billNumber: formData.invoiceNumber || '',
    dueDate: formData.dueDate as Date,
    note: formData.noteToSelf || '',
    vendorId: formData.vendorId as string,
    paymentFrequency: formData.frequency || AddBillV2FormFrequency.ONE_TIME,
    numOfOccurrences: formData.recurringOccurrences || undefined,
    invoice: formData.file,
    endDate: formData.recurringEndDate as Date,
    endPolicy: formData.recurringEndBy as BillSubscriptionEndPolicyEnum,
    startDate: formData.recurringStartDate as Date,
  };
}
