import { AccountingPlatformSyncConnectionErrorEnum } from '@melio/ap-activities';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { BrandSymbol, Link } from '@melio/penny';
import { useAnalytics, useAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { AccountingPlatform, AccountingPlatformConnectionStatus, AccountingPlatformSlug } from '@melio/platform-api';

import { AccountingPlatformCard as AccountingPlatformCardCl } from '@/cl/components/AccountingPlatformCard/AccountingPlatformCard.component';
import { useFormatSyncDate } from '@/hooks/date.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountingPlatformConnectedCard } from './AccountingPlatformConnectedCard.widget';
import { AccountingPlatformErrorCard } from './AccountingPlatformErrorCard.widget';
import { AccountingPlatformUnlinkedCard } from './AccountingPlatformUnlinkedCard.widget';

type Props = {
  isLoading: boolean;
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  accountingPlatform: AccountingPlatform;
  onConnectClicked: () => void;
  onDisconnectClicked: () => Promise<void>;
};

export const AccountingPlatformCard = ({
  isLoading,
  syncError,
  accountingPlatform,
  onConnectClicked,
  onDisconnectClicked,
}: Props) => {
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const { data: account } = useAccount({ id: 'me' });
  const { formatDate } = useFormatSyncDate();
  const {
    id,
    accountingSlug,
    lastCompletionTime,
    accountingCompanyName,
    connectionStatus: connectionStatusAccountingPlatform,
  } = accountingPlatform;
  const userName = account ? `${account.user.firstName} ${account.user.lastName}` : '';
  const accountingPlatformName = useAccountingPlatformName(accountingSlug);
  const { partnerConfig } = usePartnerConfig();
  useAnalyticsContext({
    globalProperties: {
      accountingSlug,
    },
  });

  const handleConnectClicked = () => {
    track('Settings', 'Click', {
      Flow: 'settings',
      Intent: 'sync',
      PageName: 'accounting-software-sync',
      EntryPoint: 'settings-page',
      Cta: `${accountingSlug}-connect`,
    });
    return onConnectClicked();
  };

  const handleDisconnectClicked = () => {
    track('SyncAccountingSoftware', 'Click', {
      Flow: 'settings',
      Intent: 'disconnect-sync',
      PageName: `disconnect-${accountingSlug}-sync`,
      EntryPoint: 'settings-page',
      Cta: `${accountingSlug}-disconnect`,
    });
    return onDisconnectClicked();
  };

  const renderCardByType = () => {
    let connectionStatus = connectionStatusAccountingPlatform;
    switch (syncError) {
      case AccountingPlatformSyncConnectionErrorEnum.AccessDenied:
        connectionStatus = AccountingPlatformConnectionStatus.InvalidCredentials;
        break;
      case AccountingPlatformSyncConnectionErrorEnum.GeneralError:
      case AccountingPlatformSyncConnectionErrorEnum.InvalidState:
        connectionStatus = AccountingPlatformConnectionStatus.Error;
        break;

      default:
        break;
    }

    const getLogoBySlug = () => {
      switch (accountingSlug) {
        case AccountingPlatformSlug.QuickBooksOnline: {
          return <BrandSymbol type="quickbooks" size="extra-large" />;
        }
        case AccountingPlatformSlug.Xero: {
          return <BrandSymbol type="xero-logo" size="extra-large" />;
        }
        case AccountingPlatformSlug.QuickBooksDesktop: {
          return <BrandSymbol type="quickbooks" size="extra-large" />;
        }
        default:
          return null;
      }
    };
    const logo = getLogoBySlug();

    const errorAccountDifferentMessage =
      syncError === AccountingPlatformSyncConnectionErrorEnum.AccountPlatformConnectToDifferentMelioAccount
        ? formatMessage(
            'widgets.accountingPlatform.card.type.error.type.accountPlatformConnectToDifferentMelioAccount.alert',
            {
              accountingPlatformName,
              support: (
                <Link
                  href={`mailto:${partnerConfig.supportEmail}`}
                  label={formatMessage(`widgets.accountingPlatform.errorMessage.links.support`)}
                  newTab
                />
              ),
            },
          )
        : undefined;
    switch (connectionStatus) {
      case AccountingPlatformConnectionStatus.Connected: {
        return (
          <AccountingPlatformConnectedCard
            accountingPlatformId={id}
            isLoading={isLoading}
            logo={logo}
            onDisconnectClicked={handleDisconnectClicked}
            errorText={errorAccountDifferentMessage}
            title={formatMessage(`widgets.accountingPlatform.card.type.connected.title`, {
              accountingPlatformName,
            })}
            descriptionList={[
              formatMessage(`widgets.accountingPlatform.card.type.connected.description`, {
                accountingPlatformName,
                accountingCompanyName: accountingCompanyName || '',
                VendorPaymentsExperience: userName,
              }),
            ]}
            lastCompletionTime={lastCompletionTime}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }
      case AccountingPlatformConnectionStatus.Unlinked: {
        return (
          <AccountingPlatformUnlinkedCard
            accountingCompanyName={accountingCompanyName}
            syncDate={formatDate(lastCompletionTime || undefined)}
            accountingPlatformName={accountingPlatformName}
            logo={logo}
            isLoading={isLoading}
            onReconnectedClicked={handleConnectClicked}
            userName={userName}
            errorText={errorAccountDifferentMessage}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }
      case AccountingPlatformConnectionStatus.NotEligibleToSyncPay:
      case AccountingPlatformConnectionStatus.InvalidCredentials:
      case AccountingPlatformConnectionStatus.Error: {
        return (
          <AccountingPlatformErrorCard
            name={accountingPlatformName}
            logo={logo}
            isLoading={isLoading}
            onConnectedClicked={handleConnectClicked}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }
      case AccountingPlatformConnectionStatus.InvalidSubscription: {
        return (
          <AccountingPlatformConnectedCard
            accountingPlatformId={id}
            isLoading={isLoading}
            logo={logo}
            onDisconnectClicked={handleDisconnectClicked}
            title={accountingPlatformName}
            descriptionList={[
              formatMessage(`widgets.accountingPlatform.card.type.error.type.invalidSubscription.description`, {
                accountingPlatformName,
              }),
              formatMessage(`widgets.accountingPlatform.card.type.error.type.invalidSubscription.description2`, {
                accountingPlatformName,
              }),
            ]}
            errorText={formatMessage(`widgets.accountingPlatform.card.type.error.type.invalidSubscription.alert`, {
              accountingPlatformName,
            })}
            lastCompletionTime={lastCompletionTime}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }

      case AccountingPlatformConnectionStatus.Disconnected:
      default: {
        const buttons = [
          {
            text: formatMessage('widgets.accountingPlatform.card.buttons.connect'),
            onClicked: handleConnectClicked,
            isLoading,
            dataTestId: `accounting-platform-${accountingSlug}-connect-card-button`,
          },
        ];
        return (
          <AccountingPlatformCardCl
            title={accountingPlatformName}
            titleAs="h4"
            descriptionList={[
              formatMessage(`widgets.accountingPlatform.card.type.initial.description`, {
                accountingPlatformName,
              }),
            ]}
            buttons={buttons}
            logo={logo}
            errorText={errorAccountDifferentMessage}
            accountingPlatformSlug={accountingSlug}
          />
        );
      }
    }
  };

  return renderCardByType();
};
