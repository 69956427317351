import { getFundingSourceIconOrImage } from '@melio/ap-domain';
import {
  Card,
  Checkbox,
  Collapse,
  Container,
  Group,
  IconButton,
  SectionBanner,
  Switch,
  Text,
  Typography,
} from '@melio/penny';
import { FundingSource, FundingSourceEntitlementType, useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useFormContext } from '../../../FormContext';
import { FormFieldsSettings } from '../../../types';

export const EntitlementSection = ({
  formFieldsSettings,
}: {
  formFieldsSettings: Pick<FormFieldsSettings, 'entitlementsFundingSourceIds' | 'entitlementsFundingSourceType'>;
}) => {
  const { formatMessage } = useMelioIntl();
  const [expanded, setExpanded] = useState(true);
  const { setValue, watch, formState } = useFormContext();

  const { data: fundingSources } = useFundingSources();

  const entitlementsFundingSourceIds = watch('entitlementsFundingSourceIds');
  const entitlementsFundingSourceType = watch('entitlementsFundingSourceType');

  const handleEntitlementTypeChange = (checked: boolean) => {
    setValue('entitlementsFundingSourceType', checked ? 'all' : 'selective');
    checked && setValue('entitlementsFundingSourceIds', fundingSources?.map(({ id }) => id) ?? []);
  };

  const handleFundingSourceSelectionChange = (fundingSourceId: string, checked: boolean) => {
    setValue(
      'entitlementsFundingSourceIds',
      checked
        ? [...entitlementsFundingSourceIds, fundingSourceId]
        : entitlementsFundingSourceIds.filter((id: string) => id !== fundingSourceId)
    );
  };

  const getFundingSourceLabel = (fundingSource: FundingSource) =>
    [fundingSource.displayName, fundingSource.nickname]
      .filter(Boolean)
      .join(formatMessage('activities.collaboratorDrawer.body.paymentMethod.checkboxLabelDivider'));

  const { readonly: isEntitlementsFundingSourceTypeReadonly, hidden: isEntitlementsFundingSourceTypeHidden } =
    formFieldsSettings.entitlementsFundingSourceType;
  const { readonly: isEntitlementsFundingSourceIdsReadonly, hidden: isEntitlementsFundingSourceIdsHidden } =
    formFieldsSettings.entitlementsFundingSourceIds;

  const isSelectiveFundingSource = entitlementsFundingSourceType === FundingSourceEntitlementType.Selective;
  const hasFundingSources = fundingSources && fundingSources.length > 0;

  const showEntitlementTypeSwitch = !isEntitlementsFundingSourceTypeHidden;
  const showEntitlementsList =
    !isEntitlementsFundingSourceIdsHidden &&
    (isSelectiveFundingSource || isEntitlementsFundingSourceIdsReadonly) &&
    hasFundingSources;
  return (
    <Container overflow="initial" data-testid="entitlement-section">
      <Group variant="vertical" spacing="l" as="fieldset">
        <Group justifyContent="space-between">
          <Group variant="vertical" spacing="xxs">
            <Text as="h3" textStyle="heading3Semi" id="entitlement-section-label">
              {formatMessage('activities.collaboratorDrawer.body.paymentMethod.heading')}
            </Text>

            <Typography.Description
              as="legend"
              id="entitlement-section-description"
              label={formatMessage('activities.collaboratorDrawer.body.paymentMethod.description')}
            />
          </Group>

          <IconButton
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            size="large"
            variant="naked"
            aria-labelledby="entitlement-section-label"
            aria-expanded={expanded}
            aria-controls="entitlement-section-content"
            onClick={() => setExpanded((prev) => !prev)}
          />
        </Group>

        <Collapse
          in={expanded}
          id="entitlement-section-content"
          role="group"
          aria-labelledby="entitlement-section-description"
        >
          <Group variant="vertical" spacing="m">
            {formState.errors.entitlementsFundingSourceIds ? (
              <Group role="alert">
                <SectionBanner
                  title={formatMessage('activities.collaboratorDrawer.body.paymentMethod.alert.title')}
                  description={formatMessage('activities.collaboratorDrawer.body.paymentMethod.alert.description')}
                  variant="critical"
                  data-testid="entitlements-error-banner"
                />
              </Group>
            ) : null}

            {showEntitlementTypeSwitch ? (
              <Card variant="flat" paddingX="s" paddingY="s">
                <Group variant="vertical" spacing="xxs" width="full">
                  <Group justifyContent="space-between">
                    <Group.Item>
                      <Typography.MainLabel
                        label={formatMessage('activities.collaboratorDrawer.body.paymentMethod.access.heading')}
                        variant="bold"
                      />
                    </Group.Item>
                    <Switch
                      data-testid="entitlement-full-access-switch"
                      size="large"
                      value={entitlementsFundingSourceType === FundingSourceEntitlementType.All}
                      onChange={(isChecked) => handleEntitlementTypeChange(isChecked)}
                      isDisabled={isEntitlementsFundingSourceTypeReadonly}
                      label={
                        watch('entitlementsFundingSourceType') === FundingSourceEntitlementType.All
                          ? formatMessage('activities.collaboratorDrawer.body.paymentMethod.switch.on')
                          : formatMessage('activities.collaboratorDrawer.body.paymentMethod.switch.off')
                      }
                      aria-describedby="entitlement-full-access-switch-description"
                    />
                  </Group>

                  <Group.Item>
                    <Typography.Description
                      id="entitlement-full-access-switch-description"
                      label={formatMessage('activities.collaboratorDrawer.body.paymentMethod.access.description')}
                    />
                  </Group.Item>
                </Group>
              </Card>
            ) : null}

            {showEntitlementsList ? (
              <Container paddingLeft="xxs" paddingY="xxs">
                <Group variant="vertical" data-testid="entitlement-funding-source-list" justifyContent="center">
                  {fundingSources.map((fundingSource) => (
                    <Group
                      key={fundingSource.id}
                      data-testid="entitlement-funding-source-list-item"
                      alignItems="center"
                    >
                      <Checkbox
                        data-testid={`entitlement-funding-source-${fundingSource.id}-checkbox`}
                        aria-label={getFundingSourceLabel(fundingSource)}
                        isDisabled={isEntitlementsFundingSourceIdsReadonly}
                        onChange={(e) => handleFundingSourceSelectionChange(fundingSource.id, e.currentTarget.checked)}
                        isChecked={entitlementsFundingSourceIds.includes(fundingSource.id)}
                        size="large"
                      />

                      {getFundingSourceIconOrImage(fundingSource)}

                      <Text textStyle="body3Semi" shouldSupportEllipsis>
                        {getFundingSourceLabel(fundingSource)}
                      </Text>
                    </Group>
                  ))}
                </Group>
              </Container>
            ) : null}
          </Group>
        </Collapse>
      </Group>
    </Container>
  );
};
