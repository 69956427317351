/* eslint-disable react-hooks/exhaustive-deps */
import {
  getFundingSourceIconType,
  getFundingSourceImageProps,
  isCreditCardFundingSource,
  PaymentReviewLineItem,
  useGetFundingSourceCardHelperText,
  useGetFundingSourceLabel,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { PaymentFullyExpanded, PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { useState } from 'react';

import { MicroDepositsVerificationModalActivity } from '../../../../funding-sources/MicroDepositsVerification';
import { LoanSection } from './loan-section/LoanSection';
import { SectionBlock } from './SectionBlock';

type Props = {
  payment: PaymentFullyExpanded;
};

export const PayFromSection = ({ payment }: Props) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [isFinancingEnabled] = useFeature<boolean>(FeatureFlags.PlatformFinancing, false);

  const [microDepositsModalOpen, setMicroDepositsModalOpen] = useState(false);

  const { fundingSource, loan, scheduledDate, status: paymentStatus } = payment;
  const { id: fundingSourceId, type: fundingSourceType, displayName, nickname } = fundingSource;

  const helperText = useGetFundingSourceCardHelperText({
    fundingSource,
    onClick: () => {
      setMicroDepositsModalOpen(true);
    },
  });

  const oneDoneHandler = React.useCallback(() => {
    setMicroDepositsModalOpen(false);
  }, []);

  const onClose = React.useCallback(() => {
    setMicroDepositsModalOpen(false);
  }, [setMicroDepositsModalOpen]);

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);

  const payFromContent =
    isFinancingEnabled && loan ? (
      <LoanSection payment={payment} />
    ) : (
      <Group variant="vertical" spacing="s">
        <SectionBlock.Title label={formatMessage('widgets.paymentDetails.payFrom.title')} />
        <PaymentReviewLineItem
          labelProps={{ label: fundingSourceLabel }}
          mainLabelProps={{ label: nickname || displayName }}
          contentTestId="pay-from-section-bank-details"
          {...(fundingSourceType === 'bank-account' && helperText ? { descriptionProps: { label: helperText } } : {})}
          {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
        />
        <PaymentReviewLineItem
          labelProps={{
            label: formatMessage(
              paymentStatus === PaymentStatusEnum.Completed
                ? 'widgets.paymentDetails.payFrom.completedPaymentDate'
                : `widgets.paymentDetails.payFrom.scheduledDate.label.${
                    isCreditCardFundingSource(fundingSource) ? 'creditCardFundingType' : 'default'
                  }`
            ),
          }}
          mainLabelProps={{ label: formatDate(scheduledDate, { dateStyle: 'medium' }) }}
          contentTestId="pay-from-section-deducted-from"
          icon={{ type: 'scheduled' }}
        />
      </Group>
    );

  return (
    // Spacing is set to 'none' to avoid layout shift when the modal is opened
    <Group variant="vertical" spacing="none" data-testid="pay-from-section">
      {payFromContent}
      <MicroDepositsVerificationModalActivity
        isOpen={microDepositsModalOpen}
        fundingSourceId={fundingSourceId}
        onClose={onClose}
        onDone={oneDoneHandler}
      />
    </Group>
  );
};
