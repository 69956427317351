import { useMelioIntl } from '@melio/ar-domain';
import { Modal } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type ExitModalProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  onSubmit: VoidFunction;
  onClose: VoidFunction;
  onCloseModal: VoidFunction;
};

export const CreateInvoiceExitModal = forwardRef<ExitModalProps>(
  ({ isOpen, onCloseModal, onSubmit, onClose, isSubmitting }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Modal
        isOpen={isOpen}
        onClose={() => !isSubmitting && onCloseModal()}
        // TODO: https://meliorisk.atlassian.net/browse/ME-57709
        // disable close icon when `isSubmitting`
        primaryButton={{
          variant: 'primary',
          isDisabled: isSubmitting,
          label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.exitModal.buttons.primary'),
          onClick: onClose,
        }}
        secondaryButton={{
          variant: 'tertiary',
          label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.exitModal.buttons.secondary'),
          isLoading: isSubmitting,
          onClick: onSubmit,
        }}
        data-testid="create-invoice-exit-modal"
        data-component={CreateInvoiceExitModal.displayName}
        header={formatMessage('ar.invoiceLifecycle.activities.createInvoice.exitModal.header')}
        description={formatMessage('ar.invoiceLifecycle.activities.createInvoice.exitModal.description')}
        ref={ref}
      />
    );
  }
);

CreateInvoiceExitModal.displayName = 'CreateInvoiceExitModal';
