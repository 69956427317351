import { Box } from '@chakra-ui/react';
import { Container, Divider, Form, Group, Link, Loader, Text, useBreakpoint } from '@melio/penny';
import { EbillsSubscriptionOptionsReceivingOption, useRemoteText } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { RegisterFormField, SetFormError } from './types';

const CheckboxWithoutLink = ({
  isDisabled,
  registerField,
}: {
  isDisabled?: boolean;
  registerField: RegisterFormField;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Form.Checkbox
      data-testid="ebills-subscription-form-terms-checkbox"
      {...registerField('acceptedTerms')}
      aria-label=""
      isDisabled={isDisabled}
    >
      <Text textStyle="body4">
        {formatMessage('activities.ebillsSubscriptionForm.form.sections.terms.embeded.description.label')}
      </Text>
    </Form.Checkbox>
  );
};

const CheckboxWithLink = ({ url, registerField }: { url: string; registerField: RegisterFormField }) => {
  const { formatMessage } = useMelioIntl();

  const linkLabel = formatMessage('activities.ebillsSubscriptionForm.form.sections.terms.description.link.label');

  return (
    <Form.Checkbox
      {...registerField('acceptedTerms')}
      aria-label=""
      data-testid="ebills-subscription-form-terms-checkbox"
    >
      <Group spacing="xxs" alignItems="center">
        <Text color="neutral.black" textStyle="body3">
          {formatMessage('activities.ebillsSubscriptionForm.form.sections.terms.description.label')}
        </Text>
        <Link
          href={url}
          newTab
          aria-label={formatMessage(
            'activities.ebillsSubscriptionForm.form.sections.terms.description.link.newTabAriaLabel',
            { label: linkLabel }
          )}
          label={linkLabel}
          variant="standalone"
          color="secondary"
          size="medium"
        />
      </Group>
    </Form.Checkbox>
  );
};

const EbillsSubscriptionFormEmbededTerms = ({
  url,
  registerField,
  setError,
}: {
  url: string;
  registerField: RegisterFormField;
  setError: SetFormError;
}) => {
  const { data, isError, isLoading } = useRemoteText(url);
  const { formatMessage } = useMelioIntl();

  if (isError) {
    setError('acceptedTerms', {
      message: formatMessage('activities.ebillsSubscriptionForm.form.errors.fetchingTerms'),
    });
  }

  return (
    <Group variant="vertical">
      <Divider />
      <Box maxHeight="96px" overflowY="scroll">
        {isLoading ? (
          <Loader />
        ) : (
          <Text color="neutral.darkest" textStyle="body4">
            {data}
          </Text>
        )}
      </Box>
      <Divider />
      <CheckboxWithoutLink isDisabled={isError || isLoading} registerField={registerField} />
    </Group>
  );
};

export const EBillsSusbscriptionFormTerms = ({
  restriction,
  selectedReceivingOption,
  receivingOptions,
  registerField,
  setError,
}: {
  restriction: string | null;
  registerField: RegisterFormField;
  selectedReceivingOption: string;
  receivingOptions: EbillsSubscriptionOptionsReceivingOption[];
  setError: SetFormError;
}) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const receivingOption = receivingOptions.find((option) => option.name === selectedReceivingOption);
  if (!receivingOption) {
    return null;
  }

  const { terms } = receivingOption;
  if (!restriction && !terms.text && !terms.url) {
    return null;
  }

  return (
    <Group variant="vertical" spacing="s">
      <Text textStyle="body3Semi" as="h2">
        {formatMessage('activities.ebillsSubscriptionForm.form.sections.terms.title')}
      </Text>
      <Container border="regular" paddingX={isExtraSmallScreen ? 's' : 'm'} paddingY={isExtraSmallScreen ? 's' : 'm'}>
        <Group variant="vertical">
          {restriction && (
            <Text color="neutral.darkest" textStyle="body4">
              {restriction}
            </Text>
          )}
          {terms.text && (
            <Text color="neutral.darkest" textStyle="body4">
              {terms.text
                .replace(/\[@TCURL@\]/, 'Terms & Conditions')
                .replace(/I have read and agree to the biller's Terms & Conditions./, '')}
            </Text>
          )}
          {!terms.url && <CheckboxWithoutLink registerField={registerField} />}
          {terms.url && terms.isEmbedded && (
            <EbillsSubscriptionFormEmbededTerms url={terms.url} registerField={registerField} setError={setError} />
          )}
          {terms.url && !terms.isEmbedded && <CheckboxWithLink url={terms.url} registerField={registerField} />}
        </Group>
      </Container>
    </Group>
  );
};
