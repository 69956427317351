import { getIsBillHasPaymentsInProgress, useBillActions } from '@melio/ap-domain';
import { Container, Group, ModalProps, StatusModal, Typography, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useBills, usePaymentRequests } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import _partition from 'lodash/partition';
import { useEffect } from 'react';

import { MonitoredAction } from '../../../monitoring';
import { SelectedInboxItemType } from '../../PayDashboard/components/BillsTab/types';

export type BatchDeleteBillItemsModalActivityProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  billItems: SelectedInboxItemType[];
  onSuccess?: VoidFunction;
};

export const BatchDeleteBillItemsModalActivity = withAnalyticsContext<BatchDeleteBillItemsModalActivityProps>(
  (props) => {
    const { isOpen, billItems, onClose, setAnalyticsProperties, onSuccess } = props;
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const { formatMessage, formatCurrency } = useMelioIntl();
    const { track } = useAnalytics();
    const { toast } = useToast();
    const { getBillActions } = useBillActions();
    const billIds = billItems.filter(({ type }) => type === 'bill').map(({ id }) => id);
    const paymentRequestIds = billItems.filter(({ type }) => type === 'paymentRequest').map(({ id }) => id);

    const {
      data: bills,
      batchDelete: batchDeleteBills,
      isLoading: isBillsLoading,
      isMutating: isBillsDeletionMutating,
    } = useBills({
      params: { search: { 'bill.id': billIds }, expand: ['vendor', 'payments'] },
      enabled: isOpen && billIds.length > 0,
    });
    const {
      data: paymentRequests,
      reject: { mutateAsync: batchRejectPaymentRequests, isLoading: isPaymentRequestsRejectionMutating },
      isLoading: isPaymentRequestsLoading,
    } = usePaymentRequests({
      params: { search: { 'paymentRequest.id': paymentRequestIds } },
      enabled: isOpen && paymentRequestIds.length > 0,
    });

    const [undeletableBills, deletableBills] = _partition(
      bills ?? [],
      (bill) => getIsBillHasPaymentsInProgress(bill) || !getBillActions(bill).actions.delete
    );
    const deletableBillItemsCount = deletableBills.length + (paymentRequests || []).length;

    const header = formatMessage('activities.deleteBillModal.batchDeleteBills.header', {
      billsCount: deletableBillItemsCount,
    });
    const description =
      deletableBillItemsCount !== billItems.length
        ? formatMessage('activities.deleteBillModal.batchDeleteBills.partialDescription', {
            deletableBillsCount: deletableBillItemsCount,
            billsCount: billIds.length,
          })
        : formatMessage('activities.deleteBillModal.batchDeleteBills.description');
    const confirmLabel = formatMessage('activities.deleteBillModal.batchDeleteBills.primaryButton', {
      billsCount: deletableBillItemsCount,
    });
    const undeletableBillsListItems = undeletableBills.map((bill) => ({
      content: formatMessage('activities.deleteBillModal.batchDeleteBills.undeletableBillItem', {
        vendorName: bill.vendor?.name,
        billAmount: formatCurrency(bill.amount),
      }),
    }));

    setAnalyticsProperties({
      PageName: 'delete-bill',
      Intent: 'delete-bill',
      BillsSelected: billIds,
      BillsAmount: bills?.reduce((acc, bill) => acc + bill.amount, 0),
      PaymentRequestsSelected: paymentRequestIds,
      PaymentRequestsAmount: paymentRequests?.reduce((acc, paymentRequest) => acc + paymentRequest.totalAmount, 0),
    });

    useEffect(() => {
      const isBillsLoaded = billIds.length === 0 ?? !!(bills && bills.length > 0);
      const isPaymentRequestsLoaded =
        paymentRequestIds.length === 0 ?? !!(paymentRequests && paymentRequests.length > 0);

      if (isOpen && isBillsLoaded && isPaymentRequestsLoaded) {
        track('Bill', 'View');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, bills?.length, paymentRequests?.length, billIds, paymentRequestIds]);

    const handleClose = () => {
      track('Bill', 'Click', { Cta: 'exit' });
      onClose();
    };

    const handleDeleteOnClick = async () => {
      startAction('batch_delete_bills');
      track('Bill', 'Click', { Cta: 'delete-bill' });
      try {
        const deleteBillItemPromises = [];

        if (deletableBills.length > 0) {
          deleteBillItemPromises.push(() => batchDeleteBills({ ids: deletableBills.map((b) => b.id) }));
        }

        if ((paymentRequests || []).length > 0) {
          deleteBillItemPromises.push(() =>
            batchRejectPaymentRequests({ paymentRequests: (paymentRequests || []).map(({ id }) => ({ id })) })
          );
        }

        await Promise.all(deleteBillItemPromises.map((fn) => fn()));

        toast({
          type: 'informative',
          id: 'batch-delete-success',
          title: formatMessage('activities.deleteBillModal.batchDeleteBills.toast.success', {
            deletableBillsCount: deletableBillItemsCount,
          }),
        });
        endAction('batch_delete_bills');
        onSuccess?.();
        onClose();
      } catch (err) {
        toast({
          type: 'error',
          id: 'batch-delete-error',
          title: formatMessage('activities.deleteBillModal.batchDeleteBills.toast.failure'),
        });
      }
    };

    return (
      <StatusModal
        data-testid="batch-delete-bills-modal-activity"
        variant="cancel"
        isLoading={isBillsLoading || isPaymentRequestsLoading}
        isOpen={isOpen}
        onClose={handleClose}
        header={header}
        primaryButton={{
          label: confirmLabel,
          onClick: handleDeleteOnClick,
          variant: 'primary',
          isLoading: isBillsDeletionMutating || isPaymentRequestsRejectionMutating,
        }}
      >
        <Group variant="vertical">
          <Container>{description}</Container>
          <Group variant="vertical" spacing="none">
            {undeletableBillsListItems.length > 0 && (
              <>
                <Container data-testid="undeletable-bills-list">
                  {formatMessage('activities.deleteBillModal.batchDeleteBills.undeletableList', {
                    undeletableBillsCount: undeletableBills.length,
                  })}
                </Container>
                <Typography.ParagraphList size="large" list={undeletableBillsListItems} type="unordered" />
              </>
            )}
          </Group>
        </Group>
      </StatusModal>
    );
  }
);
