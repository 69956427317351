import { Card, Container, Group, SkeletonCircle, SkeletonText } from '@melio/penny';

export const Loading = () => (
  <Card variant="flat" paddingX="s" paddingY="m">
    <Group width="full" variant="horizontal" alignItems="center">
      <SkeletonCircle diameter="24px" />
      <Group.Item grow={1}>
        <Container paddingY="xxxs">
          <Group width="full" variant="vertical">
            <SkeletonText />
          </Group>
        </Container>
      </Group.Item>
    </Group>
  </Card>
);
