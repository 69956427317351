import { AddressSearchWidget, AddressSearchWidgetProps, FormWidgetProps } from '@melio/ap-widgets';
import { Button, Form, Group, NakedButton, OnSubmissionStateChange, useBreakpoint, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Address, US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';

import { useSchema } from './CardFundingSourceForm.hooks';
import { CardSourceCreditCardForm } from './CardSourceCreditCardForm';
import { CardFundingSourceFormFields } from './types';

export type CardFundingSourceFormProps = FormWidgetProps<CardFundingSourceFormFields> & {
  onReady?: VoidFunction;
  onCloseForm?: VoidFunction;
  showCloseButton?: boolean;
  isLoading?: boolean;
};

export const CardFundingSourceForm = ({
  onSubmit,
  isSaving,
  isDisabled,
  defaultValues,
  onCloseForm,
  showCloseButton,
  isLoading,
}: CardFundingSourceFormProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();

  const trackClickOrFocus = (Cta: string) => {
    track('Organization', 'Click', {
      Intent: 'fill-card-holder-details',
      Cta,
    });
  };

  const { isExtraSmallScreen } = useBreakpoint();

  const submit = () => {
    const result = {
      cardNumber: getValues('cardNumber'),
      cardExpiration: getValues('cardExpiration'),
      cardVerificationCode: getValues('cardVerificationCode'),
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      line1: getValues('line1'),
      state: getValues('state'),
      city: getValues('city'),
      postalCode: getValues('postalCode'),
    } as CardFundingSourceFormFields;
    onSubmit(result);
  };

  const schema = useSchema();

  const onSubmissionStateChange: OnSubmissionStateChange<CardFundingSourceFormFields> = () => {
    Logger.log(`Submission state changed`, 'debug');
  };

  const { formProps, getValues, formState, registerField, setValue } = useMelioForm<CardFundingSourceFormFields>({
    onSubmit: submit,
    isSaving,
    defaultValues,
    schema,
    onSubmissionStateChange,
  });
  const showErrors = !!Object.keys(formState?.errors).length;

  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address;
    const setFormField = (field: keyof Omit<Address, 'line2' | 'countryCode'>) => {
      if (address?.[field]) {
        setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };
    setFormField('line1');
    setFormField('state');
    setFormField('city');
    setFormField('postalCode');
  };

  return (
    <Group variant="vertical" spacing="l" data-component="CardFundingSourceForm" data-testid="card-funding-source-form">
      {!showCloseButton ? null : (
        <Group justifyContent="flex-end">
          <NakedButton
            label={formatMessage('activities.subscription.checkout.fundingSourceSelection.button.close')}
            variant="secondary"
            onClick={() => {
              trackClickOrFocus('exit');
              onCloseForm?.();
            }}
            data-testid="close-card-funding-source-form-button"
          />
        </Group>
      )}
      <Form {...formProps} size="small" columns={2} isReadOnly={isLoading}>
        <CardSourceCreditCardForm
          showErrors={showErrors}
          setValue={setValue}
          registerField={registerField}
          isDisabled={isDisabled}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.firstName.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.firstName.placeholder')}
          {...registerField('firstName')}
          data-testid="first-name-field"
          onFocus={() => trackClickOrFocus('first-name')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.lastName.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.lastName.placeholder')}
          {...registerField('lastName')}
          data-testid="last-name-field"
          onFocus={() => trackClickOrFocus('last-name')}
        />
        <AddressSearchWidget
          {...registerField('line1')}
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.address.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.address.placeholder')}
          onChange={handleAddressChange}
          // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
          formatSelectedValue={(option) =>
            (option.value as unknown as Address).line1 || (option.value as unknown as string)
          }
          colSpan={2}
          data-testid="address-search-field"
          onFocus={() => trackClickOrFocus('address-line')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.city.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.city.placeholder')}
          {...registerField('city')}
          data-testid="city-field"
          onFocus={() => trackClickOrFocus('city')}
        />
        <Form.Select
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.state.label') }}
          emptyState={{ label: '' }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.state.placeholder')}
          {...registerField('state')}
          options={US_STATES.map((state) => ({
            value: state,
            label: formatMessage(`local.USA.states.${state}`),
          }))}
          data-testid="state-select-field"
          onFocus={() => trackClickOrFocus('state')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.cardHolderDetailsForm.zipcode.label') }}
          placeholder={formatMessage('widgets.cardHolderDetailsForm.zipcode.placeholder')}
          {...registerField('postalCode')}
          colSpan={2}
          data-testid="postal-code-field"
          onFocus={() => trackClickOrFocus('zip-code')}
        />
        <Form.ContentBox colSpan={isExtraSmallScreen ? 1 : 2}>
          <Group justifyContent="flex-end">
            <Button
              isFullWidth={false}
              label={formatMessage('activities.subscription.checkout.fundingSourceSelection.submit.label')}
              size="medium"
              onClick={(e) => {
                formProps.onSubmit(e);
                trackClickOrFocus('submit');
              }}
              data-testid="submit-card-details-button"
              isLoading={isLoading}
            />
          </Group>
        </Form.ContentBox>
      </Form>
    </Group>
  );
};
