import { useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, Button, Container, Group, Icon, SearchBar as DSSearchBar } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';

import { useFiltersLabel } from '../hooks';
import { Filter, OnSort, SortParams } from '../types';
import { SortMenuMobile } from './SortMenu.mobile';

export type FilterBarMobileProps = {
  selectedFilter: Filter;
  onSelectFilter: (filter: Filter) => void;
  search?: string;
  onSearch: (value: string) => void;
  onSort: OnSort;
  sortParams?: SortParams;
  filters: Filter[];
};

export const FiltersToolBarMobile = forwardRef<FilterBarMobileProps>(
  ({ onSelectFilter, selectedFilter, onSearch, search, filters, onSort, sortParams, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const filtersLabel = useFiltersLabel();
    const [isFilterDropdownOpen, toggleFiltersDropDown] = useBoolean(false);

    return (
      <Group
        ref={ref}
        data-component={FiltersToolBarMobile.displayName}
        variant="horizontal"
        alignItems="center"
        data-testid="pay-dashboard-payments-sub-and-search-tab"
        {...props}
      >
        <Group.Item>
          <DSSearchBar
            value={search}
            onChange={(e) => onSearch(e.target.value)}
            placeholder={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.placeholder')}
            onClear={() => onSearch('')}
          />
        </Group.Item>
        <Group.Item>
          <Group allowOverflowX={false} spacing="xs">
            <Container width="fit-content">
              <SortMenuMobile onSort={onSort} sortParams={sortParams} />
            </Container>
            <Container width="fit-content">
              <ActionsDropdownMenu
                isOpen={isFilterDropdownOpen}
                items={filters.map((filter) => ({
                  key: filter,
                  label: filtersLabel[filter],
                  onClick: () => {
                    onSelectFilter(filter);
                    toggleFiltersDropDown.off();
                  },
                  dataTestId: `ar-dashboard-filter-${filter}`,
                  isSelected: selectedFilter === filter,
                }))}
                trigger={
                  <Button
                    leftElement={<Icon size="small" type="filter" color="inherit" aria-hidden />}
                    size="medium"
                    isFullWidth
                    variant="tertiary"
                    label={formatMessage('ar.dashboard.activities.invoiceTable.mobile.filters.triggerButton.label')}
                    data-testid="mobile-filters-button"
                    onClick={toggleFiltersDropDown.toggle}
                  />
                }
                onOpenChange={toggleFiltersDropDown.toggle}
              />
            </Container>
          </Group>
        </Group.Item>
      </Group>
    );
  }
);
FiltersToolBarMobile.displayName = 'MobileFiltersToolBar';
