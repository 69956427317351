/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { usePayments } from '@melio/platform-api';

import { failedPaymentsSearchParams } from './usePaymentsTabQueryParams';

export const useFailedPayments = () =>
  usePayments({
    refetchOnMount: 'always',
    params: {
      search: failedPaymentsSearchParams,
      expand: 'none',
    },
  });
