import type {
  CardChangeEvent,
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
  ChangeEvent,
} from '@basis-theory/basis-theory-react/types';
import {
  TBTCardBrandLogosWidget,
  TBTCardNumberFieldWidget,
  TBTExpirationDateFieldWidget,
  TBTVerificationCodeFieldWidget,
} from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { CardBrand } from '@melio/platform-provider';
import { uniqueId } from 'lodash';
import { useRef, useState } from 'react';

import { CardSourceFormSetValue, RegisterCardFundingSourceFieldFn } from './types';

export type CardSourceCreditCardFormProps = {
  showErrors: boolean;
  setValue: CardSourceFormSetValue;
  registerField: RegisterCardFundingSourceFieldFn;
  isDisabled?: boolean;
};

export const CardSourceCreditCardForm = ({
  isDisabled,
  setValue,
  showErrors,
  registerField,
}: CardSourceCreditCardFormProps) => {
  const { formatMessage } = useMelioIntl();
  const { error: cardNumberFormError, ...cardNumberRegisterField } = registerField('cardNumber');
  const { error: cardExpirationFormError, ...cardExpirationRegisterField } = registerField('cardExpiration');
  const cardNumberRef = useRef<CardNumberElement>(null);
  const cardExpirationRef = useRef<CardExpirationDateElement>(null);
  const cardVerificationRef = useRef<CardVerificationCodeElement>(null);
  const [cardNumberEvent, setCardNumberEvent] = useState<CardChangeEvent>();
  const [cardExpirationEvent, setCardExpirationEvent] = useState<ChangeEvent>();
  const [cardVerificationCodeEvent, setCardVerificationCodeEvent] = useState<ChangeEvent>();
  const { error: cardVerificationCodeFormError, ...cardVerificationCodeRegisterField } =
    registerField('cardVerificationCode');
  const { track } = useAnalytics();
  const trackFocus = (Cta: string) => {
    track('Organization', 'Click', {
      Intent: 'fill-card-details',
      Cta,
    });
  };
  return (
    <>
      <TBTCardBrandLogosWidget contentAlignment="left" selectedBrand={cardNumberEvent?.cardBrand as CardBrand} />
      <TBTCardNumberFieldWidget
        {...cardNumberRegisterField}
        data-testid="card-number-field"
        colSpan={2}
        name="cardNumber"
        labelProps={{
          label: formatMessage('activities.subscription.checkout.fundingSourceSelection.cardNumber.label'),
        }}
        placeholder={formatMessage('activities.subscription.checkout.fundingSourceSelection.cardNumber.placeholder')}
        isRequired
        isDisabled={isDisabled}
        onChange={(event) => {
          setCardNumberEvent?.(event as CardChangeEvent);
          if (cardNumberRef.current) {
            setValue('cardNumber', cardNumberRef.current);
          }
        }}
        error={
          cardNumberFormError ||
          (showErrors && cardNumberEvent?.empty
            ? {
                message: formatMessage(
                  'activities.subscription.checkout.fundingSourceSelection.cardNumber.validation.required'
                ),
              }
            : undefined)
        }
        id={uniqueId('cardNumber')}
        ref={cardNumberRef}
        onFocus={() => trackFocus('card-number')}
      />
      <TBTExpirationDateFieldWidget
        {...cardExpirationRegisterField}
        name="cardExpiration"
        data-testid="card-expiration-field"
        labelProps={{
          label: formatMessage('activities.subscription.checkout.fundingSourceSelection.cardExpiration.label'),
        }}
        placeholder={formatMessage(
          'activities.subscription.checkout.fundingSourceSelection.cardExpiration.placeholder'
        )}
        isRequired
        isDisabled={isDisabled}
        onChange={(event) => {
          setCardExpirationEvent?.(event);
          if (cardExpirationRef.current) {
            setValue('cardExpiration', cardExpirationRef.current);
          }
        }}
        error={
          cardExpirationFormError ||
          (showErrors && cardExpirationEvent?.empty
            ? {
                message: formatMessage(
                  'activities.subscription.checkout.fundingSourceSelection.cardExpiration.validation.required'
                ),
              }
            : undefined)
        }
        id={uniqueId('cardExpiration')}
        ref={cardExpirationRef}
        onFocus={() => trackFocus('expiration-date')}
      />
      <TBTVerificationCodeFieldWidget
        {...cardVerificationCodeRegisterField}
        data-testid="card-verification-code-field"
        name="cardVerificationCode"
        labelProps={{
          label: formatMessage('activities.subscription.checkout.fundingSourceSelection.cardVerificationCode.label'),
        }}
        placeholder={formatMessage(
          'activities.subscription.checkout.fundingSourceSelection.cardVerificationCode.placeholder'
        )}
        isRequired
        isDisabled={isDisabled}
        onChange={(event) => {
          setCardVerificationCodeEvent?.(event);
          if (cardVerificationRef.current) {
            setValue('cardVerificationCode', cardVerificationRef.current);
          }
        }}
        error={
          cardVerificationCodeFormError ||
          (showErrors && cardVerificationCodeEvent?.empty
            ? {
                message: formatMessage(
                  'activities.subscription.checkout.fundingSourceSelection.cardVerificationCode.validation.required'
                ),
              }
            : undefined)
        }
        id={uniqueId('cardVerificationCode')}
        ref={cardVerificationRef}
        onFocus={() => trackFocus('cvc')}
      />
    </>
  );
};
