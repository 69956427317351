import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMemo } from 'react';

export const usePlaidAuth = ({ context }: { context: 'fundingSource' | 'deliveryMethod' }) => {
  const [isPlaidMicroDepositsEnabled] = useFeature(FeatureFlags.PlaidMicroDeposits, false);
  const enabled = useMemo(
    //currently plaid-micro-deposits flow is enabled for funding-sources only
    () => isPlaidMicroDepositsEnabled && context === 'fundingSource',
    [isPlaidMicroDepositsEnabled, context]
  );
  return { enabled };
};
