import { Group, Icon, Table, TableColumnDef, Tooltip } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { UsersCell } from './UsersCell';

export const useEntitledUsersTableColumns = () => {
  const { formatMessage } = useMelioIntl();

  return useMemo<TableColumnDef<Collaborator<'user'>>[]>(() => {
    const usersCell: TableColumnDef<Collaborator<'user'>> = {
      id: 'Users',
      size: 'l',
      header: (
        <Table.HeaderCell>
          <Group alignItems="center" spacing="xxs">
            {formatMessage('widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.users')}
            <Tooltip
              label={formatMessage(
                'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.users.tooltip'
              )}
            >
              <Icon type="info" size="small" />
            </Tooltip>
          </Group>
        </Table.HeaderCell>
      ),
      cell: ({ row }) => <UsersCell collaborator={row} />,
    };

    return [usersCell];
  }, [formatMessage]);
};
