import { DeprecatedSelectProps, Form } from '@melio/penny';
import { AccountingPlatform, AccountingPlatformCategory, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { useAccountingPlatformCategorySelect } from './useAccountingPlatformCategorySelect';

export type AccountingPlatformCategorySelectWidgetProps = {
  value?: AccountingPlatformCategory['id'];
  activeAccountingPlatform?: AccountingPlatform;
} & Omit<DeprecatedSelectProps, 'loadOptions' | 'formatCreateLabel' | 'onCreateOption' | 'value' | 'options'>;

export const AccountingPlatformCategorySelectWidget = forwardRef<AccountingPlatformCategorySelectWidgetProps, 'input'>(
  (
    { placeholder: _placeholder, isReadOnly, isViewMode, viewModePlaceholder, activeAccountingPlatform, ...props },
    ref
  ) => {
    const { data: categories, isFetched } = useAccountingPlatformCategorySelect();
    const { formatMessage } = useMelioIntl();

    const getPlaceholder = (activeAccountingPlatform?: AccountingPlatform, isViewMode?: boolean) => {
      const isQuickBooksOnline = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
      const isXero = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero;
      if (isQuickBooksOnline && isViewMode) {
        return formatMessage('form.categorySelect.viewModePlaceholder.quickbooks');
      }

      if (isQuickBooksOnline) {
        return formatMessage('form.categorySelect.placeholder.quickbooks');
      }

      if (isXero) {
        return formatMessage('form.categorySelect.placeholder.xero');
      }

      if (isViewMode) {
        return formatMessage('form.categorySelect.viewModePlaceholder.general');
      }

      return formatMessage('form.categorySelect.placeholder.general');
    };

    const placeholder = getPlaceholder(activeAccountingPlatform, isViewMode);

    return (
      <Form.DeprecatedSelect
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - there is an issue with the underline type that penny uses
        options={categories}
        data-component="AccountingPlatformCategorySelect"
        ref={ref}
        isLoading={!isFetched && !isReadOnly && !isViewMode}
        placeholder={placeholder}
        viewModePlaceholder={placeholder}
        isReadOnly={isReadOnly}
        isViewMode={isViewMode}
        {...props}
      />
    );
  }
);

AccountingPlatformCategorySelectWidget.displayName = 'AccountingPlatformCategorySelect';
