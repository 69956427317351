import { PlaidAccountData, usePlaidLinkToken } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';
import { PlaidLinkOnEvent, PlaidLinkOnEventMetadata, usePlaidLink } from 'react-plaid-link';

export type UsePlaidProps = {
  onSuccess: (data: PlaidAccountData) => void;
  onExit?: VoidFunction;
  onLoad?: VoidFunction;
  onError?: ErrorFunction;
  fundingSourceId?: string;
};

export const usePlaid = ({ onError, onSuccess, onLoad, onExit, fundingSourceId }: UsePlaidProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    data,
    remove,
    error: linkError,
  } = usePlaidLinkToken({
    params: { type: fundingSourceId ? 'verification' : 'registration', details: { fundingSourceId } },
  });
  useEffect(() => remove, [remove]);

  // XXX standardize error codes and messages https://linear.app/meliopayments/issue/PLA-905
  const handleError = useCallback(
    (data: PlaidLinkOnEventMetadata) => {
      const buildErrorMessage = () => {
        if (!data) {
          return '';
        }
        if (data.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
          return formatMessage('activities.microDepositsVerification.screens.exceedingFailure.title');
        }
        return data.error_message || '';
      };

      onError?.({
        code: '500',
        message: buildErrorMessage(),
      });
    },
    [onError, formatMessage]
  );

  useEffect(() => {
    if (linkError) {
      Logger.log('Plaid link generation failed', 'error');
      onError?.({
        code: '500',
        message: formatMessage(
          fundingSourceId
            ? 'widgets.paymentMethods.bankAccount.verify.error'
            : 'activities.createFundingSource.screens.error'
        ),
      });
      onExit?.();
    }
  }, [linkError, onError, onExit, formatMessage, fundingSourceId]);

  return usePlaidLink({
    token: data?.linkToken || null,
    onLoad,
    onExit,
    onSuccess: (public_token, metadata) => onSuccess({ public_token, ...metadata } as PlaidAccountData),
    // plaid sends duplicate error events we need to throttle in order to not get multiple toast messages
    onEvent: throttle<PlaidLinkOnEvent>(
      (name, data) => {
        if (name === 'ERROR' || data.error_code) {
          return handleError(data);
        }
      },
      100,
      { trailing: false }
    ),
  });
};
