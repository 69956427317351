import { Box } from '@chakra-ui/react';
import { Container, LoadingContainer } from '@melio/penny';
import { Logger } from '@melio/platform-logger';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useRef } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '//platform-static.meliopayments.com/js/pdf.worker.min.js';

type PDFPreviewerProps = {
  previewContent?: string;
  onError?: ARErrorFunction;
  isLoadingContent?: boolean;
};

const fetchPdf = async (canvas: HTMLCanvasElement, previewContent: string) => {
  const binaryContent = convertDataURIToBinary(previewContent);
  const pdf = pdfjs.getDocument(binaryContent).promise;

  const firstPageNumber = 1;
  const page = await (await pdf).getPage(firstPageNumber);

  const scale = 3;
  const viewport = page.getViewport({ scale });

  // Prepare canvas using PDF page dimensions.
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  // Render PDF page into canvas context.
  const renderContext = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    canvasContext: canvas.getContext('2d')!,
    viewport,
  };

  await page.render(renderContext).promise;
};

export const PDFPreviewer = forwardRef<PDFPreviewerProps>(
  ({ previewContent = '', isLoadingContent = false, onError, ...props }, ref) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isLoading, loadingState] = useBoolean(true);

    useEffect(() => {
      loadingState.on();
      if (canvasRef.current && previewContent) {
        try {
          void fetchPdf(canvasRef.current, previewContent).then(loadingState.off);
        } catch (error) {
          Logger.handleException(error);
          onError?.(error as ARPlatformError);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewContent]);

    return (
      <LoadingContainer isLoading={isLoading || isLoadingContent}>
        <Container
          border="regular"
          data-component={PDFPreviewer.displayName}
          data-testid="pdf-previewer-container"
          {...props}
          ref={ref}
        >
          <Box as="canvas" width="100%" ref={canvasRef as never} />
        </Container>
      </LoadingContainer>
    );
  }
);
PDFPreviewer.displayName = 'PDFPreviewer';

function convertDataURIToBinary(dataURI: string) {
  const BASE64_MARKER = ';base64,';
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
