import { Container, IconButton, useBreakpoint } from '@melio/penny';
import { useTodos } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../utils';
import { useTodosEnabled } from '../../../todos-drawer/hooks/useTodosEnabled';
import { usePayDashboardTabs } from '../../hooks/usePayDashboardTabs';

export const TodosWidgetButton = () => {
  const { isEnabled } = useTodosEnabled();

  if (isEnabled) {
    return <TodosButton />;
  }

  return null;
};

const TodosButton = () => {
  const { navigateToTodos } = useActivitiesNavigate();
  const { selectedTab } = usePayDashboardTabs();
  const { isExtraSmallScreen } = useBreakpoint();
  const { totalCount } = useTodos();
  const { formatMessage } = useMelioIntl();

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    return navigateToTodos(selectedTab);
  };

  return (
    <Container data-testid="todos-button-container" overflow="initial">
      <IconButton
        icon="to-do"
        onClick={onClick}
        variant={isExtraSmallScreen ? 'naked' : 'primary'}
        data-testid="todos-button"
        aria-label={formatMessage('widgets.todosButton.ariaLabel')}
        status={totalCount === 0 ? undefined : 'critical'}
      />
    </Container>
  );
};
