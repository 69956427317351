import { AmountField, Container, FormField, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { isNull } from 'lodash';
import { useEffect } from 'react';

import { DEFAULT_APPROVAL_LIMIT } from '../../../edit-collaborator-drawer/utils';
import { useFormContext } from '../../../FormContext';
import { FormFieldsSettings } from '../../../types';

type Props = { formFieldsSettings: Pick<FormFieldsSettings, 'paymentApprovalLimit'> };
export const PaymentApprovalLimitSection = ({ formFieldsSettings }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { registerField, watch, setValue } = useFormContext();
  const paymentApprovalLimitValue = watch('paymentApprovalLimit');

  useEffect(() => {
    if (isNull(paymentApprovalLimitValue)) {
      setValue('paymentApprovalLimit', DEFAULT_APPROVAL_LIMIT);
    }
  }, [paymentApprovalLimitValue, setValue]);

  return (
    <Container data-testid="payment-approval-limit-section" overflow="initial">
      <Group variant="vertical" spacing="m">
        <Group variant="vertical" spacing="xxs">
          <Group justifyContent="space-between" alignItems="center">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('activities.collaboratorDrawer.body.paymentApprovalLimitSection.heading')}
            </Text>
          </Group>

          <Text textStyle="body4" color="neutral.darkest">
            {formatMessage('activities.collaboratorDrawer.body.paymentApprovalLimitSection.description')}
          </Text>
        </Group>

        <FormField
          {...registerField('paymentApprovalLimit')}
          isReadOnly={formFieldsSettings.paymentApprovalLimit?.readonly}
          isRequired={formFieldsSettings.paymentApprovalLimit?.required}
          aria-required={formFieldsSettings.paymentApprovalLimit?.required}
          labelProps={{
            label: formatMessage('activities.collaboratorDrawer.body.paymentApprovalLimitSect.input.label'),
          }}
          data-testid="payment-approval-limit-form"
          render={(formFieldProps) => (
            <AmountField
              {...formFieldProps}
              allowNegativeValue={false}
              data-testid="form-input-paymentApprovalLimit"
              decimalScale={2}
            />
          )}
        />
      </Group>
    </Container>
  );
};
