import { Button, ButtonProps, useBreakpointValue } from '@melio/penny';

export const NextButton = (
  props: Pick<
    ButtonProps,
    'onClick' | 'isDisabled' | 'isLoading' | 'label' | 'variant' | 'isFullWidth' | 'size' | 'link'
  >
) => {
  const isFullWidth = useBreakpointValue({ xs: true, s: true, m: false });

  return (
    <Button
      data-component="NewSinglePaymentStepLayout.NextButton"
      data-testid="layout-next-button"
      tabIndex={0}
      size="large"
      isFullWidth={isFullWidth}
      {...props}
    />
  );
};

NextButton.displayName = 'NewSinglePaymentStepLayout.NextButton';
