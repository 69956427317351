import { Card, Container, Group, Icon, useDisclosure } from '@melio/penny';
import noop from 'lodash/noop';
import React from 'react';

import { FinancingDetailsModalWidget } from '../../../../financing-more-details-modal/FinancingMoreDetailsModal.widget';
import { CreditsLimit } from './components/CreditsLimit.component';
import { Description } from './components/Description.component';
import { Title } from './components/Title.component';

export type FinancingApplicationFlowCardProps = {
  onClick?: VoidFunction;
  isSelected?: boolean;
  isDisabled?: boolean;
};

export const FinancingApplicationFlowCard: React.VFC<FinancingApplicationFlowCardProps> = ({
  onClick = noop,
  isDisabled = false,
  isSelected,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <FinancingDetailsModalWidget isOpen={isOpen} onClose={onClose} />
      <Card
        data-component="FinancingApplicationFlowCard"
        data-testid="financing-application-flow-card"
        onClick={onClick}
        isSelected={isSelected}
        isDisabled={isDisabled}
        {...props}
      >
        <Group>
          <Group.Item shrink={0}>
            <Container width="fit-content" alignItems="center" height="full">
              <Icon size="large" type="installments" isDisabled={isDisabled} />
            </Container>
          </Group.Item>
          <Group variant="vertical" spacing="none">
            <Title isDisabled={isDisabled} />
            <Description isDisabled={isDisabled} onOpen={onOpen} />
          </Group>
          <Group.Item alignSelf="flex-start">
            <CreditsLimit isDisabled={isDisabled} />
          </Group.Item>
        </Group>
      </Card>
    </>
  );
};

FinancingApplicationFlowCard.displayName = 'FinancingApplicationFlowCard';
