import { useVendorNotPermitted } from '@melio/ap-domain';
import { EbillCellBadge, LockedVendorIcon, VendorAvatar } from '@melio/ap-widgets';
import { Badge, Group, NakedButton, Table, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Payment, VendorEBillStatusEnum } from '@melio/platform-api';
import { useLocation, useNavigate } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../../utils';
import { FocusSkipToComponent, FocusSkipToComponentProps } from '../../../FocusSkipToComponent';

export type PaymentsTabVendorNameCellProps = {
  payment: Payment;
  focusProps?: FocusSkipToComponentProps;
};
export const PaymentsTabVendorNameCell = ({ payment, focusProps }: PaymentsTabVendorNameCellProps) => {
  const navigate = useNavigate();
  const { navigateToEbillActivationForm, navigateToAutopayForm } = useActivitiesNavigate();
  const { isExtraSmallScreen } = useBreakpoint();
  const { track } = useAnalytics();
  const { search } = useLocation();
  const isVendorNotPermitted = useVendorNotPermitted(payment.vendor);

  const onVendorNameClick = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    e.stopPropagation();

    track('Dashboard', 'Click', {
      Intent: 'vendor-details',
      Cta: 'vendor-name-click',
    });

    return navigate({ pathname: `vendor/${payment.vendorId}`, search });
  };

  const vendorName = payment.vendor?.name ?? '';
  const vendorExternalLogoUrl = payment.vendor?.externalLogoUrl;
  const isManaged = payment.vendor?.isManaged;
  const isVendorDeleted = !!payment.vendor?.history.deletedAt;

  return (
    <Table.Cell data-testid={`payments-tab-row-${payment.id}-vendor-name-cell`}>
      <Group alignItems="center">
        {!isExtraSmallScreen && (
          <VendorAvatar name={vendorName || '?'} logoUrl={vendorExternalLogoUrl} isManaged={isManaged} />
        )}
        <Group variant="vertical" spacing="xxs">
          <Group alignItems="center" spacing="xxs">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}

            {isVendorDeleted ? (
              <Group alignItems="center" spacing="xs">
                <Text textStyle="body3" color="neutral.darker">
                  {vendorName}
                </Text>
                <Badge type="secondary" label="Deleted" status="neutral" />
              </Group>
            ) : (
              <NakedButton
                shouldSupportEllipsis
                variant="secondary"
                label={vendorName}
                data-testid={`payments-tab-row-${payment.id}-vendor-name-cell-button`}
                onClick={onVendorNameClick}
                aria-haspopup="dialog"
              />
            )}
            {!isVendorDeleted && payment.vendor && payment.vendor.eBillStatus !== VendorEBillStatusEnum.NotCapable && (
              <EbillCellBadge
                vendor={payment.vendor}
                onEBillSubscribeClick={(vendorId) => navigateToEbillActivationForm({ vendorId })}
                onAutoPayActivationClick={(vendorId) => navigateToAutopayForm({ vendorId })}
                isReadOnly={isVendorNotPermitted}
              />
            )}
          </Group>
          {focusProps && <FocusSkipToComponent {...focusProps} />}
        </Group>
      </Group>
    </Table.Cell>
  );
};
