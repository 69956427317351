import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { InternationalAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isObject } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import type { Path, PathValue } from 'react-hook-form';
import { object, SchemaOf, string } from 'yup';
import * as yup from 'yup';

import { phoneIsValid } from '../../../../../utils/validation/phone.valdiation.utils';

const isDate = (value: unknown): value is Date => value instanceof Date;

const getDirtyValues = <T extends FieldValues, DirtyFields extends Record<string, unknown>>({
  dirtyFields,
  formValues,
}: {
  dirtyFields: DirtyFields;
  formValues: T;
}): Partial<T> =>
  Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) {
      return prev;
    }

    return {
      ...prev,
      [key as keyof T]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues({ dirtyFields: dirtyFields[key] as DirtyFields, formValues: formValues[key] as T })
          : (formValues[key] as T),
    };
  }, {});
export const getDirtyValuesFromForm = <T extends FieldValues>({
  form,
}: {
  form: UseMelioFormResults<T>;
}): Partial<T> => {
  const formValues = form.getValues();
  const dirtyFields = form.formState.dirtyFields;

  return getDirtyValues({ dirtyFields, formValues });
};
export const isFieldReadonly = <T extends FieldValues>({
  fieldKey,
  form,
}: {
  form: UseMelioFormResults<T>;
  fieldKey: Path<T>;
}) => {
  const isDirty = form.getFieldState(fieldKey, form.formState).isDirty;
  const formValue = form.getValues(fieldKey);

  return !isEmpty(formValue) && !isDirty;
};

export const isEmpty = <T extends FieldValues>(value: PathValue<T, Path<T>>) => {
  if (isDate(value)) {
    return !value;
  }
  if (isObject(value)) {
    return Object.values(value).every((val) => _isEmpty(val));
  }
  return _isEmpty(value);
};

export const setValueAndMakeDirty = <T extends FieldValues, K extends Path<T>>({
  fieldKey,
  form,
  value,
}: {
  form: UseMelioFormResults<T>;
  fieldKey: K;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: T[K];
}) => form.setValue(fieldKey, value, { shouldDirty: true });

export const useInternationalAddressSchema = () => {
  const { formatMessage } = useMelioIntl();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const addressSchema = object()
    .shape({
      line1: string().required(
        formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.addressField.required')
      ),
      line2: string().notRequired(),
      city: string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.addressField.city.required'
          )
        )
        .trim(),
      state: string().optional(),
      postalCode: string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.addressField.zipCode.required'
          )
        )
        .trim(),
      countryCode: string()
        .required(
          formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.countryCode.required')
        )
        .length(
          2,
          formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.countryCode.length')
        )
        .trim(),
    })
    .default(null)
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.addressField.required'
      )
    ) as SchemaOf<InternationalAddress>;

  return addressSchema;
};

export const useFxCommonValidations = () => {
  const { formatMessage } = useMelioIntl();
  const addressSchema = useInternationalAddressSchema();

  const firstNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.invalid'
      )
    )
    .trim();

  const lastNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.invalid'
      )
    )
    .trim();

  const ssnSchema = yup
    .string()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.ssn.required')
    )
    .trim();

  const dateOfBirthSchema = yup
    .date()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    )
    .typeError(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    );

  const emailSchema = yup
    .string()
    .email(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.invalid')
    )
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.required')
    )
    .trim();

  const phoneSchema = yup
    .string()
    .trim()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.required')
    )
    .test(
      'phoneIsValid',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.invalid'),
      phoneIsValid
    );

  return {
    phoneSchema,
    emailSchema,
    firstNameSchema,
    lastNameSchema,
    addressSchema,
    ssnSchema,
    dateOfBirthSchema,
  };
};
