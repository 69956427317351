import React from 'react';
export type DatePickerFields = {
  from: string;
  to: string;
};

export interface DatePickerProps {
  fieldName: keyof DatePickerFields;
  isPickerOpen: boolean;
  onSelect: (date: Date) => void;
  label: React.ReactNode;
  placeholder: string;
  onClick: VoidFunction;
  minDate?: Date;
  selectedDate: Date;
}

export interface DateRangeProps {
  startDate?: Date;
  endDate?: Date;
  onSelectFrom: (date: Date) => void;
  onSelectTo: (date: Date) => void;
}

export enum exportFormat {
  CSV = 'CSV',
  IIF = 'IIF',
  QIF = 'QIF',
}

export type ExportFileFormWidgetFields = {
  fileFormatRadioValue: exportFormat;
  toDate: Date;
  fromDate: Date;
  includePayments: boolean;
};
