import { StatusIconSolid } from '@melio/penny';
import { TodoFailedPayment, TodoFailedPaymentTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../utils';
import { PayDashboardTabs } from '../../../PayDashboard';
import { useTodosDescriptions } from '../../hooks/useTodosDescriptions';
import { TodoSection } from '../TodoSection';
import { normalizePayments } from '../utils';

export type TodoSectionFailedPaymentsProps = {
  todo?: TodoFailedPayment;
};

export const TodoSectionFailedPayments = ({ todo }: TodoSectionFailedPaymentsProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToTab } = useActivitiesNavigate();
  const { createAmountDescription, createViewPaymentAction } = useTodosDescriptions();

  if (!todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null) {
    return null;
  }

  const totalItems = todo.items.pagination.totalCount || 0;
  const firstItem = todo.items.data[0];

  const title =
    totalItems === 1
      ? formatMessage('widgets.todosDrawer.section.failePayments.singular.title', {
          vendorName: firstItem?.vendor?.name ?? '',
        })
      : formatMessage('widgets.todosDrawer.section.failePayments.plural.title', {
          count: totalItems,
        });

  const onClickAction = () => {
    navigateToTab(PayDashboardTabs.Payments, {
      ...(totalItems === 1 ? { entityId: firstItem?.id } : {}),
      urlParams: [{ key: 'status', value: 'failed' }],
    });
  };
  const action = createViewPaymentAction(totalItems, onClickAction);

  const data = normalizePayments(todo);
  const description = [...createAmountDescription(totalItems, data)];

  return (
    <TodoSection
      key={`section-${TodoFailedPaymentTypeEnum.FailedPayments}`}
      icon={<StatusIconSolid variant="alert" size="medium" />}
      title={title}
      description={description}
      action={action}
      type={TodoFailedPaymentTypeEnum.FailedPayments}
    />
  );
};
