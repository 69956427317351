import { getManagedAccountDeliveryMethodType } from '@melio/ap-domain';
import { DeliveryMethod } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useCallback } from 'react';

import { usePaymentProcessingInfo } from './paymentProcessingInfo.hook';

export const useShowMemoToVendor = () => {
  const {
    settings: {
      payment: {
        memoToVendor: {
          deliveryMethodBlackList: memoToVendorDeliveryMethodBlackList,
          allowSendingEmailToVendorWhenAddingMemo,
        },
      },
    },
  } = useConfig();
  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();

  const shouldDisplayMemoToVendor = useCallback(
    (deliveryMethod: DeliveryMethod) => {
      let deliveryMethodType = deliveryMethod.type;
      if (deliveryMethod.type === 'managed-account') {
        deliveryMethodType = getManagedAccountDeliveryMethodType(deliveryMethod.details);
      }
      if (isPaymentProcessedByCapitalOne(deliveryMethodType)) {
        return false;
      }

      if (memoToVendorDeliveryMethodBlackList.includes(deliveryMethodType)) {
        return false;
      }

      return true;
    },
    [isPaymentProcessedByCapitalOne, memoToVendorDeliveryMethodBlackList]
  );

  return {
    shouldDisplayMemoToVendor,
    allowSendingEmailToVendorWhenAddingMemo,
  };
};

export type ShowMemoToVendor = ReturnType<typeof useShowMemoToVendor>;
