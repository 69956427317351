/* eslint-disable react-hooks/exhaustive-deps */
import { ReconciliationForm, ReconciliationFormFields, useAccountingPlatformName } from '@melio/ap-widgets';
import { Modal, useFormSubmissionController, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccountingPlatforms, useBill, usePayment, useTodos } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MonitoredAction } from '../../monitoring';
import { useTodosEnabled } from '../todos-drawer/hooks/useTodosEnabled';

type MarkAsPaidBillDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  onSuccess: () => void;
  type: 'bill' | 'payment';
};

export const MarkAsPaidModal = withAnalyticsContext<MarkAsPaidBillDialogProps>(
  ({ isOpen, onClose, id, onSuccess, type }) => {
    const {
      data: bill,
      markAsPaid: markBillAsPaid,
      isLoading: isBillLoading,
      isMutating: isBillMutating,
    } = useBill({ id, enabled: type === 'bill' });
    const {
      data: payment,
      markAsPaid: markPaymentAsPaid,
      isLoading: isPaymentLoading,
      isMutating: isPaymentMutating,
    } = usePayment({ id, enabled: isOpen && type === 'payment' });
    const navigate = useNavigate();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const { isEnabled: isTodosEnabled } = useTodosEnabled();
    const { refetch: refetchTodos } = useTodos({ enabled: false });

    const {
      activeAccountingPlatform,
      hasAccountingPlatform,
      isLoading: isLoadingAccountingPlatforms,
    } = useAccountingPlatforms();
    const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
    const { formatMessage, formatCurrency } = useMelioIntl();
    const { toast } = useToast();
    const { track } = useAnalytics();
    const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<ReconciliationFormFields>();

    useAnalyticsContext({
      globalProperties: {
        PageName: 'mark-as-paid',
        Flow: 'dashboard',
        Intent: 'mark-as-paid',
        AccountingSoftwareBill: accountingPlatformName,
        EntryPoint: `dashboard-${bill ? 'bills' : 'payments'}`,
      },
    });

    useEffect(() => {
      if (isOpen && (!isBillLoading || !isPaymentLoading)) {
        track('Dashboard', 'View');
      }
    }, [isOpen]);

    const onDialogClose = useCallback(() => {
      track('Dashboard', 'click', {
        Cta: 'exit',
      });
      onClose();
    }, [onClose]);

    const handleMarkAsPaid = async (accountingPlatformPaymentAccountId?: string) => {
      if (bill) {
        await markBillAsPaid({ isPaid: true, amount: bill.balance, accountingPlatformPaymentAccountId });
      } else if (payment) {
        await markPaymentAsPaid({ accountingPlatformPaymentAccountId });
      } else {
        throw new Error(`Could not mark as paid this bill or payment`);
      }
    };

    const showRelevantToast = () => {
      track('Dashboard', 'status', {
        Status: 'success',
        BillId: bill?.id,
        PaymentId: payment?.id,
      });
      if (bill) {
        toast({
          type: 'success',
          title: formatMessage('activities.payDashboard.billsTab.markAsPaidModal.toast.success', {
            amount: formatCurrency(bill.amount, bill?.currency || 'USD'),
            vendorName: bill.vendor?.name || '',
          }),
          action: {
            type: 'button',
            text: formatMessage('activities.payDashboard.billsTab.markAsPaidModal.toast.success.single.action'),
            onAction: (closeToast) => {
              track('Dashboard', 'Click', {
                Cta: 'view-payment',
                BillId: bill.id,
              });
              closeToast();
              return navigate('../payments');
            },
          },
        });
      }
      if (payment) {
        toast({
          type: 'success',
          title: formatMessage('activities.payDashboard.paymentsTab.markAsPaidModal.toast.success', {
            amount: formatCurrency(payment.amount),
            numberOfBills: payment.bills?.length,
            vendorName: payment.vendor?.name || '',
          }),
          action: {
            type: 'button',
            text: formatMessage('activities.payDashboard.billsTab.markAsPaidModal.toast.success.single.action'),
            onAction: (closeToast) => {
              track('Dashboard', 'Click', {
                Cta: 'view-bill',
                PaymentId: payment.id,
              });
              closeToast();
            },
          },
        });
      }
    };

    const onDialogConfirm = async (accountingPlatformPaymentAccountId?: string) => {
      try {
        startAction(`${type}_mark_as_paid`);
        track('Dashboard', 'click', {
          Cta: 'mark-as-paid',
          QuickbooksAccountId: accountingPlatformPaymentAccountId,
        });
        await handleMarkAsPaid(accountingPlatformPaymentAccountId);
        onSuccess();
        showRelevantToast();
        if (isTodosEnabled) {
          refetchTodos();
        }
        endAction(`${type}_mark_as_paid`);
      } catch (error) {
        track('Dashboard', 'status', {
          Status: 'failure',
          BillId: bill?.id,
          PaymentId: payment?.id,
        });
        toast({ type: 'error', title: formatMessage('activities.payDashboard.billsTab.markAsPaidModal.toast.error') });
      } finally {
        onClose();
      }
    };

    const getModalDescription = () => {
      if (hasAccountingPlatform) {
        return formatMessage('activities.payDashboard.billsTab.markAsPaidModal.syncDescription', {
          accountingPlatformName,
        });
      } else if (type === 'bill') {
        return formatMessage('activities.payDashboard.billsTab.markAsPaidModal.description');
      } else {
        return formatMessage('activities.payDashboard.paymentsTab.markAsPaidModal.description');
      }
    };

    const modalPrimaryButton = hasAccountingPlatform
      ? {
          ...submitButtonProps,
          variant: 'primary' as const,
          label: formatMessage('activities.payDashboard.billsTab.markAsPaidModal.confirm'),
          isLoading:
            isBillLoading || isPaymentLoading || isBillMutating || isPaymentMutating || isLoadingAccountingPlatforms,
        }
      : {
          onClick: () => onDialogConfirm(),
          variant: 'primary' as const,
          label: formatMessage('activities.payDashboard.billsTab.markAsPaidModal.confirm'),
          isLoading: isBillLoading || isPaymentLoading || isBillMutating || isPaymentMutating,
        };

    const modalChildren = hasAccountingPlatform && (
      <ReconciliationForm
        disabledNewOption
        newPaymentAccountName=""
        onSubmit={(data) => onDialogConfirm(data.accountingPlatformPaymentAccountId)}
        isSaving={isBillLoading || isPaymentLoading}
        onSubmissionStateChange={onSubmissionStateChange}
        onCreateOption={() => undefined}
      />
    );

    return (
      <Modal
        isOpen={isOpen}
        onClose={onDialogClose}
        data-testid="mark-as-paid-modal-screen"
        header={formatMessage('activities.payDashboard.billsTab.markAsPaidModal.title')}
        description={getModalDescription()}
        primaryButton={modalPrimaryButton}
        isLoading={isLoadingAccountingPlatforms}
      >
        {modalChildren}
      </Modal>
    );
  }
);
