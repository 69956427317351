import { Table } from '@melio/penny';
import { Bill, BillingFeeSetting, PaymentIntent } from '@melio/platform-api';
import { forwardRef, sumBy } from '@melio/platform-utils';

import { getTotalFee, usePaymentFees } from './AmountCell.utils';

export type AmountCellProps = {
  paymentIntent: PaymentIntent;
  bills: Bill[];
  orgBillingFeeSettings: BillingFeeSetting[];
};

export const AmountCell = forwardRef<AmountCellProps, 'div'>(
  ({ paymentIntent, orgBillingFeeSettings, bills, ...props }, ref) => {
    const amountToPay = sumBy(bills, (bill) => bill.balance);

    const { getFeesTooltip } = usePaymentFees(paymentIntent.estimatedFees, orgBillingFeeSettings);
    const tooltip = getFeesTooltip();
    return (
      <Table.AmountCell
        {...props}
        value={amountToPay}
        additionalAmount={{ amount: getTotalFee(paymentIntent.estimatedFees), tooltip }}
        ref={ref}
      />
    );
  }
);

AmountCell.displayName = 'AmountCell';
