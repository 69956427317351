import React from 'react';
import { Stack } from '@chakra-ui/react';
import { BrandSymbol, Link, Text } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType, Vendor, VirtualCardAccount } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { DeliveryMethodCardTheme } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component.theme';

export interface ManagedVirtualCardDeliveryMethodCardProps {
  vendor: Vendor;
  virtualCard: DeliveryMethod;
}

const useManagedVirtualCardMessages = ({ vendor, virtualCard }: ManagedVirtualCardDeliveryMethodCardProps) => {
  const { formatMessage, formatDateWithTime } = usePlatformIntl();
  const {
    settings: { virtualCardLearnMoreLink },
  } = useConfig();

  const title = formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.title');
  const helperText = formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.feeText');
  const additionalInfo = formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.info.additionalInfo', {
    accountEmail: (virtualCard.details as VirtualCardAccount).accountEmail,
  });
  const secondaryAdditionalInfo = formatMessage(
    'widgets.addOrUpdateDeliveryMethod.managedVirtualCard.info.secondaryAdditionalInfo',
    {
      vendorName: vendor.name,
      creationDate: formatDateWithTime(virtualCard.history.createdAt),
      learnMoreLink: (
        <Link
          variant="inline"
          href={virtualCardLearnMoreLink}
          label={formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.info.learnMoreText')}
          newTab
        />
      ),
    },
  );

  return { title, helperText, additionalInfo, secondaryAdditionalInfo };
};

export const ManagedVirtualCardDeliveryMethodCard = forwardRef(
  ({ vendor, virtualCard, ...props }: ManagedVirtualCardDeliveryMethodCardProps, ref) => {
    const styles = useAppTheme(DeliveryMethodCardTheme, {});
    const { title, helperText, additionalInfo, secondaryAdditionalInfo } = useManagedVirtualCardMessages({
      vendor,
      virtualCard,
    });

    return (
      <MethodCard
        type={DeliveryMethodType.VirtualCard}
        icon={<BrandSymbol type={DeliveryMethodIconsEnum.VIRTUAL_CARD} />}
        displayName={title}
        helperText={helperText}
        data-component="ManagedVirtualCardDeliveryMethodCard"
        ref={ref}
        {...props}
      >
        <Stack flexDirection="column" sx={styles['container']}>
          <Text textStyle="body4">{additionalInfo}</Text>
          <Text textStyle="body4" color="neutral.darker">
            {secondaryAdditionalInfo}
          </Text>
        </Stack>
      </MethodCard>
    );
  },
);

ManagedVirtualCardDeliveryMethodCard.displayName = 'ManagedVirtualCardDeliveryMethodCard';
