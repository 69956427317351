/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { compact } from 'lodash';
import { getIsRppsVendor, getVendorAccountNumber } from '@melio/ap-widgets';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, IconButton } from '@melio/penny';
import { OriginFlow, Traits, useAnalytics } from '@melio/platform-analytics';
import {
  DeliveryMethodType,
  UpdateVendorParams,
  useBills,
  useDeliveryMethods,
  useUnilateralRequests,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { useAppToast } from '@/hooks/useAppToast.hooks';
import { useConfirmationBarSubmissionController } from '@/hooks/useConfirmationBar';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useVendorEnrichedByIdQuery, useVendorUpdate } from '@/hooks/vendors.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum, VendorDetailsFormFields } from '@/types/vendors.types';
import { VendorDeliveryMethods } from '@/widgets/vendorsDashboard/DeliveryMethod/VendorDeliveryMethods.widget';
import { DeleteVendorModal } from '@/widgets/vendorsDashboard/Modals/DeleteVendorModal.widget';
import {
  VendorPaymentsOverview,
  VendorPaymentsOverviewHandler,
} from '@/widgets/vendorsDashboard/PaymentsOverview/VendorPaymentsOverview.widget';
import { VendorAvatarWithTitle } from '@/widgets/vendorsDashboard/VendorAvatar/VendorAvatar.widget';
import { VendorDetails, VendorDetailsHandler } from '@/widgets/vendorsDashboard/VendorDetails/VendorDetails.widget';

export const SingleVendorScreen = ({
  vendorId,
  setVendorId,
}: {
  vendorId?: string;
  setVendorId: (vendorId?: string) => void;
}) => {
  const { formatMessage } = usePlatformIntl();
  const { successToast, errorToast, infoToast } = useAppToast();
  const { updateVendor } = useVendorUpdate();
  const { goToAddNewBill } = useRouter();
  const { can } = usePermissions();
  const { setTraits, track } = useAnalytics();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const VendorDetailsRef = React.useRef<VendorDetailsHandler>(null);
  const VendorPaymentsOverviewRef = React.useRef<VendorPaymentsOverviewHandler>(null);
  const { data: vendor, isLoading: isVendorLoading } = useVendorEnrichedByIdQuery(vendorId);
  const {
    data: deliveryMethods = [],
    isLoading: isDeliveryMethodsLoading,
    create: createDeliveryMethod,
  } = useDeliveryMethods({
    vendorId: vendorId,
  });
  const { data: vendorBills, isLoading: isBillsDataLoading } = useBills({
    enabled: !!vendorId,
    params: { search: { 'vendor.id': vendorId, 'bill.balance': { $gt: 0 } } },
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobile = useIsMobile();

  const { isLoading: areUnilateralRequestsLoading } = useUnilateralRequests({
    vendorId: vendorId!,
    enabled: !!vendorId,
  });

  const isVendorGotHistory = useMemo(
    () =>
      vendor?.paymentsOverview
        ? vendor.paymentsOverview.unpaid.count > 0 ||
          vendor.paymentsOverview.paid.count > 0 ||
          vendor.paymentsOverview.scheduled.count > 0
        : false,
    [vendor],
  );

  const defaultValues = useMemo(() => {
    return {
      companyName: vendor?.name! || '',
      accountNumber: vendor && getVendorAccountNumber(vendor),
      address: vendor?.contact.address || null,
      fullName: vendor?.contact.name || '',
      email: vendor?.contact.email || '',
      phone: vendor?.contact.phoneNumber || '',
      nickname: vendor?.nickname || '',
    };
  }, [vendor]);

  React.useEffect(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, [vendor]);

  const {
    isOpen: isConfirmationBarOpen,
    showConfirmationBar,
    setSubmitButtonState,
    onSubmissionStateChange,
    reset,
  } = useConfirmationBarSubmissionController<VendorDetailsFormFields>(
    {
      submitButtonProps: {
        text: formatMessage('widgets.confirmation.singleVendor.primary'),
      },
      cancelButtonProps: {
        text: formatMessage('widgets.confirmation.singleVendor.secondary'),
      },
      defaultValues,
    },
    { resetOnSecondary: true },
  );

  const setDeliveryMethodTraits = () => {
    const traits: Traits = { added_delivery_method: true };

    setTraits(traits);
  };

  const onCreateBill = () => {
    track('VendorsActions', 'Chose', { ActionChose: 'AddBill' });
    const addBillProps = { vendorId: vendor?.id, originFlow: OriginFlow.VendorsFlow };

    goToAddNewBill(addBillProps);
  };

  const syncVirtualCardDeliveryMethod = async (updatePayload: UpdateVendorParams) => {
    const virtualCardDm = deliveryMethods?.find((dm) => dm.type === DeliveryMethodType.VirtualCard);
    const shouldUpdateVirtualCardDetails =
      virtualCardDm &&
      !virtualCardDm.isManaged &&
      updatePayload.contact?.email &&
      updatePayload.contact.email !== (virtualCardDm as VirtualCardAccountDeliveryMethod).details.accountEmail;

    if (shouldUpdateVirtualCardDetails) {
      await createDeliveryMethod({ type: 'virtual-card', details: { accountEmail: updatePayload.contact!.email! } });
      setDeliveryMethodTraits();
    }
  };

  const onEditVendor = () => {
    showConfirmationBar(true);
    // This workaround will make the scrolling only after the
    // confirmation bar appears so the scrolling will work
    track('VendorsActions', 'Chose', { ActionChose: 'Edit' });
    setTimeout(() => {
      VendorDetailsRef.current?.scrollIntoView();
    }, 0);
  };

  const onVendorDetailsFormSubmit = useCallback(
    async (payload: UpdateVendorParams) => {
      try {
        if (!vendorId) {
          return;
        }
        setSubmitButtonState({ isLoading: true });
        track('EditVendorsDetails', 'Submitted');
        await syncVirtualCardDeliveryMethod(payload);
        await updateVendor({
          vendorId: vendorId,
          body: payload,
        });
        infoToast(formatMessage('widgets.editVendor.toast.success'));
        track('EditVendorsDetailsSucceed', 'Viewed');
      } catch (e) {
        reset?.(defaultValues);
        errorToast(formatMessage('widgets.editVendor.toast.error', { companyName: payload.name }));
      } finally {
        setSubmitButtonState({ isLoading: false });
        showConfirmationBar(false);
      }
    },
    [vendor, successToast, errorToast, setSubmitButtonState, showConfirmationBar],
  );

  const onDeleteVendor = React.useCallback(() => {
    setIsDeleteConfirmationModalOpen(true);
    track('VendorsActions', 'Chose', { ActionChose: 'Delete' });
  }, [setIsDeleteConfirmationModalOpen]);

  const actionMenuItems = compact([
    {
      label: formatMessage('widgets.singleVendor.actions.addBill'),
      onClick: onCreateBill,
    },
    {
      label: formatMessage('widgets.singleVendor.actions.editVendor'),
      onClick: onEditVendor,
      dataTestId: 'vendor-menu-edit',
    },
    can({ subject: 'vendor', action: 'delete', subjectData: vendor }) && {
      label: formatMessage('widgets.singleVendor.actions.deleteVendor'),
      onClick: onDeleteVendor,
      variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
      dataTestId: 'vendor-menu-delete',
    },
  ]);

  const isRppsVendor = vendor && getIsRppsVendor(vendor);

  return (
    <WithLoading
      isLoading={isVendorLoading || isDeliveryMethodsLoading || areUnilateralRequestsLoading || isBillsDataLoading}
      isAbsoluteCenter
    >
      <HStack data-component={DataComponentEnum.SINGLE_VENDOR_PAGE}>
        {vendor && (
          <>
            <Stack flexDirection="column" width={{ xs: '100%', s: '628px' }} spacing={0} gridGap="xl">
              <HStack justifyContent={'space-between'} alignItems={'center'}>
                {isMobile && (
                  <IconButton
                    icon="chevron-left"
                    aria-label="Go back"
                    variant="naked"
                    size="medium"
                    onClick={() => setVendorId(undefined)}
                  />
                )}
                <Box width={'520px'}>
                  <VendorAvatarWithTitle vendor={vendor} />
                </Box>
                <ActionsDropdownMenu
                  isOpen={isMenuOpen}
                  onOpenChange={setIsMenuOpen}
                  label={formatMessage('widgets.singleVendor.button.text')}
                  items={actionMenuItems}
                  data-testid="vendor-menu"
                />
              </HStack>
              <VendorPaymentsOverview ref={VendorPaymentsOverviewRef} vendor={vendor} vendorBills={vendorBills ?? []} />
              <VendorDetails
                vendorId={vendor.id}
                ref={VendorDetailsRef}
                isEditable={isConfirmationBarOpen}
                onSubmit={onVendorDetailsFormSubmit}
                onSubmissionStateChange={onSubmissionStateChange}
                defaultValues={defaultValues}
                isRPPSVendor={isRppsVendor}
                managedBy={vendor.managedBy}
              />
              <VendorDeliveryMethods vendor={vendor} deliveryMethods={deliveryMethods} />
            </Stack>
            <DeleteVendorModal
              vendor={vendor}
              isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
              setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
              isVendorGotHistory={isVendorGotHistory}
            />
          </>
        )}
      </HStack>
    </WithLoading>
  );
};
