import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { SubscriptionBillingCycleEnum, useIsFeatureInSubscription, useSubscription } from '@melio/subscriptions';
import Big from 'big.js';
import { isNil } from 'lodash';

import { useManageCollaboratorsData } from '../../hooks/useManageCollaboratorsData';

type AddSeatToSubscriptionBannerMessageProps = {
  remainingFreeSeats: number;
  currentCycle: SubscriptionBillingCycleEnum;
  excessUnitFee: number | undefined;
  isFreeTrial: boolean;
};

const useAddSeatToSubscriptionBannerMessage = ({
  remainingFreeSeats,
  currentCycle,
  excessUnitFee,
  isFreeTrial,
}: AddSeatToSubscriptionBannerMessageProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  if (remainingFreeSeats > 0) {
    return formatMessage(
      'activities.manageCollaborators.screens.invitationModal.subscriptions.addUser.freeSeatsRemaining',
      { remainingFreeSeats }
    );
  }

  if (isNil(excessUnitFee)) {
    throw new Error('excessUnitFee should not be undefined when there are no remaining free seats');
  }

  const priceAmount =
    currentCycle === 'monthly' ? excessUnitFee : Number(new Big(excessUnitFee || 0).div(12).toFixed(2));

  const pricePerMonth = formatMessage(
    'activities.manageCollaborators.screens.invitationModal.subscriptions.addUser.pricePerMonth',
    {
      formattedPricePerMonth: formatCurrency(priceAmount || 0),
    }
  );

  return formatMessage(
    isFreeTrial
      ? 'activities.manageCollaborators.screens.invitationModal.subscriptions.addUser.freeTrial.info'
      : `activities.manageCollaborators.screens.invitationModal.subscriptions.addUser.info.${currentCycle}`,
    { pricePerMonth }
  );
};

const AddSeatToSubscriptionBannerMessage = (props: AddSeatToSubscriptionBannerMessageProps) => {
  const message = useAddSeatToSubscriptionBannerMessage(props);

  return (
    <SectionBanner data-testid="add-seat-to-subscription-banner" variant="neutral" icon="info" description={message} />
  );
};

export const AddSeatToSubscriptionBanner = () => {
  const subscription = useSubscription();
  const { isFreeTrial } = subscription || {};
  const { collaborators, invitations } = useManageCollaboratorsData();

  const currentCycle = subscription?.planCyclePeriod || 'monthly';

  const totalPaidCollaborators = (collaborators || []).length + (invitations || []).length;

  const {
    isEligible,
    quota: { freeUnitsLimit, excessUnitFee },
  } = useIsFeatureInSubscription({
    featureName: 'collaborators',
    requirements: {
      totalUnits: totalPaidCollaborators + 1,
    },
  });

  if (!isEligible) {
    throw new Error('this component should not be rendered if the user is not eligible to add a seat');
  }

  if (isNil(freeUnitsLimit)) {
    return <></>;
  }

  const remainingFreeSeats = freeUnitsLimit - totalPaidCollaborators;

  return (
    <AddSeatToSubscriptionBannerMessage
      remainingFreeSeats={remainingFreeSeats}
      currentCycle={currentCycle}
      excessUnitFee={excessUnitFee}
      isFreeTrial={!!isFreeTrial}
    />
  );
};
