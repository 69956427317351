import { Box } from '@chakra-ui/react';
import { DeliveryMethod } from '@melio/platform-api';

import { DeliveryMethodsList } from '@/widgets/settings-page/ReceivingMethodsPage/DeliveryMethodsList.widget';

interface ReceivingMethodsSettingProps {
  deliveryMethods: DeliveryMethod[] | undefined;
}

export const ReceivingMethodsSettingsPage = ({ deliveryMethods }: ReceivingMethodsSettingProps) => (
  <Box>
    <DeliveryMethodsList deliveryMethods={deliveryMethods} />
  </Box>
);
