import { DeliveryMethod, FundingSource, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const useDeliveryDateTooltipText = ({
  vendor,
  deliveryMethod,
  fundingSource,
}: {
  vendor: Vendor | undefined;
  deliveryMethod: DeliveryMethod | undefined;
  fundingSource: FundingSource | undefined;
}) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      payment: {
        scheduling: { disableEditDeliveryDateForCards },
      },
    },
  } = useConfig();

  if (!vendor) {
    return {
      tooltipText: formatMessage('activities.paymentFlow.form.content.deliveryDate.tooltip.disabledVendorNotSelected'),
    };
  }

  if (!deliveryMethod) {
    return {
      tooltipText: formatMessage(
        'activities.paymentFlow.form.content.deliveryDate.tooltip.disabledDeliveryMethodNotSelected'
      ),
    };
  }

  if (disableEditDeliveryDateForCards && fundingSource?.type === 'card') {
    return {
      tooltipText: formatMessage('activities.paymentFlow.form.content.deliveryDate.tooltip.disabledForCard'),
    };
  }

  return {
    tooltipText: '',
  };
};
