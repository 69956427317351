import { Button, Group } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export enum SubmitTarget {
  CLOSE = 'close',
  CONTINUE = 'continue',
}

type Props = {
  onClose: VoidFunction;
  onSubmit: () => void;
  isLoading: boolean;
};

export const FooterContent = ({ onClose, onSubmit, isLoading }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout.Footer padding="m">
      <Group width="full" justifyContent="space-between">
        <Group.Item>
          <Button
            variant="tertiary"
            onClick={onClose}
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.cancel'
            )}
            size="large"
            isLoading={isLoading}
            data-testid="layout-close-button"
            isFullWidth
          />
        </Group.Item>
        <Group.Item>
          <NewSinglePaymentStepLayout.NextButton
            size="large"
            isFullWidth
            onClick={onSubmit}
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.verify'
            )}
            isLoading={isLoading}
            aria-label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.verify'
            )}
          />
        </Group.Item>
      </Group>
    </NewSinglePaymentStepLayout.Footer>
  );
};
