import { Group, Icon, IconKey, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export const CurrentPlanCell = ({
  label,
  iconType,
  children,
}: {
  label: string;
  iconType?: IconKey;
  children: React.ReactNode;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group width="full" variant="vertical" alignItems="flex-start" spacing="xxs">
      {/* @ts-expect-error group does not support color but we need it to in order for the icon to inherit it */}
      <Group spacing="xs" color="neutral.darker">
        <Text textStyle="body4Semi" color="neutral.darker">
          {label}
        </Text>
        {iconType && (
          <Tooltip label={formatMessage('activities.settings.editablePlanCardFiserv.tooltip.subscriptionFee')}>
            <Icon type={iconType} color="inherit" size="small" />
          </Tooltip>
        )}
      </Group>
      {children}
    </Group>
  );
};
