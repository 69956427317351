import { Group, SelectionCard } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type ChooseHowToResendScreenProps = {
  vendorName: string;
  isLoading?: boolean;
  paperCheckAddress: string;
  onClose: VoidFunction;
  onSwitchToBankTransfer: VoidFunction;
  onResendPaperCheck: VoidFunction;
  achDetails?: string;
};

export const ChooseHowToResendScreen = ({
  vendorName,
  isLoading,
  paperCheckAddress,
  onClose,
  onSwitchToBankTransfer,
  onResendPaperCheck,
  achDetails,
}: ChooseHowToResendScreenProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout
      data-component="VoidAndResendPaymentActivity.ChooseHowToResendScreen"
      data-testid="void-and-resend-payment-activity-choose-how-to-resend-screen"
      isLoading={isLoading}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          <NewSinglePaymentStepLayout.ProgressBar currentStep={1} totalSteps={2} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.voidAndResend.screens.chooseHowToResend.title', {
          vendorName,
        })}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" data-testid="choose-how-to-resend-buttons">
          <SelectionCard
            mainLabelProps={{
              label: formatMessage('activities.voidAndResend.screens.chooseHowToResend.switchToBankTransfer.label'),
              badgeProps: {
                label: formatMessage(
                  'activities.voidAndResend.screens.chooseHowToResend.switchToBankTransfer.badgeLabel'
                ),
                status: 'brand',
              },
            }}
            descriptionProps={{
              label:
                achDetails ??
                formatMessage(
                  'activities.voidAndResend.screens.chooseHowToResend.switchToBankTransfer.descriptionLabel'
                ),
            }}
            icon="bank-out"
            onClick={onSwitchToBankTransfer}
            data-testid="choose-how-to-resend-switch-to-bank-transfer-button"
          />
          <SelectionCard
            mainLabelProps={{
              label: formatMessage('activities.voidAndResend.screens.chooseHowToResend.resendPaperCheck.label'),
            }}
            descriptionProps={{
              label: paperCheckAddress,
            }}
            icon="paper-check"
            onClick={onResendPaperCheck}
            data-testid="choose-how-to-resend-paper-check-button"
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

ChooseHowToResendScreen.displayName = 'VoidAndResendPaymentActivity.ChooseHowToResendScreen';
