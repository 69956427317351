import { Drawer } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Payment, useAccountingPlatforms, useBills, usePayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { LoanDrawerContext } from './LoanDrawerContext';
import { useLoanDrawerAnalytics } from './useLoanDrawerAnalytics';

export type LoanDrawerActivityProps = {
  onClose: VoidFunction;
  onViewPayment: ({ id }: { id: Payment['id'] }) => void;
};

export const LoanDrawerActivity = ({ onClose, onViewPayment }: LoanDrawerActivityProps) => {
  const { paymentId } = useParams();
  const [closed, setClosed] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { data: payment, isLoading: isPaymentLoading } = usePayment({ id: paymentId, refetchOnMount: 'always' });
  const { track, createTrackHandler } = useAnalytics();
  useLoanDrawerAnalytics({ payment });
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'exit' | 'view-payment';
  }>('Payment', 'Click');

  const {
    isMutating: isBillUpdating,
    data: bills,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
  } = useBills({
    params: {
      search: { 'bill.id': payment?.bills?.map((bill) => bill.id) },
      expand: ['vendor', 'payments', 'payments.timeline', 'payments.loan', 'file'],
    },
    enabled: !!payment?.bills,
  });

  const { activeAccountingPlatform, isFetching: isAccountingPlatformLoading } = useAccountingPlatforms();

  const isLoading =
    isPaymentLoading || isLoadingBills || isFetchingBills || isBillUpdating || isAccountingPlatformLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    track('Payment', 'View');
  }, [track, isLoading]);

  const handleCloseClick = () => {
    track('Payment', 'Click', { Cta: 'exit' });
    setClosed(true);
  };

  return (
    <LoanDrawerContext.Provider
      value={{
        onViewPayment: ({ id }: { id: Payment['id'] }) =>
          trackAndHandleClick({ Cta: 'view-payment' }, () => onViewPayment({ id })),
      }}
    >
      <Drawer
        data-testid="pay-dashboard-financing-tab-drawer"
        isOpen={!closed}
        onClose={handleCloseClick}
        onCloseComplete={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
        header={<Header />}
        closeButtonAriaLabel={formatMessage('activities.payDashboard.drawer.header.payment.closeButtonAriaLabel')}
        body={
          <Body
            isLoading={isLoading}
            payment={payment}
            bills={bills}
            activeAccountingPlatform={activeAccountingPlatform}
          />
        }
        footer={payment ? <Footer payment={payment} onClose={handleCloseClick} /> : null}
      />
    </LoanDrawerContext.Provider>
  );
};
