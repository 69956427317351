import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@melio/penny';

type SyncNowIconButtonProps = {
  onClick: VoidFunction;
  isDisabled: boolean;
  ariaLabel: string;
  variant?: IconButtonProps['variant'];
  tooltipTestId?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
  placement?: TooltipProps['placement'];
};

export const SyncNowIconButton = ({
  onClick,
  isDisabled,
  ariaLabel,
  tooltipTestId,
  tooltipTitle,
  placement,
  tooltipLabel,
  variant = 'primary',
}: SyncNowIconButtonProps) =>
  tooltipTitle ? (
    <Tooltip data-testid={tooltipTestId} label={tooltipLabel || ''} placement={placement} title={tooltipTitle}>
      <IconButton
        aria-label={ariaLabel !== tooltipTitle ? ariaLabel : ''}
        icon="refresh"
        onClick={onClick}
        size={variant === 'naked' || variant === 'nakedInverted' ? 'medium' : 'large'}
        variant={variant}
        isDisabled={isDisabled}
        data-testid="sync-now-button"
        data-tourid="sync-now-button"
      />
    </Tooltip>
  ) : (
    <IconButton
      aria-label={ariaLabel}
      icon="refresh"
      onClick={onClick}
      size={variant === 'naked' || variant === 'nakedInverted' ? 'medium' : 'large'}
      variant={variant}
      isDisabled={isDisabled}
      data-testid="sync-now-button"
      data-tourid="sync-now-button"
    />
  );
