import { useCollectionApi, UseCollectionApiProps, useCreateMutation, useMelioQueryClient } from '@melio/api-client';
import { BillsApiClient } from '@melio/platform-api-axios-client';
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { Bill as LegacyBill, BillsApiGetBillsRequest } from './types';
import { convertBillCentsToDollars, postBillsBulk, prepareBatchCreatePayload, prepareBillParams } from './utils';

const queryFn = ({ expand, search, searchTerm }: BillsApiGetBillsRequest = {}): ReturnType<
  typeof BillsApiClient.getBills
> => BillsApiClient.getBills(expand ?? 'none', searchTerm, JSON.stringify(search));

export type UseBillsProps = UseCollectionApiProps<typeof queryFn, LegacyBill> & {
  params?: BillsApiGetBillsRequest;
};

export const useBills = ({ params, ...props }: UseBillsProps = {}) => {
  const queryClient = useMelioQueryClient();
  const paramsWithDefault = {
    ...params,
    search: {
      ...params?.search,
      'bill.status': params?.search?.['bill.status'] || { $nin: ['draft', 'cancelled'] },
    },
  };
  const query = useCollectionApi<
    typeof queryFn,
    typeof BillsApiClient.postBills,
    typeof BillsApiClient.patchBillsBillId,
    typeof BillsApiClient.deleteBillsBillId,
    LegacyBill
  >({
    ...props,
    queryKey: ['BillsApi', paramsWithDefault],

    queryFn: () => queryFn(paramsWithDefault),

    createFn: BillsApiClient.postBills,
    prepareCreateParams: prepareBillParams,

    updateFn: BillsApiClient.patchBillsBillId,
    prepareUpdateParams: prepareBillParams,

    deleteFn: BillsApiClient.deleteBillsBillId,
  });

  const batchDelete = useMutation(
    query.queryKey,
    ({ ids }: { ids: string[] }) => BillsApiClient.postBillsBatchDelete({ ids }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(query.queryKey[0], 'collection');
        void queryClient.invalidateQueries(query.queryKey[0], 'paginated-collection');
      },
    }
  );

  const batchCreateFn = useCreateMutation(postBillsBulk, query.queryKey, {
    prepareData: (data) => prepareBatchCreatePayload(data),
    onSuccess: () => {
      void queryClient.invalidateQueries(query.queryKey[0], 'collection');
      void queryClient.invalidateQueries(query.queryKey[0], 'paginated-collection');
    },
  });

  return {
    ...query,
    data: useMemo(() => query.data?.map(convertBillCentsToDollars), [query.data]),
    batchDelete: batchDelete.mutateAsync,
    batchCreate: batchCreateFn.mutateAsync,
  };
};

export type BillsCollection = ReturnType<typeof useBills>;
