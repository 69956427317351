import { Button, Container, Group, GroupProps, Text, useBreakpointValue } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../../utils';
import { PayDashboardTabs } from '../../../../PayDashboard/types';

export const VendorEBillSubscriptionSection = ({ vendorId }: { vendorId: string }) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { navigateToEbillActivationForm, generateNPEDashboardLink } = useActivitiesNavigate();

  const onEBillSubscription = () => {
    track('Payment', 'Click', {
      PageName: 'payment-scheduled',
      Intent: 'subscribe',
      Cta: 'subscribe',
    });
    navigateToEbillActivationForm({ vendorId, returnUrl: generateNPEDashboardLink(PayDashboardTabs.Vendors) });
  };

  const containerSpacing = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 'm', m: 'l' });

  return (
    <Container
      overflow="initial"
      paddingX={containerSpacing}
      paddingY={containerSpacing}
      border="regular"
      data-testid="vendor-ebill-subscription-section"
    >
      <Group variant="vertical">
        <Group justifyContent="space-between" width="full" alignItems="center">
          <Text textStyle="body2Semi">
            {formatMessage(
              'activities.paymentScheduled.screens.paymentScheduled.description.singlePayment.eBills.subscription.title'
            )}
          </Text>
          <Button
            variant="primary"
            label={formatMessage(
              'activities.paymentScheduled.screens.paymentScheduled.description.singlePayment.eBills.subscription.cta'
            )}
            size="small"
            onClick={onEBillSubscription}
            data-testid="vendor-ebill-subscription-button"
          />
        </Group>
        <Text textStyle="body4" color="neutral.darker">
          {formatMessage(
            'activities.paymentScheduled.screens.paymentScheduled.description.singlePayment.eBills.subscription.description'
          )}
        </Text>
      </Group>
    </Container>
  );
};
