/* eslint-disable react-hooks/exhaustive-deps */
import { Form, FormProps, useMelioForm } from '@melio/penny';
import { AccountingPlatform, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect } from 'react';
import { date, object, SchemaOf, string } from 'yup';

import { FormWidgetProps } from '../../../types';
import { AccountingPlatformCategorySelectWidget, VendorSelectWidget } from '../../form-controls';
import { useAccountingPlatformBillCategoryLabel, useAccountingPlatformName } from '../../funding-sources';
import { getBillPaidAmount } from '../../utils/bill-utils';
import { BillDetailsFormFields, BillDetailsWidgetProps } from '../types';
import { getBillOriginLabel } from '../utils';

export type BillDetailsFormProps = FormWidgetProps<BillDetailsFormFields> & {
  billInfo: Partial<BillDetailsWidgetProps['bill']>;
  isReadOnly?: FormProps['isReadOnly'];
  isViewMode?: FormProps['isViewMode'];
  isFileAttached?: boolean;
  activeAccountingPlatform?: AccountingPlatform;
  paymentVendor?: Vendor;
};

const getDoesBillRecommendInvoiceByAmount = (amount: number, threshold: number): boolean => amount >= threshold;

export const useSchema = (bill: Partial<BillDetailsWidgetProps['bill']>) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const amountPaid = getBillPaidAmount(bill);

  return object().shape({
    vendorId: string().required(formatMessage('widgets.billDetails.form.vendorName.validation.required')).nullable(),
    amount: string()
      .required(formatMessage('widgets.billDetails.form.amount.validation.required'))
      .test(
        'validMoreThanZero',
        formatMessage('widgets.billDetails.form.amount.validation.greaterThan', {
          minValue: formatCurrency(0),
        }),
        (value?: string) => {
          const number = Number(value);
          return number > 0;
        }
      )
      .test(
        'validateGreaterOrEqualToPaidAmount',
        formatMessage('widgets.billDetails.form.amount.validation.greaterOrEqualToPaidAmount'),
        (value?: string) => {
          const number = Number(value);
          return number >= amountPaid;
        }
      ),
    minimumAmount: string().nullable(),
    billerAccountBalance: string().nullable(),
    invoiceNumber: string().nullable(),
    creationDate: date(),
    dueDate: date().nullable().required(formatMessage('widgets.billDetails.form.dueDate.validation.required')),
    note: string().nullable(),
    origin: string(),
    categoryId: string().optional(),
  }) as SchemaOf<BillDetailsFormFields>;
};

export const BillDetailsForm = forwardRef<BillDetailsFormProps, 'form'>(
  (
    {
      onSubmit,
      billInfo,
      isReadOnly,
      isViewMode,
      isSaving,
      onSubmissionStateChange,
      isFileAttached,
      activeAccountingPlatform,
      paymentVendor,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatCurrency } = useMelioIntl();
    const showCategorySelect = !!activeAccountingPlatform;
    const accountingPlatformBillCategoryLabel = useAccountingPlatformBillCategoryLabel(
      activeAccountingPlatform?.accountingSlug
    );
    const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);

    const originLabel = getBillOriginLabel(billInfo, accountingPlatformName, formatMessage);
    const [doesBillRecommendInvoice, setDoesBillRecommendInvoice] = useBoolean(false);

    const defaultValues = {
      vendorId: billInfo?.vendorId,
      amount: billInfo?.amount !== undefined ? `${billInfo?.amount}` : undefined,
      minimumAmount: billInfo?.minimumAmount !== undefined ? `${billInfo?.minimumAmount}` : undefined,
      billerAccountBalance:
        billInfo?.billerAccountBalance !== undefined ? `${billInfo?.billerAccountBalance}` : undefined,
      invoiceNumber: billInfo?.invoice?.number ?? '',
      creationDate: billInfo?.history?.createdAt,
      dueDate: billInfo?.dueDate,
      note: billInfo?.note ?? '',
      categoryId: billInfo?.categoryId,
      origin: originLabel ?? '',
    };
    const { requireInvoiceForBillsAmountThreshold } = useConfig().settings;

    const { formProps, registerField, watch } = useMelioForm<BillDetailsFormFields>({
      onSubmit,
      schema: useSchema(billInfo),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    useEffect(() => {
      getDoesBillRecommendInvoiceByAmount(Number(watch('amount')), requireInvoiceForBillsAmountThreshold)
        ? setDoesBillRecommendInvoice.on()
        : setDoesBillRecommendInvoice.off();
    }, [watch('amount'), requireInvoiceForBillsAmountThreshold]);

    const renderDueDate = !billInfo?.payments?.[0]?.subscriptionOccurrenceId;

    return (
      <Form
        data-component="BillDetailsForm"
        isViewMode={isViewMode}
        isReadOnly={isReadOnly}
        size="small"
        {...props}
        {...formProps}
        ref={ref}
      >
        <VendorSelectWidget
          labelProps={{ label: formatMessage('widgets.billDetails.form.vendorName.label') }}
          placeholder={formatMessage('widgets.billDetails.form.vendorName.placeholder')}
          viewModePlaceholder={formatMessage('widgets.billDetails.form.vendorName.viewModePlaceholder')}
          isDisabled={!isViewMode}
          paymentVendor={paymentVendor}
          eventContextName="Bill"
          {...registerField('vendorId')}
        />
        {showCategorySelect && (
          <AccountingPlatformCategorySelectWidget
            activeAccountingPlatform={activeAccountingPlatform}
            labelProps={{
              label: accountingPlatformBillCategoryLabel,
            }}
            {...registerField('categoryId')}
          />
        )}
        <Form.AmountField
          labelProps={{ label: formatMessage('widgets.billDetails.form.amount.label') }}
          viewModePlaceholder={formatMessage('widgets.billDetails.form.amount.viewModePlaceholder')}
          currency={billInfo.currency}
          {...registerField('amount')}
          {...(doesBillRecommendInvoice &&
            !isFileAttached &&
            !isViewMode && {
              helperTextProps: {
                label: formatMessage('widgets.billDetails.form.amount.helperText', {
                  threshold: formatCurrency(requireInvoiceForBillsAmountThreshold, 'USD', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }),
                }),
              },
            })}
        />
        {billInfo.billerAccountBalance && (
          <Form.AmountField
            labelProps={{ label: formatMessage('widgets.billDetails.form.billerAccountBalance.label') }}
            {...registerField('billerAccountBalance')}
          />
        )}
        {billInfo.minimumAmount && (
          <Form.AmountField
            labelProps={{ label: formatMessage('widgets.billDetails.form.minimumAmount.label') }}
            {...registerField('minimumAmount')}
          />
        )}
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.billDetails.form.invoiceNumber.label') }}
          placeholder={billInfo?.invoice?.number ?? formatMessage('widgets.billDetails.form.invoiceNumber.placeholder')}
          viewModePlaceholder={formatMessage('widgets.billDetails.form.invoiceNumber.viewModePlaceholder')}
          {...registerField('invoiceNumber')}
        />

        <Form.DateField
          labelProps={{ label: formatMessage('widgets.billDetails.form.creationDate.label') }}
          {...registerField('creationDate')}
        />
        {renderDueDate ? (
          <Form.DateField
            labelProps={{ label: formatMessage('widgets.billDetails.form.dueDate.label') }}
            placeholder={formatMessage('widgets.billDetails.form.dueDate.placeholder')}
            viewModePlaceholder={formatMessage('widgets.billDetails.form.dueDate.viewModePlaceholder')}
            {...registerField('dueDate')}
          />
        ) : null}
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.billDetails.form.origin.label') }}
          {...registerField('origin')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.billDetails.form.note.label') }}
          placeholder={formatMessage('widgets.billDetails.form.note.placeholder')}
          viewModePlaceholder={formatMessage('widgets.billDetails.form.note.viewModePlaceholder')}
          {...registerField('note')}
        />
      </Form>
    );
  }
);

BillDetailsForm.displayName = 'BillDetailsForm';
