import { MicroDepositsVerificationFormFields } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  BankAccount,
  BankAccountVerifiedStatusEnum,
  FundingSourcesVerifyRequestMicroDepositsTypeEnum,
  FundingSourcesVerifyRequestPlaidTypeEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useMemo, useState } from 'react';

import { useReconciliationModal } from '../FundingSourceSelection/useReconciliationModal';
import { ReconciliationModalActivity } from '../Reconciliation';
import { ExceedingFailureModalScreen, VerificationModalScreen } from './screens';
import {
  isMicroDepositsPayload,
  MicroDepositsVerificationModalActivityProps,
  MicroDepositsVerificationStep,
} from './types';
import { useMicroDepositVerification } from './useMicroDepositVerification';

export const MicroDepositsVerificationModalActivity: React.VFC<MicroDepositsVerificationModalActivityProps> = ({
  isOpen,
  fundingSourceId,
  deliveryMethodId,
  onClose,
  onError,
  onDone,
  onSuccessMicroDepositVerified,
}) => {
  const [isDone, setIsDone] = useState(false);
  const { fundingSource, deliveryMethod, isUpdating, refetchFundingSource, errorCode, onSuccess, isBlocked } =
    useMicroDepositVerification({ fundingSourceId, deliveryMethodId, onError });
  const [currentStep, goToStep] = useState<MicroDepositsVerificationStep>(
    isBlocked ? 'MICRO_DEPOSITS_VERIFICATION_EXCEEDING_FAILURE' : 'MICRO_DEPOSITS_VERIFICATION'
  );

  const {
    shouldRunReconciliation,
    shouldShowReconciliationModal,
    onCloseReconciliationFlow,
    onDoneReconciliationFlow,
    runReconciliationFlow,
  } = useReconciliationModal();

  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const isReconciliationNeeded = shouldRunReconciliation(fundingSource);

  const isShowVerificationModal =
    (!fundingSource?.isVerified && !deliveryMethodId) ||
    (!fundingSourceId &&
      deliveryMethod?.type === 'bank-account' &&
      deliveryMethod?.details?.verifiedStatus === BankAccountVerifiedStatusEnum.NotVerified);

  const verificationMethod = useMemo(() => {
    const isBankAccount = fundingSource
      ? fundingSource.type === 'bank-account'
      : deliveryMethod?.type === 'bank-account';
    if (!isBankAccount) {
      return null;
    }

    const bankAccount = (fundingSource ? fundingSource.details : deliveryMethod?.details) as BankAccount;

    return bankAccount.verifiedStatus === 'plaid-not-verified' ? 'plaid' : 'microDeposits';
  }, [deliveryMethod, fundingSource]);

  const verificationSuccessMessage = formatMessage(
    'activities.microDepositsVerification.screens.verification.successToastMessage'
  );

  const reconciliationSuccessMessage = formatMessage(
    'activities.microDepositsVerification.screens.verification.reconciliation.successToastMessage'
  );

  const onDoneReconciliation = () => {
    void refetchFundingSource();
    toast({ type: 'success', title: reconciliationSuccessMessage });
    onDoneReconciliationFlow();
  };

  useEffect(() => {
    if (errorCode === '409') {
      goToStep('MICRO_DEPOSITS_VERIFICATION_EXCEEDING_FAILURE');
    }
  }, [errorCode]);

  const { track } = useAnalytics();
  useEffect(() => {
    isOpen && track('MicroDepositVerification', 'Viewed');
  }, [isOpen, track]);

  useEffect(() => {
    if (isDone || !fundingSource?.isVerified || currentStep === 'MICRO_DEPOSITS_VERIFICATION_EXCEEDING_FAILURE') {
      return;
    }

    if (isReconciliationNeeded) {
      runReconciliationFlow();
    } else {
      onDone();
      setIsDone(true);
    }
  }, [
    fundingSource,
    currentStep,
    isReconciliationNeeded,
    runReconciliationFlow,
    onDone,
    verificationSuccessMessage,
    isDone,
  ]);

  const onVerifySubmitted = (
    data: MicroDepositsVerificationFormFields | null,
    onSuccessMicroDepositVerified?: VoidFunction
  ) => {
    const payload = isMicroDepositsPayload(data)
      ? {
          type: FundingSourcesVerifyRequestMicroDepositsTypeEnum.MicroDeposits,
          details: {
            amount1: Number(data.amount1),
            amount2: Number(data.amount2),
          },
        }
      : { type: FundingSourcesVerifyRequestPlaidTypeEnum.Plaid };
    return onSuccess(payload, onSuccessMicroDepositVerified);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <VerificationModalScreen
        isOpen={currentStep === 'MICRO_DEPOSITS_VERIFICATION' && isShowVerificationModal}
        onClose={onClose}
        onDone={(data) => onVerifySubmitted(data, onSuccessMicroDepositVerified)}
        isSaving={isUpdating}
        fundingSourceId={fundingSourceId}
        verificationMethod={verificationMethod}
        onError={onError}
        {...((errorCode === '400' || errorCode === '500') && { errorCode })}
      />

      <ExceedingFailureModalScreen
        onDone={onDone}
        isOpen={currentStep === 'MICRO_DEPOSITS_VERIFICATION_EXCEEDING_FAILURE'}
      />
      {!!fundingSourceId && isReconciliationNeeded && (
        <ReconciliationModalActivity
          isOpen={shouldShowReconciliationModal}
          onClose={onCloseReconciliationFlow(onClose)}
          onDone={onDoneReconciliation}
          selectedFundingSourceId={fundingSourceId}
        />
      )}
    </>
  );
};
