import { ChipProps } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

import { SelectedEntity } from '../../../delete-payment-modal';
import { PayDashboardTabSortableColumn } from '../../types';

export enum PaymentsTabFilter {
  All = 'all',
  Scheduled = 'scheduled',
  InProgress = 'inProgress',
  Paid = 'paid',
  Failed = 'failed',
}

export type PaymentChipFilter = { id: PaymentsTabFilter; label: MessageKey; status?: ChipProps['status'] };

export type PaymentsTableMeta = {
  onViewClick: (id: Payment['id']) => void;
  onEditPayment: (id: Payment['id']) => void;
  onCancelPayment: (id: Payment['id'], currentEntity: SelectedEntity) => void;
  onSuccessMarkAsUnpaid: (payment: Payment) => void;
  onMarkFailedPaymentAsPaid: (id: Payment['id']) => void;
};

export const CELLS_IDS = {
  VENDOR_NAME: 'vendorName',
  DELIVERY_METHOD: 'deliveryMethod',
  DEBIT_DATE: 'debitDate',
  DELIVERY_DATE: 'deliveryDate',
  UPDATED_AT: 'updatedAt',
  BILL: 'bill',
  STATUS: 'status',
  AMOUNT: 'amount',
  ACTION_BUTTON: 'actionButton',
} as const;

export type PaymentsTabSortableCells = Extract<
  (typeof CELLS_IDS)[keyof typeof CELLS_IDS],
  | typeof CELLS_IDS.VENDOR_NAME
  | typeof CELLS_IDS.DEBIT_DATE
  | typeof CELLS_IDS.DELIVERY_DATE
  | typeof CELLS_IDS.AMOUNT
  | typeof CELLS_IDS.UPDATED_AT
>;

export type PaymentsTabSortableColumn = PayDashboardTabSortableColumn<PaymentsTabSortableCells>;
