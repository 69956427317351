import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Icon, Text, useTheme } from '@melio/penny';
import { FeeType } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { Fee } from '@/widgets/Fee/Fee.widget';
import { PayBillCardHeader } from './CardHeader.widget';

export const PayBillCard = () => {
  const { formatMessage } = usePlatformIntl();
  const conditionKeys = ['utilities', 'rent', 'service', 'insurance', 'taxes', 'suppliers'] as const;

  const getConditions = () => {
    const components = conditionKeys.map((conditionKey) => {
      const title = formatMessage(`widgets.payDashboardCards.payBillCard.conditions.${conditionKey}.title`);
      const description = formatMessage(`widgets.payDashboardCards.payBillCard.conditions.${conditionKey}.description`);
      return <PayBillCondition key={conditionKey} title={title} description={description} />;
    });

    const returnComponents: React.ReactElement[] = [];

    for (let index = 0; index + 1 < components.length; index += 2) {
      returnComponents.push(
        <Flex key={index} direction="row" justifyContent={'space-between'} mb={4}>
          {components[index]}
          {components[index + 1]}
        </Flex>,
      );
    }
    return returnComponents;
  };

  return (
    <Flex direction={'column'}>
      <PayBillCardHeader
        title={formatMessage('widgets.payDashboardCards.payBillCard.title')}
        description={formatMessage('widgets.payDashboardCards.payBillCard.description', {
          achFee: <Fee feeType={FeeType.Ach} />,
          cardFee: <Fee feeType={FeeType.Credit} />,
          debitFee: <Fee feeType={FeeType.Debit} />,
        })}
      />
      {getConditions()}
    </Flex>
  );
};

type PayBillConditionProps = {
  title: string;
  description: string;
};
const PayBillCondition = ({ description, title }: PayBillConditionProps) => {
  const currentTheme = useTheme();
  return (
    <Flex direction="row" gap="2px" flex={1}>
      <Box sx={{ color: currentTheme.colors.neutral.darkest, display: 'inline-flex' }}>
        <Icon type="checked" color="inherit" />
      </Box>
      <Flex direction="column" flexGrow={1}>
        <Box marginBottom={1}>
          <Text textStyle="body3Semi">{title}</Text>
        </Box>
        <Text textStyle="body4">{description}</Text>
      </Flex>
    </Flex>
  );
};
