import React from 'react';
import { CompleteRequiredDetailsActivity } from '@melio/ap-activities/src/components/complete-required-details';
import { CompleteRequiredDetailsActivityProps } from '@melio/ap-activities/src/components/complete-required-details/CompleteRequiredDetails.activity';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSetAccountComplianceRecoilState } from '@/store/Compliance/compliance.model';

export const CompleteMtlDetailsScreen = withAnalyticsContext<{
  isTriggerManually?: boolean;
  onClose?: VoidFunction;
}>(({ isTriggerManually, onClose, setAnalyticsProperties }) => {
  const setAccountCompliance = useSetAccountComplianceRecoilState();
  const { formatMessage } = useMelioIntl();
  const handleOnClose = (props?: { isSubmitAllMissingFields: boolean }) => {
    const { isSubmitAllMissingFields } = props || {};
    setAccountCompliance({
      isDismissKycLoginBlockerScreen: !isSubmitAllMissingFields,
      isSubmitAllMissingFields,
    });
    onClose?.();
  };

  const onDone: CompleteRequiredDetailsActivityProps['onDone'] = ({ isSubmitAllMissingFields }) => {
    setAccountCompliance({ isSubmitted: true, isSubmitAllMissingFields });
    onClose?.();
  };

  setAnalyticsProperties({
    Flow: isTriggerManually ? 'banner' : 'sign-in',
    Intent: isTriggerManually ? 'complete-details' : 'sign-in',
  });

  return (
    <CompleteRequiredDetailsActivity
      onClose={handleOnClose}
      onDone={onDone}
      onSkip={handleOnClose}
      forceCloseButton={!!onClose}
      submitButtonLabel={formatMessage('widgets.completeRequiredDetailsModal.button.primary.label')}
    />
  );
});
