import { useAnalyticsView } from '@melio/platform-analytics';

import { AccountingPlatformSyncScreen } from '../screens';

type Props = {
  accountingPlatformName?: string;
  isLoadingAccountingPlatform: boolean;
};

export const SyncStep = ({ accountingPlatformName, isLoadingAccountingPlatform }: Props) => {
  useAnalyticsView('connecting-to-accounting-software');

  return (
    <AccountingPlatformSyncScreen
      accountingPlatformName={accountingPlatformName}
      isLoadingAccountingPlatform={isLoadingAccountingPlatform}
    />
  );
};
