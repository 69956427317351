import {
  addWildcardToRoutes,
  MessageKey,
  RouteElement,
  useGuestPayorPaymentRequestDetails,
  useMelioIntl,
  useNavigate,
  useUpdatedPaymentRequestLink,
} from '@melio/ar-domain';
import { SectionBannerProps } from '@melio/penny';
import { PartnerName } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AddBankFundingSourceActivity,
  AddCardFundingSourceActivity,
  CustomPaymentActivity,
  GuestPaymentConfirmationActivity,
  GuestPaymentRequestErrorsActivity,
  PayByBankActivity,
} from '../activities';
import { GuestPaymentLayout } from '../layout';
import { PaymentsLayoutWrapper } from './PaymentsLayoutWrapper';

export type GuestPaymentRouterFlowProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onUpdatedLink: (updatedPaymentRequestLink: string) => void;
};

export const GuestPaymentRouterFlow = forwardRef<GuestPaymentRouterFlowProps>(
  ({ onLoggedIn, onUpdatedLink, partnerName, paymentRequestLink, onViewInvoice }) => {
    const { data, isLoading, isError } = useGuestPayorPaymentRequestDetails({ paymentRequestLink });
    const { Paths, goToError, goToPayByBank, goToPaymentConfirmation, goToBank, goToCard, goToCustom } =
      useGuestPaymentRouterFlow();
    const {
      data: updatedPaymentRequestLink,
      isError: isUpdatedLinkError,
      isLoading: isUpdatedLinkLoading,
    } = useUpdatedPaymentRequestLink({ paymentRequestLink, enabled: isError });
    const { formatMessage, formatDate } = useMelioIntl();
    const [notificationInfo, setNotificationInfo] = useState<
      SectionBannerProps & { type: 'error' | 'invoice-updated' }
    >();

    const [isCreatePaymentLoading, setIsCreatePaymentLoading] = useState<boolean>(false);
    useEffect(() => {
      if (updatedPaymentRequestLink) {
        onUpdatedLink(updatedPaymentRequestLink);
      } else if (isError && isUpdatedLinkError) {
        goToError();
      }
    }, [updatedPaymentRequestLink, isError, isUpdatedLinkError]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (data?.invoice.editedAt) {
        setNotificationInfo({
          title: formatMessage('ar.guestPayment.notifications.updatedAt.title.text'),
          description: formatMessage('ar.guestPayment.notifications.updatedAt.description.text', {
            updatedAtDate: formatDate(data.invoice.editedAt),
            companyName: data.payeeDetails.companyName,
          }),
          showCloseIcon: true,
          type: 'invoice-updated',
        });
      }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!data) return;

      if (data.receivablePaymentDetails) goToPaymentConfirmation();
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const config = useConfig();
    const generateDeepLinkToPaymentRequestPayDashboard =
      config.settings.guestPaymentFlow?.generateDeepLinkToPaymentRequestPayDashboard;

    if (isUpdatedLinkLoading || isLoading) {
      return <GuestPaymentLayout isLoading />;
    }

    const setNotification = ({
      titleKey,
      descriptionKey,
      variant,
      type,
    }: {
      titleKey: MessageKey;
      descriptionKey: MessageKey;
      variant: SectionBannerProps['variant'];
      type: 'error' | 'invoice-updated';
    }) => {
      setNotificationInfo({
        title: formatMessage(titleKey),
        description: formatMessage(descriptionKey),
        variant,
        type,
        showCloseIcon: true,
      });
    };

    const handlePaymentError = () => {
      setNotification({
        titleKey: 'ar.guestPayment.notifications.createPaymentError.title.text',
        descriptionKey: 'ar.guestPayment.notifications.createPaymentError.description.text',
        variant: 'critical',
        type: 'error',
      });
    };

    const handlePaymentFormLoading = (isLoading: boolean) => {
      setIsCreatePaymentLoading(isLoading);
    };

    const redirectToPayDashboardUrl =
      generateDeepLinkToPaymentRequestPayDashboard && data
        ? generateDeepLinkToPaymentRequestPayDashboard(data.paymentRequestId)
        : undefined;

    return (
      <Routes>
        <Route
          path={Paths.Error}
          element={<GuestPaymentRequestErrorsActivity paymentRequestLink={paymentRequestLink} />}
        />
        <Route
          path={Paths.PaymentConfirmation}
          element={
            <GuestPaymentConfirmationActivity paymentRequestLink={paymentRequestLink} onViewInvoice={onViewInvoice} />
          }
        />
        <Route
          path="*"
          element={
            <PaymentsLayoutWrapper
              notificationInfo={notificationInfo}
              goToCustom={goToCustom}
              goToBank={goToBank}
              goToCard={goToCard}
              goToPayByBank={goToPayByBank}
              onViewInvoice={onViewInvoice}
              isPaymentFormLoading={isCreatePaymentLoading}
              paymentRequestLink={paymentRequestLink}
            />
          }
        >
          <Route
            path={Paths.Custom}
            element={<CustomPaymentActivity paymentRequestLink={paymentRequestLink} onViewInvoice={onViewInvoice} />}
          />
          <Route
            path={Paths.Bank}
            element={
              <AddBankFundingSourceActivity
                onLoggedIn={onLoggedIn}
                paymentRequestLink={paymentRequestLink}
                onDone={goToPayByBank}
                partnerName={partnerName}
              />
            }
          />
          <Route
            path={Paths.PayByBank}
            element={
              <RouteElement
                component={PayByBankActivity}
                pathToProps={{ fundingSourceId: 'fundingSourceId' }}
                onCreatePayment={goToPaymentConfirmation}
                onError={handlePaymentError}
                onFormLoading={handlePaymentFormLoading}
                goToBankRoute={goToBank}
                paymentRequestLink={paymentRequestLink}
              />
            }
          />
          <Route
            path={Paths.Card}
            element={
              <AddCardFundingSourceActivity
                onError={handlePaymentError}
                onLoggedIn={onLoggedIn}
                partnerName={partnerName}
                paymentRequestLink={paymentRequestLink}
                onFormLoading={handlePaymentFormLoading}
                onCreatePayment={goToPaymentConfirmation}
                redirectToPayDashboardUrl={redirectToPayDashboardUrl}
              />
            }
          />
        </Route>
      </Routes>
    );
  }
);
GuestPaymentRouterFlow.displayName = 'GuestPaymentRouterFlow';

const useGuestPaymentRouterFlow = () => {
  enum Paths {
    Custom = 'custom',
    Bank = 'bank',
    PayByBank = 'bank/:fundingSourceId',
    Card = 'card',
    Error = 'error',
    PaymentConfirmation = 'payment-confirmation',
  }

  const navigate = useNavigate<Paths>({ withSearchparams: true });
  const navigateAndReplace = (path: Paths, options: object = {}) => navigate(path, { replace: true, ...options });
  const goToBank = () => navigateAndReplace(Paths.Bank);
  const goToPayByBank = (fundingSourceId: string) =>
    navigateAndReplace(Paths.PayByBank, { pathParams: { fundingSourceId } });
  const goToCard = () => navigateAndReplace(Paths.Card);
  const goToCustom = () => navigateAndReplace(Paths.Custom);
  const goToError = () => navigateAndReplace(Paths.Error);
  const goToPaymentConfirmation = () => navigateAndReplace(Paths.PaymentConfirmation);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToBank,
    goToCard,
    goToCustom,
    goToPayByBank,
    goToError,
    goToPaymentConfirmation,
  };
};
