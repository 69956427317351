/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Stack } from '@chakra-ui/react';
import { MicroDepositsVerificationModalActivity } from '@melio/ap-activities';
import { getFundingSourceIconType, getFundingSourceImageProps } from '@melio/ap-domain';
import { isCreditCardFundingSource, PaymentReviewLineItem, usePaymentProcessingInfo } from '@melio/ap-widgets';
import { Divider, Text } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType, FundingSource, PaymentStatusEnum } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { useGetFundingSourceCardHelperText, useGetFundingSourceLabel } from '@/utils/FundingSources.utils';
import { MicroDepositModalRefHandlers } from '@/widgets/microDepositModal/MicroDepositModal.widget';

type PaymentSourceSectionProps = {
  fundingSource: FundingSource;
  scheduledDate: Date;
  paymentStatus?: PaymentStatusEnum;
  deliveryMethod: DeliveryMethod | undefined;
};

export const PayFromSection = ({
  fundingSource,
  scheduledDate,
  deliveryMethod,
  paymentStatus,
}: PaymentSourceSectionProps) => {
  const { formatMessage, formatDate, formatDateTimeRange } = usePlatformIntl();
  const microDepositRef = React.useRef<MicroDepositModalRefHandlers>(null);
  const [isMicroDepositsModalOpen, setIsMicroDepositsModalOpen] = React.useState<boolean>(false);
  const { id: fundingSourceId, type: fundingSourceType, displayName, nickname } = fundingSource;
  const { isPaymentProcessedByCapitalOne, calculateExpiryDate } = usePaymentProcessingInfo();

  const openMicroDepositModal = React.useCallback(() => {
    setIsMicroDepositsModalOpen(true);
  }, [microDepositRef.current]);

  const helperText = useGetFundingSourceCardHelperText({
    fundingSource: fundingSource,
    onClick: openMicroDepositModal,
  });

  const oneDoneHandler = React.useCallback(() => {
    setIsMicroDepositsModalOpen(false);
  }, []);

  const onClose = React.useCallback(() => {
    setIsMicroDepositsModalOpen(false);
  }, [setIsMicroDepositsModalOpen]);

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);

  const isPaymentCompleted = paymentStatus === PaymentStatusEnum.Completed;

  const scheduledDateLabel =
    isPaymentCompleted || !isPaymentProcessedByCapitalOne(deliveryMethod?.type)
      ? formatDate(scheduledDate, { dateStyle: 'medium' })
      : formatDateTimeRange(scheduledDate, calculateExpiryDate(scheduledDate), {
          dateStyle: 'medium',
        });

  const sectionTitleLabelKey = isPaymentCompleted
    ? 'widgets.paymentDetails.payFrom.title.completed'
    : 'widgets.paymentDetails.payFrom.title';

  return (
    <Stack flexDirection="column" spacing={0}>
      <Stack flexDirection="column" gridGap={'1rem'} spacing={0}>
        <Text textStyle="body4SemiUpper" color="neutral.darker">
          {formatMessage(sectionTitleLabelKey)}
        </Text>
        <PaymentReviewLineItem
          labelProps={{ label: fundingSourceLabel }}
          mainLabelProps={{ label: nickname || displayName }}
          {...(fundingSourceType === 'bank-account' && helperText ? { descriptionProps: { label: helperText } } : {})}
          {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
        />

        <Divider />

        <PaymentReviewLineItem
          labelProps={{
            label: formatMessage(
              isPaymentCompleted
                ? 'widgets.paymentDetails.payFrom.completedPaymentDate'
                : `widgets.paymentDetails.payFrom.scheduledDate.label.${
                    isCreditCardFundingSource(fundingSource) ? 'creditCardFundingType' : 'default'
                  }`,
            ),
          }}
          mainLabelProps={{ label: scheduledDateLabel }}
          descriptionProps={{
            label: formatMessage(
              `widgets.reviewAndConfirm.payFromSection.scheduledDate.description.${
                deliveryMethod?.type === DeliveryMethodType.VirtualCard ? 'virtualCard' : 'default'
              }`,
            ),
          }}
          icon={{ type: 'scheduled' }}
        />
      </Stack>
      <MicroDepositsVerificationModalActivity
        isOpen={isMicroDepositsModalOpen}
        fundingSourceId={fundingSourceId}
        onClose={onClose}
        onDone={oneDoneHandler}
      />
    </Stack>
  );
};
