import { Image, PromotionalModal, useBreakpoint, useDisclosure } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useUserPreference } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import React from 'react';

const preferenceKey = 'payDashboardFirstTimeExperience';

type FreeAchPromotionModalProps = {
  onOpen: () => boolean;
};

export const FreeAchPromotionModal = withAnalyticsContext<FreeAchPromotionModalProps>(
  ({ onOpen, setAnalyticsProperties }) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { isOpen, onClose, onOpen: onOpenFromDisclosure } = useDisclosure();
    const { isExtraSmallScreen } = useBreakpoint();
    const [isFreeAchPromotionEnabled] = useFeature<boolean>(FeatureFlags.CloverFreeAchPromotion, false);
    const [isFirstTimeExperienceEnabled] = useFeature<boolean>(FeatureFlags.NpeFirstTimeExperience, false);
    const { data: userPreference, isLoading } = useUserPreference({ id: preferenceKey });
    const config = useConfig();
    const { freeAchPromotionModalMobileImg: desktopImgUrl, freeAchPromotionModalDesktopImg: mobileImageUrl } =
      config.settings.assets;

    setAnalyticsProperties({
      PageName: 'pay-vendors-with-free-bank-transfers',
      Flow: 'ach-promotion',
      Intent: 'ach-promotion',
      PageEntryPoint: 'dashboard-vendors',
    });

    React.useEffect(() => {
      if (!isFirstTimeExperienceEnabled || (!isLoading && userPreference)) {
        if (onOpen()) {
          track('Dashboard', 'Viewed');
          onOpenFromDisclosure();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnClose = () => {
      track('Dashboard', 'Click');
      onClose();
    };

    const imageURL = isExtraSmallScreen ? mobileImageUrl : desktopImgUrl;

    return isFreeAchPromotionEnabled ? (
      <PromotionalModal
        data-testid="pay-dashboard-fee-ach-campaign-promotional-modal"
        asset={imageURL && <Image src={imageURL} alt="free ach promotion illustration" objectFit="contain" />}
        header={formatMessage('activities.payDashboard.freeAchPromotion.modal.header')}
        description={formatMessage('activities.payDashboard.freeAchPromotion.modal.description')}
        primaryButton={{
          label: formatMessage('activities.payDashboard.freeAchPromotion.modal.button'),
          variant: 'primary',
          onClick: handleOnClose,
        }}
        onClose={handleOnClose}
        isOpen={isOpen}
      />
    ) : (
      <></>
    );
  }
);
