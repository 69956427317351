import { Container, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../api';
import { usePlansTiers, useRedirects, useSubscription } from '../../../hooks';
import { CancelOrDowngradeFlowActivity, SwitchSubscriptionBillingCycleModalScreen } from '../../activities';
import { SubscriptionCancelOrDowngradeOriginEnum } from '../../constants';
import { EditablePlanCard } from './EditablePlanCard';
import { FreePlanCard } from './FreePlanCard';

export const SubscriptionPlansSelect = () => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState<boolean>(false);
  const { track } = useAnalytics();
  const { toast } = useToast();
  const { goToSettingsPage } = useRedirects();
  const { formatMessage } = useMelioIntl();

  const subscription = useSubscription();
  const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({
    enabled: false,
  });
  const { freePlan } = usePlansTiers();

  const isFreePlan = subscription && subscription.planId === freePlan?.id;
  const newCycle =
    subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
      ? SubscriptionBillingCycleEnum.Annual
      : SubscriptionBillingCycleEnum.Monthly;
  const onOpenCancelModal = () => setIsCancelModalOpen(true);
  const onCloseCancelModal = () => setIsCancelModalOpen(false);
  const onOpenSwitchModal = () => setIsSwitchModalOpen(true);
  const onCloseSwitchModal = () => setIsSwitchModalOpen(false);
  const onSwitchBillingCycle = () => {
    void updateSubscription({ planId: subscription?.planId, planCyclePeriod: newCycle })
      .then(() => {
        track('Organization', 'Status', {
          Intent: 'upgrade-plan',
        });
        goToSettingsPage();
        onCloseSwitchModal();
      })
      .catch(() => {
        toast({
          id: 'subscription-checkout-error',
          type: 'error',
          title: formatMessage('activities.subscription.checkout.summary.toast.error'),
        });
      });
  };

  return (
    <Container>
      {isFreePlan ? (
        <FreePlanCard />
      ) : (
        <EditablePlanCard onOpenCancelModal={onOpenCancelModal} onOpenSwitchBillingCycleModal={onOpenSwitchModal} />
      )}
      {freePlan && (
        <CancelOrDowngradeFlowActivity
          isOpen={isCancelModalOpen}
          newPlanId={freePlan.id}
          onClose={onCloseCancelModal}
          origin={SubscriptionCancelOrDowngradeOriginEnum.Settings}
        />
      )}
      {subscription && (
        <SwitchSubscriptionBillingCycleModalScreen
          isOpen={isSwitchModalOpen}
          onClose={onCloseSwitchModal}
          onChange={onSwitchBillingCycle}
          subscription={subscription}
          isSubscribing={isUpdatingSubscription}
        />
      )}
    </Container>
  );
};
