import { Route, Routes } from 'react-router-dom';
import { GuestPaymentRouter, InvoiceDashboardRouter } from '@melio/ar-app';
import { addWildcardToRoutes, useNavigate } from '@melio/ar-domain';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { usePartnerConfig } from '@/hooks/partners';
import { useResetAccessToken } from '@/hooks/session.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { NotFoundScreen } from '@/screens/error-screens/NotFound.screen';
import { ScreensEnum } from '@/store/app/app.types';
import { DashboardLayout } from '../../layouts/Dashboard.layout';
import { WithAnonymousInitialData, WithInitialData } from '../../utils';

export const ARRouter = () => {
  useActiveScreen(ScreensEnum.ARDashboard);
  const [arDashboardEnabled] = useFeature(FeatureFlags.ARDashboard, false);
  const { partnerName } = usePartnerConfig();
  const { goToSupportSettings } = useSettingsRouterNavigation();

  return (
    <Routes>
      <Route
        path="meliome/*"
        element={
          <GuestPaymentRouter
            partnerName={partnerName}
            WithAnonymousInitialData={WithAnonymousInitialData}
            useResetAccessToken={useResetAccessToken}
          />
        }
      />
      <Route
        path="*"
        element={
          arDashboardEnabled ? (
            <InvoiceDashboardRouter
              onVisitSupportSettingsPage={goToSupportSettings}
              DashboardLayout={DashboardLayout}
              WithInitialData={WithInitialData}
            />
          ) : (
            <NotFoundScreen />
          )
        }
      />
    </Routes>
  );
};

const useSettingsRouterNavigation = () => {
  enum Paths {
    SupportSetting = '/settings/support',
  }
  const navigate = useNavigate<Paths>();

  const goToSupportSettings = () => {
    navigate(Paths.SupportSetting);
  };

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToSupportSettings,
  };
};
