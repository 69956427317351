import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { useEffect, useState } from 'react';

import { CollapsibleCardSection } from '../../../../components/CollapsibleCardSection/CollapsibleCardSection.component';
import { useNewFirmClientFormSchema, useNewFirmClientStepManager } from '../../hooks';
import {
  FormState,
  NewFirmClientFormField,
  NewFirmClientFormFields,
  NewFirmClientFormSteps,
  SubscriptionBillingPayor,
  TriggerFieldsFn,
  WatchFn,
} from '../../types';

type NewFirmClientFormProps = {
  onSuccess: (data: NewFirmClientFormFields) => void;
};

const useTriggerFormStepValidationFields = ({
  watch,
  trigger,
  formState,
}: {
  trigger: TriggerFieldsFn;
  watch: WatchFn;
  formState: FormState;
}) => {
  const [lastStepTriggeredFields, setLastStepLastStepTriggeredFields] = useState<Array<NewFirmClientFormField>>([]);

  const watchTriggeredFields = watch(lastStepTriggeredFields);
  // hold last triggered fields keys in order to track change in form state
  const triggeredFieldsKey = [...lastStepTriggeredFields, ...watchTriggeredFields].join('');
  // Trigger validations on every field change (after the first attempt)
  useEffect(() => {
    void trigger(lastStepTriggeredFields);
  }, [triggeredFieldsKey, lastStepTriggeredFields, trigger]);

  return async (fields: NewFirmClientFormField[]) => {
    await trigger(fields);
    setLastStepLastStepTriggeredFields(fields);

    const filteredErrors = fields.reduce<Partial<Record<NewFirmClientFormField, boolean>>>((acc, field) => {
      if (formState.errors[field]) {
        acc[field] = true;
      }
      return acc;
    }, {});

    return Object.keys(filteredErrors).length === 0;
  };
};

export const NewFirmClientForm: React.FC<NewFirmClientFormProps> = ({ onSuccess }) => {
  const { formatMessage } = useMelioIntl();
  const { steps, expandSelectedStep, nextStep } = useNewFirmClientStepManager();
  const onFormFinish = (data: NewFirmClientFormFields) => {
    onSuccess(data);
  };

  const { formProps, formState, setValue, registerField, trigger, watch, submitButtonProps } =
    useMelioForm<NewFirmClientFormFields>({
      onSubmit: onFormFinish,
      schema: useNewFirmClientFormSchema(),
      defaultValues: {
        businessDBA: '',
        businessName: '',
        clientEmailAddress: '',
        clientFirstName: '',
        clientLastName: '',
        shouldInviteClient: false,
        whoPays: SubscriptionBillingPayor.Client,
      },
      subscribeToDefaultValuesChanges: true,
    });

  const triggerFormStepValidationFields = useTriggerFormStepValidationFields({ watch, trigger, formState });

  const onExpandChange = (expanded: boolean, expandedStepId: NewFirmClientFormSteps) => {
    if (expanded) {
      expandSelectedStep(expandedStepId);
    }
  };

  return (
    <Form {...formProps}>
      {steps.map(({ stepId, title, description, isExpanded, isDisabled, component: StepFromComponent }) => {
        const isSubmitStep = steps[steps.length - 1]?.stepId === stepId;
        const submitLabel = isSubmitStep
          ? formatMessage(`activities.accountants.newClient.form.submitButton.label`)
          : formatMessage(`activities.accountants.newClient.form.continueButton.label`);

        return (
          <CollapsibleCardSection
            key={stepId}
            id={stepId}
            title={title}
            description={description}
            isExpanded={isExpanded}
            isDisabled={isDisabled}
            onExpandChange={(expanded) => onExpandChange(expanded, stepId)}
          >
            <StepFromComponent
              submitLabel={submitLabel}
              watch={watch}
              registerField={registerField}
              setValue={setValue}
              triggerFormValidationFields={triggerFormStepValidationFields}
              nextStep={isSubmitStep ? submitButtonProps?.onClick : nextStep}
            />
          </CollapsibleCardSection>
        );
      })}
    </Form>
  );
};
