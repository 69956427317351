import { Modal, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { SetInvoiceNumberForm } from './SetInvoiceNumberForm';
import { InvoiceNumberSchema, SetInvoiceNumberModalProps } from './types';

const addInvoiceNumberModalAnalytics = {
  PageName: 'add-an-invoice-number',
  Intent: 'add-invoice-number',
};

export const SetInvoiceNumberModal = ({
  isOpen,
  onClose,
  onSubmitInvoiceNumber,
  onContinueWithoutInvoiceNumber,
  vendorName,
  analytics,
}: SetInvoiceNumberModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<InvoiceNumberSchema>();
  const { track } = useAnalytics();

  useAnalyticsView(analytics.contextView, true, false, addInvoiceNumberModalAnalytics);

  const defaultValues = { invoiceNumber: '' };
  const handleSubmitInvoiceNumber = ({ invoiceNumber }: InvoiceNumberSchema) => {
    track(analytics.contextView, 'Click', {
      ...addInvoiceNumberModalAnalytics,
      Cta: 'add-invoice-and-continue',
    });
    onSubmitInvoiceNumber({ invoiceNumber });
  };

  const handleContinueWithoutInvoiceNumber = () => {
    track(analytics.contextView, 'Click', {
      ...addInvoiceNumberModalAnalytics,
      Cta: 'continue-without-invoice',
    });
    onContinueWithoutInvoiceNumber();
  };

  const handleErrorSchema = () => {
    track(analytics.contextView, 'Click', {
      ...addInvoiceNumberModalAnalytics,
      ErrorType: 'add-invoice-number-or-continue-without',
      Status: 'failure',
    });
  };

  const handleCloseModal = () => {
    track(analytics.contextView, 'Click', {
      ...addInvoiceNumberModalAnalytics,
      Cta: 'exit-invoice',
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      data-testid="set-invoice-number-modal"
      header={formatMessage('activities.addBill.setInvoiceNumberModal.title')}
      description={formatMessage('activities.addBill.setInvoiceNumberModal.description', { vendorName })}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage('activities.addBill.setInvoiceNumberModal.primaryButton'),
      }}
      secondaryButton={{
        variant: 'secondary',
        label: formatMessage('activities.addBill.setInvoiceNumberModal.secondaryButton'),
        onClick: handleContinueWithoutInvoiceNumber,
      }}
    >
      <SetInvoiceNumberForm
        onSubmit={handleSubmitInvoiceNumber}
        onSubmissionStateChange={onSubmissionStateChange}
        defaultValues={defaultValues}
        onError={handleErrorSchema}
      />
    </Modal>
  );
};
