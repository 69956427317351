import React from 'react';
import { Box } from '@chakra-ui/react';
import { Button, SectionBanner, Text, useBreakpoint } from '@melio/penny';
import { AccountingPlatformSlug } from '@melio/platform-api';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { AccountingPlatformCardTheme } from './AccountingPlatformCard.component.theme';

type AccountingPlatformButton = {
  text: string;
  onClicked: VoidFunction;
  isLoading?: boolean;
  isDisable?: boolean;
  dataTestId?: string;
};
type AccountingPlatformCardProps = {
  logo: React.ReactElement | null;
  title: string;
  descriptionList: string[];
  errorText?: string;
  buttons: AccountingPlatformButton[];
  lastSync?: string;
  accountingPlatformSlug?: AccountingPlatformSlug;
  titleAs?: 'span' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const AccountingPlatformCard = ({
  buttons,
  descriptionList,
  logo,
  title,
  errorText,
  lastSync,
  accountingPlatformSlug,
  titleAs = 'span',
}: AccountingPlatformCardProps) => {
  const { isExtraSmallScreen } = useBreakpoint();

  const renderButtons = () => {
    return buttons.map((button, index) => {
      const { text, onClicked, isDisable, isLoading, dataTestId } = button;
      return (
        <Button
          key={index}
          variant={'tertiary'}
          onClick={onClicked}
          isDisabled={isDisable}
          isLoading={isLoading}
          label={text}
          data-testid={dataTestId}
        />
      );
    });
  };
  const renderDescriptions = () => {
    return descriptionList.map((description, index) => (
      <Text textStyle={isExtraSmallScreen ? 'body3' : 'body2'} key={index}>
        {description}
      </Text>
    ));
  };
  const styles = useAppTheme(AccountingPlatformCardTheme, {});
  return (
    <Box
      data-component="AccountingPLatformCardContainer"
      sx={styles['container']}
      data-testid={`accounting-platform-card-${accountingPlatformSlug}`}
    >
      <Box>{logo}</Box>
      <Box sx={styles['titleContainer']}>
        <Text as={titleAs} textStyle={isExtraSmallScreen ? 'heading1Semi' : 'heading3Semi'}>
          {title}
        </Text>
      </Box>
      <Box sx={styles['bodyContainer']}>
        {errorText && (
          <SectionBanner
            data-testid={`accounting-platform-card-${accountingPlatformSlug}-error-section`}
            variant="critical"
            description={errorText}
          />
        )}
        {renderDescriptions()}
      </Box>
      <Box sx={styles['buttonsContainer']}>{renderButtons()}</Box>
      {lastSync && (
        <Box sx={styles['footerContainer']}>
          <Text color="neutral.darker">{lastSync}</Text>
        </Box>
      )}
    </Box>
  );
};
