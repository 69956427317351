import { Container, Group, IconButton, Text } from '@melio/penny';
import React from 'react';

type NewClientHeaderProps = {
  title: string;
  onClose?: () => void;
};

export const FlowHeader: React.FC<NewClientHeaderProps> = ({ title, onClose }) => (
  <Container justifyContent="space-between" alignItems="center" paddingBottom="m">
    <Group>
      <Text textStyle="heading1Semi">{title}</Text>
    </Group>
    {onClose && (
      <Group>
        <IconButton aria-label="icon-button-prev" icon="close" onClick={onClose} size="medium" variant="primary" />
      </Group>
    )}
  </Container>
);
