import { Form, StatusModal, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment, PostApprovalDecisionEnum, usePayments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig } from '@melio/platform-provider';
import { useNavigate } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, string } from 'yup';

import { MonitoredAction } from '../../../monitoring';
import { useBatchPaymentApprovalDecision } from '../../payment-drawer/hooks/useBatchPaymentApprovalDecision';

type FormFields = { reason?: string };
type Props = {
  paymentsIds: Payment['id'][];
  isOpen: boolean;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  onFailure?: VoidFunction;
  navigatePath: string;
};

export const PaymentApprovalWorkflowDeclineModalActivity = withAnalyticsContext<Props>(
  ({ paymentsIds, isOpen, onClose, onSuccess, onFailure, navigatePath, setAnalyticsProperties }) => {
    const { formatMessage, formatCurrency } = useMelioIntl();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const navigate = useNavigate();
    const { track } = useAnalytics();
    const {
      settings: { isDeclinePaymentReasonEnabled },
    } = useConfig();
    const { batchApprovalDecision, isMutating } = useBatchPaymentApprovalDecision({
      onToastActionClick: () => navigate(navigatePath),
    });
    const { data: payments, isLoading } = usePayments({
      enabled: paymentsIds.length > 0,
      params: {
        expand: ['subscriptionOccurrence', 'vendor', 'createdBy'],
        pendingCurrentUserApproval: true,
        search: { 'payment.id': paymentsIds },
      },
    });

    setAnalyticsProperties({ Flow: 'dashboard', Intent: 'decline-approval', PaymentId: paymentsIds });

    useEffect(() => {
      track('Dashboard', 'View', { PageName: 'decline-payment' });
    }, [track]);

    const handleClose = () => {
      track('Dashboard', 'Click', { Cta: 'exit' });
      onClose();
    };

    const handleSubmit = async (fields?: FormFields) => {
      try {
        const declineReason = fields?.reason;
        startAction('payment_decline');
        await batchApprovalDecision(paymentsIds, PostApprovalDecisionEnum.Declined, declineReason);
        track('Dashboard', 'Click', { Cta: 'decline-payment', note: !!declineReason });
        onClose();
        onSuccess?.();
        endAction('payment_decline');
      } catch (e) {
        onFailure?.();
      }
    };

    const { submitButtonProps, cancelButtonProps, onSubmissionStateChange } = useFormSubmissionController<FormFields>();
    const { formProps, registerField } = useMelioForm<FormFields>({
      onSubmit: handleSubmit,
      schema: object().shape({ reason: string().trim().optional() }),
      isSaving: isMutating,
      onSubmissionStateChange,
    });

    const isMultiplePaymentsToDecline = paymentsIds.length > 1;
    const singlePayment = payments?.length === 1 ? payments?.[0] : undefined;
    const isPaymentToDeclineIsRecurringPayment =
      paymentsIds?.length === 1 &&
      payments?.find((payment) => paymentsIds.includes(payment.id) && payment.subscriptionOccurrenceId);

    const pluralSuffix = paymentsIds.length > 1 ? 's' : '';

    const headerText = isPaymentToDeclineIsRecurringPayment
      ? formatMessage('activities.paymentApprovalWorkflowDeclineModal.recurring.header')
      : formatMessage('activities.paymentApprovalWorkflowDeclineModal.header', {
          pluralSuffix,
        });

    const getDescriptionText = () => {
      if (isPaymentToDeclineIsRecurringPayment && singlePayment) {
        return formatMessage('activities.paymentApprovalWorkflowDeclineModal.description.recurringDecline', {
          vendor: singlePayment.vendor?.name,
          firstName: singlePayment.createdBy?.firstName,
          lastName: singlePayment.createdBy?.lastName,
        });
      }
      if (!isMultiplePaymentsToDecline && singlePayment) {
        return formatMessage('activities.paymentApprovalWorkflowDeclineModal.description.singleDecline', {
          amount: formatCurrency(singlePayment?.amount),
          vendor: singlePayment.vendor?.name,
          firstName: singlePayment.createdBy?.firstName,
          lastName: singlePayment.createdBy?.lastName,
        });
      }
      return formatMessage('activities.paymentApprovalWorkflowDeclineModal.description.batchDecline', {
        numberOfPayments: paymentsIds.length,
      });
    };

    return (
      <StatusModal
        isOpen={isOpen}
        role="alertdialog"
        onClose={handleClose}
        variant="alert"
        data-testid="payment-approval-workflow-decline-modal"
        header={headerText}
        primaryButton={{
          onClick: handleSubmit,
          isLoading: isMutating,
          ...(isDeclinePaymentReasonEnabled ? { ...submitButtonProps } : {}),
          label: formatMessage('activities.paymentApprovalWorkflowDeclineModal.primaryButton', { pluralSuffix }),
          variant: 'critical',
        }}
        secondaryButton={{
          ...(isDeclinePaymentReasonEnabled ? { ...cancelButtonProps } : {}),
          label: formatMessage('activities.paymentApprovalWorkflowDeclineModal.secondaryButton'),
          variant: 'tertiary',
          onClick: handleClose,
        }}
        isLoading={isLoading || isMutating}
        description={getDescriptionText()}
      >
        {isDeclinePaymentReasonEnabled && (
          <Form data-component="PaymentApprovalWorkflowDeclineForm" {...formProps}>
            <Form.TextField
              {...registerField('reason')}
              labelProps={{
                label: formatMessage('activities.paymentApprovalWorkflowDeclineModal.fields.reason.label'),
              }}
              placeholder={formatMessage('activities.paymentApprovalWorkflowDeclineModal.fields.reason.placeholder')}
            />
          </Form>
        )}
      </StatusModal>
    );
  }
);
