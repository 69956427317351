import { getCurrencies } from '@melio/international-payments-utils';
import { FlagKey } from '@melio/penny';
import { FxCurrency } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

export type CurrencyDetails = { label: MessageKey; flag: FlagKey; sign: string; currencyCode: FxCurrency };

export type CurrencyDictionaryType = Record<FxCurrency, CurrencyDetails>;

const currencies: string[] = getCurrencies();
export const supportedCurrencies = ['USD', ...currencies];
export const isCurrencySupportedWithFX = (currency?: FxCurrency) => (currency ? currencies.includes(currency) : false);

export const currencyDictionary: CurrencyDictionaryType = {
  USD: {
    label: 'currencies.USD.label',
    flag: 'united-states',
    sign: '$',
    currencyCode: 'USD',
  },
  CAD: {
    label: 'currencies.CAD.label',
    flag: 'canada',
    sign: '$',
    currencyCode: 'CAD',
  },
  EUR: {
    label: 'currencies.EUR.label',
    flag: 'european-union',
    sign: '€',
    currencyCode: 'EUR',
  },
  GBP: {
    label: 'currencies.GBP.label',
    flag: 'united-kingdom',
    sign: '£',
    currencyCode: 'GBP',
  },
  INR: {
    label: 'currencies.INR.label',
    flag: 'india',
    sign: '₹',
    currencyCode: 'INR',
  },
  CNY: {
    label: 'currencies.CNY.label',
    flag: 'china',
    sign: '¥',
    currencyCode: 'CNY',
  },
  HKD: {
    label: 'currencies.HKD.label',
    flag: 'hong-kong',
    sign: '$',
    currencyCode: 'HKD',
  },
  PHP: {
    label: 'currencies.PHP.label',
    flag: 'philippines',
    sign: '₱',
    currencyCode: 'PHP',
  },
  PLN: {
    label: 'currencies.PLN.label',
    flag: 'poland',
    sign: 'zł',
    currencyCode: 'PLN',
  },
  BRL: {
    label: 'currencies.BRL.label',
    flag: 'brazil',
    sign: 'R$',
    currencyCode: 'BRL',
  },
  AUD: {
    label: 'currencies.AUD.label',
    flag: 'australia',
    sign: '$',
    currencyCode: 'AUD',
  },
  ILS: {
    label: 'currencies.ILS.label',
    flag: 'israel',
    sign: '₪',
    currencyCode: 'ILS',
  },
  SEK: {
    label: 'currencies.SEK.label',
    flag: 'sweden',
    sign: 'kr',
    currencyCode: 'SEK',
  },
  SGD: {
    label: 'currencies.SGD.label',
    flag: 'singapore',
    sign: '$',
    currencyCode: 'SGD',
  },
  KRW: {
    label: 'currencies.KRW.label',
    flag: 'south-korea',
    sign: '₩',
    currencyCode: 'KRW',
  },
  GEL: {
    label: 'currencies.GEL.label',
    flag: 'georgia',
    sign: '₾',
    currencyCode: 'GEL',
  },
  MXN: {
    label: 'currencies.MXN.label',
    flag: 'mexico',
    sign: '$',
    currencyCode: 'MXN',
  },
};

export const defaultCurrencyDetails: CurrencyDetails = {
  label: 'currencies.USD.label',
  flag: 'united-states',
  sign: '$',
  currencyCode: 'USD',
};

export const getCurrencyDetails = (currency?: FxCurrency) =>
  currency ? currencyDictionary[currency] : defaultCurrencyDetails;

export const supportedCurrenciesDefs: CurrencyDetails[] = Object.keys(currencyDictionary)
  .filter((key) => supportedCurrencies.includes(key as FxCurrency))
  .map((key) => currencyDictionary[key as FxCurrency])
  .sort((a, b) => a.currencyCode.localeCompare(b.currencyCode));
