import { Button, Container, Group, GroupProps, Text, useBreakpoint, useBreakpointValue } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../../utils';
import { PayDashboardTabs } from '../../../../PayDashboard/types';

export type VendorAutoPaySetupSectionProps = {
  vendorId?: string;
};

export const VendorAutoPaySetupSection = ({ vendorId }: VendorAutoPaySetupSectionProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { navigateToAutopayForm, generateNPEDashboardLink } = useActivitiesNavigate();

  const onAutoPaySetupClick = () => {
    if (vendorId) {
      track('Payment', 'Click', {
        PageName: 'payment-scheduled',
        Intent: 'set-up-autopay',
        Cta: 'set-up-autopay',
      });
      navigateToAutopayForm({ vendorId, returnUrl: generateNPEDashboardLink(PayDashboardTabs.Vendors) });
    }
  };

  const containerSpacing = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 'm', m: 'l' });

  return (
    <Container
      overflow="initial"
      paddingX={containerSpacing}
      paddingY={containerSpacing}
      border="regular"
      data-testid="vendor-autopay-setup-section"
    >
      <Group variant="vertical">
        <Group justifyContent="space-between" width="full" alignItems="center">
          <Text textStyle="body2Semi">
            {formatMessage('activities.paymentScheduled.screens.paymentScheduled.autoPay.setup.title')}
          </Text>

          {!isMobile ? (
            <Button
              variant="primary"
              label={formatMessage('activities.paymentScheduled.screens.paymentScheduled.autoPay.setup.button')}
              size="small"
              onClick={onAutoPaySetupClick}
              data-testid="autopay-setup-button"
            />
          ) : null}
        </Group>
        <Text textStyle="body4" color="neutral.darker">
          {formatMessage('activities.paymentScheduled.screens.paymentScheduled.autoPay.setup.description')}
        </Text>

        {isMobile ? (
          <Button
            variant="primary"
            label={formatMessage('activities.paymentScheduled.screens.paymentScheduled.autoPay.setup.button')}
            size="small"
            onClick={onAutoPaySetupClick}
            data-testid="autopay-setup-button"
          />
        ) : null}
      </Group>
    </Container>
  );
};
