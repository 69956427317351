import { Table, Text } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const VendorsTabLastPaidCell = ({ vendor }: { vendor: Vendor }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { lastPaymentDate } = vendor;

  return (
    <Table.Cell data-testid={`vendors-tab-row-${vendor.id}-last-paid-cell`}>
      {lastPaymentDate ? (
        <Text textStyle="body3">{formatDate(lastPaymentDate, { dateStyle: 'medium' })}</Text>
      ) : (
        <Text textStyle="body3" color="neutral.darker">
          {formatMessage('activities.payDashboard.vendorsTab.table.cells.lastPaid.none')}
        </Text>
      )}
    </Table.Cell>
  );
};
