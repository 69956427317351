import { useMelioIntl } from '@melio/ar-domain';
import { Modal } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type AddReceivingMethodScreenProps = {
  onClose: VoidFunction;
  onAddPlaidAccount: VoidFunction;
  isSaving: boolean;
  isOpen: boolean;
};

export const AddReceivingMethodModalScreen = forwardRef<AddReceivingMethodScreenProps>(
  ({ onClose, onAddPlaidAccount, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    return (
      <Modal
        data-testid="add-receiving-method-modal-screen"
        data-component={AddReceivingMethodModalScreen.displayName}
        onClose={isSaving ? () => null : onClose}
        header={formatMessage('ar.invoiceLifecycle.activities.receivingMethods.modal.addReceivingMethod.title')}
        description={formatMessage(
          'ar.invoiceLifecycle.activities.receivingMethods.modal.addReceivingMethod.description'
        )}
        primaryButton={{
          label: formatMessage(
            'ar.invoiceLifecycle.activities.receivingMethods.modal.addReceivingMethod.primaryButton.label'
          ),
          onClick: onAddPlaidAccount,
          variant: 'primary',
          isLoading: isSaving,
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.invoiceLifecycle.activities.receivingMethods.modal.addReceivingMethod.secondaryButton.label'
          ),
          onClick: onClose,
          variant: 'tertiary',
          isDisabled: isSaving,
        }}
        {...props}
        ref={ref}
      />
    );
  }
);
AddReceivingMethodModalScreen.displayName = 'AddReceivingMethodScreen';
