/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount, useAccounts } from '@melio/platform-api';

import { AccountCardList } from '@/cl/components/AccountCard/AccountCardList.component';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountCardWidget } from '@/widgets/AccountCard/AccountCard.widget';

export const AccountsDashboardScreen = () => {
  const { track } = useAnalytics();
  const { formatMessage } = usePlatformIntl();
  const { data: me } = useAccount({ id: 'me' });
  const { data: accounts = [] } = useAccounts();

  useEffect(() => {
    track(`CompaniesDashboard`, 'Viewed');
  }, []);

  return (
    <AccountCardList title={formatMessage('app.accountsDashboard.title')}>
      {accounts.map((account) => (
        <AccountCardWidget key={`account_card_${account.id}`} account={account} isSelected={me?.id === account.id} />
      ))}
    </AccountCardList>
  );
};
