import { useNavigate, useParams } from 'react-router-dom';
import { EBillsSubscriptionFlow } from '@melio/ap-activities';
import { useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useRouter } from '@/hooks/router.hooks';

export const EBillsSubscriptionRoute = () => {
  const { vendorId } = useParams<string>();

  const navigate = useNavigate();
  const { generateNPEDashboardLink } = useRouter();
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const handleClose = () => {
    navigate(generateNPEDashboardLink('vendors'), { state: { keepToast: true } });
  };

  const handleDone = () => {
    toast({
      type: 'success',
      title: formatMessage('activities.ebillsSubscriptionSuccess.toast.title'),
      action: {
        type: 'button',
        text: formatMessage('activities.ebillsSubscriptionSuccess.toast.action.title'),
        onAction: (closeToast) => {
          navigate(generateNPEDashboardLink(`vendors/vendor/${vendorId}`));
          closeToast();
        },
      },
    });
    handleClose();
  };

  return <EBillsSubscriptionFlow onDone={handleDone} onClose={handleClose} />;
};
