import { InvoiceExpandedSummary, useMelioIntl } from '@melio/ar-domain';
import { Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

type ListItemProps = {
  title: string;
  value: string;
  testId: string;
};

const ListItem: React.FC<ListItemProps> = ({ title, value, testId }) => (
  <Group variant="vertical" spacing="xxs" data-testid={`invoice-drawer-${testId}-list-item`}>
    <Text textStyle="body4Semi" color="neutral.darker" data-testid="list-item-title">
      {title}
    </Text>
    <Text textStyle="body2" data-testid="list-item-value">
      {value}
    </Text>
  </Group>
);

type InvoiceBasicDetailsProps = {
  expandedInvoice: InvoiceExpandedSummary;
  companyName: string;
};

export const InvoiceBasicDetails = forwardRef<InvoiceBasicDetailsProps>(({ companyName, expandedInvoice }, ref) => {
  const { formatDate, formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="m" ref={ref}>
      <ListItem
        title={formatMessage('ar.dashboard.activities.InvoiceDrawer.body.summary.listItem.billTo.title.label')}
        value={companyName}
        testId="bill-to"
      />
      <ListItem
        title={formatMessage('ar.dashboard.activities.InvoiceDrawer.body.summary.listItem.dueDate.title.label')}
        testId="due-date"
        value={formatDate(expandedInvoice.dueDate)}
      />
      {expandedInvoice.note ? (
        <ListItem
          title={formatMessage('ar.dashboard.activities.InvoiceDrawer.body.summary.listItem.message.title.label')}
          value={expandedInvoice.note}
          testId="message"
        />
      ) : null}
    </Group>
  );
});
