import { BankAccountFormModel, useAchValidationSchema } from '@melio/ap-widgets';
import { useMelioForm, useToast } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  useDeliveryMethods,
  useDeliveryMethodUnmaskedAccountNumber,
  Vendor,
} from '@melio/platform-api';

import { useConfirmationModal } from '../useConfirmationModal';

export function useBankAccountDetailsFormModal({
  vendor,
  onDone,
}: {
  vendor?: Vendor;
  onDone: (deliveryMethod: BankAccountDeliveryMethod) => void;
}) {
  const { toast } = useToast();
  const { create, confirm, isMutating } = useDeliveryMethods({ enabled: false, vendorId: vendor?.id });

  const bankAccount = vendor?.deliveryMethods.find((method) => method.type === 'bank-account') as
    | BankAccountDeliveryMethod
    | undefined;

  const { data: bankAccountUnmaskedAccountNumber } = useDeliveryMethodUnmaskedAccountNumber({
    deliveryMethodId: bankAccount?.id || '',
  });

  const unmaskedAccountNumber = bankAccountUnmaskedAccountNumber?.accountNumber;

  const confirmationModal = useConfirmationModal({
    deliveryMethod: bankAccount,
  });

  const bankModalForm = useMelioForm<BankAccountFormModel>({
    schema: useAchValidationSchema(),
    onSubmit: async (data) => {
      try {
        if (
          !bankAccount ||
          unmaskedAccountNumber !== data.accountNumber ||
          bankAccount.details.routingNumber !== data.routingNumber
        ) {
          const newBankAccount = await create({
            type: 'bank-account',
            details: { ...data, accountType: 'checking' },
            requireConfirmationForPayment: false,
          });
          onDone(newBankAccount as BankAccountDeliveryMethod);
        } else {
          await confirm(bankAccount.id);
          onDone(bankAccount);
        }

        confirmationModal.close();
      } catch (error) {
        toast({ type: 'error', title: (error as PlatformError)?.message });
      }
    },
    defaultValues: bankAccount?.details ?? {
      accountNumber: '',
      routingNumber: '',
    },
    subscribeToDefaultValuesChanges: true,
  });

  return {
    ...confirmationModal,
    ...bankModalForm,
    vendor,
    isSaving: isMutating,
    routingNumber: bankAccount?.details.routingNumber,
    unmaskedAccountNumber,
  };
}
