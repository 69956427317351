import React, { PropsWithChildren, useContext } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { Button, Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { usePartnerConfig } from '@/hooks/partners';
import { SettingsContext } from '@/screens/settings/SettingsProvider';
import { usePlatformIntl } from '@/translations/Intl';
import { SectionHeaderWithButton } from '@/widgets/settings-page/Common/SectionHeaderWithButton.widget';

export const PaymentsReportSection = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { track } = useAnalytics();
  const { openCreateReportModal } = useContext(SettingsContext);

  const onClickCreateReport = () => {
    openCreateReportModal();
    track('Settings', 'Click', {
      PageName: SettingsCardIdEnum.CompanySettings,
      Cta: 'create-report',
    });
  };

  const title = formatMessage('widgets.companySettings.paymentsReportSection.title');
  const content = formatMessage('widgets.companySettings.paymentsReportSection.content', {
    helpLink: (
      <Link
        variant="inline"
        href={partnerConfig.config.links.helpCenter}
        label={formatMessage('widgets.companySettings.paymentsReportSection.linkText')}
        newTab
      />
    ),
  });
  const btnText = formatMessage('widgets.companySettings.paymentsReportSection.btnText');

  return <ReportWidget title={title} buttonText={btnText} onClick={onClickCreateReport} content={content} />;
};

export type CompanyReportProps = PropsWithChildren<{
  title: string;
  content: React.ReactNode;
  buttonText: string;
  onClick: VoidFunction;
}>;
const ReportWidget: React.FC<CompanyReportProps> = (props: CompanyReportProps) => {
  const { title, content, buttonText, onClick, children } = props;
  return (
    <Stack flexDirection="column" gridGap={'24px'} spacing={0}>
      <SectionHeaderWithButton title={title} titleAs="h3" />
      <Box>
        <Box mb={6}>
          <Text>{content}</Text>
        </Box>
        <Button variant="tertiary" onClick={onClick} label={buttonText} data-testid="create-report-btn" />
        {children}
      </Box>
    </Stack>
  );
};
