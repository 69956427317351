import { getIsVoidAndRefundEnabled } from '@melio/ap-domain';
import { Badge, Table } from '@melio/penny';
import { DeliveryMethodType, Payment, PaymentApprovalDecisionStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import {
  isPaymentCompletedRefund,
  isPaymentCompletedVoidAndRefund,
  isPaymentScheduledRefund,
  isPaymentScheduledVoid,
  isPaymentScheduledVoidAndRefund,
} from '../../../../../utils/pay-flow/Payment.utils';

export const PaymentsTabStatusCell = ({ payment }: { payment: Payment }) => {
  const calculatePaymentStatus = usePaymentStatus();
  const { label, status } = calculatePaymentStatus(payment);
  return (
    <Table.Cell data-testid={`payments-tab-row-${payment.id}-status-cell`}>
      <Badge type="secondary" label={label} status={status} />
    </Table.Cell>
  );
};

export const usePaymentStatus = () => {
  const { formatMessage } = useMelioIntl();
  return useCallback(
    (payment: Payment) => {
      let label;
      let status;
      const isVoidAndRefundEnabled: boolean = getIsVoidAndRefundEnabled(payment);

      switch (payment.status) {
        case 'completed':
          if (payment.markedAsPaid) {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.markedAsPaid');
            status = 'success' as const;
          } else {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.paid');
            status = isVoidAndRefundEnabled ? ('warning' as const) : ('success' as const);
          }
          break;
        case 'in-progress':
          {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.inProgress');
            status = 'success' as const;
          }
          break;
        case 'scheduled':
          if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualAccount) {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.pendingVendor');
            status = 'warning' as const;
          } else {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.scheduled');
            status = 'informative' as const;
          }
          break;
        case 'blocked':
          if (payment.approvalDecisionStatus === 'pending') {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.approvalPending');
            status = 'warning' as const;
          } else if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualAccount) {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.pendingVendor');
            status = 'warning' as const;
          } else {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.scheduled');
            status = 'informative' as const;
          }
          break;
        case 'failed':
          {
            const isProcessingVoid = isPaymentScheduledVoid(payment);
            const isProcessingRefund = isPaymentScheduledRefund(payment);
            const isProcessingVoidAndRefund = isPaymentScheduledVoidAndRefund(payment);
            const isCompletedRefund = isPaymentCompletedRefund(payment);
            const isCompletedVoidAndRefund = isPaymentCompletedVoidAndRefund(payment);

            if (payment.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Declined) {
              label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.approvalDeclined');
              status = 'critical' as const;
            } else if (isProcessingVoid) {
              label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.voidInProgress');
              status = 'neutral' as const;
            } else if (isProcessingRefund || isProcessingVoidAndRefund) {
              label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.refundInProgress');
              status = 'neutral' as const;
            } else if (isCompletedRefund || isCompletedVoidAndRefund) {
              label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.refunded');
              status = 'neutral' as const;
            } else {
              label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.failed');
              status = 'critical' as const;
            }
          }
          break;
        case 'canceled':
          {
            label = formatMessage('activities.payDashboard.paymentsTab.table.cells.status.canceled');
            status = 'critical' as const;
          }
          break;
        default: {
          label = 'unknown'; //TODO:  should remove while we add all statuses
          status = 'critical' as const;
        }
      }

      return { label, status };
    },
    [formatMessage]
  );
};
