import { Modal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  onReplace: () => void;
  onCancel: () => void;
};

export const ReplaceExistingDetailsModal = ({ isOpen = false, onClose, onReplace, onCancel }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const defaultAnalyticsFields = {
    PageName: 'replace-existing-details',
    Intent: 'replace-invoice',
  };

  useEffect(() => {
    if (isOpen) {
      track('Bill', 'View', defaultAnalyticsFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, isOpen]);

  const onConfirm = () => {
    track('Bill', 'Click', {
      Cta: 'replace-details',
      ...defaultAnalyticsFields,
    });
    onReplace();
  };

  const onExit = () => {
    track('Bill', 'Click', {
      Cta: 'exit',
      ...defaultAnalyticsFields,
    });
    onClose();
  };

  const onUploadWithoutReplacing = () => {
    track('Bill', 'Click', {
      Cta: 'upload-without-replace',
      ...defaultAnalyticsFields,
    });
    onCancel();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onExit}
      header={formatMessage('activities.addBillV2.billForm.replaceExistingDetails.header')}
      description={formatMessage('activities.addBillV2.billForm.replaceExistingDetails.description')}
      primaryButton={{
        label: formatMessage('activities.addBillV2.billForm.replaceExistingDetails.primaryButton.label'),
        variant: 'primary',
        onClick: onConfirm,
      }}
      secondaryButton={{
        label: formatMessage('activities.addBillV2.billForm.replaceExistingDetails.secondaryButton.label'),
        variant: 'tertiary',
        onClick: onUploadWithoutReplacing,
      }}
      data-testid="add-bill-v2-replace-existing-details-modal"
    />
  );
};
