import { isCollaboratorBlocked } from '@melio/ap-domain';
import { Container, Group, SectionBanner, Switch, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PermissionLevelEnum, useCollaborators } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useIsSubscriptionsEnabled, useSubscriptionFeature } from '@melio/subscriptions';

import { useFormContext } from '../../../FormContext';

export const PermissionLevelSection = () => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { watch, setValue, formState } = useFormContext();
  const { track } = useAnalytics();

  const { data: collaborators } = useCollaborators({});

  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const collaboratorsInSubscriptionPlan = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];
  const { quota } = useSubscriptionFeature({
    featureName: 'collaborators',
    requirements: { totalUnits: (collaborators ?? []).filter((c) => !isCollaboratorBlocked(c)).length + 1 },
  });

  const permissionLevel = watch('permissionLevel');

  const handleChange = (value: boolean) => {
    const permissionLevelIntention = value ? PermissionLevelEnum.Full : PermissionLevelEnum.Blocked;

    track('User', 'Click', {
      Intent: 'manage-user-activation',
      Cta: value ? 'on' : 'off',
      AlertShown: isSubscriptionBannerShown(permissionLevelIntention) ? 'additional-user-surcharge' : null,
    });
    setValue('permissionLevel', permissionLevelIntention);
  };

  const isSubscriptionBannerShown = (permissionLevelIntention: PermissionLevelEnum) =>
    isSubscriptionsEnabled &&
    quota.excessUnitFee &&
    collaboratorsInSubscriptionPlan.length >= quota.freeUnitsLimit &&
    permissionLevelIntention === PermissionLevelEnum.Full &&
    formState.defaultValues?.permissionLevel === PermissionLevelEnum.Blocked;

  return (
    <Container overflow="initial">
      <Group variant="vertical" spacing="s">
        <Group variant="vertical" spacing="xxs">
          <Group justifyContent="space-between" alignItems="center">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.heading')}
            </Text>

            <Group alignItems="center">
              <Switch
                data-testid="collaborator-drawer-body-permission-level-switch"
                onChange={handleChange}
                value={permissionLevel === PermissionLevelEnum.Full}
                label={
                  permissionLevel === PermissionLevelEnum.Full
                    ? formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.switch.on')
                    : formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.switch.off')
                }
                aria-describedby="permission-level-switch-description"
              />
            </Group>
          </Group>

          <Text textStyle="body4" color="neutral.darkest" id="permission-level-switch-description">
            {formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.description')}
          </Text>
        </Group>

        {isSubscriptionBannerShown(permissionLevel) ? (
          <SectionBanner
            description={
              <Text textStyle="body3" color="neutral.black">
                {formatMessage(
                  'activities.collaboratorDrawer.body.permissionLevelSection.subscription.planWithExcessFee.exceedsMaximumFreeSeats',
                  {
                    usedSeatsCount: collaboratorsInSubscriptionPlan.length,
                    freeSeatsCount: quota.freeUnitsLimit,
                    excessFee: formatCurrency(quota.excessUnitFee ?? 0),
                  }
                )}
              </Text>
            }
            variant="neutral"
            size="small"
            icon="info"
            data-testid="collaborator-drawer-body-permission-level-subscription-banner"
          />
        ) : null}
      </Group>
    </Container>
  );
};
