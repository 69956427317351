import { useMelioQueryClient, withLocalization } from '@melio/ar-domain';
import { GuestPaymentWithAuthFlow } from '@melio/ar-guest-payment';
import { PartnerName } from '@melio/platform-api';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

type ResetAccessTokenFunction = (accessToken?: string, refreshToken?: string | null) => void;

type GuestPaymentRouterProps = {
  WithAnonymousInitialData: React.ComponentType;
  useResetAccessToken: () => ResetAccessTokenFunction;
  partnerName: PartnerName;
};

export const GuestPaymentRouter = withLocalization(
  ({ partnerName, useResetAccessToken, WithAnonymousInitialData }: GuestPaymentRouterProps) => {
    const queryClient = useMelioQueryClient();
    const resetAccessToken = useResetAccessToken();
    const onLoggedIn = (...args: Parameters<ResetAccessTokenFunction>) => {
      resetAccessToken(...args);
      void queryClient.invalidateQueries('AccountsApi');
      return new Promise<void>((resolve) => setTimeout(resolve, 1));
    };

    return (
      <Routes>
        <Route element={<WithAnonymousInitialData />}>
          <Route path="*" element={<GuestPaymentWithAuthFlow partnerName={partnerName} onLoggedIn={onLoggedIn} />} />
        </Route>
      </Routes>
    );
  }
);
GuestPaymentRouter.displayName = 'GuestPaymentRouter';
