import { Box } from '@chakra-ui/react';
import { AvatarWithDetails, Checkbox, Group, Modal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useEffect } from 'react';

import { useSelectVendorRecommendations } from '../hooks/useSelectRecommendedVendors';
import { RankedVendor, recommendationToAnalyticsEvents } from '../utils';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  recommendedVendors: RankedVendor[];
};

export const RecommendedVendorsModal = (props: Props) => {
  const { selectVendor, selectedVendors, isVendorSelected, saveVendors, isSavingVendors } =
    useSelectVendorRecommendations(props.recommendedVendors);

  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { settings } = useConfig();

  useEffect(() => {
    if (props.isOpen) {
      track('Vendor', 'View', {
        Intent: 'add-vendors',
        PageName: 'suggested-vendors',
        SuggestedVendors: recommendationToAnalyticsEvents(props.recommendedVendors),
      });
    }
  }, [props.isOpen, track, props.recommendedVendors]);

  const onSubmit = async () => {
    track('Vendor', 'Click', {
      Cta: 'add-vendors',
      PageName: 'suggested-vendors',
      SuggestedVendorsAdded: recommendationToAnalyticsEvents(selectedVendors),
    });
    await saveVendors();
    props.onClose();
  };

  return (
    <Modal
      header={formatMessage(`activities.recommendedVendors.modal.header`)}
      description={formatMessage(`activities.recommendedVendors.modal.description`)}
      isOpen={props.isOpen}
      onClose={props.onClose}
      primaryButton={{
        onClick: onSubmit,
        isDisabled: !selectedVendors.length,
        variant: 'primary',
        label: formatMessage(`activities.recommendedVendors.modal.primaryButton`),
        isLoading: isSavingVendors,
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage(`activities.recommendedVendors.modal.secondaryButton`),
        onClick: props.onClose,
      }}
    >
      <Box paddingBottom="xs-s">
        <Group height="full" width="full" spacing="m" variant="vertical">
          {props.recommendedVendors.map((v) => {
            const badge =
              v.type === 'business' && settings.showCheckMarkIconForManagedVendor ? { badge: 'verified' as const } : {};

            return (
              <Group spacing="l" alignItems="center" justifyContent="space-between" key={v.id} variant="horizontal">
                <Box overflow="hidden">
                  <AvatarWithDetails
                    description={formatAddress(v.address)}
                    avatarProps={{
                      name: v.name,
                      ...badge,
                    }}
                    mainLabelProps={{
                      label: v.name,
                    }}
                  />
                </Box>
                <Checkbox value={Boolean(isVendorSelected(v.id))} onChange={() => selectVendor(v.id)} />
              </Group>
            );
          })}
        </Group>
      </Box>
    </Modal>
  );
};
