import { useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import { NotificationsApiClient } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';
import { Notification, NotificationsNames, PatchNotificationPreferencesByNameRequest } from './types';
import { patchNotificationPreferencesByNotificationName } from './utils';

export type UseNotificationsProps = UseCollectionProps<typeof NotificationsApiClient.getNotificationsPreferences> & {
  onUpdateError?: ARErrorFunction;
};

export const useNotifications = ({ onUpdateError, ...props }: UseNotificationsProps = {}) => {
  const queryClient = useMelioQueryClient();
  const query = useCollection({
    ...props,
    queryKey: 'NotificationsApi',
    queryFn: NotificationsApiClient.getNotificationsPreferences,
    populateModels: true,
  });

  type UpdateFn = typeof patchNotificationPreferencesByNotificationName;

  const updateMutation = useNonEntityMutation<UpdateFn, Parameters<UpdateFn>[0], Notification, ARPlatformError>(
    patchNotificationPreferencesByNotificationName,
    query.queryKey,
    {
      onSuccess: () => queryClient.invalidateQueries('NotificationsApi'),
      onError: onUpdateError,
    }
  );

  return {
    ...query,
    update: (id: NotificationsNames, data: PatchNotificationPreferencesByNameRequest) =>
      updateMutation.mutateAsync({ id, data }),
  };
};

export type NotificationsCollection = ReturnType<typeof useNotifications>;
