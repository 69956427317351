import {
  useAccount,
  useGuestPayorOnboarding,
  useGuestPayorPayment,
  useGuestPayorPaymentRequestDetails,
  ValidationUtils,
} from '@melio/ar-domain';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useRef, useState } from 'react';

import { SignInBanner } from '../../components';
import { AuthenticationModalActivity } from '../authentication-modal';
import { CardHolderDetailsFormFields } from '../types';
import { useAddCardFundingSource } from './hooks';
import { AddCardFormScreen } from './screens';

type AddCardFundingSourceActivityProps = {
  onCreatePayment: VoidFunction;
  partnerName: PartnerName;
  paymentRequestLink: string;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onError: ARErrorFunction;
  onFormLoading: (isLoading: boolean) => void;
  redirectToPayDashboardUrl?: string;
};

export const AddCardFundingSourceActivity = forwardRef<AddCardFundingSourceActivityProps>(
  (
    { onError, onFormLoading, onLoggedIn, partnerName, paymentRequestLink, onCreatePayment, redirectToPayDashboardUrl },
    ref
  ) => {
    const { data: paymentRequestDetails, isLoading: isLoadingPaymentRequestLink } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
    });
    const { createPayment, isLoading: isCreatingPayment } = useGuestPayorPayment();
    // TODO: userEmail temporarily unused, we should pass it to the API hook once ready
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userEmail, setUserEmail] = useState<string>();
    const { toast } = useToast();
    const [formValues, setFormValues] = useState<CardHolderDetailsFormFields>();
    const [isOTPModalOpen, otpModalOpen] = useBoolean(false);
    const { onboarding, isLoading: isLoadingOnBoarding } = useGuestPayorOnboarding();
    const { data: account, error: fetchAccountError } = useAccount({ id: 'me' });
    const prevFormValuesRef = useRef<CardHolderDetailsFormFields>();

    const { isCreating, addCard } = useAddCardFundingSource({
      onAddCardSuccess: async (fundingSourceId: string) => {
        try {
          await createPayment({ fundingSourceId, paymentRequestLink });
          onCreatePayment();
        } catch (error) {
          onError(error as ARPlatformError);
        }
      },
    });
    const { track } = useAnalytics();

    const onEmailEntered = (email: string) => {
      if (ValidationUtils.isValidEmail(email)) {
        setUserEmail(email);
      }
    };

    useEffect(() => {
      const prevFormValues = prevFormValuesRef.current;

      if (formValues && formValues !== prevFormValues) {
        prevFormValuesRef.current = formValues;
        const isAuthenticated = !fetchAccountError && account && account.user.email === formValues.email;
        isAuthenticated ? void handleAuthenticationDone() : otpModalOpen.on();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues, account]);
    const isSaving = isLoadingOnBoarding || isLoadingPaymentRequestLink || isCreating || isCreatingPayment;

    useEffect(() => {
      onFormLoading(isSaving);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaving]);

    const handleOnSubmit = (values: CardHolderDetailsFormFields) => {
      track('PaymentRequest', 'Click', {
        PaymentMethodType: 'card',
        Cta: 'pay',
      });
      setFormValues(values);
    };

    const handleAuthenticationDone = async () => {
      try {
        otpModalOpen.off();
        await onboarding({ paymentRequestLink });
        await addCard(formValues as CardHolderDetailsFormFields);
      } catch (error) {
        toast({ type: 'error', title: (error as ARPlatformError).message });
        onError(error as ARPlatformError);
      }
    };

    // TODO: remove the eslint-disable once we set shouldDisplaySignInBanner value according to the API response
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const shouldDisplaySignInBanner = !!redirectToPayDashboardUrl && false;

    const onSignInClick = () => {
      window.location.href = redirectToPayDashboardUrl as string;
    };

    return (
      <>
        <AddCardFormScreen
          onSubmit={handleOnSubmit}
          isSaving={isSaving}
          amount={paymentRequestDetails?.invoice.totalAmount || 0}
          ref={ref}
          onEmailFieldBlur={onEmailEntered}
          emailBanner={
            // TODO: remove the eslint-disable once we set shouldDisplaySignInBanner value according to the API response
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            shouldDisplaySignInBanner ? <SignInBanner onSignInClick={onSignInClick} /> : null
          }
        />
        <AuthenticationModalActivity
          partnerName={partnerName}
          email={formValues?.email || ''}
          onLoggedIn={onLoggedIn}
          onClose={() => otpModalOpen.off()}
          isOpen={isOTPModalOpen}
          onDone={handleAuthenticationDone}
        />
      </>
    );
  }
);
AddCardFundingSourceActivity.displayName = 'AddCardFundingSourceActivity';
