import { useState } from 'react';
import { Container, Group, Loader, Text, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  TaxpayerIdentificationRequestStatusEnum,
  useTaxPayerInfo,
  useTaxPayerInfoDetailsRequest,
  useVendor,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useDisclosure } from '@/hooks/useDisclosure';
import { TaxPayerInfoCheckbox } from './components/TaxPayerInfoCheckbox.component';
import { TaxPayerRequestStatus } from './components/TaxPayerRequestStatus.component';
import { CancelRequestVendorTaxDetailsModal } from './CancelRequestVendoTaxDetailsModal.widget';
import { RequestVendorTaxDetailsModal } from './RequestVendorTaxDetailsModal.widget';
import { TaxPayerDeleteAttachmentModal } from './TaxPayerDeleteAttachmentModal.widget';
import { TaxPayerInfoForm } from './TaxPayerInfoForm.widget';

type TaxPayerInfoCardProps = {
  vendor: Vendor;
  isVendorLoading: boolean;
};

export const TaxPayerInfoCard = ({ vendor, isVendorLoading }: TaxPayerInfoCardProps) => {
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { track } = useAnalytics();

  const { isMutating: isVendorMutating, update: updateVendor } = useVendor({ id: vendor.id });
  const taxPayerIdentificationRequestStatus = vendor?.lastTaxpayerIdentificationRequest?.status;
  const isPendingTaxPayerRequestStatus =
    taxPayerIdentificationRequestStatus === TaxpayerIdentificationRequestStatusEnum.Pending;
  const [formVisibility, setFormVisibility] = useState<'hidden' | 'visible'>('hidden');
  const {
    resend: resendTaxPayerInfoRequest,
    create: createTaxPayerInfoRequest,
    isMutating: isTaxPayerInfoRequestMutating,
  } = useTaxPayerInfoDetailsRequest({
    vendorId: vendor.id,
  });

  const {
    create: uploadW9File,
    isLoading: isLoadingTaxPayerInfo,
    data: taxpayerInfo,
    isMutating: isTaxPayerInfoMutating,
  } = useTaxPayerInfo({
    vendorId: vendor.id,
    taxpayerInfoId: vendor.taxpayerInfo?.id!,
    enabled: !!vendor.taxpayerInfo?.id && !isVendorLoading,
  });

  const showTaxPayerRequestStatusForm = vendor.contractor && isPendingTaxPayerRequestStatus;
  const isLoadingState = isLoadingTaxPayerInfo || isVendorMutating || isVendorLoading;

  const onSendReminderRequest = async () => {
    try {
      await resendTaxPayerInfoRequest(vendor.lastTaxpayerIdentificationRequest?.id as string);
      setFormVisibility('hidden');
      toast({
        type: 'success',
        title: formatMessage('widgets.taxPayerInfo.statusForm.reminder.toast.success'),
        id: 'vendor-tax-details-request-reminder-success',
      });
      track('Vendor', 'Status', { Intent: 'send-w9-reminder', TaxId: 'request-sent', Status: 'success' });
    } catch (e) {
      toast({
        type: 'error',
        title: formatMessage('widgets.taxPayerInfo.vendorUpdate.fail.toast'),
        id: 'vendor-tax-details-request-reminder-fail',
      });
      track('Vendor', 'Status', { Intent: 'send-w9-reminder', TaxId: 'request-sent', Status: 'fail' });
    }
  };

  const showSendReminderForm = () => {
    setFormVisibility('visible');
  };

  const {
    isOpen: isTaxRequestDetailsModalOpen,
    onOpen: openTaxRequestDetailsModal,
    onClose: closeTaxRequestDetailsModal,
  } = useDisclosure();

  const {
    isOpen: isTaxRequestCancelModalOpen,
    onOpen: openTaxRequestCancelModal,
    onClose: closeTaxRequestCancelModal,
  } = useDisclosure();

  const {
    isOpen: isTaxPayerDeleteAttachmentModalOpen,
    onOpen: openTaxPayerDeleteAttachmentModal,
    onClose: closeTaxPayerDeleteAttachmentModal,
  } = useDisclosure();

  const onContractorUpdate = async () => {
    try {
      await updateVendor({ contractor: !vendor.contractor });
    } catch (e) {
      toast({
        type: 'error',
        title: formatMessage('widgets.taxPayerInfo.vendorUpdate.fail.toast'),
      });
    }
  };

  const onRequestTaxPayerInfoDetails = async () => {
    track('Vendor', 'Click', {
      Intent: 'send-w9-request',
      TaxId: 'email',
      Cta: 'send-w9-request',
    });
    if (vendor?.contact.email) {
      try {
        await createTaxPayerInfoRequest({ email: vendor.contact.email });
        toast({
          type: 'success',
          title: formatMessage('widgets.taxPayerInfo.modal.toast.success'),
          id: 'vendor-tax-details-request-success',
        });
        track('Vendor', 'Status', { Intent: 'send-w9-request', TaxId: 'email', Status: 'success' });
      } catch (e) {
        toast({
          type: 'error',
          title: formatMessage('widgets.taxPayerInfo.modal.toast.error'),
          id: 'vendor-tax-details-request-error',
        });
        track('Vendor', 'Status', { Intent: 'send-w9-request', TaxId: 'email', Status: 'fail' });
      }
    } else {
      openTaxRequestDetailsModal();
    }
  };

  const renderContent = () => {
    if (isLoadingState) {
      return <Loader />;
    }

    if (!vendor.contractor) return null;

    if (showTaxPayerRequestStatusForm) {
      return (
        <TaxPayerRequestStatus
          onShowForm={showSendReminderForm}
          onCancelRequest={openTaxRequestCancelModal}
          onSendReminder={onSendReminderRequest}
          vendor={vendor}
          isMutating={isTaxPayerInfoMutating || isVendorMutating || isTaxPayerInfoRequestMutating}
          formVisibility={formVisibility}
        />
      );
    }

    return (
      <TaxPayerInfoForm
        handleFileDelete={openTaxPayerDeleteAttachmentModal}
        handleFileUpload={uploadW9File}
        taxPayerInfo={taxpayerInfo}
        isLoading={isTaxPayerInfoRequestMutating || isTaxPayerInfoMutating || isLoadingTaxPayerInfo}
        handleSubmitRequest={onRequestTaxPayerInfoDetails}
      />
    );
  };

  return (
    <Container overflow="initial" data-testid="taxpayer-info-card">
      <Group variant="vertical" spacing="m">
        <Group justifyContent="space-between">
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('widgets.taxPayerInfo.title')}
          </Text>
        </Group>

        <Group spacing="m" hasDivider variant="vertical">
          {isVendorMutating ? (
            <Loader />
          ) : (
            <TaxPayerInfoCheckbox
              isUpdating={isVendorMutating}
              onChange={onContractorUpdate}
              isContractor={vendor.contractor}
            />
          )}
          {renderContent()}
        </Group>
        <RequestVendorTaxDetailsModal
          isOpen={isTaxRequestDetailsModalOpen}
          onClose={closeTaxRequestDetailsModal}
          vendor={vendor}
        />
        <CancelRequestVendorTaxDetailsModal
          isOpen={isTaxRequestCancelModalOpen}
          onClose={closeTaxRequestCancelModal}
          vendor={vendor}
        />
        <TaxPayerDeleteAttachmentModal
          isOpen={isTaxPayerDeleteAttachmentModalOpen}
          onClose={closeTaxPayerDeleteAttachmentModal}
          taxpayerInfo={taxpayerInfo}
        />
      </Group>
    </Container>
  );
};

TaxPayerInfoCard.displayName = 'TaxPayerInfoCard';
