import React from 'react';
import { Box } from '@chakra-ui/react';
import { uniq } from 'lodash';
import { useRecoilValue } from 'recoil';
import { Button, Currency } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useInboxItems } from '@melio/platform-api';

import { Footer } from '@/cl/components/Footer/Footer.component';
import { useIsInternationalSupportForBusiness } from '@/hooks/vendorInternational.hooks';
import { payDashboardItemSelectionsSelector } from '@/store/PayDashboard/PayDashboards.model';
import { FormattedMessage, usePlatformIntl } from '@/translations/Intl';
import { useRouter } from '../../../hooks/router.hooks';

const useInboxItemsSelection = () => {
  const selectedItemIds = useRecoilValue(payDashboardItemSelectionsSelector);
  const { isInternationalSupportedForBusiness } = useIsInternationalSupportForBusiness();
  const { data: inboxItemsData } = useInboxItems({});

  const selectedInboxItems =
    inboxItemsData?.data.filter((inboxItem) => selectedItemIds.includes(inboxItem.payload.id)) || [];

  const totalAmount = selectedInboxItems.reduce((totalAmount, inboxItem) => {
    if (inboxItem.type === 'paymentRequest') {
      return totalAmount + inboxItem.payload.totalAmount;
    }
    if (inboxItem.type === 'bill') {
      return totalAmount + inboxItem.payload.amount;
    }
    return totalAmount;
  }, 0);

  const withInternationalVendors = selectedInboxItems.some((inboxItem) => {
    if (inboxItem.type === 'paymentRequest' || inboxItem.type === 'bill') {
      return inboxItem.payload.vendor?.businessGeography === 'international';
    }
    return false;
  });
  const numberOfVendors = uniq(
    selectedInboxItems.reduce<string[]>((vendorIds, inboxItem) => {
      if (inboxItem.type === 'paymentRequest') {
        return [...vendorIds, inboxItem.payload.vendorId];
      }
      if (inboxItem.type === 'bill') {
        return [...vendorIds, inboxItem.payload.vendorId];
      }
      return vendorIds;
    }, []),
  ).length;

  return {
    selectedItemIds,
    totalAmount,
    numberOfVendors,
    shouldDisableBatchScheduleButton: !isInternationalSupportedForBusiness && withInternationalVendors,
  };
};

export const ActionsFooter = () => {
  const {
    selectedItemIds,
    totalAmount = 0,
    numberOfVendors,
    shouldDisableBatchScheduleButton,
  } = useInboxItemsSelection();
  const { formatMessage } = usePlatformIntl();

  const { navigateToBatchPayment } = useRouter();

  const { track } = useAnalytics();

  const handleGoToBatchPayments = () => {
    track('ScheduleAPayment', 'Chose', {
      IsBatch: true,
      NumberOfBills: selectedItemIds.length,
      NumberOfVendors: numberOfVendors,
      TPV: totalAmount,
    });
    navigateToBatchPayment({ billIds: selectedItemIds });
  };

  return (
    <Footer
      data-testid="pay-dashboard-footer"
      left={
        <>
          <Box textStyle="body4Semi" color="gray.600" textTransform="uppercase" lineHeight="25px">
            <FormattedMessage id="app.payDashboard.actionsFooter.totalAmount" />
          </Box>
          <Box mb={1} data-testid="pay-dashboard-footer-amount">
            <Currency amount={totalAmount} />
          </Box>
        </>
      }
      right={
        <Button
          variant="primary"
          size="large"
          onClick={handleGoToBatchPayments}
          isFullWidth
          isDisabled={shouldDisableBatchScheduleButton}
          data-testid="batch-schedule-btn"
          label={formatMessage('app.payDashboard.actionsFooter.continueToScheduling', {
            count: selectedItemIds.length,
          })}
        />
      }
    />
  );
};
