import { AddBillFormWidgetFields } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Bill, PaymentIntent, useAccountingPlatforms, useBills, useFiles, useVendor } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { useState } from 'react';

import { CreateBillFormTarget, isSinglePaymentFields, useTrackBillCreation } from '../bill-utils';
import { AddManualBillScreen } from './screens';

export type AddManualBillActivityProps = {
  defaultValues?: Partial<AddBillFormWidgetFields>;
  onDone: (data: AddBillFormWidgetFields, id?: PaymentIntent['id'] | Bill['id'], target?: CreateBillFormTarget) => void;
  onClose: VoidFunction;
  onBack: VoidFunction;
  onError?: ErrorFunction;
  isInternationalSupportedForRecurringFlow?: boolean;
};

export const AddManualBillActivity = withAnalyticsContext<AddManualBillActivityProps>(
  ({ onBack, onClose, onDone, onError, isInternationalSupportedForRecurringFlow, defaultValues }) => {
    // We're using this and not `isCreatingPaymentIntent || isCreatingFile` because there's a gap of loading
    // just right after the isCreatingFile is done and before the isCreatingPaymentIntent begins.
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const { create: createBill } = useBills({ enabled: false });
    const { create: createFile } = useFiles({ enabled: false });
    const { toast } = useToast();

    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message || 'Failed to create the bill' });
      onError?.(error);
    };

    const { createDate } = useDateUtils();
    const { setTraits, track } = useAnalytics();
    const { trackBillCreation } = useTrackBillCreation();
    const setBillTraits = () => {
      const traits: Traits = {
        create_a_bill: true,
        last_bill_added_date: createDate().toISOString(),
      };

      setTraits(traits);
    };

    const handleDone = async (data: AddBillFormWidgetFields, target?: CreateBillFormTarget) => {
      try {
        let uploadedInvoice;

        setIsSaving(true);

        if (data.invoice) {
          const analyticsProps = {
            PageName: 'bill-details',
            Intent: 'invoice-upload-started',
            Status: 'Success',
          };

          try {
            track('Bill', 'Status', analyticsProps);
            uploadedInvoice = await createFile(data.invoice);
            track('Bill', 'Status', {
              ...analyticsProps,
              Intent: 'invoice-upload-ended',
            });
          } catch (error) {
            track('Bill', 'Status', {
              ...analyticsProps,
              Intent: 'invoice-upload-ended',
              Status: 'Failure',
            });
            throw error;
          }
        }

        if (isSinglePaymentFields(data)) {
          const billCreationPayload = {
            amount: parseFloat(data.billAmount),
            dueDate: data.dueDate?.toISOString(),
            vendorId: data.vendorId,
            invoice: {
              number: data.billNumber,
              fileId: uploadedInvoice?.id,
            },
            note: data.note,
            categoryId: data.categoryId,
          };

          const createdBill = await createBill(billCreationPayload);
          trackBillCreation(createdBill, target);
          setBillTraits();
          onDone(data, createdBill.id, target);
        } else {
          onDone(data, undefined, target);
        }
      } catch (error) {
        handleFail(error as PlatformError);
      } finally {
        setIsSaving(false);
      }
    };

    const handleClose = () => {
      track('Bill', 'Click', {
        PageName: 'bill-details',
        Cta: 'exit',
      });
      onClose();
    };

    const { isFetching, activeAccountingPlatform } = useAccountingPlatforms();
    const { isFetching: isDefaultVendorFetching } = useVendor({
      id: defaultValues?.vendorId,
      enabled: !!defaultValues?.vendorId,
    });
    return (
      <AddManualBillScreen
        isLoading={isFetching || isDefaultVendorFetching}
        defaultValues={defaultValues}
        activeAccountingPlatform={activeAccountingPlatform}
        isInternationalSupportedForRecurringFlow={isInternationalSupportedForRecurringFlow}
        isSaving={isSaving}
        onBack={onBack}
        onClose={handleClose}
        onDone={(data, _event, target) => handleDone(data, target)}
      />
    );
  }
);

AddManualBillActivity.displayName = 'AddManualBillActivity';
