import { compact } from 'lodash';
import { useRecoilValue } from 'recoil';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import {
  BusinessBankingIcon,
  CompaniesIcon,
  GetPaidIcon,
  SettingsIcon,
  VendorIcon,
  VendorNewIcon,
} from '@/cl/icons/system';
import { usePreferences } from '@/hooks/preferences.hooks';
import { appSelectedActiveScreenSelector } from '@/store/app/app.model';
import { ScreensEnum } from '@/store/app/app.types';
import { usePlatformIntl } from '@/translations/Intl';
import { LeftLayoutIconLink } from '../cl/layouts/app-layout/leftLayout.component';
import { useRouter } from './router.hooks';
import { useAccountingFirmTabLinks } from './useAccountingFirmTabLinks.hooks';
import { useExpensesTabLinks } from './useExpensesTabLinks';
import { useGetPaidTabLinks } from './useGetPaidTabLinks';
import { useHasAccessToFirm } from './useHasAccessToFirm.hooks';
import { useIsMobile } from './useIsMobile';
import { useIsNewDashboardEnabled } from './useIsNewDashboardEnabled';
import { useShouldShowCompaniesLink } from './useShouldShowCompaniesLink';

export const useNavigationTabLinks = () => {
  const activeScreen = useRecoilValue(appSelectedActiveScreenSelector);
  const { orgPreferences } = usePreferences();
  const [splitContactsTab] = useFeature(FeatureFlags.SplitContactsTab, true);
  const [arDashboardEnabled] = useFeature(FeatureFlags.ARDashboard, false);
  const isArEnabled = arDashboardEnabled || orgPreferences?.isArEnabled === 'true';
  const [isCapOnePortalEnabled] = useFeature(FeatureFlags.isCapOnePortalEnabled, false);
  const { formatMessage } = usePlatformIntl();
  const { shouldShowCompaniesLink } = useShouldShowCompaniesLink();
  const hasAccessToFirm = useHasAccessToFirm();
  const isMobile = useIsMobile();
  const {
    generateSettingsProfileTabLink,
    generateSettingsTabLink,
    generatePayTabLink,
    generateVendorsTabLink,
    generateAccountsDashboardLink,
    generateBackToPartnerUrl,
    generateARTabLink,
  } = useRouter();
  const { links: getExpensesTabLinks } = useExpensesTabLinks();
  const { links: getPaidTabLinksList } = useGetPaidTabLinks();
  const accountingFirmTabLinks = useAccountingFirmTabLinks();

  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();
  // If the new Get Paid (AR) is enabled, we should not show the old Get Paid links
  const getPaidTabLinks = isArEnabled ? [] : getPaidTabLinksList;

  const payTabLinks: LeftLayoutIconLink[] = compact([
    {
      id: 'Pay',
      Icon: BusinessBankingIcon,
      href: isCapOnePortalEnabled ? generateBackToPartnerUrl() : generatePayTabLink(),
      linkText: formatMessage('app.navigation.pay'),
      isActive: activeScreen === ScreensEnum.payDashboard,
    },
    !shouldRedirectToNewDashboard && {
      id: 'Vendors',
      Icon: splitContactsTab ? VendorNewIcon : VendorIcon,
      href: generateVendorsTabLink(),
      linkText: formatMessage('app.navigation.vendors'),
      isActive: activeScreen === ScreensEnum.vendors,
    },
  ]);

  const settingsTabLink: LeftLayoutIconLink[] =
    !splitContactsTab || isMobile
      ? [
          {
            id: 'Settings',
            Icon: SettingsIcon,
            href: isMobile ? generateSettingsTabLink() : generateSettingsProfileTabLink(),
            linkText: formatMessage('app.navigation.settings'),
            isActive: activeScreen === ScreensEnum.settings,
          },
        ]
      : [];

  const arTabLinks: LeftLayoutIconLink[] = [
    {
      id: 'GetPaid',
      Icon: GetPaidIcon,
      href: generateARTabLink(),
      linkText: 'Get Paid',
      isActive: activeScreen === ScreensEnum.ARDashboard,
      isHidden: !isArEnabled,
    },
  ];
  const links: LeftLayoutIconLink[][] = [
    shouldShowCompaniesLink
      ? [
          {
            id: 'Companies',
            Icon: CompaniesIcon,
            href: generateAccountsDashboardLink(),
            linkText: formatMessage('app.navigation.accounts'),
            isActive: activeScreen === ScreensEnum.accounts,
          },
        ]
      : [],
    [...accountingFirmTabLinks],
    hasAccessToFirm ? [...payTabLinks] : [],
    hasAccessToFirm ? [...getPaidTabLinks] : [],
    hasAccessToFirm ? [...getExpensesTabLinks] : [],
    [...settingsTabLink],
    hasAccessToFirm ? [...arTabLinks] : [],
  ];

  return {
    links: links.filter((group) => group.length),
  };
};
