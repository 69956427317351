import { FormattedMessage, ReceivablePaymentDetails, useMelioIntl } from '@melio/ar-domain';
import { FileAttachment, Group, StatusIconSolid, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { GuestPaymentLayout } from '../../../layout';

export type GuestPaymentConfirmationScreenProps = {
  // todo - fix types to be required in the response and remove the ?
  amount: number;
  paidAt?: Date;
  invoicePreviewUrl?: string;
  isMarkedAsPaid: boolean;
  paymentStatus: ReceivablePaymentDetails['paymentStatus'];
  onViewInvoice: VoidFunction;
};

const PaymentConfirmationDescription = ({
  isMarkedAsPaid,
  paymentStatus,
  paidAt,
}: {
  isMarkedAsPaid: boolean;
  paymentStatus: ReceivablePaymentDetails['paymentStatus'];
  paidAt: string;
}) => {
  if (isMarkedAsPaid) {
    return (
      <Text textStyle="body3Semi" color="success.main">
        <FormattedMessage
          id="ar.guestPayment.successfulPayment.paymentConfirmation.markedAsPaid.text"
          values={{ date: paidAt }}
        />
      </Text>
    );
  }

  if (paymentStatus === 'in-progress') {
    return (
      <Text textStyle="body3Semi" color="neutral.darker">
        <FormattedMessage
          id="ar.guestPayment.successfulPayment.paymentConfirmation.inProgress.text"
          values={{ date: paidAt }}
        />
      </Text>
    );
  }

  return (
    <Text textStyle="body3Semi" color="success.main">
      <FormattedMessage
        id="ar.guestPayment.successfulPayment.paymentConfirmation.paidAt.text"
        values={{ date: paidAt }}
      />
    </Text>
  );
};

export const GuestPaymentConfirmationScreen = forwardRef<GuestPaymentConfirmationScreenProps>(
  ({ amount, paidAt, invoicePreviewUrl, onViewInvoice, isMarkedAsPaid, paymentStatus }, ref) => {
    const { formatCurrency, formatDate } = useMelioIntl();
    useAnalyticsView('PaymentRequest', true, true, {
      PageName: 'invoice-paid',
      Intent: 'payment-confirmation',
    });

    return (
      <GuestPaymentLayout data-component={GuestPaymentConfirmationScreen.displayName} ref={ref}>
        <Group variant="vertical" spacing="l" hasDivider>
          <Group justifyContent="space-between">
            <Group variant="vertical" justifyContent="space-between">
              <StatusIconSolid variant="success" size="extra-large" />
              <Text textStyle="heading3Semi" color="neutral.black">
                <FormattedMessage id="ar.guestPayment.successfulPayment.paymentCompleted.title" />
              </Text>
              <Group variant="vertical" spacing="xxxs">
                <Text data-testid="payment-confirmation-amount" textStyle="heading1Semi">
                  {formatCurrency(amount)}
                </Text>
                <PaymentConfirmationDescription
                  isMarkedAsPaid={isMarkedAsPaid}
                  paymentStatus={paymentStatus}
                  paidAt={formatDate(paidAt, { dateStyle: 'medium' })}
                />
              </Group>
            </Group>
            <FileAttachment onViewModeClick={onViewInvoice} value={invoicePreviewUrl} isViewMode width={90} />
          </Group>
        </Group>
      </GuestPaymentLayout>
    );
  }
);

GuestPaymentConfirmationScreen.displayName = 'GuestPaymentConfirmationScreen';
