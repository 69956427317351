import {
  AccountingPlatformCategory,
  useAccountingPlatformCategories,
  useAccountingPlatforms,
} from '@melio/platform-api';

import type { CategoryOption } from './AccountingPlatformCategorySelect.widget.types';

/**
 * Hook to get accounting platform categories and map them to options for the select component.
 * Currently, API supports qb a/p only and will return empty array for other a/p.
 */
export const useAccountingPlatformCategorySelect = (): { isFetched: boolean; data: CategoryOption[] } => {
  const { activeAccountingPlatform, ...accountingPlatformsQuery } = useAccountingPlatforms();
  const categoriesQuery = useAccountingPlatformCategories({ accountingPlatformId: activeAccountingPlatform?.id });

  const categories = categoriesToOptions(categoriesQuery.data);

  const isFetched = accountingPlatformsQuery.isFetched && categoriesQuery.isFetched;

  return {
    isFetched,
    data: categories,
  };
};

function categoriesToOptions(categories: AccountingPlatformCategory[] = []): CategoryOption[] {
  const categoryMap = categories.reduce<
    Record<string, CategoryOption & Pick<AccountingPlatformCategory, 'parentCategoryId'>>
  >(
    (acc, curr) => ({
      ...acc,
      [curr.id]: {
        label: curr.name,
        value: curr.id,
        description: curr.categoryType,
        subOptions: [] as CategoryOption[],
        parentCategoryId: curr.parentCategoryId,
      },
    }),
    {}
  );

  return categories.reduce<CategoryOption[]>((acc, { id }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const option = categoryMap[id]!;
    const parentCategory = option.parentCategoryId && categoryMap[option.parentCategoryId];
    if (parentCategory) {
      parentCategory.subOptions?.push(option);
    } else {
      acc.push(option);
    }

    return acc;
  }, []);
}
