import { useToast } from '@melio/penny';
import { useAccountingPlatformInitQuickBooksDesktop } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  organizationId?: string;
  accountingPlatformId?: string;
};

export const useInitQuickBooksDesktop = ({ organizationId, accountingPlatformId }: Props) => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const {
    isMutating,
    initQuickBooksDesktop: initQuickBooksDesktopAction,
    initQuickBooksDesktopData,
  } = useAccountingPlatformInitQuickBooksDesktop({
    id: accountingPlatformId,
    enabled: false,
  });

  const initQuickBooksDesktop = async () => {
    try {
      await initQuickBooksDesktopAction(organizationId || '');
    } catch (e) {
      toast({
        type: 'error',
        title: formatMessage('activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.initError'),
      });
      throw e;
    }
  };

  return {
    isMutating,
    initQuickBooksDesktop,
    initQuickBooksDesktopData,
  };
};
