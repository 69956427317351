import { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  Container,
  Form,
  Group,
  Icon,
  IconButton,
  Link,
  StatusIconOutlined,
  Text,
  Tooltip,
  useBreakpoint,
  useMelioForm,
  useToast,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { TaxpayerInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { InfoCard } from '../../../cl/components/InfoCard/InfoCard.component';
import { TaxPayerFileUpload } from './TaxPayerFileUpload';
import { W9SubmissionMethod } from './types';

type Fields = {
  W9SubmissionMethod: W9SubmissionMethod;
};

type TaxPayerInfoFormProps = {
  handleSubmitRequest: () => void;
  handleFileUpload: (file: FileType) => Promise<FileType>;
  handleFileDelete: () => void;
  taxPayerInfo: TaxpayerInfo;
  isLoading: boolean;
};

const schema = yup.object().shape({
  W9SubmissionMethod: yup.string().required(),
}) as yup.SchemaOf<Fields>;

type FileType = File | null | TaxpayerInfo['file'];

export const TaxPayerInfoForm = ({
  handleSubmitRequest,
  handleFileUpload,
  handleFileDelete,
  taxPayerInfo,
  isLoading,
}: TaxPayerInfoFormProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [file, setFile] = useState<FileType>(null);
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { track } = useAnalytics();
  const {
    settings: { tax1099LearnMoreLink, fileSizeLimit },
  } = useConfig();

  const { registerField, formProps, watch } = useMelioForm({
    schema,
    onSubmit: () => {},
    defaultValues: { W9SubmissionMethod: taxPayerInfo?.file ? W9SubmissionMethod.Pdf : W9SubmissionMethod.Email },
    subscribeToDefaultValuesChanges: true,
  });
  const selectedW9SubmissionMethod = watch('W9SubmissionMethod');

  const options = [
    {
      mainLabelProps: {
        label: formatMessage('widgets.taxPayerInfo.form.radioGroup.email'),
      },
      value: W9SubmissionMethod.Email,
    },
    {
      mainLabelProps: {
        label: formatMessage('widgets.taxPayerInfo.form.radioGroup.pdf'),
      },
      value: W9SubmissionMethod.Pdf,
    },
  ];

  const onSuccessUploadW9File = (file: FileType) => {
    setFile({ ...file, name: file.fileName });
    toast({
      type: 'success',
      title: formatMessage('widgets.taxPayerInfo.uploadW9.toast.success'),
      id: 'vendor-tax-details-upload-w9-success',
    });
    track('Vendor', 'Status', {
      Intent: 'upload-w9-file',
      TaxId: 'file',
      Status: 'success',
    });
  };

  const onFailUploadW9File = () =>
    toast({
      type: 'error',
      title: formatMessage('widgets.taxPayerInfo.uploadW9.toast.fail'),
      id: 'vendor-tax-details-upload-w9-fail',
    });

  const handleSelectAndUploadFile = (file: File | null) => {
    track('Vendor', 'Click', {
      Intent: 'upload-w9-file',
      TaxId: 'file',
      Cta: 'upload-w9-file',
    });
    if (!file) {
      return;
    }
    if (file.size > fileSizeLimit) {
      setFile(file);
      return setIsInvalidFile(true);
    }

    setIsInvalidFile(false);
    uploadW9File(file);
  };

  const uploadW9File = (file: FileType) => {
    handleFileUpload(file)
      .then(({ file }) => onSuccessUploadW9File(file))
      .catch(onFailUploadW9File);
  };

  useEffect(() => {
    if (taxPayerInfo?.file) {
      setFile({ ...taxPayerInfo?.file, name: taxPayerInfo?.file?.fileName });
    } else {
      setFile(null);
    }
  }, [taxPayerInfo]);

  const onDeleteInvalidFile = () => {
    setFile(null);
    setIsInvalidFile(false);
  };

  const renderContent = () => {
    if (taxPayerInfo?.file) {
      return (
        <TaxPayerFileUpload
          onDelete={handleFileDelete}
          onSelectFile={handleSelectAndUploadFile}
          value={file}
          isLoading={isLoading}
        />
      );
    }

    return (
      <Form gap="m" data-testid="taxpayer-info-form" {...formProps}>
        <Form.RadioGroup
          {...registerField('W9SubmissionMethod')}
          options={options}
          variant="horizontal"
          aria-label="Taxpayer submission form type"
        />
        {selectedW9SubmissionMethod === W9SubmissionMethod.Pdf && (
          <TaxPayerFileUpload
            onDelete={onDeleteInvalidFile}
            onSelectFile={handleSelectAndUploadFile}
            value={file}
            isInvalidFile={isInvalidFile}
            isLoading={isLoading}
          />
        )}
        {selectedW9SubmissionMethod === W9SubmissionMethod.Email && (
          <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} justifyContent="space-between" spacing="s">
            <InfoCard
              icon={<IconButton variant="naked" size="small" icon="help-circle" />}
              label={formatMessage('widgets.taxPayerInfo.info.label', {
                learnMoreLink: (
                  <Text textStyle="body4" color="brand.main">
                    <Link
                      variant="inline"
                      color="inherit"
                      href={tax1099LearnMoreLink}
                      label={formatMessage('widgets.taxPayerInfo.info.link')}
                      newTab
                    />
                  </Text>
                ),
              })}
            />
            <Button
              variant="tertiary"
              leftElement={<Icon size="small" type="send" color="inherit" aria-hidden />}
              isLoading={isLoading}
              label={formatMessage('widgets.taxPayerInfo.form.submit.request')}
              data-testid={'taxpayer-request-btn'}
              onClick={handleSubmitRequest}
            />
          </Group>
        )}
      </Form>
    );
  };

  return (
    <Container overflow="initial">
      <Group
        data-testid="taxpayer-form"
        spacing="m"
        hasDivider={!isExtraSmallScreen}
        variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
      >
        <Group width="full" variant="vertical" spacing="s">
          <Container gap="xs" alignItems="center" display="flex">
            <Text textStyle="body2Semi">{formatMessage('widgets.taxPayerInfo.form.title')}</Text>
            <Tooltip label={formatMessage('widgets.taxPayerInfo.form.title.tooltip')}>
              <StatusIconOutlined variant="neutral" color="neutral.darker" size="small" />
            </Tooltip>
          </Container>
          {renderContent()}
        </Group>
      </Group>
    </Container>
  );
};

TaxPayerInfoForm.displayName = 'TaxPayerInfoForm';
