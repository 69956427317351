import { Button as ChakraButton } from '@chakra-ui/react';
import { BaseMenu, Group, Icon, Text, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo, useState } from 'react';

import { CompanyAvatarWidget } from '../CompanyAvatar';
import { Account, sortAccounts } from './AccountSwitcher.widgets.utils';

export type AccountSwitcherWidgetsProps = {
  data: Account[];
  selectedId: Account['id'];
  onSelect: (account: Account) => void;
  defaultIsOpen?: boolean;
};

export const AccountSwitcherWidget = ({
  data,
  selectedId,
  onSelect,
  defaultIsOpen,
  ...props
}: AccountSwitcherWidgetsProps) => {
  const { formatMessage } = useMelioIntl();

  const [isOpen, setIsOpen] = useState<boolean>(!!defaultIsOpen);

  const selectedItem = data.find((item) => item.id === selectedId);
  const theme = useTheme();

  const sortedAccounts = useMemo(() => sortAccounts(data), [data]);

  const trigger = (
    <ChakraButton
      data-testid="select-company-btn"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      sx={{
        ...theme.textStyles.body4Semi,
        maxWidth: 'inherit',
        borderRadius: '8px',
        padding: '12px 16px',
        _hover: { backgroundColor: theme.colors.neutral.lightest },
        _expanded: { backgroundColor: theme.colors.neutral.lighter },
        _focus: { outlineColor: theme.colors.info.main, outlineOffset: 0 },
      }}
    >
      <Group alignItems="center" spacing="s">
        {selectedItem?.company.name && (
          <CompanyAvatarWidget
            name={selectedItem?.company.name}
            src={selectedItem?.logoUrl}
            isSelected={selectedItem.id === selectedId}
          />
        )}
        {selectedItem?.company.name && (
          <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
            {selectedItem?.company.name}
          </Text>
        )}
        {data.length > 1 && <Icon type={isOpen ? 'caret-up' : 'caret-down'} size="small" color="default" />}
      </Group>
    </ChakraButton>
  );

  const header = (
    <BaseMenu.Header>
      <Text textStyle="body4SemiUpper" color="neutral.darkest">
        {formatMessage('widgets.accountSwitcher.menuList.title')}
      </Text>
    </BaseMenu.Header>
  );

  return (
    <BaseMenu
      data-component="AccountSwitcherWidget"
      data-testid="account-switcher-dropdown"
      {...props}
      trigger={trigger}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      header={header}
      width="310px"
    >
      {sortedAccounts.map((item, index) => (
        <BaseMenu.Item
          key={index}
          onClick={() => {
            onSelect(item);
            setIsOpen(false);
          }}
          data-testid={`account-switcher-item-${item.id}`}
        >
          <Group alignItems="center" spacing="s">
            {item?.company.name && (
              <CompanyAvatarWidget name={item.company.name} src={item?.logoUrl} isSelected={item.id === selectedId} />
            )}
            {item.company.name && (
              <Text textStyle="inline" shouldSupportEllipsis data-testid="account-switcher-item-name">
                {item.company.name}
              </Text>
            )}
          </Group>
        </BaseMenu.Item>
      ))}
    </BaseMenu>
  );
};

AccountSwitcherWidget.displayName = 'AccountSwitcherWidget';
