import { Box } from '@chakra-ui/react';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { Group, Icon, Table, Text, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  InboxItemBill,
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
  useAccountingPlatforms,
  useFile,
} from '@melio/platform-api';
import { FormatMessage, useMelioIntl } from '@melio/platform-i18n';
import { MouseEventHandler, useEffect, useState } from 'react';

import { BillsTabItem } from '../types';

export const BillsTabOriginCell = ({ billsTabItem }: { billsTabItem: BillsTabItem }) => {
  const { activeAccountingPlatform } = useAccountingPlatforms();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const { originLabel, invoiceNumber, invoiceNumberLabel } = getBillsTabOrigin(
    billsTabItem,
    accountingPlatformName,
    formatMessage
  );

  const fileId = getFileId(billsTabItem);
  const { downloadFile } = useFileDownloader();

  const handleDownloadFileAttempt: MouseEventHandler<HTMLDivElement> = (e) => {
    track('Dashboard', 'Click', {
      Intent: 'download-file',
      Cta: 'download-file',
    });
    if (fileId) {
      e.stopPropagation();
      downloadFile(fileId);
    }
  };

  return (
    <Table.Cell data-testid="bills-tab-bill-cell">
      <Group spacing="xxs" variant="vertical">
        <Box
          display="flex"
          color={!fileId && !invoiceNumber ? 'neutral.darker' : 'neutral.black'}
          flexDirection="row"
          gap={1}
          alignItems="center"
          onClick={handleDownloadFileAttempt}
        >
          <Icon
            type={fileId ? 'bills' : 'no-bills'}
            size="small"
            color="inherit"
            aria-hidden="true"
            isDisabled={!fileId}
            isReadOnly
          />
          <Text textStyle="body3" color={invoiceNumber ? undefined : 'neutral.700'} shouldSupportEllipsis>
            {invoiceNumberLabel}
          </Text>
        </Box>
        <Typography.Description data-testid="bills-tab-bill-cell-label" label={originLabel} />
      </Group>
    </Table.Cell>
  );
};

export const getBillsTabOrigin = (
  billsTabItem: BillsTabItem,
  accountingPlatformName: string,
  formatMessage: FormatMessage
) => {
  let invoiceNumber;
  let originLabel;

  switch (billsTabItem.type) {
    case InboxItemBillTypeEnum.Bill: {
      invoiceNumber = billsTabItem.payload.invoice.number;
      originLabel = getBillOriginLabel(billsTabItem, accountingPlatformName, formatMessage);
      break;
    }
    case InboxItemScannedInvoiceTypeEnum.ScannedInvoice: {
      invoiceNumber = billsTabItem.payload.invoiceNumber;
      const hasInboxEmail = !!billsTabItem.payload.inboxEmailId;
      originLabel = hasInboxEmail
        ? formatMessage(`activities.payDashboard.billsTab.table.cells.bill.origin.billInbox`)
        : '';
      break;
    }
    case InboxItemPaymentRequestTypeEnum.PaymentRequest: {
      invoiceNumber = billsTabItem.payload.invoiceNumber;
      originLabel = formatMessage('activities.payDashboard.billsTab.table.cells.bill.origin.paymentRequest');
      break;
    }
  }
  const invoiceNumberLabel = invoiceNumber
    ? formatMessage('activities.payDashboard.billsTab.table.cells.bill.invoiceNumber', { invoiceNumber })
    : formatMessage('activities.payDashboard.billsTab.table.cells.bill.noInvoice');

  return { invoiceNumberLabel, originLabel, invoiceNumber };
};

const getBillOriginLabel = (bill: InboxItemBill, accountingPlatformName: string, formatMessage: FormatMessage) => {
  switch (bill.payload.origin) {
    case 'fiserv':
      return formatMessage('activities.payDashboard.billsTab.table.cells.bill.origin.ebill');
    case 'intuit':
      return accountingPlatformName;
    default:
      return '';
  }
};

const getFileId = (billsTabItem: BillsTabItem) => {
  if (billsTabItem.type === InboxItemBillTypeEnum.Bill) {
    return billsTabItem.payload.invoice.fileId || null;
  } else if (billsTabItem.type === InboxItemScannedInvoiceTypeEnum.ScannedInvoice) {
    return billsTabItem.payload.fileId || null;
  } else if (billsTabItem.type === InboxItemPaymentRequestTypeEnum.PaymentRequest) {
    return billsTabItem.payload.file?.id || null;
  }

  return null;
};

const useFileDownloader = () => {
  const [fileToDownload, setFileToDownload] = useState<{ id: string } | undefined>();

  const { data: file } = useFile({
    id: fileToDownload?.id,
    enabled: !!fileToDownload,
  });

  useEffect(() => {
    if (file) {
      window.location.href = file.url;
    }
  }, [fileToDownload, file]);

  const downloadFile = (fileId: string) => {
    setFileToDownload({ id: fileId });
  };

  return { downloadFile };
};
