/* eslint-disable react-hooks/exhaustive-deps */
import { cloneElement, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { getPaymentAnalyticsFields } from '@melio/ap-activities/src/utils/analytics/paymentAnalytics';
import { Carousel } from '@melio/ap-widgets';
import { Group, Text } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccountingPlatforms, useBill, usePayment } from '@melio/platform-api';

import { PaymentFullyExpanded } from '@/api/payments/payments.api.types';
import { Loader } from '@/cl/components/Loader/Loader.component';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { BillPaymentDetailsEmptyState } from '@/widgets/billPaymentDetailsEmptyState/BillPaymentDetailsEmptyState.widget';
import { BillDetails } from '@/widgets/pay-dashboard/bill-details/BillDetails.widget';
import { PaymentDetails } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.widget';
import { PaymentDetailsScreenTop } from '@/widgets/pay-dashboard/payment-details-screen-top/PaymentDetailsScreenTop.widget';

export const PaymentDetailsScreen = withAnalyticsContext(({ paymentId }: { paymentId: string }) => {
  const { goToRetryFailedToDeliverPayment, goToRetryFailedToCollectPayment } = useRouter();
  const {
    data,
    isLoading: paymentIsLoading,
    refetch: refetchPayment,
  } = usePayment({
    id: paymentId,
  });
  const { isMutating: isUpdating } = useBill({ id: data?.bills?.[0].id, enabled: false });

  const payment = data as PaymentFullyExpanded;

  const { isFetching: accountingPlatformIsLoading, activeAccountingPlatform } = useAccountingPlatforms();

  const { formatMessage } = usePlatformIntl();

  useEffect(() => {
    refetchPayment();
  }, []);

  useAnalyticsContext({ globalProperties: { ...getPaymentAnalyticsFields(payment), Flow: 'dashboard' } });

  const billDetailsRef = useRef<HTMLDivElement>(null);

  if (paymentIsLoading || accountingPlatformIsLoading || isUpdating) {
    return <Loader isAbsoluteCenter />;
  }

  if (!payment) {
    return <BillPaymentDetailsEmptyState />;
  }

  const retryCollect = () => {
    goToRetryFailedToCollectPayment(paymentId);
  };
  const retryDeliver = () => {
    goToRetryFailedToDeliverPayment(paymentId);
  };

  const elements = payment.bills?.map((bill) => (
    <BillDetails
      bill={bill}
      ref={billDetailsRef}
      key={`${bill.id}`}
      activeAccountingPlatform={activeAccountingPlatform}
      withPayment
      noBorder
    />
  ));

  return (
    <Group variant="vertical">
      <Box display="flex" gridGap={{ xs: '24px', s: '40px' }} flexDir="column" data-testid="pay-dashboard-payment">
        <PaymentDetailsScreenTop
          payment={payment}
          retryCollect={retryCollect}
          retryDeliver={retryDeliver}
          billDetailsRef={billDetailsRef}
          bills={payment.bills}
        />
        <PaymentDetails payment={payment} isActionsEnabled />
        {elements ? (
          elements.length > 1 ? (
            <Group variant="vertical" spacing="m">
              <Text textStyle="body2Semi">
                {formatMessage('screens.paymentDetails.billCarouselTitle.multipleBills', {
                  numberOfBills: elements.length,
                })}
              </Text>
              <Carousel elements={elements} initialElementIndex={0} ref={billDetailsRef} />
            </Group>
          ) : (
            cloneElement(elements[0], { noBorder: false })
          )
        ) : null}
      </Box>
    </Group>
  );
});
