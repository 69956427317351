/* eslint-disable max-lines */
import { AccountingPlatformBillLineItemLabelSelectWidget } from '@melio/ap-widgets';
import {
  AmountField,
  Container,
  Control,
  Form,
  FormField,
  FormLineItems,
  Group,
  Text,
  TextField,
  useBreakpoint,
  useWatch,
} from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformBillLineItemLabelOption,
  AccountingPlatformItem,
  FxCurrency,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDebounceCallback } from '@melio/platform-utils';
import { isEmpty } from 'lodash';
import { useLayoutEffect, useRef } from 'react';

import { ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE } from '../../../constants';
import { AddBillV2FormValues, AddBillV2SetValue, RegisterFieldResult } from '../../../types';
import {
  getNewAmount,
  getNewQuantity,
  getNewUnitPrice,
  useGetLabelForItemsPickerByAccountingSoftware,
} from '../../../utils';
import { AccountingPlatformItemSelect } from '../AccountingPlatformItemSelect';
import { DeleteLineItem } from '../DeleteLineItem';
import { DeleteLineItemMobile } from '../DeleteLineItemMobile';

type Props = {
  items: AccountingPlatformItem[];
  index: number;
  initialExternalLabelId?: string;
  formControl: Control<AddBillV2FormValues>;
  amountFieldProps: RegisterFieldResult;
  itemsPickerProps: RegisterFieldResult;
  quantityFieldProps: RegisterFieldResult;
  unitPriceFieldProps: RegisterFieldResult;
  descriptionFieldProps: RegisterFieldResult;
  currency?: FxCurrency;
  labelFieldProps: RegisterFieldResult;
  hasLineItems: boolean;
  activeAccountingPlatform?: AccountingPlatform;
  billLineItemLabelOptions?: AccountingPlatformBillLineItemLabelOption[];
  setValue: AddBillV2SetValue;
  onClickRemoveLineItem: VoidFunction | null;
  shouldAutoFocus?: boolean;
};

export const ItemBasedBillLineItem = ({
  items,
  index,
  initialExternalLabelId,
  formControl,
  amountFieldProps,
  itemsPickerProps,
  currency,
  quantityFieldProps,
  unitPriceFieldProps,
  descriptionFieldProps,
  activeAccountingPlatform,
  labelFieldProps,
  billLineItemLabelOptions,
  hasLineItems,
  setValue,
  onClickRemoveLineItem,
  shouldAutoFocus = true,
}: Props) => {
  const naturalIndex = index + 1;
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { formatCurrency, formatMessage } = useMelioIntl();
  const { label: selectItemLabel, ariaLabel: selectItemAriaLabel } = useGetLabelForItemsPickerByAccountingSoftware({
    activeAccountingPlatform,
    index: naturalIndex,
  });
  const [description, unitPrice, quantity, amount, externalItemId] = useWatch({
    control: formControl,
    name: [
      `itemBasedLineItems.${index}.description`,
      `itemBasedLineItems.${index}.unitPrice`,
      `itemBasedLineItems.${index}.quantity`,
      `itemBasedLineItems.${index}.amount`,
      `itemBasedLineItems.${index}.externalItemId`,
      'categoryBasedLineItems',
      'itemBasedLineItems',
    ],
  });
  const deleteButtonAriaLabel = formatMessage('activities.addBillV2.lineItems.itemBased.delete.ariaLabel', {
    index: naturalIndex,
    description: description || undefined,
    amount: amount ? formatCurrency(parseFloat(amount), currency) : undefined,
  });

  const externalItemIdRef = useRef<string>(externalItemId);
  const amountRef = useRef<string>(amount || '');
  const quantityRef = useRef<string>(quantity);
  const unitPriceRef = useRef<string>(unitPrice);

  const setFormattedValue: AddBillV2SetValue = (name, value) => {
    const formattedValue = new Number(value ?? 0).toFixed(2);

    setValue(name, formattedValue as never);
  };

  const isEqualByValue = (numericString1?: string | null, numericString2?: string | null) =>
    new Number(numericString1 ?? 0).valueOf() === new Number(numericString2 ?? 0).valueOf();

  const updateUnitPrice = useDebounceCallback(() => {
    const { unitPriceToUpdate, quantityToUpdate } = getNewUnitPrice(quantity, amount || '');
    if (quantityToUpdate) {
      quantityRef.current = quantityToUpdate;
      setValue(`itemBasedLineItems.${index}.quantity`, '1');
    }
    if (unitPriceToUpdate) {
      unitPriceRef.current = unitPriceToUpdate;
      setFormattedValue(`itemBasedLineItems.${index}.unitPrice`, unitPriceToUpdate);
    }
  }, ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE);

  const updateAmount = useDebounceCallback(() => {
    const amountToUpdate = getNewAmount(quantity, unitPrice);
    if (amountToUpdate) {
      amountRef.current = amountToUpdate;
      setFormattedValue(`itemBasedLineItems.${index}.amount`, amountToUpdate);
    }
  }, ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE);

  const updateQuantity = useDebounceCallback(() => {
    const quantityToUpdate = getNewQuantity(amount || '', unitPrice);
    if (quantityToUpdate) {
      quantityRef.current = quantityToUpdate;
      setValue(`itemBasedLineItems.${index}.quantity`, quantityToUpdate);
    }
  }, ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE);

  useLayoutEffect(() => {
    if (!isEqualByValue(amount, amountRef.current)) {
      amountRef.current = amount || '';
      if (!amount) {
        return;
      }
      if (quantity) {
        return updateUnitPrice();
      }
      if (unitPrice && parseFloat(amount) >= parseFloat(unitPrice)) {
        return updateQuantity();
      }
    }
    if (!isEqualByValue(unitPrice, unitPriceRef.current)) {
      unitPriceRef.current = unitPrice || '';
      if (!unitPrice) {
        return;
      }
      if (quantity) {
        return updateAmount();
      }
      if (amount && parseFloat(amount) >= parseFloat(unitPrice)) {
        return updateQuantity();
      }
    }
    if (quantity !== quantityRef.current) {
      quantityRef.current = quantity || '';
      if (!quantity) {
        return;
      }
      if (unitPrice && quantity) {
        return updateAmount();
      }
      if (amount && quantity) {
        return updateUnitPrice();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, unitPrice, quantity]);

  useLayoutEffect(() => {
    if (!externalItemId || externalItemId === externalItemIdRef.current) {
      return;
    }
    externalItemIdRef.current = externalItemId;
    const selectedItem: AccountingPlatformItem | undefined = items.find((item) => item.id === externalItemId);
    if (selectedItem?.purchaseCost) {
      const unitPriceForCalc = selectedItem.purchaseCost as unknown as string;
      unitPriceRef.current = unitPriceForCalc;
      setFormattedValue(`itemBasedLineItems.${index}.unitPrice`, unitPriceForCalc);
      const qtyForCalc = quantity || '1';
      quantityRef.current = qtyForCalc;
      setValue(`itemBasedLineItems.${index}.quantity`, qtyForCalc);
      const amountToUpdate = getNewAmount(qtyForCalc, unitPriceForCalc);
      if (amountToUpdate) {
        amountRef.current = amountToUpdate;
        setFormattedValue(`itemBasedLineItems.${index}.amount`, amountToUpdate);
      }
    }
    if (selectedItem?.description) {
      setValue(`itemBasedLineItems.${index}.description`, selectedItem.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalItemId]);

  const shouldShowClassField = !isEmpty(billLineItemLabelOptions);
  const shouldShowAmountCurrency = currency && currency !== 'USD';

  if (isMobile) {
    return (
      <FormLineItems.MobileListItem index={index}>
        <Group variant="vertical" spacing="m">
          <AccountingPlatformItemSelect
            {...itemsPickerProps}
            data-testid={`add-bill-v2-items-picker-${index}`}
            labelProps={{
              label: selectItemLabel,
            }}
            isRequired
            aria-label={selectItemAriaLabel}
            placeholder={selectItemLabel}
            autoFocus={shouldAutoFocus && hasLineItems}
          />
          <Form.TextField
            {...descriptionFieldProps}
            data-testid={`add-bill-v2-item-description-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.itemBased.description.label') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.description.ariaLabel', {
              index: naturalIndex,
            })}
          />
          <Form.TextField
            {...quantityFieldProps}
            data-testid={`add-bill-v2-item-quantity-${index}`}
            isRequired
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.itemBased.quantity.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.quantity.ariaLabel', {
              index: naturalIndex,
            })}
          />
          <Form.AmountField
            {...unitPriceFieldProps}
            decimalScale={2}
            isRequired
            currency={currency}
            data-testid={`add-bill-v2-item-unitPrice-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.itemBased.unitPrice.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.unitPrice.ariaLabel', {
              index: naturalIndex,
            })}
          />
          {shouldShowClassField && (
            <AccountingPlatformBillLineItemLabelSelectWidget
              data-testid={`add-bill-v2-bill-line-item-label-picker-${index}`}
              initialLabelId={initialExternalLabelId}
              billLineItemLabelOptions={billLineItemLabelOptions}
              activeAccountingPlatform={activeAccountingPlatform}
              labelProps={{
                label: formatMessage('activities.addBillV2.lineItems.itemBased.class.label'),
              }}
              isFetched
              aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.class.ariaLabel', {
                index: naturalIndex,
              })}
              {...labelFieldProps}
            />
          )}
          <Form.AmountField
            {...amountFieldProps}
            isRequired
            decimalScale={2}
            data-testid={`add-bill-v2-items-line-item-amount-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.itemBased.amount.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.amount.ariaLabel', {
              index: naturalIndex,
            })}
            currency={currency}
            endElement={
              shouldShowAmountCurrency ? (
                <Container paddingX="s">
                  <Text color="neutral.darker">{currency}</Text>
                </Container>
              ) : undefined
            }
          />
          {onClickRemoveLineItem && (
            <DeleteLineItemMobile
              IndexNumber={index}
              label={formatMessage('activities.addBillV2.lineItems.removeItem.label')}
              onClick={onClickRemoveLineItem}
              testId="add-bill-v2-items-line-item-delete"
            />
          )}
        </Group>
      </FormLineItems.MobileListItem>
    );
  }

  return (
    <FormLineItems.Row>
      <FormLineItems.Cell size="xs" data-testid="add-bill-v2-items-line-item-number">
        <FormField labelProps={{ label: '', isHidden: true }} render={() => <>{index + 1}</>} />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="m">
        <AccountingPlatformItemSelect
          {...itemsPickerProps}
          data-testid={`add-bill-v2-items-picker-${index}`}
          isRequired
          aria-label={selectItemAriaLabel}
          placeholder={selectItemLabel}
          size="small"
          autoFocus={shouldAutoFocus && hasLineItems}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="m">
        <FormField
          {...descriptionFieldProps}
          data-testid={`add-bill-v2-item-description-${index}`}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.itemBased.description.label'),
            isHidden: true,
          }}
          size="small"
          render={(formFieldProps) => (
            <TextField
              {...formFieldProps}
              size="small"
              aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.description.ariaLabel', {
                index: naturalIndex,
              })}
            />
          )}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="s">
        <FormField
          {...quantityFieldProps}
          data-testid={`add-bill-v2-item-quantity-${index}`}
          isRequired
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.itemBased.quantity.placeholder'),
            isHidden: true,
          }}
          size="small"
          render={(formFieldProps) => (
            <TextField
              {...formFieldProps}
              size="small"
              aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.quantity.ariaLabel', {
                index: naturalIndex,
              })}
            />
          )}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="s">
        <FormField
          {...unitPriceFieldProps}
          isRequired
          data-testid={`add-bill-v2-item-unitPrice-${index}`}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.itemBased.unitPrice.placeholder'),
            isHidden: true,
          }}
          size="small"
          render={(formFieldProps) => (
            <AmountField
              {...formFieldProps}
              currency={currency}
              decimalScale={2}
              aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.unitPrice.ariaLabel', {
                index: naturalIndex,
              })}
            />
          )}
        />
      </FormLineItems.Cell>

      {shouldShowClassField && (
        <FormLineItems.Cell size="m">
          <AccountingPlatformBillLineItemLabelSelectWidget
            data-testid={`add-bill-v2-bill-line-item-label-picker-${index}`}
            initialLabelId={initialExternalLabelId}
            billLineItemLabelOptions={billLineItemLabelOptions}
            activeAccountingPlatform={activeAccountingPlatform}
            isFetched
            {...labelFieldProps}
            size="small"
            aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.class.ariaLabel', {
              index: naturalIndex,
            })}
          />
        </FormLineItems.Cell>
      )}
      <FormLineItems.Cell size={shouldShowClassField ? 'l' : 'm'} isSticky={shouldShowClassField}>
        <Group>
          <Group.Item grow={1}>
            <FormField
              data-testid={`add-bill-v2-items-line-item-amount-${index}`}
              labelProps={{
                label: formatMessage('activities.addBillV2.lineItems.itemBased.amount.placeholder'),
                isHidden: true,
              }}
              {...amountFieldProps}
              isRequired
              size="small"
              render={(formFieldProps) => (
                <AmountField
                  {...formFieldProps}
                  currency={currency}
                  endElement={
                    shouldShowAmountCurrency ? (
                      <Container paddingX="s">
                        <Text color="neutral.darker">{currency}</Text>
                      </Container>
                    ) : undefined
                  }
                  decimalScale={2}
                  aria-label={formatMessage('activities.addBillV2.lineItems.itemBased.amount.ariaLabel', {
                    index: naturalIndex,
                  })}
                />
              )}
            />
          </Group.Item>

          {onClickRemoveLineItem && (
            <Group.Item>
              <DeleteLineItem
                ariaLabel={deleteButtonAriaLabel}
                testId="add-bill-v2-items-line-item-delete"
                onClick={onClickRemoveLineItem}
                IndexNumber={index}
              />
            </Group.Item>
          )}
        </Group>
      </FormLineItems.Cell>
    </FormLineItems.Row>
  );
};
