import { AccountingPlatformSyncConnectionErrorEnum, SyncFlowEnum } from '@melio/ap-activities';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';

import { useAccountingPlatformDisconnect, useQuickBooksDesktopConnect } from '@/hooks/accountingPlatforms.hooks';
import { AccountingPlatformCard } from './AccountingPlatformCard.widget';

type Props = {
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  organizationId: string;
  accountingPlatform: AccountingPlatform;
};

export const AccountingPlatformCardQbdt = ({ syncError, organizationId, accountingPlatform }: Props) => {
  const { accountingSlug, id: accountingPlatformId } = accountingPlatform;

  const { isLoading: isLoadingConnect, connectToAccountingPlatform } = useQuickBooksDesktopConnect({
    flowToReturn: SyncFlowEnum.Setting,
    accountingPlatformId,
  });

  const { isLoading: isLoadingDisconnect, disconnectAccountingPlatform } = useAccountingPlatformDisconnect({
    accountingPlatformId,
    accountingSlug,
    organizationId,
  });

  if (accountingSlug !== AccountingPlatformSlug.QuickBooksDesktop) {
    return null;
  }

  return (
    <AccountingPlatformCard
      accountingPlatform={accountingPlatform}
      onConnectClicked={connectToAccountingPlatform}
      onDisconnectClicked={disconnectAccountingPlatform}
      isLoading={isLoadingDisconnect || isLoadingConnect}
      syncError={syncError}
    />
  );
};
