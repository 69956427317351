import { VoidAndResendActivity } from '@melio/ap-activities';
import { useInboxItems } from '@melio/platform-api';
import { useNavigate } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';

export const VoidAndResendPaymentScreen = ({ paymentId }: { paymentId: string }) => {
  const navigate = useNavigate();
  const { generateNPEDashboardLink } = useRouter();
  const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });

  const goToDashboard = () => {
    refetchInboxItems();
    navigate(generateNPEDashboardLink(`payments?status=failed`));
  };

  return <VoidAndResendActivity paymentId={paymentId} goToDashboard={goToDashboard} />;
};
