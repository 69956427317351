import {
  DeliveryMethod,
  DeliveryPreference,
  FundingSource,
  useDeliveryMethod,
  useFeesBreakdown,
  useFundingSource,
  useOrgBillingFeeSettings,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { usePaymentFee, useTotalFees } from './fees';

type PaymentData = {
  amount: number;
  fundingSourceId: FundingSource['id'];
  deliveryMethodId: DeliveryMethod['id'];
  deliveryPreference?: DeliveryPreference;
  lastAmount?: number;
};

export const usePaymentFeesDetails = (payment: PaymentData) => {
  const { data: fundingSource, isLoading: isLoadingFundingSource } = useFundingSource({
    id: payment.fundingSourceId,
  });

  const { data: deliveryMethod, isLoading: isLoadingDeliveryMethod } = useDeliveryMethod({
    id: payment.deliveryMethodId,
  });

  const { deliveryPreference, lastAmount } = payment;
  const [isBillingFeeSettingsEnabled] = useFeature(FeatureFlags.BillingFees, false);

  const { data: orgBillingFeeSettings = [], isLoading: isBillingFeeSettingsLoading } = useOrgBillingFeeSettings({
    enabled: isBillingFeeSettingsEnabled,
  });

  const shouldEnableFeesBreakdown = !!deliveryPreference && !!fundingSource && !!deliveryMethod;

  const {
    data: feesBreakdown,
    isLoading: isLoadingFeesBreakdown,
    error: feesBreakdownError,
  } = useFeesBreakdown({
    params: {
      feesBreakdownParams: shouldEnableFeesBreakdown
        ? [
            {
              deliveryMethodType: deliveryMethod.type,
              deliveryMethodId: deliveryMethod.id,
              fundingSourceType: fundingSource.type,
              fundingSourceId: fundingSource.id,
              deliveryPreference: deliveryPreference.type,
              paymentAmount: payment.amount,
            },
          ]
        : [],
    },
    enabled: shouldEnableFeesBreakdown,
  });

  const shouldEnableLastAmountFeesBreakdown = shouldEnableFeesBreakdown && lastAmount !== undefined;

  const {
    data: feesBreakdownLastAmount,
    isLoading: isLoadingLastAmountFeesBreakdown,
    error: lastAmountFeesBreakdownError,
  } = useFeesBreakdown({
    params: {
      feesBreakdownParams: shouldEnableLastAmountFeesBreakdown
        ? [
            {
              deliveryMethodType: deliveryMethod.type,
              deliveryMethodId: deliveryMethod.id,
              fundingSourceType: fundingSource.type,
              fundingSourceId: fundingSource.id,
              deliveryPreference: deliveryPreference.type,
              paymentAmount: lastAmount,
            },
          ]
        : [],
    },
    enabled: shouldEnableLastAmountFeesBreakdown,
  });

  const feeItems = usePaymentFee(feesBreakdown?.feesBreakdown || []);
  const total = useTotalFees({
    transactionFees: feeItems || [],
    orgBillingFeeSettings,
    recurringLastAmountFees: feesBreakdownLastAmount?.feesBreakdown,
  });
  return {
    error: feesBreakdownError || lastAmountFeesBreakdownError,
    feeItems,
    total,
    isLoading:
      isLoadingFundingSource ||
      isLoadingDeliveryMethod ||
      isBillingFeeSettingsLoading ||
      isLoadingFeesBreakdown ||
      (shouldEnableLastAmountFeesBreakdown && isLoadingLastAmountFeesBreakdown),
  };
};
