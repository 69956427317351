import { CardFundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';

export const useFormatExpirationDate = () => {
  const { formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();

  const formatExpirationDate = (fundingSource: CardFundingSource) => {
    const { expirationYear, expirationMonth } = fundingSource.details;
    const expirationDate = createDate(`${expirationYear}-${expirationMonth}-01`);

    return formatDate(expirationDate, {
      month: 'numeric',
      year: '2-digit',
    });
  };

  return { formatExpirationDate };
};
