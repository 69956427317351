import { isFXCurrency, useDownloadPaymentConfirmation } from '@melio/ap-domain';
import { Group, Icon, IconButton, IconKey, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, FxCurrency } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { getPaymentAmount } from '../../../../../../utils/pay-flow/Payment.utils';
import { useIsMobile } from '../../../../../../utils/viewport-utils/useIsMobile';

export const PaymentDetailsCardTitle = ({
  paymentId,
  amount,
  bills,
  icon,
  currency,
  foreignAmount,
}: {
  paymentId?: string | null;
  amount: number;
  foreignAmount?: number | null;
  bills: Bill[];
  icon?: IconKey;
  currency: FxCurrency;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'download' | 'print';
    Intent: 'download' | 'print';
    PageName: 'payment-scheduled';
  }>('Payment', 'Click', { PageName: 'payment-scheduled' });

  const isMobile = useIsMobile();
  const isFXPayment = isFXCurrency(currency);
  const paymentAmount = getPaymentAmount(amount, foreignAmount);
  const isPartialPayment = bills.length === 1 && bills[0] && paymentAmount < bills[0].amount;

  const { download, print } = useDownloadPaymentConfirmation();
  return (
    <Group variant="vertical" spacing="m" data-testid="payment-details-card-title">
      <Group alignItems="center" justifyContent="space-between">
        <Text textStyle="heading3Semi" as="h2">
          {formatMessage('activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.title')}
        </Text>
        {paymentId ? (
          <Group spacing="xs">
            <IconButton
              data-testid="payment-details-card-title-download"
              icon="download"
              onClick={() => trackAndHandleClick({ Cta: 'download', Intent: 'download' }, () => download(paymentId))}
              size="large"
              aria-label={formatMessage(
                'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.title.download.ariaLabel'
              )}
            />
            {!isMobile ? (
              <IconButton
                data-testid="payment-details-card-title-print"
                icon="print"
                onClick={() => trackAndHandleClick({ Cta: 'print', Intent: 'print' }, () => print(paymentId))}
                size="large"
                aria-label={formatMessage(
                  'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.title.print.ariaLabel'
                )}
              />
            ) : null}
          </Group>
        ) : null}
      </Group>
      <Group variant="vertical" spacing="none">
        <Group variant="horizontal" alignItems="center">
          <Text data-testid="payment-details-card-title-amount" textStyle="display2">
            {formatCurrency(foreignAmount ?? amount, currency)}
          </Text>
          {icon && <Icon type={icon} />}
        </Group>
        {isFXPayment && (
          <Text data-testid="payment-details-card-title-fxDebitAmount" textStyle="body3" color="neutral.darker">
            {formatMessage(
              'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.fxDebitAmount',
              {
                amount: formatCurrency(amount),
              }
            )}
          </Text>
        )}
        {isPartialPayment && bills[0] ? (
          <Text data-testid="payment-details-card-title-partial-payment" textStyle="body4Semi" color="neutral.darker">
            {formatMessage(
              'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.partialPaymentRemaining',
              {
                amount: formatCurrency(bills[0].balance),
              }
            )}
          </Text>
        ) : null}
      </Group>
    </Group>
  );
};
