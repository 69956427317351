import { Box } from '@chakra-ui/react';
import {
  Control,
  Form,
  FormLineItems,
  NakedButton,
  Text,
  Typography,
  useBreakpoint,
  useFieldArray,
  useWatch,
} from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformBillLineItemLabelOption,
  AccountingPlatformSlug,
  FxCurrency,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty, keyBy, mapValues } from 'lodash';
import { useMemo, useState } from 'react';

import { useLineItemAccessibility } from '../../../hooks/useLineItemAccessibility';
import { useLineItemAnalytics } from '../../../hooks/useLineItemAnalytics';
import { AddBillV2FormDefaultValues, AddBillV2FormValues, RegisterFieldFn } from '../../../types';
import { createEmptyCategoryLineItem } from '../../../utils';
import { CategoryBasedBillLineItem } from './CategoryBasedBillLineItem';

type Props = {
  isHidden: boolean;
  formControl: Control<AddBillV2FormValues>;
  formDefaultValues?: AddBillV2FormDefaultValues;
  billLineItemLabelOptions?: AccountingPlatformBillLineItemLabelOption[];
  activeAccountingPlatform?: AccountingPlatform;
  currency?: FxCurrency;
  registerField: RegisterFieldFn;
};

export const CategoryBasedBillLineItemsForm = ({
  isHidden,
  formControl,
  currency,
  formDefaultValues,
  billLineItemLabelOptions,
  activeAccountingPlatform,
  registerField,
}: Props) => {
  const { onDeleteLineItemSuccess } = useLineItemAccessibility();
  const { trackLineItemAdded, trackLineItemDeleted } = useLineItemAnalytics();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const [amount, itemBasedLineItems] = useWatch({
    control: formControl,
    name: ['amount', 'itemBasedLineItems'],
  });

  const { fields, append, remove } = useFieldArray<AddBillV2FormValues, 'categoryBasedLineItems'>({
    control: formControl,
    name: 'categoryBasedLineItems',
    shouldUnregister: false,
    rules: {
      minLength: 1,
    },
  });

  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const onAddLineItem = () => {
    if (!shouldAutoFocus) {
      setShouldAutoFocus(true);
    }

    if (!fields.length && !itemBasedLineItems?.length) {
      append(
        createEmptyCategoryLineItem({
          amount: amount ?? '',
        })
      );
      return;
    }

    append(createEmptyCategoryLineItem());
    trackLineItemAdded();
    return;
  };

  const [itemFields, categoryFields] = useWatch({
    control: formControl,
    name: ['itemBasedLineItems', 'categoryBasedLineItems'],
  });
  const initialLineItems = formDefaultValues?.categoryBasedLineItems;
  const initialLineItemExternalLabelIdsMap = useMemo(
    () => mapValues(keyBy(initialLineItems, 'lineItemId'), 'externalLabelId'),
    [initialLineItems]
  );

  if (isHidden) {
    return null;
  }

  const onDeleteLineItem = (index: number) => {
    remove(index);
    trackLineItemDeleted();
    onDeleteLineItemSuccess();
  };

  const isQuickBooksOnline = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
  const accountingPlatformCategoryLabel = isQuickBooksOnline
    ? formatMessage('activities.addBillV2.lineItems.categoryBased.sectionTitle.quickbooks')
    : formatMessage('activities.addBillV2.lineItems.categoryBased.sectionTitle.general');

  const addAnotherLineItemAriaLabel = isQuickBooksOnline
    ? formatMessage('activities.addBillV2.lineItems.categoryBased.addNewLine.ariaLabel.quickbooks')
    : formatMessage('activities.addBillV2.lineItems.categoryBased.addNewLine.ariaLabel.general');

  const canDeleteRow = (categoryFields?.length || 0) + (itemFields?.length || 0) > 1;
  const shouldShowClassField = !isEmpty(billLineItemLabelOptions);
  const hasLineItems = fields.length > 1;

  const lineItems = isMobile ? (
    <FormLineItems.MobileList>
      {fields.map((field, index) => (
        <CategoryBasedBillLineItem
          formControl={formControl}
          activeAccountingPlatform={activeAccountingPlatform}
          key={field.id}
          initialExternalLabelId={
            field.lineItemId ? (initialLineItemExternalLabelIdsMap?.[field.lineItemId] as string) : undefined
          }
          currency={currency}
          categoriesPickerProps={registerField(`categoryBasedLineItems.${index}.externalCategoryId`)}
          descriptionFieldProps={registerField(`categoryBasedLineItems.${index}.description`)}
          amountFieldProps={registerField(`categoryBasedLineItems.${index}.amount`)}
          index={index}
          billLineItemLabelOptions={billLineItemLabelOptions}
          onClickRemoveLineItem={canDeleteRow ? () => onDeleteLineItem(index) : null}
          labelFieldProps={registerField(`categoryBasedLineItems.${index}.externalLabelId`)}
          hasLineItems={hasLineItems}
          shouldAutoFocus={shouldAutoFocus}
        />
      ))}
    </FormLineItems.MobileList>
  ) : (
    <Form.ContentBox colSpan={16}>
      <FormLineItems>
        <FormLineItems.HeaderRow>
          <FormLineItems.HeaderCell size="xs" />
          <FormLineItems.HeaderCell size="m">
            <Typography.Label labelShouldSupportEllipsis label={accountingPlatformCategoryLabel} />
          </FormLineItems.HeaderCell>
          <FormLineItems.HeaderCell size="l">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.categoryBased.description.label')}
            />
          </FormLineItems.HeaderCell>
          {shouldShowClassField && (
            <FormLineItems.HeaderCell size="m">
              <Typography.Label
                labelShouldSupportEllipsis
                label={formatMessage('activities.addBillV2.lineItems.categoryBased.class.label')}
              />
            </FormLineItems.HeaderCell>
          )}
          <FormLineItems.HeaderCell size="m">
            <Typography.Label
              labelShouldSupportEllipsis
              label={formatMessage('activities.addBillV2.lineItems.categoryBased.amount.placeholder')}
              description="*"
            />
          </FormLineItems.HeaderCell>
          {canDeleteRow && <FormLineItems.HeaderCell size={48} />}
        </FormLineItems.HeaderRow>

        <FormLineItems.Body>
          {fields.map((field, index) => (
            <CategoryBasedBillLineItem
              formControl={formControl}
              activeAccountingPlatform={activeAccountingPlatform}
              key={field.id}
              currency={currency}
              initialExternalLabelId={
                field.lineItemId ? (initialLineItemExternalLabelIdsMap?.[field.lineItemId] as string) : undefined
              }
              categoriesPickerProps={registerField(`categoryBasedLineItems.${index}.externalCategoryId`)}
              descriptionFieldProps={registerField(`categoryBasedLineItems.${index}.description`)}
              amountFieldProps={registerField(`categoryBasedLineItems.${index}.amount`)}
              index={index}
              onClickRemoveLineItem={canDeleteRow ? () => onDeleteLineItem(index) : null}
              billLineItemLabelOptions={billLineItemLabelOptions}
              labelFieldProps={registerField(`categoryBasedLineItems.${index}.externalLabelId`)}
              hasLineItems={hasLineItems}
              shouldAutoFocus={shouldAutoFocus}
            />
          ))}
        </FormLineItems.Body>
      </FormLineItems>
    </Form.ContentBox>
  );

  return (
    <>
      <Form.ContentBox colSpan={16} data-testid="add-bill-v2-categories-line-items-title">
        <Text as="h3" textStyle="body2Semi">
          {accountingPlatformCategoryLabel}
        </Text>
      </Form.ContentBox>

      {!isEmpty(fields) && lineItems}

      <Form.ContentBox colSpan={16}>
        <Box py="xs">
          <NakedButton
            data-testid="add-bill-v2-categories-line-items-add-new-line"
            aria-label={addAnotherLineItemAriaLabel}
            variant="secondary"
            label={formatMessage('activities.addBillV2.lineItems.categoryBased.addNewLine')}
            onClick={onAddLineItem}
          />
        </Box>
      </Form.ContentBox>
    </>
  );
};
