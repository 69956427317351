import {
  InvoiceExpandedSummary,
  InvoiceTimeline,
  ReceivablePaymentDetailsSourceEnum,
  useMelioIntl,
} from '@melio/ar-domain';
import { IconKey, Link, SectionBannerProps } from '@melio/penny';
import { useDateUtils } from '@melio/platform-utils';
import { differenceInCalendarDays } from 'date-fns';

import { InvoiceActionsHandlers } from '../../../utils';
import { useGetLastSentEmails } from './useGetLastSentEmail';

export const useDrawerSectionBanner = (
  invoice: InvoiceExpandedSummary,
  invoiceActions: Pick<
    InvoiceActionsHandlers,
    'onPreviewInvoice' | 'onIssueInvoice' | 'onEditInvoice' | 'onUpdateMarkAsPaid' | 'onMarkInvoiceAsPaid'
  >,
  invoiceTimeline: InvoiceTimeline
) => {
  const { formatCurrency, formatMessage, formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();
  const { lastSentEmail, lastSentReminder } = useGetLastSentEmails(invoiceTimeline);

  const getSectionBannerVariant = (): NonNullable<SectionBannerProps['variant']> => {
    switch (invoice.displayStatus) {
      case 'draft':
      case 'canceled':
        return 'neutral';
      case 'open':
        return 'informative';
      case 'inProcess':
        return 'informative';
      case 'overdue':
        return 'warning';
      case 'paid':
        return 'success';
    }
  };

  const getSectionBannerIcon = (): IconKey | null => {
    if (invoice.displayStatus === 'draft' || invoice.displayStatus === 'canceled') {
      return 'info-fill';
    }
    if (invoice.displayStatus === 'inProcess') {
      return 'stopwatch-fill';
    }
    return null;
  };

  const getSectionBannerTitle = (): string => {
    switch (invoice.displayStatus) {
      case 'draft':
        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.draft.text');
      case 'open': {
        const daysToDue = Math.abs(differenceInCalendarDays(invoice.dueDate, createDate()));

        if (daysToDue === 0) {
          return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.notSeen.dueToday.text');
        } else if (daysToDue === 1) {
          return formatMessage(
            'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.notSeen.dueTomorrow.text',
            { daysToDue }
          );
        }

        return formatMessage(
          'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.notSeen.dueAfterTomorrow.text',
          { daysToDue }
        );
      }
      case 'overdue': {
        const daysOverdue = Math.abs(differenceInCalendarDays(createDate(), invoice.dueDate));

        if (daysOverdue === 0) {
          return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.overDueToday.text');
        } else if (daysOverdue === 1) {
          return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.overDueYesterday.text', {
            daysOverdue,
          });
        }

        return formatMessage(
          'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.overDueTheDayBeforeYesterday.text',
          { daysOverdue }
        );
      }
      case 'paid': {
        return invoice.receivablePaymentDetails?.source === ReceivablePaymentDetailsSourceEnum.Manual
          ? formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.paid.markAsPaid.text', {
              formattedDate: formatDate(invoice.receivablePaymentDetails.paidAt),
            })
          : formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.paid.text', {
              formattedDate: formatDate(invoice.receivablePaymentDetails?.paidAt),
            });
      }
      case 'inProcess':
        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.scheduled.text', {
          formattedDate: formatDate(invoice.dueDate),
        });
      case 'canceled':
        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.title.canceled.text');
    }
  };

  const getSectionBannerDescription = (): string => {
    const { displayStatus, totalAmount, id, lastSeen, receivablePaymentDetails } = invoice;

    switch (displayStatus) {
      case 'draft':
        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.draft.text', {
          link: (
            <Link
              href="#"
              data-testid="section-banner-action-button"
              variant="inline"
              label={formatMessage(
                'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.draft.link.text'
              )}
              onClick={(e) => {
                e.preventDefault();
                invoiceActions.onEditInvoice(id);
              }}
            />
          ),
        });
      case 'open':
        if (lastSeen) {
          return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.seen.text', {
            lastSeen: formatDate(lastSeen),
          });
        }

        if (lastSentEmail) {
          return formatMessage(
            'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.notSeen.emailSent.text',
            {
              sentDate: formatDate(lastSentEmail.sentAt),
              email: lastSentEmail.email,
            }
          );
        }

        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.notSeen.text', {
          link: (
            <Link
              href="#"
              data-testid="section-banner-action-button"
              variant="inline"
              label={formatMessage(
                'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.notSeen.link.text'
              )}
              onClick={(e) => {
                e.preventDefault();
                invoiceActions.onIssueInvoice(id);
              }}
            />
          ),
        });
      case 'inProcess':
        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.scheduled.text');
      case 'paid': {
        if (receivablePaymentDetails?.source === ReceivablePaymentDetailsSourceEnum.Manual) {
          return formatMessage(
            'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.paid.markAsPaidDetails.text',
            {
              link: (
                <Link
                  href="#"
                  data-testid="section-banner-action-button"
                  variant="inline"
                  label={formatMessage(
                    'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.paid.updateMarkAsPaid.link.text'
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    invoiceActions.onUpdateMarkAsPaid(id);
                  }}
                />
              ),
            }
          );
        }
        if (invoice.receivablePaymentDetails?.method === 'card') {
          return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.paid.card.text', {
            totalAmount: formatCurrency(totalAmount),
          });
        }
        if (invoice.receivablePaymentDetails?.method === 'ach') {
          return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.paid.ach.text', {
            totalAmount: formatCurrency(totalAmount),
          });
        }
        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.paid.general.text', {
          totalAmount: formatCurrency(totalAmount),
        });
      }
      case 'overdue':
        if (lastSentReminder) {
          return formatMessage(
            'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.overdue.reminderSent.text',
            {
              sentDate: formatDate(lastSentReminder.sentAt),
              email: lastSentReminder.email,
              link: (
                <Link
                  href="#"
                  data-testid="section-banner-action-button"
                  variant="inline"
                  label={formatMessage(
                    'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.overdue.link.text'
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    invoiceActions.onIssueInvoice(id);
                  }}
                />
              ),
            }
          );
        }

        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.overdue.text', {
          link: (
            <Link
              href="#"
              data-testid="section-banner-action-button"
              variant="inline"
              label={formatMessage(
                'ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.overdue.link.text'
              )}
              onClick={(e) => {
                e.preventDefault();
                invoiceActions.onIssueInvoice(id);
              }}
            />
          ),
        });
      case 'canceled':
        return formatMessage('ar.dashboard.activities.InvoiceDrawer.body.sectionBanner.description.canceled.text', {
          formattedDate: formatDate(invoice.history.updatedAt),
        });
      default:
        return '';
    }
  };

  return {
    variant: getSectionBannerVariant(),
    title: getSectionBannerTitle(),
    description: getSectionBannerDescription(),
    icon: getSectionBannerIcon(),
  };
};
