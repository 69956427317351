import { Form, UseMelioFormResults, useToast } from '@melio/penny';
import { useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { PaymentFlowFormFields } from '../../../types';

type VendorEmailInputProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  vendor: Vendor | undefined;
};

export const VendorEmailInput = ({ form, vendor }: VendorEmailInputProps) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = form;
  const { toast } = useToast();
  const { update, isMutating } = useVendor({
    enabled: false,
    id: vendor?.id || 'VendorEmailInputVendorId',
    onUpdate: () =>
      toast({
        type: 'success',
        title: formatMessage('activities.paymentFlow.form.content.vendorEmail.toast.success'),
      }),
  });

  return (
    <Form.TextField
      {...registerField('vendorEmail')}
      onBlur={async () => {
        const valid = await form.trigger('vendorEmail');
        const currentEmail = form.getValues('vendorEmail');
        if (vendor && currentEmail !== vendor.contact.email && valid) {
          update({ contact: { email: form.getValues('vendorEmail') } });
        }
      }}
      isLoading={isMutating}
      labelProps={{
        label: formatMessage('activities.paymentFlow.form.content.vendorEmail.label'),
      }}
      helperTextProps={{
        label: formatMessage('activities.paymentFlow.form.content.vendorEmail.helperText'),
      }}
    />
  );
};
