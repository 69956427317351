import { Modal } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
};

export const FeesConfirmationModal = ({ isOpen, onClose, onConfirm, title, description }: Props) => {
  const { formatMessage } = usePlatformIntl();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="fees-confirmation-modal-screen"
      header={title}
      description={description}
      primaryButton={{
        variant: 'primary',
        label: formatMessage('widgets.receivingMethodsSettings.creditCardFeePayment.feesApprovalDialog.confirm'),
        onClick: onConfirm,
      }}
    />
  );
};
