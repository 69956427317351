/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/restrict-template-expressions, no-console */
import {
  fxMemoRegex,
  isFxDM,
  shouldCollectLegalInfoOnPaymentFlow,
  useInvalidFundingSources,
  useMissingCompanyLegalInfoFields,
  useNonPositiveAmountsEffect,
  usePaymentSchedulingPreference,
} from '@melio/ap-domain';
import {
  fsTypesOptionsToFundingSourceTypesOptions,
  isFundingSourceDisabled,
  usePaymentProcessingInfo,
} from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { sanitizeId, Traits, useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BatchUpdateParam,
  BatchUpdateResultErrorItem,
  BatchUpdateResultSuccessItem,
  Bill,
  Currency,
  DeliveryMethod,
  DeliveryPreference,
  FundingSource,
  PatchPaymentIntentsPaymentIntentIdRequest,
  PaymentIntent,
  useFundingSources,
  useInvalidateCache,
  useOrgBillingFeeSettings,
  usePaymentIntents,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { sumBy, useAutoCloseToast, useBoolean } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';

import { useActivitiesNavigate, useMonitorTiming } from '../../utils';
import { CompleteLegalInfoActivity } from '../business-legal-info';
import { DeliveryMethodSelectionActivity } from '../delivery-methods';
import {
  AddBankAccountActivity,
  CreateFundingSourceActivity,
  ReconciliationModalActivity,
  VendorDetailsModalActivity,
} from '../funding-sources';
import { useVendorDetailsModal } from '../funding-sources/FundingSourceSelection/useVendorDetailsModal.hook';
import { InternationalPaymentPurposeActivity } from '../international-payment/payment-purpose';
import { PaymentPurposeUpdateData } from '../international-payment/payment-purpose/PaymentPurpose.activity';
import { InvoiceAttachmentActivity } from '../invoice-attachment';
import { InvoiceBillDetails } from '../invoice-attachment/types';
import { MemoToVendorModalActivity } from '../MemoToVendor';
import { NewSinglePaymentStepLayout } from '../NewSinglePaymentStepLayout';
import { PayDashboardTabs } from '../PayDashboard';
import { PaymentScheduledActivity } from '../PaymentScheduled';
import { SetInvoiceNumberModalActivity } from '../set-invoice-number';
import { PaymentFlowDoneAction } from '../types';
import {
  createPaymentsIntentsWithDerivatives,
  getByDueDateDateToUpdate,
  getByDueDateDeductionDateCalculatorParams,
  getByEarliestDateDataToUpdate,
  getDataToUpdateFromCalculatorResponse,
  getDefaultMemo,
  getRegularPaymentIntents,
  getUpdateSingleDeliveryPreferenceUpdateData,
} from './BatchPayments.activity.utils';
import { BillDetailsDrawerScreen, PaymentIntentsTableLoadingScreen, PaymentIntentsTableScreen } from './screens';
import { EditPaymentAmountModalActivity } from './screens/EditPaymentAmount/EditPaymentAmount.modal.activity';
import { BatchPaymentsActivityProps, DeliveryDateHeaderCellOption, SchedulePaymentIntent } from './types';
import { useBatchPaymentsActivityStep } from './useBatchPaymentsActivityStep';
import { useCreatePaymentIntentsFromBills } from './useCreatePaymentIntentsFromBills';
import { useSubmitBatchPayments } from './useSubmitBatchPayments';

export const BatchPaymentsActivity = withAnalyticsContext<BatchPaymentsActivityProps>(
  ({
    onDone,
    onBack: onFirstStepBack,
    billIds,
    onError,
    externalOrigin,
    isLoading,
    missingKycComplianceInformation,
    setKycComplianceFormState,
  }) => {
    useInvalidateCache();
    const { closeToast } = useToast();

    const { toast } = useToast();
    const { formatCurrency } = useMelioIntl();
    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message });
      onError?.(error);
    };

    const {
      isToggling,
      paymentIntentsWithDerivatives,
      setPaymentIntents,
      allBills,
      vendors,
      refetchVendors,
      isGroupedByVendor: arePaymentsCombined,
      toggleGroupByVendor,
      updatePaymentIntentsBills,
      updateBill,
    } = useCreatePaymentIntentsFromBills({
      billIds,
      onError: handleFail,
    });

    useNonPositiveAmountsEffect(
      onFirstStepBack,
      paymentIntentsWithDerivatives?.map(({ paymentIntent }) => paymentIntent.amountToPay)
    );

    const { setTraits } = useAnalytics();
    const legacyRouteReady = useMonitorTiming('batch_payments_ready');
    const { routeReady } = useMonitoring();
    const triggerMonitoring = (el: HTMLDivElement) => {
      legacyRouteReady(el);
      routeReady(el);
    };
    const setPaymentTraits = () => {
      const traits: Traits = { first_time_pay: true };

      setTraits(traits);
    };

    const { isByDeliveryDate } = usePaymentSchedulingPreference();

    const { formatMessage } = useMelioIntl();

    const { currentStep, goToStep, goToPreviousStep } = useBatchPaymentsActivityStep({ onFirstStepBack });
    useAutoCloseToast(currentStep.step, currentStep.state);

    const [isReconciliationModalOpen, openReconciliationModal] = useBoolean(false);
    const [isPaymentPurposeModalOpen, openPaymentPurposeModal] = useBoolean(false);
    const [isInvoiceAttachmentModalOpen, openInvoiceAttachmentModal] = useBoolean(false);
    const [shouldDisplayVendorDetailsFailureBanner, setShouldDisplayVendorDetailsFailureBanner] = useBoolean(false);
    const [isResolvingFailedPayments, setIsResolvingFailedPayments] = useBoolean(false);

    const paymentIntentExpandParam = [
      'deliveryPreferences',
      'calculatedFees',
      'file',
      'minScheduleDate',
      'fundingSourceTypesOptions',
      'deliveryMethodTypeOptions',
    ];
    const paymentIntentCollection = usePaymentIntents({
      enabled: false,
    });

    const { data: fundingSources } = useFundingSources();
    const [isBillingFeeSettingsEnabled] = useFeature(FeatureFlags.BillingFees, false);
    const [isEditAmountEnabled] = useFeature(FeatureFlags.BatchPaymentEbillEditAmount, false);
    const [shouldShowIndicationForFailedPaymentsScheduling] = useFeature(
      FeatureFlags.BatchPaymentsIndicationForFailedPaymentsSchedulingEnabled,
      false
    );

    const { data: orgBillingFeeSettings = [] } = useOrgBillingFeeSettings({
      enabled: isBillingFeeSettingsEnabled,
    });

    const { missingCompanyLegalInfoFields } = useMissingCompanyLegalInfoFields() || {};

    const [isMemoToVendorModalOpen, setIsMemoToVendorModalOpen] = useState<boolean>(false);
    const [isEditPaymentAmountModalOpen, setIsEditPaymentAmountModalOpen] = useState<boolean>(false);
    const [isSetInvoiceNumberModalOpen, setIsSetInvoiceNumberModalOpen] = useState<boolean>(false);
    const [isBillDetailsDrawerOpen, setIsBillDetailsDrawerOpen] = useState<boolean>(false);

    const [selectedPaymentIntentId, setSelectedPaymentIntentId] = useState<PaymentIntent['id']>();

    const [selectedBill, setSelectedBill] = useState<Bill>();
    const [invoiceBillDetails, setInvoiceBillDetails] = useState<InvoiceBillDetails>();

    const { paymentResults, createPayments } = useSubmitBatchPayments();

    const [loadingRowIds, setLoadingRowIds] = useState<string[]>();

    const { getHasValidFundingSource } = useInvalidFundingSources();

    const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();

    const {
      vendorDetailsModalState,
      onCloseVendorDetailsModal,
      onOpenVendorDetailsModal: openVendorDetailsModal,
    } = useVendorDetailsModal();

    const { tryUseFeature: tryUseBatchPayments } = useSubscriptionFeature({ featureName: 'batchPayments' });
    const { navigateToTab } = useActivitiesNavigate();

    const isDoneLoading = fundingSources && paymentIntentsWithDerivatives;
    useEffect(() => {
      if (isDoneLoading && billIds?.length > 1) {
        tryUseBatchPayments({ onFeatureIsBlocked: () => navigateToTab(PayDashboardTabs.Bills) });
      }
    }, [tryUseBatchPayments, isDoneLoading]);

    const { paymentIntent, bills } =
      paymentIntentsWithDerivatives?.find(({ paymentIntent }) => paymentIntent.id === selectedPaymentIntentId) || {};

    const billTotalAmount = bills?.reduce((acc, bill) => acc + bill.amount, 0) || 0;
    const paymentAmount = paymentIntent?.amountToPay || 0;
    const isPartialPayment = !!(billTotalAmount && 0 < paymentAmount && paymentAmount < billTotalAmount);

    useAnalyticsContext({
      globalProperties: {
        IsBatch: true,
        Flow: 'batch-payment',
        PaymentFrequency: 'One Time',
        ...(paymentIntent && {
          PaymentMethodId: paymentIntent.fundingSourceId,
          PaymentMethodType: paymentIntent.fundingSource?.type,
          DeliveryMethodType: paymentIntent.deliveryMethod?.type,
          VendorId: paymentIntent.billInfo.vendorId,
          BillId: paymentIntent.billPayments?.map(({ billId }) => billId),
          BillTotalAmount: billTotalAmount,
          PaymentAmount: paymentAmount,
          IsPartial: isPartialPayment,
          DueDate: paymentIntent.billInfo.dueDate,
        }),
      },
    });

    const onOpenReconciliationModal = (paymentIntent: PaymentIntent) => {
      setSelectedPaymentIntentId(paymentIntent.id);
      openReconciliationModal.on();
    };

    const onOpenPaymentPurposeModal = (paymentIntent: PaymentIntent) => {
      setSelectedPaymentIntentId(paymentIntent.id);
      openPaymentPurposeModal.on();
    };

    const onOpenVendorDetailsModal = (paymentIntent: PaymentIntent) => {
      setSelectedPaymentIntentId(paymentIntent.id);
      const vendor = vendors?.find((v) => v.id === paymentIntent.billInfo.vendorId);
      const fundingSource = fundingSources?.find((fundingSource) => fundingSource.id === paymentIntent.fundingSourceId);
      openVendorDetailsModal(vendor, fundingSource);
    };

    const onOpenInvoiceAttachmentModal = (paymentIntent: PaymentIntent, bill: Bill) => {
      const paymentIntentId = paymentIntent.id;
      setSelectedPaymentIntentId(paymentIntentId);
      const paymentIntentWithDerivatives = paymentIntentsWithDerivatives?.find(
        ({ paymentIntent }) => paymentIntent.id === paymentIntentId
      );

      const invoiceBillDetails: InvoiceBillDetails = {
        billId: bill.id,
        billNumber: sanitizeId(bill.id) as string,
        billAmount: formatCurrency(bill.amount, bill.currency as Currency),
        organizationName: paymentIntentWithDerivatives?.vendor?.name,
      };

      setInvoiceBillDetails(invoiceBillDetails);
      openInvoiceAttachmentModal.on();
    };

    const onDoneFundingSourceReconciliation = () => {
      openReconciliationModal.off();
    };

    const onDonePaymentPurpose = async (paymentIntentId: string, data: PaymentPurposeUpdateData) => {
      const paymentIntentWithDerivatives = paymentIntentsWithDerivatives?.find(
        ({ paymentIntent }) => paymentIntent.id === paymentIntentId
      );

      if (paymentIntentWithDerivatives?.paymentIntent?.billInfo.id && data.billInfo?.invoice?.fileId) {
        await updateBill(paymentIntentWithDerivatives.paymentIntent.billInfo.id, { invoice: data.billInfo.invoice });
      }
      await onUpdateSinglePaymentIntent(paymentIntentId, data);
      openPaymentPurposeModal.off();
    };

    const onDoneInvoiceAttachmentModel = () => {
      updatePaymentIntentsBills();
      openInvoiceAttachmentModal.off();
    };

    const onAddMemoToVendorClick = (paymentIntentId: PaymentIntent['id']) => {
      setSelectedPaymentIntentId(paymentIntentId);
      setIsMemoToVendorModalOpen(true);
    };

    const onEditAmountClick = (paymentIntentId: PaymentIntent['id'], bill: Bill) => {
      setSelectedPaymentIntentId(paymentIntentId);
      setSelectedBill(bill);
      setIsEditPaymentAmountModalOpen(true);
    };

    const onSetInvoiceNumberClick = (paymentIntentId: PaymentIntent['id']) => {
      setSelectedPaymentIntentId(paymentIntentId);
      setIsSetInvoiceNumberModalOpen(true);
    };

    const onViewBillDetailsClick = (paymentIntentId: PaymentIntent['id'], bill: Bill) => {
      setSelectedPaymentIntentId(paymentIntentId);
      setSelectedBill(bill);
      setIsBillDetailsDrawerOpen(true);
    };

    const toggleCombinedPayments = () => {
      toast({
        type: 'informative',
        title: formatMessage(`activities.batchPayments.toggle.toast.${arePaymentsCombined ? 'turnOff' : 'turnOn'}`),
      });
      toggleGroupByVendor().then((paymentIntents) => {
        if (paymentIntents && !ignoreIsByDueDate.length) {
          maintainByDueDateLogic(paymentIntents);
        }
        setIgnoreIsByDueDate([]);
      });
    };

    const onRemoveBillsClick = (paymentIntentId: PaymentIntent['id'], billId?: Bill['id']) => {
      if (billId) {
        const paymentIntentWithDerivatives = paymentIntentsWithDerivatives?.find(
          ({ paymentIntent }) => paymentIntent.id === paymentIntentId
        );

        if (!paymentIntentWithDerivatives) {
          return;
        }

        expandLoadingRowIds([paymentIntentId]);

        const {
          paymentIntent: { billPayments, amountToPay },
        } = paymentIntentWithDerivatives;

        if (!billPayments || !amountToPay) {
          return;
        }

        const prevData = {
          billPayments,
          amountToPay,
        };

        const newBillPayments = billPayments.filter((billPayment) => billPayment.billId !== billId);
        const newData = {
          billPayments: newBillPayments,
          amountToPay: sumBy(newBillPayments, (bill) => bill.amount),
        };

        paymentIntentCollection
          .update(
            [
              {
                id: paymentIntentId,
                data: newData,
              },
            ],
            {
              expand: paymentIntentExpandParam,
            }
          )
          .then((results) => {
            if (results.length) {
              const successResults = results.filter(
                (result) => result.status === 'success'
              ) as BatchUpdateResultSuccessItem<PaymentIntent>[];
              setPaymentIntentsListFromSuccessUpdateResults(successResults);
              maintainByDueDateLogicAfterUpdate(successResults);
            }
            toast({
              type: 'informative',
              title: formatMessage(`activities.batchPayments.billHasBeenRemoved.content`),
              action: {
                type: 'button',
                text: formatMessage(`activities.batchPayments.billHasBeenRemoved.undo`),
                onAction: (closeToast) => {
                  expandLoadingRowIds([paymentIntentId]);

                  paymentIntentCollection
                    .update([{ id: paymentIntentId, data: prevData }], {
                      expand: paymentIntentExpandParam,
                    })
                    .then((results) => {
                      if (results.length) {
                        const successResults = results.filter(
                          (result) => result.status === 'success'
                        ) as BatchUpdateResultSuccessItem<PaymentIntent>[];
                        setPaymentIntentsListFromSuccessUpdateResults(successResults);
                      }
                    });
                  closeToast();
                },
              },
            });
          });
      } else {
        const prevPaymentIntents = paymentIntentsWithDerivatives?.map(({ paymentIntent }) => paymentIntent);
        setPaymentIntents((paymentIntents) =>
          paymentIntents?.filter((paymentIntent) => paymentIntent.id !== paymentIntentId)
        );

        toast({
          type: 'informative',
          title: formatMessage(`activities.batchPayments.paymentHasBeenRemoved.content`),
          action: {
            type: 'button',
            text: formatMessage(`activities.batchPayments.paymentHasBeenRemoved.undo`),
            onAction: (closeToast) => {
              setPaymentIntents(prevPaymentIntents);
              closeToast();
            },
          },
        });
      }
    };

    const onAddFundingSourceClick = () => {
      goToStep('CREATE_FUNDING_SOURCE');
    };

    const onAddDeliveryMethodClick = (paymentIntent: PaymentIntent) => {
      setSelectedPaymentIntentId(paymentIntent.id);
      goToStep('CREATE_DELIVERY_METHOD');
    };

    const removeFromLoadingRowIds = (idsToRemove: PaymentIntent['id'][]) =>
      setLoadingRowIds(loadingRowIds?.filter((id) => !idsToRemove.includes(id)));

    const handleUpdateFail = (paymentIntentIds: PaymentIntent['id'][], error?: PlatformError) => {
      removeFromLoadingRowIds(paymentIntentIds);
      if (error) {
        handleFail(error);
      }
    };

    useEffect(() => {
      if (!paymentIntentCollection.isMutating) {
        setLoadingRowIds([]);
      }
    }, [paymentIntentCollection.isMutating]);

    useEffect(() => {
      if (fundingSources && !getHasValidFundingSource(fundingSources)) {
        goToStep('FIRST_CREATE_FUNDING_SOURCE');
      }
    }, [fundingSources]);

    const onUpdateSinglePaymentIntent = (
      paymentIntentId: PaymentIntent['id'],
      data: PatchPaymentIntentsPaymentIntentIdRequest,
      shouldNotMaintainDueDateLogic?: boolean
    ) => onUpdatePaymentIntents([{ id: paymentIntentId, data }], shouldNotMaintainDueDateLogic);

    const expandLoadingRowIds = (paymentIntentsToAdd: PaymentIntent['id'][]) => {
      const subRows = compact(
        paymentIntentsToAdd.flatMap((id) =>
          paymentIntentsWithDerivatives?.find(({ paymentIntent }) => paymentIntent.id === id)?.bills.map(({ id }) => id)
        )
      );

      setLoadingRowIds([...(loadingRowIds || []), ...paymentIntentsToAdd, ...subRows]);
    };

    const setPaymentIntentsListFromSuccessUpdateResults = (
      successResults: BatchUpdateResultSuccessItem<PaymentIntent>[]
    ) => {
      setPaymentIntents((current) =>
        current?.map((paymentIntent) => {
          const matchingResult = successResults.find((result) => result.id === paymentIntent.id);
          return matchingResult?.data || paymentIntent;
        })
      );
    };

    const onUpdatePaymentIntents = (
      batchUpdate: BatchUpdateParam<PatchPaymentIntentsPaymentIntentIdRequest>,
      shouldNotMaintainDueDateLogic?: boolean
    ) => {
      const paymentIntentsToUpdate = batchUpdate.map((item) => item.id);
      expandLoadingRowIds(paymentIntentsToUpdate);

      return paymentIntentCollection
        .update(batchUpdate, { expand: paymentIntentExpandParam })
        .then((results) => {
          if (results.length < 1) {
            return Promise.reject({});
          }
          const successResults = results.filter(
            (result) => result.status === 'success'
          ) as BatchUpdateResultSuccessItem<PaymentIntent>[];
          setPaymentIntentsListFromSuccessUpdateResults(successResults);
          if (!shouldNotMaintainDueDateLogic) {
            void maintainByDueDateLogicAfterUpdate(successResults);
          }
          const failedResult = results.find((result) => result.status === 'error') as BatchUpdateResultErrorItem;
          if (failedResult) {
            handleFail(failedResult.error);
            return Promise.reject(failedResult.error);
          }
          return results;
        })
        .catch((error) => handleUpdateFail(paymentIntentsToUpdate, error));
    };

    const [selectedDeliveryDateHeaderCellOption, setSelectedDeliveryDateHeaderCellOption] = useState(
      DeliveryDateHeaderCellOption.EarliestPossible
    );
    const [isByDueDate, setIsByDueDate] = useState(false);
    const [ignoreIsByDueDate, setIgnoreIsByDueDate] = useState<PaymentIntent['id'][]>([]);
    // if deduction date header is marked "by due date" - maintain the logic after each update
    const maintainByDueDateLogicAfterUpdate = (successResults: BatchUpdateResultSuccessItem<PaymentIntent>[]) => {
      if (isByDueDate) {
        const paymentIntentsToUpdate = successResults
          .filter((result) => !ignoreIsByDueDate.includes(result.id))
          .map((result) => result.data);
        maintainByDueDateLogic(paymentIntentsToUpdate);
      }
      return successResults;
    };
    const maintainByDueDateLogic = (paymentIntentsToUpdate: PaymentIntent[]) => {
      if (isByDueDate && allBills && vendors) {
        if (paymentIntentsToUpdate.length > 0) {
          const byDueDateDeductionDateCalculatorParams = getByDueDateDeductionDateCalculatorParams(
            createPaymentsIntentsWithDerivatives(paymentIntentsToUpdate, allBills, vendors)
          );

          const paymentIntentsToUpdateIds = paymentIntentsToUpdate.map((paymentIntent) => paymentIntent.id);

          expandLoadingRowIds(paymentIntentsToUpdateIds);

          return paymentIntentCollection
            .getPaymentSettings(byDueDateDeductionDateCalculatorParams)
            .then((calculatorResponse) => {
              removeFromLoadingRowIds(paymentIntentsToUpdateIds);

              const dataToUpdate = getDataToUpdateFromCalculatorResponse(paymentIntentsToUpdate, calculatorResponse);

              if (dataToUpdate.length) {
                return onUpdatePaymentIntents(dataToUpdate, true);
              }
              return;
            })
            .catch((error) => handleUpdateFail(paymentIntentsToUpdateIds, error));
        }
      }
      return paymentIntentsToUpdate;
    };

    const onUpdateAllWithTheSameData = (
      data: PatchPaymentIntentsPaymentIntentIdRequest,
      shouldMaintainDueDateLogic?: boolean
    ) => {
      if (!paymentIntentsWithDerivatives) {
        return;
      }
      const batchUpdate = paymentIntentsWithDerivatives.map(({ paymentIntent: { id } }) => ({
        id,
        data,
      }));
      return onUpdatePaymentIntents(batchUpdate, shouldMaintainDueDateLogic);
    };

    const onUpdateAllFundingSources = (fundingSource: FundingSource) => {
      setShouldDisplayVendorDetailsFailureBanner.off();
      if (!paymentIntentsWithDerivatives || !fundingSource) {
        return;
      }

      const batchUpdate = paymentIntentsWithDerivatives
        .filter(
          ({ paymentIntent: { fundingSourceTypesOptions } }) =>
            !isFundingSourceDisabled(fundingSource, fundingSourceTypesOptions)
        )
        .map(({ paymentIntent: { id } }) => ({
          id,
          data: { fundingSourceId: fundingSource.id },
        }));

      return batchUpdate.length ? onUpdatePaymentIntents(batchUpdate) : null;
    };

    const onUpdatePaymentIntentsToArriveByDueDate = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) => {
      const paymentIntentsIds = paymentIntentsWithDerivatives.map(({ paymentIntent }) => paymentIntent.id);
      expandLoadingRowIds(paymentIntentsIds);

      const calculatorParams = getByDueDateDeductionDateCalculatorParams(paymentIntentsWithDerivatives);
      return paymentIntentCollection.getPaymentSettings(calculatorParams).then((calculatorResponse) => {
        removeFromLoadingRowIds(paymentIntentsIds);

        const dataToUpdate = getDataToUpdateFromCalculatorResponse(
          paymentIntentsWithDerivatives.map(({ paymentIntent }) => paymentIntent),
          calculatorResponse
        );
        if (dataToUpdate.length) {
          return onUpdatePaymentIntents(dataToUpdate, true);
        }
        return;
      });
    };

    const onUpdatePaymentIntentsToTheEarliestDate = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) => {
      const regularPaymentIntentsWithDerivatives = getRegularPaymentIntents(paymentIntentsWithDerivatives);
      if (regularPaymentIntentsWithDerivatives.length === paymentIntentsWithDerivatives.length) {
        setSelectedDeliveryDateHeaderCellOption(DeliveryDateHeaderCellOption.EarliestPossible);
      }

      const dataToUpdate = getByEarliestDateDataToUpdate(regularPaymentIntentsWithDerivatives);

      if (dataToUpdate.length) {
        onUpdatePaymentIntents(dataToUpdate, true);
      }
    };

    const onUpdatePaymentIntentsToTheDueDate = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) => {
      const regularPaymentIntentsWithDerivatives = getRegularPaymentIntents(paymentIntentsWithDerivatives);
      if (regularPaymentIntentsWithDerivatives.length === paymentIntentsWithDerivatives.length) {
        setSelectedDeliveryDateHeaderCellOption(DeliveryDateHeaderCellOption.ByDueDate);
      }

      const dataToUpdate = getByDueDateDateToUpdate(regularPaymentIntentsWithDerivatives);

      if (dataToUpdate.length) {
        onUpdatePaymentIntents(dataToUpdate, true);
      }
    };

    const updateVendorPaymentIntentsDeliveryMethod = (vendor: Vendor, deliveryMethod: DeliveryMethod) => {
      const schedulePaymentIntentsToUpdate = paymentIntentsWithDerivatives?.filter(
        (schedulePaymentIntent) =>
          schedulePaymentIntent.vendor.id === vendor.id &&
          schedulePaymentIntent.paymentIntent.deliveryMethodTypeOptions?.find(
            (option) => option.type === deliveryMethod.type
          )?.supported
      );

      if (schedulePaymentIntentsToUpdate) {
        const batchUpdate = schedulePaymentIntentsToUpdate.map(({ paymentIntent }) => ({
          id: paymentIntent.id,
          data: { deliveryMethodId: deliveryMethod.id },
        }));

        return onUpdatePaymentIntents(batchUpdate);
      }

      return;
    };

    const handleConfirm = () => {
      closeToast();
      goToStep('LOADING');
      createPayments({
        paymentIntents: paymentIntentsWithDerivatives,
      })
        .then((paymentResults) => {
          console.info('Start: Payments created callback');
          setPaymentTraits();
          setIsResolvingFailedPayments.off();
          setPaymentIntents(
            paymentResults?.failedPaymentIntents.map((schedulePaymentIntent) => schedulePaymentIntent.paymentIntent)
          );
          if (paymentResults?.containsSuccessfulPaymentsScheduling) {
            goToStep('PAYMENT_SCHEDULED');
          } else {
            goToStep('PAYMENT_INTENTS_TABLE');
          }
          console.info('End: Payments created callback');
        })
        .catch(handleFail);
    };

    const handleCompleteLegalInfoDone = () => {
      handleConfirm();
    };

    const handleSetInvoiceSuccess = (invoiceNumber?: string) => {
      setPaymentIntents((current) =>
        current?.map((paymentIntent) => {
          if (paymentIntent.id !== selectedPaymentIntentId) {
            return paymentIntent;
          }
          return {
            ...paymentIntent,
            billInfo: {
              ...paymentIntent.billInfo,
              invoice: { ...paymentIntent.billInfo.invoice, number: invoiceNumber },
            },
          };
        })
      );
      if (selectedPaymentIntentId && paymentIntent) {
        onUpdateSinglePaymentIntent(selectedPaymentIntentId, {
          fundingSourceId: paymentIntent.fundingSourceId,
        });
      }
    };

    const onUpdateSingleDeliveryPreferenceType = (
      paymentIntentId: PaymentIntent['id'],
      type: DeliveryPreference['type']
    ) => {
      if (isByDeliveryDate) {
        const paymentIntentWithDerivatives = paymentIntentsWithDerivatives?.find(
          (paymentIntentWithDerivatives) => paymentIntentWithDerivatives.paymentIntent.id === paymentIntentId
        );

        if (!paymentIntentWithDerivatives) {
          return;
        }

        const deliveryPreference = paymentIntentWithDerivatives.paymentIntent.deliveryPreferenceOptions?.find(
          (option) => option.type === type
        );

        if (!deliveryPreference) {
          return;
        }

        setSelectedDeliveryDateHeaderCellOption(DeliveryDateHeaderCellOption.Multiple);

        const dataToUpdate = getUpdateSingleDeliveryPreferenceUpdateData(deliveryPreference);

        return onUpdateSinglePaymentIntent(paymentIntentId, dataToUpdate);
      }

      return onUpdateSinglePaymentIntent(paymentIntentId, { selectedDeliveryPreferenceType: type });
    };

    const onUpdateSingleDeliveryDate = (paymentIntentId: PaymentIntent['id'], date: Date) => {
      setSelectedDeliveryDateHeaderCellOption(DeliveryDateHeaderCellOption.Multiple);
      return onUpdateSinglePaymentIntent(paymentIntentId, { deliveryDate: date }, true);
    };

    if (!isDoneLoading) {
      return <PaymentIntentsTableLoadingScreen onBack={goToPreviousStep} />;
    }

    if (isLoading) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    switch (currentStep.step) {
      case 'PAYMENT_INTENTS_TABLE':
      default: {
        const selectedSchedulePaymentIntent = paymentIntentsWithDerivatives?.find(
          ({ paymentIntent }) => paymentIntent.id === selectedPaymentIntentId
        );

        const { deliveryMethodId } = selectedSchedulePaymentIntent?.paymentIntent || {};

        const deliveryMethod = selectedSchedulePaymentIntent?.vendor?.deliveryMethods.find(
          (deliveryMethod) => deliveryMethod.id === deliveryMethodId
        );

        const isFx = deliveryMethod && isFxDM(deliveryMethod);

        const shouldDisplayMemoToVendor = !isPaymentProcessedByCapitalOne(
          selectedSchedulePaymentIntent?.paymentIntent.deliveryMethod?.type
        );

        const hasMultipleBills =
          selectedSchedulePaymentIntent?.paymentIntent.billPayments &&
          selectedSchedulePaymentIntent.paymentIntent.billPayments.length > 1;

        const memoValidation = isFx
          ? {
              memoRegex: fxMemoRegex,
              memoErrorMsg: formatMessage('widgets.memoToVendorForm.fields.memo.validation.fx.pattern'),
            }
          : void 0;

        return (
          <>
            <PaymentIntentsTableScreen
              ref={triggerMonitoring}
              isToggling={isToggling}
              isUpdating={paymentIntentCollection.isMutating}
              paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
              immediatelyShowStatus={paymentResults.allFailed || isResolvingFailedPayments}
              onOpenReconciliationModal={onOpenReconciliationModal}
              onOpenPaymentPurposeModal={onOpenPaymentPurposeModal}
              onOpenVendorDetailsModal={onOpenVendorDetailsModal}
              onOpenInvoiceAttachmentModal={onOpenInvoiceAttachmentModal}
              fundingSources={fundingSources}
              orgBillingFeeSettings={orgBillingFeeSettings}
              onViewBillDetailsClick={onViewBillDetailsClick}
              onAddMemoToVendorClick={onAddMemoToVendorClick}
              onEditAmountClick={onEditAmountClick}
              onSetInvoiceNumberClick={onSetInvoiceNumberClick}
              onRemoveBillsClick={onRemoveBillsClick}
              onAddFundingSourceClick={onAddFundingSourceClick}
              onAddDeliveryMethodClick={onAddDeliveryMethodClick}
              onUpdateSingleDeliveryPreferenceType={(paymentIntentId, type) =>
                onUpdateSingleDeliveryPreferenceType(paymentIntentId, type)
              }
              onUpdateSingleFundingSource={(paymentIntentId, fundingSourceId) => {
                setShouldDisplayVendorDetailsFailureBanner.off();
                onUpdateSinglePaymentIntent(paymentIntentId, { fundingSourceId });
              }}
              onUpdateSingleDeliveryMethod={(paymentIntentId, deliveryMethodId) =>
                onUpdateSinglePaymentIntent(paymentIntentId, { deliveryMethodId })
              }
              onUpdateSingleScheduledDate={(paymentIntentId, date) => {
                if (isByDueDate) {
                  setIgnoreIsByDueDate([...ignoreIsByDueDate, paymentIntentId]);
                }
                return onUpdateSinglePaymentIntent(paymentIntentId, { scheduledDate: date }, true);
              }}
              onUpdateSingleDeliveryDate={(paymentIntentId, date) => onUpdateSingleDeliveryDate(paymentIntentId, date)}
              isByDueDate={isByDueDate && !ignoreIsByDueDate.length}
              selectedDeliveryDateHeaderCellOption={selectedDeliveryDateHeaderCellOption}
              onBack={goToPreviousStep}
              onDone={() => {
                if (
                  vendors &&
                  shouldCollectLegalInfoOnPaymentFlow({
                    vendors,
                    missingKycComplianceInformation,
                    missingCompanyLegalInfoFields: missingCompanyLegalInfoFields || [],
                  })
                ) {
                  goToStep('COMPLETE_LEGAL_INFO');
                } else {
                  handleConfirm();
                }
              }}
              loadingRowIds={loadingRowIds}
              onUpdateAllFundingSources={onUpdateAllFundingSources}
              onUpdateAllDeductionDatesToTheSameDate={(scheduledDate) => {
                setIsByDueDate(false);
                return onUpdateAllWithTheSameData({ scheduledDate }, true);
              }}
              onUpdateAllDeductionDatesToTheEarliestDate={() =>
                onUpdatePaymentIntentsToTheEarliestDate(paymentIntentsWithDerivatives)
              }
              onUpdateAllDeductionDatesToArriveByDueDate={() => {
                setIsByDueDate(true);
                setIgnoreIsByDueDate([]);
                return onUpdatePaymentIntentsToArriveByDueDate(paymentIntentsWithDerivatives);
              }}
              onUpdateAllDeliveryDatesToTheDueDate={() =>
                onUpdatePaymentIntentsToTheDueDate(paymentIntentsWithDerivatives)
              }
              arePaymentsCombined={arePaymentsCombined}
              onToggleCombinedPayments={toggleCombinedPayments}
              shouldDisplayVendorDetailsFailureBanner={shouldDisplayVendorDetailsFailureBanner}
            />
            {selectedSchedulePaymentIntent && (
              <>
                {shouldDisplayMemoToVendor && (
                  <MemoToVendorModalActivity
                    isOpen={isMemoToVendorModalOpen}
                    memo={selectedSchedulePaymentIntent.paymentIntent.noteToVendor || void 0}
                    defaultMemo={getDefaultMemo(selectedSchedulePaymentIntent)}
                    vendorId={selectedSchedulePaymentIntent.vendor.id}
                    onClose={() => setIsMemoToVendorModalOpen(false)}
                    onError={onError}
                    onDone={(memo) =>
                      onUpdateSinglePaymentIntent(selectedSchedulePaymentIntent.paymentIntent.id, {
                        noteToVendor: memo,
                      }).then(() => setIsMemoToVendorModalOpen(false))
                    }
                    isUpdatingMemo={loadingRowIds?.includes(selectedSchedulePaymentIntent.paymentIntent.id)}
                    memoValidation={memoValidation}
                  />
                )}
                {selectedBill && isEditAmountEnabled && (
                  <EditPaymentAmountModalActivity
                    isOpen={isEditPaymentAmountModalOpen}
                    onClose={() => setIsEditPaymentAmountModalOpen(false)}
                    onDone={(amount) => {
                      if (!amount) {
                        return;
                      }
                      const billPayments = selectedSchedulePaymentIntent.paymentIntent.billPayments ?? [];
                      selectedBill.balance = amount;
                      onUpdateSinglePaymentIntent(selectedSchedulePaymentIntent.paymentIntent.id, {
                        billPayments: billPayments.map((billPayment) => {
                          if (billPayment.billId === selectedBill.id) {
                            return { ...billPayment, amount };
                          } else {
                            return billPayment;
                          }
                        }),
                      }).then(() => setIsEditPaymentAmountModalOpen(false));
                    }}
                    bill={selectedBill}
                    isUpdatingAmount={loadingRowIds?.includes(selectedSchedulePaymentIntent.paymentIntent.id)}
                  />
                )}
                <SetInvoiceNumberModalActivity
                  isOpen={isSetInvoiceNumberModalOpen}
                  onClose={() => setIsSetInvoiceNumberModalOpen(false)}
                  billId={selectedSchedulePaymentIntent.paymentIntent.billInfo?.id}
                  onSuccess={handleSetInvoiceSuccess}
                />
                {selectedBill && (
                  <BillDetailsDrawerScreen
                    vendor={selectedSchedulePaymentIntent.vendor}
                    isOpen={isBillDetailsDrawerOpen}
                    bill={selectedBill}
                    onClose={() => setIsBillDetailsDrawerOpen(false)}
                  />
                )}
                <VendorDetailsModalActivity
                  vendorDetailsModalState={vendorDetailsModalState}
                  onDone={onCloseVendorDetailsModal}
                  onClose={onCloseVendorDetailsModal}
                  vendorId={selectedSchedulePaymentIntent.vendor.id}
                  onLoadingVendorDetailsFailure={setShouldDisplayVendorDetailsFailureBanner.on}
                />
                {selectedSchedulePaymentIntent.paymentIntent.fundingSourceId && (
                  <ReconciliationModalActivity
                    isOpen={isReconciliationModalOpen}
                    onDone={onDoneFundingSourceReconciliation}
                    onClose={openReconciliationModal.off}
                    selectedFundingSourceId={selectedSchedulePaymentIntent.paymentIntent.fundingSourceId}
                  />
                )}
                <InternationalPaymentPurposeActivity
                  variation="modalScreen"
                  isOpen={isPaymentPurposeModalOpen}
                  onClose={openPaymentPurposeModal.off}
                  onDone={(data: PaymentPurposeUpdateData) =>
                    onDonePaymentPurpose(selectedSchedulePaymentIntent.paymentIntent.id, data)
                  }
                  isSaving={loadingRowIds?.includes(selectedSchedulePaymentIntent.paymentIntent.id) || false}
                  vendorId={selectedSchedulePaymentIntent.vendor.id}
                  hasInvoice={hasMultipleBills || !!selectedSchedulePaymentIntent.paymentIntent.fileId}
                  internationalCountryCode={
                    deliveryMethod?.type === 'international-account'
                      ? deliveryMethod?.details.identifierDetails.bankCountryCode
                      : undefined
                  }
                />
                {invoiceBillDetails && (
                  <InvoiceAttachmentActivity
                    isOpen={isInvoiceAttachmentModalOpen}
                    onClose={openInvoiceAttachmentModal.off}
                    onDone={onDoneInvoiceAttachmentModel}
                    paymentIntentId={selectedSchedulePaymentIntent.paymentIntent?.id}
                    billDetails={invoiceBillDetails}
                  />
                )}
              </>
            )}
          </>
        );
      }

      case 'LOADING': {
        return <NewSinglePaymentStepLayout isLoading />;
      }

      case 'COMPLETE_LEGAL_INFO': {
        return (
          <CompleteLegalInfoActivity
            onBack={goToPreviousStep}
            onError={onError}
            onClose={onFirstStepBack}
            onDone={handleCompleteLegalInfoDone}
            missingKycComplianceInformation={missingKycComplianceInformation}
            setKycComplianceFormState={setKycComplianceFormState}
          />
        );
      }

      case 'PAYMENT_SCHEDULED': {
        if (!isResolvingFailedPayments && paymentResults.containsSuccessfulPaymentsScheduling) {
          return (
            <PaymentScheduledActivity
              paymentsIds={paymentResults.confirmedPaymentIds}
              {...(shouldShowIndicationForFailedPaymentsScheduling && {
                failedPaymentsSchedulingCount: paymentResults.failedPaymentIntents.length,
              })}
              onError={onError}
              onDone={(action: PaymentFlowDoneAction) => {
                if (action === 'resolveFailedPayments') {
                  goToStep('PAYMENT_INTENTS_TABLE');
                  setIsResolvingFailedPayments.on();
                  return;
                }
                onDone(paymentResults.confirmedPaymentIds || [], action);
              }}
              externalOrigin={externalOrigin}
            />
          );
        }
        return <PaymentIntentsTableLoadingScreen onBack={goToPreviousStep} />;
      }

      case 'FIRST_CREATE_FUNDING_SOURCE':
      case 'CREATE_FUNDING_SOURCE': {
        const selectedSchedulePaymentIntent = paymentIntentsWithDerivatives?.find(
          ({ paymentIntent }) => paymentIntent.id === selectedPaymentIntentId
        );

        const fundingSourceTypesOptions =
          selectedSchedulePaymentIntent?.paymentIntent.fundingSourceTypesOptions ||
          paymentIntentsWithDerivatives?.[0]?.paymentIntent.fundingSourceTypesOptions;

        return (
          <CreateFundingSourceActivity
            onClose={goToPreviousStep}
            onError={onError}
            onDone={(arg: FundingSource | DeliveryMethod) => {
              const fundingSource = arg as FundingSource;
              void onUpdateAllFundingSources(fundingSource);
              goToStep('PAYMENT_INTENTS_TABLE');
            }}
            fundingSourceTypesOptions={fundingSourceTypesOptions?.map(fsTypesOptionsToFundingSourceTypesOptions)}
          />
        );
      }

      case 'CREATE_DELIVERY_METHOD': {
        const selectedSchedulePaymentIntent = paymentIntentsWithDerivatives?.find(
          ({ paymentIntent }) => paymentIntent.id === selectedPaymentIntentId
        );

        if (selectedSchedulePaymentIntent) {
          const {
            paymentIntent: { fundingSourceId, deliveryMethodId, deliveryMethodTypeOptions, fileId, billInfo },
            vendor,
          } = selectedSchedulePaymentIntent;

          return (
            <DeliveryMethodSelectionActivity
              fundingSourceId={fundingSourceId || void 0}
              selectedId={deliveryMethodId || void 0}
              vendorId={vendor.id}
              onClose={() => goToStep('PAYMENT_INTENTS_TABLE')}
              onBack={() => goToStep('PAYMENT_INTENTS_TABLE')}
              onError={onError}
              onDone={(deliveryMethod) => {
                void refetchVendors();
                void updateVendorPaymentIntentsDeliveryMethod(vendor, deliveryMethod);
                goToStep('PAYMENT_INTENTS_TABLE');
              }}
              origin="batch"
              billCurrency={billInfo.currency}
              deliveryMethodTypeOptions={deliveryMethodTypeOptions}
              fileId={fileId || billInfo.invoice?.fileId}
            />
          );
        }
        return <PaymentIntentsTableLoadingScreen onBack={goToPreviousStep} />;
      }

      case 'ADD_ACH_INTEGRATION_SELECTION': {
        return (
          <AddBankAccountActivity
            onBack={() => goToStep('PAYMENT_INTENTS_TABLE')}
            onClose={() => goToStep('PAYMENT_INTENTS_TABLE')}
            onDone={(fundingSource: FundingSource | DeliveryMethod) => {
              void onUpdateAllFundingSources(fundingSource as FundingSource);
              goToStep('PAYMENT_INTENTS_TABLE');
            }}
          />
        );
      }
    }
  }
);
