import { usePaymentActions } from '@melio/ap-domain';
import { NakedButton, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BillSubscriptionApprovalDecisionStatusEnum, Payment, PaymentStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  payment: Payment;
  onViewClick: (id: Payment['id']) => void;
};

export const PaymentsTabActionButtonCell = ({ payment, onViewClick }: Props) => {
  const { label, handleActionClick } = useAction({ payment, onViewClick });

  const onClick = () => {
    handleActionClick();
  };

  return (
    <Table.Cell textAlign="end">
      <NakedButton
        variant="secondary"
        size="medium"
        data-testid={`payments-tab-action-cell-${payment.id}`}
        onClick={onClick}
        label={label}
      />
    </Table.Cell>
  );
};

const useAction = ({
  payment,
  onViewClick,
}: {
  payment: Payment;
  onViewClick: (id: Payment['id']) => void;
}): { label: string; handleActionClick: VoidFunction } => {
  const { formatMessage } = useMelioIntl();
  const allowedActions = usePaymentActions(payment);
  const { track } = useAnalytics();

  const isPaymentRelatedToPendingApprovalBillSubscription =
    payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Pending;

  if (allowedActions.actions.approvalDecision) {
    return {
      label: isPaymentRelatedToPendingApprovalBillSubscription
        ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approveAll')
        : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approve'),
      handleActionClick: () => {
        track('Dashboard', 'Click', {
          Cta: 'approve',
          Intent: 'approve-payment',
        });
        onViewClick(payment.id);
      },
    };
  } else if (payment.status === PaymentStatusEnum.Failed) {
    const isResolveCTA = allowedActions.actions.resolveFailedToCollect || allowedActions.actions.resolveFailedToDeliver;
    if (isResolveCTA) {
      return {
        label: formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.resolve'),
        handleActionClick: () => {
          track('Dashboard', 'Click', {
            Cta: 'resolve',
            Intent: 'view-payment',
          });
          onViewClick(payment.id);
        },
      };
    } else {
      return {
        label: formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.review'),
        handleActionClick: () => {
          track('Dashboard', 'Click', {
            Cta: 'review',
            Intent: 'view-payment',
          });
          onViewClick(payment.id);
        },
      };
    }
  }
  return {
    label: payment.markedAsPaid
      ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view.details')
      : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view'),
    handleActionClick: () => {
      track('Dashboard', 'Click', {
        Cta: 'view-payment',
        Intent: 'view-payment',
      });
      onViewClick(payment.id);
    },
  };
};
