import { useMelioIntl } from '@melio/ar-domain';
import { Container, Form, Group, Icon, ListItem } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { useInvoiceFormContext } from '../../../utils';

type PayOptionCardProps = {
  payOptionType: 'bankTransfer' | 'card';
  isDisabled?: boolean;
};
const FieldName = {
  bankTransfer: `isAchAllowed`,
  card: `isCardAllowed`,
} as const;

export const PayOptionCard = forwardRef<PayOptionCardProps>(({ payOptionType, isDisabled }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { registerField } = useInvoiceFormContext();

  return (
    <Container backgroundColor="default" border="regular" paddingX="m" paddingY="m" width="full" ref={ref}>
      <Group>
        <Group.Item shrink={0}>
          <Container width="fit-content" alignItems="center" height="full">
            <Icon size="large" type="bank" />
          </Container>
        </Group.Item>
        <Group.Item grow={1}>
          <Container>
            <ListItem
              descriptionProps={{
                label: formatMessage(
                  `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.${payOptionType}.description.text`
                ),
              }}
              mainLabelProps={{
                label: formatMessage(
                  `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.${payOptionType}.title.text`
                ),
              }}
            />
          </Container>
        </Group.Item>
        <Group.Item shrink={0}>
          <Container width="fit-content" alignItems="center" height="full">
            <Form.Checkbox
              isChecked={isDisabled ? false : undefined}
              isDisabled={isDisabled}
              aria-label={formatMessage(
                `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.${payOptionType}.title.text`
              )}
              {...registerField(FieldName[payOptionType])}
            />
          </Container>
        </Group.Item>
      </Group>
    </Container>
  );
});
