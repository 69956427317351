import { Bill, useBills, usePaginatedVendors } from '@melio/platform-api';

import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { usePayDashboardPagination } from '../Pagination';
import { convertVendorsTabSortToApiSort } from './utils';

type UseVendorsTabDataResult = ReturnType<typeof usePaginatedVendors> & { unpaidBills: Bill[] };

export const useVendorsTabData = ({
  searchTerm,
  enabled = true,
}: {
  searchTerm: string | null;
  enabled?: boolean;
}): UseVendorsTabDataResult => {
  const { pageSize } = usePayDashboardPagination();
  const sorting = usePayDashboardSorting();

  const paginationResponse = usePaginatedVendors({
    cacheTime: 0,
    params: {
      ...(searchTerm ? { searchTerm } : {}),
      expand: 'lastPaymentDate',
      limit: pageSize,
      sort: sorting?.sortingState ? convertVendorsTabSortToApiSort(sorting.sortingState) : undefined,
    },
    enabled,
  });
  const { data: vendorsResult, isLoading: isLoadingVendors } = paginationResponse;
  const vendors = vendorsResult?.data ?? [];

  const { data: unpaidBills = [], isLoading: isLoadingBills } = useBills({
    cacheTime: 0,
    enabled: enabled ? vendors.length > 0 : false,
    params: { search: { 'vendor.id': vendors.map(({ id }) => id), 'bill.balance': { $gt: 0 } }, expand: 'none' },
  });

  return {
    ...paginationResponse,
    isLoading: isLoadingVendors || isLoadingBills,
    unpaidBills,
  } as UseVendorsTabDataResult;
};
