import { ARCustomerTypeEnum, useCustomer } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { CustomerFormFields } from './components';
import { UpdateCustomerScreen } from './screens';

type UpdateCustomerActivityProps = {
  onClose: VoidFunction;
  onDone: (customerId: string) => void;
  isOpen: boolean;
  onError?: ARErrorFunction;
  customerId: string;
};

export const UpdateCustomerActivity = withAnalyticsContext<UpdateCustomerActivityProps>(
  forwardRef(({ setAnalyticsProperties, onDone, onError: _onError, customerId, onClose, ...props }, ref) => {
    const { data: customer, isLoading, update, isMutating } = useCustomer({ id: customerId });
    const [error, setError] = useState<ARPlatformError>();

    setAnalyticsProperties({ CustomerId: customerId });
    const { track } = useAnalytics();

    const onError = (err: ARPlatformError) => [setError, _onError].forEach((fn) => fn?.(err));

    const isSupportedCustomerType =
      customer?.type === ARCustomerTypeEnum.Consumer || customer?.type === ARCustomerTypeEnum.Business;
    const currentCustomerType =
      customer?.type && isSupportedCustomerType ? (customer.type as ARCustomerTypeEnum) : ARCustomerTypeEnum.Business;

    const handleSubmit = ({ customerName, email, customerType, phone }: CustomerFormFields) => {
      track('Customer', 'Click', {
        ProductName: 'ar',
        PageName: 'edit-customer-details',
        Flow: 'edit-customer',
        Intent: 'save-customer-details',
        Cta: 'save',
      });
      update({
        companyName: customerName,
        fullName: customerName,
        email,
        type: customerType,
        phone: customerType === ARCustomerTypeEnum.Consumer ? phone : undefined,
      })
        .then((customer) => {
          track('Customer', 'Status', {
            StatusType: 'success',
            ProductName: 'ar',
            PageName: 'edit-customer-details',
            Flow: 'edit-customer',
            Intent: 'save-customer-details',
            Cta: 'save',
          });
          onDone(customer.id);
        })
        .catch((err: ARPlatformError) => {
          track('Customer', 'Status', {
            StatusType: 'failure',
            ProductName: 'ar',
            PageName: 'edit-customer-details',
            Flow: 'edit-customer',
            Intent: 'save-customer-details',
            Cta: 'save',
          });
          onError(err);
        });
    };

    return (
      <UpdateCustomerScreen
        defaultValues={{
          customerName: customer?.companyName || '',
          email: customer?.email || '',
          customerType: currentCustomerType,
          phone: customer?.phone || '',
        }}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        isSaving={isMutating}
        error={error}
        ref={ref}
        onClose={() => {
          track('Customer', 'Click', {
            ProductName: 'ar',
            PageName: 'edit-customer-details',
            Flow: 'edit-customer',
            Intent: 'exit-flow',
            Cta: 'cancel/close',
          });
          onClose();
        }}
        {...props}
      />
    );
  })
);
UpdateCustomerActivity.displayName = 'UpdateCustomerActivity';
