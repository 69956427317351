import { useFundingSourceIconOrImage } from '@melio/ap-domain';
import { Group, Link, Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePartnerGroups } from '../../../hooks';
import { SectionSettingCard } from '../SectionSettingCard';
import { useSubscriptionPaymentMethodDisplayText } from './useSubscriptionPaymentMethodDisaplyText';

type Props = {
  fundingSource: FundingSource;
  onChange?: () => void;
  onSelect?: () => void;
  isSelected?: boolean;
};

export const SubscriptionPaymentMethodCard = ({ fundingSource, onChange, onSelect, isSelected }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { title, description } = useSubscriptionPaymentMethodDisplayText(fundingSource);
  const fundingSourceIcon = useFundingSourceIconOrImage(fundingSource);
  const { isFiservPartner } = usePartnerGroups();

  const shouldUseBoldLink = !!isFiservPartner;

  const onChangeHandler = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    onChange?.();
  };

  return (
    <SectionSettingCard data-testid="subscription-payment-method-card" isSelected={isSelected} onClick={onSelect}>
      <Group width="full" justifyContent="space-between" alignItems="flex-start">
        <Group alignItems="center" spacing="m">
          {fundingSourceIcon}
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle="body2Semi" color="neutral.black" data-testid="subscription-payment-method-card-title">
              {title}
            </Text>
            <Text textStyle="body4" color="neutral.darker" data-testid="subscription-payment-method-card-description">
              {description}
            </Text>
          </Group>
        </Group>

        {onChange && (
          <Text textStyle={shouldUseBoldLink ? 'body3Semi' : 'body3'}>
            <Link
              data-testid="subscription-payment-method-card-change-button"
              href="#"
              onClick={onChangeHandler}
              label={formatMessage('activities.settings.subscriptionPaymentMethod.card.change')}
              variant="inline"
              color="secondary"
            />
          </Text>
        )}
      </Group>
    </SectionSettingCard>
  );
};
