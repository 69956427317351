import { Box } from '@chakra-ui/react';
import { OrganizationSwitcherWidget, OrganizationSwitcherWidgetsProps } from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import {
  isAccountingFirmOrganization,
  useAccountWithLogoUrl,
  useIsMemberOfAccountingFirm,
  useOrganizationsWithLogoUrl,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { useRouter } from '@/hooks/router.hooks';
import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';

export const OrganizationSelectWidget = () => {
  const { track } = useAnalytics();
  const { switchOrganization } = useSwitchOrganization();
  const [isAddCompanyEnabled] = useFeature(FeatureFlags.AddCompanyEnabled, false);
  const isUserMemberOfAccountingFirm = useIsMemberOfAccountingFirm();
  const shouldShowAddCompanyButton = isAddCompanyEnabled && !isUserMemberOfAccountingFirm;
  const { goToAddNewCompany } = useRouter();
  const { data: accountWithLogo, isFetching: isAccountWithLogoLoading } = useAccountWithLogoUrl({ id: 'me' });
  const { data: organizations = [], isLoading: isOrganizationsLoading } = useOrganizationsWithLogoUrl();

  const onSelectHandler: OrganizationSwitcherWidgetsProps['onSelect'] = async (organizationId) => {
    const alreadySelected = organizationId === accountWithLogo?.organizationId;
    track('SwitchCompanyFromCompanyBar', 'Chose', { alreadySelected });
    if (alreadySelected) {
      return;
    }
    const targetOrganization = organizations.find(({ id }) => id === organizationId)!;
    switchOrganization({
      organizationId: targetOrganization.id,
      isAccountingFirm: isAccountingFirmOrganization(targetOrganization),
    });
  };

  const onAddNewCompany = () => {
    goToAddNewCompany({ entryPoint: 'companies-dropdown' });
  };

  return (
    <Box>
      {!isAccountWithLogoLoading && !isOrganizationsLoading && accountWithLogo && (
        <OrganizationSwitcherWidget
          selectedId={accountWithLogo.organizationId}
          organizations={organizations}
          onSelect={onSelectHandler}
          onAddCompany={onAddNewCompany}
          shouldShowAddCompanyButton={shouldShowAddCompanyButton}
        />
      )}
    </Box>
  );
};
