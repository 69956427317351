import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BillingIcon = (props: IconProps) => (
  <Icon width="20px" height="24px" viewBox="0 0 20 24" fill="currentColor" {...props}>
    <path
      d="M10 8.99973C10.5523 8.99973 11 9.44744 11 9.99973C11 10.5126 10.614 10.9352 10.1166 10.993L10 10.9997H5C4.44772 10.9997 4 10.552 4 9.99973C4 9.48689 4.38604 9.06422 4.88338 9.00646L5 8.99973H10Z"
      fill="#212124"
    />
    <path
      d="M16 9.99973C16 9.44744 15.5523 8.99973 15 8.99973H14L13.8834 9.00646C13.386 9.06422 13 9.48689 13 9.99973C13 10.552 13.4477 10.9997 14 10.9997H15L15.1166 10.993C15.614 10.9352 16 10.5126 16 9.99973Z"
      fill="#212124"
    />
    <path
      d="M10 12.9997C10.5523 12.9997 11 13.4474 11 13.9997C11 14.5126 10.614 14.9352 10.1166 14.993L10 14.9997H5C4.44772 14.9997 4 14.552 4 13.9997C4 13.4869 4.38604 13.0642 4.88338 13.0065L5 12.9997H10Z"
      fill="#212124"
    />
    <path
      d="M16 13.9997C16 13.4474 15.5523 12.9997 15 12.9997H14L13.8834 13.0065C13.386 13.0642 13 13.4869 13 13.9997C13 14.552 13.4477 14.9997 14 14.9997H15L15.1166 14.993C15.614 14.9352 16 14.5126 16 13.9997Z"
      fill="#212124"
    />
    <path
      d="M10 16.9997C10.5523 16.9997 11 17.4474 11 17.9997C11 18.5126 10.614 18.9352 10.1166 18.993L10 18.9997H5C4.44772 18.9997 4 18.552 4 17.9997C4 17.4869 4.38604 17.0642 4.88338 17.0065L5 16.9997H10Z"
      fill="#212124"
    />
    <path
      d="M16 17.9997C16 17.4474 15.5523 16.9997 15 16.9997H14L13.8834 17.0065C13.386 17.0642 13 17.4869 13 17.9997C13 18.552 13.4477 18.9997 14 18.9997H15L15.1166 18.993C15.614 18.9352 16 18.5126 16 17.9997Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 22.9997V1.99973C0 1.20103 0.890146 0.724642 1.5547 1.16768L4 2.79673L6.4453 1.16768C6.74388 0.968626 7.12281 0.94651 7.43925 1.10133L7.5547 1.16768L10 2.79673L12.4453 1.16768C12.7439 0.968626 13.1228 0.94651 13.4392 1.10133L13.5547 1.16768L16 2.79673L18.4453 1.16768C19.0782 0.745738 19.9157 1.15775 19.9941 1.88789L20 1.99973V22.9997C20 23.552 19.5523 23.9997 19 23.9997H1C0.447715 23.9997 0 23.552 0 22.9997ZM15.4453 4.83178L13 3.20173L10.5547 4.83178C10.2188 5.05571 9.7812 5.05571 9.4453 4.83178L7 3.20173L4.5547 4.83178C4.25612 5.03083 3.87719 5.05295 3.56075 4.89813L3.4453 4.83178L2 3.86873V21.9997H18V3.86773L16.5547 4.83178C16.2561 5.03083 15.8772 5.05295 15.5608 4.89813L15.4453 4.83178Z"
      fill="#212124"
    />
  </Icon>
);
