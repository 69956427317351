import { Form, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../../types';

export function FrequencySelector({
  form,
  isDisabled,
  disabledText,
  onChange,
}: {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  isDisabled: boolean;
  disabledText: {
    oneTime?: string;
    recurring?: string;
  };
  onChange: PaymentFlowOnChangeHandlers['onRecurrenceTypeChange'];
}) {
  const { formatMessage } = useMelioIntl();
  const { registerField } = form;

  return (
    <Form.RadioGroup
      {...registerField('recurrenceType')}
      labelProps={{ label: formatMessage('activities.paymentFlow.form.content.recurring.recurrenceType.label') }}
      variant="horizontal"
      options={[
        {
          value: 'one_time',
          mainLabelProps: {
            label: formatMessage('activities.paymentFlow.form.content.recurring.recurrenceType.options.one_time'),
          },
          disabled: disabledText?.oneTime ? { isDisabled: true, message: disabledText.oneTime } : undefined,
          ariaLabel: formatMessage(
            'activities.paymentFlow.form.content.recurring.recurrenceType.options.one_time.ariaLabel'
          ),
        },
        {
          value: 'recurring',
          mainLabelProps: {
            label: formatMessage('activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring'),
          },
          disabled: disabledText?.recurring ? { isDisabled: true, message: disabledText.recurring } : undefined,
          ariaLabel: formatMessage(
            'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.ariaLabel'
          ),
        },
      ]}
      isDisabled={isDisabled}
      onChange={(e) => onChange(e.target.value as PaymentFlowFormFields['recurrenceType'])}
    />
  );
}
