import { Company } from '@melio/platform-api';

export const hasRequiredAddressFields = (address: Partial<Company['address']> | null): boolean => {
  if (!address) {
    return false;
  }
  const { line1, city, state, postalCode } = address;
  return !!(line1 && city && state && postalCode);
};

export const isPOBox = (addressLine1?: string | null): boolean => {
  const POBoxRegex =
    /^((unit|#)?\s?(num|number|#)?\s\d+)|(((p[\s\\.]?[o\s][\\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/;
  return addressLine1 ? POBoxRegex.test(addressLine1.toLowerCase()) : false;
};
