import { Box } from '@chakra-ui/react';
import { Group, Icon, Table, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const ApprovalLimitHeaderCell = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Table.HeaderCell>
      <Group alignItems="center" textAlign="center" spacing="xxs">
        {formatMessage('activities.settings.collaborators.table.headers.approvalLimit')}
        <Tooltip
          label={formatMessage('activities.settings.collaborators.table.headers.approvalLimit.tooltip.content')}
          data-testid="table-header-cell-approvalLimit-tooltip"
        >
          <Box
            as={Icon}
            type="info"
            size="small"
            data-testid="table-header-cell-approvalLimit-icon"
            tabIndex={0}
            aria-label={formatMessage('activities.settings.collaborators.table.headers.approvalLimit.tooltip.content')}
            role="tooltip"
          />
        </Tooltip>
      </Group>
    </Table.HeaderCell>
  );
};
