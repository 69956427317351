import { Group } from '@melio/penny';
import { ScannedInvoice } from '@melio/platform-api';
import React from 'react';

import { ScannedInvoiceDetailsWidget } from './ScannedInvoiceDetails.widget';
import { ScannedInvoiceEmailDetails } from './ScannedInvoiceEmailDetails.widget';

type ScannedInvoiceDetailsProps = {
  scannedInvoice: ScannedInvoice;
  onReviewScannedInvoice?: () => void;
};

export const ScannedInvoiceDetailsBody = ({ scannedInvoice, onReviewScannedInvoice }: ScannedInvoiceDetailsProps) => (
  <Group variant="vertical" spacing="l" data-testid="scanned-invoice-details-body" hasDivider>
    <ScannedInvoiceDetailsWidget scannedInvoice={scannedInvoice} onReviewScannedInvoice={onReviewScannedInvoice} />
    {scannedInvoice.inboxEmail && <ScannedInvoiceEmailDetails inboxEmail={scannedInvoice.inboxEmail} />}
  </Group>
);
