import { useBillActions } from '@melio/ap-domain';
import { Link, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { InboxItemPaymentRequestTypeEnum, InboxItemScannedInvoiceTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { BillsTabItem } from '../types';
import { useBillsTabAriaLabelContextForVendor } from '../utils';

type BillsTabActionButtonInfo = {
  label: string;
  ariaLabel?: string;
  onClick: () => void;
  href?: string;
};

export type BillsTabActionButtonCellProps = {
  billsTabItem: BillsTabItem;
  onReviewScannedInvoiceClick: (billId: string) => void;
  onPayBillClick: (billId: string) => void;
  onPayPaymentRequest: (paymentRequestId: string) => void;
  onViewItemClick: ({ id }: { id: string }) => void;
  isDisabled: boolean;
};

export const BillsTabActionButtonCell = ({
  billsTabItem,
  isDisabled,
  onPayBillClick,
  onReviewScannedInvoiceClick,
  onPayPaymentRequest,
  onViewItemClick,
}: BillsTabActionButtonCellProps) => {
  const { label, ariaLabel, onClick } = useBillsTabActionButtonInfo({
    billsTabItem,
    onPayBillClick,
    onPayPaymentRequest,
    onReviewScannedInvoiceClick,
    onViewItemClick,
  });

  const handleClick: React.MouseEventHandler<HTMLLinkElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };

  return (
    <Table.Cell textAlign="end">
      <Link
        isBold
        variant="standalone"
        size="medium"
        onClick={handleClick}
        href="#"
        data-testid={`bills-tab-action-cell-${billsTabItem.payload.id}`}
        data-tourid={`bills-tab-action-cell-${billsTabItem.payload.id}`}
        color="secondary"
        isDisabled={isDisabled}
        label={label}
        aria-label={ariaLabel}
      />
    </Table.Cell>
  );
};

const useBillsTabActionButtonInfo = ({
  billsTabItem,
  onReviewScannedInvoiceClick,
  onPayBillClick,
  onPayPaymentRequest,
  onViewItemClick,
}: Pick<
  BillsTabActionButtonCellProps,
  'billsTabItem' | 'onReviewScannedInvoiceClick' | 'onPayBillClick' | 'onPayPaymentRequest' | 'onViewItemClick'
>): BillsTabActionButtonInfo => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { payload, type } = billsTabItem;
  const { getBillActions } = useBillActions();
  const context = useBillsTabAriaLabelContextForVendor(billsTabItem);

  if (type === InboxItemScannedInvoiceTypeEnum.ScannedInvoice) {
    return {
      label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.review'),
      onClick: () => {
        track('Dashboard', 'Click', {
          Intent: 'review-bill',
          Cta: 'review',
          billId: billsTabItem.payload.id,
        });
        onReviewScannedInvoiceClick(payload.id);
      },
    };
  } else if (type === InboxItemPaymentRequestTypeEnum.PaymentRequest) {
    return {
      label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.pay'),
      onClick: () => {
        track('Dashboard', 'Click', {
          Intent: 'pay-payment-request',
          Cta: 'pay',
          billId: billsTabItem.payload.id,
        });
        onPayPaymentRequest(payload.id);
      },
    };
  }

  const { actions: billActions } = getBillActions(payload);
  if (billActions.newPayment) {
    return {
      label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.pay'),
      ariaLabel: formatMessage('activities.payDashboard.billsTab.table.cells.actions.pay.ariaLabel', {
        context,
      }),
      onClick: () => {
        track('Dashboard', 'Click', {
          Intent: 'pay-bill',
          Cta: 'pay',
          billId: billsTabItem.payload.id,
        });
        onPayBillClick(payload.id);
      },
    };
  }

  return {
    label: formatMessage('activities.payDashboard.billsTab.table.cells.actions.view'),
    onClick: () => {
      track('Dashboard', 'Click', {
        Intent: 'view-bill',
        Cta: 'view-details',
        billId: billsTabItem.payload.id,
      });
      onViewItemClick({ id: payload.id });
    },
  };
};
