/* eslint-disable react-hooks/exhaustive-deps */
import { PlaidAccountData } from '@melio/platform-api';
import { useEffect } from 'react';

import { usePlaid } from './usePlaid';

export type AddPlaidAccountProps = {
  onSuccess: (data: PlaidAccountData) => void;
  onExit?: VoidFunction;
  onLoad?: VoidFunction;
  onError?: ErrorFunction;
  fundingSourceId?: string;
};

export const AddPlaidAccountWidget = ({
  onSuccess,
  onError,
  onLoad,
  onExit,
  fundingSourceId,
}: AddPlaidAccountProps) => {
  const { open, ready, error } = usePlaid({ onError, onSuccess, onLoad, onExit, fundingSourceId });

  useEffect(() => {
    if (ready) {
      open();
    }
    if (error) {
      onError?.({ message: error.message, code: '500' });
    }
  }, [ready, open, error]);

  return <></>;
};

AddPlaidAccountWidget.displayName = 'AddPlaidAccountWidget';
