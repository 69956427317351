import { _TableHeaderCellProps, Group, Icon, Table } from '@melio/penny';
import { VFC } from 'react';

import { Order, SortField } from '../../types';

export type SortableHeaderCellProps = {
  label: string;
  order?: Order;
  field?: SortField;
  selectedField?: SortField;
  textAlign?: _TableHeaderCellProps['textAlign'];
  onClick: (field: SortField, order: Order) => void;
};

export const SortableHeaderCell: VFC<SortableHeaderCellProps> = ({
  label,
  order,
  field,
  selectedField,
  onClick,
  textAlign,
  ...props
}) => {
  const isSameField = !!field && field === selectedField;
  const targetOrder = isSameField ? (order == 'Asc' ? 'Desc' : 'Asc') : 'Desc';
  return (
    <Table.HeaderCell
      data-component={SortableHeaderCell.displayName}
      data-testid={`sortable-header-cell-${field ?? ''}`}
      textAlign={textAlign}
      onClick={field && (() => onClick(field, targetOrder))}
      {...props}
    >
      <Group alignItems="center" spacing="xxs">
        {label}
        {isSameField && <Icon type={order === 'Desc' ? 'arrow-down' : 'arrow-up'} size="small" />}
      </Group>
    </Table.HeaderCell>
  );
};
SortableHeaderCell.displayName = 'SortableHeaderCell';
