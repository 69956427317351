import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { Button, Collapse, Container, Form, Group, NakedButton, Skeleton, Text, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
// eslint-disable-next-line no-restricted-imports
import { PromoCode } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useOneTimeEffect } from '@melio/platform-utils';
import { useState } from 'react';

import { SubscriptionPreview, usePromoCode } from '../../../../../../api';

type SubscriptionCheckoutPromoCodeProps = {
  promoCode?: string | null;
  isLoading: boolean;
  isPromoCodeFromQS: boolean;
  preview?: SubscriptionPreview;
  onChangePromoCode: (promoCode?: string) => void;
  onDoneFetchingPromoCodeData: () => void;
};
export const SubscriptionCheckoutPromoCode = ({
  promoCode,
  isPromoCodeFromQS,
  isLoading,
  preview,
  onChangePromoCode,
  onDoneFetchingPromoCodeData,
}: SubscriptionCheckoutPromoCodeProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [promoCodeData, setPromoCodeData] = useState<PromoCode | undefined>();
  const [isPromoCodeFetching, setIsPromoCodeFetching] = useState<boolean>(false);
  const [showPromoCodeInput, setShowPromoCodeInput] = useState<boolean>(!!promoCode);
  const [errorPromoCode, setErrorPromoCode] = useState<string | undefined>();
  const getPromoCode = usePromoCode();
  const { track } = useAnalytics();
  const { discounts } = preview || {};
  const discount = discounts?.[0]?.amount || 0;

  const updateData = (data: PromoCode) => {
    if (data.status === 'valid') {
      setPromoCodeData(data);
      onChangePromoCode(data.code);
    } else {
      setPromoCodeData(undefined);
      setErrorPromoCode(data.message);
      onChangePromoCode();
    }
  };
  const fetchPromoCode = (promoCode?: string | null) => {
    if (!promoCode) {
      return;
    }
    setIsPromoCodeFetching(true);
    getPromoCode(promoCode)
      .then(updateData)
      .catch(() => {
        setErrorPromoCode(formatMessage('activities.subscription.checkout.summary.promocode.notfound'));
        onChangePromoCode();
      })
      .finally(() => {
        setIsPromoCodeFetching(false);
        onDoneFetchingPromoCodeData();
      });
  };

  useOneTimeEffect(
    () => {
      fetchPromoCode(promoCode);
    },
    () => !!promoCode && !!isPromoCodeFromQS,
    [isPromoCodeFromQS, promoCode]
  );

  const { registerField, formProps, setValue } = useMelioForm({
    onSubmit: ({ promoCode }) => {
      fetchPromoCode(promoCode);
    },
    defaultValues: { promoCode: promoCode || '' },
  });

  const togglePromoCodeInput = () => {
    if (!showPromoCodeInput) {
      track('Organization', 'Click', {
        Intent: 'add-promo',
        Cta: 'add-promo-code',
      });
    }
    setShowPromoCodeInput((prev) => !prev);
  };
  const removePromoCode = () => {
    setErrorPromoCode(undefined);
    setShowPromoCodeInput(true);
    setPromoCodeData(undefined);

    onChangePromoCode();
    setValue('promoCode', '');
    track('Organization', 'Click', {
      Intent: 'remove-promo',
      Cta: 'remove-promo',
    });
  };

  if (isLoading || (isPromoCodeFetching && isPromoCodeFromQS)) {
    return (
      <>
        <Skeleton height="12px" />
        <Skeleton height="12px" />
      </>
    );
  }

  if (promoCodeData) {
    return (
      <Container>
        <Group justifyContent="space-between">
          <Tag size="md" borderRadius="full">
            <TagLabel data-testid="subscription-checkout-promocode">{promoCodeData.code}</TagLabel>
            <TagCloseButton onClick={removePromoCode} />
          </Tag>
          <Text color="success.main" textStyle="body3">
            {formatCurrency(discount)}
          </Text>
        </Group>

        {promoCodeData?.message && (
          <Container paddingTop="xs">
            <Text color="neutral.darker" textStyle="body4">
              {promoCodeData?.message}
            </Text>
          </Container>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <NakedButton
        label={formatMessage('activities.subscription.checkout.summary.promocode.label')}
        variant="secondary"
        data-testid="subscription-checkout-toggle-promocode"
        onClick={togglePromoCodeInput}
      />
      <Collapse in={showPromoCodeInput} data-testid="subscription-checkout-promocode-form">
        <Form paddingTop="xs" paddingLeft="xxs" paddingBottom="xxs" columns={3} gap="s" {...formProps}>
          <Form.TextField
            colSpan={2}
            {...registerField('promoCode')}
            aria-label={formatMessage('activities.subscription.checkout.summary.promocode.label')}
            size="small"
            error={(errorPromoCode && { message: errorPromoCode }) || undefined}
          />
          <Button
            label={formatMessage('activities.subscription.checkout.summary.promocode.submit')}
            onClick={(e) => {
              formProps.onSubmit(e);
              track('Organization', 'Click', {
                Intent: 'add-promo',
                Cta: 'apply',
              });
            }}
            isLoading={isPromoCodeFetching}
            data-testid="subscription-checkout-apply-promocode"
          />
        </Form>
      </Collapse>
    </Container>
  );
};
