import { useAccountingPlatforms, useUserPreference, useUserPreferences } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useEffect, useState } from 'react';

import {
  FISERV_DASHBOARD_TOUR_PREFERENCE_KEY,
  PAY_DASHBOARD_TOUR_PREFERENCE_KEY,
} from '../components/PayDashboardTour';
import { useIsSubscriptionPending } from './useIsSubscriptionPending';

const preferenceKey = 'payDashboardFirstTimeExperience';

export const useFirstTimeExperience = () => {
  const [isFirstTimeExperienceEnabled] = useFeature<boolean>(FeatureFlags.NpeFirstTimeExperience, false);
  const [isPayDashboardTourEnabled] = useFeature<boolean>(FeatureFlags.IsPayDashboardTourEnabled, false);
  const [isFiservDashboardTourEnabled] = useFeature<boolean>(FeatureFlags.IsFiservDashboardTourEnabled, false);
  const isSubscriptionPending = useIsSubscriptionPending();

  const { create } = useUserPreferences({ enabled: false });
  const { data, isLoading: isLoadingUserPreference } = useUserPreference({
    id: preferenceKey,
    enabled: isFirstTimeExperienceEnabled,
  });
  const { isLoading: isLoadingAccountingPlatforms } = useAccountingPlatforms();
  const [isFirstTimeExperience, setIsFirstTimeExperience] = useState(false);

  useEffect(() => {
    if (
      !isFirstTimeExperienceEnabled ||
      isLoadingAccountingPlatforms ||
      isLoadingUserPreference ||
      data ||
      isSubscriptionPending
    ) {
      return;
    }

    setIsFirstTimeExperience(true);
    create({ userPreference: { key: preferenceKey, value: 'true' } });
  }, [
    isLoadingUserPreference,
    isLoadingAccountingPlatforms,
    data,
    create,
    isFirstTimeExperienceEnabled,
    isSubscriptionPending,
  ]);

  const handleShowProductTour = () => {
    if (isFiservDashboardTourEnabled) {
      create({ userPreference: { key: FISERV_DASHBOARD_TOUR_PREFERENCE_KEY, value: 'true' } });
    } else if (isPayDashboardTourEnabled) {
      create({ userPreference: { key: PAY_DASHBOARD_TOUR_PREFERENCE_KEY, value: 'true' } });
    }
  };

  return { isFirstTimeExperience, handleShowProductTour, isLoadingUserPreference };
};
