import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccountingPlatformSyncConnectionErrorEnum, SyncAppStateType, SyncFlowEnum } from '@melio/ap-activities';
import { Loader } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { useRouter } from '@/hooks/router.hooks';
import { useAppToast } from '@/hooks/useAppToast.hooks';
import { usePlatformIntl } from '@/translations/Intl';

export const AuthIntuitCallbackRouteError = () => {
  const { goToAccountingPlatformError, goToAccountingPlatform, goToContextualOnboarding } = useRouter();
  const { errorToast } = useAppToast();
  const { formatMessage } = usePlatformIntl();
  const [searchParams] = useSearchParams();
  const { track } = useAnalytics();

  useEffect(() => {
    const errorString = searchParams.get('error');
    const errorType =
      errorString === 'access_denied'
        ? AccountingPlatformSyncConnectionErrorEnum.AccessDenied
        : AccountingPlatformSyncConnectionErrorEnum.GeneralError;

    const appStateString = searchParams.get('state');

    if (appStateString) {
      const appState: SyncAppStateType = JSON.parse(decodeURIComponent(appStateString));
      if (appState.flow === SyncFlowEnum.NewBill || appState.flow === SyncFlowEnum.PayBoard) {
        goToAccountingPlatformError({
          syncError: errorType,
          flowToReturn: appState.flow,
          accountingPlatformId: appState.accountingPlatformId,
        });
      } else if (appState.flow === SyncFlowEnum.ContextualOnboarding) {
        track('SyncAccountingSoftware', 'Status', {
          StatusType: 'failure',
          ErrorType: errorType,
          Cta: 'connect-your-quickbooks-online',
          PageName: 'onboarding',
          Flow: 'accounting-software-connection',
          Intent: 'connect-accounting-software',
          AccountingSoftwareType: 'quickbooks-online',
        });
        goToContextualOnboarding();
        errorToast(formatMessage('screens.contextualOnboarding.quickbooksButton.error'));
      } else {
        goToAccountingPlatform({ syncError: errorType, accountingPlatformId: appState.accountingPlatformId });
      }
    }
  }, [
    goToAccountingPlatformError,
    goToAccountingPlatform,
    searchParams,
    errorToast,
    formatMessage,
    goToContextualOnboarding,
    track,
  ]);
  return <Loader />;
};
