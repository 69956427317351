import { Box, Text } from '@chakra-ui/react';
import { Badge, Button, Group, Image, Theme, useBreakpoint, useTheme } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BillProvider } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import AmazonLogo from '../assets/AmazonCardLogo.svg';
import GmailLogo from '../assets/GmailCardLogo.svg';
import { useProviderDisplayProps } from '../hooks/useProviderDisplayProps';
import { DisconnectProviderModal } from './DisconnectModal';

const logos = {
  amazon: AmazonLogo,
  gmail: GmailLogo,
};

const getCustomTheme = (currentTheme: Theme) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      p: 'm',
      borderStyle: 'solid',
      borderColor: currentTheme.colors.neutral.light,
      borderWidth: '1px',
      justifyContent: 'center',
      borderRadius: 'l',
    },
    titleContainer: {
      marginTop: '8px',
    },
    bodyContainer: {
      marginTop: '32px',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },
    buttonsContainer: {
      marginTop: '24px',
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
    },
    footerContainer: {
      marginTop: '16px',
      color: currentTheme.colors.neutral.darker,
    },
  },
});

export const SettingsCard = ({ provider }: { provider: BillProvider }) => {
  const [showConfirmModal, toggleConfirmModal] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const theme = useTheme();
  const styles = getCustomTheme(theme);
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();

  const { redirectURL, isLoading, displayName } = useProviderDisplayProps(provider);

  const isConnected = provider.isConnected;

  const getDescriptionMessage = () => {
    if (!isConnected) {
      return formatMessage(`activities.eBillsImport.settings.providers.${provider.name}.disconnected.description`);
    } else {
      return formatMessage(`activities.eBillsImport.settings.providers.${provider.name}.connected.description`);
    }
  };

  const onClick = async () => {
    if (isConnected) {
      return toggleConfirmModal(true);
    }

    track('Settings', 'Click', {
      Intent: 'sync',
      Provider: provider.name,
      PageName: 'sync-and-import',
      Flow: 'settings',
      PageEntryPoint: 'settings',
      Cta: 'connect',
      SyncType: provider.name,
    });

    setIsConnecting(true);
    try {
      await provider.connect(redirectURL);
    } catch (error) {
      track('Settings', 'Status', {
        PageName: 'sync-and-import',
        Provider: provider.name,
        Flow: 'settings',
        Intent: 'bill-import-connect-account',
        EntryPoint: 'settings',
        BillImportAccountType: provider.name,
        Status: 'failure',
        FailureReason: (error as Error).message,
      });
      setIsConnecting(false);
    }
  };

  const confirmDisconnect = async () => {
    toggleConfirmModal(false);
    track('Settings', 'Click', {
      Intent: 'unsync',
      Provider: provider.name,
      PageName: 'sync-and-import',
      Flow: 'settings',
      PageEntryPoint: 'settings',
      Cta: 'disconnect',
      SyncType: provider.name,
    });

    try {
      await provider.disconnect();
    } catch (error) {
      track('Settings', 'Status', {
        PageName: 'sync-and-import',
        Flow: 'settings',
        Intent: 'bill-import-disconnect-account',
        EntryPoint: 'settings',
        Provider: provider.name,
        BillImportAccountType: provider.name,
        Status: 'failure',
        FailureReason: (error as Error).message,
      });
    }
  };

  const title = isConnected ? `${displayName} is connected` : displayName;

  return (
    <>
      <Box
        data-component="AccountingPLatformCardContainer"
        sx={styles.baseStyle.container}
        data-testid="ebills-import-providers-container"
      >
        <Group variant="vertical" spacing="xs">
          <Box>
            <Image alt={`${provider.name} logo`} src={logos[provider.name]} />{' '}
          </Box>
          <Box sx={styles.baseStyle.titleContainer}>
            <Group alignItems="center">
              <Text textStyle={isExtraSmallScreen ? 'heading1Semi' : 'heading3Semi'}>{title}</Text>
              {!provider.isConnected && <Badge label="New" status="brand" type="primary" />}
            </Group>
          </Box>
        </Group>
        <Box sx={styles.baseStyle.bodyContainer}>
          <Text data-testid="description" textStyle={isExtraSmallScreen ? 'body3' : 'body2'}>
            {getDescriptionMessage()}
          </Text>
        </Box>
        <Box sx={styles.baseStyle.buttonsContainer}>
          <Button
            data-testid="connectButton"
            variant="tertiary"
            onClick={onClick}
            isLoading={isLoading || isConnecting}
            label={isConnected ? 'Disconnect' : 'Connect'}
          />
        </Box>
      </Box>
      {showConfirmModal && (
        <DisconnectProviderModal
          onCancel={() => toggleConfirmModal(false)}
          onConfirm={confirmDisconnect}
          provider={provider}
        />
      )}
    </>
  );
};
