import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ExpiredSessionScreen } from '@/screens/error-screens/ExpiredSessionScreen';
import { LogoutSuccessfulScreen } from '@/screens/error-screens/LogoutSuccessful.screen';
import { NotFoundScreen } from '@/screens/error-screens/NotFound.screen';
import { ServerErrorScreen } from '@/screens/error-screens/ServerError.screen';
import { UnauthorizedScreen } from '@/screens/error-screens/Unauthorized.screen';

export const ErrorRoute = () => {
  return (
    <Routes>
      <Route path="unauthorized" element={<UnauthorizedScreen />} />
      <Route path="error" element={<ServerErrorScreen />} />
      <Route path="expired-session" element={<ExpiredSessionScreen />} />
      <Route path="logout-successful" element={<LogoutSuccessfulScreen />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
};
