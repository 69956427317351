import { useParams } from 'react-router-dom';
import { AddBankAccountPlaidActivity } from '@melio/ap-activities';

import { useRouter } from '@/hooks/router.hooks';
import { useReceivingMethodActivityEvents } from '@/hooks/useReceivingMethodActivityEvents';

export const EditPlaidBankAccountScreen = () => {
  const { onBack, onDone } = useReceivingMethodActivityEvents();

  const { deliveryMethodId: updateDeliveryMethodId } = useParams();

  const { goToCantFindBankAccountScreen } = useRouter();

  const onClose = () => {
    updateDeliveryMethodId && goToCantFindBankAccountScreen(updateDeliveryMethodId);
  };

  return <AddBankAccountPlaidActivity onClose={onClose} onBack={onBack} onDone={onDone} isReceivingMethodFlow />;
};
