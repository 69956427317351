import { useCallback } from 'react';

export const usePdfDownloader = () => {
  const base64ToBlob = useCallback((base64: string, type: string) => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    return new Blob([arr], { type });
  }, []);

  const downloadPdf = useCallback(
    (base64String: string, fileName: string) => {
      const pdfBlob = base64ToBlob(base64String || '', 'application/pdf');
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');

      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [base64ToBlob]
  );

  return { downloadPdf };
};
