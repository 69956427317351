import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Avatar, FileAttachment, Group, Text } from '@melio/penny';
import { PayorPaymentRequestDetailsInvoice, PayorPaymentRequestDetailsPayeeDetails } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

export type PaymentRequestDetailsHeaderProps = {
  onViewInvoice: VoidFunction;
  payeePaymentRequestDetails: PayorPaymentRequestDetailsPayeeDetails;
  invoicePaymentRequestDetails: PayorPaymentRequestDetailsInvoice;
  isViewInvoiceEnabled: boolean;
};

export const PaymentRequestDetailsHeader = forwardRef<PaymentRequestDetailsHeaderProps>(
  ({ isViewInvoiceEnabled, payeePaymentRequestDetails, invoicePaymentRequestDetails, onViewInvoice }, ref) => {
    const { formatDate, formatCurrency } = useMelioIntl();

    return (
      <Group justifyContent="space-between" ref={ref}>
        <Group variant="vertical">
          <Avatar
            data-testid="company-logo"
            name={payeePaymentRequestDetails.companyName}
            size="large"
            src={payeePaymentRequestDetails.logoUrl ?? undefined}
            variant="square"
          />
          <Group variant="vertical" spacing="xs">
            <Group variant="vertical" spacing="none">
              <Text textStyle="heading3Semi" data-testid="company-name">
                {payeePaymentRequestDetails.companyName}
              </Text>
              <Text textStyle="body3" color="neutral.darker">
                <FormattedMessage id="ar.guestPayment.header.invoiceInvoiceOf.text" />
              </Text>
            </Group>
            <Group variant="vertical" spacing="none">
              <Text textStyle="heading1Semi" data-testid="invoice-amount">
                {formatCurrency(invoicePaymentRequestDetails.totalAmount)}
              </Text>
              <Text textStyle="body3" color="neutral.darker" data-testid="invoice-due-date">
                <FormattedMessage
                  id="ar.guestPayment.header.invoiceDueDate.text"
                  values={{ dueDate: formatDate(invoicePaymentRequestDetails.dueDate) }}
                />
              </Text>
            </Group>
          </Group>
        </Group>
        <FileAttachment
          data-testid="invoice-file"
          onViewModeClick={onViewInvoice}
          width={90}
          value={invoicePaymentRequestDetails.fileInfo.previewUrls[0]}
          isViewMode={!isViewInvoiceEnabled}
          isReadOnly={isViewInvoiceEnabled}
        />
      </Group>
    );
  }
);

PaymentRequestDetailsHeader.displayName = 'PaymentRequestDetailsHeader';
