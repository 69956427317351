import { Stack } from '@chakra-ui/react';
import { NakedButton, SectionBanner } from '@melio/penny';
import {
  DeliveryMethodType,
  FundingSourceType,
  PaymentFullyExpanded,
  useIsPlaidLinkVerificationTokenFetching,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { addDays } from 'date-fns';
import { useState } from 'react';

import { MicroDepositsVerificationModalActivity } from '../../../../funding-sources/MicroDepositsVerification';

const maxDaysWaitingForVendorDeliveryMethod = 7;
export const PaymentScheduledActionAlert = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();
  const [microDepositsModalOpen, setMicroDepositsModalOpen] = useState(false);

  const { fundingSource, deliveryMethod, vendor } = payment;
  const isVerifyingBankAccount = useIsPlaidLinkVerificationTokenFetching(fundingSource.id);

  let alertDescription = '';
  let alertAction;

  if (deliveryMethod.type === DeliveryMethodType.VirtualAccount) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.unilateral', {
      vendorName: vendor.name,
      date: formatDate(
        addDays(createDate(payment.scheduledDate.toISOString()), maxDaysWaitingForVendorDeliveryMethod),
        { dateStyle: 'medium' }
      ),
    });
  }

  // payment can't have virtual deliveryMethod and unverified paymentMethod at once (risk wise)
  if (fundingSource.type === FundingSourceType.BankAccount && !fundingSource.isVerified) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.text', {
      '4digits': fundingSource.details.accountNumber.slice(-4),
      date: formatDate(createDate(payment.scheduledDate.toISOString()), {
        month: 'short',
        day: 'numeric',
      }),
    });
    alertAction = (
      <NakedButton
        variant="secondary"
        onClick={() => {
          setMicroDepositsModalOpen(true);
        }}
        label={formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.link')}
        isDisabled={isVerifyingBankAccount}
        data-testid="payment-scheduled-action-alert-verification-cta"
      />
    );
  }

  return alertDescription ? (
    <Stack direction="column" spacing={0}>
      <SectionBanner
        variant="warning"
        description={alertDescription}
        title={formatMessage('widgets.paymentDetails.scheduled.actionRequired.title')}
        data-testid="payment-scheduled-action-alert-verification-banner"
        action={alertAction}
      />
      {fundingSource.type === FundingSourceType.BankAccount && (
        <MicroDepositsVerificationModalActivity
          fundingSourceId={payment.fundingSourceId}
          isOpen={microDepositsModalOpen}
          onClose={() => {
            setMicroDepositsModalOpen(false);
          }}
          onDone={() => {
            setMicroDepositsModalOpen(false);
          }}
        />
      )}
    </Stack>
  ) : null;
};
