/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { useNavigationWithQueryParams } from '@melio/ap-domain';
import { Container, Group, useBreakpoint, useDisclosure, useTable, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { BillSubscription, Payment, PaymentExpandableFields, usePaginatedPayments, Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMonitoring } from '@melio/platform-monitoring';
import { useMelioIntl } from '@melio/platform-provider';
import { useLocation } from '@melio/platform-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom';

import { MonitoredAction } from '../../../../monitoring';
import { useMonitorTiming } from '../../../../utils';
import { DeletePaymentModal, SelectedEntity } from '../../../delete-payment-modal';
import { MarkAsPaidModal } from '../../../mark-as-paid-modal/MarkAsPaidModal';
import { PaymentDrawerActivity } from '../../../payment-drawer';
import { Filter } from '../../Filter/Filter';
import { emitFocusEvent, FocusEvents } from '../../FocusSkipToComponent';
import { useFiltersNewFailedPayments } from '../../hooks/useGetNewFailedPayments';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useNewPaymentsTabItems } from '../../hooks/useNewPaymentsTabItems';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PayDashboardTabs } from '../../types';
import { APTable } from '../APTable';
import { useItemsTabHighlightedRows } from '../BillsTab/useItemsTabHighlightedRows';
import { MobileSortMenu } from '../MobileSortMenu/MobileSortMenu';
import { PayDashboardPagination, PayDashboardPaginationProvider, usePayDashboardPagination } from '../Pagination';
import { EmptySearchResult, SearchBar } from '../Search';
import { FilterChips } from './components/FilterChips';
import { PaymentsTabEmptyState } from './components/PaymentsTabEmptyState';
import { PaymentsTabListItem } from './components/PaymentsTabListItem';
import { getSchedulerFullName } from './components/PaymentsTabScheduledByCell';
import { usePaymentStatus } from './components/PaymentsTabStatusCell';
import { PaymentsTabFilter } from './types';
import { usePaymentFilters } from './usePaymentFilters';
import { usePaymentsTabAnalytics } from './usePaymentsTabAnalytics';
import { usePaymentsTableColumns } from './usePaymentsTableColumns';
import { DEFAULT_SORT_BY_TAB_FILTER, usePaymentsTabQueryParams } from './usePaymentsTabQueryParams';
import { usePaymentsTabSortableColumns } from './usePaymentsTabSortableColumns';

export type PaymentsTabProps = {
  onEditPayment: ({ id, returnUrl }: { id: Payment['id']; returnUrl: string }) => void;
  onAddNewPayment: ({
    returnUrl,
    vendorId,
    amount,
  }: {
    returnUrl: string;
    vendorId: Vendor['id'];
    amount: string;
  }) => void;
  onRetryFailedToDeliverPayment: (paymentId: Payment['id']) => void;
  onRetryFailedToCollectPayment: (paymentId: Payment['id']) => void;
  onRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndResendPayment: (paymentId: Payment['id']) => void;
  onEditBillSubscription: ({ id, returnUrl }: { id: BillSubscription['id']; returnUrl: string }) => void;
};

const PaymentsTabComponent = withAnalyticsContext(
  ({
    onEditPayment,
    onAddNewPayment,
    onRetryFailedToDeliverPayment,
    onRetryFailedToCollectPayment,
    onRefundPayment,
    onVoidAndRefundPayment,
    onVoidAndResendPayment,
    onEditBillSubscription,
  }: PaymentsTabProps) => {
    const { isExtraSmallScreen } = useBreakpoint();
    const [isMarkAsPaidEnabled] = useFeature(FeatureFlags.MarkAsPaid, false);
    const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>(null);
    const { track } = useAnalytics();
    const { pathname } = useLocation();
    const resolvedPathUrl = useResolvedPath('');
    const [selectedRow, setSelectedRow] = useState<Payment>();
    const { searchTerm } = useSearchTerm();
    const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
    const { sortableColumnsMobile: paymentsTabSortableItems } = usePaymentsTabSortableColumns();

    const sorting = usePayDashboardSorting();
    const navigate = useNavigate();
    const { toast } = useToast();
    const { formatMessage, formatCurrency } = useMelioIntl();
    const [isFetchPaymentActionsOnDashboardEnabled] = useFeature(FeatureFlags.FetchPaymentActionsOnDashboard, false);
    const [isMobileSortEnabled] = useFeature<boolean>(FeatureFlags.NpeSortMobile, false);

    const {
      isOpen: isCancelPaymentModalOpen,
      onOpen: onCancelPaymentModalOpen,
      onClose: onCancelPaymentModalClose,
    } = useDisclosure();

    const {
      isOpen: isMarkAsPaidModalOpen,
      onOpen: onMarkAsPaidModalOpen,
      onClose: onMarkAsPaidClose,
    } = useDisclosure();

    const handleMarkedFailedPaymentAsPaid = (id: string) => {
      setSelectedRow(paymentsResult?.data.find(({ id: paymentId }) => id === paymentId));
      onMarkAsPaidModalOpen();
    };

    const handelOnSuccessMarkAsPaid = () => {
      if (activeFilter === PaymentsTabFilter.Failed) {
        navigate(`../${PayDashboardTabs.Payments}`);
      }
      refetchPayments();
    };

    const handleOnSuccessApprovalDecision = () => {
      refetchPayments();
    };

    const handleOnSuccessMarkAsUnpaid = async (payment: Payment) => {
      await refetchPayments();
      track('Dashboard', 'Status', {
        Intent: 'mark-as-unpaid',
        Status: 'success',
        PaymentId: payment.id,
      });
      toast({
        type: 'success',
        title: formatMessage('activities.payDashboard.billsTab.markAsUnpaid.toast.success', {
          amount: formatCurrency(payment.amount),
          vendorName: payment.vendor?.name || '',
        }),
        action: {
          type: 'button',
          text: formatMessage('activities.payDashboard.billsTab.markAsUnpaid.toast.success.single.action'),
          onAction: (closeToast) => {
            track('Dashboard', 'Click', {
              Intent: 'mark-as-unpaid',
              Cta: 'view-bill',
              Status: 'success',
              PaymentId: payment.id,
            });
            closeToast();
            return navigate(`../${PayDashboardTabs.Bills}`);
          },
        },
      });
    };

    const expand: PaymentExpandableFields[] = [
      'vendor',
      'deliveryMethod',
      'bills',
      'subscriptionOccurrence',
      'subscriptionOccurrence.billSubscription',
      'loan',
      'fundingSource',
    ];
    const expandWithPaymentActions: PaymentExpandableFields[] = [
      'vendor',
      'deliveryMethod',
      'bills',
      'subscriptionOccurrence',
      'subscriptionOccurrence.billSubscription',
      'loan',
      'fundingSource',
      'paymentActions',
    ];

    const { pageSize, resetToFirstPage } = usePayDashboardPagination();

    const { queryParams, activeFilter, applyFilter, sort } = usePaymentsTabQueryParams();
    const paginationResponse = usePaginatedPayments({
      cacheTime: 0,
      params: {
        ...(searchTerm ? { searchTerm } : {}),
        search: queryParams.search,
        sort,
        limit: pageSize,
        expand: isFetchPaymentActionsOnDashboardEnabled ? expandWithPaymentActions : expand,
      },
    });
    const {
      data: paymentsResult,
      refetch: refetchPayments,
      isLoading: isLoadingPayments,
      isPreviousData,
      isFetching: isFetchingPayments,
    } = paginationResponse;

    const { newPayments, markAsSeen } = useNewPaymentsTabItems();
    const paymentsToHighlights = useMemo(() => newPayments?.map((payment) => payment.id), [newPayments]);
    const highlightedRowIds = useItemsTabHighlightedRows(paymentsToHighlights, !!paymentsResult, markAsSeen);

    const paymentFilters = usePaymentFilters();
    const { markAsSeen: markFailedIndicatorAsSeen } = useFiltersNewFailedPayments();

    const legacyRouteReady = useMonitorTiming('payments_tab_ready');
    const { routeReady, endAction } = useMonitoring<MonitoredAction>();
    const triggerMonitoring = (el: HTMLDivElement) => {
      legacyRouteReady(el);
      routeReady(el);
      endAction('payment_cancel');
      endAction('recurring-payment-cancel');
    };

    usePaymentsTabAnalytics({ payments: paymentsResult?.data ?? [], activeFilter });
    const {
      isEmptyState,
      isEmptySearchResult,
      isInitialLoading,
      shouldShowTabHeader,
      isTableLoading,
      shouldShowPaginationControls,
    } = useLoadingState({
      isLoading: isLoadingPayments,
      items: paymentsResult?.data ?? [],
      searchTerm,
      isFiltered: activeFilter !== PaymentsTabFilter.All,
      paginationConfig: {
        isFetching: isFetchingPayments,
        isPreviousData,
        totalCount: paymentsResult?.pagination.totalCount,
      },
    });
    useEffect(() => {
      if (isLoadingPayments) {
        return;
      }
      track('Dashboard', 'View', {
        Intent: 'view-payment',
        SortColumn: sorting?.sortingState?.id,
        SortDirection: sorting?.sortingState?.sortDirection,
      });
    }, [isLoadingPayments]);

    const handleCloseDrawer = () => {
      handleNavigationWithQueryParams({ newPath: resolvedPathUrl.pathname });
    };

    const handleCancelPayment = (id: string, currentEntity: SelectedEntity) => {
      track('Dashboard', 'Click', { Intent: 'cancel-payment', Cta: 'cancel-payment' });
      setSelectedRow(paymentsResult?.data.find(({ id: paymentId }) => id === paymentId));
      setSelectedEntity(currentEntity);
      onCancelPaymentModalOpen();
    };

    const handelOnSuccessCancelPayment = () => {
      refetchPayments();
    };

    const handleEditPayment = (id: Payment['id']) => {
      track('Dashboard', 'Click', { Intent: 'edit-payment', Cta: 'edit-payment' });
      onEditPayment({ id, returnUrl: pathname });
    };

    const handleEditBillSubscription = (id: Payment['id']) => {
      track('Dashboard', 'Click', { Intent: 'edit-bill-subscription', Cta: 'edit-bill-subscription' });
      onEditBillSubscription({ id, returnUrl: resolvedPathUrl.pathname });
    };

    const handleViewPayment = (id: Payment['id']) => {
      handleNavigationWithQueryParams({ newPath: id });
    };

    const handleFilterChange = (filter: PaymentsTabFilter) => {
      track('Dashboard', 'Click', { Intent: 'filter-list', Cta: filter });
      applyFilter(filter);
      resetToFirstPage();
      if (filter === PaymentsTabFilter.Failed) {
        markFailedIndicatorAsSeen();
      }
    };

    const columns = usePaymentsTableColumns({
      filter: activeFilter,
      onSortChange: resetToFirstPage,
    });

    const mobileSortMenuOptions = Object.values(paymentsTabSortableItems(activeFilter));

    const mobileRowRenderer = useCallback((row: Payment) => <PaymentsTabListItem payment={row} />, []);
    const getItemAriaLabelContext = usePaymentAriaLabel();

    const tableProps = useTable({
      isLoading: isTableLoading,
      data: paymentsResult?.data ?? [],
      columns,
      getRowId: (row) => row.id,
      onRowClick: ({ rowData }) => handleViewPayment(rowData.id),
      ...sorting,
      headerVariant: 'dark',
      hideHeaderWhileLoading: isInitialLoading,
      getRowSelectionAriaLabel: getItemAriaLabelContext,
      mobileRowRenderer,
      captionId: formatMessage(`activities.payDashboard.tabs.payments.caption`),
      meta: {
        getItemAriaLabelContext,
        onViewClick: handleViewPayment,
        onEditPayment: handleEditPayment,
        onCancelPayment: handleCancelPayment,
        onMarkFailedPaymentAsPaid: handleMarkedFailedPaymentAsPaid,
        onSuccessMarkAsUnpaid: handleOnSuccessMarkAsUnpaid,
      },
      highlightedRowIds,
    });

    const renderTableContent = () => (
      <Group alignItems="flex-end" variant="vertical" spacing={shouldShowPaginationControls ? 'm' : undefined}>
        <APTable
          {...tableProps}
          captionLabel={
            shouldShowTabHeader ? formatMessage('activities.payDashboard.tabs.payments.captionLabel') : undefined
          }
        />
        {isEmptySearchResult && !isFetchingPayments ? (
          <EmptySearchResult onClear={() => emitFocusEvent(FocusEvents.TAB_TOP_SEARCH)} />
        ) : null}
        <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
      </Group>
    );

    const renderSearchBar = () => (
      <SearchBar
        onSearchSubmitted={resetToFirstPage}
        placeholderOnFocus="activities.payDashboard.paymentsTab.searchPlaceholder"
        label={isExtraSmallScreen ? undefined : formatMessage('activities.payDashboard.paymentsTab.searchLabel')}
        pagination={!paginationResponse.isFetching ? paginationResponse.data?.pagination : undefined}
        focusOnEvent={FocusEvents.TAB_TOP_SEARCH}
        placeholder={isExtraSmallScreen ? 'activities.payDashboard.paymentsTab.search.placeholder' : undefined}
      />
    );

    const renderContent = () => {
      const isEmptyStateWithFilter = isEmptyState && activeFilter !== PaymentsTabFilter.All;
      const shouldRenderTableContent = !isEmptyState || isEmptySearchResult || isEmptyStateWithFilter;
      const shouldRenderEmptyState = isEmptyState && !isEmptySearchResult;
      return (
        <>
          {shouldRenderTableContent && renderTableContent()}
          {shouldRenderEmptyState && (
            <PaymentsTabEmptyState
              subTab={activeFilter}
              onAddNewPayment={() => onAddNewPayment({ returnUrl: pathname, vendorId: '', amount: '' })}
            />
          )}
        </>
      );
    };

    const renderFilter = () => {
      if (isExtraSmallScreen) {
        return (
          <Filter<PaymentsTabFilter>
            options={paymentFilters}
            defaultFilter={PaymentsTabFilter.All}
            activeFilter={activeFilter}
            title="activities.payDashboard.filter.title"
            onChange={handleFilterChange}
          ></Filter>
        );
      }
      return <FilterChips options={paymentFilters} activeFilter={activeFilter} onChange={handleFilterChange} />;
    };

    return (
      <Container data-testid="pay-dashboard-payments-tab" overflow="initial">
        {!isLoadingPayments && <span ref={triggerMonitoring} />}
        <Group variant="vertical" width="full" spacing={isExtraSmallScreen ? 's' : 'm'}>
          <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
            {shouldShowTabHeader ? (
              <Group
                variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                justifyContent="flex-start"
                width="full"
                spacing="s"
                data-testid="pay-dashboard-payments-sub-and-search-tab"
              >
                {isExtraSmallScreen ? (
                  <Group variant="horizontal" alignItems="center">
                    <Group.Item>{renderSearchBar()}</Group.Item>

                    <Group.Item>
                      <Group allowOverflowX={false} spacing="xs">
                        {isMobileSortEnabled && (
                          <Container width="fit-content">
                            <MobileSortMenu
                              items={mobileSortMenuOptions}
                              onSortChange={resetToFirstPage}
                              title="activities.payDashboard.paymentsTab.sort.title"
                            />
                          </Container>
                        )}
                        <Container width="fit-content">{renderFilter()}</Container>
                      </Group>
                    </Group.Item>
                  </Group>
                ) : (
                  <>
                    {renderSearchBar()}
                    {renderFilter()}
                  </>
                )}
              </Group>
            ) : null}
          </Container>
          <Container overflow="initial">{renderContent()}</Container>
          {selectedRow && (
            <DeletePaymentModal
              isOpen={isCancelPaymentModalOpen}
              onClose={onCancelPaymentModalClose}
              payment={selectedRow}
              selectedEntity={selectedEntity}
              onSuccess={handelOnSuccessCancelPayment}
            />
          )}
          {isMarkAsPaidEnabled && selectedRow && (
            <MarkAsPaidModal
              id={selectedRow.id}
              isOpen={isMarkAsPaidModalOpen}
              onClose={onMarkAsPaidClose}
              onSuccess={handelOnSuccessMarkAsPaid}
              type="payment"
            />
          )}
        </Group>
        <Routes>
          <Route
            path=":paymentId"
            element={
              <PaymentDrawerActivity
                onClose={handleCloseDrawer}
                onEditPayment={handleEditPayment}
                onRetryFailedToDeliverPayment={onRetryFailedToDeliverPayment}
                onRetryFailedToCollectPayment={onRetryFailedToCollectPayment}
                onRefundPayment={onRefundPayment}
                onVoidAndRefundPayment={onVoidAndRefundPayment}
                onVoidAndResendPayment={onVoidAndResendPayment}
                onMarkAsUnpaid={handleOnSuccessMarkAsUnpaid}
                onApprovalDecision={handleOnSuccessApprovalDecision}
                onEditBillSubscription={handleEditBillSubscription}
              />
            }
          />
        </Routes>
      </Container>
    );
  }
);

export const PaymentsTab = (props: PaymentsTabProps) => {
  const [searchParams] = useSearchParams();
  const activeFilter = (searchParams.get('status') as PaymentsTabFilter) ?? PaymentsTabFilter.All;

  return (
    <PayDashboardPaginationProvider>
      <PayDashboardSortingProvider defaultSort={DEFAULT_SORT_BY_TAB_FILTER[activeFilter]}>
        <PaymentsTabComponent {...props} />
      </PayDashboardSortingProvider>
    </PayDashboardPaginationProvider>
  );
};

export function usePaymentAriaLabel() {
  const getPaymentStatus = usePaymentStatus();
  const { formatDate, formatMessage, formatCurrency } = useMelioIntl();

  return useCallback(
    (payment: Payment) => {
      const { label: statusLabel } = getPaymentStatus(payment);
      return formatMessage('activities.payDashboard.approvalsTab.table.aria.row', {
        vendorName: payment.vendor?.name,
        name: getSchedulerFullName(payment),
        date: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
        status: statusLabel,
        amount: formatCurrency(payment.amount),
      });
    },
    [formatCurrency, formatDate, formatMessage, getPaymentStatus]
  );
}
