import { Group, Layout, LoadingContainer, Text } from '@melio/penny';
import { useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usePartnerGroups, useSubscription } from '../../../hooks';
import { FundingSourceGroupList, PageTitle } from '../../components';
import { useSubscriptionRouter } from '../../utils';
import { SubscriptionSelectFundingSourceFooter } from './SubscriptionSelectFundingSourceFooter';
import { SubscriptionSelectFundingSourceHeader } from './SubscriptionSelectFundingSourceHeader';

export const SubscriptionSelectFundingSource = () => {
  const { data: fundingSources, isFetching: isFetchingFundingSources } = useFundingSources();
  const { formatMessage } = useMelioIntl();
  const { goToAddBankAccount, goToAddCard } = useSubscriptionRouter();
  const subscription = useSubscription();
  const { isFiservPartner } = usePartnerGroups();
  useSetDocumentTitle(formatMessage('activities.subscriptionPaymentMethod.screens.title'));

  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string>(subscription?.fundingSourceId || '');
  const location = useLocation();

  const navigateToAddBankAccount = () => goToAddBankAccount({ returnUrl: location.pathname });
  const navigateToAddCard = () => goToAddCard({ returnUrl: location.pathname });

  return (
    <Layout
      variant="full"
      paddingContent="s"
      data-testid="subscription-payment-method-select"
      {...(!isFiservPartner && {
        header: {
          isSticky: true,
          content: <SubscriptionSelectFundingSourceHeader />,
        },
      })}
      footer={{
        isSticky: true,
        content: <SubscriptionSelectFundingSourceFooter selectedFundingSourceId={selectedFundingSourceId} />,
      }}
    >
      <Layout variant="6Columns" paddingContent="s">
        <Group variant="vertical" alignItems="flex-start" spacing="l" height="full">
          <Group variant="vertical" alignItems="flex-start" spacing="xl">
            <PageTitle textStyle="heading1Semi" id="subscritpions-choose-billing-account">
              {formatMessage('activities.subscriptionPaymentMethod.screens.title')}
            </PageTitle>
            <Text>{formatMessage('activities.subscriptionPaymentMethod.screens.description')}</Text>
          </Group>
          <LoadingContainer isLoading={isFetchingFundingSources}>
            <FundingSourceGroupList
              fundingSources={fundingSources || []}
              onSelected={(fundingSource) => setSelectedFundingSourceId(fundingSource.id)}
              selectedId={selectedFundingSourceId}
              onAddBank={navigateToAddBankAccount}
              onAddCard={navigateToAddCard}
            />
          </LoadingContainer>
        </Group>
      </Layout>
    </Layout>
  );
};
