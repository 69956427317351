import { Checkbox, Container, Group, Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

type TaxPayerInfoCheckboxProps = {
  isContractor?: boolean;
  isUpdating: boolean;
  onChange: () => void;
};

export const TaxPayerInfoCheckbox = ({ isContractor, onChange, isUpdating }: TaxPayerInfoCheckboxProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: { contractorLearnMoreLink },
  } = useConfig();
  const { createTrackHandler } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');

  return (
    <Container overflow="initial">
      <Group spacing="xs" variant="vertical">
        <Group spacing="xs" alignItems="center">
          <Checkbox
            label={formatMessage('widgets.taxPayerInfo.header.checkboxLabel')}
            isDisabled={isUpdating}
            data-testid="taxpayer-info-contractor-checkbox"
            isChecked={isContractor}
            onChange={() => {
              trackActionClick({
                IsContractor: isContractor,
                Cta: 'mark-1099-contractor',
                PageName: 'vendor-details',
                Flow: 'dashboard-vendors',
              });
              onChange();
            }}
          />
        </Group>
        <Text pl="1.8rem" textStyle="body4" color="neutral.darker">
          {formatMessage('widgets.taxPayerInfo.header.caption', {
            learnMoreLink: (
              <Text textStyle="body4" color="brand.main">
                <Link
                  variant="inline"
                  color="inherit"
                  href={contractorLearnMoreLink}
                  label={formatMessage('widgets.taxPayerInfo.header.caption.link')}
                  newTab
                />
              </Text>
            ),
          })}
        </Text>
      </Group>
    </Container>
  );
};

TaxPayerInfoCheckbox.displayName = 'TaxPayerInfoCheckbox';
