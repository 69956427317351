import { Group } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { groupBy } from 'lodash';
import { useMemo } from 'react';

import { FundingSourceCardList } from './FundingSourceCardList';

export const FundingSourceGroupList = ({
  fundingSources,
  onSelected,
  selectedId,
  onAddBank,
  onAddCard,
}: {
  fundingSources: FundingSource[];
  onSelected: (fundingSource: FundingSource) => void;
  selectedId: string | null;
  onAddBank: VoidFunction;
  onAddCard: VoidFunction;
}) => {
  const { formatMessage } = useMelioIntl();
  const groupedSources = useMemo(() => groupBy(fundingSources, 'type'), [fundingSources]);

  return (
    <Group variant="vertical" width="full">
      <FundingSourceCardList
        fundingSourceType="bank-account"
        fundingSources={groupedSources['bank-account'] || []}
        onCardClick={onSelected}
        selectedId={selectedId}
        onAddClick={onAddBank}
        addLabel={formatMessage('activities.subscription.checkout.fundingSourceSelection.bank.addAnother')}
      />
      <FundingSourceCardList
        fundingSourceType="card"
        fundingSources={groupedSources['card'] || []}
        onCardClick={onSelected}
        selectedId={selectedId}
        onAddClick={onAddCard}
        addLabel={formatMessage('activities.subscription.checkout.fundingSourceSelection.card.addAnother')}
      />
    </Group>
  );
};
