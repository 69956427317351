import { Loader } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useApprovalWorkflows } from '@melio/platform-api';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { ComponentProps, useEffect } from 'react';

import { useManageCollaboratorAnalytics } from './hooks/analytics/useManageCollaboratorAnalytics';
import { useManageCollaboratorsData } from './hooks/useManageCollaboratorsData';
import { ManageCollaboratorsScreen } from './screens/ManageCollaborators.screen';
import { DeleteCollaboratorModalScreen } from './screens/modals/DeleteCollaborator/DeleteCollaborator.modal-screen';
import { CreateInvitationModalScreen } from './screens/modals/InviteOrEdit/CreateInvitation.modal-screen';
import { EditCollaboratorModalScreen } from './screens/modals/InviteOrEdit/EditCollaborator.modal-screen';
import { MakeOwnerCollaboratorModalScreen } from './screens/modals/InviteOrEdit/MakeOwnerCollaborator.modal-screen';
import { toFullName } from './screens/utils/toFullName';
import { useCancelInvitationModal } from './useCancelInvitationModal';
import { useCreateInvitationModal } from './useCreateInvitationModal';
import { useDeleteCollaboratorModal } from './useDeleteCollaboratorModal';
import { useEditCollaboratorModal } from './useEditCollaboratorModal';
import { useMakeOwnerCollaboratorModal } from './useMakeOwnerCollaboratorModal';
import { useResendInvitationFlow } from './useResendInvitationFlow';

const CancelInvitationModalScreen = DeleteCollaboratorModalScreen;

export const ManageCollaboratorsActivity = withAnalyticsContext(() => {
  const {
    collaborators,
    invitations,
    isFetching,
    isLoading,
    actor,
    companyName,
    actorMinApprovalThreshold,
    permissions,
  } = useManageCollaboratorsData();
  const createInvitationModal = useCreateInvitationModal();
  const cancelInvitationModal = useCancelInvitationModal();
  const editCollaboratorModal = useEditCollaboratorModal();
  const makeOwnerCollaboratorModal = useMakeOwnerCollaboratorModal();
  const deleteCollaboratorModal = useDeleteCollaboratorModal();
  const { resendInvitation } = useResendInvitationFlow();
  const { data: approvalWorkflows, isLoading: isLoadingApprovalWorkflows } = useApprovalWorkflows();
  const { track } = useAnalytics();

  const { tryUseFeature: tryUseAddCollaborators } = useSubscriptionFeature({
    featureName: 'collaborators',
    requirements: {
      totalUnits: (collaborators || []).length + (invitations || []).length + 1,
    },
  });

  useEffect(() => {
    track('Settings', 'View', { PageName: 'collaborators-setting' });
  }, [track]);

  const hasApprovalWorkflow = !!(approvalWorkflows?.length ?? 0);

  const analytics = useManageCollaboratorAnalytics({ collaborators: collaborators ?? [], isLoadingData: isLoading });

  const onAddCollaboratorClick = () => {
    tryUseAddCollaborators({ onFeatureIsEligible: createInvitationModal.openModal });
    analytics.clickAddCollaborator();
  };

  const onCollaboratorAction: ComponentProps<typeof ManageCollaboratorsScreen>['onCollaboratorAction'] = (
    action,
    options
  ) => {
    switch (action) {
      case 'delete':
        deleteCollaboratorModal.openModal(options.collaborator);
        break;
      case 'edit':
        editCollaboratorModal.openModal(options.collaborator);
        break;
      case 'owner':
        makeOwnerCollaboratorModal.openModal(options.collaborator);
    }
    analytics.clickCollaboratorAction({ action, collaboratorRoleUniqueName: options.collaborator.roleUniqueName });
  };

  const onInvitationAction: ComponentProps<typeof ManageCollaboratorsScreen>['onInvitationAction'] = (
    action,
    options
  ) => {
    switch (action) {
      case 'cancel':
        cancelInvitationModal.openModal(options.invitation);
        break;
      case 'resend':
        resendInvitation(options.invitation);
        break;
    }
    analytics.clickInvitationAction({
      action,
      collaboratorRoleUniqueName: options.invitation.roleUniqueName,
      approvalAmountThreshold: options.invitation.approvalAmountThreshold,
    });
  };

  if (!actor) {
    return <Loader />;
  }

  return (
    <>
      <ManageCollaboratorsScreen
        onAddCollaboratorClick={onAddCollaboratorClick}
        onCollaboratorAction={onCollaboratorAction}
        onInvitationAction={onInvitationAction}
        actor={actor}
        invitations={invitations}
        collaborators={collaborators}
        isFetching={isFetching}
        canInvite={permissions.invite}
      />
      {actor.roleUniqueName ? (
        <>
          <DeleteCollaboratorModalScreen
            companyName={companyName}
            fullName={toFullName(
              deleteCollaboratorModal.state.collaborator?.firstName,
              deleteCollaboratorModal.state.collaborator?.lastName
            )}
            isOpen={deleteCollaboratorModal.state.isOpen}
            onClose={deleteCollaboratorModal.closeModal}
            onDone={deleteCollaboratorModal.submit}
            isSaving={deleteCollaboratorModal.isDeleting}
            hasApprovalWorkflow={hasApprovalWorkflow}
            isLoading={isLoadingApprovalWorkflows}
            isApprover={deleteCollaboratorModal.state.collaborator?.approvalActions.canApprovePayments}
          />
          <CancelInvitationModalScreen
            companyName={companyName}
            fullName={toFullName(
              cancelInvitationModal.state.invitation?.firstName,
              cancelInvitationModal.state.invitation?.lastName
            )}
            isOpen={cancelInvitationModal.state.isOpen}
            onClose={cancelInvitationModal.closeModal}
            onDone={cancelInvitationModal.submit}
            isSaving={cancelInvitationModal.isCanceling}
          />
          <EditCollaboratorModalScreen
            collaborator={editCollaboratorModal.state.collaborator}
            isOpen={editCollaboratorModal.state.isOpen}
            onClose={editCollaboratorModal.closeModal}
            onDone={editCollaboratorModal.submit}
            isSaving={editCollaboratorModal.isUpdating}
            hasApprovalWorkflow={hasApprovalWorkflow}
            availableRoleOptions={editCollaboratorModal.state.availableRoleOptions}
          />
          <CreateInvitationModalScreen
            companyName={companyName}
            isOpen={createInvitationModal.isOpen}
            onClose={createInvitationModal.closeModal}
            onDone={createInvitationModal.submit}
            actor={actor}
            availableRoleOptions={permissions.inviteRoleOptions}
            maxAllowedThreshold={actorMinApprovalThreshold}
            isSaving={createInvitationModal.isCreating}
          />
          <MakeOwnerCollaboratorModalScreen
            isOpen={makeOwnerCollaboratorModal.isOpen}
            onClose={makeOwnerCollaboratorModal.closeModal}
            actor={actor}
            collaborator={makeOwnerCollaboratorModal.collaborator}
          />
        </>
      ) : null}
    </>
  );
});
