import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';
import { Modal, useToast } from '@melio/penny';
import { Form, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useTaxPayerInfoDetailsRequest, Vendor } from '@melio/platform-api';

import { useVendorUpdate } from '@/hooks/vendors.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { emailIsValid } from '@/utils/email.utils';

type VendorTaxDetailsModalFields = {
  email: string;
};

const useSchema = () => {
  const { formatMessage } = usePlatformIntl();

  return object().shape({
    email: string()
      .required(formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.validations.empty'))
      .test(
        'validAddress',
        formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.validations.invalid'),
        emailIsValid,
      ),
  }) as SchemaOf<VendorTaxDetailsModalFields>;
};

type VendorTaxDetailsModalProps = {
  vendor: Vendor;
  isOpen: boolean;
  onClose: () => void;
};

export const RequestVendorTaxDetailsModal = ({ vendor, isOpen, onClose }: VendorTaxDetailsModalProps) => {
  const { formatMessage } = usePlatformIntl();
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<VendorTaxDetailsModalFields>();
  const { create: createTaxPayerInfoRequest, isMutating } = useTaxPayerInfoDetailsRequest({
    vendorId: vendor.id,
  });
  const { updateVendor, isLoading } = useVendorUpdate();

  const { toast } = useToast();
  const { track, createTrackHandler } = useAnalytics();

  const trackActionClick = createTrackHandler('Vendor', 'Click');

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View', {
        PageName: 'request-taxpayer',
        Flow: 'add-vendor-1099',
        Intent: 'add-vendor-email',
        TaxId: 'email',
        IsContractor: true,
      });
    }
  }, [isOpen, track]);

  const handleSubmit = async (data: VendorTaxDetailsModalFields) => {
    trackActionClick({
      Cta: 'send-w9-request',
      PageName: 'request-taxpayer',
      Flow: 'add-vendor-1099',
      Intent: 'add-vendor-email',
    });
    try {
      await updateVendor({
        vendorId: vendor.id,
        body: { contact: { email: data.email } },
      });
      createTaxPayerInfoRequest({ email: data.email });
      onClose();
      toast({
        type: 'success',
        title: formatMessage('widgets.taxPayerInfo.modal.toast.success'),
        id: 'vendor-tax-details-request-success',
      });
      track('Vendor', 'Status', { Intent: 'send-w9-request', TaxId: 'email', Status: 'success' });
    } catch (e) {
      toast({
        type: 'error',
        title: formatMessage('widgets.taxPayerInfo.modal.toast.error'),
        id: 'vendor-tax-details-request-error',
      });
      track('Vendor', 'Status', { Intent: 'send-w9-request', TaxId: 'email', Status: 'fail' });
    }
  };

  const { formProps, registerField } = useMelioForm<VendorTaxDetailsModalFields>({
    onSubmit: handleSubmit,
    onSubmissionStateChange,
    schema: useSchema(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        trackActionClick(
          { Cta: 'exit', PageName: 'request-taxpayer', Flow: 'add-vendor-1099', Intent: 'add-vendor-email' },
          () => onClose(),
        );
      }}
      isLoading={isMutating || isLoading}
      data-testid="vendor-request-tax-details-modal"
      header={formatMessage('widgets.taxPayerInfo.modal.title', {
        vendorName: vendor.name || '',
      })}
      description={formatMessage('widgets.taxPayerInfo.modal.description')}
      primaryButton={{
        label: formatMessage('widgets.taxPayerInfo.modal.submit'),
        variant: 'primary',
        isLoading: isMutating || isLoading,
        ...submitButtonProps,
      }}
    >
      <Form data-component="VendorRequestTaxDetailsModalForm" size="large" {...formProps}>
        <Form.TextField
          autoFocus
          {...registerField('email')}
          data-testid="vendor-request-tax-details-modal-email"
          labelProps={{ label: formatMessage('widgets.taxPayerInfo.modal.fields.email.title') }}
          placeholder={formatMessage('widgets.taxPayerInfo.modal.fields.email.placeholder')}
        />
      </Form>
    </Modal>
  );
};
