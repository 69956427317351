import { useInternationalPaymentCardRestrictionsText } from '@melio/ap-domain';
import { _IconIndicator, Group, Table, Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

export type AddPaymentMethodCellProps = {
  onClick: VoidFunction;
  selectedFundingSource?: FundingSource;
};

export const AddPaymentMethodCell = forwardRef<AddPaymentMethodCellProps, 'div'>(
  ({ onClick, selectedFundingSource, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const tooltipLabelProvider = useInternationalPaymentCardRestrictionsText();

    return (
      <Table.Cell data-testid="funding-source-add-payment-method-cell" onClick={onClick} {...props} ref={ref}>
        <Group spacing="xs" alignItems="center" justifyContent="space-between" width="full">
          <Text color="brand.dark" textStyle="inline" shouldSupportEllipsis>
            {formatMessage(
              `activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.addPaymentMethod.button`
            )}
          </Text>
          <_IconIndicator
            data-testid="funding-source-add-payment-method-cell-icon"
            variant="warning"
            tooltip={{
              label: tooltipLabelProvider(selectedFundingSource),
            }}
          />
        </Group>
      </Table.Cell>
    );
  }
);

AddPaymentMethodCell.displayName = 'AddPaymentMethodCell';
