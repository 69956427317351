import { TabItem } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';

import { usePayDashboardPagination } from '../components/Pagination';
import { PayDashboardTabs } from '../types';
import { usePaymentsTabNewFailedPayments } from './useGetNewFailedPayments';
import { useNewApprovalsTabItems } from './useNewApprovalsTabItems';
import { useNewBillsTabItems } from './useNewBillsTabItems';
import { useFinancingTabNewFailedLoans, useNewFinancingTabItems } from './useNewFinancingTabItems';

export type Indication = {
  counters: {
    [key in PayDashboardTabs]?: TabItem['badges'];
  };
  badges: {
    [key in PayDashboardTabs]?: TabItem['badges'];
  };
};

export const usePayDashboardTabIndication = (tab: string | undefined): Indication => {
  const { formatMessage } = useMelioIntl();

  const { newPayments: newFailedPayments } = usePaymentsTabNewFailedPayments();
  const { newPayments: newFailedLoans } = useFinancingTabNewFailedLoans();
  const { newBillsTabItems } = useNewBillsTabItems();
  const { newPayments: newLoans } = useNewFinancingTabItems();
  const { newPayments: newApprovalsTabItems } = useNewApprovalsTabItems();

  const { pageSize } = usePayDashboardPagination();

  const getBadgeAmount = (amount: number) => (amount >= pageSize ? `${pageSize}+` : `${amount}`);

  const billsTabCounters: TabItem['badges'] =
    newBillsTabItems.length > 0 && tab !== PayDashboardTabs.Bills
      ? [
          {
            status: 'brand',
            label: getBadgeAmount(newBillsTabItems.length),
          },
        ]
      : [];

  const billsTabBadges: TabItem['badges'] =
    newBillsTabItems.length > 0 && tab !== PayDashboardTabs.Bills
      ? [
          {
            label: formatMessage('activities.payDashboard.billsTab.newBillsIndication', {
              newBillsNumber: getBadgeAmount(newBillsTabItems.length),
            }),
            status: 'brand',
          },
        ]
      : [];

  const paymentsTabCounter: TabItem['badges'] =
    newFailedPayments.length > 0 ? [{ status: 'critical', label: getBadgeAmount(newFailedPayments.length) }] : [];

  const paymentsTabBadges: TabItem['badges'] =
    newFailedPayments.length > 0
      ? [
          {
            label: formatMessage('activities.payDashboard.paymentsTab.failedIndication', {
              num: getBadgeAmount(newFailedPayments.length),
            }),
            status: 'critical',
          },
        ]
      : [];

  const financingTabCounter: TabItem['badges'] = compact([
    newFailedLoans.length > 0
      ? {
          status: 'critical',
          label: getBadgeAmount(newFailedLoans.length),
        }
      : undefined,
    newLoans.length > 0
      ? {
          status: 'brand',
          label: getBadgeAmount(newLoans.length),
        }
      : undefined,
  ]);

  const financingTabBadges: TabItem['badges'] = compact([
    newFailedLoans.length > 0
      ? {
          label: formatMessage('activities.payDashboard.financingTab.failedIndication', {
            num: getBadgeAmount(newFailedLoans.length),
          }),
          status: 'critical',
        }
      : undefined,
    newLoans.length > 0
      ? {
          label: formatMessage('activities.payDashboard.financingTab.newLoansIndication', {
            num: getBadgeAmount(newLoans.length),
          }),
          status: 'brand',
        }
      : undefined,
  ]);

  const approvalTabCounter: TabItem['badges'] =
    newApprovalsTabItems.length > 0 && tab !== PayDashboardTabs.Approvals
      ? [
          {
            status: 'brand',
            label: getBadgeAmount(newApprovalsTabItems.length),
          },
        ]
      : [];

  const approvalsTabBadges: TabItem['badges'] =
    newApprovalsTabItems.length > 0 && tab !== PayDashboardTabs.Approvals
      ? [
          {
            label: formatMessage('activities.payDashboard.approvalsTab.newPendingApprovalIndication', {
              newPendingApprovalNumber: getBadgeAmount(newApprovalsTabItems.length),
            }),
            status: 'brand',
          },
        ]
      : [];

  return {
    counters: {
      [PayDashboardTabs.Bills]: billsTabCounters,
      [PayDashboardTabs.Payments]: paymentsTabCounter,
      [PayDashboardTabs.Financing]: financingTabCounter,
      [PayDashboardTabs.Approvals]: approvalTabCounter,
    },
    badges: {
      [PayDashboardTabs.Bills]: billsTabBadges,
      [PayDashboardTabs.Payments]: paymentsTabBadges,
      [PayDashboardTabs.Financing]: financingTabBadges,
      [PayDashboardTabs.Approvals]: approvalsTabBadges,
    },
  };
};
