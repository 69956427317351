import { getIsVoidAndRefundEnabled } from '@melio/ap-domain';
import {
  ApprovalDecision,
  PaymentApprovalDecisionStatusEnum,
  PaymentFullyExpanded,
  PaymentStatusEnum,
} from '@melio/platform-api';

import {
  isPaymentCompletedRefund,
  isPaymentCompletedVoidAndRefund,
  isPaymentScheduledRefund,
  isPaymentScheduledVoidAndRefund,
  isPaymentScheduledVoidToRefund,
  isPaymentScheduledVoidToResend,
} from '../../../../utils/pay-flow/Payment.utils';

type PaymentDetailsSectionType =
  | 'payment-scheduled'
  | 'payment-in-progress'
  | 'payment-completed'
  | 'payment-approval-declined'
  | 'refund-in-progress'
  | 'refund-completed'
  | 'payment-failed'
  | 'payment-canceled';

export const paymentDetailsSectionTypeFactory = (
  payment: PaymentFullyExpanded,
  approvalDecisions?: ApprovalDecision[]
): PaymentDetailsSectionType => {
  switch (payment.status) {
    case PaymentStatusEnum.Blocked:
    case PaymentStatusEnum.Scheduled:
      return 'payment-scheduled';
    case PaymentStatusEnum.InProgress:
      return 'payment-in-progress';
    case PaymentStatusEnum.Completed: {
      if (getIsVoidAndRefundEnabled(payment)) {
        return 'payment-in-progress';
      }
      return 'payment-completed';
    }
    case PaymentStatusEnum.Failed: {
      const isRefundInProgress = isPaymentScheduledRefund(payment);
      const isVoidAndRefundInProgress = isPaymentScheduledVoidAndRefund(payment);
      const isRefundCompleted = isPaymentCompletedRefund(payment);
      const isVoidAndRefundCompleted = isPaymentCompletedVoidAndRefund(payment);
      const isVoidingToResend = isPaymentScheduledVoidToResend(payment);
      const isVoidingToRefund = isPaymentScheduledVoidToRefund(payment);

      if (payment.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Declined && approvalDecisions) {
        return 'payment-approval-declined';
      }
      if (isVoidingToResend) {
        return 'payment-in-progress';
      }
      if (isVoidingToRefund || isRefundInProgress || isVoidAndRefundInProgress) {
        return 'refund-in-progress';
      }
      if (isRefundCompleted || isVoidAndRefundCompleted) {
        return 'refund-completed';
      }
      return 'payment-failed';
    }
    case PaymentStatusEnum.Canceled:
      return 'payment-canceled';
  }
};
