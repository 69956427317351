import { ActionsDropdownMenuItemProps } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { compact } from 'lodash';

import { PaymentFlowOnChangeHandlers } from '../../../../../../types';

export const useDeliveryMethodCardMenuItems = ({
  selectedDeliveryMethod,
  deliveryMethods,
  isRecurring,
  onDeliveryMethodChange,
  onEditDeliveryMethod,
}: {
  selectedDeliveryMethod: DeliveryMethod;
  deliveryMethods: DeliveryMethod[];
  isRecurring: boolean;
  onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'];
  onEditDeliveryMethod: (deliveryMethodType: DeliveryMethodType) => void;
}): ActionsDropdownMenuItemProps[] => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      payment: {
        scheduling: { recurringDeliveryMethodBlackList },
      },
    },
  } = useConfig();

  const onSwitchActionClick = ({
    deliveryMethodType,
    targetDeliveryMethod,
  }: {
    deliveryMethodType: DeliveryMethodType;
    targetDeliveryMethod: DeliveryMethod | undefined;
  }) => {
    if (!targetDeliveryMethod || targetDeliveryMethod.requireConfirmationForPayment) {
      onEditDeliveryMethod(deliveryMethodType);
    } else {
      onDeliveryMethodChange(targetDeliveryMethod);
    }
  };

  const getSwitchActionDisabledText = (deliveryMethodType: DeliveryMethodType) => {
    if (isRecurring && recurringDeliveryMethodBlackList.includes(deliveryMethodType)) {
      return formatMessage(
        'activities.paymentFlow.form.content.deliveryMethodCard.action.switch.disabled.deliveryMethodNotAllowedForRecurring'
      );
    }

    return null;
  };

  const createSwitchAction = (deliveryMethodType: 'paper-check' | 'bank-account') => {
    const targetDeliveryMethod = deliveryMethods.find((method) => method.type === deliveryMethodType);
    const disabledText = getSwitchActionDisabledText(deliveryMethodType);

    return {
      dataTestId: `switch-delivery-method-${deliveryMethodType}`,
      label: formatMessage(
        `activities.paymentFlow.form.content.deliveryMethodCard.action.switch.${deliveryMethodType}.label`
      ),
      disabled: disabledText ? { isDisabled: true, message: disabledText } : undefined,
      onClick: () => onSwitchActionClick({ deliveryMethodType, targetDeliveryMethod }),
    };
  };

  const switchActions = compact([
    selectedDeliveryMethod.type !== DeliveryMethodType.PaperCheck
      ? createSwitchAction(DeliveryMethodType.PaperCheck)
      : undefined,
    selectedDeliveryMethod.type !== DeliveryMethodType.BankAccount
      ? createSwitchAction(DeliveryMethodType.BankAccount)
      : undefined,
  ]);

  const editAction = {
    dataTestId: 'edit-delivery-method',
    label: formatMessage(`activities.paymentFlow.form.content.deliveryMethodCard.action.edit.label`),
    onClick: () => onEditDeliveryMethod(selectedDeliveryMethod.type),
  };

  return [editAction, ...switchActions];
};
