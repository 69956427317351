import { usePaymentActions } from '@melio/ap-domain';
import { Button, Group, useDisclosure } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Payment, useMelioQueryClient } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { CancelRepaymentModal } from '../../cancel-repayment-modal';

type FooterProps = {
  onClose: VoidFunction;
  payment: Payment;
};

export const Footer = ({ onClose, payment }: FooterProps) => {
  const { formatMessage } = useMelioIntl();
  const queryClient = useMelioQueryClient();
  const paymentActions = usePaymentActions(payment);
  const { createTrackHandler } = useAnalytics();

  const trackAndHandleClick = createTrackHandler<{
    Cta: 'cancel-repayment';
  }>('Payment', 'Click');

  const {
    isOpen: isCancelRepaymentModalOpen,
    onOpen: onCancelRepaymentModalOpen,
    onClose: onCancelRepaymentModalClose,
  } = useDisclosure();

  const handleDeleteRepayment = () => {
    void queryClient.invalidateQueries('FinancingApi');
    onClose();
  };

  if (!paymentActions.actions.delete) {
    return null;
  }

  return (
    <Group spacing="s" data-testid="pay-dashboard-financing-tab-drawer-footer">
      <Button
        variant="tertiary"
        label={formatMessage('activities.payDashboard.drawer.footer.loan.cancelRepayment.label')}
        onClick={() => trackAndHandleClick({ Cta: 'cancel-repayment' }, onCancelRepaymentModalOpen)}
        data-testid="repayment-btn-delete"
      />

      <CancelRepaymentModal
        isOpen={isCancelRepaymentModalOpen}
        onClose={onCancelRepaymentModalClose}
        payment={payment}
        onSuccess={handleDeleteRepayment}
      />
    </Group>
  );
};
