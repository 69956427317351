import { Address, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { AddressSearchWidget, AddressSearchWidgetProps } from '@melio/form-controls';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { CardHolderAddressDetails } from '../../types';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    line1: string()
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.line1.required.text'))
      .nullable(),
    city: string().required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.city.required.text')),
    state: string().required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.state.required.text')),
    postalCode: string()
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.postalCode.required.text'))
      .matches(/^\d{5}$/, formatMessage('ar.guestPayment.activities.cardHolder.form.fields.postalCode.length.text')),
  }) as SchemaOf<CardHolderAddressDetails>;
};

const ZIP_CODE_SHORT_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];

export type CardHolderAddressDetailsFormProps = FormWidgetProps<CardHolderAddressDetails>;

export const CardHolderAddressDetailsForm = forwardRef<CardHolderAddressDetailsFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const [shouldShowAddressFields, showAddressFields] = useBoolean(false);
    const { formProps, registerField, setValue } = useMelioForm<CardHolderAddressDetails>({
      onSubmit,
      schema: useSchema(),
      isSaving,
      onSubmissionStateChange,
    });

    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address | null;

      if (address === null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - if we pass '' the second search doesn't work properly
        setValue('line1', null);
        setValue('state', '');
        setValue('city', '');
        setValue('postalCode', '');
        showAddressFields.off();
      } else {
        showAddressFields.on();
      }

      const setFormField = (field: keyof CardHolderAddressDetails) => {
        if (address?.[field]) {
          setValue(field, address[field], {
            shouldValidate: true,
          });
        }
      };

      setFormField('line1');
      setFormField('state');
      setFormField('city');
      setFormField('postalCode');
    };

    return (
      <Form
        data-component="CardHolderAddressDetailsForm"
        data-testid="card-holder-address-details-form"
        {...props}
        {...formProps}
        columns={2}
        ref={ref}
      >
        <AddressSearchWidget
          {...registerField('line1')}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Type of formatSelectedValue will be fixed & data can be string | undefined | Address
          formatSelectedValue={(data): Address | string => (data.value as unknown as Address).line1}
          labelProps={{
            label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.billingAddress.label'),
          }}
          colSpan={2}
          onChange={handleAddressChange}
        />
        <Form.TextField
          colSpan={2}
          isHidden={!shouldShowAddressFields}
          labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.city.label') }}
          {...registerField('city')}
        />
        <Form.TextField
          isHidden={!shouldShowAddressFields}
          labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.state.label') }}
          {...registerField('state')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.postalCode.label') }}
          maskProps={{ mask: ZIP_CODE_SHORT_MASK }}
          isHidden={!shouldShowAddressFields}
          {...registerField('postalCode')}
        />
      </Form>
    );
  }
);
CardHolderAddressDetailsForm.displayName = 'CardHolderAddressDetailsForm';
