import { isEbill } from '@melio/ap-domain';

import { BasicAmountInput } from './components/BasicAmountInput';
import { EbillAmountDropdown } from './components/EbillAmountDropdown';
import { AmountInputProps } from './types';

export const AmountInput = (props: AmountInputProps) => {
  if (props.bill && isEbill(props.bill)) {
    return <EbillAmountDropdown {...props} bill={props.bill} />;
  }

  return <BasicAmountInput {...props} />;
};
