import { Form } from '@melio/penny';
import { BusinessResultItem } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { FocusEventHandler } from 'react';

import { BusinessSearchWidget, BusinessSearchWidgetProps } from '../../../../BusinessSearch';
import { AddManagedVendorFormProps } from './AddManagedVendorForm/types';

type CompanyFieldProps = Omit<
  BusinessSearchWidgetProps,
  'onCreateOption' | 'onSelectOption' | 'onClearField' | 'aria-label'
> & {
  onSelectCompanyName: AddManagedVendorFormProps['onSelectCompanyName'];
  autoFocus?: boolean;
  onClearSearchField: BusinessSearchWidgetProps['onClearField'];
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

export const CompanyField: React.FC<CompanyFieldProps> = ({
  onSelectCompanyName,
  onClearSearchField,
  autoFocus,
  isBusiness,
  onBlur,
  value,
  dropdownPortalRoot,
  ...props
}) => {
  const { formatMessage } = useMelioIntl();

  const [isSearchBusinessesInDirectoriesSupported] = useFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );

  const fieldProps = {
    labelProps: { label: formatMessage('widgets.vendors.companyName.label') },
    placeholder: formatMessage('widgets.vendors.companyName.placeholder'),
    onBlur,
  };

  if (isSearchBusinessesInDirectoriesSupported) {
    return (
      <BusinessSearchWidget
        {...props}
        dropdownPortalRoot={dropdownPortalRoot}
        onCreateOption={(companyName) => {
          onSelectCompanyName?.({ companyName });
        }}
        onSelectOption={(business: BusinessResultItem) => {
          onSelectCompanyName?.({
            companyName: business.business.name,
            business,
          });
        }}
        onClearField={onClearSearchField}
        isBusiness={isBusiness}
        autoFocus={autoFocus}
        {...fieldProps}
      />
    );
  }

  return <Form.TextField {...props} {...fieldProps} />;
};
