import { Modal, useFormSubmissionController, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Bill, useBill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { InvoiceNumberSchema, SetInvoiceNumberForm } from './SetInvoiceNumberForm';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSuccess?: (invoiceNumber?: string) => void;
  billId?: Bill['id'];
};

export const SetInvoiceNumberModalActivity = withAnalyticsContext<Props>(
  ({ setAnalyticsProperties, isOpen, onClose, onSuccess, billId }) => {
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { track } = useAnalytics();
    const { data: bill, isLoading, update, isMutating } = useBill({ id: billId, enabled: !!billId && isOpen });
    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<InvoiceNumberSchema>();

    const defaultValues = { invoiceNumber: bill?.invoice.number ?? '' };

    setAnalyticsProperties({
      PageName: defaultValues.invoiceNumber ? 'edit-invoice-number' : 'add-invoice-number',
      Intent: defaultValues.invoiceNumber ? 'edit-invoice-number' : 'add-invoice-number',
    });

    const handleSubmit = async ({ invoiceNumber }: InvoiceNumberSchema) => {
      track('Payment', 'Click', {
        Cta: 'save',
      });
      try {
        await update({ invoice: { number: invoiceNumber } });
        onSuccess?.(invoiceNumber);
        onClose();
      } catch (err) {
        toast({
          id: 'set-invoice-number-error',
          type: 'error',
          title: formatMessage('activities.setInvoiceNumberModal.toast.error'),
        });
      }
    };

    const handleClose = () => {
      track('Payment', 'Click', {
        Cta: 'exit',
      });
      onClose();
    };

    return (
      <Modal
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={handleClose}
        data-testid="set-invoice-number-modal-activity"
        header={
          defaultValues.invoiceNumber
            ? formatMessage('activities.setInvoiceNumberModal.header.edit')
            : formatMessage('activities.setInvoiceNumberModal.header.add')
        }
        primaryButton={{
          ...submitButtonProps,
          variant: 'primary',
          label: formatMessage('activities.setInvoiceNumberModal.primaryButton'),
        }}
      >
        {!isLoading && isOpen && (
          <SetInvoiceNumberForm
            onSubmit={handleSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            isSaving={isMutating}
            defaultValues={defaultValues}
          />
        )}
      </Modal>
    );
  }
);
