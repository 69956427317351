import {
  AccountingPlatformBillLineItemLabelSelectWidget,
  AccountingPlatformCategorySelectWidget,
} from '@melio/ap-widgets';
import {
  AmountField,
  Container,
  Control,
  Form,
  FormField,
  FormLineItems,
  Group,
  Text,
  TextField,
  useBreakpoint,
  useWatch,
} from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformBillLineItemLabelOption,
  AccountingPlatformSlug,
  FxCurrency,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty } from 'lodash';

import { AddBillV2FormValues, RegisterFieldResult } from '../../../types';
import { DeleteLineItem } from '../DeleteLineItem';
import { DeleteLineItemMobile } from '../DeleteLineItemMobile';

type Props = {
  index: number;
  formControl: Control<AddBillV2FormValues>;
  initialExternalLabelId?: string;
  labelFieldProps: RegisterFieldResult;
  amountFieldProps: RegisterFieldResult;
  descriptionFieldProps: RegisterFieldResult;
  currency?: FxCurrency;
  categoriesPickerProps: RegisterFieldResult;
  hasLineItems: boolean;
  billLineItemLabelOptions?: AccountingPlatformBillLineItemLabelOption[];
  activeAccountingPlatform?: AccountingPlatform;
  onClickRemoveLineItem: VoidFunction | null;
  shouldAutoFocus?: boolean;
};

export const CategoryBasedBillLineItem = ({
  index,
  formControl,
  initialExternalLabelId,
  labelFieldProps,
  amountFieldProps,
  currency,
  descriptionFieldProps,
  categoriesPickerProps,
  billLineItemLabelOptions,
  activeAccountingPlatform,
  hasLineItems,
  onClickRemoveLineItem,
  shouldAutoFocus = true,
}: Props) => {
  const naturalIndex = index + 1;
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { formatCurrency, formatMessage } = useMelioIntl();

  const [description, amount] = useWatch({
    control: formControl,
    name: [`categoryBasedLineItems.${index}.description`, `categoryBasedLineItems.${index}.amount`],
  });
  const deleteButtonAriaLabel = formatMessage('activities.addBillV2.lineItems.categoryBased.delete.ariaLabel', {
    index: naturalIndex,
    description: description || undefined,
    amount: amount ? formatCurrency(parseFloat(amount), currency) : undefined,
  });

  const shouldShowClassField = !isEmpty(billLineItemLabelOptions);
  const shouldShowAmountCurrency = currency && currency !== 'USD';

  const isQuickBooksOnline = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
  const accountingPlatformCategorySelectLabel = isQuickBooksOnline
    ? formatMessage('activities.addBillV2.lineItems.categoryBased.categorySelect.label.quickbooks')
    : formatMessage('activities.addBillV2.lineItems.categoryBased.categorySelect.label.general');
  const accountingPlatformCategorySelectAriaLabel = isQuickBooksOnline
    ? formatMessage('activities.addBillV2.lineItems.categoryBased.categorySelect.ariaLabel.quickbooks', {
        index: naturalIndex,
      })
    : formatMessage('activities.addBillV2.lineItems.categoryBased.categorySelect.ariaLabel.general', {
        index: naturalIndex,
      });

  if (isMobile) {
    return (
      <FormLineItems.MobileListItem index={index}>
        <Group variant="vertical" spacing="m">
          <AccountingPlatformCategorySelectWidget
            data-testid={`add-bill-v2-category-picker-${index}`}
            activeAccountingPlatform={activeAccountingPlatform}
            aria-label={accountingPlatformCategorySelectAriaLabel}
            labelProps={{
              label: accountingPlatformCategorySelectLabel,
            }}
            autoFocus={shouldAutoFocus && hasLineItems}
            {...categoriesPickerProps}
          />
          <Form.TextField
            {...descriptionFieldProps}
            data-testid={`add-bill-v2-category-line-item-description-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.categoryBased.description.label') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.categoryBased.description.ariaLabel', {
              index: naturalIndex,
            })}
          />
          {shouldShowClassField && (
            <AccountingPlatformBillLineItemLabelSelectWidget
              data-testid={`add-bill-v2-bill-line-item-label-picker-${index}`}
              initialLabelId={initialExternalLabelId}
              billLineItemLabelOptions={billLineItemLabelOptions}
              activeAccountingPlatform={activeAccountingPlatform}
              labelProps={{
                label: formatMessage('activities.addBillV2.lineItems.categoryBased.class.label'),
              }}
              isFetched
              {...labelFieldProps}
              aria-label={formatMessage('activities.addBillV2.lineItems.categoryBased.class.ariaLabel', {
                index: naturalIndex,
              })}
            />
          )}
          <Form.AmountField
            {...amountFieldProps}
            decimalScale={2}
            data-testid={`add-bill-v2-category-line-item-amount-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.categoryBased.amount.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.categoryBased.amount.ariaLabel', {
              index: naturalIndex,
            })}
            currency={currency}
            endElement={
              shouldShowAmountCurrency ? (
                <Container paddingX="s">
                  <Text color="neutral.darker">{currency}</Text>
                </Container>
              ) : undefined
            }
            isRequired
          />
          {onClickRemoveLineItem && (
            <DeleteLineItemMobile
              IndexNumber={index}
              label={formatMessage('activities.addBillV2.lineItems.removeItem.label')}
              onClick={onClickRemoveLineItem}
              testId="add-bill-v2-category-line-item-delete"
            />
          )}
        </Group>
      </FormLineItems.MobileListItem>
    );
  }

  return (
    <FormLineItems.Row>
      <FormLineItems.Cell size="xs" data-testid="add-bill-v2-category-line-item-number">
        <FormField labelProps={{ label: '', isHidden: true }} render={() => <>{index + 1}</>} />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="m">
        <AccountingPlatformCategorySelectWidget
          data-testid={`add-bill-v2-category-picker-${index}`}
          activeAccountingPlatform={activeAccountingPlatform}
          aria-label={accountingPlatformCategorySelectAriaLabel}
          {...categoriesPickerProps}
          size="small"
          autoFocus={shouldAutoFocus && hasLineItems}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="l">
        <FormField
          {...descriptionFieldProps}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.categoryBased.description.label'),
            isHidden: true,
          }}
          size="small"
          data-testid={`add-bill-v2-category-line-item-description-${index}`}
          render={(formFieldProps) => (
            <TextField
              {...formFieldProps}
              size="small"
              aria-label={formatMessage('activities.addBillV2.lineItems.categoryBased.description.ariaLabel', {
                index: naturalIndex,
              })}
            />
          )}
        />
      </FormLineItems.Cell>
      {shouldShowClassField && (
        <FormLineItems.Cell size="m">
          <AccountingPlatformBillLineItemLabelSelectWidget
            data-testid={`add-bill-v2-bill-line-item-label-picker-${index}`}
            initialLabelId={initialExternalLabelId}
            billLineItemLabelOptions={billLineItemLabelOptions}
            activeAccountingPlatform={activeAccountingPlatform}
            isFetched
            {...labelFieldProps}
            size="small"
          />
        </FormLineItems.Cell>
      )}
      <FormLineItems.Cell size="m">
        <FormField
          data-testid={`add-bill-v2-category-line-item-amount-${index}`}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.categoryBased.amount.placeholder'),
            isHidden: true,
          }}
          {...amountFieldProps}
          size="small"
          isRequired
          render={(formFieldProps) => (
            <AmountField
              {...formFieldProps}
              decimalScale={2}
              aria-label={formatMessage('activities.addBillV2.lineItems.categoryBased.amount.ariaLabel', {
                index: naturalIndex,
              })}
              currency={currency}
              endElement={
                shouldShowAmountCurrency ? (
                  <Container paddingX="s">
                    <Text color="neutral.darker">{currency}</Text>
                  </Container>
                ) : undefined
              }
            />
          )}
        />
      </FormLineItems.Cell>
      {onClickRemoveLineItem && (
        <FormLineItems.Cell size={48}>
          <DeleteLineItem
            ariaLabel={deleteButtonAriaLabel}
            onClick={onClickRemoveLineItem}
            IndexNumber={index}
            testId="add-bill-v2-category-line-item-delete"
          />
        </FormLineItems.Cell>
      )}
    </FormLineItems.Row>
  );
};
