import { Table, useTable } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useEntitledUsersTableColumns } from './useEntitledUsersTableColumns';

type EntitledUserTableProps = {
  collaborators: Collaborator<'user'>[] | [];
  isCollaboratorsLoading: boolean;
} & Pick<
  Parameters<typeof useTable<Collaborator>>[0],
  'selectedRows' | 'onRowSelectionChange' | 'onAllRowsSelectionChange' | 'rowSelectionTooltips' | 'disableRowSelection'
>;

export const EntitledUserTable = ({
  collaborators,
  isCollaboratorsLoading,
  selectedRows,
  rowSelectionTooltips,
  onRowSelectionChange,
  disableRowSelection,
  onAllRowsSelectionChange,
}: EntitledUserTableProps) => {
  const { formatMessage } = useMelioIntl();

  const columns = useEntitledUsersTableColumns();

  const tableProps = useTable({
    isHeaderSticky: true,
    isLoading: isCollaboratorsLoading,
    data: collaborators || [],
    columns,
    getRowId: (row) => row.id,
    selectedRows,
    onRowSelectionChange,
    onAllRowsSelectionChange,
    headerVariant: 'dark',
    rowSelectionTooltips,
    disableRowSelection,
    //TODO: add the getRowSelectionAriaLabel and getRowAriaLabel
    hideHeaderWhileLoading: true,
    allRowsSelectionAriaLabel: formatMessage(
      'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.table.selectAllRows'
    ),
    captionId: formatMessage(`widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.table.caption`),
  });

  return <Table {...tableProps} />;
};
