import { EditBillSubscriptionActivity, EditRecurringActivity } from '@melio/ap-activities';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useLocation, useNavigate } from '@melio/platform-utils';

import { useRouter } from '../../hooks/router.hooks';
import { useIsMissingKycComplianceInformation } from '../../hooks/useIsMissingKycComplianceInformation';
import { PayDashboardTabs } from '../../types/payDashboard.types';

export const EditBillSubscriptionScreen = ({ billSubscriptionId }: { billSubscriptionId: string }) => {
  const navigate = useNavigate();
  const { state } = useLocation<{ returnUrl?: string }>();
  const { replaceToPayDashboardTab } = useRouter();
  const [isNewPaymentFlowEnabled] = useFeature(FeatureFlags.NewPaymentFlow, false);
  const { missingKycComplianceInformation, onKycComplianceFormDone, setKycComplianceFormState } =
    useIsMissingKycComplianceInformation();

  const { returnUrl } = state || {};
  const handleDone = () => {
    onKycComplianceFormDone();
    handleClose();
  };

  const handleClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }

    replaceToPayDashboardTab(PayDashboardTabs.Scheduled);
  };

  if (isNewPaymentFlowEnabled) {
    return (
      <EditRecurringActivity
        billSubscriptionId={billSubscriptionId}
        onDone={handleDone}
        onClose={handleClose}
        missingKycComplianceInformation={missingKycComplianceInformation}
        setKycComplianceFormState={setKycComplianceFormState}
      />
    );
  }

  return <EditBillSubscriptionActivity billSubscriptionId={billSubscriptionId} onDone={handleClose} />;
};
