import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const SettingsSectionContainerTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      paddingBottom: { xs: 'm', s: 'l' } as never,
      paddingRight: 0,
      paddingLeft: 0,
      justifyContent: 'center',

      '&:not(:first-child)': {
        paddingTop: { xs: 'm', s: 'l' } as never,
      },

      '&:not(:last-child)': {
        borderBottom: `1px solid ${currentTheme.colors.neutral.light}`,
      },
    },
  },
});
