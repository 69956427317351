import { useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const useLineItemAccessibility = () => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const onDeleteLineItemSuccess = () => {
    toast({
      title: formatMessage('activities.addBillV2.lineItems.deletedSuccessfullyToast'),
      type: 'success',
    });
  };

  return {
    onDeleteLineItemSuccess,
  };
};
