import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const TopNavLayoutTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      flexWrap: 'wrap',
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center',
      gridGap: '16px',
      paddingX: '16px',
      height: { xs: '108px', s: '72px' } as never,
      borderBottom: `1px solid ${currentTheme.colors.neutral.light}`,
    },
    iconContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      padding: '8px',
    },
    smallIconContainer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 'calc(16px - 1px)',
      borderRight: `1px solid ${currentTheme.colors.neutral.light}`,
      backgroundColor: currentTheme.colors.neutral.white,
    },
    centerIcon: {
      maxHeight: '40px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});
