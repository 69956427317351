import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Avatar, Group, Typography } from '@melio/penny';
import { useState } from 'react';

import { toFullName } from '../utils/toFullName';

type Props = {
  firstName: string;
  lastName: string;
  email: string | null;
  role: string;
  roleVariant?: 'neutral' | 'secondary';
  menuItems?: ActionsDropdownMenuItemProps[];
};

export const CollaboratorItem = ({ role, firstName, lastName, email, roleVariant = 'neutral', menuItems }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fullName = toFullName(firstName, lastName);

  return (
    <Group data-testid="collaborator-item">
      <Group.Item alignSelf="flex-start">
        <Avatar name={fullName} />
      </Group.Item>
      <Group.Item grow={1}>
        <Group variant="vertical" spacing="xxs">
          <Typography.MainLabel
            label={fullName}
            variant="bold"
            badgeProps={{ label: role, status: 'neutral', type: roleVariant === 'secondary' ? 'secondary' : 'primary' }}
          />
          <Typography.Description label={email || ''} />
        </Group>
      </Group.Item>
      {!!menuItems?.length && (
        <Group.Item alignSelf="flex-start">
          <ActionsDropdownMenu
            size="small"
            label="actions"
            items={menuItems || []}
            isOpen={isMenuOpen}
            onOpenChange={setIsMenuOpen}
          />
        </Group.Item>
      )}
    </Group>
  );
};
