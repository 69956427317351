import { getIsVoidAndRefundEnabled } from '@melio/ap-domain';
import { Container, Group } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';
import { RefObject } from 'react';

import { isPaymentScheduledVoidToResend } from '../../../../../utils/pay-flow/Payment.utils';
import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { ResendedCheckBanner } from '../completed/components/ResendedCheckBanner';
import { UndepositedCheckBanner } from '../completed/components/UndepositedCheckBanner';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { PaidToSection } from '../sections/PaidToSection';
import { PayFromSection } from '../sections/PayFromSection';
import { PaymentApprovalDecisionSection } from '../sections/PaymentApprovalDecisionSection';
import { PaymentScheduledBySection } from '../sections/PaymentScheduledBySection';
import { SectionWrapper } from '../sections/SectionWrapper';
import { VendorReceivesSection } from '../sections/VendorReceivesSection';

export const PaymentInProgress = ({
  payment,
  billDetailsRef,
  approvalDecisions,
}: {
  payment: PaymentFullyExpanded;
  billDetailsRef: RefObject<HTMLDivElement>;
  approvalDecisions?: ApprovalDecision[];
}) => {
  const {
    vendor,
    deliveryMethod,
    timeline,
    note,
    maxEstimatedDelivery,
    estimatedDelivery,
    status,
    createdBy,
    history,
    processedTimeline,
  } = payment;

  const isVoidAndRefundEnabled = getIsVoidAndRefundEnabled(payment);
  const isVoidingToResend = isPaymentScheduledVoidToResend(payment);
  const description = usePaymentDescription(payment);

  return (
    <>
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-in-progress">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            {isVoidAndRefundEnabled && <UndepositedCheckBanner payment={payment} />}
            {isVoidingToResend && <ResendedCheckBanner payment={payment} />}
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <PaidToSection payment={payment} billDetailsRef={billDetailsRef} />
          </SectionWrapper>

          <SectionWrapper>
            <Group variant="vertical" hasDivider spacing="m">
              {approvalDecisions && approvalDecisions.length > 0 && (
                <PaymentApprovalDecisionSection approvalDecisions={approvalDecisions} />
              )}

              {createdBy && <PaymentScheduledBySection scheduledDate={history.createdAt} user={createdBy} />}
            </Group>
          </SectionWrapper>

          <SectionWrapper>
            <PayFromSection payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <VendorReceivesSection
              vendorName={vendor.name}
              deliveryMethod={deliveryMethod}
              deliveryPreference={payment.deliveryPreferenceType}
              estimatedDelivery={estimatedDelivery}
              maxEstimatedDelivery={maxEstimatedDelivery}
              processedTimeline={processedTimeline}
              memoToVendor={note}
              timeline={timeline}
              paymentStatus={status}
            />
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
