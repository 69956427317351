import { AutoPaymentAmountTypeEnum, AutoPaymentDeliveryDateTypeEnum, AutoPaymentOptions } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { inRange, keyBy } from 'lodash';
import { boolean, object, SchemaOf, string } from 'yup';

import { AutoPaymentActivationFormFields } from '../types';

export const useAutoPaymentActivationFormSchema = ({ autoPaymentFundingSources }: AutoPaymentOptions) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const indexedAutoPaymentFundingSources = keyBy(autoPaymentFundingSources, 'fundingSourceId');

  return object().shape({
    accountNumber: string().required(),
    fundingSourceId: string()
      .nullable()
      .required(
        formatMessage(
          'activities.vendor.autoPaymentActivationForm.screens.activation.form.fundingSourceId.validation.required.label'
        )
      ),
    deliveryDateType: string()
      .nullable()
      .required(
        formatMessage(
          'activities.vendor.autoPaymentActivationForm.screens.activation.form.deliveryDateType.validation.required.label'
        )
      ),
    scheduleDaysBefore: string()
      .nullable()
      .required()
      .when('deliveryDateType', {
        is: AutoPaymentDeliveryDateTypeEnum.DaysBeforeDueDate,
        then: (schema) =>
          schema
            .nullable()
            .required(
              formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.scheduleDaysBefore.validation.required.label'
              )
            ),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
    paymentAmountType: string()
      .nullable()
      .required(
        formatMessage(
          'activities.vendor.autoPaymentActivationForm.screens.activation.form.paymentAmountType.validation.required.label'
        )
      ),
    maxAmount: string()
      .nullable()
      .notRequired()
      .test(
        'is-valid',
        formatMessage(
          'activities.vendor.autoPaymentActivationForm.screens.activation.form.maxAmount.validation.amount.label'
        ),
        (value) => (value ? Number(value) > 0 : true)
      ),
    fixedAmount: string()
      .required()
      .nullable()
      .when('paymentAmountType', {
        is: AutoPaymentAmountTypeEnum.FixedAmount,
        then: (schema) =>
          schema
            .required(
              formatMessage(
                'activities.vendor.autoPaymentActivationForm.screens.activation.form.fixedAmount.validation.required.label'
              )
            )
            .nullable()
            .test('is-between', '', (value, testContext) => {
              const context = testContext.parent as unknown as AutoPaymentActivationFormFields;
              const autoPaymentFundingSource = indexedAutoPaymentFundingSources[context.fundingSourceId];

              if (autoPaymentFundingSource) {
                const { minFixedAmount, maxFixedAmount } = autoPaymentFundingSource;
                const isInRange = inRange(Number(value), minFixedAmount, maxFixedAmount);

                if (!isInRange) {
                  return testContext.createError({
                    message: formatMessage(
                      'activities.vendor.autoPaymentActivationForm.screens.activation.form.fixedAmount.validation.amount.label',
                      {
                        minFixedAmount: formatCurrency(minFixedAmount),
                        maxFixedAmount: formatCurrency(maxFixedAmount),
                      }
                    ),
                  });
                }
              }
              return true;
            }),
        otherwise: (schema) => schema.notRequired(),
      }),
    shouldNotifyOnScheduled: boolean(),
    shouldNotifyOnSent: boolean(),
  }) as SchemaOf<AutoPaymentActivationFormFields>;
};
