import { Container, Form, Group, IconButton } from '@melio/penny';
import {
  WorkflowCollaboratorsConditionTypeEnum,
  WorkflowNumericConditionTypeEnum,
  WorkflowVendorsConditionTypeEnum,
} from '@melio/platform-api';
import difference from 'lodash/difference';
import React, { useEffect, useRef } from 'react';

import { useFormContext } from '../../FormContext';
import { AmountCondition } from './AmountCondition';
import { SchedulerCondition } from './SchedulerCondition';
import { ConditionType, CriteriaOptionsType, useConditionsList } from './useConditionsList';
import { VendorCondition } from './VendorCondition';

export type CriteriaSelectorType = {
  index: number;
  onClickRemoveCriteria: (() => void) | null;
};
export const CriteriaSelector = ({ index, onClickRemoveCriteria }: CriteriaSelectorType) => {
  const selectRef = useRef<HTMLInputElement>(null);
  const { watch, registerField, setValue } = useFormContext();

  const allConditions = useConditionsList();
  const conditions = watch('criteria.conditions');
  const selectedCondition = conditions[index]?.condition.type;
  const isReadOnly = conditions.length === allConditions.length;

  useAutoOpenCrtiteriaSelector(selectRef, index !== 0 && index !== allConditions.length - 1);

  const changeCondition = (newConditionType: ConditionType, index: number) => {
    const newCondition = allConditions.find((cond) => cond.value === newConditionType);
    if (newCondition) {
      setValue(`criteria.conditions.${index}`, newCondition.condition);
    }
  };

  const onSelectCriteria = (value: React.ChangeEvent<HTMLInputElement>): void => {
    if (value.target.value === WorkflowNumericConditionTypeEnum.WorkflowNumericCondition) {
      changeCondition(WorkflowNumericConditionTypeEnum.WorkflowNumericCondition, index);
    } else if (value.target.value === WorkflowCollaboratorsConditionTypeEnum.WorkflowCollaboratorsCondition) {
      changeCondition(WorkflowCollaboratorsConditionTypeEnum.WorkflowCollaboratorsCondition, index);
    } else if (value.target.value === WorkflowVendorsConditionTypeEnum.WorkflowVendorsCondition) {
      changeCondition(WorkflowVendorsConditionTypeEnum.WorkflowVendorsCondition, index);
    }
  };

  const getAvailableOptions = (): CriteriaOptionsType[] => {
    const usedConditionType = conditions.map((cond) => cond.condition.type);
    const defaultConditionType = allConditions.map((cond) => cond.value);
    const availableTypes = difference(defaultConditionType, usedConditionType);
    if (selectedCondition) {
      availableTypes.push(selectedCondition);
    }
    const availableCondition = allConditions.filter((cond) => availableTypes.includes(cond.value));
    return availableCondition;
  };

  const renderConditionForm = () => {
    switch (selectedCondition) {
      case WorkflowNumericConditionTypeEnum.WorkflowNumericCondition:
        return <AmountCondition index={index} />;
      case WorkflowCollaboratorsConditionTypeEnum.WorkflowCollaboratorsCondition:
        return <SchedulerCondition index={index} />;
      case WorkflowVendorsConditionTypeEnum.WorkflowVendorsCondition:
        return <VendorCondition index={index} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Form.ContentBox colSpan={4}>
        <Form.Select
          {...registerField(`criteria.conditions.${index}`)}
          value={selectedCondition}
          error={undefined}
          ref={selectRef}
          onChange={onSelectCriteria}
          emptyState={{ label: '' }}
          data-testid={`criteria.${index}.type`}
          size="large"
          aria-label="criteria"
          options={getAvailableOptions()}
          isReadOnly={isReadOnly}
          isRequired
        />
      </Form.ContentBox>
      {renderConditionForm()}
      {onClickRemoveCriteria && (
        <Form.ContentBox colSpan={1}>
          <Group alignItems="flex-start" justifyContent="center" width="full" height="full">
            <Container paddingTop="xs">
              <IconButton
                data-testid={`approval-workflow-v2-delete-condition-${index}`}
                aria-label="icon-button-prev"
                icon="delete"
                onClick={onClickRemoveCriteria}
                size="medium"
                variant="naked"
              />
            </Container>
          </Group>
        </Form.ContentBox>
      )}
    </>
  );
};

export const useAutoOpenCrtiteriaSelector = (
  selectRef: React.RefObject<HTMLInputElement>,
  autoOpenCondition: boolean
) => {
  const shouldAutoOpen = useRef(true);
  useEffect(() => {
    if (autoOpenCondition && shouldAutoOpen.current) {
      setTimeout(() => selectRef.current?.click(), 0);
    }
    shouldAutoOpen.current = false;
  }, [autoOpenCondition, selectRef]);
};
