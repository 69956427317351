import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  BankAccountDeliveryMethod,
  FundingSourceVerificationErrorCode,
  isMicroDepositsRequest,
  PostFundingSourcesVerifyRequest,
  useDeliveryMethod,
  useFundingSource,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

type Props = {
  fundingSourceId?: string;
  deliveryMethodId?: string;
  onError?: ErrorFunction;
};

export const useMicroDepositVerification = ({ fundingSourceId, deliveryMethodId, onError }: Props) => {
  const { createTrackHandler, trackMarketing } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [errorCode, setErrorCode] = useState<FundingSourceVerificationErrorCode>();

  const handleFail = (error: PlatformError<FundingSourceVerificationErrorCode>) => {
    setErrorCode(error.code);
    onError?.(error);
  };

  const { toast } = useToast();
  const trackSubmit = createTrackHandler('MicroDepositVerification', 'Submitted');
  const verificationSuccessMessage = formatMessage(
    'activities.microDepositsVerification.screens.verification.successToastMessage'
  );

  const {
    data: fundingSource,
    verify,
    isMutating: isUpdating,
    refetch: refetchFundingSource,
  } = useFundingSource({ id: fundingSourceId, enabled: false });

  const {
    data: deliveryMethod,
    verify: verifyBankAccount,
    isMutating: isDeliveryMethodUpdate,
    refetch: refetchDeliveryMethod,
  } = useDeliveryMethod({
    id: deliveryMethodId,
    enabled: false,
  });

  const onSuccess = (payload: PostFundingSourcesVerifyRequest, onSuccessMicroDepositVerified?: VoidFunction) => {
    if (fundingSourceId) {
      verify(payload)
        .then(() => {
          toast({ type: 'success', title: verificationSuccessMessage });
          trackSubmit({ FundingSource: 'bank-account', Status: 'succeeded' });
          trackMarketing(
            payload.type === 'plaid'
              ? 'bank-add-plaid-microdeposit_funding-source-added'
              : 'bank-add-microdeposit_funding-source-added'
          );
          refetchFundingSource().catch((err) => handleFail(err));
          onSuccessMicroDepositVerified?.();
        })
        .catch((err) => {
          trackSubmit({ FundingSource: 'bank-account', Status: 'failed' });
          handleFail(err);
        });
    } else if (deliveryMethodId) {
      if (!isMicroDepositsRequest(payload)) {
        throw new Error('micro-deposit amounts are missing in the request');
      }
      verifyBankAccount({
        id: deliveryMethodId,
        amount1: payload.details.amount1,
        amount2: payload.details.amount2,
      })
        .then(() => {
          toast({ type: 'success', title: verificationSuccessMessage });
          trackSubmit({ ReceivingMethod: 'bank-account', Status: 'succeeded' });
          trackMarketing('vendor-company-delivery-method_add-delivery-method-success');
          refetchDeliveryMethod().catch((err) => handleFail(err));
          onSuccessMicroDepositVerified?.();
        })
        .catch((err) => {
          trackSubmit({ ReceivingMethod: 'bank-account', Status: 'failed' });
          handleFail(err);
        });
    }
  };

  const isBlocked = fundingSourceId
    ? fundingSource?.isBlocked
    : (deliveryMethod as BankAccountDeliveryMethod)?.details.isBlocked;

  return {
    fundingSource,
    deliveryMethod,
    isUpdating: isUpdating || isDeliveryMethodUpdate,
    onSuccess,
    refetchFundingSource,
    errorCode,
    isBlocked,
  };
};
