import { useState } from 'react';
import { compact } from 'lodash';
import {
  ActionsDropdownMenu,
  ActionsDropdownMenuItemProps,
  Badge,
  Button,
  Container,
  Group,
  Icon,
  IconButton,
  Link,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { InfoCard } from '@/cl/components/InfoCard/InfoCard.component';

type TaxPayerRequestStatusProps = {
  vendor: Vendor;
  formVisibility: 'hidden' | 'visible';
  onSendReminder: () => void;
  onShowForm: () => void;
  onCancelRequest: () => void;
  isMutating: boolean;
};

export const TaxPayerRequestStatus = ({
  vendor,
  onSendReminder,
  onShowForm,
  onCancelRequest,
  formVisibility,
  isMutating = false,
}: TaxPayerRequestStatusProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { lastTaxpayerIdentificationRequest } = vendor;
  const { formatMessage, formatDate } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const {
    settings: { contractorLearnMoreLink },
  } = useConfig();
  const { createTrackHandler } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');

  const actionMenuItems = compact([
    {
      label: formatMessage('widgets.taxPayerInfo.statusForm.reminder.label'),
      onClick: () => {
        onShowForm();
        trackActionClick({
          TaxId: 'request-sent',
          Cta: 'request-send-reminder',
        });
      },
      textStyle: 'body4Semi',
      dataTestId: 'taxpayer-info-menu-resend',
    },
    {
      label: formatMessage('widgets.taxPayerInfo.statusForm.cancel.label'),
      onClick: () => {
        onCancelRequest();
        trackActionClick({
          TaxId: 'request-sent',
          Cta: 'cancel-request',
        });
      },
      textStyle: 'body4Semi',
      variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
      dataTestId: 'taxpayer-info-menu-cancel',
    },
  ]);

  return (
    <Container data-testid="tax-payer-info-status-container" border="regular" paddingX="m" paddingY="m">
      <Group hasDivider={true} variant="vertical">
        <Group variant="horizontal" alignItems="flex-start" justifyContent="space-between" spacing="s">
          <Group variant="horizontal" alignItems="center" justifyContent="space-between" spacing="s">
            <Icon type="stopwatch" size="large" color="default" />
            <Container>
              <Group alignItems="center" spacing="s">
                <Text color={'neutral.black'} textStyle="body2Semi">
                  {formatMessage('widgets.taxPayerInfo.statusForm.heading.status.sent')}
                </Text>
                <Badge
                  type="secondary"
                  label={formatMessage('widgets.taxPayerInfo.statusForm.badge.status.pending')}
                  status="warning"
                />
              </Group>

              <Container data-testid="tax-payer-info-status-recipient">
                <Text textStyle="body4" color="neutral.darker">
                  {formatMessage('widgets.taxPayerInfo.info.label.sent', {
                    email: lastTaxpayerIdentificationRequest?.email,
                    date: (
                      <Text textStyle="body4Semi" color="neutral.darker">
                        {formatDate(lastTaxpayerIdentificationRequest?.history?.createdAt ?? undefined, {
                          dateStyle: 'medium',
                        })}
                      </Text>
                    ),
                  })}
                </Text>
              </Container>
            </Container>
          </Group>

          <Group>
            <ActionsDropdownMenu
              size="small"
              data-testid="taxpayer-info-menu"
              label={formatMessage('widgets.taxPayerInfo.form.menu.label')}
              items={actionMenuItems}
              isOpen={isMenuOpen}
              onOpenChange={setIsMenuOpen}
            />
          </Group>
        </Group>

        {formVisibility === 'visible' && (
          <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} justifyContent="space-between" spacing="s">
            <InfoCard
              icon={<IconButton variant="naked" size="small" icon="help-circle" />}
              label={formatMessage('widgets.taxPayerInfo.info.reminder.label', {
                learnMoreLink: (
                  <Text textStyle="body4" color={'brand.main'}>
                    <Link
                      variant="inline"
                      color="inherit"
                      href={contractorLearnMoreLink}
                      label={formatMessage('widgets.taxPayerInfo.info.link')}
                      newTab
                    />
                  </Text>
                ),
              })}
            />
            <Button
              variant="tertiary"
              leftElement={<Icon size="small" type="send" color="inherit" aria-hidden />}
              isLoading={isMutating}
              label={formatMessage('widgets.taxPayerInfo.form.reminder.request')}
              data-testid={'taxpayer-send-reminder-btn'}
              onClick={onSendReminder}
            />
          </Group>
        )}
      </Group>
    </Container>
  );
};
