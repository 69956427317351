import { Container, ContainerProps, Text, useBreakpointValue } from '@melio/penny';

type Props = {
  title: string;
  testId?: string;
};

export const LineItemsHeader = ({ title, testId }: Props) => {
  const headerPaddingTop = useBreakpointValue<ContainerProps['paddingTop']>({ xs: 's', m: 'm' });

  return (
    <Container paddingTop={headerPaddingTop} paddingBottom="xs">
      <Text as="h2" textStyle="heading3Semi" data-testid={testId}>
        {title}
      </Text>
    </Container>
  );
};
