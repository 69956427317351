import { Card, CardProps } from '@melio/penny';

export const SectionSettingCard = ({ children, ...props }: CardProps) => {
  const additionalProps = { border: '1px solid', borderColor: 'neutral.light', outline: 0, boxShadow: 'none' };

  return (
    <Card {...props} variant="default" paddingX="m" paddingY="m" {...additionalProps}>
      {children}
    </Card>
  );
};
