import { Link, SelectionCard, useDisclosure } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FreeChecksData, FundingSource, SupportedDeliveryMethodTypeOption } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { CheckProtectionModalWidget } from '../../../CheckProtectionModal/CheckProtectionModal.widget';
import { VirtualCardInfoModal } from '../../VirtualCardInfoModal';
import { useAddDeliveryMethodUtils } from './utils';

type AddDeliveryMethodCardProps = SupportedDeliveryMethodTypeOption & {
  fundingSourceType?: FundingSource['type'];
  freeChecks?: FreeChecksData;
  onClick: VoidFunction;
  isInternationalFx?: boolean;
};

export const AddDeliveryMethodCard = forwardRef<AddDeliveryMethodCardProps>(
  ({ type, supported, reason, fundingSourceType, onClick, isInternationalFx = false, freeChecks, ...props }, ref) => {
    const { isOpen: isCheckProtectionModalOpen, onOpen, onClose } = useDisclosure();
    const { formatMessage } = useMelioIntl();
    const { isOpen: isVirtualCardModalOpen, onOpen: onVcModalOpen, onClose: onVcModalClose } = useDisclosure();
    const { track } = useAnalytics();

    const handleOpenModalClick = (e: React.MouseEvent<HTMLLinkElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onOpen();
    };

    const handleVcModalOpen = (e: React.MouseEvent<HTMLLinkElement>) => {
      e.preventDefault();
      e.stopPropagation();
      track('DeliveryMethod', 'Click', {
        Cta: 'tell-me-more',
        PageName: 'choose-how-vendor-received-payment',
        Intent: 'information-page',
        DeliveryMethodType: 'virtual-card',
      });
      onVcModalOpen();
    };

    const link = (
      <Link
        href="#"
        data-testid="delivery-method-card-paper-check-protection-link"
        onClick={handleOpenModalClick}
        size="medium"
        label={formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.paper-check.description.link')}
      />
    );

    const { getDescription, getTooltipLabel, getIconKey, getLabel, getIsDisabled, getBadgeLabel, getBadgeAriaLabel } =
      useAddDeliveryMethodUtils({
        type,
        supported,
        link,
        fundingSourceType,
        freeChecks,
        reason,
        isInternationalFx,
      });

    const tooltipLabel = getTooltipLabel();
    const badgeLabel = getBadgeLabel();
    const badgeAriaLabel = getBadgeAriaLabel();
    const { description, actionLabel } = getDescription();

    const actionProps = actionLabel
      ? {
          action: {
            label: actionLabel,
            'aria-label': badgeAriaLabel,
            'data-testid': 'tell-me-more-action',
            onClick: handleVcModalOpen,
          },
        }
      : {};

    return (
      <>
        <SelectionCard
          data-component="AddDeliveryMethodCard"
          data-testid={`add-delivery-method-card-${type}${isInternationalFx ? '-fx' : ''}`}
          {...props}
          ref={ref}
          icon={getIconKey()}
          mainLabelProps={{
            label: getLabel(),
            ...(tooltipLabel && { tooltipProps: { label: tooltipLabel } }),
            ...(badgeLabel && {
              badgeProps: { label: badgeLabel, status: 'brand' },
            }),
          }}
          descriptionProps={{
            label: description,
            ...actionProps,
          }}
          onClick={onClick}
          isDisabled={getIsDisabled()}
        />
        {isVirtualCardModalOpen && <VirtualCardInfoModal onClose={onVcModalClose} addVirtualCardHandler={onClick} />}
        <CheckProtectionModalWidget isOpen={isCheckProtectionModalOpen} onClose={onClose} />
      </>
    );
  }
);

AddDeliveryMethodCard.displayName = 'AddDeliveryMethodCard';
