import { ReactElement } from 'react';
import { getAccountingPlatformNameForAnalytics } from '@melio/ap-domain';
import { Link } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { AccountingPlatformSlug } from '@melio/platform-api';

import { AccountingPlatformCard as AccountingPlatformCardCl } from '@/cl/components/AccountingPlatformCard/AccountingPlatformCard.component';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  onConnectedClicked: () => void;
  name: string;
  logo: ReactElement | null;
  isLoading: boolean;
  accountingPlatformSlug?: AccountingPlatformSlug;
};

export const AccountingPlatformErrorCard = ({
  name,
  logo,
  isLoading,
  accountingPlatformSlug,
  onConnectedClicked,
}: Props) => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const accountingPlatformNameForAnalytics = getAccountingPlatformNameForAnalytics(accountingPlatformSlug);
  const isQuickBooksDesktopAccountingPlatform = accountingPlatformSlug === AccountingPlatformSlug.QuickBooksDesktop;
  const buttons = [
    {
      text: isQuickBooksDesktopAccountingPlatform
        ? formatMessage('widgets.accountingPlatform.card.buttons.reconnect')
        : formatMessage('widgets.accountingPlatform.card.buttons.connect'),
      onClicked: onConnectedClicked,
      isLoading,
      dataTestId: 'accounting-platform-error-card-connect-button',
    },
  ];

  useAnalyticsView('SyncAccountingSoftware', true, true, {
    PageName: 'something-went-wrong',
    Flow: `${accountingPlatformNameForAnalytics}-sync`,
    Intent: `sync-to-${accountingPlatformNameForAnalytics}`,
  });

  const support = (
    <Link
      href={`mailto:${partnerConfig.supportEmail}`}
      variant="inline"
      label={formatMessage('widgets.accountingPlatform.card.links.support')}
      newTab
    />
  );

  const description = [formatMessage('widgets.accountingPlatform.card.type.error.type.invalidCredentials.description')];
  const error = isQuickBooksDesktopAccountingPlatform
    ? formatMessage('widgets.accountingPlatform.card.type.error.type.quickBooksDesktop.alert', {
        link: (
          <Link
            // TODO: Replace with the link to the QuickBooks Desktop Web Connector documentation
            // https://meliorisk.atlassian.net/browse/ME-65043
            size="medium"
            href="#"
            label={formatMessage('widgets.accountingPlatform.card.type.error.type.quickBooksDesktop.alert.link')}
            variant="standalone"
            data-testid="connecting-melio-and-qbdt-guide-link"
          />
        ),
      })
    : formatMessage('widgets.accountingPlatform.card.type.error.type.invalidCredentials.alert', { support });

  return (
    <AccountingPlatformCardCl
      title={name}
      errorText={error}
      descriptionList={description}
      buttons={buttons}
      logo={logo}
      accountingPlatformSlug={accountingPlatformSlug}
    />
  );
};
