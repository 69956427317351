import { useMelioIntl } from '@melio/ar-domain';
import { Modal, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { MarkAsPaidFormField, MarkInvoiceAsPaidForm } from '../../../components';

export type MarkInvoiceAsPaidScreenProps = {
  onSubmit: (data: MarkAsPaidFormField) => void;
  onClose: VoidFunction;
  isOpen: boolean;
  amount: number;
  paymentDate: Date;
  isLoading?: boolean;
  isSaving?: boolean;
};

export const MarkInvoiceAsPaidScreen = forwardRef<MarkInvoiceAsPaidScreenProps>(
  ({ amount, paymentDate, onSubmit, onClose, isOpen, isLoading, isSaving, ...props }, ref) => {
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
      useFormSubmissionController<MarkAsPaidFormField>();
    const { formatMessage } = useMelioIntl();

    useAnalyticsView('Invoice', true, true, {
      ProductName: 'ar',
      PageName: 'mark-invoice-as-paid',
      Flow: 'manage-invoice',
      Intent: 'mark-as-paid',
    });

    return (
      <Modal
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={() => !isSaving && onClose()}
        // TODO: https://meliorisk.atlassian.net/browse/ME-57709
        // disable close icon when `isSaving`
        header={formatMessage('ar.dashboard.activities.markInvoiceAsPaid.modal.header')}
        description={formatMessage('ar.dashboard.activities.markInvoiceAsPaid.modal.description')}
        primaryButton={{
          ...submitButtonProps,
          label: formatMessage('ar.dashboard.activities.markInvoiceAsPaid.modal.buttons.primary.label'),
          variant: 'primary',
        }}
        secondaryButton={{
          ...cancelButtonProps,
          label: formatMessage('ar.dashboard.activities.markInvoiceAsPaid.modal.buttons.secondary.label'),
          variant: 'tertiary',
          onClick: onClose,
        }}
        data-testid="mark-invoice-as-paid-modal"
        ref={ref}
        {...props}
      >
        <MarkInvoiceAsPaidForm
          isSaving={isSaving}
          isLoading={isLoading}
          defaultValues={{ amount, paymentDate }}
          onSubmissionStateChange={onSubmissionStateChange}
          onSubmit={onSubmit}
        />
      </Modal>
    );
  }
);
