/* eslint-disable react-hooks/exhaustive-deps */
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  AccountingPlatform,
  AccountingPlatformPaymentAccount,
  BankFundingSource,
  CardFundingSource,
  FlexFundingSource,
  FundingSource,
  useAccountingPlatformPaymentAccounts,
  useAccountingPlatforms,
  useFundingSource,
  useFundingSources,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { ReconciliationModalScreen, ReconciliationModalScreenProps } from './screens/Reconciliation.modal-screen';
export type ReconciliationModalActivityProps = Pick<
  ReconciliationModalScreenProps,
  'isOpen' | 'isLoading' | 'onClose'
> & {
  selectedFundingSourceId: FundingSource['id'];
  onError?: ErrorFunction;
  onDone: (
    AccountingPlatformPaymentAccountId: AccountingPlatformPaymentAccount['id'],
    isNewPaymentAccount?: boolean
  ) => void;
  onLoadingReconciliationFailed?: VoidFunction;
  isNewFundingSource?: boolean;
};

type TrackSubmittedProps = {
  Status: 'succeeded' | 'failed';
  Action: 'Adding' | 'Mapping';
  PMInternalName: string;
  PMExternalName: string;
  AccountingSoftwareId: AccountingPlatform['id'];
  PaymentMethodType: BankFundingSource['type'] | CardFundingSource['details']['type'] | FlexFundingSource['type'];
  AccountingSoftwareName: string;
};

export const ReconciliationModalActivity: React.VFC<ReconciliationModalActivityProps> = (props) => {
  const {
    selectedFundingSourceId,
    onDone,
    onError,
    onLoadingReconciliationFailed,
    isNewFundingSource,
    onClose,
    ...rest
  } = props;

  const { formatMessage } = useMelioIntl();

  const {
    activeAccountingPlatform,
    isLoading: isLoadingAccountingPlatforms,
    error: loadingAccountingPlatformsError,
  } = useAccountingPlatforms({});

  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);

  const {
    data: fundingSource,
    linkAccountingPlatformPaymentAccount,
    isLoading: isLoadingFundingSource,
    error: loadingFundingSourceError,
    refetch: refetchFundingSource,
    isMutating: isUpdatingFundingSource,
  } = useFundingSource({
    id: selectedFundingSourceId,
    enabled: !!selectedFundingSourceId,
  });
  const { refetch: refetchFundingSources } = useFundingSources();

  const {
    create: createAccountingPlatformPaymentAccount,
    data: accountingPlatformPaymentAccounts,
    isMutating: isCreatingAccountingPlatformPaymentAccount,
  } = useAccountingPlatformPaymentAccounts({
    accountingPlatformId: activeAccountingPlatform?.id as string,
    enabled: !!activeAccountingPlatform,
  });

  const loadingReconciliationError = loadingAccountingPlatformsError || loadingFundingSourceError;
  if (loadingReconciliationError) {
    onLoadingReconciliationFailed?.();
  }

  const { toast } = useToast();

  const { track, createTrackHandler } = useAnalytics();

  useEffect(() => {
    props.isOpen && track('PMReconciliationMatching', 'Viewed');
  }, [props.isOpen]);

  const trackSubmitted = (
    status: TrackSubmittedProps['Status'],
    accountingPlatformPaymentAccountId: AccountingPlatformPaymentAccount['id'] | undefined,
    isNewPaymentAccount?: boolean
  ) => {
    const selectedPaymentAccount = accountingPlatformPaymentAccounts?.find(
      (paymentAccount) => paymentAccount.id === accountingPlatformPaymentAccountId
    );
    createTrackHandler<TrackSubmittedProps>(
      'PMReconciliationMatching',
      'Submitted'
    )({
      Status: status,
      Action: isNewPaymentAccount ? 'Adding' : 'Mapping',
      AccountingSoftwareName: accountingPlatformName,
      AccountingSoftwareId: activeAccountingPlatform?.id,
      PaymentMethodType: fundingSource?.type === 'card' ? fundingSource?.details?.type : fundingSource?.type,
      PMInternalName: fundingSource?.displayName,
      PMExternalName: selectedPaymentAccount?.name ?? fundingSource?.displayName,
    });
  };

  const errorMessage = formatMessage('activities.reconciliationModalScreen.errorToastMessage');

  const handleFail =
    (
      accountingPlatformPaymentAccountId: AccountingPlatformPaymentAccount['id'] | undefined,
      isNewPaymentAccount?: boolean
    ) =>
    (error: PlatformError) => {
      toast({ type: 'error', title: errorMessage });
      trackSubmitted('failed', accountingPlatformPaymentAccountId, isNewPaymentAccount);
      onError?.(error);
    };

  const handleSuccess = (
    accountingPlatformPaymentAccountId: AccountingPlatformPaymentAccount['id'],
    isNewPaymentAccount?: boolean
  ) => {
    {
      const fundingSourceType = fundingSource?.type === 'bank-account' ? 'bank' : 'card';
      const paymentAccountType = isNewPaymentAccount ? 'new' : 'existing';

      const newFundingSourceSuccessMessage = formatMessage(
        `activities.reconciliationModalScreen.successToastMessage.newFundingSource.${fundingSourceType}`,
        { accountingPlatform: accountingPlatformName }
      );

      const existingFundingSourceSuccessMessage = formatMessage(
        `activities.reconciliationModalScreen.successToastMessage.existingFundingSource.${fundingSourceType}.${paymentAccountType}`,
        { accountingPlatform: accountingPlatformName }
      );

      const successMessage = isNewFundingSource ? newFundingSourceSuccessMessage : existingFundingSourceSuccessMessage;

      toast({ type: 'success', title: successMessage });
      trackSubmitted('succeeded', accountingPlatformPaymentAccountId, isNewPaymentAccount);
      onDone(accountingPlatformPaymentAccountId, isNewPaymentAccount);
    }
  };

  const linkFundingSource = (accountingPlatformPaymentAccountId: AccountingPlatformPaymentAccount['id']) =>
    linkAccountingPlatformPaymentAccount({ accountingPlatformPaymentAccountId })
      .then(() => handleSuccess(accountingPlatformPaymentAccountId, false))
      .catch(handleFail(accountingPlatformPaymentAccountId, false));

  const createNewPaymentAccount = (accountingPlatformPaymentAccountName: AccountingPlatformPaymentAccount['name']) =>
    createAccountingPlatformPaymentAccount({
      fundingSourceId: fundingSource?.id as string,
      name: accountingPlatformPaymentAccountName,
      type: fundingSource?.type === 'bank-account' ? 'bank' : 'credit',
    })
      .then(
        ({
          data: {
            data: { accountingPlatformPaymentAccountId },
          },
        }) => {
          Promise.all([refetchFundingSource(), refetchFundingSources()])
            .then(() => {
              handleSuccess(accountingPlatformPaymentAccountId, true);
            })
            .catch(handleFail(undefined, true));
        }
      )
      .catch(handleFail(undefined, true));

  const isLoading = isLoadingFundingSource || isLoadingAccountingPlatforms;

  const handleClose = () => {
    onClose();

    createTrackHandler(
      'PMReconciliationMatching',
      'Closed'
    )({
      Action: 'Closed',
      AccountingSoftwareName: accountingPlatformName,
      AccountingSoftwareId: activeAccountingPlatform?.id,
      PaymentMethodType: fundingSource?.type === 'card' ? fundingSource?.details?.type : fundingSource?.type,
      PMInternalName: fundingSource?.displayName,
    });
  };

  return (
    <ReconciliationModalScreen
      fundingSource={fundingSource}
      isLoading={isLoading}
      isSaving={isUpdatingFundingSource || isCreatingAccountingPlatformPaymentAccount}
      activeAccountingPlatform={activeAccountingPlatform}
      onClose={handleClose}
      {...rest}
      onCreateNewPaymentAccount={createNewPaymentAccount}
      onLinkFundingSource={linkFundingSource}
    />
  );
};
