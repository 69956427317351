import React from 'react';
import { Box } from '@chakra-ui/react';
import { useMelioIntl } from '@melio/i18n-tools/src/useMelioIntl';
import { Group, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { LabelCopy } from '@/cl/components/LabelCopy/LabelCopy.component';
import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SectionHeaderWithButton } from '@/widgets/settings-page/Common/SectionHeaderWithButton.widget';

export const PaymentPageSection = ({ link }: { link: string }) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const onCopy = () => {
    track('Settings', 'Click', {
      PageName: SettingsCardIdEnum.CompanySettings,
      Cta: 'copy-link',
    });
  };

  return (
    <Group variant="vertical" width="full" data-testid="payment-page-section">
      <SectionHeaderWithButton title={formatMessage('widgets.companySettings.paymentPage.title')} />
      <Box>
        <Text>{formatMessage('widgets.companySettings.paymentPage.content')}</Text>
        <LabelCopy
          testId="payment-page-link"
          variant="button"
          direction="column"
          size="medium"
          label={link.replace('https://', '')}
          link={link}
          buttonLabel={formatMessage('widgets.companySettings.paymentPage.copyLink')}
          successMessage={formatMessage('widgets.companySettings.paymentPage.successCopyToast')}
          errorMessage={formatMessage('widgets.companySettings.paymentPage.errorCopyToast')}
          onCopy={onCopy}
        />
      </Box>
    </Group>
  );
};
