import { Container, Group } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import {
  PlanSettingCard,
  SubscriptionBillingHistory,
  SubscriptionPaymentMethod,
  SubscriptionPlansSelect,
  useSubscriptionRouter,
} from '@melio/subscriptions';

import { SectionHeaderWithButton } from '../ManageCollaborators/screens/components/SectionHeaderWithButton';

export const SubscriptionPlansSettingsScreen = () => {
  const { formatMessage } = useMelioIntl();
  const router = useSubscriptionRouter();
  useAnalyticsView('Settings', true, false, {
    PageName: 'plans',
    Flow: 'settings',
    Intent: 'view-plans-page',
  });

  return (
    <Container paddingY="xs">
      <Group variant="vertical" spacing="xl" data-testid="subscription-plans">
        <PlanSettingCard>
          <SectionHeaderWithButton
            title={formatMessage('widgets.subscriptionPlanSettings.title.currentPlan')}
            titleAs="h3"
          />
          <SubscriptionPlansSelect />
        </PlanSettingCard>

        <PlanSettingCard>
          <SectionHeaderWithButton
            title={formatMessage('widgets.subscriptionPlanSettings.title.paymentMethod')}
            titleAs="h3"
          />
          <SubscriptionPaymentMethod
            onChange={() => {
              router.goToPaymentMethodSelect();
            }}
          />
        </PlanSettingCard>

        <PlanSettingCard>
          <SectionHeaderWithButton
            title={formatMessage('widgets.subscriptionPlanSettings.title.billingHistory')}
            titleAs="h3"
          />
          <SubscriptionBillingHistory />
        </PlanSettingCard>
      </Group>
    </Container>
  );
};
