import { GoodsReceivedFormWidget, GoodsReceivedFormWidgetFields } from '@melio/ap-widgets';
import { Modal, useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../../monitoring';

export type GoodsReceivedModalScreenProps = {
  isOpen: boolean;
  onDone: (goodsReceived: boolean) => void;
  onClose: VoidFunction;
  isSaving?: boolean;
  amountThreshold?: number;
  currency?: string;
};

export const GoodsReceivedModalScreen: React.VFC<GoodsReceivedModalScreenProps> = ({
  isOpen,
  onDone,
  onClose,
  isSaving,
  amountThreshold = 3000,
  currency,
  ...props
}) => {
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<GoodsReceivedFormWidgetFields>();
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
  }, [endAction]);

  return (
    <Modal
      ref={endActionMonitoring}
      isOpen={isOpen}
      onClose={onClose}
      data-testid="funding-source-selection-activity-goods-received-modal-screen"
      header={formatMessage('activities.fundingSourceSelection.screens.goodsReceived.title')}
      description={formatMessage('activities.fundingSourceSelection.screens.goodsReceived.content', {
        amount: formatCurrency(amountThreshold, currency),
      })}
      primaryButton={{
        variant: 'primary',
        label: formatMessage('activities.fundingSourceSelection.screens.goodsReceived.continue'),
        ...submitButtonProps,
      }}
      {...props}
    >
      <GoodsReceivedFormWidget
        onSubmit={({ goodsReceivedRadioValue }) => {
          startAction('goods_received_flow');
          onDone(goodsReceivedRadioValue === 'yes');
        }}
        onSubmissionStateChange={onSubmissionStateChange}
        isSaving={isSaving}
      />
    </Modal>
  );
};

GoodsReceivedModalScreen.displayName = 'FundingSourceSelectionActivity.GoodsReceivedModalScreen';
