import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PostApprovalDecisionEnum, usePayments, useTodos } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import { useTodosEnabled } from '../../todos-drawer/hooks/useTodosEnabled';

export const useBatchPaymentApprovalDecision = ({ onToastActionClick }: { onToastActionClick: VoidFunction }) => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { batchApprovalDecision: mutate, isMutating } = usePayments({ enabled: false });
  const { isEnabled: isTodosEnabled } = useTodosEnabled();
  const { refetch: refetchTodos } = useTodos({ enabled: false });

  const batchApprovalDecision = useCallback(
    async (ids: string[], decision: PostApprovalDecisionEnum, reason?: string) => {
      try {
        await mutate({ ids, approvalDecision: { decision, reason } });
        track('Dashboard', 'Status', {
          PageName: decision === 'approved' ? 'payment-approved' : 'payment-declined',
          Intent: 'view-approvals',
          Status: 'success',
        });

        toast({
          type: 'success',
          title: formatMessage(
            decision === PostApprovalDecisionEnum.Approved
              ? 'activities.payDashboard.approvalsTab.approveSuccessToast'
              : 'activities.payDashboard.approvalsTab.declineSuccessToast',
            { count: ids.length }
          ),
          id: 'payment-approval-decision-success',
          action: {
            type: 'link',
            text: formatMessage('activities.payDashboard.approvalsTab.approvalSuccessToastAction', {
              count: ids.length,
            }),
            onAction: onToastActionClick,
          },
        });
      } catch (err) {
        track('Payment', 'Status', {
          Intent: 'payment-approval',
          Status: 'error',
        });

        toast({
          type: 'error',
          title: formatMessage(
            decision === PostApprovalDecisionEnum.Approved
              ? 'activities.payDashboard.approvalsTab.approveErrorToast'
              : 'activities.payDashboard.approvalsTab.declineErrorToast'
          ),
          id: 'payment-approval-decision-error',
        });
      }
      if (isTodosEnabled) {
        refetchTodos();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { batchApprovalDecision, isMutating };
};
