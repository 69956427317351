import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useRouter } from '@/hooks/router.hooks';
import { CompleteMtlDetailsScreen } from '@/screens/CompleteMtlDetails.screen';

export const CompleteDetailsRoute = ({ isTriggerManually }: { isTriggerManually?: boolean }) => {
  const { goHome } = useRouter();

  return (
    <Routes>
      <Route
        path="*"
        element={
          <CompleteMtlDetailsScreen
            isTriggerManually={isTriggerManually}
            onClose={isTriggerManually ? goHome : undefined}
          />
        }
      />
    </Routes>
  );
};
