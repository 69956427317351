import { FormattedDate, useMelioIntl } from '@melio/platform-i18n';
import { isToday, isTomorrow } from 'date-fns';

type FormatDateOptions = Parameters<typeof FormattedDate>[0];

export const useRelativeDateText = () => {
  const { formatDate, formatMessage } = useMelioIntl();

  return {
    formatRelativeDate: ({ date, options = { dateStyle: 'medium' } }: FormatDateOptions) => {
      if (isToday(date)) {
        return formatMessage(`app.today`);
      }

      if (isTomorrow(date)) {
        return formatMessage(`app.tomorrow`);
      }

      return formatDate(date, options);
    },
  };
};
