import { AccountingPlatformBillLineItemLabelOption } from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

import { BillLineItemLabelOption } from './AccountingPlatformBillLineItemLabelSelect.widget.types';

export const getPlaceholder = (
  isQuickBooksOnline: boolean,
  formatMessage: (id: MessageKey, values?: Record<string, unknown>) => string,
  isViewMode?: boolean
) => {
  if (isViewMode) {
    if (isQuickBooksOnline) {
      return formatMessage('form.billLineItemLabelSelect.viewModePlaceholder.quickbooks');
    }
    return formatMessage('form.billLineItemLabelSelect.viewModePlaceholder.xero');
  }

  if (isQuickBooksOnline) {
    return formatMessage('form.billLineItemLabelSelect.placeholder.quickbooks');
  }

  return formatMessage('form.billLineItemLabelSelect.placeholder.xero');
};

export const generateBillLineItemLabelSelectOptions = (
  options: AccountingPlatformBillLineItemLabelOption[] = []
): BillLineItemLabelOption[] => {
  const optionsMap = options.reduce<
    Record<string, BillLineItemLabelOption & Pick<AccountingPlatformBillLineItemLabelOption, 'parentOptionId'>>
  >(
    (acc, curr) => ({
      ...acc,
      [curr.id]: {
        label: curr.name,
        value: curr.id,
        subOptions: [] as BillLineItemLabelOption[],
        parentOptionId: curr.parentOptionId,
      },
    }),
    {}
  );

  return options.reduce<BillLineItemLabelOption[]>((acc, { id }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const option = optionsMap[id]!;
    const parentCategory = option.parentOptionId && optionsMap[option.parentOptionId];
    if (parentCategory) {
      parentCategory.subOptions?.push(option);
    } else {
      acc.push(option);
    }

    return acc;
  }, []);
};
