import { Group, Illustration, IllustrationProps, useBreakpointValue } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import {
  AutoPaymentAction,
  AutoPaymentAmountDetails,
  useAutoPaymentActivationSuccessMessages,
} from './useAutoPaymentActivationSuccessMessages.hook';

const PAGE_NAME = {
  [AutoPaymentAction.Create]: 'set-up-autopay',
  [AutoPaymentAction.Edit]: 'autopay-updated',
};

const INTENT = {
  [AutoPaymentAction.Create]: 'set-autopay',
  [AutoPaymentAction.Edit]: 'edit-autopay',
};

export type AutoPaymentActivationSuccessScreenProps = {
  vendorName: string;
  autoPaymentAmountDetails: AutoPaymentAmountDetails | undefined;
  action: AutoPaymentAction;
  onDone: () => void;
};

export const AutoPaymentActivationSuccessScreen = withAnalyticsContext<AutoPaymentActivationSuccessScreenProps>(
  ({ vendorName, autoPaymentAmountDetails, action, onDone, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { getTitleMessage, getDescriptionMessage } = useAutoPaymentActivationSuccessMessages();

    const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });
    const { endAction } = useMonitoring<MonitoredAction>();

    const { track, createTrackHandler } = useAnalytics();
    const trackAndHandleClick = createTrackHandler<{
      Cta: 'back-to-dashboard';
    }>('PaymentMethod', 'Click');

    setAnalyticsProperties({
      PageName: PAGE_NAME[action],
      Intent: INTENT[action],
      Flow: 'autopay',
    });

    const onReady = useCallback(
      (ref) => {
        if (ref) {
          track('PaymentMethod', 'View');
          endAction(
            action === AutoPaymentAction.Create
              ? 'auto_pay_activation_creation_submitted'
              : 'auto_pay_activation_edit_submitted'
          );
        }
      },
      [action, endAction, track]
    );

    const title = getTitleMessage({
      action,
    });

    const description = getDescriptionMessage({
      autoPaymentAmountDetails,
      vendorName,
    });

    return (
      <NewSinglePaymentStepLayout
        ref={onReady}
        data-component="AutoPaymentActivationSuccessScreen"
        data-testid="auto-payment-activation-success-screen"
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              data-testid="auto-payment-activation-success-submit-button"
              label={formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.submit')}
              link={{
                href: '#',
              }}
              onClick={(e) =>
                trackAndHandleClick({ Cta: 'back-to-dashboard' }, () => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDone();
                })
              }
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <Group justifyContent="center">
          <Illustration type="success" size={illustrationSize} aria-hidden />
        </Group>
        <NewSinglePaymentStepLayout.Title data-testid="auto-payment-activation-success-title">
          {title}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description data-testid="auto-payment-activation-success-description">
          {description}
        </NewSinglePaymentStepLayout.Description>
      </NewSinglePaymentStepLayout>
    );
  }
);
