import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const BackToPartnerButtonTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    backIconContainer: {
      width: { xs: '72px', s: '107px' } as never,
      height: { xs: '32px', s: '48px' } as never,
      border: '2px solid ',
      borderRadius: '4px',
      borderColor: currentTheme.colors.neutral.black,
      justifyContent: 'center',
      alignItems: 'center',
      gridGap: '5.33px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
});
