import React from 'react';
import { getFundingSourceIconType, getFundingSourceImageProps } from '@melio/ap-domain';
import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { useGetFundingSourceLabel } from '@/utils/FundingSources.utils';

export const PayFromSectionPaymentFailed = ({ fundingSource }: { fundingSource: FundingSource }) => {
  const { formatMessage } = usePlatformIntl();
  const { displayName, nickname } = fundingSource;

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);
  return (
    <>
      <Text textStyle="body4SemiUpper" color="neutral.darker">
        {formatMessage('widgets.paymentDetails.payFrom.title')}
      </Text>
      <PaymentReviewLineItem
        labelProps={{ label: fundingSourceLabel }}
        mainLabelProps={{ label: nickname || displayName }}
        {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
      />
    </>
  );
};
