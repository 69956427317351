import { useMelioIntl } from '@melio/ar-domain';
import { Chip, Group, SearchBar as DSSearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useFiltersLabel } from '../hooks';
import { Filter } from '../types';

export type FilterBarProps = {
  selectedFilter: Filter;
  onSelectFilter: (filter: Filter) => void;
  search?: string;
  onSearch: (value: string) => void;
  filters: Filter[];
};

export const FiltersToolBar = forwardRef<FilterBarProps>(
  ({ onSelectFilter, selectedFilter, onSearch, search, filters, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const filtersLabel = useFiltersLabel();

    return (
      <Group
        variant="horizontal"
        justifyContent="flex-start"
        width="full"
        spacing="s"
        data-testid="pay-dashboard-payments-sub-and-search-tab"
        data-component={FiltersToolBar.displayName}
        {...props}
        ref={ref}
      >
        <DSSearchBar
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          label={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.label')}
          placeholder={formatMessage('ar.dashboard.activities.invoiceTable.searchInput.placeholder')}
          onClear={() => onSearch('')}
        />
        {filters.map((filter) => (
          <Chip
            key={filter}
            onClick={() => onSelectFilter(filter)}
            label={filtersLabel[filter]}
            isSelected={selectedFilter === filter}
            data-testid={`ar-dashboard-filter-${filter}`}
          />
        ))}
      </Group>
    );
  }
);
FiltersToolBar.displayName = 'FiltersToolBar';
