import { PaymentIntentsTableProps } from './components/PaymentIntentsTable/PaymentIntentsTable';

export type PaymentIntentsTableScreenProps = Omit<
  PaymentIntentsTableProps,
  'shouldDisplayStatus' | 'unreadyPaymentIntentsIds' | 'onRowExpand'
> & {
  isUpdating?: boolean;
  onBack: VoidFunction;
  onDone: VoidFunction;
  shouldDisplayVendorDetailsFailureBanner: boolean;
  immediatelyShowStatus?: boolean;
};

export const PaymentIntentsAnalyticsEvent = {
  BatchTable: 'BatchTable',
  BatchTableSchedulePayment: 'BatchTableSchedulePayment',
  BatchTableBatchAddPaymentMethod: 'BatchTableBatchAddPaymentMethod',
  CloseBatchTable: 'CloseBatchTable',
  BatchTableBatchEditPaymentMethod: 'BatchTableBatchEditPaymentMethod',
  BatchTableBatchEditDeductionDate: 'BatchTableBatchEditDeductionDate',
  BatchTableEditPaymentMethod: 'BatchTableEditPaymentMethod',
  BatchTableEditDeliveryMethod: 'BatchTableEditDeliveryMethod',
  BatchTableEditDeductionDate: 'BatchTableEditDeductionDate',
  BatchTableAddDeliveryMethod: 'BatchTableAddDeliveryMethod',
  BatchTableFastPayment: 'BatchTableFastPayment',
  BatchTableCancelFastPaymentCancel: 'BatchTableCancelFastPaymentCancel',
  BatchTableRemoveBillFromList: 'BatchTableRemoveBillFromList',
  BatchTableAddMemoToVendor: 'BatchTableAddMemoToVendor',
  BatchTableSetInvoiceNumber: 'BatchTableSetInvoiceNumber',
  BatchTableViewBillDetails: 'BatchTableViewBillDetails',
  BatchTableReconciliation: 'BatchTableReconciliation',
  BatchTableCombinedPaymentsExpandRow: 'BatchTableCombinedPaymentsExpand',
} as const;

export type PaymentIntentsAnalyticsEvent = keyof typeof PaymentIntentsAnalyticsEvent;
