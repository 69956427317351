import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const PaymentsOverviewTheme: ComponentThemeType<{ isDisabled: boolean }> = (currentTheme, { isDisabled }) => ({
  baseStyle: {
    paymentsOverviewContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    paymentsOverview: {
      width: '70px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    paymentsOverviewTitleSkeleton: {
      height: '10px',
      width: '30px',
      marginBottom: '10px',
    },
    paymentsOverviewValueSkeleton: {
      height: '10px',
      width: '10px',
    },
    paymentsOverviewNoValue: {
      ...currentTheme.textStyles.body3,
      color: isDisabled ? currentTheme.colors.neutral.light : currentTheme.colors.neutral.darker,
    } as never,
  },
});
