import { useRoleText } from '@melio/ap-domain';
import { Badge, Group, Table, Text } from '@melio/penny';
import { Collaborator, useCollaborator } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const UsersCell = ({ collaborator }: { collaborator: Collaborator<'user'> }) => {
  const { formatMessage } = useMelioIntl();
  const { data: actor } = useCollaborator({ id: 'me' });

  const { getRoleText } = useRoleText();

  const getLabel = () => {
    let label = '';
    if (collaborator.user) {
      label = `${collaborator.user.firstName} ${collaborator.user.lastName} `;
    }
    if (actor?.id === collaborator.id) {
      return label + formatMessage('activities.settings.collaborators.table.cells.user.actor');
    }
    return label;
  };

  return (
    <Table.Cell data-testid={`row-${collaborator.id}-users-name-cell`}>
      <Group variant="vertical" spacing="none">
        <Group alignItems="center" spacing="xxs">
          <Text textStyle="body3Semi" color="neutral.black">
            {getLabel()}{' '}
          </Text>
          <Badge
            type={collaborator.id === actor?.id ? 'primary' : 'secondary'}
            status="neutral"
            label={getRoleText(collaborator.roleUniqueName) || ''}
          />
        </Group>
      </Group>
    </Table.Cell>
  );
};
