import { Container, Group, IconButton, Layout, Text, useMelioForm, useToast, useWatch } from '@melio/penny';
import { useAccount, useVerifiedPersonas } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useState } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { FXPersonalDetailsCard } from './components/AccountHolderDetailsCard';
import { FXBusinessDetailsCard } from './components/BusinessDetailsCard';
import { FXDecisionMakerCard } from './components/DecisionMakerCard';
import { FXOwnershipDetailsCard } from './components/OwnershipDetailsCard';
import { UnsupportedBusinessTypeModal } from './components/UnsupportedBusinessTypeModal';
import { fXVerifiedPersonasFormToDataModel } from './fXVerifiedPersonasFormToDataModel';
import { useAccountHolderForm } from './hooks/useAccountHolderForm';
import { useDecisionMakerFormSchema } from './hooks/useDecisionMakerFormSchema';
import { useFxBusinessDetailsForm } from './hooks/useFxBusinessDetailsForm';
import { useGetUBOFormValues, useOwnershipDetailsForm } from './hooks/useOwnershipDetailsForm';
import { DecisionMakerForm, FXBusinessDetailsFormSteps } from './types';

export type AddFxDeliveryMethodBusinessDetailsActivityProps = {
  onDone?: VoidFunction;
  onClose?: VoidFunction;
};

export const AddFxDeliveryMethodBusinessDetailsActivity = ({
  onClose,
  onDone,
}: AddFxDeliveryMethodBusinessDetailsActivityProps) => {
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { formatMessage } = useMelioIntl();
  const [currentStep, setCurrentStep] = useState<FXBusinessDetailsFormSteps>(
    FXBusinessDetailsFormSteps.BUSINESS_DETAILS
  );
  const { toast } = useToast();
  const onFlowFinished = () => {
    endAction('fx_verified_persona_submit');
    onDone?.();
  };

  const {
    data: verifiedPersonas,
    isLoading: isUboLoading,
    create: createVerifiedPersonas,
    isCreating: isCreatingVerifiedPersonas,
  } = useVerifiedPersonas({
    onCreate: onFlowFinished,
    onCreateError: () => {
      toast({
        // @ts-expect-error Missing type on DS side
        'data-testid': 'fx-update-update-ubo-failure-toast',
        type: 'error',
        title: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.errors.general-error'
        ),
      });
    },
  });
  const { data: account, isLoading: isAccountLoading } = useAccount({
    id: 'me',
  });

  const [isBusinessDetailsCompleted, setBusinessDetailsCompleted] = useState(false);
  const [isAccountHolderFormCompleted, setIsAccountHolderFormCompleted] = useState(false);
  const [isUBOFormCompleted, setIsUBOFormCompleted] = useState(false);
  const [isUnsupportedBusinessTypeModalOpen, setIsUnsupportedBusinessTypeModalOpen] = useState(false);
  const [isBusinessTypeChanged, setIsBusinessTypeChanged] = useState(false);
  const [didShowUnsupportedBusinessTypeModal, setDidShowUnsupportedBusinessTypeModal] = useState(false);
  const getUboFormValues = useGetUBOFormValues();

  const {
    form: businessDetailsForm,
    isLoading: isLoadingBusinessDetailsForm,
    isMutating: isMutatingBusiness,
  } = useFxBusinessDetailsForm({
    onUnsupportedBusinessType: () => {
      setIsUnsupportedBusinessTypeModalOpen(true);
      setDidShowUnsupportedBusinessTypeModal(true);
      setIsBusinessTypeChanged(false);
    },
    onDone: () => {
      setBusinessDetailsCompleted(true);
      setCurrentStep(FXBusinessDetailsFormSteps.ACCOUNT_HOLDER);
    },
  });
  const selectedBusinessType = useWatch({
    control: businessDetailsForm.control,
    name: 'businessType',
  });

  const {
    form: accountHolderForm,
    isMutating: isUpdatingAccountHolder,
    isLoading: isLoadingAccountHolderForm,
  } = useAccountHolderForm({
    onDone: (values) => {
      const ubos = getUboFormValues({ accountHolderFormValues: values, verifiedPersonas });
      uboForm.reset({ ubos });
      setIsAccountHolderFormCompleted(true);
      setCurrentStep(FXBusinessDetailsFormSteps.UBO);
    },
  });

  const { form: uboForm } = useOwnershipDetailsForm({
    onDone: () => {
      setIsUBOFormCompleted(true);
      setCurrentStep(FXBusinessDetailsFormSteps.DECISION_MAKER);
    },
  });

  const decisionMakerFormSchema = useDecisionMakerFormSchema();
  const decisionMakerForm = useMelioForm<DecisionMakerForm>({
    onSubmit: () => {
      startAction('fx_verified_persona_submit');
      const request = fXVerifiedPersonasFormToDataModel({
        accountHolderForm,
        ownershipDetailsForm: uboForm,
        decisionMakerForm,
        existingPersonas: verifiedPersonas,
      });
      createVerifiedPersonas(request);
    },
    defaultValues: {
      decisionMakerFirstName: '',
      decisionMakerLastName: '',
      decisionMakerSsn: '',
    },
    schema: decisionMakerFormSchema,
  });

  const onBusinessDetailsExpandChanged = (step: FXBusinessDetailsFormSteps) => (isExpanded: boolean) => {
    if (isExpanded) {
      setCurrentStep(step);
    }
  };

  const isLoading = isUboLoading || isAccountLoading || isLoadingAccountHolderForm || isLoadingBusinessDetailsForm;

  return (
    <>
      <Layout
        variant="6Columns"
        data-testid="fx-dm-business-details-activity"
        isLoading={isLoading}
        header={{
          content: (
            <Container paddingRight="xl" paddingTop="xl">
              <Group justifyContent="flex-end" width="full">
                <IconButton icon="close" onClick={onClose} variant="naked" size="medium" aria-label="Go back" />
              </Group>
            </Container>
          ),
        }}
      >
        <Group variant="vertical" spacing="xl">
          <Group alignItems="center" variant="vertical" textAlign="center">
            <Text textStyle="heading1Semi">
              {formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.title', {
                vendorName: account?.company.name ?? '',
              })}
            </Text>
            <Text>{formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.subtitle')}</Text>
          </Group>
          <Group variant="vertical">
            <FXBusinessDetailsCard
              form={businessDetailsForm}
              isExpanded={currentStep === FXBusinessDetailsFormSteps.BUSINESS_DETAILS}
              onExpandChange={onBusinessDetailsExpandChanged(FXBusinessDetailsFormSteps.BUSINESS_DETAILS)}
              isCompleted={isBusinessDetailsCompleted}
              businessName={account?.company.name ?? ''}
              isLoading={isMutatingBusiness}
              isBlockedFromSubmitting={didShowUnsupportedBusinessTypeModal && !isBusinessTypeChanged}
              onBusinessTypeChanged={() => {
                setIsBusinessTypeChanged(true);
              }}
            />
            <FXPersonalDetailsCard
              isExpanded={currentStep === FXBusinessDetailsFormSteps.ACCOUNT_HOLDER}
              form={accountHolderForm}
              onExpandChange={onBusinessDetailsExpandChanged(FXBusinessDetailsFormSteps.ACCOUNT_HOLDER)}
              isCompleted={isAccountHolderFormCompleted}
              isAllowedToBeUbo={!verifiedPersonas?.length}
              isLoading={isUpdatingAccountHolder}
            />
            <FXOwnershipDetailsCard
              isExpanded={currentStep === FXBusinessDetailsFormSteps.UBO}
              form={uboForm}
              onExpandChange={onBusinessDetailsExpandChanged(FXBusinessDetailsFormSteps.UBO)}
              isDisabled={currentStep < FXBusinessDetailsFormSteps.UBO}
              isCompleted={isUBOFormCompleted}
            />
            <FXDecisionMakerCard
              isExpanded={currentStep === FXBusinessDetailsFormSteps.DECISION_MAKER}
              form={decisionMakerForm}
              owners={uboForm.getValues('ubos')}
              accountHolder={accountHolderForm.getValues()}
              isDisabled={currentStep < FXBusinessDetailsFormSteps.DECISION_MAKER}
              isLoading={isCreatingVerifiedPersonas}
            />
          </Group>
        </Group>
      </Layout>
      <UnsupportedBusinessTypeModal
        isOpen={isUnsupportedBusinessTypeModalOpen}
        onClose={() => {
          setIsUnsupportedBusinessTypeModalOpen(false);
        }}
        businessType={selectedBusinessType}
      />
    </>
  );
};
