import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const ReviewScannedInvoiceScreenWidgetTheme: ComponentThemeType = () => ({
  baseStyle: {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'left',
    },
    toolbar: {
      display: 'flex',
      py: '16px',
      px: '40px',
      width: '100%',
      justifyContent: 'flex-end',
    },
    contentContainer: {
      flexGrow: 1,
      flexDirection: { xs: 'column', s: 'row' } as never,
      overflow: { xs: 'auto', s: 'initial' } as never,
      flexWrap: { xs: 'initial', s: 'wrap' } as never,
      width: '100%',
      height: 0,
    },
    fileContainer: {
      display: 'flex',
      flexGrow: { xs: 5.5, xl: 7 } as never,
      flexBasis: 0,
      justifyContent: 'center',
      py: '40px',
      px: '40px',
      width: { xs: '100%', s: '50%' } as never,
      height: '100%',
      borderRight: { xs: 'none', s: '1px solid' } as never,
      borderRightColor: { xs: 'none', s: '#D8D8D8' } as never,
    },
    formContainer: {
      gridGap: 4,
      flexGrow: { xs: 4.5, xl: 3 } as never,
      flexBasis: 0,
      justifyContent: 'start',
      py: '40px',
      px: '40px',
      height: '100%',
      width: { xs: '100%', s: '50%' } as never,
      alignItems: 'start',
      overflowY: { xs: 'initial', s: 'auto' } as never,
    },
    actionBar: {
      flexDirection: 'row',
      justifyContent: { xs: 'center', s: 'flex-end' } as never,
      paddingX: '24px',
      paddingY: '16px',
      width: '100%',
      boxShadow: 300 as never,
    },
  },
});
