import { InternationalAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { useFxCommonValidations } from '../formUtils';
import { DecisionMakerForm, OTHER_DECISION_MAKER_KEY } from '../types';

export const useDecisionMakerFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { firstNameSchema, ssnSchema, addressSchema, lastNameSchema, dateOfBirthSchema } = useFxCommonValidations();
  const schema = yup.object().shape({
    uboId: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.decisionMakerField.required'
        )
      ),
    companyRole: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.companyRoleField.required'
        )
      ),
    decisionMakerFirstName: whenDecisionMakerIsOther({
      then: firstNameSchema,
    }),
    decisionMakerLastName: whenDecisionMakerIsOther({
      then: lastNameSchema,
    }),
    decisionMakerDayOfBirth: whenDecisionMakerIsOther({
      then: dateOfBirthSchema,
    }),
    decisionMakerSsn: whenDecisionMakerIsOther({
      then: ssnSchema,
    }),
    decisionMakerAddress: yup.object().optional().nullable().when('uboId', {
      is: isOtherUbo,
      then: addressSchema,
    }) as yup.SchemaOf<InternationalAddress>,
    termsAndConditions: yup
      .boolean()
      .oneOf(
        [true],
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.termsAndConditionsField.required'
        )
      )
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.termsAndConditionsField.required'
        )
      ),
  }) as yup.SchemaOf<DecisionMakerForm>;

  return schema;
};

const isOtherUbo = (id: string) => id === OTHER_DECISION_MAKER_KEY;

const whenDecisionMakerIsOther = ({ then }: { then: yup.AnySchema }) =>
  yup.mixed().when('uboId', {
    is: isOtherUbo,
    then,
    otherwise: yup.string().optional().nullable(),
  });
