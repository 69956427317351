import { useMtlMessages } from '@melio/ap-domain';
import { Modal, Text } from '@melio/penny';
import { OrganizationBusinessType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type UnsupportedBusinessTypeModalProps = {
  onClose: () => void;
  isOpen: boolean;
  businessType: OrganizationBusinessType;
};
export const UnsupportedBusinessTypeModal = ({ isOpen, onClose, businessType }: UnsupportedBusinessTypeModalProps) => {
  const { labels } = useMtlMessages();
  const { formatMessage } = useMelioIntl();
  return (
    <Modal
      data-testid="fx-unsupported-business-type-modal"
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Text textStyle="heading2Semi">
          {formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupporterdBusinessTypeModal.title'
          )}
        </Text>
      }
      primaryButton={{
        label: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupporterdBusinessTypeModal.cta'
        ),
        onClick: onClose,
        variant: 'primary',
      }}
    >
      <Text textStyle="body2">
        {formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.unsupporterdBusinessTypeModal.content',
          { businessType: labels.company.businessTypeOption(businessType) }
        )}
      </Text>
    </Modal>
  );
};
