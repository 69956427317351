import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useVendors } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo, useState } from 'react';

import { RankedVendor, recommendationsToVendors, recommendationToAnalyticsEvents } from '../utils';

export const useSelectVendorRecommendations = (recommendedVendors: RankedVendor[]) => {
  const [selectedVendorIds, updateSelectorVendorIds] = useState<Record<string, boolean>>({});
  const [isSavingVendors, setIsSavingVendors] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { batchCreate } = useVendors();
  const { toast } = useToast();
  const { track } = useAnalytics();

  const selectVendor = useMemo(
    () => (id: string) => {
      if (selectedVendorIds[id]) {
        const { [id]: omitted, ...rest } = selectedVendorIds;
        return updateSelectorVendorIds(rest);
      }

      updateSelectorVendorIds({ ...selectedVendorIds, [id]: true });
    },
    [selectedVendorIds]
  );

  const saveVendors = useMemo(
    () => async () => {
      const selectedVendors = recommendedVendors.filter((v) => selectedVendorIds[v.id]);

      try {
        setIsSavingVendors(true);

        const savedVendors = await batchCreate({ vendors: recommendationsToVendors(selectedVendors) });
        toast({ title: formatMessage('activities.recommendedVendors.saveVendors.success'), type: 'success' });
        const vendorsAdded = recommendationToAnalyticsEvents(selectedVendors).map((v) => ({
          ...v,
          NewVendorId: savedVendors.find((s) => s.name === v.VendorName)?.id,
        }));

        track('Vendor', 'Status', {
          Intent: 'add-vendors',
          Status: 'success',
          SuggestedVendorsAddedSuccess: vendorsAdded,
        });
      } catch (err) {
        track('Vendor', 'Status', {
          Intent: 'add-vendors',
          Status: 'failure',
          SuggestedVendorsAddFailed: recommendationToAnalyticsEvents(selectedVendors),
        });
        toast({ title: formatMessage('activities.recommendedVendors.saveVendors.error'), type: 'error' });
      }
    },
    [batchCreate, formatMessage, recommendedVendors, selectedVendorIds, toast, track]
  );

  return {
    selectedVendors: recommendedVendors.filter((v) => selectedVendorIds[v.id]),
    isVendorSelected: (id: string) => Boolean(selectedVendorIds[id]),
    selectVendor,
    isSavingVendors,
    saveVendors,
  };
};
