/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Flex, HStack } from '@chakra-ui/react';
import { VendorAvatar } from '@melio/ap-widgets';
import { Badge, Card, Text, useBreakpointValue } from '@melio/penny';
import { DeliveryMethodType, Vendor } from '@melio/platform-api';

import { HighlightedText } from '@/cl/components/HighlightedText/HighlightedText.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useScrollIntoView } from '@/hooks/useScrollIntoView.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { VendorCardTheme } from './VendorCard.component.theme';

export interface VendorCardProps {
  vendor: Vendor;
  onClickHandler: ({ vendorId }: { vendorId: string }) => void;
  isSelected: boolean;
  highlightedText?: string | undefined;
  locationIndexInList: number;
}

export const VendorCardBase = ({
  vendor,
  onClickHandler,
  isSelected,
  locationIndexInList,
  highlightedText = '',
}: VendorCardProps) => {
  const styles = useAppTheme(VendorCardTheme, {});
  const { formatMessage, formatPhone } = usePlatformIntl();
  const ref = React.useRef<HTMLDivElement>(null);
  const shouldShowBadge = vendor.deliveryMethods?.some((dm) => dm.type === DeliveryMethodType.VirtualAccount);

  useScrollIntoView({ ref, isSelected, locationIndexInList });
  const shouldSupportEllipsis = useBreakpointValue({ xs: false, s: true });

  const VendorHighlightedText = React.useCallback(
    ({ styleKey, text }: { styleKey: string; text: string }) => {
      return (
        <Text textStyle={styleKey === 'name' ? 'body2Semi' : 'body4'} shouldSupportEllipsis={shouldSupportEllipsis}>
          <HighlightedText highlightedText={highlightedText} text={text} />
        </Text>
      );
    },
    [highlightedText],
  );

  const { name: vendorName, externalLogoUrl: vendorExternalLogoUrl, isManaged: isVendorManaged } = vendor;

  return (
    <Card
      ref={ref}
      data-component={'vendor-card'}
      isSelected={isSelected}
      onClick={() => onClickHandler({ vendorId: vendor.id })}
    >
      <HStack spacing={2} alignItems={'center'} w={'full'}>
        <Flex data-component={'left-side'}>
          <VendorAvatar name={vendorName} logoUrl={vendorExternalLogoUrl} isManaged={isVendorManaged} />
        </Flex>
        <Flex data-component={'right-side'} sx={styles['rightSide']} direction={'column'}>
          <HStack justifyContent={'space-between'}>
            <VendorHighlightedText styleKey={'name'} text={vendor.name} />
            {shouldShowBadge && <Badge status="neutral" isReadOnly label={formatMessage('widgets.vendorCard.badge')} />}
          </HStack>
          {vendor.contact.email && <VendorHighlightedText styleKey={'email'} text={vendor.contact.email} />}
          {vendor.contact.phoneNumber && (
            <VendorHighlightedText styleKey={'phone'} text={formatPhone(vendor.contact.phoneNumber)} />
          )}
        </Flex>
      </HStack>
    </Card>
  );
};

export const VendorCard = React.memo(VendorCardBase);

VendorCard.displayName = 'VendorCard';
