import { AddManagedVendorForm } from './components/AddManagedVendorForm';
import { AddUnmanagedVendorForm } from './components/AddUnmanagedVendorForm';
import { AddVendorFormWidgetProps } from './types';

export const AddVendorFormWidget = ({ dropdownPortalRoot, ...props }: AddVendorFormWidgetProps) =>
  props.managed ? (
    <AddManagedVendorForm {...props} managed={props.managed} dropdownPortalRoot={dropdownPortalRoot} />
  ) : (
    <AddUnmanagedVendorForm {...props} />
  );

AddVendorFormWidget.displayName = 'AddVendorFormWidget';
