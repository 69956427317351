import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { AddBankAccountActivity } from '@melio/ap-activities';

import { usePaymentMethodActivityEvents } from '@/hooks/usePaymentMethodActivityEvents';

export const PaymentMethodBankSelectScreen = () => {
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');
  const { onBack, onDone, onClose } = usePaymentMethodActivityEvents(returnUrl);

  return <AddBankAccountActivity onBack={onBack} onClose={onClose} onDone={onDone} />;
};

PaymentMethodBankSelectScreen.displayName = 'PaymentMethodBankSelectScreen';
