import { Modal } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  companyName: string;
  isLoading: boolean;
  isOpen: boolean;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const ResendConfirmationModalScreen = ({ companyName, isLoading, isOpen, onDone, onClose }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      isOpen={isOpen}
      header={formatMessage(`activities.resendJoinOrganizationRequestModal.title`)}
      description={formatMessage(`activities.resendJoinOrganizationRequestModal.description`, {
        companyName,
      })}
      primaryButton={{
        label: formatMessage(`activities.resendJoinOrganizationRequestModal.confirm`),
        onClick: () => onDone(),
        variant: 'primary',
        isLoading,
      }}
      onClose={onClose}
      data-testid="resend-join-organization-request-modal-screen"
      data-component="ResendJoinOrganizationRequestModalActivity.ResendConfirmationModalScreen"
    />
  );
};

ResendConfirmationModalScreen.displayName = 'ResendJoinOrganizationRequestModalActivity.ResendConfirmationModalScreen';
