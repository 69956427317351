import { Group, Table, Text, Typography } from '@melio/penny';
import { Loan } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { LoansTabItem } from '../types';

type StatusList = Loan['status'][];

export const FinancingTabNextInstallmentCell = ({ loansTabItem }: { loansTabItem: LoansTabItem }) => {
  const { formatCurrency, formatMessage } = useMelioIntl();
  const {
    id,
    loan: { status, nextInstallmentAmount, nextInstallmentNumber, installments },
  } = loansTabItem;

  return (
    <Table.Cell data-testid={`financing-tab-row-${id}-next-installment-cell`} textAlign="end">
      {status === 'completed' ? (
        <Text textStyle="body3Semi" color="neutral.dark" data-testid="financing-tab-next-installment-cell-completed">
          {formatMessage('activities.payDashboard.financingTab.table.cells.nextInstallment.repaidInFull')}
        </Text>
      ) : (['active', 'failed'] as StatusList).includes(status) ? (
        <Group
          variant="vertical"
          spacing="xxs"
          alignItems="flex-end"
          data-testid={`financing-tab-next-installment-cell-${status}`}
        >
          <Text textStyle="body3Semi">{formatCurrency(nextInstallmentAmount)}</Text>
          <Typography.Description
            label={formatMessage('activities.payDashboard.financingTab.table.cells.nextInstallment.description', {
              installmentNumber: nextInstallmentNumber,
              totalInstallments: installments.length,
            })}
          />
        </Group>
      ) : (
        <Text textStyle="body3Semi" color="neutral.dark" data-testid={`financing-tab-next-installment-cell-${status}`}>
          {formatCurrency(0)}
        </Text>
      )}
    </Table.Cell>
  );
};
