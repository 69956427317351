import { useFormatExpirationDate } from '@melio/ap-domain';
import { BadgeProps } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useFundingSourceCardProps = ({
  fundingSource,
  isValue,
}: {
  fundingSource: FundingSource;
  isValue: boolean;
}): { title: string; description?: string; badgeProps?: Array<BadgeProps & { 'data-testid': string }> } => {
  const { formatMessage } = useMelioIntl();
  const { formatExpirationDate } = useFormatExpirationDate();

  switch (fundingSource.type) {
    case 'bank-account':
      return {
        title: fundingSource.displayName,
        description: fundingSource.nickname,
      };
    case 'card':
      return {
        title: fundingSource.displayName,
        description: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.description', {
          nickname: fundingSource.nickname,
          expirationDate: formatExpirationDate(fundingSource),
        }),
        badgeProps: isValue
          ? [
              {
                label: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.fee'),
                type: 'secondary',
                status: 'informative',
                'data-testid': 'funding-source-card-badge-card-fee',
              },
            ]
          : undefined,
      };
    default:
      return {
        title: 'not implemented',
        description: 'not implemented',
      };
  }
};
