import { FormattedMessage, InvoiceExpandedSummary, InvoiceTimeline } from '@melio/ar-domain';
import { Badge, Divider, Group, SectionBanner, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { DisplayStatusLabel, DisplayStatusLabelMap, InvoiceActionsHandlers } from '../../../utils';
import { useDrawerSectionBanner } from '../hooks';
import { InvoiceBasicDetails } from './InvoiceBasicDetails';
import { InvoicePaymentSummeryDetails } from './InvoicePaymentSummeryDetails';
import { InvoiceSummaryTitle } from './InvoiceSummaryTitle';

type DrawerBodyProps = {
  expandedInvoice: InvoiceExpandedSummary;
  invoiceTimeline: InvoiceTimeline;
  companyName: string;
  invoiceActions: InvoiceActionsHandlers;
};

export const InvoiceSummary = forwardRef<DrawerBodyProps>(
  ({ companyName, expandedInvoice, invoiceTimeline, invoiceActions }, ref) => {
    const { title, description, variant, icon } = useDrawerSectionBanner(
      expandedInvoice,
      invoiceActions,
      invoiceTimeline
    );

    const hideSectionBanner =
      expandedInvoice.displayStatus == 'open' && !expandedInvoice.isAchAllowed && !expandedInvoice.isCardAllowed;

    return (
      <Group variant="vertical" spacing="l" ref={ref}>
        <Text as="h3" ref={ref} textStyle="heading3Semi">
          <FormattedMessage id="ar.dashboard.activities.InvoiceDrawer.body.header.label" />
        </Text>
        <Badge
          type="secondary"
          status={DisplayStatusLabelMap[expandedInvoice.displayStatus]}
          label={DisplayStatusLabel[expandedInvoice.displayStatus]}
          data-testid="invoice-drawer-badge"
        />
        {!hideSectionBanner && (
          <SectionBanner description={description} title={title} variant={variant} icon={icon ?? undefined} />
        )}
        <Group variant="vertical" spacing="m">
          <InvoiceSummaryTitle expandedInvoice={expandedInvoice} />
          <InvoiceBasicDetails expandedInvoice={expandedInvoice} companyName={companyName} />
          <Divider />
          <InvoicePaymentSummeryDetails expandedInvoice={expandedInvoice} />
        </Group>
      </Group>
    );
  }
);
