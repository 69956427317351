import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const ReceiptsTableTheme: ComponentThemeType = (currentTheme) => {
  const headerCell = {
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: 0,
    borderLeft: `1px solid ${currentTheme.colors.neutral.lighter}`,
    padding: '16px 12px',
    ...currentTheme.textStyles.body4Semi,
  };

  const firstHeaderCell = {
    ...headerCell,
    borderBottomLeftRadius: '8px',
    borderLeft: 0,
  };

  const dataCell = {
    borderTop: `1px solid ${currentTheme.colors.neutral.lighter}`,
    padding: '16px 12px',
    backgroundColor: currentTheme.colors.neutral.white,
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: 0,
    borderLeft: `1px solid ${currentTheme.colors.neutral.lighter}`,
  };

  const firstDataCell = {
    ...dataCell,
    borderBottomLeftRadius: '8px',
    borderLeft: 0,
  };

  return {
    baseStyle: {
      dataRow: {
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
      },
      dataCell,
      firstDataCell,
      table: {
        border: `1px solid ${currentTheme.colors.neutral.light}`,
        borderCollapse: 'initial', //Overriding shared properties to allow border-radius
        borderSpacing: 0,
        borderRadius: '8px',
        backgroundColor: currentTheme.colors.neutral.lightest,
        width: '100%',
        display: 'table',
      },
      headerRow: {},
      headerCell,
      firstHeaderCell,
      receiptCell: {
        cursor: 'pointer',
      },
    } as never,
  };
};
