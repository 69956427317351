import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { Icon, Text, useTheme } from '@melio/penny';

import quickBooks from '@/images/quickBooksLogo.svg';
import { usePlatformIntl } from '@/translations/Intl';
import { PayBillCardHeader } from './CardHeader.widget';

export const QuickBooksCard = () => {
  const { formatMessage } = usePlatformIntl();
  const currentTheme = useTheme();
  const conditionKeys = ['automatic', 'realTime', 'reconcile'] as const;

  const getConditions = () => {
    return conditionKeys.map((conditionKey) => {
      const title = formatMessage(`widgets.payDashboardCards.quickBooksCard.conditions.${conditionKey}.title`);

      return (
        <Box key={conditionKey} sx={{ display: 'flex', gap: 'xs', marginBottom: '20px', alignItems: 'center' }}>
          <Box sx={{ color: currentTheme.colors.neutral.darkest, display: 'inline-flex' }}>
            <Icon type="checked" color="inherit" />
          </Box>
          <Text textStyle="body3Semi">{title}</Text>
        </Box>
      );
    });
  };

  return (
    <Flex direction={'column'}>
      <Image boxSize={'40px'} objectFit={'fill'} src={quickBooks} alt={'quickBooks'} mb={2} />
      <PayBillCardHeader
        title={formatMessage('widgets.payDashboardCards.quickBooksCard.title')}
        description={formatMessage('widgets.payDashboardCards.quickBooksCard.description')}
      />
      {getConditions()}
    </Flex>
  );
};
