import {
  _AsyncCreatableSelect as AsyncCreatableSelect,
  _AsyncCreatableSelectProps as AsyncCreatableSelectProps,
  _createFormFieldInput,
  _Option as Option,
} from '@melio/penny';
import { useAccountingPlatformBankAccounts } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent, useEffect, useState } from 'react';

export type AccountingPlatformBankAccountSelectWidgetProps = Omit<
  AsyncCreatableSelectProps,
  'loadOptions' | 'formatCreateLabel'
> & { newBankAccountName: string; disabledNewOption?: boolean };

export const AccountingPlatformBankAccountSelectWidget = _createFormFieldInput(
  forwardRef<AccountingPlatformBankAccountSelectWidgetProps, 'input'>(
    (
      {
        onChange,
        placeholder,
        newBankAccountName,
        onCreateOption,
        isReadOnly,
        isViewMode,
        viewModePlaceholder,
        disabledNewOption = false,
        ...props
      },
      ref
    ) => {
      const [accountingPlatformAccountOptions, setAccountingPlatformAccountOptions] = useState<Option<string>[]>([]);
      const [newOptionWasCreated, setNewOptionWasCreated] = useState(false);

      const {
        data: accountingPlatformBankAccount,
        isFetched,
        isLoading: isLoadingBankAccounts,
      } = useAccountingPlatformBankAccounts();

      const newBankAccountAlreadyExists = accountingPlatformAccountOptions?.find(
        (bankAccountOption) => bankAccountOption.label === newBankAccountName
      );

      const { formatMessage } = useMelioIntl();

      useEffect(() => {
        if (isFetched && accountingPlatformBankAccount?.length && accountingPlatformBankAccount?.length > 0) {
          setAccountingPlatformAccountOptions(
            accountingPlatformBankAccount?.map((accountingPlatformBankAccount) => ({
              value: accountingPlatformBankAccount?.id,
              label: accountingPlatformBankAccount?.name,
            }))
          );
        }
      }, [accountingPlatformBankAccount, isFetched]);

      const handleLoadOptions = (inputValue: string) =>
        Promise.resolve(
          accountingPlatformAccountOptions.filter((accountingPlatformAccountOption) =>
            accountingPlatformAccountOption.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        );

      const handleCreateOption = () => {
        if (newBankAccountName) {
          setAccountingPlatformAccountOptions((value) => [
            ...value,
            {
              value: newBankAccountName,
              label: newBankAccountName,
            },
          ]);
          onChange?.({ target: { value: newBankAccountName } } as ChangeEvent<HTMLSelectElement>);
          onCreateOption(newBankAccountName);
          setNewOptionWasCreated(true);
        }
      };

      return (
        <AsyncCreatableSelect
          data-component="AccountingPlatformAccountSelectWidget"
          ref={ref}
          defaultOptions={accountingPlatformAccountOptions}
          loadOptions={handleLoadOptions}
          openMenuOnFocus
          openMenuOnClick
          onChange={onChange}
          isLoading={isLoadingBankAccounts}
          placeholder={placeholder}
          viewModePlaceholder={viewModePlaceholder}
          onCreateOption={handleCreateOption}
          isValidNewOption={() => !disabledNewOption && !newOptionWasCreated && !newBankAccountAlreadyExists}
          formatCreateLabel={() => formatMessage('form.accountingPlatformAccountSelect.createLabel')}
          isReadOnly={isReadOnly}
          isViewMode={isViewMode}
          {...props}
        />
      );
    }
  )
);

AccountingPlatformBankAccountSelectWidget.displayName = 'AccountingPlatformBankAccountSelectWidget';
