import { Control, Form } from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformBillLineItemLabel,
  AccountingPlatformSlug,
  FxCurrency,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { AddBillV2FormDefaultValues, AddBillV2FormValues, AddBillV2SetValue, RegisterFieldFn } from '../../../../types';
import { CategoryBasedBillLineItemsForm } from '../../CategoryBasedBillLineItemsForm/CategoryBasedBillLineItemsForm';
import { ItemBasedBillLineItemsForm } from '../../ItemBasedBillLineItemsForm/ItemBasedBillLineItemsForm';
import { LineItemsHeader } from '../../LineItemsHeader';
import { UnSyncedLineItemsForm } from '../../UnSyncedLineItemsForm/UnSyncedLineItemsForm';
import { XeroSyncedLineItemsForm } from '../../XeroSyncedLineItemsForm/XeroSyncedLineItemsForm';

type Props = {
  formControl: Control<AddBillV2FormValues>;
  formDefaultValues?: AddBillV2FormDefaultValues;
  shouldRender?: boolean;
  isMinifiedView?: boolean;
  billLineItemLabels?: AccountingPlatformBillLineItemLabel[];
  activeAccountingPlatform?: AccountingPlatform;
  isConnectedToAccountingPlatform?: boolean;
  currency?: FxCurrency;
  setValue: AddBillV2SetValue;
  registerField: RegisterFieldFn;
};

export const LineItemsForm = ({
  formControl,
  formDefaultValues,
  shouldRender,
  isMinifiedView,
  currency,
  billLineItemLabels,
  activeAccountingPlatform,
  isConnectedToAccountingPlatform,
  setValue,
  registerField,
}: Props) => {
  const { formatMessage } = useMelioIntl();

  const isHidden = !shouldRender || !!isMinifiedView;

  if (!isConnectedToAccountingPlatform) {
    return (
      <UnSyncedLineItemsForm
        currency={currency}
        formControl={formControl}
        registerField={registerField}
        isHidden={isHidden}
      />
    );
  }

  if (activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero && !isMinifiedView) {
    return (
      <XeroSyncedLineItemsForm
        formControl={formControl}
        currency={currency}
        registerField={registerField}
        isHidden={isHidden}
        setValue={setValue}
      />
    );
  }

  const billLineItemLabelOptions = billLineItemLabels?.[0]?.options || undefined;

  return (
    <>
      {!!shouldRender && (
        <Form.ContentBox colSpan={16}>
          <LineItemsHeader
            title={formatMessage('activities.addBillV2.lineItems.synced.sectionTitle')}
            testId="add-bill-v2-synced-line-items-title"
          />
        </Form.ContentBox>
      )}
      <CategoryBasedBillLineItemsForm
        formControl={formControl}
        formDefaultValues={formDefaultValues}
        registerField={registerField}
        isHidden={isHidden}
        currency={currency}
        billLineItemLabelOptions={billLineItemLabelOptions}
        activeAccountingPlatform={activeAccountingPlatform}
      />
      <ItemBasedBillLineItemsForm
        formControl={formControl}
        formDefaultValues={formDefaultValues}
        registerField={registerField}
        setValue={setValue}
        currency={currency}
        isHidden={isHidden}
        billLineItemLabelOptions={billLineItemLabelOptions}
      />
    </>
  );
};
