import { StatusIconSolid } from '@melio/penny';
import {
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../utils';
import { PayDashboardTabs } from '../../../PayDashboard';
import { useTodosDescriptions } from '../../hooks/useTodosDescriptions';
import { TodoSection } from '../TodoSection';
import { isEmptySection, normalizeInboxItems } from '../utils';

export type TodoSectionOverdueInFutureInboxItemsProps = {
  overdueIn7Days?: TodoOverdueIn7DaysInboxItems;
  overdueInMoreThan7Days?: TodoOverdueInMoreThan7DaysInboxItems;
};

export const TodoSectionOverdueInFutureInboxItems = ({
  overdueIn7Days,
  overdueInMoreThan7Days,
}: TodoSectionOverdueInFutureInboxItemsProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToTab } = useActivitiesNavigate();
  const { createAmountDescription, createViewBillsAction, createDueDateDescription } = useTodosDescriptions();

  if (isEmptySection(overdueIn7Days) && isEmptySection(overdueInMoreThan7Days)) {
    return null;
  }

  const overdueIn7DaysTotalItems = overdueIn7Days?.items?.pagination?.totalCount ?? 0;
  const overdueInMoreThan7DaysTotalItems = overdueInMoreThan7Days?.items?.pagination?.totalCount ?? 0;
  const totalItems = overdueIn7DaysTotalItems + overdueInMoreThan7DaysTotalItems;
  const data = [...normalizeInboxItems(overdueIn7Days), ...normalizeInboxItems(overdueInMoreThan7Days)];

  const calcTitle = () => {
    if (totalItems === 1) {
      return formatMessage('widgets.todosDrawer.section.overdueInWeekBills.singular.title', {
        vendorName: data[0]?.vendorName ?? '',
      });
    } else if (overdueIn7DaysTotalItems > 0 && overdueInMoreThan7DaysTotalItems > 0) {
      return formatMessage('widgets.todosDrawer.section.overdueInTheFuture.title', {
        total: totalItems,
        totalIn7Days: overdueIn7DaysTotalItems,
      });
    }
    return formatMessage('widgets.todosDrawer.section.overdueInWeekBills.plural.title', {
      count: totalItems,
    });
  };

  const title = calcTitle();
  const description = [...createAmountDescription(totalItems, data)];
  if (totalItems === 1 && data[0]?.dueDate) {
    description.push(createDueDateDescription(data[0].dueDate));
  }

  const onClickAction = () => {
    const firstItem =
      overdueIn7Days && overdueIn7Days?.items.data.length > 0
        ? overdueIn7Days?.items.data[0]
        : overdueInMoreThan7Days?.items.data[0];

    navigateToTab(PayDashboardTabs.Bills, totalItems === 1 && firstItem ? { entityId: firstItem.payload?.id } : {});
  };
  const action = createViewBillsAction(totalItems, onClickAction);

  return (
    <TodoSection
      key={`section-${TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}`}
      icon={<StatusIconSolid variant="informative" size="medium" />}
      title={title}
      description={description}
      action={action}
      type={TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}
    />
  );
};
