import { Box } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
import { Text } from '@melio/penny';
import { Account } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { PaymentsOverviewTheme } from './PaymentsOverview.component.theme';

export type PaymentsOverviewProps = {
  isPaymentsOverviewLoading?: boolean;
  paymentsOverview?: Account<'billsAndPaymentsOverview'>['billsAndPaymentsOverview'];
  isDisabled?: boolean;
};

export const PaymentsOverview = ({
  isPaymentsOverviewLoading = false,
  paymentsOverview,
  isDisabled = false,
}: PaymentsOverviewProps) => {
  const styles = useAppTheme(PaymentsOverviewTheme, { isDisabled });

  const [isApprovalWorkflowsFeatureEnabled] = useFeature(FeatureFlags.ApprovalWorkflows, false, {
    shouldTrack: true,
  });

  const paymentsOverviewMapping = {
    bills: paymentsOverview?.unpaid.count || 0,
    ...(isApprovalWorkflowsFeatureEnabled
      ? {
          pending: paymentsOverview?.pending.count || 0,
        }
      : {}),
    scheduled: paymentsOverview?.scheduled.count || 0,
    failed: paymentsOverview?.failed.count || 0,
  };

  return (
    <Box sx={styles['paymentsOverviewContainer']}>
      {Object.entries(paymentsOverviewMapping).map(([key, value], i) => (
        <Box key={i} sx={styles['paymentsOverview']} data-testid={`payment-overview-info-${key}`}>
          {isPaymentsOverviewLoading ? (
            <>
              <Skeleton sx={styles['paymentsOverviewTitleSkeleton']} />
              <Skeleton sx={styles['paymentsOverviewValueSkeleton']} />
            </>
          ) : (
            <>
              <Box
                textStyle="caption1"
                color={isDisabled ? 'neutral.light' : 'neutral.darker'}
                textTransform="capitalize"
              >
                {key}
              </Box>
              <Text
                data-testid={`payment-overview-info-${key}-value`}
                textStyle="body3"
                color={
                  isDisabled
                    ? 'neutral.light'
                    : value
                    ? key === 'failed'
                      ? 'critical.main'
                      : 'neutral.black'
                    : 'neutral.darker'
                }
              >
                {value}
              </Text>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};
