import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ReceivingMethodsIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.5C6 1.567 7.567 0 9.5 0C11.433 0 13 1.567 13 3.5C13 5.433 11.433 7 9.5 7C7.567 7 6 5.433 6 3.5ZM11 3.5C11 2.67157 10.3284 2 9.5 2C8.67157 2 8 2.67157 8 3.5C8 4.32843 8.67157 5 9.5 5C10.3284 5 11 4.32843 11 3.5Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9.5C14 7.567 15.567 6 17.5 6C19.433 6 21 7.567 21 9.5C21 11.433 19.433 13 17.5 13C15.567 13 14 11.433 14 9.5ZM19 9.5C19 8.67157 18.3284 8 17.5 8C16.6716 8 16 8.67157 16 9.5C16 10.3284 16.6716 11 17.5 11C18.3284 11 19 10.3284 19 9.5Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11C3.8472 11 6.07212 11.6298 7.3517 12.8901L7.46799 13.011L9.99999 13.012C11.5986 13.012 13.0221 13.7668 13.9374 14.9416L13.998 15.023L19 15.024C20.4334 15.024 21.5049 15.4721 22.204 16.2497L22.3388 16.4095C22.7757 16.9618 22.9567 17.5199 22.9958 17.9503C23.0329 18.3577 22.8213 18.7426 22.4688 18.9305L22.3589 18.9809L10.7255 23.7316C9.7514 24.1089 8.67235 24.0865 7.71793 23.6764L7.49999 23.5749L0.552786 19.9477C0.251645 19.7962 0.0490965 19.5042 0.00780362 19.1735L0 19.0479V12.006C0 11.4901 0.38604 11.0649 0.883378 11.0068L0.999999 11H2ZM14.9276 17.1842C14.9752 17.4629 15 17.7495 15 18.0419C15 18.5578 14.6119 18.9351 14.1146 18.9932L13.998 19H6.99799C6.44571 19 5.99799 18.5496 5.99799 17.994C5.99799 17.4781 6.38403 17.0529 6.88137 16.9948L6.99799 16.988H12.827L12.8231 16.9762C12.4268 15.8633 11.401 15.1005 10.1762 15.0291L9.99999 15.024H6.99999C6.71455 15.024 6.4448 14.9014 6.25645 14.6907L6.18091 14.5951C5.54977 13.6886 3.69858 13.0728 2.21896 13.0162L2 13.012V18.4262L8.39442 21.7753C8.84165 22.0003 9.35379 22.0478 9.83037 21.913L10.0072 21.8539L20.619 17.4977C20.3266 17.237 19.8823 17.0687 19.2089 17.0402L19 17.0359H14.898L14.9276 17.1842Z"
      fill="#212124"
    />
  </Icon>
);
