import { Group, Icon, Link, Text } from '@melio/penny';

export const PlanDetailsRow = ({
  label,
  description,
  actionLink,
  testIdSuffix,
}: {
  label: string;
  description: string;
  actionLink?: {
    onClick?: (e: React.MouseEvent<HTMLLinkElement>) => void;
    label: string;
  };
  testIdSuffix?: string;
}) => (
  <Group width="full" variant="horizontal" alignItems="flex-start" spacing="s-m" as="li">
    <Icon type="checked-circle" aria-hidden="true" />
    <Group variant="vertical" data-testid={`subscription-plan-details-row-${testIdSuffix ?? ''}`} spacing="xxs">
      <Text>{label}</Text>

      <Group spacing="xxs" variant="horizontal">
        <Text textStyle="body4" color="neutral.700">
          {description}
        </Text>

        {actionLink?.onClick && (
          <Text textStyle="body4Semi">
            <Link href="#" onClick={actionLink.onClick} label={actionLink.label} variant="inline" color="secondary" />
          </Text>
        )}
      </Group>
    </Group>
  </Group>
);
