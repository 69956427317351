import { isEbill } from '@melio/ap-domain';
import {
  Bill,
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
  BillSubscriptionOptions,
  DeliveryPreferenceType,
  Payment,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { inRange } from 'lodash';
import { date, mixed, number, object, SchemaOf, string, StringSchema } from 'yup';

import { PaymentFlowFormFields } from '../../types';
import { getAvailableBalance } from '../util/amount';

export const usePaymentFlowFormSchema = (options?: {
  bill: Bill | undefined;
  payment: Payment | undefined;
  billSubscriptionOptions: BillSubscriptionOptions | undefined;
}): SchemaOf<PaymentFlowFormFields> => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { bill, payment, billSubscriptionOptions } = options || {};
  const { messageKey: positiveAmountMessageKey, validation: positiveAmountValidation } = createPositiveAmountTest();

  return object().shape({
    amountToPay: string()
      .required(formatMessage('activities.paymentFlow.form.content.amountToPay.required'))
      .test('amountToPayPositive', formatMessage(positiveAmountMessageKey), positiveAmountValidation)
      .test('validLessThanOrEqualBalance', '', (value, context) => {
        if (!bill) {
          return true;
        }

        if (isEbill(bill)) {
          return true;
        }

        const amount = Number(value);
        const billAvailableBalance = getAvailableBalance(bill, payment);

        return (
          amount <= billAvailableBalance.toNaturalUnit() ||
          context.createError({
            message: formatMessage(
              'activities.paymentFlow.form.content.amountToPay.validLessThanOrEqualBalance.label',
              {
                balance: formatCurrency(billAvailableBalance.toNaturalUnit()),
              }
            ),
          })
        );
      })
      .test('validBetweenMinimumAndMaximum', '', (value, context) => {
        if (!billSubscriptionOptions || !('fundingSourceId' in context.parent)) {
          return true;
        }

        const { fundingSourceId, recurrenceType } = context.parent as PaymentFlowFormFields;

        if (recurrenceType !== 'recurring') {
          return true;
        }

        const fundingSourceOption = billSubscriptionOptions.eligibleFundingSources?.find(
          (source) => source.id === fundingSourceId
        );

        if (!fundingSourceOption) {
          return true;
        }

        const minFixedAmount = fundingSourceOption.minAmount || 0;
        const maxFixedAmount = fundingSourceOption.maxAmount || Infinity;
        const isInRange = inRange(Number(value), minFixedAmount, maxFixedAmount);
        if (!isInRange) {
          return context.createError({
            message: formatMessage(
              'activities.paymentFlow.form.content.amountToPay.validBetweenMinimumAndMaximum.label',
              {
                minAmount: formatCurrency(minFixedAmount),
                maxAmount: formatCurrency(maxFixedAmount),
              }
            ),
          });
        }

        return true;
      }),
    vendorId: string().nullable().required(formatMessage('activities.paymentFlow.form.content.vendor.required')),
    deliveryMethodId: string().required(formatMessage('activities.paymentFlow.form.content.deliveryMethod.required')),
    fundingSourceId: string().required(formatMessage('activities.paymentFlow.form.content.fundingSource.required')),
    scheduleDate: date().nullable().required(),
    deliveryDate: date().when('vendorId', {
      is: (vendorId: string) => !!vendorId,
      then: date().nullable().required(formatMessage('activities.paymentFlow.form.content.deliveryDate.required')),
      otherwise: date().nullable().notRequired(),
    }),
    deliveryPreference: new StringSchema<DeliveryPreferenceType>(),
    noteToVendor: string().optional().nullable(),
    vendorEmail: string()
      .email(formatMessage('activities.paymentFlow.form.content.vendorEmail.validation.format'))
      .optional()
      .nullable(),

    paymentPurpose: string(),
    description: string(),
    invoice: mixed<File>(),

    recurrenceType: new StringSchema<'one_time' | 'recurring'>().oneOf(['one_time', 'recurring']).required(),
    intervalType: mixed().when('recurrenceType', {
      is: 'recurring',
      then: new StringSchema<BillSubscriptionIntervalTypeEnum>().required(
        formatMessage('activities.paymentFlow.form.content.recurring.frequency.required')
      ),
      otherwise: new StringSchema<BillSubscriptionIntervalTypeEnum>().notRequired(),
    }),
    startDate: mixed().when('recurrenceType', {
      is: 'recurring',
      then: date()
        .nullable()
        .required(formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.required')),
      otherwise: date().nullable().notRequired(),
    }),
    endPolicy: mixed().when('recurrenceType', {
      is: 'recurring',
      then: new StringSchema<BillSubscriptionEndPolicyEnum>().required(
        formatMessage('activities.paymentFlow.form.content.recurring.paymentDuration.required')
      ),
      otherwise: new StringSchema<BillSubscriptionEndPolicyEnum>().notRequired(),
    }),
    endDate: mixed().when('endPolicy', {
      is: BillSubscriptionEndPolicyEnum.EndDate,
      then: date().nullable().required(formatMessage('activities.paymentFlow.form.content.recurring.endDate.required')),
      otherwise: date().nullable().notRequired(),
    }),
    numOfOccurrences: mixed().when('endPolicy', {
      is: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
      then: number().required(formatMessage('activities.paymentFlow.form.content.recurring.occurrences.required')),
      otherwise: number().notRequired(),
    }),
    lastAmount: string()
      .test('lastAmountPositive', formatMessage(positiveAmountMessageKey), positiveAmountValidation)
      .notRequired(),
  });
};

const createPositiveAmountTest = (): { messageKey: MessageKey; validation: () => boolean } => ({
  messageKey: 'activities.paymentFlow.form.content.amountToPay.validGreaterThanZero.label',
  validation: (value?: string) => {
    if (!value) {
      return true;
    }
    const number = Number(value);
    return number > 0;
  },
});
