import { Button, Container, Divider, Group, Text } from '@melio/penny';
import { useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import type { Plan, SubscriptionBillingCycleEnum, SubscriptionPreview } from '../../../../../api';
import { SubscriptionCheckoutBillingTotal } from './components/SubscriptionCheckoutBillingTotal';
import { SubscriptionCheckoutPromoCode } from './components/SubscriptionCheckoutPromoCode';
import { SubscriptionCheckoutSummaryPlan } from './components/SubscriptionCheckoutSummaryPlan';
import { SubscriptionDetailedTotal } from './components/SubscriptionDetailedTotal';

type SubscriptionCheckoutSummaryProps = {
  preview?: SubscriptionPreview;
  selectedPlan: Plan;
  isSubscribing: boolean;
  onSubmit: () => void;
  promoCode?: string | null;
  onChangePromoCode: (promoCode?: string) => void;
  onDoneFetchingPromoCodeData: () => void;
  currentCycle: SubscriptionBillingCycleEnum;
  isLoading: boolean;
  isPromoCodeFromQS: boolean;
};
export const SubscriptionCheckoutSummary = ({
  preview,
  selectedPlan,
  isSubscribing,
  onSubmit,
  promoCode,
  onChangePromoCode,
  onDoneFetchingPromoCodeData,
  currentCycle,
  isLoading,
  isPromoCodeFromQS,
}: SubscriptionCheckoutSummaryProps) => {
  const { formatMessage } = useMelioIntl();
  const { data: fundingSources } = useFundingSources();
  const haveFundingSources = !!fundingSources?.length;

  return (
    <Group variant="vertical" spacing="s">
      <Text as="h2" textStyle="body2Semi" color="neutral.darker">
        {formatMessage('activities.subscription.checkout.summary.title')}
      </Text>

      <Container border="regular" paddingX="m" paddingY="m">
        <Group variant="vertical" spacing="m">
          <SubscriptionCheckoutSummaryPlan
            selectedPlan={selectedPlan}
            currentCycle={currentCycle}
            preview={preview}
            isLoading={isLoading}
          />
          <Divider />

          <SubscriptionCheckoutPromoCode
            promoCode={promoCode}
            isLoading={isLoading}
            isPromoCodeFromQS={isPromoCodeFromQS}
            preview={preview}
            onChangePromoCode={onChangePromoCode}
            onDoneFetchingPromoCodeData={onDoneFetchingPromoCodeData}
          />
          <SubscriptionDetailedTotal preview={preview} isLoading={isLoading} />
          <Divider />
          <SubscriptionCheckoutBillingTotal preview={preview} isLoading={isLoading} />

          <Button
            label={formatMessage('activities.subscription.checkout.summary.submit')}
            size="large"
            onClick={onSubmit}
            isLoading={isSubscribing}
            isDisabled={!haveFundingSources || isLoading}
            data-testid="subscription-checkout-submit"
          />
          <Text textStyle="body4" color="neutral.darker">
            {formatMessage('activities.subscription.checkout.summary.disclaimer')}
          </Text>
        </Group>
      </Container>
    </Group>
  );
};
