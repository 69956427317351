import { Container, Divider, Group, Layout, Text, useFormSubmissionController } from '@melio/penny';
import { Account, EbillsSubscriptionOptions, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';

import type { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { PageTitle } from '../../../../PageTitle/PageTitle';
import { EBillsSubscriptionForm } from './EBillsSubscriptionForm';
import { EbillsSubscriptionFormFields } from './types';

export const EBillsSusbscriptionFormScreen = ({
  vendor,
  account,
  ebillsSubscriptionOptions,
  onSubmit,
  onClose,
  isSaving,
}: {
  vendor: Vendor;
  account: Account;
  ebillsSubscriptionOptions: EbillsSubscriptionOptions;
  onSubmit: (data: EbillsSubscriptionFormFields) => void;
  onClose: () => void;
  isSaving: boolean;
}) => {
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<EbillsSubscriptionFormFields>();
  const { formatMessage } = useMelioIntl();
  const { routeReady } = useMonitoring<MonitoredAction>();

  return (
    <Layout
      variant="6Columns"
      ref={routeReady}
      backgroundColor="white"
      data-testid="ebills-subscription-form"
      footer={{
        isSticky: true,
        content: (
          <Container width="full" paddingX="s" paddingY="s">
            <Group variant="vertical" width="full" alignItems="flex-end">
              <Divider />
              <Group>
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  label={formatMessage('activities.ebillsSubscriptionForm.form.cancel.label')}
                  onClick={onClose}
                  size="medium"
                ></NewSinglePaymentStepLayout.SecondaryNextButton>
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  size="medium"
                  data-testid="ebills-subscription-form-submit-button"
                  label={formatMessage('activities.ebillsSubscriptionForm.form.submit.label')}
                />
              </Group>
            </Group>
          </Container>
        ),
      }}
    >
      <Container justifyContent="center" width="full">
        <PageTitle textStyle="heading1Semi">{formatMessage('activities.ebillsSubscriptionForm.form.title')}</PageTitle>
      </Container>
      <Text textStyle="body3">
        {formatMessage('activities.ebillsSubscriptionForm.form.description', { vendorName: vendor.name })}
      </Text>
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" spacing="m">
          <Text color="neutral.darkest" textStyle="body4">
            {formatMessage('activities.ebillsSubscriptionForm.form.requireFields')}
          </Text>
          <EBillsSubscriptionForm
            vendor={vendor}
            account={account}
            ebillsSubscriptionOptions={ebillsSubscriptionOptions}
            onSubmit={onSubmit}
            onSubmissionStateChange={onSubmissionStateChange}
            isSaving={isSaving}
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </Layout>
  );
};
