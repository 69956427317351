import { Badge, BadgeProps } from '@melio/penny';
import { ApprovalDecision, DeliveryMethodType, PaymentFullyExpanded } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { paymentDetailsSectionTypeFactory } from '../utils';

type Props = { payment: PaymentFullyExpanded; approvalDecisions?: ApprovalDecision[] };
export const PaymentStatusBadgeSection = ({ payment, approvalDecisions }: Props) => {
  const { formatMessage } = useMelioIntl();

  switch (paymentDetailsSectionTypeFactory(payment, approvalDecisions)) {
    case 'payment-scheduled': {
      if (payment.approvalDecisionStatus === 'pending') {
        return (
          <StatusBadge
            status="warning"
            label={formatMessage('widgets.paymentDetails.approvalPending.title')}
            data-testid="pay-dashboard-payment-pending-status"
          />
        );
      }
      if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualAccount) {
        return (
          <StatusBadge
            status="warning"
            label={formatMessage('widgets.paymentDetails.pendingVendor.title')}
            data-testid="pay-dashboard-payment-pending-vendor-status"
          />
        );
      }
      return (
        <StatusBadge
          status="informative"
          label={formatMessage('widgets.paymentDetails.scheduled.title')}
          data-testid="pay-dashboard-payment-scheduled-status"
        />
      );
    }
    case 'payment-in-progress':
      return (
        <StatusBadge
          status="success"
          label={formatMessage('widgets.paymentDetails.inProgress.title')}
          data-testid="pay-dashboard-payment-in-progress-status"
        />
      );
    case 'payment-completed':
      return (
        <StatusBadge
          status="success"
          label={
            payment.markedAsPaid
              ? formatMessage('widgets.paymentDetails.markedAsPaid.title')
              : formatMessage('widgets.paymentDetails.completed.title')
          }
          data-testid="pay-dashboard-payment-completed-status"
        />
      );
    case 'payment-approval-declined':
      return (
        <StatusBadge
          status="critical"
          label={formatMessage('widgets.paymentDetails.approvalDeclined.title')}
          data-testid="pay-dashboard-payment-approval-declined-status"
        />
      );
    case 'refund-in-progress':
      return (
        <StatusBadge
          status="neutral"
          label={formatMessage('widgets.paymentDetails.inProgressRefund.title')}
          data-testid="pay-dashboard-payment-refund-in-progress-status"
        />
      );
    case 'refund-completed':
      return (
        <StatusBadge
          status="success"
          label={formatMessage('widgets.paymentDetails.completedRefund.title')}
          data-testid="pay-dashboard-payment-refund-completed-status"
        />
      );
    case 'payment-canceled':
      return (
        <StatusBadge
          status="critical"
          label={formatMessage('widgets.paymentDetails.canceled.title')}
          data-testid="pay-dashboard-payment-canceled-status"
        />
      );
    case 'payment-failed': {
      if (payment.isFinanced && payment.loan?.status === 'canceled') {
        return (
          <StatusBadge
            status="critical"
            label={formatMessage('widgets.paymentDetails.canceled.title')}
            data-testid="payment-failed-status-bar-canceled"
          />
        );
      }
      return (
        <StatusBadge
          status="critical"
          label={formatMessage('widgets.paymentDetails.failed.title')}
          data-testid="payment-failed-status-bar-failed"
        />
      );
    }
  }
};

function StatusBadge(props: Omit<BadgeProps, 'type'>) {
  return <Badge type="secondary" {...props} />;
}
