import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const FooterLayoutTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: { xs: '48px', s: '40px' } as never,
      backgroundColor: currentTheme.colors.neutral.lightest,
      paddingY: '4px',
      paddingX: '16px',
      gap: '12px',
      borderTop: `1px solid ${currentTheme.colors.neutral.light}`,
    },
    link: {
      ...currentTheme.textStyles.body3,
      fontSize: '10px',
    } as never,
    melioLogo: {
      color: currentTheme.colors.neutral.darkest,
    },
  },
});
