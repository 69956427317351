import { useState } from 'react';

type RequiredDataProps = {
  stepId: string;
};

type UseStepManagerProps<T extends RequiredDataProps> = {
  data: T[];
  defaultActiveId?: string;
};

export const useStepManager = <T extends RequiredDataProps>({ data, defaultActiveId }: UseStepManagerProps<T>) => {
  const [steps, setSteps] = useState<
    (T & {
      isExpanded: boolean;
      isDisabled: boolean;
    })[]
  >(
    data.map((item, index) => ({
      ...item,
      isExpanded: defaultActiveId ? item.stepId === defaultActiveId : index === 0,
      isDisabled: defaultActiveId ? item.stepId !== defaultActiveId : index !== 0,
    }))
  );

  const nextStep = () => {
    const expandedStepIndex = steps.findIndex((step) => !!step.isExpanded);
    if (expandedStepIndex < steps.length - 1) {
      const nextStepId = steps[expandedStepIndex + 1]?.stepId;
      nextStepId && expandSelectedStep(nextStepId);
    }
  };

  const expandSelectedStep = (expandedStepId: string) =>
    setSteps((currentSelections) =>
      currentSelections.map((step) => ({
        ...step,
        isDisabled: step.stepId === expandedStepId ? false : step.isDisabled,
        isExpanded: step.stepId === expandedStepId,
      }))
    );

  return { steps, nextStep, expandSelectedStep };
};
