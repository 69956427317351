import { Modal, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export type CheckProtectionModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const CheckProtectionModalWidget: React.VFC<CheckProtectionModalProps> = ({ isOpen, onClose }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-component="CheckProtectionModalActivity"
      data-testid="check-protection-modal-activity"
      header={formatMessage('activities.checkProtectionModal.header')}
      description={formatMessage('activities.checkProtectionModal.description')}
      primaryButton={{
        onClick: onClose,
        variant: 'primary',
        label: formatMessage('activities.checkProtectionModal.close'),
      }}
    >
      <Typography.ParagraphList
        type="unordered"
        size="large"
        list={[
          {
            title: formatMessage('activities.checkProtectionModal.securing.title'),
            content: formatMessage('activities.checkProtectionModal.securing.content'),
            icon: 'shield',
          },
          {
            title: formatMessage('activities.checkProtectionModal.monitoring.title'),
            content: formatMessage('activities.checkProtectionModal.monitoring.content'),
            icon: 'monitor',
          },
          {
            title: formatMessage('activities.checkProtectionModal.handling.title'),
            content: formatMessage('activities.checkProtectionModal.handling.content'),
            icon: 'path',
          },
        ]}
      />
    </Modal>
  );
};

CheckProtectionModalWidget.displayName = 'CheckProtectionModal';
