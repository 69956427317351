import { Group, Link, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useSubscriptionMe } from '../../../api';
import { usePendingSubscription, useSubscription } from '../../../hooks';
import { ReactivatePlanModal } from '../ReactivatePlanModal/ReactivatePlanModal';
export const PendingSubscriptionPlanChange = () => {
  const { isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const { isReactivationAllowed, pendingPlanInfo } = usePendingSubscription();
  const { formatMessage, formatDate } = useMelioIntl();
  const subscription = useSubscription();

  return (
    <>
      <ReactivatePlanModal
        variant="cancel-pending-change"
        isOpen={showReactivateModal}
        onClose={() => setShowReactivateModal(false)}
      />
      {isReactivationAllowed && (
        <Group spacing="xs" variant="vertical">
          <Text textStyle="body3" color="neutral.darker">
            {formatMessage('activities.settings.pendingSubscriptionPlanChange.title.reactivatePlan.description', {
              date: formatDate(subscription?.endDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              }),
              planName: pendingPlanInfo.planName,
            })}
          </Text>
          <Link
            data-testid="pedning-subscription-reactivate-plan-button"
            isDisabled={isUpdatingSubscription}
            label={formatMessage('activities.settings.pendingSubscriptionPlanChange.title.reactivatePlan.submit')}
            href="#"
            color="secondary"
            onClick={() => setShowReactivateModal(true)}
            isBold
            size="medium"
            variant="standalone"
          />
        </Group>
      )}
    </>
  );
};
