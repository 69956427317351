/* eslint-disable max-lines */
import { Box } from '@chakra-ui/react';
import { useIsNewBillExperienceEnabled, useNavigationWithQueryParams } from '@melio/ap-domain';
import { InboxEmailAddressCopyLinkWidget } from '@melio/ap-widgets';
import { Button, Container, Group, Icon, useBreakpoint, useDisclosure, useTable, useToast } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import {
  Bill,
  InboxItemBillTypeEnum,
  PaymentRequest,
  useAccountingPlatforms,
  usePaymentRequests,
  useTodos,
  useUserHasPayments,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig } from '@melio/platform-provider';
import { useLocation } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useResolvedPath, useSearchParams } from 'react-router-dom';

import { useMonitorTiming } from '../../../../utils';
import { DeleteBillItemsModalActivity } from '../../../delete-bill-modal';
import { EbillsImportModal, useShouldShowEbillsSyncOption } from '../../../ebills-import';
import { useDynamicEbillsImportCopy } from '../../../ebills-import/EbillsImport.activity';
import { MarkAsPaidModal } from '../../../mark-as-paid-modal/MarkAsPaidModal';
import { PaymentRequestRejectModal } from '../../../PaymentRequest';
import { useTodosEnabled } from '../../../todos-drawer/hooks/useTodosEnabled';
import { emitFocusEvent, FocusEvents } from '../../FocusSkipToComponent';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import { useShouldConnectAccountingSoftware } from '../../hooks/useShouldConnectAccountingSoftware';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { APTable } from '../APTable';
import { BillsTabDrawers } from '../Drawer';
import { MobileSortMenu } from '../MobileSortMenu/MobileSortMenu';
import { PayDashboardPagination, PayDashboardPaginationProvider, usePayDashboardPagination } from '../Pagination';
import { PayDashboardZeroState } from '../PayDashboardEmptyState/PayDashboardZeroState';
import { EmptySearchResult, SearchBar } from '../Search';
import { AddBillDropdownMenu } from './components/AddBillDropdownMenu';
import { BillItemsSelectionFooter } from './components/BillItemsSelectionFooter';
import { getAmountToDisplay } from './components/BillsTabAmountCell';
import { BillsTabEmptyState } from './components/BillsTabEmptyState';
import { BillsTabListItem } from './components/BillsTabListItem';
import { useBillsTabStatusCell } from './components/BillsTabStatusCell';
import { BillsTabItem, CELLS_IDS, SelectedInboxItemType } from './types';
import { useBillsTabAnalytics } from './useBillsTabAnalytics';
import { useBillsTabItems } from './useBillsTabItems';
import { useBillsTableColumns } from './useBillsTableColumns';
import { Selection, useBillsTabSelection } from './useBillsTabSelection';
import { useBillsTabSortableColumns } from './useBillsTabSortableColumns';

export type BillsTabProps = {
  onAddNewBill: ({
    originFlow,
    returnUrl,
    vendorId,
    amount,
  }: {
    originFlow?: OriginFlow;
    returnUrl: string;
    vendorId?: string;
    amount?: string;
  }) => void;
  onEditBill: ({ id }: { id: string; returnUrl?: string }) => void;
  onPayPaymentRequest: (id: string) => void;
  onReviewScannedInvoice: ({ id, returnUrl }: { id: string; returnUrl: string }) => void;
  onAccountingPlatformConnect: () => void;
  onAddNewBillManual: ({ returnUrl }: { returnUrl: string }) => void;
  onAddNewBillUpload: ({ returnUrl, files }: { returnUrl: string; files: File[] }) => void;
};

const BillsTabComponent = ({
  onAddNewBill,
  onPayPaymentRequest,
  onReviewScannedInvoice,
  onEditBill,
  onAccountingPlatformConnect,
  onAddNewBillManual,
  onAddNewBillUpload,
}: BillsTabProps) => {
  const { track } = useAnalytics();
  const { toast } = useToast();
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchTerm } = useSearchTerm();
  const resolvedPathUrl = useResolvedPath('');
  const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
  const { resetToFirstPage } = usePayDashboardPagination();
  const { data: accountingPlatforms, isLoading: isAccountingPlatformsLoading } = useAccountingPlatforms();
  const { isNewBillExperienceEnabled } = useIsNewBillExperienceEnabled();
  const {
    settings: {
      newBillExperience: { shouldShowIconInBillsTabCta },
    },
  } = useConfig();
  const [isMobileSortEnabled] = useFeature<boolean>(FeatureFlags.NpeSortMobile, false);
  const [isNewBillEntryPointEnabled, isNewBillEntryPointEnabledLoading] = useFeature(
    FeatureFlags.NewBillEntryPoint,
    false
  );
  const sorting = usePayDashboardSorting();
  const { sortableColumns: billsTabSortableItems } = useBillsTabSortableColumns();
  const { isEnabled: isTodosEnabled } = useTodosEnabled();
  const { refetch: refetchTodos } = useTodos({ enabled: false });

  const paginationResponse = useBillsTabItems({ searchTerm });
  const {
    data: billTabItemsResult,
    isLoading: isLoadingItems,
    refetch: refetchInboxItems,
    isPreviousData,
    isFetching: isFetchingItems,
  } = paginationResponse;
  const billItems = billTabItemsResult?.data ?? [];

  const [billItemsToDelete, setBillItemsToDelete] = useState<SelectedInboxItemType[]>([]);
  const [billToMarkAsPaidId, setBillToMarkAsPaidId] = useState('');
  const [isEbillsImportModalOpen, toggleEbillsImportModal] = useState(false);

  const {
    approve: { mutateAsync: approvePaymentRequests },
  } = usePaymentRequests({ enabled: false });
  const { tryUseFeature: tryUseBatchPayments } = useSubscriptionFeature({ featureName: 'batchPayments' });

  const onSelectionChange = useCallback((rowSelections: SelectedInboxItemType[], lastSelection: Selection) => {
    const selectedBills = rowSelections.filter((row) => row.type === 'bill');
    if (selectedBills.length <= 1) {
      return;
    }

    tryUseBatchPayments({ onFeatureIsBlocked: () => uncheckSelection(lastSelection) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    selectedRows,
    rowSelections,
    setRowSelections,
    setDisableAllRowsSelection,
    disableAllRowsSelection,
    onRowSelectionChange,
    onAllRowsSelectionChange,
    disableRowSelection,
    rowSelectionTooltips,
    uncheckSelection,
    areAllSelected,
  } = useBillsTabSelection({ billsData: billItems, onChange: onSelectionChange });

  const legacyRouteReady = useMonitorTiming('bills_tab_ready');
  const { routeReady } = useMonitoring();
  const triggerMonitoring = (el: HTMLDivElement) => {
    legacyRouteReady(el);
    routeReady(el);
  };

  useBillsTabAnalytics({ billItems, rowSelections, areAllSelected });
  const {
    isEmptyState,
    isEmptySearchResult,
    isInitialLoading,
    isTableLoading,
    shouldShowPaginationControls,
    shouldShowTabHeader: isNotEmptyStateWithoutFilter,
  } = useLoadingState({
    isLoading: isLoadingItems,
    searchTerm,
    items: billItems,
    paginationConfig: {
      isFetching: isFetchingItems,
      isPreviousData,
      totalCount: billTabItemsResult?.pagination?.totalCount,
    },
  });

  const { userHasPayments, isLoading: isUserHasPaymentsLoading } = useUserHasPayments();
  const shouldShowZeroState = isNewBillEntryPointEnabled && isEmptyState && userHasPayments;
  const shouldShowTabHeader = isNotEmptyStateWithoutFilter || shouldShowZeroState;
  const mobileSortMenuOptions = Object.values(billsTabSortableItems);

  useEffect(() => {
    if (isLoadingItems || isUserHasPaymentsLoading) {
      return;
    }
    track('Dashboard', 'View', {
      Intent: 'pay-bill',
      SortColumn: sorting?.sortingState?.id,
      SortDirection: sorting?.sortingState?.sortDirection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, isLoadingItems, isUserHasPaymentsLoading]);

  const onCloseDrawer = () => handleNavigationWithQueryParams({ newPath: resolvedPathUrl.pathname });

  const onDeleteBillItemClick = (billItemToDelete: SelectedInboxItemType) => {
    setBillItemsToDelete([billItemToDelete]);
  };

  const onMarkAsPaid = ({ id }: { id: string }) => {
    setBillToMarkAsPaidId(id);
    accountingPlatforms?.length && isExtraSmallScreen && onCloseDrawer(); // This is a temporary fix for the reconciliation form is crashing on mobile https://meliorisk.atlassian.net/browse/ME-46585. the issue will be fixed in this PR: https://github.com/melio/platform-app/pull/6199
    onMarkAsPaidBillModalOpen();
  };

  const onViewItemClick = ({ id }: { id: string }) => {
    handleNavigationWithQueryParams({ newPath: id });
  };

  const onEditItemClick = ({ id }: { id: string }) => {
    if (isNewBillExperienceEnabled) {
      onEditBill({ id });
    } else {
      handleNavigationWithQueryParams({ newPath: id, newUrlQueryParam: { edit: 'true' } });
    }
  };

  const onReviewScannedInvoiceClick = (id: string) => {
    onReviewScannedInvoice({ id, returnUrl: pathname });
  };

  const onRejectClick = ({ id }: { id: string }) => {
    setSearchParams({ paymentRequestReject: id });
  };

  const onRowClick = useCallback(
    ({ rowData }: { rowData: BillsTabItem }) => {
      if (rowSelections.length) {
        return;
      }
      if (!disableAllRowsSelection) {
        track('Dashboard', 'Click', { Intent: 'view-bill', Cta: 'row-bill-details' });
        handleNavigationWithQueryParams({ newPath: rowData.payload.id });
      }
    },
    [handleNavigationWithQueryParams, rowSelections.length, track, disableAllRowsSelection]
  );

  const onAddNewBillClick = () => {
    track('Dashboard', 'Click', { Intent: 'add-bill', Cta: 'add-a-bill' });
    onAddNewBill({ returnUrl: pathname });
  };

  const onEbillsSyncClick = (isEmptyState: boolean) => {
    toggleEbillsImportModal(true);
    track('Dashboard', 'Click', {
      PageName: 'dashboard-bills',
      Flow: 'dashboard',
      Intent: 'import-bill',
      Cta: 'connect-accounts',
      IsEmptyState: isEmptyState,
      EntryPoint: 'dashboard - bills',
    });
  };

  const handleAddNewBillManual = () => {
    track('Dashboard', 'Click', { Intent: 'add-bill', Cta: 'add-a-bill' });
    onAddNewBillManual({
      returnUrl: pathname,
    });
  };

  const handleAddNewBillUpload = (files: File[]) => {
    onAddNewBillUpload({
      returnUrl: pathname,
      files,
    });
  };

  const handleAccountingPlatformConnect = () => {
    track('Dashboard', 'Click', { Intent: 'add-bill', Cta: 'import-connect-accounting-software' });
    onAccountingPlatformConnect();
  };

  const handleOnCloseRejectModal = () => {
    setSearchParams((params) => {
      params.delete('paymentRequestReject');
      return params;
    });
  };

  const handleRejectSuccess = () => {
    refetchInboxItems();
  };

  const handleDeleteInboxItemSuccess = () => {
    onCloseDrawer();
    setRowSelections([]);
    refetchInboxItems();
    if (isTodosEnabled) {
      refetchTodos();
    }
  };

  const handleMarkAsPaidBillSuccess = () => {
    onCloseDrawer();
    refetchInboxItems();
  };

  const {
    isOpen: isMarkAsPaidBillDialogOpen,
    onOpen: onMarkAsPaidBillModalOpen,
    onClose: onMarkAsPaidBillModalClose,
  } = useDisclosure();

  const handleDeleteActionModalClose = () => setBillItemsToDelete([]);

  const handleInboxEmailCopy = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-bill',
      Cta: 'copy-email',
    });
  };

  const getBillIdsToReviewAndPay = async ({
    paymentRequests,
    bills,
  }: {
    paymentRequests: PaymentRequest[];
    bills: Bill[];
  }) => {
    setDisableAllRowsSelection(true);

    try {
      const billIdsFromPaymentRequests: string[] = [];

      if (paymentRequests.length > 0) {
        const approvedPaymentRequests = (await approvePaymentRequests({
          paymentRequests: paymentRequests.map(({ id }) => ({ id })),
        })) as Array<PaymentRequest & { billId: string }>;

        billIdsFromPaymentRequests.push(...approvedPaymentRequests.map(({ billId }) => billId));
      }

      return [...bills.map(({ id }) => id), ...billIdsFromPaymentRequests];
    } catch (error) {
      toast({
        type: 'error',
        title: formatMessage('activities.payDashboard.billsTab.selectionFooter.reviewAndPay.error'),
      });
    } finally {
      setDisableAllRowsSelection(false);
    }

    return null;
  };

  const columns = useBillsTableColumns({
    onSortChange: resetToFirstPage,
  });

  const mobileRowRenderer = useCallback((row: BillsTabItem) => <BillsTabListItem billsTabItem={row} />, []);
  const getBillsTabStatus = useBillsTabStatusCell();

  const getItemAriaLabelContext = useCallback(
    (billItem: BillsTabItem) => {
      if (billItem.type === InboxItemBillTypeEnum.Bill) {
        const amount = getAmountToDisplay(billItem);
        const { label: statusLabel } = getBillsTabStatus({ billsTabItem: billItem });
        return formatMessage('activities.payDashboard.billsTab.table.aria.row', {
          vendorName: billItem.payload.vendor?.name,
          creationDate: formatDate(billItem.payload.history.createdAt ?? undefined, { dateStyle: 'medium' }),
          dueDate: formatDate(billItem.payload.dueDate ?? undefined, { dateStyle: 'medium' }),
          status: statusLabel,
          amount: amount ? formatCurrency(amount) : '',
        });
      }
      return '';
    },
    [formatCurrency, formatDate, formatMessage, getBillsTabStatus]
  );

  const tableProps = useTable({
    isLoading: isTableLoading,
    data: billItems,
    columns,
    getRowId: (row) => row.payload.id,
    rowSelectionTooltips,
    selectedRows,
    onRowSelectionChange,
    disableRowSelection,
    disableAllRowsSelection,
    onAllRowsSelectionChange,
    onRowClick,
    ...sorting,
    mobileRowRenderer,
    headerVariant: 'dark',
    allRowsSelectionAriaLabel: '',
    hideHeaderWhileLoading: isInitialLoading,
    getRowSelectionAriaLabel: getItemAriaLabelContext,
    captionId: formatMessage(`activities.payDashboard.tabs.bills.caption`),
    meta: {
      rowSelections: rowSelections.map(({ id }) => id),
      getItemAriaLabelContext,
      onPayPaymentRequest,
      onReviewScannedInvoiceClick,
      onDeleteBillItemClick,
      onViewItemClick,
      onEditItemClick,
      onRejectClick,
      onMarkAsPaid,
    },
  });

  const paymentRequestRejectQP = searchParams.get('paymentRequestReject');

  const shouldShowConnectAccountingSoftwareOption = useShouldConnectAccountingSoftware();
  const shouldShowEbillsSyncOption = useShouldShowEbillsSyncOption();
  const { importCta } = useDynamicEbillsImportCopy();

  const renderTableContent = () => (
    <Group alignItems="flex-end" variant="vertical" spacing={shouldShowPaginationControls ? 'm' : undefined}>
      <APTable
        {...tableProps}
        captionLabel={
          shouldShowTabHeader ? formatMessage('activities.payDashboard.tabs.bills.captionLabel') : undefined
        }
      />
      {isEmptySearchResult && !isFetchingItems ? (
        <EmptySearchResult onClear={() => emitFocusEvent(FocusEvents.TAB_TOP_SEARCH)} />
      ) : null}
      <>
        <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
        {/* Fills space on the DOM equals to gap required by pagination controls in order for ActionBar footer to respect it due to it's fixed position  */}
        {shouldShowPaginationControls ? <Box height={6} /> : null}
      </>
    </Group>
  );

  const renderContent = () => {
    if (isEmptySearchResult || !isEmptyState) {
      return renderTableContent();
    }

    if (shouldShowZeroState) {
      return <PayDashboardZeroState />;
    }

    return (
      <BillsTabEmptyState
        connectGmailCta={importCta}
        shouldShowConnectAccountingSoftwareOption={shouldShowConnectAccountingSoftwareOption}
        onAccountingPlatformConnect={handleAccountingPlatformConnect}
        onAddNewBillUpload={handleAddNewBillUpload}
        onAddNewBill={onAddNewBillClick}
        onGmailConnect={() => onEbillsSyncClick(true)}
        shouldShouldGmailOption={shouldShowEbillsSyncOption}
        manuallyAddNewBill={handleAddNewBillManual}
      />
    );
  };

  const isHeaderActionsDataLoading = isAccountingPlatformsLoading || isNewBillEntryPointEnabledLoading;

  return (
    <Container data-testid="pay-dashboard-bills-tab" overflow="initial">
      {!isLoadingItems && <span ref={triggerMonitoring} />}
      <Box paddingBottom="xxxl">
        <Group variant="vertical" width="full" spacing={isExtraSmallScreen ? 's' : 'm'}>
          <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
            {shouldShowTabHeader && (
              <Group justifyContent="space-between" width="full">
                <SearchBar
                  onSearchSubmitted={resetToFirstPage}
                  placeholderOnFocus="activities.payDashboard.billsTab.searchPlaceholder"
                  label={isExtraSmallScreen ? undefined : formatMessage('activities.payDashboard.billsTab.searchLabel')}
                  pagination={!paginationResponse.isFetching ? paginationResponse.data?.pagination : undefined}
                  focusOnEvent={FocusEvents.TAB_TOP_SEARCH}
                  placeholder={isExtraSmallScreen ? 'activities.payDashboard.billsTab.search.placeholder' : undefined}
                />
                {!isExtraSmallScreen && (
                  <Box display="flex" flexDirection="row" gap="s" alignItems="center">
                    {!isHeaderActionsDataLoading ? (
                      isNewBillEntryPointEnabled ? (
                        <>
                          <InboxEmailAddressCopyLinkWidget onCopy={handleInboxEmailCopy} />
                          <AddBillDropdownMenu
                            label={formatMessage('activities.payDashboard.billsTab.importButton')}
                            onGmailConnect={() => onEbillsSyncClick(false)}
                            onAddNewBillUpload={handleAddNewBillUpload}
                            onAccountingPlatformConnect={handleAccountingPlatformConnect}
                            shouldShouldGmailOption={shouldShowEbillsSyncOption}
                            connectGmailCta={importCta}
                            shouldShowConnectAccountingSoftwareOption={shouldShowConnectAccountingSoftwareOption}
                          />
                          <Button
                            data-testid="bills-tab-add-bill-button"
                            onClick={handleAddNewBillManual}
                            label={formatMessage('activities.payDashboard.billsTab.addBillButton')}
                            variant="tertiary"
                            leftElement={
                              shouldShowIconInBillsTabCta ? (
                                <Icon size="small" type="add" color="inherit" aria-hidden />
                              ) : undefined
                            }
                          />
                        </>
                      ) : (
                        <>
                          <InboxEmailAddressCopyLinkWidget onCopy={handleInboxEmailCopy} />
                          <Button
                            data-testid="bills-tab-add-bill-button"
                            onClick={onAddNewBillClick}
                            label={formatMessage('activities.payDashboard.billsTab.addBillButton')}
                            variant="tertiary"
                            leftElement={
                              shouldShowIconInBillsTabCta ? (
                                <Icon size="small" type="add" color="inherit" aria-hidden />
                              ) : undefined
                            }
                          />
                        </>
                      )
                    ) : null}
                  </Box>
                )}
                {isExtraSmallScreen && isMobileSortEnabled && (
                  <Container width="fit-content">
                    <MobileSortMenu
                      items={mobileSortMenuOptions}
                      onSortChange={resetToFirstPage}
                      title="activities.payDashboard.billsTab.sort.title"
                    />
                  </Container>
                )}
              </Group>
            )}
          </Container>
          <Container overflow="initial">{renderContent()}</Container>
        </Group>
      </Box>
      {paymentRequestRejectQP && (
        <PaymentRequestRejectModal
          isOpen
          onClose={handleOnCloseRejectModal}
          onSuccess={handleRejectSuccess}
          paymentRequestId={paymentRequestRejectQP}
        />
      )}
      {billItemsToDelete.length > 0 && (
        <DeleteBillItemsModalActivity
          isOpen
          billItems={billItemsToDelete}
          onClose={handleDeleteActionModalClose}
          onSuccess={handleDeleteInboxItemSuccess}
        />
      )}
      {isMarkAsPaidBillDialogOpen && (
        <MarkAsPaidModal
          id={billToMarkAsPaidId}
          isOpen={isMarkAsPaidBillDialogOpen}
          onClose={onMarkAsPaidBillModalClose}
          onSuccess={handleMarkAsPaidBillSuccess}
          type="bill"
        />
      )}
      <Routes>
        <Route
          path=":billItemId"
          element={
            <BillsTabDrawers
              onClose={onCloseDrawer}
              refetchBillItems={refetchInboxItems}
              onRejectClick={(id) => onRejectClick({ id })}
              onDeleteBillClick={({ id }: { id: string }) =>
                onDeleteBillItemClick({
                  id,
                  type: InboxItemBillTypeEnum.Bill,
                })
              }
              onReviewScannedInvoiceClick={onReviewScannedInvoiceClick}
              onMarkAsPaidClick={onMarkAsPaid}
              onEditBill={onEditBill}
            />
          }
        />
      </Routes>
      <BillItemsSelectionFooter
        totalCount={billTabItemsResult?.pagination?.totalCount ?? 0}
        billItems={rowSelections}
        onDeleteBillItems={() => setBillItemsToDelete(rowSelections)}
        getBillIdsToReviewAndPay={getBillIdsToReviewAndPay}
        onCancel={() => setRowSelections([])}
      />
      <EbillsImportModal isOpen={isEbillsImportModalOpen} onClose={() => toggleEbillsImportModal(false)} />
    </Container>
  );
};

export const BillsTab = (props: BillsTabProps) => (
  <PayDashboardPaginationProvider>
    <PayDashboardSortingProvider defaultSort={{ id: CELLS_IDS.DUE_DATE, sortDirection: 'asc' }}>
      <BillsTabComponent {...props} />
    </PayDashboardSortingProvider>
  </PayDashboardPaginationProvider>
);
