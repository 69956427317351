import { isFXCurrency } from '@melio/ap-domain';
import {
  FxCurrency,
  Todo,
  TodoFailedPayment,
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueInboxItems,
  TodoOverdueInMoreThan7DaysInboxItems,
  TodoPaymentsToApprove,
} from '@melio/platform-api';

//in payments the currency from the api is the real currency.
//while the amount is always USD and the foreignAmount is the matching to the currency
export const normalizePayments = (todo: TodoFailedPayment | TodoPaymentsToApprove) =>
  todo.items.data.map((d) => ({
    amount: isFXCurrency(d.currency) ? d.foreignAmount || d.amount : d.amount,
    currency: d.currency,
    usdToForeignRate: d.usdToForeignRate,
  }));

//in paymentRequest and scannedInvoice the currency is always "USD"
//while in bills it can be any other currencies
//in bills the currency is the real currency while the amount is the foreign amount if it is foreign
export const normalizeInboxItems = (
  todo: TodoOverdueInboxItems | TodoOverdueIn7DaysInboxItems | TodoOverdueInMoreThan7DaysInboxItems | undefined
) => {
  if (!todo) {
    return [];
  }
  return todo.items.data
    .map((d) => {
      switch (d.type) {
        case 'bill': {
          const payload = d.payload;
          return {
            amount: payload.amount,
            currency: payload.currency,
            vendorName: payload.vendor?.name ?? '',
            dueDate: payload.dueDate,
            usdToForeignRate: payload.usdToForeignRate == null ? undefined : payload.usdToForeignRate,
          };
        }
        case 'paymentRequest': {
          const payload = d.payload;
          return {
            amount: payload.totalAmount,
            currency: payload.currency,
            vendorName: payload.vendor?.name ?? '',
            dueDate: payload.dueDate,
          };
        }
        case 'scannedInvoice': {
          const payload = d.payload;
          return {
            amount: payload.amount,
            currency: 'USD',
            vendorName: payload.vendorName ?? '',
            dueDate: payload.dueDate,
          };
        }
        case 'payment':
          return null;
      }
    })
    .filter(
      (
        d
      ): d is {
        amount: number;
        currency: FxCurrency;
        vendorName: string;
        dueDate: Date;
        usdToForeignRate: number | undefined;
      } => !!d
    );
};

export const isEmptySection = (todo?: Todo) =>
  !todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null;
