import { Divider, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const ApprovalFormDivider = ({ type }: { type: 'then' | 'and' }) => {
  const { formatMessage } = useMelioIntl();

  const renderLabel = () => {
    switch (type) {
      case 'and':
        return formatMessage('activities.create-approval-workflows.divider.and');
      case 'then':
        return formatMessage('activities.create-approval-workflows.divider.then');
    }
  };

  return (
    <Group alignItems="center" width="full" spacing="xs">
      <Text textStyle="body5SemiUpper" color="neutral.dark">
        {renderLabel()}
      </Text>
      <Group.Item grow={1}>
        <Divider variant="horizontal" />
      </Group.Item>
    </Group>
  );
};
