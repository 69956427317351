import { useGuestPayorOtp } from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import React, { useEffect, useState } from 'react';

import { AuthenticationModalScreen } from './screens';

export type AuthenticationModalActivityProps = {
  onDone: VoidFunction;
  onClose: VoidFunction;
  isOpen: boolean;
  email: string;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onError?: ARErrorFunction;
};

const SECONDS_TO_ENABLE_RESEND = 30;
const submitCodeVerificationAnalytics = {
  PageName: 'verification-code',
  Intent: 'verification',
  AuthenticatorType: 'email',
};

export const AuthenticationModalActivity = forwardRef<AuthenticationModalActivityProps>(
  ({ onError, onLoggedIn, partnerName, email, isOpen, onDone, onClose }, ref) => {
    const { sendVerificationCode, verifyCode, isLoadingVerifyCode } = useGuestPayorOtp();
    const [verifyCodeError, setVerifyCodeError] = useState<string>();
    const { track } = useAnalytics();
    const [secToResend, setSecToResend] = useState<number>(SECONDS_TO_ENABLE_RESEND);
    const isDisabled = secToResend > 0;

    const onSendEmailVerificationCode = async () => {
      setVerifyCodeError(undefined);
      await sendVerificationCode({ email, partnerName });
    };

    useEffect(() => {
      if (isOpen) {
        void onSendEmailVerificationCode();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
      if (!isOpen) {
        setSecToResend(SECONDS_TO_ENABLE_RESEND);
        return;
      }

      const resendIntervalId = setInterval(() => {
        if (secToResend > 0) {
          setSecToResend((prevCount) => prevCount - 1);
        }
      }, 1000);

      return () => {
        clearInterval(resendIntervalId);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled, isOpen]);

    const onSubmitCodeVerification = async (code: string, email: string) => {
      try {
        setVerifyCodeError(undefined);
        const { accessToken, refreshToken } = await verifyCode({
          email,
          partnerName,
          otp: code,
        });
        await onLoggedIn?.(accessToken, refreshToken);
        track('PaymentRequest', 'Status', {
          StatusType: 'success',
          ...submitCodeVerificationAnalytics,
        });
        onDone();
      } catch (e) {
        const error = e as ARPlatformError;
        track('PaymentRequest', 'Status', {
          StatusType: 'failure',
          ErrorType: error.message,
          ...submitCodeVerificationAnalytics,
        });
        setVerifyCodeError(error.errorCode);
        onError?.(error);
      }
    };

    const handleOnClose = () => {
      setVerifyCodeError(undefined);
      onClose();
    };

    return (
      <AuthenticationModalScreen
        errorCode={verifyCodeError}
        onResetResendTimer={() => setSecToResend(SECONDS_TO_ENABLE_RESEND)}
        secToResend={secToResend}
        isLoading={isLoadingVerifyCode}
        email={email}
        onClose={handleOnClose}
        ref={ref}
        isOpen={isOpen}
        generateNewCode={onSendEmailVerificationCode}
        onCodeComplete={onSubmitCodeVerification}
      />
    );
  }
);
AuthenticationModalActivity.displayName = 'AuthenticationModalActivity';
