import { isEinOnlyBusinessType, masks, useMtlFormValues, useMtlMessages } from '@melio/ap-domain';
import { AddressSearchWidget, IndustryTypeSelectWidget } from '@melio/ap-widgets';
import { Button, CollapsibleCard, Form, useWatch } from '@melio/penny';
import { Industry, OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { isFieldReadonly as _isFieldReadonly, setValueAndMakeDirty } from '../formUtils';
import { BusinessDetailsForm, FXFormSectionCardBaseProps } from '../types';

type FXBusinessDetailsCardProps = FXFormSectionCardBaseProps<BusinessDetailsForm> & {
  businessName: string;
  isBlockedFromSubmitting: boolean;
  onBusinessTypeChanged: VoidFunction;
};
export const FXBusinessDetailsCard = ({
  form,
  isExpanded,
  onExpandChange,
  isCompleted,
  businessName,
  isLoading,
  isBlockedFromSubmitting,
  onBusinessTypeChanged: _onBusinessTypeChanged,
}: FXBusinessDetailsCardProps) => {
  const { formatMessage } = useMelioIntl();

  const setBusinessIndustry = (industryName: BusinessDetailsForm['businessIndustry']) => {
    setValueAndMakeDirty({
      form,
      fieldKey: 'businessIndustry',
      value: industryName,
    });
  };

  const setBusinessType = (businessType: OrganizationBusinessType) => {
    _onBusinessTypeChanged();
    setValueAndMakeDirty({
      form,
      fieldKey: 'businessType',
      value: businessType,
    });
  };

  const { businessTypeOptions, taxIdTypeOptions } = useMtlFormValues();
  const {
    labels: { company: companyLabels },
    placeholders,
  } = useMtlMessages();
  const [selectedBusinessType, selectedTaxIdType] = useWatch({
    control: form.control,
    name: ['businessType', 'taxIdType'],
  });

  const isFieldReadonly = (fieldKey: keyof BusinessDetailsForm) =>
    _isFieldReadonly({
      fieldKey,
      form,
    });

  const onBusinessTypeChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const businessType = e.target.value as OrganizationBusinessType;
    setBusinessType(businessType);

    if (isEinOnlyBusinessType(businessType)) {
      if (selectedTaxIdType !== TaxIdTypeEnum.Ein) {
        onTaxIdTypeChanged(TaxIdTypeEnum.Ein);
      }
    } else {
      setForceTaxIdTypeEditable(true);
    }
  };

  const [forceTaxIdTypeEditable, setForceTaxIdTypeEditable] = useState(false);

  const onTaxIdTypeChanged = (taxIdType: TaxIdTypeEnum) => {
    setValueAndMakeDirty({
      form,
      fieldKey: 'businessTaxId',
      value: '',
    });
    setValueAndMakeDirty({
      form,
      fieldKey: 'taxIdType',
      value: taxIdType,
    });
  };

  const isBusinessIndustryError =
    form.formState?.errors.businessIndustry || !!form.formState?.errors?.businessIndustry?.name;

  return (
    <CollapsibleCard
      data-testid="fx-business-details-form-card"
      isExpanded={isExpanded}
      onExpandChange={onExpandChange}
      status={isCompleted ? 'success' : undefined}
      title={formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.title')}
      description={
        isExpanded
          ? formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.subtitle',
              { businessName }
            )
          : undefined
      }
    >
      <Form {...form.formProps}>
        <Form.TextField
          {...form.registerField('companyName')}
          isViewMode={isFieldReadonly('companyName')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.companyName.label'
            ),
          }}
        />
        <Form.TextField
          {...form.registerField('companyLegalName')}
          isViewMode={isFieldReadonly('companyLegalName')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.companyLegalName.label'
            ),
          }}
        />
        <AddressSearchWidget
          {...form.registerField('legalBusinessAddress')}
          isViewMode={isFieldReadonly('legalBusinessAddress')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.legalBusinessAddress.label'
            ),
          }}
          error={
            form.formState?.errors.legalBusinessAddress?.line1 || !!form.formState.errors.legalBusinessAddress
              ? {
                  message: formatMessage(
                    'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.legalBusinessAddress.required'
                  ),
                }
              : undefined
          }
        />
        <Form.PhoneField
          {...form.registerField('phone')}
          isViewMode={isFieldReadonly('phone')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.phone.label'
            ),
          }}
        />
        <IndustryTypeSelectWidget
          {...form.registerField('businessIndustry')}
          error={
            isBusinessIndustryError
              ? {
                  message: formatMessage(
                    'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.industry.required'
                  ),
                }
              : undefined
          }
          sixDigitNaicsOnly
          isViewMode={isFieldReadonly('businessIndustry')}
          onCreateOption={({ value }) => {
            setBusinessIndustry(value as unknown as Industry);
          }}
          onChange={({ target }) => {
            setBusinessIndustry(target.value as unknown as Industry);
          }}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessIndustry.placeholder'
          )}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessIndustry.label'
            ),
          }}
          isRequired
        />
        <Form.Select
          {...form.registerField('businessType')}
          isViewMode={!form.getFieldState('businessType').isDirty && !!selectedBusinessType}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessType.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessType.placeholder'
          )}
          options={businessTypeOptions}
          emptyState={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessType.emptyState'
            ),
          }}
          onChange={onBusinessTypeChanged}
        />
        <Form.RadioGroup
          {...form.registerField('taxIdType')}
          isViewMode={!forceTaxIdTypeEditable && isFieldReadonly('taxIdType')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.taxIdType.label'
            ),
          }}
          options={taxIdTypeOptions(selectedBusinessType)}
          onChange={(e) => onTaxIdTypeChanged(e.target.value as TaxIdTypeEnum)}
        />
        <Form.TextField
          {...form.registerField('businessTaxId')}
          isViewMode={isFieldReadonly('businessTaxId')}
          labelProps={{
            label: companyLabels.taxId(),
          }}
          type="text"
          maskProps={{
            mask: !isFieldReadonly('businessTaxId')
              ? masks.taxId[selectedTaxIdType || TaxIdTypeEnum.Ein]
              : masks.taxId.any,
          }}
          placeholder={placeholders.taxId(selectedTaxIdType)}
          onFocus={() => form.setValue('businessTaxId', '')}
        />
        <Button
          {...form.submitButtonProps}
          isLoading={isLoading}
          isDisabled={isBlockedFromSubmitting}
          data-testid="business-details-submit"
          label={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.submitButton.label'
          )}
        />
      </Form>
    </CollapsibleCard>
  );
};
