import { FormattedMessage } from '@melio/ar-domain';
import { Group, Illustration, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { GuestPaymentLayout } from '../../../layout';

export type GuestPaymentRequestErrorScreenProps =
  | {
      errorType: 'expired';
      vendorEmail: string;
    }
  | {
      errorType: 'network-error';
      vendorEmail?: never;
    };

const getTitle = (errorType: string) => {
  if (errorType === 'expired') {
    return <FormattedMessage id="ar.guestPayment.errors.paymentLinkExipred.title.text" />;
  } else {
    return <FormattedMessage id="ar.guestPayment.errors.generalMessage.title.text" />;
  }
};

const getDescription = ({ errorType, vendorEmail }: { errorType: string; vendorEmail?: string }) => {
  if (errorType === 'expired') {
    return (
      <FormattedMessage id="ar.guestPayment.errors.paymentLinkExipred.description.text" values={{ vendorEmail }} />
    );
  } else {
    return <FormattedMessage id="ar.guestPayment.errors.generalMessage.title.description" />;
  }
};

export const GuestPaymentRequestErrorScreen = forwardRef<GuestPaymentRequestErrorScreenProps>(
  ({ errorType = 'network-error', vendorEmail }, ref) => {
    const title = getTitle(errorType);
    const description = getDescription({ errorType, vendorEmail });

    useAnalyticsView('PaymentRequest', true, true, {
      PageName: 'payment-link-expired', // TODO: change to correct error name
      Intent: 'pay-invoice',
    });

    return (
      <GuestPaymentLayout data-component={GuestPaymentRequestErrorScreen.displayName} ref={ref}>
        <Group variant="vertical" spacing="s">
          <Illustration type={errorType} />
          <Group variant="vertical" spacing="none">
            <Text textStyle="heading3Semi">{title}</Text>
            <Text textStyle="body3">{description}</Text>
          </Group>
        </Group>
      </GuestPaymentLayout>
    );
  }
);

GuestPaymentRequestErrorScreen.displayName = 'GuestPaymentRequestErrorScreen';
