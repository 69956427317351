// eslint-disable-next-line no-restricted-imports
import { Card, PaymentIntent } from '@melio/javascript-sdk';
// eslint-disable-next-line no-restricted-imports
import { FundingSource, FxCurrency } from '@melio/platform-api-axios-client';
import type { AnalyticsSnippet, Callback, Options } from '@segment/analytics-next';

type FeatureFlagAnalytics = { name: string; displayed: boolean; type?: string };
export type EventPropertiesCardType = 'credit' | 'debit' | 'credit_debit';
export type EventProperties = {
  CardType?: EventPropertiesCardType;
  Currency?: FxCurrency;
  DeliveryMethod?: string;
  ShownDeliveryMethod?: string[];
  FundingSource?: FundingSource['type'] | Card['type'];
  FundingSourceShown?: Record<FundingSource['type'] | Card['type'], number>;
  PaymentIntentId?: PaymentIntent['id'];
  BillType?: 'manually' | 'upload' | 'QBsync';
  Amount?: number | null;
  PageEntryPoint?: string;
  PageName?: string;
  Intent?: string;
  PaymentFrequency?: string;
  RecurringPaymentEndBy?: string | null;
  RecurringPaymentEndValue?: string | number | null;
  FlowUid?: string;
  Status?: string | Record<string, number>;
  Origin?: OriginFlow;
  Flow?: string;
  PaymentId?: string | string[];
  VendorId?: string;
  BillId?: string | string[];
  BillSubscriptionId?: string | string[];
  Feature?: FeatureFlagAnalytics | FeatureFlagAnalytics[];
  IsSpecialDelivery?: boolean;
  SpecialDeliveryMethod?: string | null;
} & Record<string, unknown>;

export enum OriginFlow {
  BillPage = 'bill-page',
  VendorsFlow = 'vendors-flow',
  PayEmptyInbox = 'pay-empty-inbox',
  Carrousel = 'carrousel',
  SupportSettings = 'support-settings',
  PaymentScheduled = 'payment-scheduled',
  JustPay = 'just-pay',
  Onboarding = 'onboarding',
}

export type Traits = {
  siteConfig?: string;
  userId?: string;
  name?: string;
  email?: string;
  orgId?: number;
  company?: string;
  organization?: string;
  createOrigin?: string;
  registrationFlow?: string;
  PartnerId?: number;
  added_funding?: boolean;
  funding_sources_types?: string;
  added_delivery_method?: boolean;
  first_time_pay?: boolean;
  create_a_bill?: boolean;
  last_bill_added_date?: string;
  companyName?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactName?: string;
};

export type AnalyticsConfig = {
  reportEvents: boolean;
  printEvents: boolean;
  segmentKey: string;
};

type Identify = {
  (userId?: string, traits?: Traits, options?: SegmentOpts, callback?: Callback): void;
  (traits?: Traits, options?: SegmentOpts, callback?: Callback): void;
};

export type AnalyticsJS = Omit<AnalyticsSnippet, 'track' | 'identify'> & {
  track: (event: string, properties?: EventProperties, options?: SegmentOpts, callback?: Callback) => void;
  identify: Identify;
};
export type SegmentOpts = Options;
