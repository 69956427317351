import { BillSubscriptionApprovalDecisionStatusEnum, Payment, PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

import { isEbill } from '../../functions';
import { getIsVoidAndRefundEnabled } from '../../functions/isVoidAndRefundEnabled';
import { getIsVoidAndResendEnabled } from '../../functions/isVoidAndResendEnabled';
import { useBillSubscriptionActions } from '../bill-subscription/useBillSubscriptionActions';
import { PaymentActions } from './types';
import { useFailedPaymentActions } from './useFailedPaymentActions';

const defaultPaymentActions: PaymentActions = {
  edit: false,
  delete: false,
  cancel: false,
  cancelBillSubscription: false,
  isEditBillSubscriptionAllowed: false,
  markAsPaid: false,
  markAsUnpaid: false,
  resolveFailedToCollect: false,
  resolveFailedToDeliver: false,
  resolveRefund: false,
  resolveVoidAndRefund: false,
  resolveVoidAndResend: false,
  approvalDecision: false,
};

const useScheduledPaymentActions = (payment?: Payment) => {
  const { can } = usePermissions();
  const [isCancelPaymentEnabled] = useFeature(FeatureFlags.CancelPayment, false);

  const { isCancelBillSubscriptionAllowed, isEditBillSubscriptionAllowed } = useBillSubscriptionActions(
    payment?.subscriptionOccurrence?.billSubscription,
    payment?.vendor
  );

  if (!payment) {
    return defaultPaymentActions;
  }

  const isPendingApprovalRecurringPayment =
    payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Pending;
  const canUpdatePayment =
    can({
      subject: 'payment',
      action: 'update',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    }) &&
    !payment.isFinanced &&
    !!payment.paymentActions?.edit.eligible &&
    !isPendingApprovalRecurringPayment;

  const canDeleteBills = payment.bills?.length
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'delete',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: payment.vendor?.createdById,
              managedBy: payment.vendor?.managedBy,
            },
          },
        })
      )
    : true;

  const canDeletePayment =
    can({
      subject: 'payment',
      action: 'delete',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    }) &&
    canDeleteBills &&
    !!payment.paymentActions?.delete.eligible;

  const canCancelPayment =
    isCancelPaymentEnabled &&
    !!payment.paymentActions?.cancel.eligible &&
    can({
      subject: 'payment:cancel',
      action: 'update',
      subjectData: {
        createdById: payment.createdById,
        fundingSourceId: payment.fundingSourceId,
        vendor: {
          createdById: payment.vendor?.createdById,
          managedBy: payment.vendor?.managedBy,
        },
        payment: {
          origin: payment.origin,
        },
      },
    });

  const canCancelBillSubscription = isCancelBillSubscriptionAllowed && (canDeletePayment || canCancelPayment);

  return {
    edit: canUpdatePayment,
    delete: canDeletePayment && !isPendingApprovalRecurringPayment,
    cancelBillSubscription: !!payment.subscriptionOccurrenceId && canCancelBillSubscription,
    isEditBillSubscriptionAllowed,
    approvalDecision: !!payment.paymentActions?.approvalDecision.eligible,
    cancel: canCancelPayment,
  };
};

const useCompletedPaymentActions = (payment?: Payment) => {
  const [isMarkAsPaidEnabled] = useFeature(FeatureFlags.MarkAsPaid, false);
  const { can } = usePermissions();
  const isVoidAndRefundEnabled = getIsVoidAndRefundEnabled(payment);
  const isVoidAndResendEnabled = getIsVoidAndResendEnabled(payment);

  if (!payment) {
    return {
      resolveVoidAndRefund: false,
      resolveVoidAndResend: false,
      markAsUnpaid: false,
    };
  }

  const canUpdateBills = payment.bills?.length
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'update',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: payment.vendor?.createdById,
              managedBy: payment.vendor?.managedBy,
            },
          },
        })
      )
    : true;

  const canMarkAsUnpaid =
    !!payment.bills?.every((bill) => !isEbill(bill)) && isMarkAsPaidEnabled && payment.markedAsPaid && canUpdateBills;

  return {
    resolveVoidAndRefund: isVoidAndRefundEnabled,
    resolveVoidAndResend: isVoidAndResendEnabled,
    markAsUnpaid: canMarkAsUnpaid,
  };
};

export const usePaymentActions = (
  payment?: Payment
): {
  type: PaymentStatusEnum | null;
  actions: PaymentActions;
} => {
  const scheduledPaymentAllowedActions = useScheduledPaymentActions(payment);
  const completedPaymentAllowedActions = useCompletedPaymentActions(payment);
  const failedPaymentAllowedActions = useFailedPaymentActions(payment);

  if (!payment) {
    return { type: null, actions: defaultPaymentActions };
  }

  switch (payment.status) {
    case PaymentStatusEnum.Blocked:
    case PaymentStatusEnum.Scheduled:
      return { actions: { ...defaultPaymentActions, ...scheduledPaymentAllowedActions }, type: 'scheduled' as const };
    case PaymentStatusEnum.Completed:
      return { actions: { ...defaultPaymentActions, ...completedPaymentAllowedActions }, type: 'completed' as const };
    case PaymentStatusEnum.Failed:
      return { actions: { ...defaultPaymentActions, ...failedPaymentAllowedActions }, type: 'failed' as const };
    case PaymentStatusEnum.InProgress:
    default:
      return { type: payment.status, actions: defaultPaymentActions };
  }
};
