import { CollapsibleCard, Container } from '@melio/penny';

type CollapsibleCardSectionProps = {
  id: string;
  title: string;
  description: string;
  isExpanded: boolean;
  isDisabled: boolean;
  children: React.ReactNode;
  onExpandChange: (expanded: boolean) => void;
};

export const CollapsibleCardSection = ({
  id,
  title,
  description,
  isExpanded,
  isDisabled,
  children,
  onExpandChange,
}: CollapsibleCardSectionProps) => (
  <Container key={`Container_${id}`} alignItems="center">
    <CollapsibleCard
      key={`section_${id}`}
      title={title}
      description={description}
      isExpanded={isExpanded}
      isDisabled={isDisabled}
      onExpandChange={(expanded) => onExpandChange(expanded)}
      // @ts-expect-error TODO ask DS to allow variant prop on CollapsibleCard item
      variant="flat"
    >
      {children}
    </CollapsibleCard>
  </Container>
);
