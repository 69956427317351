import { TableRowSelectionState, useTable } from '@melio/penny';
import { Collaborator, FundingSourceEntitlementType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import uniq from 'lodash/uniq';
import { useCallback, useMemo, useState } from 'react';

type UseEntitledUsersSelectionResult = {
  rowSelections: string[];
  areAllSelected: boolean;
  changedOriginRows: { rowId: string; selected: boolean }[];
} & Pick<
  Parameters<typeof useTable<Collaborator>>[0],
  'selectedRows' | 'onRowSelectionChange' | 'onAllRowsSelectionChange' | 'rowSelectionTooltips' | 'disableRowSelection'
>;

export const useEntitledUsersSelection = (
  collaborators: Collaborator[],
  actorId = '',
  entitlementCollaboratorsIds: string[],
  fundingSourceId: string
): UseEntitledUsersSelectionResult => {
  const [rowSelections, setRowSelections] = useState<string[]>([...entitlementCollaboratorsIds]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const { formatMessage } = useMelioIntl();
  const [changedRows, setChangeRows] = useState<{ rowId: string; selected: boolean }[]>([]);
  const disabledFullAccessCollaborators = collaborators.filter(
    (user) => user.fundingSourceEntitlements?.entitlementType === FundingSourceEntitlementType.All
  );
  const disabledCollaboratorsWithOneEntitledFundingSource = collaborators.filter(
    (user) =>
      user.fundingSourceEntitlements?.fundingSourceIds.includes(fundingSourceId) &&
      user.fundingSourceEntitlements?.fundingSourceIds.length === 1
  );
  const disabledCollaboratorsIds = useMemo(
    () =>
      [...disabledFullAccessCollaborators, ...disabledCollaboratorsWithOneEntitledFundingSource].map(
        (collaborator) => collaborator.id
      ),
    [disabledFullAccessCollaborators, disabledCollaboratorsWithOneEntitledFundingSource]
  );
  const shouldDisableRowSelection = useCallback(
    (collaborator: Collaborator) => {
      const isDisabled = [
        ...disabledFullAccessCollaborators,
        ...disabledCollaboratorsWithOneEntitledFundingSource,
      ].find((disabledCollaborator) => disabledCollaborator.id === collaborator.id);
      if (isDisabled) {
        return true;
      }

      if (collaborator.id === actorId) {
        return true;
      }
      return false;
    },
    [disabledFullAccessCollaborators, disabledCollaboratorsWithOneEntitledFundingSource, actorId]
  );

  const updateChangedRows = useCallback(
    (rowId: string, isSelected: boolean) => {
      if (changedRows.map((row) => row.rowId).includes(rowId)) {
        setChangeRows((prev) => prev.filter((row) => row.rowId !== rowId));
      } else {
        setChangeRows((prev) => [...prev, { rowId, selected: isSelected }]);
      }
    },
    [changedRows]
  );

  const onRowSelectionChange = useCallback(
    (selectionState: TableRowSelectionState<Collaborator>) => {
      const { rowId, isSelected } = selectionState;

      updateChangedRows(rowId, isSelected);
      setRowSelections((selectedRows) =>
        isSelected ? uniq([...selectedRows, rowId]) : selectedRows.filter((selectedRowId) => selectedRowId !== rowId)
      );
    },
    [updateChangedRows]
  );

  const onAllRowsSelectionChange = useCallback(
    (areAllSelected: boolean) => {
      setAreAllSelected(areAllSelected);
      const allRowsSelections = areAllSelected
        ? collaborators?.map((collaborator) => collaborator.id) ?? []
        : [...disabledCollaboratorsIds, actorId];
      if (areAllSelected) {
        const newlySelectedRows = collaborators.filter((collaborator) => !rowSelections.includes(collaborator.id));
        newlySelectedRows.map((collaborator) => updateChangedRows(collaborator.id, true));
      } else {
        const newlyDeselectedRows = collaborators.filter(
          (collaborator) =>
            !disabledCollaboratorsIds.includes(collaborator.id) && rowSelections.includes(collaborator.id)
        );
        newlyDeselectedRows.map((collaborator) => updateChangedRows(collaborator.id, false));
      }
      setRowSelections(allRowsSelections);
    },
    [actorId, collaborators, disabledCollaboratorsIds, rowSelections, updateChangedRows]
  );

  const rowSelectionTooltips = useMemo(
    () => ({
      header: {
        label: !areAllSelected
          ? formatMessage(
              'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.tooltips.selectAll'
            )
          : formatMessage(
              'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.tooltips.deselectAll'
            ),
      },
      row: ({ rowData }: { rowData: Collaborator }) => {
        if (rowData.id === actorId && actorId) {
          return {
            label: formatMessage(
              'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.tooltips.level1'
            ),
          };
        }
        if (disabledCollaboratorsWithOneEntitledFundingSource.includes(rowData)) {
          return {
            label: formatMessage(
              'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.tooltips.oneFundingSource'
            ),
          };
        }
        if (disabledFullAccessCollaborators.includes(rowData)) {
          return {
            label: formatMessage(
              'widgets.addOrUpdateDeliveryMethod.entitled.fundingSource.modal.list.header.tooltips.fullAccess'
            ),
          };
        }
        return undefined;
      },
    }),
    [
      areAllSelected,
      formatMessage,
      actorId,
      disabledCollaboratorsWithOneEntitledFundingSource,
      disabledFullAccessCollaborators,
    ]
  );

  return {
    rowSelections,
    selectedRows: rowSelections.reduce<Record<string, boolean>>((obj, rowId) => ({ ...obj, [rowId]: true }), {}),
    onRowSelectionChange,
    disableRowSelection: shouldDisableRowSelection,
    onAllRowsSelectionChange,
    rowSelectionTooltips,
    areAllSelected,
    changedOriginRows: changedRows,
  };
};
