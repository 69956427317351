import { Button, Card, Group, Text } from '@melio/penny';
import { Organization } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';

export type SelectCompanyScreenProps = {
  organizations: Organization[];
  selectOrganization: (organizationId: string) => void;
  vendorName: string;
};

export const SelectCompanyScreen: React.FC<SelectCompanyScreenProps> = ({
  organizations,
  selectOrganization,
  vendorName,
  ...props
}) => {
  const [selectedOrgId, setSelectedOrg] = React.useState<string>();
  const { formatMessage } = useMelioIntl();

  const renderOrgsCards = () =>
    organizations.map((organization) => (
      <Card
        key={organization.id}
        onClick={() => setSelectedOrg(organization.id)}
        variant="flat"
        isSelected={organization.id === selectedOrgId}
        data-testid={`company-card-${organization.id}`}
      >
        <Text textStyle="body2Semi">{organization.name}</Text>
      </Card>
    ));

  return (
    <NewSinglePaymentStepLayout
      data-component="PaymentRequestSelectCompanyActivity.SelectCompanyScreen"
      data-testid="payment-request-select-company-activity-select-company-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions paddingY="l">
          <Button
            label={formatMessage('activities.paymentRequestSelectCompany.screens.selectCompany.cta')}
            isDisabled={!selectedOrgId}
            onClick={() => selectOrganization(selectedOrgId as string)}
            data-testid="continue-button"
          />
        </NewSinglePaymentStepLayout.Actions>
      }
      {...props}
    >
      <NewSinglePaymentStepLayout.Title data-testid="payment-request-select-company-activity-select-company-screen-title">
        <Group variant="vertical" spacing="s">
          <Text textStyle="heading1Semi">
            {formatMessage('activities.paymentRequestSelectCompany.screens.selectCompany.title')}
          </Text>
          <Text textStyle="body2">
            {formatMessage('activities.paymentRequestSelectCompany.screens.selectCompany.description', { vendorName })}
          </Text>
        </Group>
      </NewSinglePaymentStepLayout.Title>
      <Group variant="vertical" spacing="s" alignItems="center">
        {renderOrgsCards()}
      </Group>
    </NewSinglePaymentStepLayout>
  );
};

SelectCompanyScreen.displayName = 'PaymentRequestSelectCompanyActivity.SelectCompanyScreen';
