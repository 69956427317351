import { Box } from '@chakra-ui/react';
import { Button, Container, Divider, Group, Icon, Link, SecuredTextField, Text } from '@melio/penny';
import { AccountingPlatformInitQuickBooksDesktopResponseData } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { ErrorBanner } from './components/ErrorBanner';
import { FooterContent } from './components/FooterContent';

type Props = {
  isError: boolean;
  isLoading: boolean;
  isVerifyLoading: boolean;
  initQuickBooksDesktopData?: AccountingPlatformInitQuickBooksDesktopResponseData;
  onCancel: VoidFunction;
  onCopyPassword: (value?: string) => void;
  onVerifyConnection: VoidFunction;
  onDownloadWebConnector: VoidFunction;
};

export const ConnectToQuickBooksDesktopScreen = ({
  isError,
  isLoading,
  isVerifyLoading,
  initQuickBooksDesktopData,
  onCancel,
  onCopyPassword,
  onVerifyConnection,
  onDownloadWebConnector,
}: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <NewSinglePaymentStepLayout
      data-component="AccountingPlatformConnectActivity.ConnectToQuickBooksDesktopScreen"
      data-testid="accounting-platform-connect-quickbooks-desktop-screen"
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <Box />
        </NewSinglePaymentStepLayout.Header>
      }
      isStickyFooter
      isLoading={isLoading}
      footerContent={<FooterContent onClose={onCancel} onSubmit={onVerifyConnection} isLoading={isVerifyLoading} />}
    >
      <Container>
        <Group variant="vertical">
          <NewSinglePaymentStepLayout.Title textAlign="start">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.title')}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description textAlign="start">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.description')}
          </NewSinglePaymentStepLayout.Description>
        </Group>

        {isError && <ErrorBanner />}

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.description'
              )}
            </Text>
          </Box>
          <Button
            variant="secondary"
            leftElement={<Icon size="small" type="download" color="inherit" aria-hidden />}
            onClick={onDownloadWebConnector}
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.cta'
            )}
            isDisabled={isVerifyLoading}
            aria-label="download-web-connector-button"
            data-testid="download-web-connector-button"
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.description'
              )}
            </Text>
          </Box>
          <Link
            color="secondary"
            // TODO: Replace with the link to the QuickBooks Desktop Web Connector documentation
            // https://meliorisk.atlassian.net/browse/ME-65043
            href="#"
            isBold
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.cta'
            )}
            variant="standalone"
            data-testid="connecting-melio-and-qbdt-guide-link"
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.description',
                {
                  link: (
                    <Link
                      color="secondary"
                      // TODO: Replace with the link to the QuickBooks Desktop Web Connector documentation
                      // https://meliorisk.atlassian.net/browse/ME-65043
                      href="#"
                      isBold
                      label={formatMessage(
                        'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.link'
                      )}
                      variant="standalone"
                      data-testid="connecting-melio-and-qbdt-guide-link"
                    />
                  ),
                }
              )}
            </Text>
          </Box>
          <Box display="flex">
            <SecuredTextField isReadOnly type="password" value={initQuickBooksDesktopData?.password} />
            <Box pl="s">
              <Button
                variant="primary"
                onClick={() => onCopyPassword(initQuickBooksDesktopData?.password)}
                label={formatMessage(
                  'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.copyButton'
                )}
                aria-label={formatMessage(
                  'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.copyButton.ariaLabel'
                )}
                size="large"
                isLoading={isLoading}
                data-testid="copy-password-button"
              />
            </Box>
          </Box>
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepFour.title'
              )}
            </Text>
          </Box>
          <Box pt="s">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepFour.description'
              )}
            </Text>
          </Box>
        </Container>

        <Divider />

        <Container data-testid="accounting-platform-connect-quickbooks-notice" paddingY="m">
          <Box>
            <Text as="h3" textStyle="body3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.notice.title'
              )}
            </Text>
          </Box>
          <Box>
            <Text textStyle="body3">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.notice.description'
              )}
            </Text>
          </Box>
        </Container>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
