import { GoogleAddressInput, InternationalAddress as FormInternationalAddress } from '@melio/form-controls';
import { FieldValues, Form, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import _get from 'lodash/get';
import { ComponentProps, useState } from 'react';
import type { Path } from 'react-hook-form';

import { isFieldReadonly as _isFieldReadonly } from '../formUtils';

type InternationalAddressInputProps<T extends FieldValues = FieldValues> = {
  form: UseMelioFormResults<T>;
  addressLine1FieldName: Path<T>;
  cityFieldName: Path<T>;
  countryCodeFieldName: Path<T>;
  stateFieldName: Path<T>;
  postalCodeFieldName: Path<T>;
};
export const InternationalAddressInput = ({
  form,
  addressLine1FieldName,
  cityFieldName,
  countryCodeFieldName,
  stateFieldName,
  postalCodeFieldName,
}: InternationalAddressInputProps) => {
  const { formatMessage } = useMelioIntl();
  const [showFullAddress, setShowFullAddress] = useState(!!form.getValues(addressLine1FieldName));

  const isFieldReadonly = (fieldKey: string) =>
    _isFieldReadonly({
      form,
      fieldKey,
    });
  const setValuesToAddress = (address: FormInternationalAddress) => {
    form.setValue(addressLine1FieldName, address.line1, { shouldValidate: true, shouldDirty: true });
    form.setValue(stateFieldName, address.state.name, { shouldValidate: true, shouldDirty: true });
    form.setValue(cityFieldName, address.city.name, { shouldValidate: true, shouldDirty: true });
    form.setValue(postalCodeFieldName, address.postalCode, { shouldValidate: true, shouldDirty: true });
    form.setValue(countryCodeFieldName, address.country.code, { shouldValidate: true, shouldDirty: true });

    setShowFullAddress(true);
  };
  const handleAddressChange: ComponentProps<typeof GoogleAddressInput>['onChange'] = ({ target: { value } }) => {
    setValuesToAddress(value as unknown as FormInternationalAddress);
  };

  return (
    <>
      <GoogleAddressInput
        {...form.registerField(addressLine1FieldName)}
        isViewMode={isFieldReadonly(addressLine1FieldName)}
        isRequired
        onChange={handleAddressChange}
        colSpan={6}
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.line1.label'
          ),
        }}
        placeholder={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.line1.placeholder'
        )}
        error={
          _get(form.formState.errors, addressLine1FieldName)
            ? {
                message: formatMessage(
                  'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.addressField.required'
                ),
              }
            : undefined
        }
      />
      {showFullAddress ? (
        <>
          <Form.TextField
            {...form.registerField(cityFieldName)}
            colSpan={3}
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.city.label`
              ),
            }}
            isViewMode={isFieldReadonly(cityFieldName)}
          />
          <Form.TextField
            {...form.registerField(stateFieldName)}
            colSpan={3}
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.state.label`
              ),
            }}
            isViewMode={isFieldReadonly(stateFieldName)}
          />
          <Form.TextField
            {...form.registerField(postalCodeFieldName)}
            colSpan={3}
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.postalCode.label`
              ),
            }}
            isViewMode={isFieldReadonly(postalCodeFieldName)}
          />
          <Form.TextField
            {...form.registerField(countryCodeFieldName)}
            colSpan={3}
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.countryCode.label`
              ),
            }}
            isViewMode={isFieldReadonly(countryCodeFieldName)}
          />
        </>
      ) : null}
    </>
  );
};
