import { Currency } from '@melio/international-payments-utils';
import { Form, Group, Text, Tooltip, UseMelioFormResults, useWatch } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useRequireFields } from '../useRequireFields';
import { getRequireFieldsForBankDetails, useCurrenciesOptions, useGetCountryOption } from './fxBankDetailsForm.utils';
import { RequiredFieldsComponent } from './RequiredFieldsComponent';
import { FxVendorAccountFieldsFormFields } from './types';

export type InternationalRequireFieldsFormProps = {
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
  defaultValues?: Partial<FxVendorAccountFieldsFormFields>;
};

export const FxBankDetailsForm = ({ form, defaultValues }: InternationalRequireFieldsFormProps) => {
  const { formatMessage } = useMelioIntl();

  const { formProps, registerField, control, setValue } = form;

  const [foreignCurrency, fxCountrySelection] = useWatch({ control, name: ['foreignCurrency', 'fxCountrySelection'] });
  const { requiredFields } = useRequireFields({ currency: foreignCurrency });
  const currenciesOptions = useCurrenciesOptions(fxCountrySelection);
  const countriesOptions = useGetCountryOption(foreignCurrency);

  const isCountryDisabled = !!defaultValues?.fxCountrySelection;
  const isCurrencyDisabled = !!defaultValues?.foreignCurrency;

  return (
    <Group variant="vertical" width="full" spacing="m">
      <Text textStyle="body4Semi" color="neutral.darkest">
        {formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.title')}
      </Text>
      <Form {...formProps}>
        <Tooltip
          placement="top-end"
          isEnabled={isCountryDisabled}
          label={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.tooltip'
          )}
        >
          <Form.SelectNew
            options={countriesOptions}
            {...registerField('fxCountrySelection')}
            labelProps={{
              label: formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.label'
              ),
            }}
            isDisabled={isCountryDisabled}
            placeholder={formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.placeHolder'
            )}
          />
        </Tooltip>
        <Tooltip
          placement="top-end"
          isEnabled={isCurrencyDisabled}
          label={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCurrencySelection.tooltip'
          )}
        >
          <Form.SelectNew
            options={currenciesOptions}
            {...registerField('foreignCurrency')}
            onChange={(e) => {
              // Reset previous currency required fields
              getRequireFieldsForBankDetails(requiredFields)?.fields.map((field) => setValue(field.key, ''));
              setValue('foreignCurrency', e.target.value as Currency);
            }}
            labelProps={{
              label: formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency.label'
              ),
            }}
            isDisabled={isCurrencyDisabled}
            placeholder={formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency.placeHolder'
            )}
          />
        </Tooltip>
        <RequiredFieldsComponent requiredFields={requiredFields} form={form} />
      </Form>
    </Group>
  );
};
