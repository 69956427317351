import { Payment } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';

export const usePaymentsLateApprovals = ({ payments }: { payments: Payment[] }) => {
  const { isValidScheduledDate, createDate, isDatePartEqual } = useDateUtils();

  return payments.reduce((acc, payment) => {
    acc[payment.id] = {
      isDebitDateAfterToday: !isValidScheduledDate(payment.scheduledDate),
      isDebitDateToday: isDatePartEqual(payment.scheduledDate, createDate()),
    };
    return acc;
  }, {} as { [paymentId: string]: { isDebitDateAfterToday: boolean; isDebitDateToday: boolean } });
};
