import { useBillActions } from '@melio/ap-domain';
import { TableRowSelectionState, useBreakpoint, useTable } from '@melio/penny';
import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BillsTabItem, SelectableInboxItemOptionsType, SelectedInboxItemType } from './types';

export type UseBillsTabSelectionResult = {
  rowSelections: SelectedInboxItemType[];
  setRowSelections: (rows: SelectedInboxItemType[]) => void;
  setDisableAllRowsSelection: (disabled: boolean) => void;
  disableAllRowsSelection: boolean;
  uncheckSelection: (selection: Selection) => void;
  areAllSelected: boolean;
} & Pick<
  Parameters<typeof useTable<BillsTabItem>>[0],
  'selectedRows' | 'onRowSelectionChange' | 'onAllRowsSelectionChange' | 'disableRowSelection' | 'rowSelectionTooltips'
>;

type UseBillsTabSelectionProps = {
  billsData: BillsTabItem[];
  onChange: (rowSelections: SelectedInboxItemType[], lastSelection: Selection) => void;
};

export type Selection =
  | {
      type: 'all';
    }
  | { type: 'row'; rowData: BillsTabItem }
  | null;

export const useBillsTabSelection = ({
  billsData,
  onChange,
}: UseBillsTabSelectionProps): UseBillsTabSelectionResult => {
  const [isBatchPaymentRequestsEnabled] = useFeature(FeatureFlags.BatchPaymentRequestsEnabled, false);
  const [rowSelections, setRowSelections] = useState<SelectedInboxItemType[]>([]);
  const [disableAllRowsSelection, setDisableAllRowsSelection] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const { getBillActions } = useBillActions();

  const shouldDisableRowSelection = useCallback(
    (billItem: BillsTabItem) => {
      if (disableAllRowsSelection) {
        return true;
      }

      if (billItem.type === InboxItemBillTypeEnum.Bill) {
        const { actions } = getBillActions(billItem.payload);

        return !actions.newPayment;
      }

      const isEnabledPaymentRequest =
        billItem.type === InboxItemPaymentRequestTypeEnum.PaymentRequest && isBatchPaymentRequestsEnabled;

      return billItem.type === InboxItemScannedInvoiceTypeEnum.ScannedInvoice || !isEnabledPaymentRequest;
    },
    [getBillActions, isBatchPaymentRequestsEnabled, disableAllRowsSelection]
  );

  const selectableRows = billsData.filter((row) => !shouldDisableRowSelection(row));

  useEffect(() => {
    setAreAllSelected(selectableRows.length > 0 && selectableRows.length === rowSelections.length);
  }, [rowSelections, selectableRows.length]);

  const onRowSelectionChange = useCallback(
    (selectionState: TableRowSelectionState<BillsTabItem>) => {
      const { rowId, rowData, isSelected } = selectionState;

      setRowSelections((selectedRows) =>
        isSelected
          ? [
              ...selectedRows,
              {
                id: rowId,
                type: rowData.type as SelectableInboxItemOptionsType,
              },
            ]
          : selectedRows.filter((selectedRow) => selectedRow.id !== rowId)
      );

      onChange(
        isSelected
          ? [
              ...rowSelections,
              {
                id: rowId,
                type: rowData.type as SelectableInboxItemOptionsType,
              },
            ]
          : rowSelections.filter((selectedRow) => selectedRow.id !== rowId),
        { type: 'row', rowData: selectionState.rowData }
      );
    },
    [rowSelections, onChange]
  );

  const onAllRowsSelectionChange = useCallback(
    (areAllSelected: boolean) => {
      const rowSelections: SelectedInboxItemType[] = areAllSelected
        ? billsData
            ?.filter((row) => !shouldDisableRowSelection(row))
            .map((val) => ({
              id: val.payload.id,
              type: val.type as SelectableInboxItemOptionsType,
            })) ?? []
        : [];

      setRowSelections(rowSelections);

      onChange(rowSelections, areAllSelected ? { type: 'all' } : null);
    },
    [billsData, onChange, shouldDisableRowSelection]
  );

  const rowSelectionTooltips = useMemo(
    () => ({
      header: {
        label: !areAllSelected
          ? formatMessage('activities.payDashboard.billsTab.table.tooltips.selectAll')
          : formatMessage('activities.payDashboard.billsTab.table.tooltips.deselectAll'),
      },
      row: ({ rowData }: { rowData: BillsTabItem }) => {
        if (shouldDisableRowSelection(rowData) && rowData.type === InboxItemScannedInvoiceTypeEnum.ScannedInvoice) {
          return { label: formatMessage('activities.payDashboard.billsTab.table.tooltips.scannedInvoiceCheckbox') };
        }
        if (
          shouldDisableRowSelection(rowData) &&
          rowData.type === InboxItemPaymentRequestTypeEnum.PaymentRequest &&
          !isBatchPaymentRequestsEnabled
        ) {
          return { label: formatMessage('activities.payDashboard.billsTab.table.tooltips.paymentRequestCheckbox') };
        }
        return undefined;
      },
    }),
    [areAllSelected, formatMessage, shouldDisableRowSelection, isBatchPaymentRequestsEnabled]
  );

  const uncheckSelection = useCallback(
    (selection: Selection) => {
      if (!selection) {
        return;
      }

      if (selection.type === 'all') {
        onAllRowsSelectionChange(false);
      } else if (selection.type === 'row') {
        onRowSelectionChange({
          rowData: selection.rowData,
          rowId: selection.rowData.payload.id,
          isSelected: false,
        });
      }
    },
    [onAllRowsSelectionChange, onRowSelectionChange]
  );

  if (isExtraSmallScreen) {
    return {
      rowSelections,
      setRowSelections,
      uncheckSelection,
      areAllSelected,
      setDisableAllRowsSelection,
      disableAllRowsSelection,
    };
  }

  return {
    rowSelections,
    setRowSelections,
    setDisableAllRowsSelection,
    disableAllRowsSelection,
    selectedRows: rowSelections.reduce<Record<string, boolean>>((obj, row) => ({ ...obj, [row.id]: true }), {}),
    onRowSelectionChange,
    onAllRowsSelectionChange,
    rowSelectionTooltips,
    disableRowSelection: shouldDisableRowSelection,
    uncheckSelection,
    areAllSelected,
  };
};
