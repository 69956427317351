import { ReceivablePaymentDetailsSourceEnum, useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { GuestPaymentLayout } from '../../layout';
import { GuestPaymentRequestErrorsActivity } from '../guest-payments-request-errors';
import { GuestPaymentConfirmationScreen } from './screens';

export type GuestPaymentConfirmationActivityProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
};

export const GuestPaymentConfirmationActivity = withAnalyticsContext<GuestPaymentConfirmationActivityProps>(
  forwardRef(({ setAnalyticsProperties, paymentRequestLink, onViewInvoice }, ref) => {
    const {
      data: paymentRequestDetails,
      isLoading,
      error,
    } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
    });
    const { track } = useAnalytics();

    const handleOnViewInvoice = () => {
      track('PaymentRequest', 'Click', {
        PageName: 'invoice-paid',
        Intent: 'view-invoice-file',
        Cta: 'view',
      });
      onViewInvoice();
    };

    setAnalyticsProperties({
      ProductName: 'ar',
      Flow: 'payment-request',
      PaymentRequestLink: paymentRequestLink,
    });

    if (isLoading || !paymentRequestDetails || !paymentRequestDetails.receivablePaymentDetails) {
      return <GuestPaymentLayout isLoading data-component={GuestPaymentConfirmationActivity.displayName} ref={ref} />;
    }

    if (error) {
      return <GuestPaymentRequestErrorsActivity paymentRequestLink={paymentRequestLink} />;
    }

    return (
      <GuestPaymentConfirmationScreen
        ref={ref}
        amount={paymentRequestDetails.invoice.totalAmount}
        paidAt={paymentRequestDetails.receivablePaymentDetails.paidAt}
        invoicePreviewUrl={paymentRequestDetails.invoice.fileInfo.previewUrls[0]}
        paymentStatus={paymentRequestDetails.receivablePaymentDetails.paymentStatus}
        isMarkedAsPaid={
          paymentRequestDetails.receivablePaymentDetails.source === ReceivablePaymentDetailsSourceEnum.Manual
        }
        onViewInvoice={handleOnViewInvoice}
      />
    );
  })
);

GuestPaymentConfirmationActivity.displayName = 'GuestPaymentRequestConfirmationActivity';
