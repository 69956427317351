import { useEstimatedDeliveryDateLabel } from '@melio/ap-domain';
import { Group, GroupProps, Text, useBreakpointValue } from '@melio/penny';
import { DeliveryMethod } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

import { PaymentScheduledPaymentDetailsCardContainer } from '../../../../../PaymentScheduled/NewPaymentScheduled/screens/PaymentsScheduled/components/PaymentDetailsCardContainer';
import { PaymentDetailsCardRow } from '../../../../../PaymentScheduled/NewPaymentScheduled/screens/PaymentsScheduled/components/PaymentDetailsRow';
import { useDeliveryMethodDescription } from '../../../../../PaymentScheduled/NewPaymentScheduled/screens/PaymentsScheduled/components/useDeliveryMethodDescription';
import { BillSubscriptionDescriptionProps } from '../../../../screens/RecurringPaymentScheduled/RecurringPaymentScheduled.types';

export const RecurringPaymentDetailsCard = ({
  subscriptionDetails,
}: {
  subscriptionDetails: BillSubscriptionDescriptionProps;
}) => {
  const { formatDate, formatCurrency, formatMessage } = useMelioIntl();

  const rowsSpacing = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 'xs', m: 'xs' });

  const deliveryMethodDisplayText = useDeliveryMethodDescription({
    deliveryMethod: subscriptionDetails.deliveryMethod,
    vendorName: subscriptionDetails.vendor.name,
  });

  return (
    <PaymentScheduledPaymentDetailsCardContainer
      paymentId={subscriptionDetails.firstPaymentId}
      approvalDecisionStatus={subscriptionDetails.approvalDecisionStatus}
      bills={[]}
      deliveryMethod={subscriptionDetails.deliveryMethod}
      paymentAmount={subscriptionDetails.paymentAmount}
      scheduledDate={subscriptionDetails.scheduledDate}
      icon="repeat"
      isRecurringFlow
    >
      <Group variant="vertical" spacing="m" hasDivider>
        <Group as="ul" variant="vertical" spacing={rowsSpacing}>
          <PaymentDetailsCardRow
            fieldName="vendorName"
            titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.vendorName"
            value={subscriptionDetails.vendor.name}
          />
          <PaymentDetailsCardRow
            fieldName="paymentFrequency"
            titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.paymentFrequency"
            value={formatMessage(
              `activities.fundingSourceSelection.screens.recurringPaymentDetailsCard.descriptionRecurring.frequency.${subscriptionDetails.intervalType}`
            )}
          />
          {subscriptionDetails.numOfOccurrences && (
            <PaymentDetailsCardRow
              fieldName="recursFor"
              titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.recursFor"
              value={formatMessage(
                'activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.recursFor.value',
                { number: subscriptionDetails.numOfOccurrences }
              )}
            />
          )}
          {subscriptionDetails.startDate && (
            <PaymentDetailsCardRow
              fieldName="startDate"
              titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.startDate"
              value={formatDate(subscriptionDetails.startDate, { dateStyle: 'medium' })}
            />
          )}
          {subscriptionDetails.nextOccurrence?.deliveryDate && (
            <PaymentDetailsCardRow
              fieldName="nextPaymentDeliveryDate"
              titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.nextPaymentDeliveryDate"
              value={formatDate(subscriptionDetails.nextOccurrence.deliveryDate, { dateStyle: 'medium' })}
            />
          )}
          {subscriptionDetails.endDate && (
            <PaymentDetailsCardRow
              fieldName="endDate"
              titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.endDate"
              value={formatDate(subscriptionDetails.endDate, { dateStyle: 'medium' })}
            />
          )}
          {subscriptionDetails.lastPaymentAmount && (
            <PaymentDetailsCardRow
              fieldName="lastPaymentAmount"
              titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.lastPaymentAmount"
              value={formatCurrency(subscriptionDetails.lastPaymentAmount)}
            />
          )}
          <PaymentDetailsCardRow
            fieldName="paymentMethod"
            titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.paymentMethod"
            value={subscriptionDetails.fundingSource.displayName}
          />
          <PaymentDetailsCardRow
            fieldName="deliveryMethod"
            titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.deliveryMethod"
            value={deliveryMethodDisplayText}
          />
        </Group>
        {subscriptionDetails.firstPaymentDebitDate &&
        subscriptionDetails.firstPaymentDeliveryDate &&
        subscriptionDetails.firstPaymentMaxDeliveryDate ? (
          <FirstPaymentDetails
            deliveryMethod={subscriptionDetails.deliveryMethod}
            debitDate={subscriptionDetails.firstPaymentDebitDate}
            minDeliveryDate={subscriptionDetails.firstPaymentDeliveryDate}
            maxDeliveryDate={subscriptionDetails.firstPaymentMaxDeliveryDate}
          />
        ) : null}
      </Group>
    </PaymentScheduledPaymentDetailsCardContainer>
  );
};

const FirstPaymentDetails = ({
  deliveryMethod,
  debitDate,
  minDeliveryDate,
  maxDeliveryDate,
}: {
  deliveryMethod: DeliveryMethod;
  debitDate: Date;
  minDeliveryDate: Date;
  maxDeliveryDate: Date;
}) => {
  const { formatDate, formatMessage } = useMelioIntl();
  const rowsSpacing = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 'xs', m: 'xs' });

  const { dateLabel } = useEstimatedDeliveryDateLabel({ minDeliveryDate, maxDeliveryDate });

  const deliveryDateMessageKey: MessageKey =
    deliveryMethod.type === 'paper-check'
      ? 'activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.deliveryDate.check'
      : 'activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.deliveryDate';

  return (
    <Group variant="vertical" spacing="s">
      <Text textStyle="body4" color="neutral.darker">
        {formatMessage(
          'activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.firstPaymentDetails.label'
        )}
      </Text>
      <Group as="ul" variant="vertical" spacing={rowsSpacing}>
        <PaymentDetailsCardRow
          fieldName="debitDate"
          titleMessage="activities.recurringPaymentScheduledSuccessfully.screens.recurringPaymentDetailsCard.debitDate"
          value={formatDate(debitDate, { dateStyle: 'medium' })}
        />
        <PaymentDetailsCardRow fieldName="deliveryDate" titleMessage={deliveryDateMessageKey} value={dateLabel} />
      </Group>
    </Group>
  );
};
