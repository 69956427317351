import React, { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { uniq } from 'lodash';
import { calculateFundingSourcePermissionForGroup, getBiCardType, getGroupedFundingSources } from '@melio/ap-domain';
import { restrictedAddableFundingSources } from '@melio/ap-widgets';
import { Icon, NakedButton } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { EventPropertiesCardType } from '@melio/platform-analytics/src/types/types';
import {
  BillingFeeSetting,
  CardType,
  FeeType,
  FundingSource as FundingSourceAPI,
  FundingSourceType,
  useCollaborator,
  useFundingSources,
  useOrgBillingFeeSettings,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { FundingSourceGroupName, useConfig } from '@melio/platform-provider';

import { AddFundingSourceButton } from '@/cl/components/AddFundingSourceButton/AddFundingSourceButton.component';
import { FundingSourceCardList } from '@/cl/components/FundingSourceCardList/FundingSourceCardList.component';
import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';
import { FormattedMessage, usePlatformIntl } from '@/translations/Intl';
import { Fee } from '@/widgets/Fee/Fee.widget';
import { SectionHeaderWithButton } from '@/widgets/settings-page/Common/SectionHeaderWithButton.widget';

export const PaymentsMethodPage = ({ shouldRenderTitle = true }: { shouldRenderTitle?: boolean | undefined }) => {
  const [isBillingFeeSettingsEnabled] = useFeature(FeatureFlags.BillingFees, false);
  useActiveScreen(ScreensEnum.settings, ActiveFlowEnum.settingsPaymentMethods);
  const { formatMessage } = usePlatformIntl();
  const title = formatMessage('widgets.paymentMethods.title');
  const { data: fundingSources = [], isLoading: isFundingSourcesLoading } = useFundingSources();
  const { data: orgBillingFeeSettings = [], isLoading: isBillingFeeSettingsLoading } = useOrgBillingFeeSettings({
    enabled: isBillingFeeSettingsEnabled,
  });
  const { data: actor, isLoading: isActorLoading } = useCollaborator({ id: 'me' });
  const { track } = useAnalytics();

  useEffect(() => {
    if (!isFundingSourcesLoading) {
      track(`Settings`, 'View', {
        Product: 'ap',
        PageName: 'payment-methods',
        Flow: 'payment-methods',
        intent: 'manage-payment-method',
        paymentMethodsTypes: uniq(fundingSources.map((fs) => fs.type)),
        PaymentMethodIds: fundingSources.map((fs) => fs.id),
      });
    }
  }, [track, fundingSources, isFundingSourcesLoading]);

  return (
    <WithLoading isLoading={isFundingSourcesLoading || isActorLoading || isBillingFeeSettingsLoading}>
      <SettingsSectionContainer data-component="PaymentsMethodPage">
        <Flex direction={'column'} gridGap={'24px'}>
          {shouldRenderTitle ? <SectionHeaderWithButton title={title} /> : null}
          <Flex direction={'column'} gridGap={6}>
            <CardsByOrder
              orgBillingFeeSettings={orgBillingFeeSettings}
              fundingSources={fundingSources}
              userId={actor?.userId}
            />
          </Flex>
        </Flex>
      </SettingsSectionContainer>
    </WithLoading>
  );
};

const fundingSourceGroupNameToFundingType = (groupName: FundingSourceGroupName) => {
  switch (groupName) {
    case 'flex-account':
      return FundingSourceType.FlexAccount;
    case 'bank-account':
      return FundingSourceType.BankAccount;
    case 'card':
    case 'credit':
    case 'debit':
      return FundingSourceType.Card;
  }
};

const fundingSourceGroupNameToCardType = (groupName: FundingSourceGroupName) => {
  switch (groupName) {
    case 'bank-account':
    case 'flex-account':
    case 'card': // card is unified so there is no type
      return undefined;
    case 'credit':
      return CardType.Credit;
    case 'debit':
      return CardType.Debit;
  }
};

interface CardsByOrderProps {
  fundingSources: FundingSourceAPI[];
  orgBillingFeeSettings: BillingFeeSetting[];
  userId?: string;
}

const CardsByOrder = ({ orgBillingFeeSettings, fundingSources, userId }: CardsByOrderProps) => {
  const { fundingSourcePolicy = {} } = useConfig().settings ?? {};
  const connectedAccounts: JSX.Element[] = [];
  const notConnectedAccounts: JSX.Element[] = [];
  const groupedFundingSources = getGroupedFundingSources({
    fundingSources: fundingSources ?? [],
    fundingSourcesConfig: fundingSourcePolicy,
  });

  groupedFundingSources.forEach(({ type, fundingSources }) => {
    const { isWriteAllowed, isReadAllowed } = calculateFundingSourcePermissionForGroup({
      groupName: type,
      fundingSourceConfig: fundingSourcePolicy,
    });

    if (isReadAllowed) {
      const accountsElement = (
        <Accounts
          accounts={fundingSources}
          orgBillingFeeSettings={orgBillingFeeSettings}
          key={type}
          fundingType={fundingSourceGroupNameToFundingType(type)}
          canAdd={isWriteAllowed}
          cardType={fundingSourceGroupNameToCardType(type)}
          userId={userId}
        />
      );
      if (fundingSources.length) {
        connectedAccounts.push(accountsElement);
      } else if (isWriteAllowed) {
        notConnectedAccounts.push(accountsElement);
      }
    }
  });

  return <>{[...connectedAccounts, ...notConnectedAccounts]}</>;
};

type FundingTypeProps = {
  fundingType: 'card' | 'bank-account' | 'flex-account';
  cardType?: CardType;
};

type AccountsProps = {
  accounts: FundingSourceAPI[];
  orgBillingFeeSettings: BillingFeeSetting[];
  canAdd: boolean;
  userId?: string;
} & FundingTypeProps;

type EmptyAccountCardProps = {
  onClick: () => void;
} & FundingTypeProps;

const Accounts = (props: AccountsProps) => {
  const { goToBankAccountSelect, goToAddCard } = useRouter();
  const { track } = useAnalytics();
  const { accounts, fundingType, canAdd, userId, orgBillingFeeSettings } = props;

  const isCardAccount = fundingType === FundingSourceType.Card;
  const isFundingSourceAllowedToBeAdded = !restrictedAddableFundingSources.includes(fundingType);

  const onClick = () => {
    let trackProps: { CardType?: EventPropertiesCardType; FundingType: string } = { FundingType: fundingType };
    if (fundingType === FundingSourceType.BankAccount) {
      goToBankAccountSelect();
    } else {
      goToAddCard({ cardType: props.cardType });
      trackProps.CardType = getBiCardType(props.cardType);
    }
    track('AddFundingSource', 'Chose', trackProps);
  };

  return accounts.length > 0 ? (
    <Flex direction={'column'} gridGap={4}>
      <FundingSourceCardList
        fundingSources={accounts}
        orgBillingFeeSettings={orgBillingFeeSettings}
        key={isCardAccount ? `${fundingType}-${props.cardType}` : `${fundingType}`}
        userId={userId}
      />
      {canAdd && isFundingSourceAllowedToBeAdded && (
        <Box>
          {isCardAccount ? (
            <AddFundingSourceButton onClick={onClick} fundingType={fundingType} cardType={props.cardType} />
          ) : (
            <AddFundingSourceButton onClick={onClick} fundingType={fundingType} />
          )}
        </Box>
      )}
    </Flex>
  ) : isFundingSourceAllowedToBeAdded ? (
    isCardAccount ? (
      <EmptyAccountCard onClick={onClick} fundingType={fundingType} cardType={props.cardType} />
    ) : (
      <EmptyAccountCard onClick={onClick} fundingType={fundingType} />
    )
  ) : (
    <></>
  );
};

const EmptyAccountCard = (props: EmptyAccountCardProps) => {
  const { formatMessage } = usePlatformIntl();
  const { fundingType, onClick, cardType } = props;

  const feeSuffix = formatMessage('widgets.paymentMethods.feeSuffix');

  let displayName, helperText, icon;
  if (fundingType === FundingSourceType.BankAccount) {
    icon = <Icon color="inherit" type="bank" />;
    displayName = <FormattedMessage id="widgets.paymentMethods.empty.bank.displayName" />;
    helperText = (
      <FormattedMessage
        id="widgets.paymentMethods.empty.bank.helperText"
        values={{ fee: <Fee feeType={FeeType.Ach} suffix={feeSuffix} /> }}
      />
    );
  } else if (props.cardType === CardType.Credit) {
    icon = <Icon color="inherit" type="credit-card" />;

    displayName = <FormattedMessage id="widgets.paymentMethods.empty.credit.displayName" />;
    helperText = (
      <FormattedMessage
        id="widgets.paymentMethods.empty.credit.helperText"
        values={{ fee: <Fee feeType={FeeType.Credit} suffix={feeSuffix} /> }}
      />
    );
  } else if (props.cardType === CardType.Debit) {
    icon = <Icon color="inherit" type="debit-card" />;

    displayName = <FormattedMessage id="widgets.paymentMethods.empty.debit.displayName" />;
    helperText = (
      <FormattedMessage
        id="widgets.paymentMethods.empty.debit.helperText"
        values={{ fee: <Fee feeType={FeeType.Debit} suffix={feeSuffix} /> }}
      />
    );
  } else {
    icon = <Icon color="inherit" type="credit-card" />;

    displayName = <FormattedMessage id="widgets.paymentMethods.empty.card.displayName" />;
    helperText = (
      <FormattedMessage
        id="widgets.paymentMethods.empty.card.helperText"
        values={{ fee: <Fee feeType={FeeType.Credit} suffix={feeSuffix} /> }}
      />
    );
  }
  const actionElement = (
    <NakedButton
      variant="secondary"
      onClick={onClick}
      data-testid={`add-new-funding-source-${cardType ?? fundingType}`}
      label={formatMessage('widgets.paymentMethods.empty.action')}
    />
  );
  return (
    <MethodCard
      testId={`payment-method-${cardType ?? fundingType}`}
      isDeliveryMethodExists={false}
      actionElement={actionElement}
      icon={icon}
      displayName={displayName}
      helperText={helperText}
    />
  );
};
