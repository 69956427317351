import { FormattedMessage } from '@melio/ar-domain';
import { Container, GridItem, SimpleGrid } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type InvoiceItemsFormHeaderProps = {
  hasDeleteColumn?: boolean;
};
export const InvoiceItemsFormHeader = forwardRef<InvoiceItemsFormHeaderProps>(
  ({ hasDeleteColumn = true, ...props }, ref) => (
    <Container
      backgroundColor="light"
      paddingY="s"
      data-component={InvoiceItemsFormHeader.displayName}
      ref={ref}
      {...props}
    >
      <SimpleGrid columns={24} textStyle="body4Semi" color="neutral.darkest" spacing="m">
        <GridItem colSpan={12} ps="s">
          <FormattedMessage id="ar.invoiceLifecycle.activities.invoiceTable.columns.catalogItem.label" />
        </GridItem>
        <GridItem colSpan={2} ps="xs">
          <FormattedMessage id="ar.invoiceLifecycle.activities.invoiceTable.columns.quantity.label" />
        </GridItem>
        <GridItem colSpan={4} ps="xs">
          <FormattedMessage id="ar.invoiceLifecycle.activities.invoiceTable.columns.price.label" />
        </GridItem>
        <GridItem colSpan={3} ps="xs" display="flex" justifyContent="flex-end">
          <FormattedMessage id="ar.invoiceLifecycle.activities.invoiceTable.columns.amount.label" />
        </GridItem>
        <GridItem colSpan={hasDeleteColumn ? 2 : 3} display="flex" justifyContent="center">
          <FormattedMessage id="ar.invoiceLifecycle.activities.invoiceTable.columns.tax.label" />
        </GridItem>
        {hasDeleteColumn && <GridItem colSpan={1}>&nbsp;</GridItem>}
      </SimpleGrid>
    </Container>
  )
);
InvoiceItemsFormHeader.displayName = 'InvoiceItemsFormHeader';
