import { FormDialog, FormInputs, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Text, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';
import * as yup from 'yup';

export type CatalogItemFormFields = {
  name: string;
  price: number;
  taxable?: boolean;
};

type CatalogItemFormFieldsInternal = Override<CatalogItemFormFields, { taxable: 'yes' | 'no' }>;

const useValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    name: yup
      .string()
      .required(formatMessage('ar.settings.activities.addEditCatalogItem.fields.name.validation.required'))
      .min(2, formatMessage('ar.settings.activities.addEditCatalogItem.fields.name.validation.min', { length: 2 })),
    price: yup
      .number()
      .typeError(formatMessage('ar.settings.activities.addEditCatalogItem.fields.price.validation.required'))
      .required(formatMessage('ar.settings.activities.addEditCatalogItem.fields.price.validation.required'))
      .moreThan(
        0,
        formatMessage('ar.settings.activities.addEditCatalogItem.fields.price.validation.moreThan', { moreThan: 0 })
      ),
    taxable: yup.string().oneOf(['yes', 'no']),
  }) as yup.SchemaOf<CatalogItemFormFieldsInternal>;
};

export type CatalogItemFormDialogScreenProps = Pick<
  FormWidgetProps<CatalogItemFormFields>,
  'defaultValues' | 'isSaving' | 'onSubmit'
> & {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading?: boolean;
  variant: 'add' | 'edit';
};
export const CatalogItemFormDialogScreen = forwardRef<CatalogItemFormDialogScreenProps>(
  ({ onSubmit, defaultValues: dvs = {}, isSaving, isLoading, onClose, variant: mode, isOpen, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const defaultValues = useMemo<Partial<CatalogItemFormFieldsInternal>>(() => {
      const { taxable = true, price = null as never, name = '' } = dvs;
      return { price, name, taxable: taxable ? 'yes' : 'no' };
    }, [JSON.stringify(dvs)]); // eslint-disable-line react-hooks/exhaustive-deps

    const { registerField, ...rest } = useMelioForm<CatalogItemFormFieldsInternal>({
      schema: useValidationSchema(),
      isSaving,
      defaultValues,
      onSubmit: ({ taxable, ...values }) => onSubmit({ ...values, taxable: taxable === 'yes' }),
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <FormDialog
        data-component={CatalogItemFormDialogScreen.displayName}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        header={formatMessage(
          mode == 'add'
            ? 'ar.settings.activities.addEditCatalogItem.add.header'
            : 'ar.settings.activities.addEditCatalogItem.edit.header'
        )}
        primaryButton={{
          label: formatMessage(
            mode == 'add'
              ? 'ar.settings.activities.addEditCatalogItem.primaryButton.add.label'
              : 'ar.settings.activities.addEditCatalogItem.primaryButton.edit.label'
          ),
        }}
        secondaryButton={{
          label: formatMessage('ar.settings.activities.addEditCatalogItem.secondaryButton.label'),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        {mode == 'edit' && (
          <Text color="neutral.black" textStyle="body2">
            {formatMessage('ar.settings.activities.addEditCatalogItem.edit.description')}
          </Text>
        )}
        <Form.TextField
          labelProps={{
            label: formatMessage('ar.settings.activities.addEditCatalogItem.fields.name.label'),
          }}
          {...registerField('name')}
          autoFocus
        />
        <FormInputs.AmountField
          labelProps={{
            label: formatMessage('ar.settings.activities.addEditCatalogItem.fields.price.label'),
          }}
          {...registerField('price')}
        />
        <Form.RadioGroup
          labelProps={{
            label: formatMessage('ar.settings.activities.addEditCatalogItem.fields.taxable.label'),
          }}
          {...registerField('taxable')}
          options={[
            {
              label: formatMessage('ar.settings.activities.addEditCatalogItem.fields.taxable.options.yes.label'),
              value: 'yes',
              ariaLabel: 'Taxable Yes',
            },
            {
              label: formatMessage('ar.settings.activities.addEditCatalogItem.fields.taxable.options.no.label'),
              value: 'no',
              ariaLabel: 'Taxable No',
            },
          ]}
        />
      </FormDialog>
    );
  }
);
CatalogItemFormDialogScreen.displayName = 'CatalogItemFormDialogScreen';
