import { Box } from '@chakra-ui/react';
import { Group } from '@melio/penny';
import { CardBrand, useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { keys, pickBy } from 'lodash';

import { TBTCardBrandLogo } from './components';

export type TBTCardBrandLogosWidgetProps = {
  selectedBrand: CardBrand;
};

export const TBTCardBrandLogosWidget = forwardRef<TBTCardBrandLogosWidgetProps, 'div'>(
  ({ selectedBrand, ...props }, ref) => {
    const { services } = useConfig();
    const supportedCardProviders = pickBy<typeof services.tbt.supportedCardProviders>(
      services.tbt.supportedCardProviders
    );

    return (
      <Box data-component="TBTCardBrandLogosWidget" {...props} ref={ref}>
        <Group justifyContent="center" spacing="xs">
          {keys(supportedCardProviders).map((brand) => (
            <TBTCardBrandLogo
              key={brand}
              brand={brand as CardBrand}
              isSelected={selectedBrand === brand || selectedBrand === 'unknown'}
            />
          ))}
        </Group>
      </Box>
    );
  }
);

TBTCardBrandLogosWidget.displayName = 'TBTCardBrandLogosWidget';
