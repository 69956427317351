import { FieldValues, UseMelioFormResults } from '@melio/penny';

export enum NewFirmClientFormSteps {
  BasicDetails = 'basicDetails',
  BillingDetails = 'billingDetails',
  PlanSelection = 'planSelection',
}

export type NewFirmClientFormStepDetails = {
  title: string;
  description: string;
  stepId: NewFirmClientFormSteps;
  component: React.FunctionComponent<StepFormProps>;
};

export enum SubscriptionBillingPayor {
  Client = 'Client',
  Firm = 'Firm',
}

export type NewFirmClientFormFields = {
  businessName: string;
  businessDBA: string;
  shouldInviteClient: boolean;
  clientEmailAddress: string;
  clientFirstName: string;
  clientLastName: string;
  whoPays: SubscriptionBillingPayor;
  subscriptionPlanId: string;
};

export type NewFirmClientFormField = keyof NewFirmClientFormFields;

export type RegisterFieldFn<T extends FieldValues = NewFirmClientFormFields> = UseMelioFormResults<T>['registerField'];
export type WatchFn<T extends FieldValues = NewFirmClientFormFields> = UseMelioFormResults<T>['watch'];
export type SetValueFn<T extends FieldValues = NewFirmClientFormFields> = UseMelioFormResults<T>['setValue'];
export type TriggerFieldsFn<T extends FieldValues = NewFirmClientFormFields> = UseMelioFormResults<T>['trigger'];
export type FormState<T extends FieldValues = NewFirmClientFormFields> = UseMelioFormResults<T>['formState'];

export type StepFormProps = {
  submitLabel: string;
  registerField: RegisterFieldFn;
  setValue: SetValueFn;
  watch: WatchFn;
  triggerFormValidationFields: (fields: (keyof NewFirmClientFormFields)[]) => Promise<boolean>;
  nextStep?: () => void;
};
