/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { partition } from 'lodash';
import {
  CancelJoinOrganizationRequestModalActivity,
  ResendJoinOrganizationRequestModalActivity,
} from '@melio/ap-activities';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  getOrganizationsPaymentsStatuses,
  isJoinOrganizationRequestExpired,
  JoinOrganizationRequest,
  JoinOrganizationRequestStatus,
  useAccount,
  useJoinOrganizationRequests,
  useOrganizations,
  useOrganizationsFullyExpanded,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { AccountCardList } from '@/cl/components/AccountCard/AccountCardList.component';
import { Loader } from '@/cl/components/Loader/Loader.component';
import { useRouter } from '@/hooks/router.hooks';
import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { JoinOrganizationRequestCardWidget } from '@/widgets/JoinOrganizationRequestCard/JoinOrganizationRequestCard.widget';
import { OrganizationCardWidget } from '@/widgets/OrganizationCard/OrganizationCard.widget';

export const OrganizationsDashboardScreen = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { track } = useAnalytics();
  const { formatMessage } = usePlatformIntl();
  const { switchOrganization } = useSwitchOrganization();
  const { goToNewDashboardTab } = useRouter();
  const [isSwitching, setIsSwitching] = useState<boolean>(false);

  const [resendRequestEntity, setResendRequestEntity] = useState<JoinOrganizationRequest | undefined>(undefined);
  const [cancelRequestEntity, setCancelRequestEntity] = useState<JoinOrganizationRequest | undefined>(undefined);

  const [isAddCompanyFeatureEnabled] = useFeature(FeatureFlags.AddCompanyEnabled, false);

  const { data: me } = useAccount({ id: 'me' });
  const { data: organizations = [], isLoading: isOrganizationsLoading } = useOrganizations();
  const { data: joinOrganizationRequests = [], isLoading: isJoinOrganizationRequestsLoading } =
    useJoinOrganizationRequests({
      enabled: isAddCompanyFeatureEnabled,
    });
  const {
    data: expandedOrganizations = [],
    isLoading: isExpandedOrganizationsLoading,
    isFetched: isExpandedOrganizationsFetched,
  } = useOrganizationsFullyExpanded();
  const organizationsToShow = isExpandedOrganizationsFetched ? expandedOrganizations : organizations;
  const joinOrganizationRequestsToShow = joinOrganizationRequests.filter(
    ({ status }) => status === JoinOrganizationRequestStatus.Pending,
  );

  const isLoading = isOrganizationsLoading || isJoinOrganizationRequestsLoading || isSwitching;
  const isLoadingExpanded = isLoading || isExpandedOrganizationsLoading;

  useEffect(() => {
    const [expiredRequests, pendingRequests] = partition(joinOrganizationRequestsToShow, (joinOrganizationRequest) => {
      return isJoinOrganizationRequestExpired(joinOrganizationRequest);
    });

    const paymentsStatus = getOrganizationsPaymentsStatuses(expandedOrganizations);

    setAnalyticsProperties({
      PageName: 'companies-dashboard',
      Flow: 'companies',
      Intent: 'view-companies',
      CountInTab: organizations.length,
      RequestsStatus: {
        pending: pendingRequests.length,
        expired: expiredRequests.length,
      },
      PaymentsStatus: paymentsStatus,
    });
  }, [joinOrganizationRequestsToShow, expandedOrganizations]);

  useEffect(() => {
    if (!isLoadingExpanded) {
      track('User', 'View');
    }
  }, [isLoadingExpanded, track]);

  const onAddButtonClick = () => {
    track('User', 'Click', {
      Intent: 'add-company',
    });
  };

  const onSelectAccount = useCallback(
    ({ organizationId, isAccountingFirm }: { organizationId: string; isAccountingFirm: boolean }) => {
      const isSelected = me?.organizationId === organizationId;
      if (isSelected) {
        goToNewDashboardTab('');
      } else {
        setIsSwitching(true);
        switchOrganization({ organizationId, isAccountingFirm });
      }
    },
    [switchOrganization, me],
  );

  if (isLoading) {
    return <Loader isAbsoluteCenter />;
  }

  return (
    <AccountCardList title={formatMessage('app.accountsDashboard.title')} onAddButtonClick={onAddButtonClick}>
      {organizationsToShow.map((organization) => (
        <OrganizationCardWidget
          key={`organization_card_${organization.id}`}
          organization={organization}
          isSelected={me?.organizationId === organization.id}
          isPaymentsOverviewLoading={isExpandedOrganizationsLoading}
          onClick={onSelectAccount}
        />
      ))}
      {joinOrganizationRequestsToShow.map((joinOrganizationRequest) => (
        <JoinOrganizationRequestCardWidget
          key={`join_organization-request_card_${joinOrganizationRequest.id}`}
          joinOrganizationRequest={joinOrganizationRequest}
          onRequestCancel={() => setCancelRequestEntity(joinOrganizationRequest)}
          onRequestResend={() => setResendRequestEntity(joinOrganizationRequest)}
        />
      ))}
      {resendRequestEntity ? (
        <ResendJoinOrganizationRequestModalActivity
          joinOrganizationRequest={resendRequestEntity}
          onClose={() => setResendRequestEntity(undefined)}
        />
      ) : null}
      {cancelRequestEntity ? (
        <CancelJoinOrganizationRequestModalActivity
          joinOrganizationRequest={cancelRequestEntity}
          onClose={() => setCancelRequestEntity(undefined)}
        />
      ) : null}
    </AccountCardList>
  );
});
