import {
  calculateRecurringEndDateByNumOfOccurrences,
  getNumberOfOccurrences,
  getRecurringFieldsToShow,
  RECURRING_NUM_OF_OCCURRENCES_REGEX,
} from '@melio/ap-domain';
import { Form, Group, Text, useBreakpointValue, UseMelioFormResults } from '@melio/penny';
import {
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
  DeliveryPreference,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { addBusinessDays, useDateUtils } from '@melio/platform-utils';
import { isBefore } from 'date-fns';
import { ComponentProps } from 'react';

import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../../../types';
import { DateField } from '../../../components/DateField';

type Props = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  deliveryPreference: DeliveryPreference | undefined;
  onStartDateChange: PaymentFlowOnChangeHandlers['onStartDateChange'];
  isLoading: boolean;
};
export function RecurringFields({ form, deliveryPreference, onStartDateChange, isLoading }: Props) {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { createDate } = useDateUtils();
  const {
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();

  const isMobile = useBreakpointValue({ xs: true, s: false });

  const availableFrequecyValues = isRecurringPaymentImprovementsEnabled
    ? Object.values(BillSubscriptionIntervalTypeEnum)
    : [BillSubscriptionIntervalTypeEnum.Weekly, BillSubscriptionIntervalTypeEnum.Monthly];
  const frequencyOptions: ComponentProps<typeof Form.SelectNew>['options'] = availableFrequecyValues.map((type) => ({
    value: type,
    label: formatMessage(`activities.paymentFlow.form.content.recurring.frequency.options.${type}`),
    testId: `frequency-${type}`,
  }));

  const endPolicyAvailableOptions = isRecurringPaymentImprovementsEnabled
    ? [
        BillSubscriptionEndPolicyEnum.NoEndDate,
        BillSubscriptionEndPolicyEnum.EndDate,
        BillSubscriptionEndPolicyEnum.NumOfOccurrences,
      ]
    : [BillSubscriptionEndPolicyEnum.EndDate, BillSubscriptionEndPolicyEnum.NumOfOccurrences];

  const endPolicyOptions: ComponentProps<typeof Form.SelectNew>['options'] = endPolicyAvailableOptions.map((type) => ({
    value: type,
    label: formatMessage(`activities.paymentFlow.form.content.recurring.paymentDuration.options.${type}`),
    testId: `endPolicy-${type}`,
  }));

  const [endPolicy, startDate, paymentAmount, intervalType, endDate, numOfOccurrences] = form.watch([
    'endPolicy',
    'startDate',
    'amountToPay',
    'intervalType',
    'endDate',
    'numOfOccurrences',
  ]);

  const recurringFieldsToShow = endPolicy
    ? getRecurringFieldsToShow(endPolicy, isRecurringPaymentImprovementsEnabled)
    : undefined;

  const calculatedNumOfOccurrences =
    intervalType && startDate
      ? getNumberOfOccurrences({
          paymentFrequency: intervalType,
          startDate,
          endDate: endDate || undefined,
        })
      : undefined;

  const calculatedLastPaymentDate =
    intervalType && numOfOccurrences && startDate
      ? calculateRecurringEndDateByNumOfOccurrences({
          paymentFrequency: intervalType,
          startDate,
          numOfOccurrences: Number(numOfOccurrences),
        })
      : undefined;

  return (
    <Group variant="vertical" spacing="m">
      <Form.SelectNew
        {...form.registerField('intervalType')}
        labelProps={{ label: formatMessage('activities.paymentFlow.form.content.recurring.frequency.label') }}
        options={frequencyOptions}
        isRequired
        shouldHideClearButton
      />
      <Group variant="vertical" spacing={isMobile ? 'm' : 's'}>
        <Group variant={isMobile ? 'vertical' : 'horizontal'} width="full" spacing={isMobile ? 's' : 'm'}>
          <Group.Item grow={1} basis={2}>
            <Group variant="vertical" width="full" spacing="xs-s">
              <DateField
                {...form.registerField('startDate')}
                onChange={(startDate) => {
                  if (startDate && endDate && isBefore(endDate, startDate)) {
                    form.setValue('endDate', addBusinessDays(startDate, 1));
                  }
                  onStartDateChange(startDate);
                }}
                toggleDatePickerAriaLabel={formatMessage(
                  'activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.toggleDatePickerAriaLabel'
                )}
                labelProps={{
                  label: formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.label'),
                }}
                placeholder={formatMessage('activities.paymentFlow.form.content.deliveryDate.placeholder')}
                minDate={deliveryPreference?.earliestDeliveryDate}
                maxDate={endDate || undefined}
                isDisabled={!isLoading && !deliveryPreference}
                isLoading={isLoading}
                excludeHolidays
                hideClear
                value={startDate}
                selectedDateAriaLabel={formatMessage(
                  'activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.selected.aria'
                )}
              />
              {startDate && deliveryPreference?.effectiveScheduleDate && (
                <Text textStyle="body2" color="neutral.darker" data-testid="startDate-debitDate-text">
                  {formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.helperText', {
                    debitDate: (
                      <Text>{formatDate(deliveryPreference.effectiveScheduleDate, { dateStyle: 'medium' })}</Text>
                    ),
                  })}
                </Text>
              )}
            </Group>
          </Group.Item>
          <Group.Item grow={1} basis={2}>
            <Form.SelectNew
              {...form.registerField('endPolicy')}
              options={endPolicyOptions}
              isRequired
              labelProps={{
                label: formatMessage('activities.paymentFlow.form.content.recurring.paymentDuration.label'),
              }}
              shouldHideClearButton
            />
          </Group.Item>
        </Group>
        {(recurringFieldsToShow?.endDate ||
          recurringFieldsToShow?.numberOfOccurrences ||
          recurringFieldsToShow?.lastAmount) && (
          <Group variant={isMobile ? 'vertical' : 'horizontal'} width="full" spacing="m">
            {recurringFieldsToShow.endDate && (
              <Group.Item basis={2} grow={1}>
                <DateField
                  {...form.registerField('endDate')}
                  toggleDatePickerAriaLabel={formatMessage(
                    'activities.paymentFlow.form.content.recurring.endDate.toggleDatePickerAriaLabel'
                  )}
                  onChange={(date) => form.setValue('endDate', date)}
                  isRequired
                  minDate={startDate ? addBusinessDays(startDate, 1) : createDate()}
                  excludeHolidays
                  value={endDate}
                  hideClear
                  labelProps={{ label: formatMessage('activities.paymentFlow.form.content.recurring.endDate.label') }}
                  selectedDateAriaLabel={formatMessage(
                    'activities.paymentFlow.form.content.recurring.endDate.selected.aria'
                  )}
                  helperTextProps={
                    calculatedNumOfOccurrences
                      ? {
                          label: formatMessage('activities.paymentFlow.form.content.recurring.endDate.helperText', {
                            numOfOccurrences: calculatedNumOfOccurrences,
                          }),
                        }
                      : undefined
                  }
                />
              </Group.Item>
            )}
            {recurringFieldsToShow.numberOfOccurrences && (
              <Group.Item basis={2} grow={1}>
                <Form.TextField
                  {...form.registerField('numOfOccurrences')}
                  isRequired
                  type="number"
                  labelProps={{
                    label: formatMessage('activities.paymentFlow.form.content.recurring.occurrences.label'),
                  }}
                  maskProps={{ mask: RECURRING_NUM_OF_OCCURRENCES_REGEX }}
                  helperTextProps={
                    calculatedLastPaymentDate
                      ? {
                          label: formatMessage('activities.paymentFlow.form.content.recurring.occurrences.helperText', {
                            lastPaymentDate: formatDate(calculatedLastPaymentDate, {
                              dateStyle: 'medium',
                            }),
                          }),
                        }
                      : undefined
                  }
                />
              </Group.Item>
            )}
            {recurringFieldsToShow.lastAmount && (
              <Group.Item basis={2} grow={1}>
                <Form.AmountField
                  allowNegativeValue={false}
                  {...form.registerField('lastAmount')}
                  labelProps={{
                    label: formatMessage('activities.paymentFlow.form.content.recurring.lastPaymentAmount.label'),
                  }}
                  placeholder={paymentAmount ? formatCurrency(Number(paymentAmount)) : undefined}
                />
              </Group.Item>
            )}
          </Group>
        )}
      </Group>
    </Group>
  );
}
