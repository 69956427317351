import {
  BillSubscription,
  DeliveryMethod,
  FxCurrency,
  InvoiceDataSourceEntityTypeEnum,
  usePaymentSettings,
  Vendor,
} from '@melio/platform-api';
import { converDateToStringRepresentation, useDateUtils } from '@melio/platform-utils';

import { DeliveryMethodSelectionActivity } from '../../delivery-methods';
import { EditableData } from '../types';

export type AddDeliveryMethodActivityProps = {
  formState: Pick<EditableData, 'amount' | 'startDate' | 'fundingSourceId' | 'deliveryMethodId'>;
  vendorId: Vendor['id'];
  invoiceNumber: BillSubscription['invoiceNumber'];
  billSubscriptionId: BillSubscription['id'];
  billCurrency: FxCurrency;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
  onDone: (deliveryMethodId: DeliveryMethod['id']) => void;
};

const DEFAULT_AMOUNT = 0;

export const AddDeliveryMethodActivity = ({
  formState,
  vendorId,
  invoiceNumber = null,
  billSubscriptionId,
  billCurrency,
  onBack,
  onClose,
  onError,
  onDone,
}: AddDeliveryMethodActivityProps) => {
  const { amount, startDate, fundingSourceId, deliveryMethodId } = formState;
  const { createDate } = useDateUtils();
  const DEFAULT_DUE_DATE = createDate();
  const amountToPay = amount || DEFAULT_AMOUNT;

  const { data: [paymentSettings] = [] } = usePaymentSettings({
    payload: [
      {
        dueDate: converDateToStringRepresentation(startDate || DEFAULT_DUE_DATE), // TODO: this conversion should be on the API level
        amountToPay,
        fundingSourceId,
        deliveryMethodId,
        vendorId,
        invoicesData: [
          {
            invoiceNumber,
            amount: amountToPay,
            sourceEntity: { id: billSubscriptionId, type: InvoiceDataSourceEntityTypeEnum.BillSubscription },
          },
        ],
      },
    ],
    params: {
      fillWithDefaults: true,
    },
  });

  return (
    <DeliveryMethodSelectionActivity
      fundingSourceId={fundingSourceId || void 0}
      vendorId={vendorId}
      onClose={onClose}
      onBack={onBack}
      onError={onError}
      onDone={(deliveryMethod: DeliveryMethod) => {
        onDone(deliveryMethod.id);
      }}
      billCurrency={billCurrency}
      origin="edit-bill-subscription"
      deliveryMethodTypeOptions={paymentSettings?.deliveryMethodTypeOptions}
    />
  );
};
