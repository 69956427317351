/* eslint-disable max-lines */
import { AccountingPlatformCategorySelectWidget } from '@melio/ap-widgets';
import {
  AmountField,
  Container,
  Control,
  Form,
  FormField,
  FormLineItems,
  Group,
  Text,
  TextField,
  useBreakpoint,
  useWatch,
} from '@melio/penny';
import { AccountingPlatform, AccountingPlatformItem, FxCurrency } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDebounceCallback } from '@melio/platform-utils';
import { useLayoutEffect, useRef } from 'react';

import { ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE } from '../../../constants';
import { AddBillV2FormValues, AddBillV2SetValue, RegisterFieldResult } from '../../../types';
import { getNewAmount, getNewQuantity, getNewUnitPrice } from '../../../utils';
import { AccountingPlatformItemSelect } from '../AccountingPlatformItemSelect';
import { DeleteLineItem } from '../DeleteLineItem';
import { DeleteLineItemMobile } from '../DeleteLineItemMobile';

type Props = {
  onClickRemoveLineItem: VoidFunction;
  index: number;
  formControl: Control<AddBillV2FormValues>;
  descriptionFieldProps: RegisterFieldResult;
  amountFieldProps: RegisterFieldResult;
  itemsPickerProps: RegisterFieldResult;
  quantityFieldProps: RegisterFieldResult;
  unitPriceFieldProps: RegisterFieldResult;
  currency?: FxCurrency;
  categoriesPickerProps: RegisterFieldResult;
  accountingPlatformCategoryLabel: string;
  activeAccountingPlatform?: AccountingPlatform;
  items?: AccountingPlatformItem[];
  hasLineItems: boolean;
  setValue: AddBillV2SetValue;
  shouldAutoFocus?: boolean;
};

export const XeroSyncedLineItem = ({
  onClickRemoveLineItem,
  index,
  items,
  formControl,
  descriptionFieldProps,
  amountFieldProps,
  itemsPickerProps,
  quantityFieldProps,
  unitPriceFieldProps,
  currency,
  categoriesPickerProps,
  accountingPlatformCategoryLabel,
  activeAccountingPlatform,
  hasLineItems,
  setValue,
  shouldAutoFocus = true,
}: Props) => {
  const naturalIndex = index + 1;
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { formatCurrency, formatMessage } = useMelioIntl();
  const [description, unitPrice, quantity, amount, externalItemId, xeroSyncedLineItems] = useWatch({
    control: formControl,
    name: [
      `xeroSyncedLineItems.${index}.description`,
      `xeroSyncedLineItems.${index}.unitPrice`,
      `xeroSyncedLineItems.${index}.quantity`,
      `xeroSyncedLineItems.${index}.amount`,
      `xeroSyncedLineItems.${index}.externalItemId`,
      'xeroSyncedLineItems',
    ],
  });
  const deleteButtonAriaLabel = formatMessage('activities.addBillV2.lineItems.xeroSynced.delete.ariaLabel', {
    index: naturalIndex,
    description: description || undefined,
    amount: amount ? formatCurrency(parseFloat(amount), currency) : undefined,
  });

  const externalItemIdRef = useRef<string>(externalItemId || '');
  const amountRef = useRef<string>(amount);
  const quantityRef = useRef<string>(quantity);
  const unitPriceRef = useRef<string>(unitPrice);

  const shouldShowAmountCurrency = currency && currency !== 'USD';
  const setFormattedValue: AddBillV2SetValue = (name, value) => {
    const formattedValue = new Number(value ?? 0).toFixed(2);

    setValue(name, formattedValue as never);
  };

  const isEqualByValue = (numericString1?: string | null, numericString2?: string | null) =>
    new Number(numericString1 ?? 0).valueOf() === new Number(numericString2 ?? 0).valueOf();

  const updateUnitPrice = useDebounceCallback(() => {
    const { unitPriceToUpdate, quantityToUpdate } = getNewUnitPrice(quantity, amount);
    if (quantityToUpdate) {
      quantityRef.current = quantityToUpdate;
      setValue(`xeroSyncedLineItems.${index}.quantity`, '1');
    }
    if (unitPriceToUpdate) {
      unitPriceRef.current = unitPriceToUpdate;
      setFormattedValue(`xeroSyncedLineItems.${index}.unitPrice`, unitPriceToUpdate);
    }
  }, ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE);

  const updateAmount = useDebounceCallback(() => {
    const amountToUpdate = getNewAmount(quantity, unitPrice);
    if (amountToUpdate) {
      amountRef.current = amountToUpdate;
      setFormattedValue(`xeroSyncedLineItems.${index}.amount`, amountToUpdate);
    }
  }, ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE);

  const updateQuantity = useDebounceCallback(() => {
    const quantityToUpdate = getNewQuantity(amount, unitPrice);
    if (quantityToUpdate) {
      quantityRef.current = quantityToUpdate;
      setValue(`xeroSyncedLineItems.${index}.quantity`, quantityToUpdate);
    }
  }, ITEM_BASED_LINE_ITEMS_CALCULATION_DEBOUNCE);

  useLayoutEffect(() => {
    if (!isEqualByValue(amount, amountRef.current)) {
      amountRef.current = amount;
      if (!amount) {
        return;
      }
      if (quantity) {
        return updateUnitPrice();
      }
      if (unitPrice && parseFloat(amount) >= parseFloat(unitPrice)) {
        return updateQuantity();
      }
    }
    if (!isEqualByValue(unitPrice, unitPriceRef.current)) {
      unitPriceRef.current = unitPrice;
      if (!unitPrice) {
        return;
      }
      if (quantity) {
        return updateAmount();
      }
      if (amount && parseFloat(amount) >= parseFloat(unitPrice)) {
        return updateQuantity();
      }
    }
    if (quantity !== quantityRef.current) {
      quantityRef.current = quantity;
      if (!quantity) {
        return;
      }
      if (unitPrice && quantity) {
        return updateAmount();
      }
      if (amount && quantity) {
        return updateUnitPrice();
      }
    }
  }, [amount, unitPrice, quantity, updateUnitPrice, updateQuantity, updateAmount]);

  useLayoutEffect(() => {
    if (!externalItemId || externalItemId === externalItemIdRef.current) {
      return;
    }
    externalItemIdRef.current = externalItemId;
    const selectedItem: AccountingPlatformItem | undefined = items?.find((item) => item.id === externalItemId);
    if (selectedItem?.purchaseCost) {
      const unitPriceForCalc = selectedItem.purchaseCost as unknown as string;
      unitPriceRef.current = unitPriceForCalc;
      setFormattedValue(`xeroSyncedLineItems.${index}.unitPrice`, unitPriceForCalc);
      const qtyForCalc = quantity || '1';
      quantityRef.current = qtyForCalc;
      setValue(`xeroSyncedLineItems.${index}.quantity`, qtyForCalc);
      const amountToUpdate = getNewAmount(qtyForCalc, unitPriceForCalc);
      if (amountToUpdate) {
        amountRef.current = amountToUpdate;
        setFormattedValue(`xeroSyncedLineItems.${index}.amount`, amountToUpdate);
      }
    }
    if (selectedItem?.description) {
      setValue(`xeroSyncedLineItems.${index}.description`, selectedItem.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalItemId]);

  const canDeleteRow = (xeroSyncedLineItems?.length || 0) > 1;

  if (isMobile) {
    return (
      <FormLineItems.MobileListItem index={index}>
        <Group variant="vertical" spacing="m">
          <AccountingPlatformItemSelect
            {...itemsPickerProps}
            placeholder={formatMessage('activities.addBillV2.lineItems.xeroSynced.itemPicker.placeholder')}
            data-testid={`add-bill-v2-xero-items-picker-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.xeroSynced.itemPicker.label') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.xeroSynced.itemPicker.ariaLabel', {
              index: naturalIndex,
            })}
            autoFocus={shouldAutoFocus && hasLineItems}
          />
          <Form.TextField
            {...descriptionFieldProps}
            data-testid={`add-bill-v2-xero-description-${index}`}
            isTruncated
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.xeroSynced.description.label') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.xeroSynced.description.ariaLabel', {
              index: naturalIndex,
            })}
          />
          <Form.TextField
            {...quantityFieldProps}
            data-testid={`add-bill-v2-xero-quantity-${index}`}
            isRequired
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.xeroSynced.quantity.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.xeroSynced.quantity.ariaLabel', {
              index: naturalIndex,
            })}
          />
          <Form.AmountField
            {...unitPriceFieldProps}
            isRequired
            currency={currency}
            decimalScale={2}
            data-testid={`add-bill-v2-xero-unitPrice-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.xeroSynced.unitPrice.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.xeroSynced.unitPrice.ariaLabel', {
              index: naturalIndex,
            })}
          />
          <AccountingPlatformCategorySelectWidget
            data-testid={`add-bill-v2-xero-account-picker-${index}`}
            activeAccountingPlatform={activeAccountingPlatform}
            labelProps={{
              label: accountingPlatformCategoryLabel,
            }}
            isRequired={false}
            {...categoriesPickerProps}
          />
          <Form.AmountField
            {...amountFieldProps}
            isRequired
            decimalScale={2}
            data-testid={`add-bill-v2-xero-line-item-amount-${index}`}
            labelProps={{ label: formatMessage('activities.addBillV2.lineItems.xeroSynced.amount.placeholder') }}
            aria-label={formatMessage('activities.addBillV2.lineItems.xeroSynced.amount.ariaLabel', {
              index: naturalIndex,
            })}
            currency={currency}
            endElement={
              shouldShowAmountCurrency ? (
                <Container paddingX="s">
                  <Text color="neutral.darker">{currency}</Text>
                </Container>
              ) : undefined
            }
          />
          {canDeleteRow && (
            <DeleteLineItemMobile
              IndexNumber={index}
              label={formatMessage('activities.addBillV2.lineItems.removeItem.label')}
              onClick={onClickRemoveLineItem}
              testId="add-bill-v2-xero-line-item-delete"
            />
          )}
        </Group>
      </FormLineItems.MobileListItem>
    );
  }

  return (
    <FormLineItems.Row>
      <FormLineItems.Cell size="xs" data-testid="add-bill-v2-xero-items-line-item-number">
        <FormField labelProps={{ label: '', isHidden: true }} render={() => <>{index + 1}</>} />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="m">
        <AccountingPlatformItemSelect
          {...itemsPickerProps}
          placeholder={formatMessage('activities.addBillV2.lineItems.xeroSynced.itemPicker.placeholder')}
          data-testid={`add-bill-v2-xero-items-picker-${index}`}
          aria-label={formatMessage('activities.addBillV2.lineItems.xeroSynced.itemPicker.label')}
          size="small"
          autoFocus={shouldAutoFocus && hasLineItems}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="m">
        <FormField
          {...descriptionFieldProps}
          data-testid={`add-bill-v2-xero-description-${index}`}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.xeroSynced.description.label'),
            isHidden: true,
          }}
          size="small"
          render={(formFieldProps) => <TextField {...formFieldProps} isTruncated />}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="s">
        <FormField
          {...quantityFieldProps}
          data-testid={`add-bill-v2-xero-quantity-${index}`}
          isRequired
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.xeroSynced.quantity.placeholder'),
            isHidden: true,
          }}
          size="small"
          render={(formFieldProps) => <TextField {...formFieldProps} />}
        />
      </FormLineItems.Cell>
      <FormLineItems.Cell size="s">
        <FormField
          {...unitPriceFieldProps}
          size="small"
          isRequired
          data-testid={`add-bill-v2-xero-unitPrice-${index}`}
          labelProps={{
            label: formatMessage('activities.addBillV2.lineItems.xeroSynced.unitPrice.placeholder'),
            isHidden: true,
          }}
          render={(formFieldProps) => <AmountField {...formFieldProps} decimalScale={2} currency={currency} />}
        />
      </FormLineItems.Cell>

      <FormLineItems.Cell size="m">
        <AccountingPlatformCategorySelectWidget
          data-testid={`add-bill-v2-xero-account-picker-${index}`}
          activeAccountingPlatform={activeAccountingPlatform}
          colSpan={3}
          isRequired={false}
          {...categoriesPickerProps}
          size="small"
        />
      </FormLineItems.Cell>

      <FormLineItems.Cell size="l" isSticky>
        <Group>
          <Group.Item grow={1}>
            <FormField
              data-testid={`add-bill-v2-xero-line-item-amount-${index}`}
              labelProps={{
                label: formatMessage('activities.addBillV2.lineItems.xeroSynced.amount.placeholder'),
                isHidden: true,
              }}
              {...amountFieldProps}
              isRequired
              size="small"
              render={(formFieldProps) => (
                <AmountField
                  {...formFieldProps}
                  currency={currency}
                  decimalScale={2}
                  endElement={
                    shouldShowAmountCurrency ? (
                      <Container paddingX="s">
                        <Text color="neutral.darker">{currency}</Text>
                      </Container>
                    ) : undefined
                  }
                />
              )}
            />
          </Group.Item>

          {canDeleteRow && (
            <Group.Item>
              <DeleteLineItem
                testId="add-bill-v2-xero-line-item-delete"
                onClick={onClickRemoveLineItem}
                IndexNumber={index}
                ariaLabel={deleteButtonAriaLabel}
              />
            </Group.Item>
          )}
        </Group>
      </FormLineItems.Cell>
    </FormLineItems.Row>
  );
};
