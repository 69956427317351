import { Group } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';

import { RefundCompletedSectionBanner } from '../completed/RefundCompleted';
import { RefundInProgressSectionBanner } from '../in-progress/RefundInProgress';
import { PaymentApprovalPendingAlert } from '../scheduled/PaymentApprovalPendingAlert';
import { PaymentScheduledActionAlert } from '../scheduled/PaymentScheduledActionAlert';
import { paymentDetailsSectionTypeFactory } from '../utils';
import { PaymentFailedSectionBanner } from './PaymentFailedSectionBanner';

type Props = { payment: PaymentFullyExpanded; approvalDecisions?: ApprovalDecision[] };
export const PaymentBannerSection = ({ payment, approvalDecisions }: Props) => {
  switch (paymentDetailsSectionTypeFactory(payment, approvalDecisions)) {
    case 'payment-scheduled':
      return payment.approvalDecisionStatus === 'pending' ? (
        <Group variant="vertical" spacing="m">
          <PaymentApprovalPendingAlert payment={payment} />

          <PaymentScheduledActionAlert payment={payment} />
        </Group>
      ) : (
        <PaymentScheduledActionAlert payment={payment} />
      );
    case 'refund-in-progress':
      return <RefundInProgressSectionBanner payment={payment} />;
    case 'refund-completed':
      return <RefundCompletedSectionBanner payment={payment} />;
    case 'payment-failed':
      return <PaymentFailedSectionBanner payment={payment} />;
    default:
      return null;
  }
};
