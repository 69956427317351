/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { object, SchemaOf, string } from 'yup';
import { Modal } from '@melio/penny';
import { Form, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';

import { useAppToast } from '@/hooks/useAppToast.hooks';
import { useVendorUpdate } from '@/hooks/vendors.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { emailIsValid } from '@/utils/email.utils';
import { useUnilateralRequestDetails } from './useUnilateralRequestDetails';

type UnilateralDetailsModalFields = {
  email: string;
};

const useSchema = () => {
  const { formatMessage } = usePlatformIntl();

  return object().shape({
    email: string()
      .required(formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.validations.empty'))
      .test(
        'validAddress',
        formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.validations.invalid'),
        emailIsValid,
      ),
  }) as SchemaOf<UnilateralDetailsModalFields>;
};

type UnilateralRequestDetailsModalProps = {
  vendor: Vendor;
  isOpen: boolean;
  origin: OriginFlow;
  onClose: () => void;
};

export const UnilateralRequestDetailsModal = ({
  vendor,
  isOpen,
  origin,
  onClose,
}: UnilateralRequestDetailsModalProps) => {
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const { successToast, errorToast } = useAppToast();

  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<UnilateralDetailsModalFields>();

  const { updateVendor } = useVendorUpdate();

  const { sendUnilateralRequest, createVirtualAccountAndSendUnilateralRequest, isSaving, vendorVirtualDeliveryMethod } =
    useUnilateralRequestDetails({ vendorId: vendor.id });

  React.useEffect(() => {
    if (isOpen) {
      track('AskVendorDeliveryDetails', 'Viewed', { Origin: origin });
    }
  }, [isOpen]);

  const showSuccessToast = () => successToast(formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.toast.success'));
  const showErrorToast = () => errorToast(formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.toast.error'));

  const handleSubmit = async (data: UnilateralDetailsModalFields) => {
    const results = await Promise.allSettled([
      updateVendor({
        vendorId: vendor.id,
        body: { contact: { email: data.email } },
      }),
      (vendorVirtualDeliveryMethod
        ? sendUnilateralRequest(vendorVirtualDeliveryMethod.id)
        : createVirtualAccountAndSendUnilateralRequest({ email: data.email })
      )
        .then(() => {
          track('AskVendorDeliveryDetails', 'SentAndClose', { Origin: origin });
          showSuccessToast();
        })
        .catch(() => showErrorToast()),
    ]);

    !results.filter(({ status }) => status === 'rejected').length && onClose();
  };

  const { formProps, registerField } = useMelioForm<UnilateralDetailsModalFields>({
    onSubmit: handleSubmit,
    onSubmissionStateChange,
    isSaving,
    schema: useSchema(),
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-testid="unilateral-request-details-modal"
      header={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.title', {
        vendorName: vendor.name || '',
      })}
      description={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.description')}
      primaryButton={{
        label: formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.submit'),
        variant: 'primary',
        ...submitButtonProps,
      }}
    >
      <Form data-component="UnilateralRequestDetailsModalForm" size="large" {...formProps}>
        <Form.TextField
          autoFocus
          {...registerField('email')}
          labelProps={{ label: formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.title') }}
          placeholder={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.modal.fields.email.placeholder')}
        />
      </Form>
    </Modal>
  );
};
