import React from 'react';
import { StatusModal, useToast } from '@melio/penny';
import { PaymentRequest, usePaymentRequest } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { usePlatformIntl } from '@/translations/Intl';

type PaymentRequestRejectModalProps = {
  paymentRequestId: PaymentRequest['id'];
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  isOpen: boolean;
};

export const PaymentRequestRejectModal = ({
  paymentRequestId,
  isOpen,
  onClose,
  onSuccess,
}: PaymentRequestRejectModalProps) => {
  const { formatMessage } = usePlatformIntl();
  const { data: paymentRequest, isLoading, cancel, isUpdating } = usePaymentRequest({ id: paymentRequestId });
  const { toast } = useToast();

  if (isLoading || !paymentRequest) {
    return <Loader isAbsoluteCenter />;
  }

  const { vendor } = paymentRequest;

  const onConfirmClick = async () => {
    try {
      await cancel();
      toast({ type: 'informative', title: formatMessage('widgets.paymentRequestDetails.rejectModal.successToast') });
      onClose();
      onSuccess?.();
    } catch (e) {
      console.error(e);
      toast({ type: 'error', title: formatMessage('widgets.paymentRequestDetails.rejectModal.failureToast') });
    }
  };

  return (
    <StatusModal
      data-testid="payment-request-reject-modal"
      isOpen={isOpen}
      variant="decline"
      header={formatMessage('widgets.paymentRequestDetails.rejectModal.header')}
      description={formatMessage('widgets.paymentRequestDetails.rejectModal.description', {
        vendorName: vendor?.name,
      })}
      onClose={onClose}
      primaryButton={{
        label: formatMessage('widgets.paymentRequestDetails.rejectModal.primaryButton'),
        variant: 'critical',
        onClick: onConfirmClick,
        isLoading: isUpdating,
      }}
    />
  );
};
