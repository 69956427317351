import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CollaboratorsActivity, ManageCollaboratorsActivity } from '@melio/ap-activities';
import { ApprovalWorkflowsSettings } from '@melio/ap-activities';
import { SubscriptionPlansSettingsScreen } from '@melio/ap-activities';
import { ApprovalWorkflowSettingsErrorState } from '@melio/ap-activities/src/components/settings/ApprovalWorkflowsSettings/ApprovalWorkflowSettingsErrorState';
import { SubscriptionPlansSettingsScreenFiserv } from '@melio/ap-activities/src/components/settings/SubscriptionPlansSettingsPageFiserv';
import { InvoiceEmailNotificationsRouter, InvoiceItemsSettingsRouter } from '@melio/ar-app';
import { PartnerGroupEnum } from '@melio/partner-tools';
import { useBreakpoint } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { useSubscriptionPermissions } from '@melio/subscriptions';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsNestedPages } from '@/consts/SettingsConsts';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useHasAccessToFirm } from '@/hooks/useHasAccessToFirm.hooks';
import { SettingsScreen } from '@/screens/settings/Settings.screen';
import { ScreensEnum } from '@/store/app/app.types';
import { AccountSoftwareSettingsWidget } from '@/widgets/settings-page/AccountSoftwareSettingsPage/AccountSoftwareSettings.widget';
import { BillingSettings } from '@/widgets/settings-page/BillingSettingsPage/BillingSettings.widget';
import { CompanySettings } from '@/widgets/settings-page/CompanySettingPage/CompanySettings.widget';
import { NotificationPreferences } from '@/widgets/settings-page/NotificationPreferencesPage/NotificationPreferences.widget';
import { PaymentsMethodPage } from '@/widgets/settings-page/PaymentMethodsSettingsPage/PaymentMethodsSettings.widget';
import { ProfileSettings } from '@/widgets/settings-page/ProfileSettingsPage/ProfileSettings.widget';
import { ReceivingMethods } from '@/widgets/settings-page/ReceivingMethodsPage/ReceivingMethods.widget';
import { Support } from '@/widgets/settings-page/SupportPage/Support.widget';

export const SettingsRoute = () => {
  useActiveScreen(ScreensEnum.settings);
  const { goToCreateApprovalWorkflow, goToEditApprovalWorkflow } = useRouter();
  const { isExtraSmallScreen } = useBreakpoint();
  const hasAccessToFirm = useHasAccessToFirm();
  const [accountManagerAccessCollaboratorsEnabled] = useFeature<boolean>(
    FeatureFlags.AccountManagerAccessCollaborators,
    false,
  );

  const { hasAccessToSubscriptionSettings } = useSubscriptionPermissions();

  const [notificationPreferencesEnabled] = useFeature<boolean>(FeatureFlags.NotificationPreferences, false);
  const [displayReceivingMethodSettingItem] = useFeature<boolean>(FeatureFlags.DisplayReceivingMethodsItem, false);
  const {
    partnerConfig: { allowEditFirstAndLastName, getSettingsConfig },
    partnerGroup,
  } = usePartnerConfig();
  const { can } = usePermissions();
  const hasCollaboratorPermissions =
    can({ subject: 'collaborator', action: 'read' }) || can({ subject: 'invitation', action: 'read' });
  const [isApprovalWorkflowsFeatureEnabled] = useFeature(FeatureFlags.ApprovalWorkflows, false, {
    shouldTrack: true,
  });

  const isApprovalWorkflowsEnabled =
    isApprovalWorkflowsFeatureEnabled && can({ action: 'read', subject: 'approvalWorkflow' });

  const [isPlatformUserManagementAdjustmentsFiservEnabled] = useFeature(
    FeatureFlags.PlatformUserManagementAdjustmentsFiserv,
    false,
  );
  const [isArEnabled] = useFeature(FeatureFlags.ARDashboard, false); //todo - in fiserv it should be per user according to the user plan
  const [newSettings] = useFeature<boolean>(FeatureFlags.NewSettings, false);

  const settingsConfig = useMemo(() => getSettingsConfig(isArEnabled), [getSettingsConfig, isArEnabled]);

  const settingFirstCard = useMemo(() => {
    const firstSettingCard: SettingsCardIdEnum = settingsConfig[0]?.items[0];
    switch (firstSettingCard) {
      case SettingsCardIdEnum.ProfileSettings:
        return SettingsNestedPages.PROFILE;
      case SettingsCardIdEnum.CompanySettings:
        return SettingsNestedPages.COMPANY;
      case SettingsCardIdEnum.NotificationPreferences:
        return SettingsNestedPages.NOTIFICATION_PREFERENCES;
      case SettingsCardIdEnum.WorkflowsSetting:
        return SettingsNestedPages.WORKFLOWS;
      case SettingsCardIdEnum.CollaboratorsSetting:
        return SettingsNestedPages.COLLABORATORS;
      case SettingsCardIdEnum.AccountingSoftwareSync:
        return SettingsNestedPages.ACCOUNT_SOFTWARE;
      case SettingsCardIdEnum.PaymentMethodsSetting:
        return SettingsNestedPages.PAYMENT_METHODS;
      case SettingsCardIdEnum.BillingSetting:
        return SettingsNestedPages.BILLING;
      case SettingsCardIdEnum.SubscriptionPlansSettings:
        return SettingsNestedPages.SUBSCRIPTION_PLANS;
      case SettingsCardIdEnum.SupportSetting:
        return SettingsNestedPages.SUPPORT;
      case SettingsCardIdEnum.ReceivingMethodsSettings:
        return SettingsNestedPages.RECEIVING_METHODS;
      case SettingsCardIdEnum.InvoiceItems:
        return SettingsNestedPages.INVOICE_ITEMS;
      case SettingsCardIdEnum.InvoiceEmailNotifications:
        return SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS;
    }
  }, [settingsConfig]);

  const settingsVisibility: { [key in SettingsCardIdEnum]?: boolean } = useMemo(() => {
    return settingsConfig
      .map(({ items }) => items)
      .flat()
      .reduce((acc, type) => ({ ...acc, [type]: true }), {});
  }, [settingsConfig]);

  const isProfileVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.ProfileSettings] : true),
    [settingsVisibility, newSettings],
  );
  const isCompanyVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.CompanySettings] : true),
    [settingsVisibility, newSettings],
  );
  const isNotificationVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.NotificationPreferences] : true),
    [settingsVisibility, newSettings],
  );
  const isWorkflowsVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.WorkflowsSetting] : true),
    [settingsVisibility, newSettings],
  );
  const isCollaboratorsVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.CollaboratorsSetting] : true),
    [settingsVisibility, newSettings],
  );
  const isAccountingSoftwareVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.AccountingSoftwareSync] : true),
    [settingsVisibility, newSettings],
  );
  const isPaymentMethodsVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.PaymentMethodsSetting] : true),
    [settingsVisibility, newSettings],
  );
  const isBillingVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.BillingSetting] : true),
    [settingsVisibility, newSettings],
  );
  const isSubscriptionsVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.SubscriptionPlansSettings] : true),
    [settingsVisibility, newSettings],
  );
  const isSupportVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.SupportSetting] : true),
    [settingsVisibility, newSettings],
  );
  const isReceivingMethodsVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.ReceivingMethodsSettings] : true),
    [settingsVisibility, newSettings],
  );
  const isInvoiceItemsVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.InvoiceItems] : true),
    [settingsVisibility, newSettings],
  );
  const isInvoiceEmailNotificationsVisible = useMemo(
    () => (newSettings ? settingsVisibility[SettingsCardIdEnum.InvoiceEmailNotifications] : true),
    [settingsVisibility, newSettings],
  );

  return (
    <Routes>
      <Route path={'/'} element={<SettingsScreen />}>
        {!isExtraSmallScreen && <Route index element={<Navigate to={`/settings/${settingFirstCard}`} />} />}
        {isProfileVisible ? (
          <Route path={'/profile'} element={<ProfileSettings allowEdit={allowEditFirstAndLastName} />} />
        ) : null}
        {isCompanyVisible && hasAccessToFirm ? <Route path={'/company'} element={<CompanySettings />} /> : null}
        {isNotificationVisible && notificationPreferencesEnabled && hasAccessToFirm && (
          <Route path={'/notification-preferences'} element={<NotificationPreferences />} />
        )}
        {isWorkflowsVisible && isApprovalWorkflowsEnabled && hasAccessToFirm && (
          <Route
            path={'/approval-workflows'}
            element={
              <ErrorBoundary fallback={<ApprovalWorkflowSettingsErrorState />}>
                <ApprovalWorkflowsSettings
                  onAction={(workflow) => {
                    if (workflow.action === 'add') {
                      goToCreateApprovalWorkflow();
                    } else if (workflow.action === 'edit') {
                      goToEditApprovalWorkflow(workflow.meta.id);
                    }
                  }}
                />
              </ErrorBoundary>
            }
          />
        )}
        {isCollaboratorsVisible &&
          accountManagerAccessCollaboratorsEnabled &&
          hasCollaboratorPermissions &&
          hasAccessToFirm && (
            <Route
              path={'/collaborators/*'}
              element={
                isPlatformUserManagementAdjustmentsFiservEnabled ? (
                  <CollaboratorsActivity />
                ) : (
                  <ManageCollaboratorsActivity />
                )
              }
            />
          )}
        {isAccountingSoftwareVisible && hasAccessToFirm && (
          <Route path={'/accounting-software'} element={<AccountSoftwareSettingsWidget />} />
        )}
        {isPaymentMethodsVisible && hasAccessToFirm && (
          <Route path={'/payment-methods'} element={<PaymentsMethodPage shouldRenderTitle={false} />} />
        )}
        {isPaymentMethodsVisible && hasAccessToFirm && (
          <Route path={'/payment-methods/:fundingSourceId/verify'} element={<PaymentsMethodPage />} />
        )}
        {isBillingVisible && hasAccessToFirm && <Route path={'/billing'} element={<BillingSettings />} />}
        {isSubscriptionsVisible && hasAccessToSubscriptionSettings() && hasAccessToFirm && (
          <Route
            path={'/subscription-plans'}
            element={
              partnerGroup === PartnerGroupEnum.FISERV ? (
                <SubscriptionPlansSettingsScreenFiserv />
              ) : (
                <SubscriptionPlansSettingsScreen />
              )
            }
          />
        )}
        {isSupportVisible ? <Route path={'/support'} element={<Support />} /> : null}
        {isReceivingMethodsVisible && displayReceivingMethodSettingItem && hasAccessToFirm ? (
          <Route path={'/receiving-methods'} element={<ReceivingMethods />} />
        ) : null}
        {isInvoiceItemsVisible ? (
          <Route path={`/${SettingsNestedPages.INVOICE_ITEMS}/*`} element={<InvoiceItemsSettingsRouter />} />
        ) : null}
        {isInvoiceEmailNotificationsVisible ? (
          <Route
            path={`/${SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS}/*`}
            element={<InvoiceEmailNotificationsRouter />}
          />
        ) : null}
      </Route>
    </Routes>
  );
};
