import { Flex } from '@chakra-ui/react';
import { Button, Container, Group, IconButton, Illustration, Layout, Text, useTheme } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, useRedirects, useSubscription } from '../../../hooks';

export const SubscriptionSuccessActivity = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const theme = useTheme();
  const subscription = useSubscription();
  const { planName = '' } = usePlanInfo(subscription?.planId);
  const { goToReturnUrlOrDashboard } = useRedirects();
  setAnalyticsProperties({
    PageName: `welcome-${planName}`,
    Flow: 'subscription',
    Intent: 'finish-subscription',
  });
  const { track } = useAnalytics();

  useAnalyticsView('Dashboard');
  const onClose = (closeType: string) => {
    track('Dashboard', 'Click', {
      Intent: closeType,
      Cta: closeType,
    });
    goToReturnUrlOrDashboard();
  };

  return (
    <Layout
      variant="6Columns"
      data-testid="subscription-success-page-activity"
      header={{
        content: (
          <Flex
            height="72px"
            flexDir="row"
            width="full"
            justifyContent="flex-end"
            alignItems="center"
            px="m"
            borderBottom={`1px solid ${theme.colors.neutral.light}`}
          >
            <IconButton
              aria-label="close"
              icon="close"
              variant="naked"
              size="medium"
              onClick={() => onClose('close')}
            />
          </Flex>
        ),
      }}
    >
      <Container paddingX="xxs" paddingY="xxs" height="full" textAlign="center">
        <Group variant="vertical" spacing="s" justifyContent="center" alignItems="center" height="full">
          <Group variant="horizontal">
            <Illustration size="large" type="celebration" />
          </Group>

          <Group variant="vertical" spacing="xxs">
            <Text textStyle="heading2Semi">{formatMessage('activities.subscription.welcome.title', { planName })}</Text>
            <Text textStyle="body2" color="neutral.darkest">
              {formatMessage('activities.subscription.welcome.description', { planName })}
            </Text>
          </Group>

          <Container paddingTop="xs">
            <Button
              variant="primary"
              label={formatMessage('activities.subscription.welcome.goToDashboard')}
              onClick={() => onClose('go-to-dashboard')}
              size="large"
              data-testid="subscription-success-page-activity-go-to-dashboard-button"
            />
          </Container>
        </Group>
      </Container>
    </Layout>
  );
});
