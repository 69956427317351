import { Collaborator, FundingSource } from '@melio/platform-api';
import { isEqual } from 'lodash';

import { FormFields } from '../types';

type Props = {
  actor?: Collaborator;
  collaborator?: Collaborator;
  fundingSources?: FundingSource[];
  paymentApprovalLimit?: number | null;
};
export const useUnsavedChanges = (props: Props) => {
  const isCollaboratorDataChanged = (formValues?: FormFields) =>
    formValues?.permissionLevel !== props.collaborator?.permissionLevel ||
    formValues?.roleUniqueName !== props.collaborator?.roleUniqueName;

  const isCollaboratorEntitlementChanged = (formValues?: FormFields) =>
    !isEqual(
      formValues?.entitlementsFundingSourceIds.sort(),
      props.actor?.id === props.collaborator?.id
        ? props.fundingSources?.map(({ id }) => id)?.sort() || []
        : props.collaborator?.fundingSourceEntitlements?.fundingSourceIds?.sort() || []
    ) || formValues?.entitlementsFundingSourceType !== props.collaborator?.fundingSourceEntitlements?.entitlementType;

  const isPaymentApprovalLimitChanged = (formValues?: FormFields) =>
    props.paymentApprovalLimit !== undefined ? formValues?.paymentApprovalLimit != props.paymentApprovalLimit : false;

  const hasUnsavedChanges = (formValues?: FormFields) =>
    isCollaboratorDataChanged(formValues) ||
    isCollaboratorEntitlementChanged(formValues) ||
    isPaymentApprovalLimitChanged(formValues);

  return {
    isCollaboratorDataChanged,
    isCollaboratorEntitlementChanged,
    isPaymentApprovalLimitChanged,
    hasUnsavedChanges,
  };
};
