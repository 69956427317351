import { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import _compact from 'lodash/compact';
import { useRecoilValue } from 'recoil';
import { PageTitle } from '@melio/ap-activities/src/components/PageTitle/PageTitle';
import { Container, Divider, Group, Icon, IconButton, Text, useBreakpoint, useTheme } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useSubscriptionPermissions } from '@melio/subscriptions';

import { SettingsCardIdEnum, SettingsCardProps } from '@/cl/components/SettingsCard/SettingsCard.component';
import {
  AccountingSoftwareSyncIcon,
  BillingIcon,
  CompanyIcon,
  InvoiceEmailNotificationsIcon,
  InvoiceItemsIcon,
  PaymentMethodsIcon,
  ProfileIcon,
  ReceivingMethodsIcon,
  SupportChatIcon,
} from '@/cl/icons/system';
import { ScreenSplitLayout } from '@/cl/layouts/screen-split-layout/screenSplitLayout.component';
import { SettingsNestedPages } from '@/consts/SettingsConsts';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useHasAccessToFirm } from '@/hooks/useHasAccessToFirm.hooks';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useIsOrganizationInMsnOnly } from '@/hooks/useIsOrganizationInMsnOnly.hooks';
import { SettingsGroupEnum } from '@/partnersConfig.types';
import { ScreensEnum } from '@/store/app/app.types';
import {
  settingsSelectedSettingsPageSelector,
  settingsState,
  useSettingsSelectedSettingsPage,
} from '@/store/Settings/Settings.model';
import { usePlatformIntl } from '@/translations/Intl';
import { useShouldShowDeliveryMethods } from '@/utils/receivingMethods.utils';
import { SettingsGroupWidget } from '@/widgets/settings-page/SettingsHomePage/SettingsGroup.widget';
import { SettingsProvider } from './SettingsProvider';

const SettingsLayoutRoute = () => {
  const { track } = useAnalytics();
  const [accountManagerAccessCollaboratorsEnabled] = useFeature<boolean>(
    FeatureFlags.AccountManagerAccessCollaborators,
    false,
  );
  const [newSettings] = useFeature<boolean>(FeatureFlags.NewSettings, false);
  const [notificationPreferencesEnabled] = useFeature<boolean>(FeatureFlags.NotificationPreferences, true);
  const [displayReceivingMethodSettingItem] = useFeature<boolean>(FeatureFlags.DisplayReceivingMethodsItem, false);
  const [isApprovalWorkflowsFeatureEnabled] = useFeature(FeatureFlags.ApprovalWorkflows, false, {
    shouldTrack: true,
  });
  const {
    settings: { isPaymentApprovalLimitEnabled },
  } = useConfig();
  const hasAccessToFirm = useHasAccessToFirm();

  const { can } = usePermissions();

  const { hasAccessToSubscriptionSettings } = useSubscriptionPermissions();

  useActiveScreen(ScreensEnum.settings);
  const { formatMessage } = usePlatformIntl();
  const {
    goToSettingsProfile,
    goToSettingsCompany,
    goToSettingsNotificationPreferences,
    goToSettingsAccountSoftware,
    goToSettingsPaymentMethods,
    goToSettingsBilling,
    goToSettingsSupport,
    goToSettingsCollaborators,
    goToReceivingMethods,
    goToInvoiceItems,
    goToSettingsWorkflows,
    goToSettingsSubscriptionPlans,
    goToInvoiceEmailNotifications,
  } = useRouter();

  const selectedSettingsPage = useRecoilValue(settingsSelectedSettingsPageSelector);

  const isPureMSNOrganization = useShouldShowDeliveryMethods();

  const isShowReceivingMethodsItem = useIsOrganizationInMsnOnly();

  const approvalWorkflowsEnabled =
    isApprovalWorkflowsFeatureEnabled && can({ action: 'read', subject: 'approvalWorkflow' });

  const [isArEnabled] = useFeature(FeatureFlags.ARDashboard, false); //todo - in fiserv it should be per user according to the user plan
  const { isExtraSmallScreen } = useBreakpoint();

  // TODO remove when development is done
  const isInvoiceEmailNotificationsEnabled =
    useFeature(FeatureFlags.InvoiceEmailNotificationSettings, false) && isArEnabled;

  const {
    partnerConfig: { getSettingsConfig },
  } = usePartnerConfig();

  const settingGroupTypeToTitle: Record<SettingsGroupEnum, string> = useMemo(() => {
    return {
      [SettingsGroupEnum.COMPANY]: formatMessage('app.settings.companySection.title'),
      [SettingsGroupEnum.PAYMENTS]: formatMessage('app.settings.BillingSection.title'),
      [SettingsGroupEnum.AR]: formatMessage('app.settings.arSection.title'),
      [SettingsGroupEnum.SUPPORT]: formatMessage('app.settings.SupportSection.title'),
    };
  }, [formatMessage]);

  const getSettingCardProps = useCallback(
    (settingCardType: SettingsCardIdEnum) => {
      switch (settingCardType) {
        case SettingsCardIdEnum.ProfileSettings:
          return {
            id: SettingsCardIdEnum.ProfileSettings,
            icon: <ProfileIcon aria-hidden="true" />,
            content: formatMessage('app.settings.companySection.cards.profile.title'),
            onClick: goToSettingsProfile,
            isSelected: selectedSettingsPage === SettingsNestedPages.PROFILE,
            cardName: 'profile',
          };
        case SettingsCardIdEnum.CompanySettings:
          return hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.CompanySettings,
                icon: <CompanyIcon aria-hidden="true" />,
                content: formatMessage('app.settings.companySection.cards.company.title'),
                onClick: goToSettingsCompany,
                isSelected: selectedSettingsPage === SettingsNestedPages.COMPANY,
                cardName: 'company',
              }
            : null;
        case SettingsCardIdEnum.CollaboratorsSetting:
          return accountManagerAccessCollaboratorsEnabled &&
            hasAccessToFirm &&
            (can({ subject: 'collaborator', action: 'read' }) || can({ subject: 'invitation', action: 'read' }))
            ? {
                id: SettingsCardIdEnum.CollaboratorsSetting,
                icon: <Icon type="user-add" aria-hidden="true" />,
                content: formatMessage('app.settings.companySection.cards.collaborators.title'),
                onClick: goToSettingsCollaborators,
                isSelected: selectedSettingsPage === SettingsNestedPages.COLLABORATORS,
                cardName: 'collaborators',
              }
            : null;
        case SettingsCardIdEnum.WorkflowsSetting:
          return approvalWorkflowsEnabled && hasAccessToFirm && !isPaymentApprovalLimitEnabled
            ? {
                id: SettingsCardIdEnum.WorkflowsSetting,
                icon: <Icon type="flow" aria-hidden="true" />,
                content: formatMessage('app.settings.companySection.cards.workflows.title'),
                onClick: goToSettingsWorkflows,
                isSelected: selectedSettingsPage === SettingsNestedPages.WORKFLOWS,
                cardName: 'approval-workflows',
              }
            : null;
        case SettingsCardIdEnum.NotificationPreferences:
          return notificationPreferencesEnabled && hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.NotificationPreferences,
                icon: <Icon type="notification" aria-hidden="true" />,
                content: formatMessage('app.settings.companySection.cards.notificationPreferences.title'),
                onClick: goToSettingsNotificationPreferences,
                isSelected: selectedSettingsPage === SettingsNestedPages.NOTIFICATION_PREFERENCES,
                cardName: 'notification-preferences',
              }
            : null;
        case SettingsCardIdEnum.AccountingSoftwareSync:
          return hasAccessToFirm &&
            (can({ subject: 'accountingPlatform:sync', action: 'create' }) ||
              can({ subject: 'accountingPlatform', action: 'delete' }))
            ? {
                id: SettingsCardIdEnum.AccountingSoftwareSync,
                icon: <AccountingSoftwareSyncIcon aria-hidden="true" />,
                content: formatMessage('app.settings.companySection.cards.accounting.title'),
                onClick: goToSettingsAccountSoftware,
                isSelected: selectedSettingsPage === SettingsNestedPages.ACCOUNT_SOFTWARE,
                cardName: 'accounting-software',
              }
            : null;
        case SettingsCardIdEnum.PaymentMethodsSetting:
          return hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.PaymentMethodsSetting,
                icon: <PaymentMethodsIcon aria-hidden="true" />,
                content: formatMessage('app.settings.BillingSection.cards.methods.title'),
                onClick: goToSettingsPaymentMethods,
                isSelected: selectedSettingsPage === SettingsNestedPages.PAYMENT_METHODS,
                cardName: 'payment-methods',
              }
            : null;
        case SettingsCardIdEnum.ReceivingMethodsSettings:
          return hasAccessToFirm &&
            (isShowReceivingMethodsItem || isArEnabled || isPureMSNOrganization) &&
            displayReceivingMethodSettingItem
            ? {
                id: SettingsCardIdEnum.ReceivingMethodsSettings,
                icon: <ReceivingMethodsIcon aria-hidden="true" />,
                content: formatMessage('app.settings.BillingSection.cards.receivingMethods.title'),
                onClick: goToReceivingMethods,
                isSelected: selectedSettingsPage === SettingsNestedPages.RECEIVING_METHODS,
                cardName: 'receiving-methods',
              }
            : null;
        case SettingsCardIdEnum.InvoiceItems:
          return {
            id: SettingsCardIdEnum.InvoiceItems,
            icon: <InvoiceItemsIcon aria-hidden="true" />,
            content: formatMessage('app.settings.BillingSection.cards.invoiceItems.title'),
            onClick: goToInvoiceItems,
            isSelected: selectedSettingsPage === SettingsNestedPages.INVOICE_ITEMS,
            cardName: 'invoice-items',
          };
        case SettingsCardIdEnum.InvoiceEmailNotifications:
          return isInvoiceEmailNotificationsEnabled
            ? {
                id: SettingsCardIdEnum.InvoiceEmailNotifications,
                icon: <InvoiceEmailNotificationsIcon aria-hidden="true" />,
                content: formatMessage('app.settings.BillingSection.cards.invoiceEmailNotifications.tab.title'),
                onClick: goToInvoiceEmailNotifications,
                isSelected: selectedSettingsPage === SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS,
                cardName: SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS,
              }
            : null;
        case SettingsCardIdEnum.SubscriptionPlansSettings:
          return hasAccessToSubscriptionSettings()
            ? {
                id: SettingsCardIdEnum.SubscriptionPlansSettings,
                icon: <ReceivingMethodsIcon aria-hidden="true" />,
                content: formatMessage(`app.settings.BillingSection.cards.subscriptionPlans.title`),
                onClick: goToSettingsSubscriptionPlans,
                isSelected: selectedSettingsPage === SettingsNestedPages.SUBSCRIPTION_PLANS,
                cardName: 'subscription-plans',
              }
            : null;
        case SettingsCardIdEnum.BillingSetting:
          return hasAccessToFirm
            ? {
                id: SettingsCardIdEnum.BillingSetting,
                icon: <BillingIcon aria-hidden="true" />,
                content: formatMessage('app.settings.BillingSection.cards.billing.title'),
                onClick: goToSettingsBilling,
                isSelected: selectedSettingsPage === SettingsNestedPages.BILLING,
                cardName: 'billing',
              }
            : null;
        case SettingsCardIdEnum.SupportSetting:
          return {
            id: SettingsCardIdEnum.SupportSetting,
            icon: <SupportChatIcon aria-hidden="true" />,
            content: formatMessage('app.settings.SupportSection.cards.support.title'),
            onClick: goToSettingsSupport,
            isSelected: selectedSettingsPage === SettingsNestedPages.SUPPORT,
            cardName: 'support',
          };
      }
    },
    [
      formatMessage,
      goToSettingsProfile,
      selectedSettingsPage,
      hasAccessToFirm,
      goToSettingsCompany,
      accountManagerAccessCollaboratorsEnabled,
      can,
      goToSettingsCollaborators,
      approvalWorkflowsEnabled,
      isPaymentApprovalLimitEnabled,
      goToSettingsWorkflows,
      notificationPreferencesEnabled,
      goToSettingsNotificationPreferences,
      goToSettingsAccountSoftware,
      goToSettingsPaymentMethods,
      isShowReceivingMethodsItem,
      isPureMSNOrganization,
      isArEnabled,
      displayReceivingMethodSettingItem,
      goToReceivingMethods,
      goToInvoiceItems,
      isInvoiceEmailNotificationsEnabled,
      goToInvoiceEmailNotifications,
      hasAccessToSubscriptionSettings,
      goToSettingsSubscriptionPlans,
      goToSettingsBilling,
      goToSettingsSupport,
    ],
  );

  const settingsConfig = useMemo(() => getSettingsConfig(isArEnabled), [getSettingsConfig, isArEnabled]);

  const newGroups = useMemo(() => {
    return _compact(
      settingsConfig.map((settingsGroup) => {
        const { type, items } = settingsGroup;

        const title = settingsConfig.length > 1 ? settingGroupTypeToTitle[type] : undefined;
        const groupItems = _compact(items.map((item) => getSettingCardProps(item)));

        if (groupItems.length === 0) return null;

        return {
          title,
          type,
          settings: groupItems,
        };
      }),
    );
  }, [settingsConfig, settingGroupTypeToTitle, getSettingCardProps]);

  const groupsSettings = newGroups.flatMap((x) => x.settings);
  const selectedSettingCard = groupsSettings.find((setting) => setting.isSelected) || groupsSettings[0];
  const documentTitle = formatMessage('app.document.title.settings', { cardName: selectedSettingCard.content });

  useSetDocumentTitle(documentTitle);

  const CompanySettings: Array<SettingsCardProps> = _compact([
    {
      id: SettingsCardIdEnum.ProfileSettings,
      icon: <ProfileIcon aria-hidden="true" />,
      content: formatMessage('app.settings.companySection.cards.profile.title'),
      onClick: goToSettingsProfile,
      isSelected: selectedSettingsPage === SettingsNestedPages.PROFILE,
      cardName: 'profile',
    },
    hasAccessToFirm
      ? {
          id: SettingsCardIdEnum.CompanySettings,
          icon: <CompanyIcon aria-hidden="true" />,
          content: formatMessage('app.settings.companySection.cards.company.title'),
          onClick: goToSettingsCompany,
          isSelected: selectedSettingsPage === SettingsNestedPages.COMPANY,
          cardName: 'company',
        }
      : null,
    accountManagerAccessCollaboratorsEnabled &&
    hasAccessToFirm &&
    (can({ subject: 'collaborator', action: 'read' }) || can({ subject: 'invitation', action: 'read' }))
      ? {
          id: SettingsCardIdEnum.CollaboratorsSetting,
          icon: <Icon type="user-add" aria-hidden="true" />,
          content: formatMessage('app.settings.companySection.cards.collaborators.title'),
          onClick: goToSettingsCollaborators,
          isSelected: selectedSettingsPage === SettingsNestedPages.COLLABORATORS,
          cardName: 'collaborators',
        }
      : null,
    approvalWorkflowsEnabled && hasAccessToFirm && !isPaymentApprovalLimitEnabled
      ? {
          id: SettingsCardIdEnum.WorkflowsSetting,
          icon: <Icon type="flow" aria-hidden="true" />,
          content: formatMessage('app.settings.companySection.cards.workflows.title'),
          onClick: goToSettingsWorkflows,
          isSelected: selectedSettingsPage === SettingsNestedPages.WORKFLOWS,
          cardName: 'approval-workflows',
        }
      : null,
    notificationPreferencesEnabled && hasAccessToFirm
      ? {
          id: SettingsCardIdEnum.NotificationPreferences,
          icon: <Icon type="notification" aria-hidden="true" />,
          content: formatMessage('app.settings.companySection.cards.notificationPreferences.title'),
          onClick: goToSettingsNotificationPreferences,
          isSelected: selectedSettingsPage === SettingsNestedPages.NOTIFICATION_PREFERENCES,
          cardName: 'notification-preferences',
        }
      : null,
    hasAccessToFirm &&
    (can({ subject: 'accountingPlatform:sync', action: 'create' }) ||
      can({ subject: 'accountingPlatform', action: 'delete' }))
      ? {
          id: SettingsCardIdEnum.AccountingSoftwareSync,
          icon: <AccountingSoftwareSyncIcon aria-hidden="true" />,
          content: formatMessage('app.settings.companySection.cards.accounting.title'),
          onClick: goToSettingsAccountSoftware,
          isSelected: selectedSettingsPage === SettingsNestedPages.ACCOUNT_SOFTWARE,
          cardName: 'accounting-software',
        }
      : null,
  ]);
  const PaymentSettings: Array<SettingsCardProps> = _compact([
    {
      id: SettingsCardIdEnum.PaymentMethodsSetting,
      icon: <PaymentMethodsIcon aria-hidden="true" />,
      content: formatMessage('app.settings.BillingSection.cards.methods.title'),
      onClick: goToSettingsPaymentMethods,
      isSelected: selectedSettingsPage === SettingsNestedPages.PAYMENT_METHODS,
      cardName: 'payment-methods',
    },
    (isShowReceivingMethodsItem || isPureMSNOrganization) && displayReceivingMethodSettingItem
      ? {
          id: SettingsCardIdEnum.ReceivingMethodsSettings,
          icon: <ReceivingMethodsIcon aria-hidden="true" />,
          content: formatMessage('app.settings.BillingSection.cards.receivingMethods.title'),
          onClick: goToReceivingMethods,
          isSelected: selectedSettingsPage === SettingsNestedPages.RECEIVING_METHODS,
          cardName: 'receiving-methods',
        }
      : null,
    isArEnabled
      ? {
          id: SettingsCardIdEnum.InvoiceItems,
          icon: <InvoiceItemsIcon aria-hidden="true" />,
          content: formatMessage('app.settings.BillingSection.cards.invoiceItems.title'),
          onClick: goToInvoiceItems,
          isSelected: selectedSettingsPage === SettingsNestedPages.INVOICE_ITEMS,
          cardName: 'invoice-items',
        }
      : null,
    isInvoiceEmailNotificationsEnabled
      ? {
          id: SettingsCardIdEnum.InvoiceEmailNotifications,
          icon: <InvoiceEmailNotificationsIcon aria-hidden="true" />,
          content: formatMessage('app.settings.BillingSection.cards.invoiceEmailNotifications.tab.title'),
          onClick: goToInvoiceEmailNotifications,
          isSelected: selectedSettingsPage === SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS,
          cardName: SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS,
        }
      : null,
    hasAccessToSubscriptionSettings()
      ? {
          id: SettingsCardIdEnum.SubscriptionPlansSettings,
          icon: <ReceivingMethodsIcon aria-hidden="true" />,
          content: formatMessage(`app.settings.BillingSection.cards.subscriptionPlans.title`),
          onClick: () => {
            track('Settings', 'Click', {
              PageName: 'settings',
              Flow: 'settings',
              Intent: 'move-to-plans-screen',
              Cta: 'plans',
            });
            goToSettingsSubscriptionPlans();
          },
          isSelected: selectedSettingsPage === SettingsNestedPages.SUBSCRIPTION_PLANS,
          cardName: 'subscription-plans',
        }
      : null,
    {
      id: SettingsCardIdEnum.BillingSetting,
      icon: <BillingIcon aria-hidden="true" />,
      content: formatMessage('app.settings.BillingSection.cards.billing.title'),
      onClick: goToSettingsBilling,
      isSelected: selectedSettingsPage === SettingsNestedPages.BILLING,
      cardName: 'billing',
    },
  ]);
  const SupportSettings: Array<SettingsCardProps> = [
    {
      id: SettingsCardIdEnum.SupportSetting,
      icon: <SupportChatIcon aria-hidden="true" />,
      content: formatMessage('app.settings.SupportSection.cards.support.title'),
      onClick: goToSettingsSupport,
      isSelected: selectedSettingsPage === SettingsNestedPages.SUPPORT,
      cardName: 'support',
    },
  ];

  const groups = _compact([
    { title: formatMessage('app.settings.companySection.title'), settings: CompanySettings },
    hasAccessToFirm ? { title: formatMessage('app.settings.BillingSection.title'), settings: PaymentSettings } : null,
    { title: formatMessage('app.settings.SupportSection.title'), settings: SupportSettings },
  ]);

  const settingsGroups = newSettings ? newGroups : groups;

  return (
    <Container overflow="initial" paddingTop={!isExtraSmallScreen && settingsGroups.length === 1 ? 'xxs' : 'none'}>
      <Group variant="vertical" as="nav">
        {settingsGroups.map((group: { title?: string; settings: SettingsCardProps[]; type?: SettingsGroupEnum }) => (
          <SettingsGroupWidget key={group.title} title={group.title} settings={group.settings} type={group.type} />
        ))}
      </Group>
    </Container>
  );
};

export const SettingsScreen = () => {
  const location = useLocation();
  const { isExtraSmallScreen } = useBreakpoint();
  const selectedPage = useRecoilValue(settingsState);
  const setSelectedSettingsPage = useSettingsSelectedSettingsPage();
  const { isLoading } = useAccount({ id: 'me' });
  const { formatMessage } = usePlatformIntl();
  const {
    partnerConfig: { allowEditFirstAndLastName },
  } = usePartnerConfig();
  const isMobile = useIsMobile();
  const { goToSettings } = useRouter();
  const {
    settings: { isEmbeddedExperience },
  } = useConfig();
  const { goToNewDashboardTab } = useRouter();
  const currentTheme = useTheme();

  const breakpointValues = useBreakpointValue({
    xs: { containerPaddingX: '0', containerPaddingY: '0' },
    s: { containerPaddingX: '20', containerPaddingY: '16' },
    l: { containerPaddingX: '129', containerPaddingY: '32' },
  });

  useEffect(() => {
    const routeFound = Object.values(SettingsNestedPages).some((page) => {
      if (new RegExp(`^/settings/${page}(/.*)?$`).test(location.pathname)) {
        setSelectedSettingsPage(page);
        return true;
      }
    });
    if (!routeFound) {
      if (isExtraSmallScreen) setSelectedSettingsPage(null);
      else setSelectedSettingsPage(SettingsNestedPages.PROFILE);
    }
  }, [location.pathname, isExtraSmallScreen, setSelectedSettingsPage]);

  const selectedPageToTitles = useMemo<Record<SettingsNestedPages, { title: string; subTitle?: string }>>(
    () => ({
      [SettingsNestedPages.COMPANY]: {
        title: formatMessage('app.settings.companySection.cards.company.title'),
        subTitle: formatMessage('app.settings.companySection.cards.company.description'),
      },
      [SettingsNestedPages.COLLABORATORS]: {
        title: formatMessage('app.settings.companySection.cards.collaborators.title'),
        subTitle: formatMessage('app.settings.companySection.cards.collaborators.description'),
      },
      [SettingsNestedPages.ACCOUNT_SOFTWARE]: {
        title: formatMessage('app.settings.companySection.cards.accounting.title'),
      },
      [SettingsNestedPages.PAYMENT_METHODS]: {
        title: formatMessage('app.settings.BillingSection.cards.methods.title'),
        subTitle: formatMessage('app.settings.BillingSection.cards.methods.description'),
      },
      [SettingsNestedPages.BILLING]: {
        title: formatMessage('app.settings.BillingSection.cards.billing.title'),
        subTitle: formatMessage('app.settings.BillingSection.cards.billing.description'),
      },
      [SettingsNestedPages.SUPPORT]: {
        title: formatMessage('app.settings.SupportSection.cards.support.title'),
        subTitle: formatMessage('app.settings.SupportSection.cards.support.description'),
      },
      [SettingsNestedPages.NOTIFICATION_PREFERENCES]: {
        title: formatMessage('app.settings.companySection.cards.notificationPreferences.title'),
        subTitle: formatMessage('app.settings.companySection.cards.notificationPreferences.description'),
      },
      [SettingsNestedPages.PROFILE]: {
        title: formatMessage('app.settings.companySection.cards.profile.title'),
        subTitle: formatMessage(
          `app.settings.companySection.cards.profile.description${allowEditFirstAndLastName ? '' : '.uneditable'}`,
        ),
      },
      [SettingsNestedPages.RECEIVING_METHODS]: {
        title: formatMessage('app.settings.BillingSection.cards.receivingMethods.title'),
        subTitle: formatMessage('app.settings.BillingSection.cards.receivingMethods.description'),
      },
      [SettingsNestedPages.WORKFLOWS]: {
        title: formatMessage('app.settings.companySection.cards.workflows.title'),
        subTitle: formatMessage('app.settings.companySection.cards.workflows.description'),
      },
      [SettingsNestedPages.SUBSCRIPTION_PLANS]: {
        title: formatMessage(`app.settings.BillingSection.cards.subscriptionPlans.title`),
        subTitle: formatMessage(`app.settings.BillingSection.cards.subscriptionPlans.description`),
      },
      [SettingsNestedPages.INVOICE_ITEMS]: {
        title: formatMessage('app.settings.BillingSection.cards.invoiceItems.title'),
        subTitle: formatMessage('app.settings.BillingSection.cards.invoiceItems.description'),
      },
      [SettingsNestedPages.INVOICE_EMAIL_NOTIFICATIONS]: {
        title: formatMessage('app.settings.BillingSection.cards.invoiceEmailNotifications.title'),
        subTitle: formatMessage('app.settings.BillingSection.cards.invoiceEmailNotifications.description'),
      },
    }),
    [allowEditFirstAndLastName, formatMessage],
  );

  const { title, subTitle } = useMemo<{ title: string; subTitle?: string }>(
    () => selectedPageToTitles[selectedPage.selectedSettingsPage ?? SettingsNestedPages.PROFILE],
    [selectedPageToTitles, selectedPage],
  );

  const padding = useMemo(
    () => ({
      x: { xs: 's', s: `${breakpointValues?.containerPaddingX ?? 0}px` } as never,
      y: { xs: 'xs-s', s: `${breakpointValues?.containerPaddingY ?? 0}px` } as never,
    }),
    [breakpointValues],
  );

  const secondScreen = (
    <WithLoading isLoading={isLoading}>
      <Box flexDirection="column" position="absolute" width="100%" height="auto" maxWidth="100%" overflowX="initial">
        <Box position="sticky" top={0} backgroundColor={currentTheme.colors.neutral.white} zIndex={1} width="100%">
          <Container
            overflow="initial"
            paddingLeft="s"
            paddingRight={padding.x}
            paddingTop={{ xs: 'xs-s', s: 'none' } as never}
            paddingBottom="xs-s"
          >
            <Group variant="vertical" spacing={'none'}>
              <Group variant="horizontal" spacing={'s'} alignItems="center">
                {isMobile && (
                  <IconButton
                    icon="arrow-left"
                    aria-label="Go back"
                    variant="naked"
                    size="medium"
                    onClick={goToSettings}
                  />
                )}
                <Text as="h2" textStyle={isExtraSmallScreen ? 'heading1Semi' : 'heading2Semi'}>
                  {title ?? ''}
                </Text>
              </Group>
              {!isMobile && subTitle && (
                <Text textStyle="body2" color="neutral.darker">
                  {subTitle}
                </Text>
              )}
            </Group>
          </Container>
          {isExtraSmallScreen && <Divider />}
        </Box>
        <Container paddingLeft="s" paddingRight={padding.x} paddingY="m" overflow="initial">
          <Outlet />
        </Container>
      </Box>
    </WithLoading>
  );

  const backButton = useCallback(() => {
    if (!isEmbeddedExperience) return null;
    return (
      <IconButton
        variant={isMobile ? 'naked' : 'primary'}
        size={isMobile ? 'medium' : 'large'}
        aria-label={formatMessage('app.settings.backButton.ariaLabel')}
        data-testid="back-button"
        icon="arrow-left"
        onClick={() => goToNewDashboardTab('vendors')}
      />
    );
  }, [isEmbeddedExperience, isMobile, formatMessage, goToNewDashboardTab]);

  const settingsHeader = (
    <Container paddingX={padding.x} paddingY={padding.y} overflow="initial">
      <Group variant="horizontal" justifyContent="flex-start" alignItems="center" spacing="s">
        {backButton()}
        <PageTitle textStyle="heading1Semi">{formatMessage('app.settings.title')}</PageTitle>
      </Group>
    </Container>
  );

  return (
    <SettingsProvider>
      <Container backgroundColor="white" height="full" overflow="initial">
        <Group
          data-testid="settings-screen"
          variant="vertical"
          height="full"
          hasDivider={isExtraSmallScreen}
          spacing="none"
        >
          {settingsHeader}
          <ScreenSplitLayout
            variant="settings"
            {...(isExtraSmallScreen && { showScreenMobile: selectedPage.selectedSettingsPage ? 'second' : 'first' })}
            firstScreen={
              <Container paddingLeft={padding.x} paddingY={{ xs: 'xs-s', s: 'none' } as never} overflow="initial">
                <SettingsLayoutRoute />
              </Container>
            }
            secondScreen={secondScreen}
          />
        </Group>
      </Container>
    </SettingsProvider>
  );
};
