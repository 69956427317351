import { useVendorNotPermitted } from '@melio/ap-domain';
import { EbillCellBadge, LockedVendorIcon } from '@melio/ap-widgets';
import { Avatar, Container, Group, Icon, Text, Typography, useBreakpoint } from '@melio/penny';
import { Bill, Vendor, VendorEBillStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export type VendorsTabListItemProps = { vendor: Vendor; unpaidBills: Bill[] };

export const VendorsTabListItem = ({ vendor, unpaidBills }: VendorsTabListItemProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatCurrency, formatMessage } = useMelioIntl();
  const isVendorNotPermitted = useVendorNotPermitted(vendor);
  const openBalance = unpaidBills.reduce((acc, bill) => acc + bill.balance, 0);
  const noUnpaidBills = unpaidBills.length === 0;
  const unpaidBillsText = noUnpaidBills
    ? formatMessage('activities.payDashboard.vendorsTab.listItem.unpaidBills.none')
    : formatMessage('activities.payDashboard.vendorsTab.listItem.unpaidBills.multiple', {
        billsCount: unpaidBills.length,
        plural: unpaidBills.length > 1 && 's',
      });

  return (
    <Container paddingX="m" overflow="initial" data-testid="vendors-tab-list-item">
      <Group>
        <Avatar name={vendor.name} size="medium" />
        <Group variant="vertical" spacing="xxs" width="full">
          <Group variant="horizontal" spacing="xxs" alignItems="center">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}
            <Typography.MainLabel label={vendor.name} variant="bold" />
            {vendor.eBillStatus !== VendorEBillStatusEnum.NotCapable && <EbillCellBadge vendor={vendor} />}
          </Group>
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle={isExtraSmallScreen ? 'body3Semi' : 'body4Semi'} color="neutral.darkest">
              {formatMessage('activities.payDashboard.vendorsTab.listItem.openBalance', {
                openBalance: formatCurrency(openBalance, vendor.currency ?? ''),
              })}
            </Text>
            <Text
              textStyle={isExtraSmallScreen ? 'body3' : 'body4'}
              color={noUnpaidBills ? 'neutral.700' : 'neutral.darkest'}
            >
              {unpaidBillsText}
            </Text>
          </Group>
        </Group>
        <Icon type="chevron-right" size="small" />
      </Group>
    </Container>
  );
};
