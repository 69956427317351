import React, { cloneElement, PropsWithChildren } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { Divider, StatusIconOutlined, StatusIconSolid, Text, Tooltip } from '@melio/penny';
import { Collaborator, DeliveryMethodType, FundingSourceType } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { MethodCardTheme } from './MethodCard.component.theme';

type MethodCardProps = PropsWithChildren<{
  actionElement?: React.ReactElement;
  icon?: React.ReactElement;
  isDeliveryMethodExists?: boolean;
  nickname?: string | React.ReactNode;
  displayName: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  onClick?: VoidFunction;
  isDisabled?: boolean;
  type?: DeliveryMethodType | FundingSourceType;
  testId?: string;
  showTooltip?: boolean;
  tooltipText?: string;
  imageObj?: Record<string, string | undefined>;
  fundingSourceId?: string;
  collaborators?: Collaborator[];
  onEntitlementLinkClick?: VoidFunction;
  description?: string;
}>;

export const MethodCard = forwardRef(
  (
    {
      fundingSourceId,
      icon,
      displayName,
      nickname,
      helperText,
      children,
      actionElement,
      isDisabled,
      isDeliveryMethodExists,
      type,
      showTooltip,
      imageObj,
      tooltipText = '',
      testId,
      collaborators,
      onEntitlementLinkClick,
      description,
      ...rest
    }: MethodCardProps,
    ref,
  ) => {
    const { entitledFundingSourceCard } = useConfig().settings;
    const [isUsHolidaysChecksOn] = useFeature(FeatureFlags.USHolidaysChecks, true);
    const { formatMessage } = usePlatformIntl();
    const styles = useAppTheme(MethodCardTheme, {});
    const showEOYIcon = isUsHolidaysChecksOn && type === DeliveryMethodType.PaperCheck;
    const showTooltipIcon = showTooltip || showEOYIcon;
    const tooltipTextEoy = formatMessage('widgets.addOrUpdateDeliveryMethod.check.endOfYear');

    const hasFundingLogo = (withLogo: { logo?: string | null }): boolean => {
      return !isEmpty(withLogo.logo) && withLogo.logo !== 'default';
    };

    const hasLogo = !!(imageObj && hasFundingLogo(imageObj));

    const getTextContainerHeader = () => {
      const header = (
        <Flex>
          <Box data-testid={`method-card-type-${type}`} sx={styles['displayName']}>
            {displayName}
          </Box>
          {showTooltipIcon ? (
            <Box ml={1} alignItems={'center'} display={'flex'}>
              <Tooltip label={showEOYIcon ? tooltipTextEoy : tooltipText}>
                {isDeliveryMethodExists ? (
                  <Box as={StatusIconSolid} data-testid="trigger" variant="warning" size="small" tabIndex={0} />
                ) : (
                  <Box as={StatusIconOutlined} data-testid="trigger" variant="neutral" size="small" tabIndex={0} />
                )}
              </Tooltip>
            </Box>
          ) : null}
        </Flex>
      );

      if (entitledFundingSourceCard) {
        return (
          <>
            {header}
            {nickname && <Text textStyle="body3">{nickname}</Text>}
          </>
        );
      }

      return (
        <>
          {nickname && <Text textStyle="body4">{nickname}</Text>}
          {header}
        </>
      );
    };

    return (
      <Box
        sx={styles['container']}
        data-testid={testId ?? `MethodCard-${type}`}
        data-component="MethodCard"
        data-disabled={isDisabled || undefined}
        ref={ref}
        {...rest}
      >
        <Box sx={styles['topSection']}>
          <Box sx={styles['innerContainer']}>
            {icon && !hasLogo ? (
              <Box role="img" aria-label="logo" sx={styles['icon']} data-disabled={isDisabled || undefined}>
                {cloneElement(icon, { size: 'medium' })}
              </Box>
            ) : null}
            {imageObj && hasLogo && <Image src={`data:image/jpeg;base64,${imageObj.logo}`} alt={imageObj?.name} />}
            <Box sx={styles['textContainer']} data-disabled={isDisabled || undefined}>
              {getTextContainerHeader()}
              {helperText && (
                <Text data-testid="method-card-helperText" textStyle="body4" color="neutral.darker">
                  {helperText}
                </Text>
              )}
              {description && (
                <Text
                  data-testid={`method-card-${fundingSourceId}-description-text`}
                  textStyle="body3"
                  color="neutral.darker"
                >
                  {description}
                </Text>
              )}
            </Box>
          </Box>
          {actionElement ? <Box sx={styles['actionContainer']}>{actionElement}</Box> : null}
        </Box>
        {children && (
          <Box sx={styles['bottomSection']}>
            <Box sx={styles['dividerContainer']}>
              <Divider />
            </Box>
            {children}
          </Box>
        )}
      </Box>
    );
  },
);

MethodCard.displayName = 'MethodCard';
