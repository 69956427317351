import { AccountingPlatformSyncConnectionErrorEnum, SyncFlowEnum } from '@melio/ap-activities';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useSubscriptionFeature } from '@melio/subscriptions';

import { useAccountingPlatformDisconnect, useIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { AccountingPlatformCard } from './AccountingPlatformCard.widget';

type Props = {
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  organizationId: string;
  accountingPlatform: AccountingPlatform;
};

export const AccountingPlatformCardQbo = ({ syncError, organizationId, accountingPlatform }: Props) => {
  const { accountingSlug } = accountingPlatform;

  const { loginToQuickBooks, isLoading: isLoadingConnect } = useIntuitConnect(
    SyncFlowEnum.Setting,
    accountingPlatform.id,
  );

  const { isLoading: isLoadingDisconnect, disconnectAccountingPlatform } = useAccountingPlatformDisconnect({
    accountingPlatformId: accountingPlatform.id,
    accountingSlug,
    organizationId,
  });

  const { tryUseFeature: tryUseConnectQboFeature } = useSubscriptionFeature({ featureName: 'accountingPlatformQbo' });

  if (accountingSlug !== AccountingPlatformSlug.QuickBooksOnline) {
    return null;
  }

  return (
    <AccountingPlatformCard
      accountingPlatform={accountingPlatform}
      onConnectClicked={() => tryUseConnectQboFeature({ onFeatureIsEligible: loginToQuickBooks })}
      onDisconnectClicked={disconnectAccountingPlatform}
      isLoading={isLoadingDisconnect || isLoadingConnect}
      syncError={syncError}
    />
  );
};
