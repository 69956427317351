import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';

import { FormWidgetProps } from '../../../types';
import {
  BankAccountFormModel,
  CustomValidation,
  useAchValidationSchema,
} from '../../bank-details/util/use-bank-account-validation-schema';

export type VendorBankDetailsPayorFormFields = {
  routingNumber: string;
  accountNumber: string;
};

import { useEffect } from 'react';
import { FieldErrors } from 'react-hook-form';

import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
} from '../../bank-details/util/bank-account-util';

export type VendorBankDetailsPayorFormProps = FormWidgetProps<VendorBankDetailsPayorFormFields> & {
  customValidation?: CustomValidation;
  onValidation?: (errors: FieldErrors<VendorBankDetailsPayorFormFields & { account: string }>) => void;
  onChange?: () => void;
};

export const VendorBankDetailsPayorForm = forwardRef<VendorBankDetailsPayorFormProps, 'form'>(
  (
    {
      onSubmit,
      onSubmissionStateChange,
      defaultValues: _defaultValues,
      isSaving,
      customValidation,
      onValidation,
      onChange,
      ...props
    },
    ref
  ) => {
    const defaultValues = defaults(_defaultValues, {
      routingNumber: '',
      accountNumber: '',
    });
    const { formatMessage } = useMelioIntl();

    const {
      formProps,
      registerField,
      formState: { errors },
    } = useMelioForm<VendorBankDetailsPayorFormFields>({
      onSubmit,
      schema: useAchValidationSchema({ customValidation }),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    useEffect(() => {
      const fixedErrors = errors as FieldErrors<BankAccountFormModel & { '': string }>;
      onValidation?.({ ...errors, account: fixedErrors[''] });
    }, [errors, onValidation]);

    return (
      <Form
        data-component="VendorBankDetailsPayorForm"
        data-testid="vendor-bank-details-payor-form"
        {...props}
        {...formProps}
        ref={ref}
        onChange={onChange}
      >
        <Form.TextField
          labelProps={{
            label: formatMessage('widgets.deliveryMethods.vendorBankDetailsPayorForm.routingNumber.label'),
          }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorBankDetailsPayorForm.routingNumber.placeholder', {
            length: ROUTING_NUMBER_LENGTH,
          })}
          {...registerField('routingNumber')}
        />
        <Form.SecuredTextField
          labelProps={{
            label: formatMessage('widgets.deliveryMethods.vendorBankDetailsPayorForm.accountNumber.label'),
          }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorBankDetailsPayorForm.accountNumber.placeholder', {
            minLength: ACCOUNT_NUMBER_MIN_LENGTH,
            maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
          })}
          {...registerField('accountNumber')}
        />
      </Form>
    );
  }
);
VendorBankDetailsPayorForm.displayName = 'VendorBankDetailsPayorForm';
