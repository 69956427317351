import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { Text, useBreakpoint } from '@melio/penny';

import { SettingsCard, SettingsCardProps } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsGroupEnum } from '@/partnersConfig.types';

interface SettingsGroupProps {
  title: React.ReactNode;
  settings: Array<SettingsCardProps>;
  type?: SettingsGroupEnum | undefined;
}

export const SettingsGroupWidget = ({ title, settings, type }: SettingsGroupProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  return (
    <Box data-component="SettingsGroup" data-testid={`settings-group${type ? '-' + type : ''}`} marginBottom="s">
      {title ? (
        <Box mb="16px">
          <Text
            textStyle={isExtraSmallScreen ? 'body3Semi' : 'body2Semi'}
            color="neutral.darkest"
            data-testid={`settings-group-title`}
          >
            {title}
          </Text>
        </Box>
      ) : null}
      <Stack as="ul" flexDirection="column" gridGap={4} spacing={0}>
        {settings.map((props) => (
          <Stack as="li" key={props.cardName}>
            <SettingsCard {...props} />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
