import { useToast } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { ApprovalWorkflow, RoleUniqueNames, useApprovalWorkflow, useApprovalWorkflowRoles } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useEffect } from 'react';
import type { DefaultValues } from 'react-hook-form';

import { MonitoredAction } from '../../../monitoring';
import { ApprovalWorkflowForm } from '../ApprovalWorkflowFormScreen/ApprovalWorkflowForm.screen';
import { ApprovalWorkflowFormV2 } from '../ApprovalWorkflowFormScreenV2/ApprovalWorkflowFormV2.screen';
import { useAnalyticsTracker } from '../useAnalyticsTracker';
import { getDefaultValuesV2 } from './utils';

const defaultValues: DefaultValues<ApprovalWorkflow> = {
  criteria: {
    operator: 'AND',
    conditions: [
      {
        attribute: 'payment.amount',
        condition: {
          type: 'workflowNumericCondition',
          configuration: {
            operator: 'greaterEqual',
            value: 0,
          },
        },
      },
      {
        attribute: 'payment.createdBy',
        condition: {
          type: 'workflowCollaboratorsCondition',
          configuration: {
            filterBy: 'role',
            roles: [RoleUniqueNames.MelioAccountant, RoleUniqueNames.MelioContributor],
          },
        },
      },
    ],
  },
  actions: [
    {
      type: 'role',
      configuration: {
        roles: [RoleUniqueNames.MelioAdmin, RoleUniqueNames.MelioOwner],
        numberOfApprovers: 0,
      },
    },
  ],
};

type Props = {
  onClose: VoidFunction;
  onBack: VoidFunction;
  onDone: ({ workflowId }: { workflowId: ApprovalWorkflow['id'] }) => void;
};
export const CreateApprovalWorkflow = withAnalyticsContext<Props>(({ setAnalyticsProperties, ...props }) => {
  const [isApprovalWorkflowsV2] = useFeature<boolean>(FeatureFlags.PlatformApprovalWorkflowsV2, false, {
    shouldTrack: true,
  });
  const { routeReady, startAction, endAction } = useMonitoring<MonitoredAction>();
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const { trackClick, trackView, track, isLoading } = useAnalyticsTracker({ intent: 'add-approval-workflow' });
  const { data } = useApprovalWorkflowRoles({ enabled: isApprovalWorkflowsV2, suspense: true });
  const { create, isMutating } = useApprovalWorkflow({
    onCreate: ({ id }) => {
      endAction('approval_workflow_create_submit');
      props.onDone({ workflowId: id });
    },
    onCreateError(e) {
      toast({ type: 'error', title: formatMessage('activities.create-approval-workflows.toast.failed.save') });
      track('User', 'Status', {
        PageName: 'workflow-added',
        Flow: isApprovalWorkflowsV2 ? 'approval-workflow-v2' : 'approval-workflow',
        Intent: 'approval-workflow',
        Status: 'failure',
        ErrorType: e.message,
      });
    },
  });

  useEffect(() => {
    if (!isLoading) {
      trackView({ pageName: 'add-payment-approval-workflow' });
    }
  }, [isLoading, trackView]);

  return isApprovalWorkflowsV2 ? (
    <ApprovalWorkflowFormV2
      ref={routeReady}
      title={formatMessage('activities.create-approval-workflows.title')}
      onClose={(workflow, action) => {
        trackClick({ cta: action, pageName: 'add-payment-approval-workflow', workflow });
        props.onClose();
      }}
      onBack={(workflow) => {
        trackClick({ cta: 'back', pageName: 'add-payment-approval-workflow', workflow });
        props.onBack();
      }}
      isSaving={isMutating}
      onSubmit={(res) => {
        trackClick({ cta: 'save-workflow', pageName: 'add-advanced-payment-approval-workflow', workflow: res });
        startAction('approval_workflow_create_submit');
        return create(res);
      }}
      defaultValues={getDefaultValuesV2({ roles: data?.paymentApprovalRoles.approvers || [] })}
      isLoading={isLoading}
    />
  ) : (
    <ApprovalWorkflowForm
      ref={routeReady}
      title={formatMessage('activities.create-approval-workflows.title')}
      onClose={(workflow) => {
        trackClick({ cta: 'cancel', pageName: 'add-payment-approval-workflow', workflow });
        props.onClose();
      }}
      onBack={(workflow) => {
        trackClick({ cta: 'back', pageName: 'add-payment-approval-workflow', workflow });
        props.onBack();
      }}
      isSaving={isMutating}
      onSubmit={(res) => {
        trackClick({ cta: 'save-workflow', pageName: 'add-payment-approval-workflow', workflow: res });
        startAction('approval_workflow_create_submit');
        create(res);
      }}
      defaultValues={defaultValues}
      isLoading={isLoading}
    />
  );
});
