import { Collapsible, Group, Text } from '@melio/penny';
import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

import { PaymentReviewLineItem } from '../../../PaymentReviewLineItem';

export type InvoicesDetailsProps = {
  invoiceNumbers: Bill['invoice']['number'][];
};

export const InvoicesDetails = ({ invoiceNumbers }: InvoicesDetailsProps) => {
  const { formatMessage } = useMelioIntl();

  const label = formatMessage('widgets.reviewAndConfirm.payToSection.invoiceNumbersField.label');

  if (invoiceNumbers.length == 1) {
    return (
      <PaymentReviewLineItem
        labelProps={{ label }}
        data-testid="payment-review-invoice-number"
        mainLabelProps={{
          label: invoiceNumbers[0] ?? undefined,
          placeholder: formatMessage('widgets.reviewAndConfirm.payToSection.invoiceNumbersField.noInvoiceNumber'),
        }}
      />
    );
  }
  return (
    <Group variant="vertical" spacing="xs" data-testid="payment-review-invoice-number">
      <PaymentReviewLineItem
        labelProps={{ label }}
        mainLabelProps={{
          label: formatMessage(
            'widgets.reviewAndConfirm.payToSection.invoiceNumbersField.multipleBills.numberOfInvoices',
            { number: invoiceNumbers.length }
          ),
        }}
      />
      <Collapsible
        data-testid="payment-review-invoice-number-collapse-toggle"
        label={formatMessage('widgets.reviewAndConfirm.payToSection.invoiceNumbersField.multipleBills.expand')}
      >
        <Group variant="vertical" spacing="xs">
          {invoiceNumbers.map((invoiceNumber, index) => (
            <Text color="neutral.darker" textStyle="body4" key={index}>
              {invoiceNumber ||
                formatMessage('widgets.reviewAndConfirm.payToSection.invoiceNumbersField.noInvoiceNumber')}
            </Text>
          ))}
        </Group>
      </Collapsible>
    </Group>
  );
};

InvoicesDetails.displayName = 'InvoicesDetails';
