import { NotificationsApiClient } from '@melio/platform-api-axios-client';

import { NotificationsNames, PatchNotificationPreferencesByNameRequest } from './types';

type PatchNotificationPreferencesByNotificationNameProps = {
  id: NotificationsNames;
  data: PatchNotificationPreferencesByNameRequest;
};

export const patchNotificationPreferencesByNotificationName = ({
  id,
  data,
}: PatchNotificationPreferencesByNotificationNameProps) =>
  NotificationsApiClient.patchNotificationPreferencesByNotificationName(id, data);

export const patchNotification = (id: string, data: PatchNotificationPreferencesByNameRequest) =>
  NotificationsApiClient.patchNotificationPreferencesByNotificationName(id as never, data);
