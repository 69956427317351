import { Container, Group, Text } from '@melio/penny';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { InfoCardTheme } from './InfoCard.component.theme';

type Props = {
  label?: string;
  icon?: JSX.Element;
};

export const InfoCard = ({ label, icon }: Props) => {
  const styles = useAppTheme(InfoCardTheme, {});

  return (
    <Container overflow="initial" data-component="InfoCard" sx={styles['container']}>
      <Container overflow="initial" sx={styles['wrapper']}>
        {icon && <Group>{icon}</Group>}
        {label && (
          <Text sx={styles['label']} textStyle="body4">
            {label}
          </Text>
        )}
      </Container>
    </Container>
  );
};
