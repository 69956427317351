import { useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';
import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';
import { ButtonPropsWithTestId } from '../../PayDashboardEmptyState/PayDashboardEmptyState';
import { PaymentsTabFilter } from '../types';
import { PaymentsTabClearedState } from './PaymentsTabClearedState';

const PAYMENT_METHODS_REPLACE = '../../settings/payment-methods';

export const PaymentsTabEmptyState = ({
  onAddNewPayment,
  subTab,
}: {
  onAddNewPayment: VoidFunction;
  subTab: PaymentsTabFilter;
}) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [addPaymentMethodEnabled] = useFeature(FeatureFlags.GrowthAddPaymentMethodEnabled, false, {
    shouldTrack: true,
  });

  const handleAddPaymentMethodClick = useCallback(() => {
    track('Dashboard', 'Click', {
      Intent: 'add-payment-method',
      Cta: 'add-payment-method',
    });
    navigate(PAYMENT_METHODS_REPLACE);
  }, [track, navigate]);

  const handleAddPaymentClick = useCallback(() => {
    track('Dashboard', 'Click', {
      Intent: 'add-payment',
      Cta: 'add-payment',
    });
    onAddNewPayment();
  }, [track, onAddNewPayment]);

  const generateButtons = (): ButtonPropsWithTestId[] => {
    const buttons: ButtonPropsWithTestId[] = [];

    if (addPaymentMethodEnabled) {
      buttons.push({
        label: formatMessage('activities.payDashboard.paymentsTab.emptyState.secondaryButton'),
        variant: 'secondary',
        onClick: handleAddPaymentMethodClick,
        'data-testid': 'payments-tab-empty-state-secondary-button',
      });
    }

    buttons.push({
      label: formatMessage('activities.payDashboard.paymentsTab.emptyState.primaryButton'),
      onClick: handleAddPaymentClick,
      'data-testid': 'payments-tab-empty-state-primary-button',
    });

    return buttons;
  };

  if (subTab !== PaymentsTabFilter.All) {
    return <PaymentsTabClearedState />;
  }
  if (isExtraSmallScreen) {
    return (
      <PayDashboardClearedState
        title={formatMessage('activities.payDashboard.paymentsTab.emptyState.mobile.title')}
        description={formatMessage('activities.payDashboard.paymentsTab.emptyState.mobile.description')}
        aria-label={formatMessage('activities.payDashboard.paymentsTab.emptyState.ariaLabel')}
        illustrationSrc="pay-zero"
      />
    );
  }

  return (
    <PayDashboardEmptyState
      description={formatMessage('activities.payDashboard.paymentsTab.emptyState.description')}
      aria-label={formatMessage('activities.payDashboard.paymentsTab.emptyState.ariaLabel')}
      buttonProps={generateButtons()}
      getAnimationData={() => import('../../../assets/payment.lottie.json')}
    />
  );
};
