import { Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { UnsupportedFundingSourceTypeReasons } from '../../../utils';
import { FundingSourceCard } from '../FundingSourceCard/FundingSourceCard.widget';

export type Predicate = (fundingSource: RecursivePartial<FundingSource>) => boolean;
export type ReasonPredicate = (fundingSource: RecursivePartial<FundingSource>) => UnsupportedFundingSourceTypeReasons;

export type FundingSourceCardListProps = {
  fundingSources: FundingSource[];
  selectedId?: FundingSource['id'];
  onSelect: (fundingSourceId: FundingSource['id']) => void;
  onVerify: (fundingSourceId: FundingSource['id']) => void;
  isDisabled?: Predicate;
  isBillingMethod?: boolean;
  disabledReason?: ReasonPredicate;
  isFundingTypeSupported?: Predicate;
  userId?: string;
  firmBillingFeeMethodId?: string | null;
};

export const FundingSourceCardList = forwardRef<FundingSourceCardListProps, 'div'>(
  (
    {
      fundingSources,
      selectedId,
      onSelect,
      onVerify,
      isDisabled,
      isBillingMethod,
      disabledReason,
      isFundingTypeSupported,
      userId,
      firmBillingFeeMethodId,
      ...rest
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const { fundingSourcePolicy } = useConfig().settings;
    const [accountManagerAccessCollaborators] = useFeature<boolean>(
      FeatureFlags.AccountManagerAccessCollaborators,
      false
    );
    return (
      <Group
        variant="vertical"
        spacing="s"
        data-component="FundingSourceCardList"
        data-testid="funding-source-card-list"
        ref={ref}
        {...rest}
      >
        {fundingSources.map((fundingSource) => {
          const isFirmBillingFeeMethod = firmBillingFeeMethodId === fundingSource.id;
          return (
            <FundingSourceCard
              key={fundingSource.id}
              fundingSource={fundingSource}
              isSelected={selectedId === fundingSource.id}
              onClick={() => onSelect(fundingSource.id)}
              onVerify={() => {
                track('AddFundingSourceMicroDepositVerification', 'Chose');
                onVerify(fundingSource.id);
              }}
              isDisabled={isDisabled?.(fundingSource)}
              isBillingMethod={isBillingMethod}
              isFirmBillingFeeMethod={isFirmBillingFeeMethod}
              isFundingTypeSupported={isFundingTypeSupported?.(fundingSource)}
              disabledReason={disabledReason?.(fundingSource)}
              showAddedBy={
                fundingSource.createdById !== userId &&
                fundingSourcePolicy?.fundingSourceCard?.showCreatedBy &&
                accountManagerAccessCollaborators
              }
            />
          );
        })}
      </Group>
    );
  }
);
FundingSourceCardList.displayName = 'FundingSourceCardList';
