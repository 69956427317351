import { Container, Group, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import {
  SubscriptionPaymentMethod,
  SubscriptionPlansSelectFiserv,
  usePlansTiers,
  useSubscription,
  useSubscriptionRouter,
} from '@melio/subscriptions';

export const SubscriptionPlansSettingsScreenFiserv = () => {
  const { getIsFreePlan } = usePlansTiers();
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const router = useSubscriptionRouter();

  const showPaymentMethod = subscription && !getIsFreePlan(subscription.planId);

  useAnalyticsView('Settings', true, false, {
    PageName: 'fiserv-plans',
    Flow: 'settings',
    Intent: 'view-plans-page',
  });

  return (
    <Container>
      <Group variant="vertical" spacing="xl" data-testid="subscription-plans">
        <Group variant="vertical" spacing="l">
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('widgets.subscriptionPlanSettings.title.currentPlan')}
          </Text>
          <SubscriptionPlansSelectFiserv />
        </Group>

        {showPaymentMethod && (
          <Group variant="vertical" spacing="l">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('widgets.subscriptionPlanSettingsFiserv.title.billingAccount')}
            </Text>
            <SubscriptionPaymentMethod
              onChange={() => {
                router.goToPaymentMethodSelect();
              }}
            />
          </Group>
        )}
      </Group>
    </Container>
  );
};
