import { useMelioIntl } from '@melio/platform-i18n';
import { EMAIL_REGEX } from '@melio/platform-utils';
import { boolean, object, SchemaOf, string, StringSchema } from 'yup';

import { NewFirmClientFormFields } from '../types';

export const emailIsValid = (email?: string) => (email ? EMAIL_REGEX.test(email) : false);

export const useNewFirmClientFormSchema = () => {
  const { formatMessage } = useMelioIntl();

  const requiredFieldMessage = formatMessage(
    'activities.accountants.newClient.form.basicDetails.fields.validation.required.label'
  );
  const invalidEmailAddressMessage = formatMessage(
    'activities.accountants.newClient.form.clientDetails.fields.emailAddress.validation.format.label'
  );

  return object().shape({
    businessName: string().required(requiredFieldMessage),
    businessDBA: string().required(requiredFieldMessage),
    shouldInviteClient: boolean().nullable(),
    clientEmailAddress: string().when(
      'shouldInviteClient',
      (shouldInviteClient: boolean, schema: StringSchema<string | undefined>) =>
        shouldInviteClient
          ? schema.required(requiredFieldMessage).test('validAddress', invalidEmailAddressMessage, emailIsValid)
          : schema.nullable()
    ),
    clientFirstName: string().nullable(),
    clientLastName: string().nullable(),
    whoPays: string().required(requiredFieldMessage),
    subscriptionPlanId: string().required(requiredFieldMessage),
  }) as SchemaOf<NewFirmClientFormFields>;
};
