import { Box } from '@chakra-ui/react';
import { Container, Group, Skeleton, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionPreview } from '../../../../../../api';
import { SubscriptionCheckoutBillingTax } from './SubscriptionCheckoutBillingTax';

export const SubscriptionDetailedTotal = ({
  preview,
  isLoading,
}: {
  preview?: SubscriptionPreview;
  isLoading: boolean;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  return (
    <Container>
      <Group justifyContent="space-between" alignItems="flex-start">
        <Text textStyle="body2" color="neutral.darker">
          {formatMessage('activities.subscription.checkout.summary.subTotal')}
        </Text>
        <Text textStyle="body2" color="neutral.darker" data-testid="subscription-checkout-subtotal">
          {isLoading ? (
            <Box width="100px">
              <Skeleton height="12px" />
            </Box>
          ) : (
            formatCurrency(preview?.amountWithoutTax || 0)
          )}
        </Text>
      </Group>
      <br />
      <Group justifyContent="space-between" alignItems="flex-start">
        <Text textStyle="body2" color="neutral.darker">
          {formatMessage('activities.subscription.checkout.summary.taxes')}
        </Text>
        <SubscriptionCheckoutBillingTax preview={preview} isLoading={isLoading} />
      </Group>
    </Container>
  );
};
