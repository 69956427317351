import { FormattedMessage, FundingSourceType, PayorPaymentRequestDetails, useMelioIntl } from '@melio/ar-domain';
import { Group, Link, SectionBannerProps, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { FundingSourceSelection, PaymentRequestDetailsHeader } from '../components';
import { GuestPayorFundingSourceTypes } from '../types';
import { GuestPaymentLayout } from './GuestPayment.layout';

export type PaymentLayoutProps = {
  isLoading: boolean;
  payeeDetails?: PayorPaymentRequestDetails;
  onViewInvoice: VoidFunction;
  onSelectFundingSource: (fundingSource: FundingSourceType) => void;
  selectedFundingSource?: GuestPayorFundingSourceTypes;
  notificationProps?: SectionBannerProps;
  children: React.ReactNode;
  isPaymentFormLoading: boolean;
};

export const PaymentLayout = withAnalyticsContext<PaymentLayoutProps>(
  forwardRef(
    (
      {
        setAnalyticsProperties,
        notificationProps,
        onViewInvoice,
        selectedFundingSource,
        onSelectFundingSource,
        payeeDetails,
        isLoading,
        children,
        isPaymentFormLoading,
      },
      ref
    ) => {
      const { formatMessage } = useMelioIntl();
      const { privacyPolicyUrl, termsOfServiceUrl } = useConfig().settings;
      const hasAllPaymentMethods =
        payeeDetails?.invoice.paymentOptions.isCardAllowed && payeeDetails.invoice.paymentOptions.isAchAllowed;

      const hasSomePaymentMethod =
        payeeDetails?.invoice.paymentOptions.isCardAllowed || payeeDetails?.invoice.paymentOptions.isAchAllowed;
      const { track } = useAnalytics();
      const handleFundingSourceSelection = (fundingSource: 'card' | 'bank-account') => {
        track('PaymentRequest', 'Click', {
          Intent: 'choose-payment-method',
          PaymentMethodType: fundingSource === 'card' ? 'card' : 'ach',
          Cta: fundingSource === 'card' ? 'card' : 'ach',
        });
        onSelectFundingSource(fundingSource);
      };

      const getAvailablePaymentOptionsString = () => {
        if (!payeeDetails) return null;

        const { isAchAllowed, isCardAllowed, customPayInstructions } = payeeDetails.invoice.paymentOptions;
        const options = [];

        if (isAchAllowed) options.push('ach');
        if (isCardAllowed) options.push('card');
        if (customPayInstructions) options.push('custom');

        return options.join('_');
      };

      const handleOnViewInvoice = () => {
        track('PaymentRequest', 'Click', {
          PageName: 'payment-request',
          Intent: 'view-invoice-file',
          PaymentMethodType: selectedFundingSource,
          Cta: 'view',
        });
        onViewInvoice();
      };

      setAnalyticsProperties({
        PageName: 'payment-request',
        PaymentMethodShown: getAvailablePaymentOptionsString(),
        Intent: 'pay-invoice',
      });

      return (
        <GuestPaymentLayout
          isLoading={isLoading}
          data-component={PaymentLayout.displayName}
          ref={ref}
          notificationProps={notificationProps}
        >
          <Group variant="vertical">
            <Group variant="vertical" spacing="l" hasDivider>
              {payeeDetails ? (
                <PaymentRequestDetailsHeader
                  payeePaymentRequestDetails={payeeDetails.payeeDetails}
                  invoicePaymentRequestDetails={payeeDetails.invoice}
                  onViewInvoice={handleOnViewInvoice}
                  isViewInvoiceEnabled={isPaymentFormLoading}
                />
              ) : null}
              {hasAllPaymentMethods ? (
                <Group variant="vertical" spacing="l">
                  <FundingSourceSelection
                    selectedFundingSource={selectedFundingSource}
                    onCardSelected={() => handleFundingSourceSelection('card')}
                    onBankSelected={() => handleFundingSourceSelection('bank-account')}
                  />
                  <Outlet />
                </Group>
              ) : (
                <Group variant="vertical" spacing="l">
                  {hasSomePaymentMethod && payeeDetails.invoice.paymentOptions.isCardAllowed ? (
                    <Text textStyle="body2Semi">
                      <FormattedMessage id="ar.guestPayment.paymentMethods.payByCard.title.text" />
                    </Text>
                  ) : null}
                  {children}
                </Group>
              )}
            </Group>
            {hasSomePaymentMethod ? (
              <Text textStyle="body4" color="neutral.darker">
                <FormattedMessage
                  id="ar.guestPayment.payment.termsAndPolicyDisclaimer.text"
                  values={{
                    termsOfService: (
                      <Link
                        color="secondary"
                        href={termsOfServiceUrl}
                        label={formatMessage('ar.guestPayment.payment.termsAndPolicyDisclaimer.terms.label')}
                        variant="inline"
                        newTab
                      />
                    ),
                    privacyPolicy: (
                      <Link
                        color="secondary"
                        href={privacyPolicyUrl}
                        label={formatMessage('ar.guestPayment.payment.termsAndPolicyDisclaimer.privacyPolicy.label')}
                        variant="inline"
                        newTab
                      />
                    ),
                  }}
                />
              </Text>
            ) : null}
          </Group>
        </GuestPaymentLayout>
      );
    }
  )
);

PaymentLayout.displayName = 'PaymentLayout';
