import {
  CardNetwork,
  DeliveryMethodType,
  FundingSource,
  FundingSourceType,
  PaperCheckDeliveryMethod,
  Vendor,
  VendorManagedByEnum,
} from '@melio/platform-api';

import { VendorDetailsRequiredFormField } from './components/types';

export const getVendorPaperCheckDeliveryMethod = (vendor: Vendor) =>
  vendor.deliveryMethods.find(({ type }) => type === DeliveryMethodType.PaperCheck);

export const isSupportedVendor = (vendor: Vendor | undefined): boolean =>
  vendor?.managedBy !== VendorManagedByEnum.Fiserv && vendor?.managedBy !== VendorManagedByEnum.Biller;

export const getVendorRequiredFormFields = (
  vendor: Vendor | undefined,
  fundingSource: FundingSource | undefined | null
): VendorDetailsRequiredFormField[] => {
  if (fundingSource?.type !== FundingSourceType.Card) {
    return [];
  }

  const paperCheckDeliveryMethod = vendor
    ? (getVendorPaperCheckDeliveryMethod(vendor) as PaperCheckDeliveryMethod)
    : undefined;
  const missingAddress = !vendor?.contact.address?.line1 && !paperCheckDeliveryMethod?.details.address?.line1;

  const res: VendorDetailsRequiredFormField[] = [];
  switch (fundingSource.details.network) {
    case CardNetwork.Amex:
      if (!vendor?.traits?.mcc) {
        res.push('mcc');
      }
      if (missingAddress) {
        res.push('address');
      }
      if (!vendor?.contact.email) {
        res.push('email');
      }
      if (!vendor?.contact.phoneNumber) {
        res.push('phone');
      }
      return res;

    case CardNetwork.Visa:
      if (!vendor?.traits?.mcc) {
        res.push('mcc');
      }
      if (missingAddress) {
        res.push('address');
      }
      return res;

    default:
      return [];
  }
};
