import { useMelioIntl } from '@melio/platform-provider';

import { UnderMaintenancePage as UnderMaintenanceBase } from '@/cl/components/UnderMaintenancePage/UnderMaintenancePage.component';

type Props = {
  partnerDisplayName: string;
};

export const UnderMaintenancePage: React.FC<Props> = ({ partnerDisplayName }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <UnderMaintenanceBase
      title={formatMessage('app.underMaintenance.title')}
      message={formatMessage('app.underMaintenance.description', { partner: partnerDisplayName })}
    />
  );
};
