import { Box } from '@chakra-ui/react';
import { useAnalyticsView } from '@melio/platform-analytics';
import { PlaidAccountData } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { AddPlaidAccount } from '../components';

export type AddPlaidFundingSourceScreenProps = {
  onClose: VoidFunction;
  onError: ARErrorFunction;
  onSuccess: (data: PlaidAccountData) => void;
  onLoadToken: (isLoading: boolean) => void;
};

export const AddPlaidFundingSourceScreen = forwardRef<AddPlaidFundingSourceScreenProps, 'iframe'>(
  ({ onClose, onError, onSuccess, onLoadToken, children, ...props }, ref) => {
    useAnalyticsView('PaymentRequest', true, true, {
      PageName: 'connect-to-plaid',
      Intent: 'connect-to-plaid',
    });

    return (
      <Box data-testid="plaid-modal" data-component={AddPlaidFundingSourceScreen.displayName} {...props} ref={ref}>
        <AddPlaidAccount onSuccess={onSuccess} onExit={onClose} onError={onError} onLoadToken={onLoadToken} />
      </Box>
    );
  }
);

AddPlaidFundingSourceScreen.displayName = 'AddPlaidFundingSourceScreen';
