import { FormDialog, FormInputs, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, Text, Typography, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import * as yup from 'yup';

export type TaxRateFormFields = {
  name: string;
  rate: number;
  isDefault?: boolean;
};

const useValidationSchema = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    name: yup
      .string()
      .required(formatMessage('ar.settings.activities.addEditTaxRate.fields.name.validation.required'))
      .min(2, formatMessage('ar.settings.activities.addEditTaxRate.fields.name.validation.min', { length: 2 })),
    rate: yup
      .number()
      .typeError(formatMessage('ar.settings.activities.addEditTaxRate.fields.rate.validation.required'))
      .required(formatMessage('ar.settings.activities.addEditTaxRate.fields.rate.validation.required'))
      .moreThan(
        0,
        formatMessage('ar.settings.activities.addEditTaxRate.fields.rate.validation.moreThan', { moreThan: 0 })
      )
      .max(0.75, formatMessage('ar.settings.activities.addEditTaxRate.fields.rate.validation.max', { max: '75%' })),
    isDefault: yup.boolean(),
  }) as yup.SchemaOf<TaxRateFormFields>;
};

export type TaxRateFormDialogScreenProps = Pick<
  FormWidgetProps<TaxRateFormFields>,
  'defaultValues' | 'isSaving' | 'onSubmit'
> & {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading?: boolean;
  variant: 'add' | 'edit';
};
export const TaxRateFormDialogScreen = forwardRef<TaxRateFormDialogScreenProps>(
  ({ onSubmit, defaultValues, isSaving, isLoading, onClose, variant: mode, isOpen, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, ...rest } = useMelioForm<TaxRateFormFields>({
      schema: useValidationSchema(),
      isSaving,
      defaultValues: { rate: '' as never, ...defaultValues },
      onSubmit,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <FormDialog
        data-component={TaxRateFormDialogScreen.displayName}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        header={formatMessage(
          mode == 'add'
            ? 'ar.settings.activities.addEditTaxRate.add.header'
            : 'ar.settings.activities.addEditTaxRate.edit.header'
        )}
        primaryButton={{
          label: formatMessage(
            mode == 'add'
              ? 'ar.settings.activities.addEditTaxRate.primaryButton.add.label'
              : 'ar.settings.activities.addEditTaxRate.primaryButton.edit.label'
          ),
        }}
        secondaryButton={{
          label: formatMessage('ar.settings.activities.addEditTaxRate.secondaryButton.label'),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        {mode == 'edit' && (
          <Text color="neutral.black" textStyle="body2">
            {formatMessage('ar.settings.activities.addEditTaxRate.edit.description')}
          </Text>
        )}
        <Form.TextField
          colSpan={2}
          labelProps={{ label: formatMessage('ar.settings.activities.addEditTaxRate.taxRateForm.fields.name.label') }}
          {...registerField('name')}
          autoFocus
        />
        <FormInputs.PercentageField
          labelProps={{ label: formatMessage('ar.settings.activities.addEditTaxRate.taxRateForm.fields.price.label') }}
          decimalLimit={3}
          {...registerField('rate')}
        />
        <Group variant="vertical">
          <Typography.Label label="&nbsp;" /> {/* This is a hack to align the checkbox with the text field */}
          <Form.Checkbox
            label={formatMessage('ar.settings.activities.addEditTaxRate.taxRatesForm.fields.isDefault.label')}
            aria-label={formatMessage('ar.settings.activities.addEditTaxRate.taxRatesForm.fields.isDefault.label')}
            {...registerField('isDefault')}
          />
        </Group>
      </FormDialog>
    );
  }
);
TaxRateFormDialogScreen.displayName = 'TaxRateFormDialogScreen';
