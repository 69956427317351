import { useAnalyticsContext } from '@melio/platform-analytics';
import {
  Collaborator as ApiCollaborator,
  Invitation as ApiInvitation,
  useAccount,
  useCollaborator,
  useCollaborators,
} from '@melio/platform-api';
import { CanFunction, usePermissions } from '@melio/platform-permissions';

import type { Collaborator, CollaboratorAction, Invitation, InvitationAction } from '../types';
import { usePendingInvitations } from './usePendingInvitations';

const COLLABORATOR_ACTIONS: CollaboratorAction[] = ['owner', 'edit', 'delete'];
const INVITATION_ACTIONS: InvitationAction[] = ['resend', 'cancel'];

export const useManageCollaboratorsData = () => {
  const { can, roles } = usePermissions();
  const { data: actor } = useCollaborator<'user'>({ id: 'me' });
  const { data: account } = useAccount({ id: 'me' });
  const {
    data: collaborators,
    isFetching: isCollaboratorsFetching,
    isLoading: isCollaboratorsLoading,
    isFetched: isCollaboratorsFetched,
  } = useCollaborators({ enabled: !!account, expand: ['user'] });

  const {
    data: invitations,
    isFetching: isInvitationsFetching,
    isLoading: isInvitationsLoading,
    isFetched: isInvitationsFetched,
  } = usePendingInvitations();

  useAnalyticsContext({
    globalProperties: {
      PageEntryPoint: 'settings-page',
      Flow: 'settings',
      UserRole: actor?.roleUniqueName,
    },
  });

  const permissions = {
    invite: can({ action: 'create', subject: 'invitation' }) && !!actor?.roleUniqueName,
    inviteRoleOptions: roles.filter((roleUniqueName) =>
      can({ subject: 'invitation', action: 'create', subjectData: { roleUniqueName } })
    ),
  };

  const collaboratorMapper = createCollaboratorMapper(can);
  const invitationMapper = createInvitationMapper(can);

  return {
    actor: actor ? collaboratorMapper(actor) : undefined,
    collaborators: collaborators?.map(collaboratorMapper),
    invitations: invitations?.map(invitationMapper),
    isFetching: isCollaboratorsFetching || isInvitationsFetching,
    isLoading: isCollaboratorsLoading || isInvitationsLoading,
    companyName: account?.company.name ?? '',
    actorMinApprovalThreshold: undefined, // TODO: in approval workflows
    permissions,
    isFetched: isCollaboratorsFetched && isInvitationsFetched,
  };
};

const createCollaboratorMapper =
  (can: CanFunction) =>
  (collaborator: ApiCollaborator<'user'>): Collaborator => {
    const { user, ...rest } = collaborator;

    const allowedActions: CollaboratorAction[] = COLLABORATOR_ACTIONS.filter((action) => {
      if (action === 'owner') {
        return can({
          subject: 'collaborator:ownership',
          action: 'update',
          subjectData: collaborator,
        });
      }

      if (action === 'edit') {
        return can({
          subject: 'collaborator',
          action: 'update',
          subjectData: collaborator,
        });
      }

      if (action === 'delete') {
        return can({
          subject: 'collaborator',
          action: 'delete',
          subjectData: collaborator,
        });
      }

      return false;
    });

    return {
      ...rest,
      ...user,
      allowedActions,
    };
  };

const createInvitationMapper =
  (can: CanFunction) =>
  (invitation: ApiInvitation): Invitation => {
    const { status, ...rest } = invitation;

    const isExpired = status === 'pending' && rest.expired && rest.expired.getTime() < Date.now();

    const allowedActions: InvitationAction[] = INVITATION_ACTIONS.filter((action) => {
      if (action === 'resend') {
        return can({
          subject: 'invitation',
          action: 'update',
          subjectData: {
            roleUniqueName: invitation.roleUniqueName,
            createdById: invitation.invitedByUserId,
          },
        });
      }

      if (action === 'cancel') {
        return can({
          subject: 'invitation',
          action: 'cancel',
          subjectData: {
            roleUniqueName: invitation.roleUniqueName,
            createdById: invitation.invitedByUserId,
          },
        });
      }

      return false;
    });

    return {
      ...rest,
      status: isExpired ? 'expired' : status,
      allowedActions,
    };
  };
