import { useUserHasPayments } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

export const useMtlKycUpliftEnabled = () => {
  const [mtlKycUpliftEnabledFeature] = useFeature(FeatureFlags.MtlKycUplift, false);
  const { isLoading, userHasPayments } = useUserHasPayments({ enabled: mtlKycUpliftEnabledFeature });

  return {
    isLoading,
    mtlKycUpliftEnabled: mtlKycUpliftEnabledFeature && !userHasPayments,
  };
};
