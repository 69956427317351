import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { useTheme } from '@melio/penny';

export const AttachIcon = (props: IconProps) => {
  const { colors } = useTheme();
  return (
    <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M6.70831 14C4.31665 14 2.33331 12.0167 2.33331 9.625V3.20833C2.33331 1.45833 3.79165 0 5.54165 0C7.29165 0 8.74998 1.45833 8.74998 3.20833V9.04167C8.74998 10.15 7.81665 11.0833 6.70831 11.0833C5.59998 11.0833 4.66665 10.15 4.66665 9.04167V4.08333C4.66665 3.73333 4.89998 3.5 5.24998 3.5C5.59998 3.5 5.83331 3.73333 5.83331 4.08333V9.04167C5.83331 9.50833 6.24165 9.91667 6.70831 9.91667C7.17498 9.91667 7.58331 9.50833 7.58331 9.04167V3.20833C7.58331 2.1 6.64998 1.16667 5.54165 1.16667C4.43331 1.16667 3.49998 2.1 3.49998 3.20833V9.625C3.49998 11.375 4.95831 12.8333 6.70831 12.8333C8.45831 12.8333 9.91665 11.375 9.91665 9.625V2.33333C9.91665 1.98333 10.15 1.75 10.5 1.75C10.85 1.75 11.0833 1.98333 11.0833 2.33333V9.625C11.0833 12.0167 9.09998 14 6.70831 14Z"
        fill={colors.neutral.white}
      />
    </Icon>
  );
};
