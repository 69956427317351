import { Button, Container, Form, Group } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';
import { ComponentProps, useState } from 'react';

type DateFieldProps = Omit<
  ComponentProps<typeof Form.DateField>,
  'footer' | 'isOpen' | 'selectedDate' | 'onSelectDate' | 'onOpenChange' | 'isTypable'
>;
export function DateField({ value, onChange, ...props }: DateFieldProps) {
  const { formatMessage } = useMelioIntl();
  const [selectedDate, setSelectedDate] = useState(value);
  const [isCalendarOpen, calendar] = useBoolean(false);

  return (
    <Form.DateField
      {...props}
      value={value}
      onChange={(date) => {
        if (!date || !value || !isSameDay(date, value)) {
          setSelectedDate(date);
          onChange?.(date);
        }
      }}
      isOpen={isCalendarOpen}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          setSelectedDate(value);
          calendar.on();
        }
      }}
      onSelectDate={(value) => setSelectedDate(value)}
      selectedDate={selectedDate}
      isTypable
      footer={
        <Container paddingX="s" paddingY="s" data-testid="deliveryDate-calendar-footer">
          <Group variant="horizontal" justifyContent="space-between">
            <Button
              data-testid="deliveryDate-calendar-footer-close"
              variant="tertiary"
              size="small"
              label={formatMessage('activities.paymentFlow.form.content.deliveryDate.footer.close')}
              onClick={calendar.off}
            />
            <Button
              data-testid="deliveryDate-calendar-footer-apply"
              variant="primary"
              size="small"
              label={formatMessage('activities.paymentFlow.form.content.deliveryDate.footer.apply')}
              onClick={() => {
                if (!selectedDate) {
                  return;
                }
                onChange?.(selectedDate);
                calendar.off();
              }}
            />
          </Group>
        </Container>
      }
    />
  );
}
