import { Form, FormSearchProps, Icon } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

type Props = Omit<FormSearchProps, 'creatableOption' | 'emptyState'> & {
  shouldSearch: boolean;
  shouldShowBadge: boolean;
  onCreateOption: (value: string) => void;
};

export const Search = forwardRef<Props, 'input'>(
  (
    {
      options,
      isLoading,
      shouldSearch,
      shouldShowBadge,
      dropdownPortalRoot,
      onChange,
      onInputChange,
      onCreateOption,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    return (
      // The ariaLabel and labelProps props are mutually exclusive, meaning that either labelProps or ariaLabel exists, never both simultaneously.
      <Form.Search
        ref={ref}
        isLoading={isLoading}
        options={options}
        dropdownPortalRoot={dropdownPortalRoot}
        onChange={onChange}
        onInputChange={onInputChange}
        creatableOption={{
          label: (value) => formatMessage('widgets.businessSearch.createLabel', { value }),
          onClick: onCreateOption,
          shouldDisplay: () => !isLoading && shouldSearch,
        }}
        emptyState={{
          label: shouldSearch
            ? formatMessage('widgets.businessSearch.emptyState')
            : formatMessage('widgets.businessSearch.enterAtLeastToSearch'),
        }}
        onSearchTermReset={() => onInputChange?.('')}
        valueRightElement={shouldShowBadge ? <Icon size="small" type="verified" color="brand" /> : undefined}
        data-component="BusinessSearchWidget"
        formatSelectedValue={(option) => option.label}
        filterOptions={(options) => options}
        labelProps={{ label: formatMessage('widgets.businessSearch.label') }}
        placeholder={formatMessage('widgets.businessSearch.placeholder')}
        clearButtonAriaLabel={formatMessage('widgets.businessSearch.clearButtonAriaLabel')}
        statusMessageParentSelector={`[id^="floating-"][aria-modal="true"][role="dialog"]`}
        {...props}
      />
    );
  }
);
