import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { AccountingPlatformBankAccountSelectWidget } from '../AccountingPlatformBankAccountSelect/AccountingPlatformBankAccountSelect.widget';
import { MatchBankAccountFormFields, MatchBankAccountFormProps } from './MatchBankAccountForm.type';
import { useSchema } from './MatchBankAccountForm.utils';

export const MatchBankAccountForm = forwardRef<MatchBankAccountFormProps, 'form'>(
  (
    {
      defaultValues,
      onSubmit,
      newBankAccountName,
      onSubmissionStateChange,
      isSaving,
      onCreateOption,
      disabledNewOption,
      accountingPlatformName,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<MatchBankAccountFormFields>({
      onSubmit,
      schema: useSchema(accountingPlatformName, disabledNewOption),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    return (
      <Form data-component="MatchBankAccountForm" {...props} {...formProps} ref={ref}>
        <AccountingPlatformBankAccountSelectWidget
          noOptionsMessage={({ inputValue }) =>
            formatMessage('widgets.fundingSources.reconciliationForm.noResultsMessage', { value: inputValue })
          }
          onCreateOption={onCreateOption}
          newBankAccountName={newBankAccountName}
          labelProps={{
            label: formatMessage(
              'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.label',
              { accountingPlatform: accountingPlatformName }
            ),
          }}
          placeholder={formatMessage(
            'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.placeholder'
          )}
          data-testid="reconciliation-select"
          isRequired
          disabledNewOption={disabledNewOption}
          {...registerField('accountingPlatformBankAccountId')}
        />
      </Form>
    );
  }
);

MatchBankAccountForm.displayName = 'MatchBankAccountForm';
