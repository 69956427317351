import { useMtlSchemaValidations } from '@melio/ap-domain';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { BusinessDetailsForm } from '../types';

export const useBusinessDetailsFormSchema = ({ defaultTaxId }: { defaultTaxId: string }) => {
  const { formatMessage } = useMelioIntl();
  const { companyName, phoneNumber, businessType, industry, taxIdType, taxId, address } = useMtlSchemaValidations();

  const schema = yup.object().shape({
    companyName: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.companyName.validation.required'
        )
      )
      .trim(),
    companyLegalName: companyName().trim(),
    legalBusinessAddress: address('legal-address'),
    phone: phoneNumber().trim(),
    businessIndustry: industry().shape({
      name: yup
        .string()
        .required(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.industry.required'
        ),
    }),
    businessType: businessType(),
    taxIdType: taxIdType(),
    businessTaxId: yup
      .string()
      .when((taxIdValue) =>
        taxIdValue !== defaultTaxId
          ? taxId('taxIdType').trim()
          : yup
              .string()
              .required(
                formatMessage(
                  'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.ssn.required'
                )
              )
              .trim()
      )
      .trim(),
  }) as yup.SchemaOf<BusinessDetailsForm>;

  return schema;
};
