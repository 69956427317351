/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import {
  isPaymentCompletedRefund,
  isPaymentCompletedVoidAndRefund,
  isPaymentScheduledRefund,
  isPaymentScheduledVoid,
  isPaymentScheduledVoidAndRefund,
} from '@melio/ap-activities/src/utils/pay-flow/Payment.utils';
import { usePaymentProcessingInfo } from '@melio/ap-widgets';
import { useTheme } from '@melio/penny';
import { PaymentStatusEnum } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { PaymentCompleted } from './components/completed/PaymentCompleted';
import { RefundCompleted } from './components/completed/RefundCompleted';
import { PaymentFailed } from './components/failed/PaymentFailed';
import { PaymentInProgress } from './components/in-progress/PaymentInProgress';
import { RefundInProgress } from './components/in-progress/RefundInProgress';
import { PaymentScheduled } from './components/scheduled/PaymentScheduled';

export const PaymentDetails = ({ payment, isActionsEnabled }: PaymentDetailsProps) => {
  const theme = useTheme();
  const { goToEditPayment } = useRouter();
  const { isCompletedPaymentStillProcessing } = usePaymentProcessingInfo();

  const renderPaymentDetailsComponent = useCallback(() => {
    switch (payment.status) {
      case PaymentStatusEnum.Blocked:
      case PaymentStatusEnum.Scheduled:
        return (
          <PaymentScheduled
            payment={payment}
            onPaymentEdit={() => goToEditPayment({ id: payment.id })}
            isActionsEnabled={isActionsEnabled}
          />
        );
      case PaymentStatusEnum.InProgress:
        return <PaymentInProgress payment={payment} isActionsEnabled={isActionsEnabled} />;
      case PaymentStatusEnum.Completed: {
        return isCompletedPaymentStillProcessing(payment) ? (
          <PaymentInProgress payment={payment} isActionsEnabled={isActionsEnabled} />
        ) : (
          <PaymentCompleted payment={payment} isActionsEnabled={isActionsEnabled} />
        );
      }
      case PaymentStatusEnum.Failed: {
        const isVoidInProgress = isPaymentScheduledVoid(payment);
        const isRefundInProgress = isPaymentScheduledRefund(payment);
        const isVoidAndRefundInProgress = isPaymentScheduledVoidAndRefund(payment);
        const isRefundCompleted = isPaymentCompletedRefund(payment);
        const isVoidAndRefundCompleted = isPaymentCompletedVoidAndRefund(payment);

        if (isVoidInProgress) return <RefundInProgress payment={payment} isActionsEnabled={isActionsEnabled} />;
        if (isRefundInProgress || isVoidAndRefundInProgress)
          return <RefundInProgress payment={payment} isActionsEnabled={isActionsEnabled} />;
        if (isRefundCompleted || isVoidAndRefundCompleted)
          return <RefundCompleted payment={payment} isActionsEnabled={isActionsEnabled} />;

        return <PaymentFailed payment={payment} isActionsEnabled={isActionsEnabled} />;
      }
    }
  }, [payment]);

  return (
    <Box border={`1px solid ${theme.colors.neutral.light}`} borderRadius={'8px'} w={'full'}>
      {renderPaymentDetailsComponent()}
    </Box>
  );
};
