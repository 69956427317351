/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { defaults } from 'lodash';
import { object, SchemaOf, string } from 'yup';
import {
  isEinOnlyBusinessType,
  masks,
  useMtlFormValues,
  useMtlMessages,
  useMtlSchemaValidations,
} from '@melio/ap-domain';
import { AddressSearchWidget, FormWidgetProps } from '@melio/ap-widgets';
import { Form, SectionBanner, useMelioForm } from '@melio/penny';
import { AccountAddress, OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { SectionHeaderWithButton } from '@/widgets/settings-page/Common/SectionHeaderWithButton.widget';

type AddressValidation = Omit<AccountAddress, 'aptNumber'>;

export type CompanyFormDetails = {
  bizName: string;
  bizAddress: AddressValidation;
  suite: string;
  mobileNumber: string;
  contactFirstName: string;
  contactLastName: string;
  legalBizName: string;
  legalBizAddress: AddressValidation;
  businessType: string;
  taxIdType: string;
  taxIdNumber: string;
};

type DynamicSchemaFields = {
  [K in keyof CompanyFormDetails]: SchemaOf<CompanyFormDetails[K]>;
};

type CompanyFormProps = FormWidgetProps<CompanyFormDetails> & { isEditable?: boolean; mtlKycUpliftEnabled?: boolean };

const useSchema = ({
  hasTaxId,
  hasBusinessType,
  mtlKycUpliftEnabled,
  isTaxIdNumberFocused,
}: {
  hasTaxId: boolean;
  hasBusinessType: boolean;
  isTaxIdNumberFocused: boolean;
  mtlKycUpliftEnabled?: boolean;
}): SchemaOf<CompanyFormDetails> => {
  const { name, address, phoneNumber, businessType, taxId, taxIdType, companyName } = useMtlSchemaValidations();

  const taxIdSchemaV2 = isTaxIdNumberFocused ? taxId('taxIdType') : string().required().trim();

  return object().shape({
    bizName: companyName(),
    bizAddress: address('operating-address'),
    suite: string().trim(),
    mobileNumber: phoneNumber(),
    contactFirstName: name('firstName'),
    contactLastName: name('lastName'),
    legalBizName: companyName(),
    legalBizAddress: address('legal-address'),
    businessType: mtlKycUpliftEnabled && hasBusinessType ? businessType() : string().optional().nullable(),
    taxIdType: taxIdType(),
    taxIdNumber: hasTaxId && !mtlKycUpliftEnabled ? string().required().trim() : taxIdSchemaV2,
  } as DynamicSchemaFields);
};

export const CompanyForm = forwardRef<CompanyFormProps, 'form'>(
  (
    {
      defaultValues: _defaultValues = {},
      isSaving,
      onSubmit,
      onSubmissionStateChange,
      isEditable,
      mtlKycUpliftEnabled,
      ...props
    },
    ref,
  ) => {
    const [applyMask, setApplyMask] = useState<boolean>(false);
    const [isTaxIdNumberFocused, setIsTaxIdNumberFocused] = useState<boolean>(false);

    const { formatMessage } = usePlatformIntl();

    const {
      labels: { company: companyLabels },
      placeholders,
      viewModePlaceholders,
      emptyState,
    } = useMtlMessages();

    const { businessTypeOptions, taxIdTypeOptions } = useMtlFormValues();

    const defaultValues: CompanyFormDetails = defaults(_defaultValues, {
      bizName: '',
      bizAddress: null,
      suite: '',
      mobileNumber: '',
      contactFirstName: '',
      contactLastName: '',
      legalBizName: '',
      legalBizAddress: null,
      taxIdType: '',
      taxIdNumber: '',
      businessType: '',
    });

    const {
      formProps,
      registerField,
      reset,
      setValue,
      watch,
      trigger,
      formState: { isSubmitted },
    } = useMelioForm<CompanyFormDetails>({
      onSubmit: (data) => onSubmit({ ...data }),
      schema: useSchema({
        hasTaxId: !!defaultValues.taxIdNumber,
        hasBusinessType: !!defaultValues.businessType,
        mtlKycUpliftEnabled,
        isTaxIdNumberFocused,
      }),
      isSaving,
      defaultValues,
      mode: 'onChange',
      onSubmissionStateChange,
    });

    const selectedBusinessType = watch('businessType') as OrganizationBusinessType;
    const selectedTaxIdType = watch('taxIdType') as TaxIdTypeEnum;
    const einOnlyBusinessType = isEinOnlyBusinessType(selectedBusinessType);

    const handleTaxIdNumberFocus = () => {
      setValue('taxIdNumber', '');
      setApplyMask(true);
      setIsTaxIdNumberFocused(true);
    };

    useEffect(() => {
      reset(defaultValues);
    }, [_defaultValues]);

    useEffect(() => {
      if (einOnlyBusinessType) {
        setValue('taxIdType', TaxIdTypeEnum.Ein);
      }
    }, [einOnlyBusinessType]);

    useEffect(() => {
      if (!isEditable) {
        setApplyMask(false);
      }
    }, [isEditable]);

    useEffect(() => {
      if (isSubmitted) {
        void trigger('taxIdNumber');
      }
    }, [trigger, isSubmitted, selectedTaxIdType]);

    return (
      <Form data-component="CompanyForm" size={'small'} {...props} {...formProps} ref={ref} isViewMode={!isEditable}>
        <Form.TextField
          {...registerField('bizName')}
          type="text"
          labelProps={{ label: companyLabels.name }}
          placeholder={placeholders.companyName}
          viewModePlaceholder={viewModePlaceholders.companyName}
        />
        <AddressSearchWidget
          {...registerField('bizAddress')}
          labelProps={{ label: companyLabels.address }}
          placeholder={placeholders.address}
          viewModePlaceholder={viewModePlaceholders.address}
        />
        <Form.TextField
          {...registerField('suite')}
          type="text"
          labelProps={{ label: companyLabels.suite }}
          viewModePlaceholder={viewModePlaceholders.suite}
          placeholder={placeholders.suite}
        />
        <Form.TextField
          {...registerField('contactFirstName')}
          type="text"
          labelProps={{ label: companyLabels.contactFirstName }}
          placeholder={placeholders.firstName}
          viewModePlaceholder={viewModePlaceholders.firstName}
        />
        <Form.TextField
          {...registerField('contactLastName')}
          type="text"
          labelProps={{ label: companyLabels.contactLastName }}
          placeholder={placeholders.lastName}
          viewModePlaceholder={viewModePlaceholders.lastName}
        />
        <SectionHeaderWithButton
          title={formatMessage('widgets.companySettings.companyLegalSection.title')}
          titleAs="h3"
        />
        <Form.TextField
          {...registerField('legalBizName')}
          type="text"
          labelProps={{ label: companyLabels.legalName }}
          placeholder={placeholders.companyName}
          viewModePlaceholder={viewModePlaceholders.companyName}
        />
        <Form.PhoneField
          {...registerField('mobileNumber')}
          type="text"
          labelProps={{ label: companyLabels.phoneNumber }}
          placeholder={placeholders.phoneNumber}
          viewModePlaceholder={viewModePlaceholders.phoneNumber}
        />
        <AddressSearchWidget
          {...registerField('legalBizAddress')}
          labelProps={{ label: companyLabels.legalAddress }}
          placeholder={placeholders.address}
          viewModePlaceholder={viewModePlaceholders.address}
        />
        <Form.Select
          {...registerField('businessType')}
          isHidden={!mtlKycUpliftEnabled}
          isViewMode={!isEditable}
          viewModePlaceholder={viewModePlaceholders.businessType}
          labelProps={{ label: companyLabels.businessType }}
          options={businessTypeOptions}
          emptyState={{ label: emptyState.businessType }}
          placeholder={placeholders.businessType}
        />
        {isEditable && einOnlyBusinessType && (
          <SectionBanner
            description={formatMessage('widgets.completeLegalInfo.taxInfo.identifier.banner.EIN')}
            data-testid="taxInfoBanner"
          />
        )}
        <Form.RadioGroup
          {...registerField('taxIdType')}
          labelProps={{ label: companyLabels.taxIdType }}
          isViewMode={!isEditable}
          isHidden={!isEditable && !defaultValues?.taxIdType}
          options={taxIdTypeOptions(selectedBusinessType)}
          viewModePlaceholder={viewModePlaceholders.taxIdType}
        />
        <Form.TextField
          {...registerField('taxIdNumber')}
          type="text"
          onFocus={handleTaxIdNumberFocus}
          labelProps={{ label: companyLabels.taxId(selectedTaxIdType) }}
          placeholder={placeholders.taxId(selectedTaxIdType)}
          isViewMode={!isEditable}
          viewModePlaceholder={defaultValues.taxIdNumber || viewModePlaceholders.taxId}
          {...(mtlKycUpliftEnabled && {
            maskProps: {
              mask: applyMask
                ? masks.taxId[selectedTaxIdType || TaxIdTypeEnum.Ein] ?? masks.taxId.any
                : masks.taxId.any,
            },
          })}
        />
      </Form>
    );
  },
);

CompanyForm.displayName = 'CompanyForm';
