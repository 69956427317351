import { CheckAccountFormModel, useCheckValidationSchema } from '@melio/ap-widgets';
import { useMelioForm, useToast } from '@melio/penny';
import { PaperCheckDeliveryMethod, useDeliveryMethods, Vendor } from '@melio/platform-api';

import { useConfirmationModal } from '../useConfirmationModal';

export function usePaperCheckFormModal({
  vendor,
  onDone,
}: {
  vendor?: Vendor;
  onDone: (deliveryMethod: PaperCheckDeliveryMethod) => void;
}) {
  const { toast } = useToast();
  const { create, isMutating } = useDeliveryMethods({ enabled: false, vendorId: vendor?.id });

  const paperCheck = vendor?.deliveryMethods.find((method) => method.type === 'paper-check') as
    | PaperCheckDeliveryMethod
    | undefined;

  const confirmationModal = useConfirmationModal({
    deliveryMethod: paperCheck,
  });

  const paperCheckForm = useMelioForm<CheckAccountFormModel>({
    schema: useCheckValidationSchema(),
    onSubmit: async ({ printName, ...address }) => {
      try {
        const deliveryMethod = await create({
          type: 'paper-check',
          details: { printName, address },
          requireConfirmationForPayment: false,
        });
        onDone(deliveryMethod as PaperCheckDeliveryMethod);
        confirmationModal.close();
      } catch (error) {
        toast({ type: 'error', title: (error as PlatformError)?.message });
      }
    },
    defaultValues: {
      printName: paperCheck?.details.printName || '',
      line1: paperCheck?.details.address.line1 || '',
      line2: paperCheck?.details.address.line2 || '',
      city: paperCheck?.details.address.city || '',
      state: paperCheck?.details.address.state || '',
      postalCode: paperCheck?.details.address.postalCode || '',
    },
    subscribeToDefaultValuesChanges: true,
  });

  return {
    ...confirmationModal,
    ...paperCheckForm,
    vendor,
    paperCheck,
    isSaving: isMutating,
  };
}
