import { withAnalyticsContext } from '@melio/platform-analytics';

import { AddBankAccountMicroDepositsActivity } from '../AddBankAccountMicroDeposits';
import { AddBankAccountPlaidActivity } from '../AddBankAccountPlaid';
import { BankIntegrationSelectionScreen } from './screens';
import { AddBankAccountActivityProps } from './types';
import { BANK_INTEGRATION_TO_STEP, useAddBankAccountActivityStep } from './useAddBankAccountActivityStep';

export const AddBankAccountActivity = withAnalyticsContext<AddBankAccountActivityProps>(
  ({ onBack: onFirstStepBack, onClose, onError, onDone, isReceivingMethodFlow }) => {
    const { currentStep, goToStep, goToPreviousStep } = useAddBankAccountActivityStep({ onFirstStepBack });

    const onCloseACHPlaidModal = () => {
      if (isReceivingMethodFlow) {
        goToStep('ADD_ACH_MICRO_DEPOSITS');
      } else {
        onClose();
      }
    };

    switch (currentStep) {
      case 'ADD_ACH_INTEGRATION_SELECTION':
      default:
        return (
          <BankIntegrationSelectionScreen
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={(integrationType) => {
              goToStep(BANK_INTEGRATION_TO_STEP[integrationType]);
            }}
          />
        );

      case 'ADD_ACH_MICRO_DEPOSITS':
        return (
          <AddBankAccountMicroDepositsActivity
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={onDone}
            onError={onError}
            isReceivingMethodFlow={isReceivingMethodFlow}
          />
        );

      case 'ADD_ACH_PLAID':
        return (
          <AddBankAccountPlaidActivity
            onClose={onCloseACHPlaidModal}
            onBack={goToPreviousStep}
            onDone={onDone}
            onError={onError}
            isReceivingMethodFlow={isReceivingMethodFlow}
          />
        );
    }
  }
);

AddBankAccountActivity.displayName = 'AddBankAccountActivity';
