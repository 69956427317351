import { isFXCurrency } from '@melio/ap-domain';
import { Group, Icon, Table, Text } from '@melio/penny';
import { BillSubscriptionIntervalTypeEnum, BillSubscriptionManagedBy, Payment } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { isPartialPayment } from '../../../../../utils/pay-flow/Payment.utils';

export const PaymentsTabAmountCell = ({ payment }: { payment: Payment }) => {
  const { formatCurrency } = useMelioIntl();
  const description = usePaymentAmountDetails(payment);
  const isFxPayment = isFXCurrency(payment.currency);
  const formattedAmount = formatCurrency(
    isFxPayment && payment.foreignAmount ? payment.foreignAmount : payment.amount,
    payment.currency
  );

  return (
    <Table.Cell textAlign="end">
      <Group
        variant="vertical"
        spacing="xxs"
        alignItems="flex-end"
        data-testid={`payments-tab-row-${payment.id}-amount-cell`}
      >
        <Text textStyle="body3Semi">{formattedAmount}</Text>
        {description}
      </Group>
    </Table.Cell>
  );
};

export const usePaymentAmountDetails = (payment: Payment) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [isAutoPayEnabled] = useFeature(FeatureFlags.AutoPay, false);

  const getRecurringPaymentDescriptionLabel = (
    intervalType: BillSubscriptionIntervalTypeEnum,
    occurrenceNumber: number,
    managedBy: BillSubscriptionManagedBy | null,
    numOfOccurrences?: number | null
  ) =>
    managedBy !== BillSubscriptionManagedBy.Fiserv && numOfOccurrences
      ? formatMessage(`activities.payDashboard.paymentsTab.table.cells.amount.recurringPaymentText.${intervalType}`, {
          numOfOccurrences,
          occurrenceNumber,
        })
      : formatMessage(
          `activities.payDashboard.paymentsTab.table.cells.amount.recurringPaymentText.noEndDate.${intervalType}`
        );

  if (isAutoPayEnabled && payment.isAutoPayment) {
    return (
      <Group spacing="xs" alignItems="center">
        <Icon type="repeat" size="small" isReadOnly />
        <Text textStyle="body4" color="neutral.darker">
          {formatMessage('activities.payDashboard.paymentsTab.table.cells.amount.autoPay.label')}
        </Text>
      </Group>
    );
  }

  if (isPartialPayment(payment) && payment.bills?.[0]?.amount) {
    return (
      <Text
        data-testid={`payments-tab-row-${payment.id}-amount-cell-partial-payment-description`}
        textStyle="body4"
        color="neutral.darker"
      >
        {formatMessage('activities.payDashboard.paymentsTab.table.cells.amount.helperText', {
          amount: formatCurrency(payment.bills[0].amount),
        })}
      </Text>
    );
  }
  if (payment.subscriptionOccurrence?.billSubscription) {
    const {
      subscriptionOccurrence: {
        billSubscription: { intervalType, numOfOccurrences, managedBy },
        occurrenceNumber,
      },
    } = payment;

    const label = getRecurringPaymentDescriptionLabel(intervalType, occurrenceNumber, managedBy, numOfOccurrences);

    return (
      <Group spacing="xs" alignItems="center">
        <Icon type="repeat" size="small" isReadOnly />
        {label && (
          <Text textStyle="body4" color="neutral.darker">
            {label}
          </Text>
        )}
      </Group>
    );
  }
  return null;
};
