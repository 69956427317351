import { useCallback, useState } from 'react';
import { KycComplianceFormState } from '@melio/ap-activities';
import { useUserHasPayments } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import {
  useAccountComplianceRecoilState,
  useSetAccountComplianceRecoilState,
} from '@/store/Compliance/compliance.model';

export const useIsMissingKycComplianceInformation = () => {
  const [kycComplianceFormState, setKycComplianceFormState] = useState<KycComplianceFormState>({
    formState: null,
    submittedAll: false,
  });
  const { isMissingFields, isSubmitAllMissingFields } = useAccountComplianceRecoilState();
  const { userHasPayments, isLoading } = useUserHasPayments();
  const [isExistingUserMtlKycEnabled] = useFeature(FeatureFlags.ExistingUserMtlKycUplift, false);
  const setKycComplianceState = useSetAccountComplianceRecoilState();

  const missingKycComplianceInformation =
    isExistingUserMtlKycEnabled && !!isMissingFields && !isSubmitAllMissingFields && userHasPayments;

  const onKycComplianceFormDone = useCallback(() => {
    const { formState, submittedAll } = kycComplianceFormState;
    const props =
      formState === 'submit'
        ? {
            isSubmitted: true,
            isSubmitAllMissingFields: submittedAll,
            isMissingFields: !submittedAll,
            isDismissKycLoginBlockerScreen: false,
          }
        : {
            isSubmitted: false,
            isSubmitAllMissingFields: false,
            isDismissKycLoginBlockerScreen: true,
          };

    setKycComplianceState(props);
  }, [kycComplianceFormState, setKycComplianceState]);

  return { isLoading, missingKycComplianceInformation, onKycComplianceFormDone, setKycComplianceFormState };
};
