import { ARInvoice, ARPaymentRequestLink, FormattedMessage, InvoiceExpandedSummary } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps } from '@melio/penny';

import { InvoiceActions, InvoiceActionsHandlers } from './types';

const statusActions: Record<ARInvoice['displayStatus'], InvoiceActions[]> = {
  draft: ['edit', 'preview', 'delete'],
  open: ['send', 'edit', 'markAsPaid', 'preview', 'download', 'cancel'],
  overdue: ['remind', 'edit', 'markAsPaid', 'preview', 'download', 'cancel'],
  inProcess: ['preview', 'download'],
  paid: ['preview', 'download'],
  canceled: ['preview', 'download'],
};

/**
 * Retrieves the actions associated with a given invoice status.
 * @param status The display status of the invoice.
 * @returns An array of actions associated with the invoice status.
 */
export const getInvoiceStatusActions = (status: ARInvoice['displayStatus']) => statusActions[status];

type ActionFunction = (param: string) => unknown | Promise<unknown>;

export const getInvoiceAction = (
  actionName: InvoiceActions,
  actions: InvoiceActionsHandlers
): ActionFunction | void => {
  const actionsMap = {
    delete: actions.onDeleteInvoice,
    send: actions.onIssueInvoice,
    remind: actions.onSendReminder,
    preview: actions.onPreviewInvoice,
    download: actions.onDownloadInvoice,
    copy: actions.onCopyLink,
    cancel: actions.onCancelInvoice,
    edit: actions.onEditInvoice,
    markAsPaid: actions.onMarkInvoiceAsPaid,
    updateMarkAsPaid: actions.onUpdateMarkAsPaid,
    markAsUnpaid: actions.onMarkInvoiceAsUnpaid,
  };
  return actionsMap[actionName];
};

export const getActionParameterValue = ({
  action,
  invoice,
}: {
  action: InvoiceActions;
  invoice: (ARInvoice | InvoiceExpandedSummary) & ARPaymentRequestLink;
}): string => {
  try {
    switch (action) {
      case 'download':
        if (!invoice.fileId) {
          throw new Error(`Can't download file`);
        }
        return invoice.fileId;
      case 'copy':
        if (!invoice.paymentRequest?.link) {
          throw new Error(`Can't copy payment request link`);
        }
        return invoice.paymentRequest.link;
      default:
        return invoice.id;
    }
  } catch (e) {
    return '';
  }
};

/**
 * Returns the variant of the action based on the provided InvoiceActions.
 * @param action The InvoiceActions value.
 * @returns The variant of the action.
 */
export function getActionVariant(action: InvoiceActions): ActionsDropdownMenuItemProps['variant'] {
  switch (action) {
    case 'cancel':
    case 'delete':
      return 'critical';
    default:
      return 'default';
  }
}

/**
 * Retrieves the label for a specific invoice action.
 * @param action - The invoice action.
 * @returns The label for the specified action.
 */
export function getActionLabel(action: InvoiceActions): string {
  return (<FormattedMessage id={`ar.dashboard.activities.invoiceTable.actions.${action}.label`} />) as never;
}
