import React, { useEffect } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { DemoRequestButtonActivity } from '@melio/ap-activities';
import { Button, Container, Link, NakedButton, Text, useDisclosure } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';
import { useZendesk } from '@/utils/zendesk';
import { MelioTutorialVideoModal } from '@/widgets/pay-dashboard/melioTutorileVideo/MelioTutorialVideoModal.widget';
import { LaunchTourButton } from './LaunchTourButton.widget';
import { SupportAccordion } from './SupportAccordion.widget';

export const Support = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { showZendesk } = useZendesk();
  const { track } = useAnalytics();
  const [isPayDashboardTourEnabled] = useFeature<boolean>(FeatureFlags.IsPayDashboardTourEnabled, false);
  const { isOpen: isTutorialModalOpen, onOpen: onOpenTutorialModal, onClose: onCloseTutorialModal } = useDisclosure();
  const accessTokenData = getAccessTokenData();
  const [isDemoRequestEnabled] = useFeature(FeatureFlags.RequestADemo, false);

  useEffect(() => {
    track('Settings', 'View', { PageName: SettingsCardIdEnum.SupportSetting });
  }, [track]);

  return (
    <Container data-component="SupportComponent">
      {isDemoRequestEnabled && accessTokenData?.organization && (
        <SettingsSectionContainer>
          <Stack flexDirection="column" spacing={8}>
            <Stack flexDirection="column">
              <Text as="h3" textStyle={'heading3Semi'}>
                {formatMessage('app.settings.SupportSection.demo.title', {
                  partnerProductName: partnerConfig.partnerProductName,
                })}
              </Text>
              <Text>{formatMessage('app.settings.SupportSection.demo.description')}</Text>
              <Box pt={2}>
                <DemoRequestButtonActivity
                  organizationId={accessTokenData?.organization?.id}
                  email={accessTokenData?.user.email}
                  helpCenterLink={partnerConfig.config.links.helpCenter}
                />
              </Box>
            </Stack>
          </Stack>
        </SettingsSectionContainer>
      )}
      {isPayDashboardTourEnabled && (
        <SettingsSectionContainer>
          <Stack flexDirection="column" spacing={8}>
            <Stack flexDirection="column">
              <Text as="h3" textStyle="heading3Semi">
                {formatMessage('app.settings.SupportSection.tour.title')}
              </Text>
              <Text>{formatMessage('app.settings.SupportSection.tour.description')}</Text>
              <Box pt={2}>
                <LaunchTourButton />
              </Box>
            </Stack>
          </Stack>
        </SettingsSectionContainer>
      )}
      <SettingsSectionContainer data-component="SupportPage">
        <Stack flexDirection="column" spacing={8}>
          <Stack flexDirection="column">
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('app.settings.SupportSection.help.title')}
            </Text>
            <Text>
              {formatMessage('app.settings.SupportSection.help.description', {
                tutorialLink: (
                  <NakedButton
                    size="large"
                    variant="secondary"
                    onClick={onOpenTutorialModal}
                    data-testid="open-melio-tutorial-video"
                    label={formatMessage('app.settings.SupportSection.help.tutorialLink')}
                  />
                ),
              })}
            </Text>
          </Stack>
        </Stack>
      </SettingsSectionContainer>
      <SupportAccordion />
      <SettingsSectionContainer>
        <Stack>
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('app.settings.SupportSection.contact.title')}
          </Text>
          <Text>
            {formatMessage('app.settings.SupportSection.contact.description', {
              helpCenterLink: (
                <Link
                  variant="inline"
                  href={partnerConfig.config.links.helpCenter}
                  label={formatMessage('app.settings.SupportSection.contact.helpCenter.link')}
                  newTab
                />
              ),
              submitSupportRequestLink: (
                <Link
                  variant="inline"
                  href={partnerConfig.config.links.submitSupportRequest}
                  label={formatMessage('app.settings.SupportSection.contact.submitSupportRequest.link')}
                  newTab
                />
              ),
            })}
          </Text>
          <Box paddingTop="xs">
            <Button
              variant={'secondary'}
              onClick={() => {
                track('ContactUsViaChat', 'Chose');
                showZendesk();
              }}
              icon="chat-empty"
              label={formatMessage('app.settings.SupportSection.contact.chatButton')}
            />
          </Box>
        </Stack>
      </SettingsSectionContainer>
      <MelioTutorialVideoModal
        isOpen={isTutorialModalOpen}
        origin={OriginFlow.SupportSettings}
        onModalClose={onCloseTutorialModal}
      />
    </Container>
  );
};
