import { Button, Container, Form, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { StepFormProps } from '../../types';

export const BasicDetailsStep: React.FC<StepFormProps> = ({
  submitLabel,
  watch,
  triggerFormValidationFields,
  registerField,
  nextStep,
}) => {
  const { formatMessage } = useMelioIntl();

  const watchShouldInviteClient = watch('shouldInviteClient');

  const verifyBasicDetails = async () => {
    const isPartialFormValid = await triggerFormValidationFields([
      'businessName',
      'businessDBA',
      'clientEmailAddress',
      'clientFirstName',
      'clientLastName',
    ]);

    if (isPartialFormValid) {
      nextStep?.();
    }
  };

  return (
    <Group spacing="m" variant="vertical" width="full">
      <Text textStyle="body2Semi">
        {formatMessage('activities.accountants.newClient.form.basicDetails.sectionHeader.businessName.text')}
      </Text>
      <Form.TextField
        isRequired
        labelProps={{
          label: formatMessage('activities.accountants.newClient.form.basicDetails.fields.businessName.label'),
        }}
        {...registerField('businessName')}
      />
      <Form.TextField
        isRequired
        labelProps={{
          label: formatMessage('activities.accountants.newClient.form.basicDetails.fields.businessDBA.label'),
        }}
        {...registerField('businessDBA')}
      />
      <Form.Checkbox
        data-testid="basic-details-invite-client"
        label={formatMessage('activities.accountants.newClient.form.basicDetails.fields.inviteClient.label')}
        {...registerField('shouldInviteClient')}
      />
      {watchShouldInviteClient && (
        <>
          <Text textStyle="body2Semi">
            {formatMessage('activities.accountants.newClient.form.basicDetails.sectionHeader.clientDetails.text')}
          </Text>
          <Form.TextField
            isRequired={!!watchShouldInviteClient}
            labelProps={{
              label: formatMessage('activities.accountants.newClient.form.clientDetails.fields.emailAddress.label'),
            }}
            {...registerField('clientEmailAddress')}
          />
          <Group>
            <Form.TextField
              labelProps={{
                label: formatMessage('activities.accountants.newClient.form.clientDetails.fields.firstName.label'),
              }}
              {...registerField('clientFirstName')}
            />
            <Form.TextField
              labelProps={{
                label: formatMessage('activities.accountants.newClient.form.clientDetails.fields.lastName.label'),
              }}
              {...registerField('clientLastName')}
            />
          </Group>
        </>
      )}
      <Container justifyContent="flex-end">
        <Button
          variant="primary"
          size="medium"
          label={submitLabel}
          data-testid="basic-details-continue-button"
          onClick={() => void verifyBasicDetails()}
        />
      </Container>
    </Group>
  );
};
