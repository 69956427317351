import { Logger } from '@melio/platform-logger';
import { useContext } from 'react';

import { Feature } from '../api';
import { SubscriptionContext } from '../context';
import { getIsFeatureInPlan, QuantityBasedFeature } from '../utils/planFeatures.utils';
import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';

type FeatureEligibilityData = {
  isEligible: boolean;
};

type QuantityBasedFeatureEligibilityData = FeatureEligibilityData & {
  quota: { freeUnitsLimit?: number; excessUnitFee?: number };
};

type BooleanBasedFeatureEligibilityData = FeatureEligibilityData;

export function useIsFeatureInSubscription<T extends Feature>(
  feature: Parameters<typeof getIsFeatureInPlan<T>>[1]
): T extends QuantityBasedFeature ? QuantityBasedFeatureEligibilityData : BooleanBasedFeatureEligibilityData;
export function useIsFeatureInSubscription(
  feature: Parameters<typeof getIsFeatureInPlan>[1]
): QuantityBasedFeatureEligibilityData | BooleanBasedFeatureEligibilityData {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { plans, subscription } = useContext(SubscriptionContext);

  if (!isSubscriptionsEnabled) {
    return { isEligible: true, quota: { freeUnitsLimit: undefined, excessUnitFee: undefined } };
  }

  if (!subscription) {
    Logger.log('useIsFeatureInSubscription - subscription is enabled but subscription is not exist', 'error');
    return { isEligible: false, quota: { freeUnitsLimit: undefined, excessUnitFee: undefined } };
  }

  if (!plans) {
    const error = 'No plans found. useIsFeatureInSubscription must be used within a SubscriptionProvider';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  const currentPlan = plans.find((plan) => plan.id === subscription.planId);

  if (!currentPlan) {
    const error = 'currentPlan not found';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  const eligibilityData = getIsFeatureInPlan(currentPlan, feature);

  if ('quota' in eligibilityData) {
    const excessUnitFee = eligibilityData.quota.excessFee[subscription.planCyclePeriod];
    const freeUnitsLimit = eligibilityData.quota.freeUnitsLimit;

    return { isEligible: eligibilityData.isEligible, quota: { freeUnitsLimit, excessUnitFee } };
  }

  return { isEligible: eligibilityData.isEligible };
}
