import { DeprecatedSelectProps, Form } from '@melio/penny';
import { AccountingPlatformBillLabel } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { generateBillLabelSelectOptions } from './utils';

export type AccountingPlatformBillLabelSelectWidgetProps = {
  billLabel?: AccountingPlatformBillLabel;
} & Omit<DeprecatedSelectProps, 'loadOptions' | 'formatCreateLabel' | 'onCreateOption' | 'value' | 'options'>;

export const AccountingPlatformBillLabelSelectWidget = forwardRef<
  AccountingPlatformBillLabelSelectWidgetProps,
  'input'
>(({ isReadOnly, isViewMode, billLabel, ...props }, ref) => {
  const placeholder = billLabel?.name;

  const options = generateBillLabelSelectOptions(billLabel?.options);

  return (
    <Form.DeprecatedSelect
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - there is an issue with the underline type that penny uses
      options={options}
      data-component="AccountingPlatformBillLabelSelectWidget"
      ref={ref}
      placeholder={placeholder}
      viewModePlaceholder={placeholder}
      isReadOnly={isReadOnly}
      isViewMode={isViewMode}
      labelProps={{ label: placeholder ?? '' }}
      {...props}
    />
  );
});

AccountingPlatformBillLabelSelectWidget.displayName = 'AccountingPlatformBillLabelSelect';
