import { useFormatExpirationDate, useFundingSourceIconOrImage } from '@melio/ap-domain';
import { Card, Container, Group, NakedButton, Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePartnerGroups } from '../../../hooks';
import { cardProps } from './utils';

export type FundingSourceCardProps = {
  fundingSource: FundingSource;
  onChangeClick?: VoidFunction;
  onClick?: VoidFunction;
  isSelected?: boolean;
};

export const FundingSourceCard = ({ fundingSource, onChangeClick, isSelected, onClick }: FundingSourceCardProps) => {
  const { isFiservPartner } = usePartnerGroups();
  const logo = useFundingSourceIconOrImage(fundingSource);
  const { formatMessage } = useMelioIntl();
  const { formatExpirationDate } = useFormatExpirationDate();
  const getFundingSourceSubTitle = () => {
    if (fundingSource.type === 'card') {
      return formatMessage('activities.subscription.checkout.fundingSourceSelection.card.expiresText', {
        expiration: formatExpirationDate(fundingSource),
      });
    }
    if (fundingSource.type === 'bank-account') {
      return fundingSource.details.verifiedStatus;
    }
    return null;
  };

  return (
    <Card
      variant={isFiservPartner ? 'flat' : 'default'}
      isSelected={isSelected}
      onClick={onClick}
      data-component="FundingSourceCard"
      data-testid={`funding-source-card-${fundingSource.id}`}
      inputProps={{
        role: 'radio',
      }}
      {...cardProps}
    >
      <Container alignItems="center" width="full" justifyContent="space-between">
        <Group spacing="s" variant="horizontal" alignItems="center">
          {logo}
          <Group spacing="xxxs" variant="vertical">
            <Text textStyle="body2Semi">{fundingSource.displayName}</Text>
            <Text color="neutral.darker" textStyle="body4">
              {getFundingSourceSubTitle()}
            </Text>
          </Group>
        </Group>
        {onChangeClick ? (
          <Group spacing="xxxs" variant="vertical" textAlign="end">
            <NakedButton
              data-testid="change-funding-source-button"
              label={formatMessage('widgets.billingSettings.paymentMethod.card.actions.change')}
              variant="secondary"
              onClick={onChangeClick}
            />
          </Group>
        ) : null}
      </Container>
    </Card>
  );
};
