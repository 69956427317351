import React from 'react';
import { Outlet } from 'react-router-dom';
import { useConfig } from '@melio/platform-provider';

import { AppLayout } from '@/cl/layouts/app-layout/appLayout.component';
import { usePartnerConfig } from '@/hooks/partners';

export const FullScreenLayout = () => {
  const { partnerConfig } = usePartnerConfig();
  const {
    settings: { isEmbeddedExperience },
  } = useConfig();

  return (
    <AppLayout
      topNav={
        isEmbeddedExperience
          ? undefined
          : {
              TopNavIcon: partnerConfig.icons.LogoIcon,
              TopNavSmallIcon: partnerConfig.icons.SmallLogoIcon,
              showOrgSwitcher: false,
            }
      }
    >
      <Outlet />
    </AppLayout>
  );
};
