import { Container, Group } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded, PaymentStatusEnum } from '@melio/platform-api';
import { RefObject } from 'react';

import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { MarkedAsPaidSection } from '../sections/MarkedAsPaidSection';
import { PaidToSection } from '../sections/PaidToSection';
import { PayFromSection } from '../sections/PayFromSection';
import { PaymentApprovalDecisionSection } from '../sections/PaymentApprovalDecisionSection';
import { PaymentScheduledBySection } from '../sections/PaymentScheduledBySection';
import { SectionWrapper } from '../sections/SectionWrapper';
import { VendorReceivesSection } from '../sections/VendorReceivesSection';

export const PaymentCompleted = ({
  payment,
  billDetailsRef,
  approvalDecisions,
}: {
  payment: PaymentFullyExpanded;
  billDetailsRef: RefObject<HTMLDivElement>;
  approvalDecisions?: ApprovalDecision[];
}) => {
  const {
    vendor,
    deliveryMethod,
    timeline,
    scheduledDate,
    note,
    maxEstimatedDelivery,
    estimatedDelivery,
    markedAsPaid,
    createdBy,
    history,
    processedTimeline,
  } = payment;

  const description = usePaymentDescription(payment);

  return (
    <>
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-completed">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            <PaidToSection payment={payment} billDetailsRef={billDetailsRef} />
          </SectionWrapper>

          <SectionWrapper>
            <Group variant="vertical" hasDivider spacing="m">
              {approvalDecisions && approvalDecisions.length > 0 && (
                <PaymentApprovalDecisionSection approvalDecisions={approvalDecisions} />
              )}

              {createdBy && <PaymentScheduledBySection scheduledDate={history.createdAt} user={createdBy} />}
            </Group>
          </SectionWrapper>

          <SectionWrapper>
            {markedAsPaid ? (
              <MarkedAsPaidSection markedAsPayBy={createdBy} scheduledDate={scheduledDate} />
            ) : (
              <Group variant="vertical" spacing="m">
                <PayFromSection payment={payment} />
                <VendorReceivesSection
                  vendorName={vendor.name}
                  deliveryMethod={deliveryMethod}
                  deliveryPreference={payment.deliveryPreferenceType}
                  estimatedDelivery={estimatedDelivery}
                  maxEstimatedDelivery={maxEstimatedDelivery}
                  processedTimeline={processedTimeline}
                  memoToVendor={note}
                  timeline={timeline}
                  paymentStatus={PaymentStatusEnum.Completed}
                />
              </Group>
            )}
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
