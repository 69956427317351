import { Group, Icon, NakedButton, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionRouter } from '../../utils';
import { SectionSettingCard } from '../SectionSettingCard';

export const SubscriptionAddPaymentMethodCard = () => {
  const { formatMessage } = useMelioIntl();
  const { goToPaymentMethodSelect } = useSubscriptionRouter();

  return (
    <SectionSettingCard data-testid="subscription-payment-method-empty-card">
      <Group width="full" justifyContent="space-between" alignItems="flex-start">
        <Group alignItems="center" spacing="m">
          <Icon type="credit-card" />
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle="body2Semi" color="neutral.black">
              {formatMessage('activities.settings.subscriptionPaymentMethod.noPaymentMethodCard.title')}
            </Text>
            <Text textStyle="body4" color="neutral.darker">
              {formatMessage('activities.settings.subscriptionPaymentMethod.card.emptyDescription')}
            </Text>
          </Group>
        </Group>

        <NakedButton
          variant="secondary"
          label={formatMessage('activities.settings.subscriptionPaymentMethod.card.add')}
          onClick={goToPaymentMethodSelect}
        />
      </Group>
    </SectionSettingCard>
  );
};
