import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const AvatarDropdownMenuComponentTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    menuList: {
      paddingY: '8px',
      backgroundColor: currentTheme.colors.neutral.white,
      borderColor: currentTheme.colors.neutral.light,
      borderRadius: 'l',
      boxShadow: '500',
    },
    container: {},
    avatar: {
      cursor: 'pointer',
      transition: 'background-color 0.1s ease-in-out',
    },
  },
});
