import { Container, Group, Table, TableProps, Text, useBreakpoint } from '@melio/penny';

type APTableProps<T> = TableProps<T> & {
  captionLabel?: string;
};

export function APTable<T>({ captionLabel, ...rest }: APTableProps<T>) {
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group variant="vertical" width="full" spacing="s">
      {captionLabel && !isExtraSmallScreen ? (
        <Container paddingLeft="s">
          <Text id={rest.captionId} textStyle="body3Semi" color="neutral.darker">
            {captionLabel}
          </Text>
        </Container>
      ) : null}
      <Table {...rest} />
    </Group>
  );
}
