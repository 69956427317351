import { Button, ButtonProps, Group, Layout, LottieProps, Text, useBreakpoint } from '@melio/penny';
import { LazyAnimation } from '@melio/platform-utils';

export type ButtonPropsWithTestId = ButtonProps & {
  'data-testid'?: string;
};

export type PayDashboardEmptyStateProps = {
  title?: string;
  description: string;
  footer?: string;
  buttonProps?: ButtonPropsWithTestId[];
  customCTAComponent?: React.ReactNode;
  getAnimationData?: () => Promise<LottieProps['animation']>;
  'aria-label': string;
};

export const PayDashboardEmptyState = ({
  title,
  description,
  footer,
  buttonProps,
  getAnimationData,
  customCTAComponent,
  'aria-label': ariaLabel,
}: PayDashboardEmptyStateProps) => {
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();

  return (
    <Layout
      variant="6Columns"
      paddingContent={isExtraSmallScreen ? 'm' : 'none'}
      data-testid="pay-dashboard-empty-state"
      aria-live="assertive"
      aria-label={ariaLabel}
    >
      <Group spacing="none" alignItems="center" variant="vertical" textAlign="center">
        {getAnimationData && (
          <LazyAnimation
            id="pay-dashboard-empty-state-animation"
            getAnimationData={getAnimationData}
            loop
            height="100%"
            width={isExtraSmallScreen || isSmallScreen ? '100%' : undefined}
          />
        )}
        <Group spacing="m" alignItems="center" variant="vertical">
          {title && (
            <Text textStyle="heading3Semi" data-testid="pay-dashboard-empty-state-title">
              {title}
            </Text>
          )}
          <Text data-testid="pay-dashboard-empty-state-description">{description}</Text>
          {footer && (
            <Text data-testid="pay-dashboard-empty-state-footer" textStyle="body3">
              {footer}
            </Text>
          )}
          {buttonProps && buttonProps.length > 0 && (
            <Group>
              {customCTAComponent}
              {buttonProps.map((buttonProp, index) => (
                <Button key={index} variant="primary" isFullWidth={isExtraSmallScreen} {...buttonProp} />
              ))}
            </Group>
          )}
        </Group>
      </Group>
    </Layout>
  );
};
