import React from 'react';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { Link, SectionBanner, Text, useTheme } from '@melio/penny';
import { ScannedInvoice } from '@melio/platform-api';

import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { ScannedInvoiceDetailsForm } from './ScannedInvoiceDetailsForm';
import { ScannedInvoiceFile } from './ScannedInvoiceFile';

type ScannedInvoiceDetailsScreenProps = {
  scannedInvoice: ScannedInvoice;
};

export const ScannedInvoiceDetailsBody = ({ scannedInvoice }: ScannedInvoiceDetailsScreenProps) => {
  const { formatMessage } = usePlatformIntl();
  const theme = useTheme();
  const {
    partnerConfig: { billsInbox },
  } = usePartnerConfig();
  const formDefaultValues = React.useMemo(
    () => ({
      vendorName: scannedInvoice.vendorName || '',
      totalAmount: scannedInvoice.amount ? scannedInvoice.amount.toString() : undefined,
      dueDate: scannedInvoice.dueDate ? new Date(scannedInvoice.dueDate) : null,
      invoiceNumber: scannedInvoice.invoiceNumber || '',
      noteToSelf: scannedInvoice.note || '',
    }),
    [scannedInvoice],
  );
  const { invoiceNumber, dueDate, amount, vendorName } = scannedInvoice;
  const missingDetails = !invoiceNumber || !dueDate || !amount || !vendorName;

  return (
    <Box border={`1px solid ${theme.colors.neutral.light}`} borderRadius={'8px'} overflow="hidden">
      <Stack flexDirection="column" p="32px 40px" gridGap="24px" spacing={0}>
        <SectionBanner
          variant={'warning'}
          title={formatMessage(
            missingDetails
              ? 'widgets.scannedInvoiceDetailsScreen.sectionBanner.missingDetails.title'
              : 'widgets.scannedInvoiceDetailsScreen.sectionBanner.title',
          )}
          description={formatMessage(
            missingDetails
              ? 'widgets.scannedInvoiceDetailsScreen.sectionBanner.missingDetails.description'
              : 'widgets.scannedInvoiceDetailsScreen.sectionBanner.description',
            {
              learnMoreLink: billsInbox?.learnMoreLink ? (
                <Link
                  variant="inline"
                  href={billsInbox?.learnMoreLink}
                  label={formatMessage('widgets.scannedInvoiceDetailsScreen.sectionBanner.learnMoreLink.text')}
                  newTab
                />
              ) : null,
            },
          )}
        />
        <HStack alignItems={'center'} width={'full'} gridGap={'4px'}>
          <Text textStyle="heading2Semi">{formatMessage('widgets.scannedInvoiceDetailsScreen.title')}</Text>
        </HStack>
        <Stack gridGap={'32px'} alignItems="flex-start" direction={{ xs: 'column-reverse', s: 'row' }}>
          <ScannedInvoiceDetailsForm
            defaultValues={formDefaultValues}
            onSubmissionStateChange={() => {}}
            onSubmit={() => {}}
          />
          <ScannedInvoiceFile scannedInvoiceFile={scannedInvoice.file} />
        </Stack>
      </Stack>
    </Box>
  );
};
