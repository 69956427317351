import { Box } from '@chakra-ui/react';
import { getAccountingPlatformNameForAnalytics } from '@melio/ap-domain';
import { AccountingPlatformCard } from '@melio/ap-widgets';
import { Group, Modal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  accountingPlatforms: AccountingPlatform[];
  onClose: VoidFunction;
  onConnect: (accountingSlug: AccountingPlatformSlug) => void;
};

export const AccountingSoftwareConnectModal = ({
  isOpen,
  isLoading,
  accountingPlatforms,
  onClose,
  onConnect,
}: Props) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [selectedPlatformToConnect, setSelectedPlatformToConnect] = useState<AccountingPlatformSlug>();
  const [platformXeroAccountingSoftwareEnabled] = useFeature(
    FeatureFlags.PlatformXeroAccountingSoftwareEnabled,
    false,
    {
      shouldTrack: true,
    }
  );
  const quickBooksAccountingPlatform = accountingPlatforms.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksOnline
  );
  const quickBooksDesktopAccountingPlatform = accountingPlatforms.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop
  );
  const xeroAccountingPlatform = accountingPlatforms.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.Xero
  );

  const accountingPlatformsToShow = [];

  if (quickBooksAccountingPlatform) {
    accountingPlatformsToShow.push(quickBooksAccountingPlatform);
  }

  if (quickBooksDesktopAccountingPlatform) {
    accountingPlatformsToShow.push(quickBooksDesktopAccountingPlatform);
  }

  if (platformXeroAccountingSoftwareEnabled && xeroAccountingPlatform) {
    accountingPlatformsToShow.push(xeroAccountingPlatform);
  }

  useEffect(() => {
    track('Bill', 'View', { PageName: 'sync-with-accounting-software', Flow: 'add-bill', Intent: 'sync' });
  }, [track]);

  useEffect(() => {
    if (!isLoading) {
      setSelectedPlatformToConnect(undefined);
    }
  }, [isLoading]);

  const handleAccountingPlatformConnect = (accountingSlug: AccountingPlatformSlug) => {
    setSelectedPlatformToConnect(accountingSlug);
    track('Bill', 'Click', {
      PageName: 'sync-with-accounting-software',
      Flow: 'add-bill',
      Intent: 'sync',
      Cta: `connect-${getAccountingPlatformNameForAnalytics(accountingSlug)}`,
    });

    onConnect(accountingSlug);
  };

  const handleClose = () => {
    track('Bill', 'Click', {
      PageName: 'sync-with-accounting-software',
      Flow: 'add-bill',
      Intent: 'sync',
      Cta: 'exit',
    });

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      header={formatMessage(`activities.payDashboard.billsTab.accountingSoftwareConnectModal.title`)}
      description={formatMessage(`activities.payDashboard.billsTab.accountingSoftwareConnectModal.description`)}
      onClose={handleClose}
      data-testid="accounting-software-connect-modal"
    >
      <Box marginY="s">
        <Group variant="vertical" spacing="s">
          {accountingPlatformsToShow.map((accountingPlatform) => (
            <AccountingPlatformCard
              key={accountingPlatform.id}
              accountingPlatform={accountingPlatform}
              isLoading={isLoading && accountingPlatform.accountingSlug === selectedPlatformToConnect}
              isDisabled={isLoading && accountingPlatform.accountingSlug !== selectedPlatformToConnect}
              onConnect={() => handleAccountingPlatformConnect(accountingPlatform.accountingSlug)}
              shouldShowRightArrow
            />
          ))}
        </Group>
      </Box>
    </Modal>
  );
};
