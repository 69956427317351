import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useCallback, useMemo } from 'react';

import {
  AddBillV2DFormInitialValues,
  AddBillV2FormFrequency,
  AddBillV2FormValues,
  AddBillV2RecurringEndBy,
  BaseLineItem,
  BillLineItemType,
  CategoryBasedBillLineItem,
  ItemBasedBillLineItem,
  XeroSyncedBillLineItem,
} from '../types';
import { createEmptyCategoryLineItem, createEmptyUnsyncedLineItem, createEmptyXeroSyncedLineItem } from '../utils';

type UseDefaultFormValuesResult = {
  defaultFormValues: AddBillV2FormValues;
  getDefaultFormValues: (initialValues?: AddBillV2DFormInitialValues) => AddBillV2FormValues;
};

export const useDefaultFormValues = ({
  values,
  hasAccountingPlatform,
  hasAccountingPlatformItems,
  activeAccountingPlatform,
}: {
  values?: AddBillV2DFormInitialValues;
  hasAccountingPlatform: boolean;
  hasAccountingPlatformItems: boolean;
  activeAccountingPlatform?: AccountingPlatform;
}): UseDefaultFormValuesResult => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();

  const getDefaultFormValues = useCallback(
    (initialValues?: AddBillV2DFormInitialValues) => {
      let noteToSelfValue = initialValues?.noteToSelf;
      if (!noteToSelfValue && hasAccountingPlatform && !initialValues?.id) {
        noteToSelfValue = formatMessage('activities.addBillV2.billForm.noteToSelf.default');
      }

      const defaultValues: AddBillV2FormValues = {
        amount: initialValues?.amount ?? '',
        dueDate: initialValues?.dueDate,
        vendorId: initialValues?.vendorId ?? '',
        categoryId: initialValues?.categoryId,
        noteToSelf: noteToSelfValue ?? '',
        invoiceNumber: initialValues?.invoiceNumber ?? '',
        invoiceDate: initialValues?.invoiceDate,
        frequency: AddBillV2FormFrequency.ONE_TIME,
        currency: initialValues?.currency,
        recurringEndBy: isRecurringPaymentImprovementsEnabled
          ? AddBillV2RecurringEndBy.NO_END_DATE
          : AddBillV2RecurringEndBy.DATE,
        recurringOccurrences: '',
        externalLabelId: initialValues?.externalLabelId,
      };

      let unsyncedLineItems: BaseLineItem[] = [];
      let itemBasedLineItems: ItemBasedBillLineItem[] = [];
      let categoryBasedLineItems: CategoryBasedBillLineItem[] = [];
      let xeroSyncedLineItems: XeroSyncedBillLineItem[] = [];

      if (!hasAccountingPlatform) {
        unsyncedLineItems = initialValues?.lineItems?.length
          ? (initialValues.lineItems.filter((lineItem) => lineItem.amount !== null) as BaseLineItem[])
          : [createEmptyUnsyncedLineItem({ amount: initialValues?.amount || '' })];
      } else if (activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero) {
        if (initialValues?.lineItems) {
          xeroSyncedLineItems = initialValues.lineItems as XeroSyncedBillLineItem[];
        }
        if (!xeroSyncedLineItems.length) {
          xeroSyncedLineItems = [
            createEmptyXeroSyncedLineItem({
              amount: initialValues?.amount || '',
              externalCategoryId: initialValues?.categoryId || null,
            }),
          ];
        }
      } else {
        if (initialValues?.lineItems) {
          categoryBasedLineItems = initialValues.lineItems.filter(
            (lineItem): lineItem is CategoryBasedBillLineItem => lineItem.type === BillLineItemType.CATEGORY
          );
          itemBasedLineItems = initialValues.lineItems.filter(
            (lineItem): lineItem is ItemBasedBillLineItem => lineItem.type === BillLineItemType.ITEM
          );
          if (!categoryBasedLineItems.length && !itemBasedLineItems.length) {
            categoryBasedLineItems = [
              createEmptyCategoryLineItem({
                amount: initialValues?.amount || '',
                externalCategoryId: initialValues?.categoryId || null,
              }),
            ];
          }
        } else {
          if (hasAccountingPlatformItems) {
            itemBasedLineItems = [];
          }
          categoryBasedLineItems = [
            createEmptyCategoryLineItem({
              amount: initialValues?.amount || '',
              externalCategoryId: initialValues?.categoryId || null,
            }),
          ];
        }
      }

      return {
        ...defaultValues,
        nonSyncedLineItems: unsyncedLineItems.length ? unsyncedLineItems : [],
        categoryBasedLineItems: categoryBasedLineItems.length ? categoryBasedLineItems : [],
        xeroSyncedLineItems: xeroSyncedLineItems.length ? xeroSyncedLineItems : [],
        itemBasedLineItems: itemBasedLineItems.length ? itemBasedLineItems : [],
      };
    },
    [
      formatMessage,
      hasAccountingPlatform,
      activeAccountingPlatform,
      hasAccountingPlatformItems,
      isRecurringPaymentImprovementsEnabled,
    ]
  );

  return {
    defaultFormValues: useMemo(() => getDefaultFormValues(values), [values, getDefaultFormValues]),
    getDefaultFormValues,
  };
};
