import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { FormWidgetProps } from '../../types';
import { ACCOUNT_NUMBER_MAX_LENGTH, ACCOUNT_NUMBER_MIN_LENGTH, ROUTING_NUMBER_LENGTH } from './util/bank-account-util';
import { BankAccountFormModel, useAchValidationSchema } from './util/use-bank-account-validation-schema';

type BankDetailsFormProps = FormWidgetProps<BankAccountFormModel>;

export const BankDetailsForm = forwardRef<BankDetailsFormProps, 'form'>(
  ({ defaultValues, onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<BankAccountFormModel>({
      onSubmit,
      schema: useAchValidationSchema(),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    return (
      <Form data-component="BankDetailsForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          type="number"
          labelProps={{ label: formatMessage('widgets.bankDetailsForm.fields.routingNumber.label') }}
          placeholder={formatMessage('widgets.bankDetailsForm.fields.routingNumber.placeholder', {
            length: ROUTING_NUMBER_LENGTH,
          })}
          {...registerField('routingNumber')}
        />
        <Form.SecuredTextField
          type="number"
          getToggleVisibilityAriaLabel={(isVisible) =>
            formatMessage('widgets.bankDetailsForm.fields.routingNumber.aria.visibility', { isVisible })
          }
          labelProps={{ label: formatMessage('widgets.bankDetailsForm.fields.accountNumber.label') }}
          placeholder={formatMessage('widgets.bankDetailsForm.fields.accountNumber.placeholder', {
            minLength: ACCOUNT_NUMBER_MIN_LENGTH,
            maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
          })}
          {...registerField('accountNumber')}
        />
      </Form>
    );
  }
);

BankDetailsForm.displayName = 'BankDetailsForm';
