import { Box } from '@chakra-ui/react';
import { Button, Group, Text, useBreakpoint, useTheme } from '@melio/penny';
import { FxCurrency } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum, SummaryTypeEnum } from '@/types/vendors.types';
import { PaymentSummary } from './components/PaymentSummary.component';

type PaymentsOverviewCardProps = {
  vendorName: string;
  vendorBusinessGeography: 'international' | 'domestic' | null;
  linkToUnpaid?: string;
  unpaidSum?: number;
  unpaidCount?: number;
  linkToScheduled?: string;
  scheduledSum?: number;
  scheduledCount?: number;
  linkToPaid?: string;
  paidSum?: number;
  paidCount?: number;
  unpaidBillsCount?: number;
  onPayUnpaidBillsClick?: () => void;
  onLinkClick?: () => void;
  isInternationalSupportedForBusiness?: boolean;
  currency: FxCurrency | null;
};

export const PaymentsOverviewCard = ({
  linkToUnpaid = '',
  unpaidCount = 0,
  unpaidSum = 0,
  linkToScheduled = '',
  scheduledCount = 0,
  scheduledSum = 0,
  linkToPaid = '',
  paidCount = 0,
  paidSum = 0,
  unpaidBillsCount = 0,
  onPayUnpaidBillsClick,
  onLinkClick,
  vendorName,
  vendorBusinessGeography,
  isInternationalSupportedForBusiness,
  currency,
}: PaymentsOverviewCardProps) => {
  const { formatMessage } = usePlatformIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const theme = useTheme();
  const [isPayUnpaidBillsEnabled] = useFeature(FeatureFlags.PayUnpaidBills, false);

  const isPayUnpaidBillsForVendorDisabled =
    vendorBusinessGeography === 'international' ? !isInternationalSupportedForBusiness : false;
  return (
    <Box
      data-testid="payments-overview-card"
      border={`1px solid ${theme.colors.neutral.light}`}
      borderRadius={'8px'}
      padding={isExtraSmallScreen ? '16px' : '32px 40px'}
      data-component={DataComponentEnum.PAYMENT_OVERVIEW}
    >
      <Group variant="vertical" spacing="m">
        <Group justifyContent="space-between">
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage('widgets.paymentOverview.title')}
          </Text>
          {isPayUnpaidBillsEnabled && !isExtraSmallScreen && unpaidBillsCount > 0 && (
            <Button
              data-testid="pay-unpaid-bills-btn"
              onClick={onPayUnpaidBillsClick}
              variant="success"
              isDisabled={isPayUnpaidBillsForVendorDisabled}
              label={
                unpaidBillsCount > 1
                  ? formatMessage('widgets.paymentOverview.payUnpaidMultipleBillsButton')
                  : formatMessage('widgets.paymentOverview.payUnpaidSingleBillButton')
              }
            />
          )}
        </Group>
        <Group spacing="m" hasDivider={!isExtraSmallScreen} variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}>
          <PaymentSummary
            summaryType={SummaryTypeEnum.OPEN_BALANCE}
            sum={unpaidSum}
            count={unpaidCount}
            vendorName={vendorName}
            link={linkToUnpaid}
            onLinkClick={onLinkClick}
            currency={currency}
          />
          <PaymentSummary
            summaryType={SummaryTypeEnum.SCHEDULED}
            sum={scheduledSum}
            count={scheduledCount}
            link={linkToScheduled}
            currency={currency}
          />
          <PaymentSummary
            summaryType={SummaryTypeEnum.PAID}
            sum={paidSum}
            count={paidCount}
            link={linkToPaid}
            currency={currency}
          />
        </Group>
      </Group>
    </Box>
  );
};

PaymentsOverviewCard.displayName = 'PaymentsOverviewCard';
