import { useMelioIntl } from '@melio/ar-domain';
import { Modal } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type PaymentOptionsConfirmationScreenProps = {
  isOpen: boolean;
  isSaving: boolean;
  onSubmit: VoidFunction;
  onClose: VoidFunction;
  onCloseModal: VoidFunction;
};

export const PaymentOptionsConfirmationScreen = forwardRef<PaymentOptionsConfirmationScreenProps>(
  ({ isOpen, onCloseModal, onSubmit, onClose, isSaving }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Modal
        isOpen={isOpen}
        onClose={() => !isSaving && onCloseModal()}
        primaryButton={{
          variant: 'primary',
          isLoading: isSaving,
          label: formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.PaymentOptionsConfirmation.buttons.primary'
          ),
          onClick: onSubmit,
        }}
        secondaryButton={{
          variant: 'tertiary',
          label: formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.PaymentOptionsConfirmation.buttons.secondary'
          ),
          isDisabled: isSaving,
          onClick: onClose,
        }}
        data-testid="payment-options-confirmation-modal"
        data-component={PaymentOptionsConfirmationScreen.displayName}
        header={formatMessage('ar.invoiceLifecycle.activities.createInvoice.PaymentOptionsConfirmation.header')}
        description={formatMessage(
          'ar.invoiceLifecycle.activities.createInvoice.PaymentOptionsConfirmation.description'
        )}
        ref={ref}
      />
    );
  }
);

PaymentOptionsConfirmationScreen.displayName = 'PaymentOptionsConfirmationModal';
