import { useCustomer, useInvoiceExpanded, useInvoiceTimeline } from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { InvoiceActionsHandlers } from '../../utils';
import { InvoiceDrawerScreen } from './screens';

export type InvoiceDrawerActivityProps = {
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  invoiceId: string;
  onDeleteInvoice: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  onPreviewInvoice: (id: string) => unknown;
  onUpdateMarkAsPaid: (id: string) => unknown;
  onMarkInvoiceAsUnpaid: (id: string) => unknown;
  onCancelInvoice: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onDownloadInvoice: (fileId: string) => unknown;
  onIssueInvoice: (fileId: string) => unknown;
  onCopyLink: (link: string) => unknown;
  onMarkInvoiceAsPaid: (id: string) => unknown;
};

export const InvoiceDrawerActivity = withAnalyticsContext<InvoiceDrawerActivityProps>(
  forwardRef(
    (
      {
        setAnalyticsProperties,
        onDeleteInvoice,
        onSendReminder,
        onPreviewInvoice,
        onUpdateMarkAsPaid,
        onCancelInvoice,
        onEditInvoice,
        onDownloadInvoice,
        onCopyLink,
        onIssueInvoice,
        onMarkInvoiceAsUnpaid,
        onMarkInvoiceAsPaid,
        invoiceId,
        onClose,
        onError,
        ...props
      },
      ref
    ) => {
      const { data: expandedInvoice, isLoading: isFetchingInvoice } = useInvoiceExpanded({ id: invoiceId, onError });
      const { data: invoiceTimline, isLoading: isLoadingTimeline } = useInvoiceTimeline({ id: invoiceId, onError });
      const { data: customer, isLoading: isLoadingCustomers } = useCustomer({
        id: expandedInvoice?.customerId,
        onError,
      });
      const [isClosing, setIsClosing] = useState(false);
      const handleCloseDrawer = () => {
        setIsClosing(true);
      };

      const invoiceActions: InvoiceActionsHandlers = {
        onSendReminder,
        onDeleteInvoice,
        onPreviewInvoice,
        onDownloadInvoice,
        onCopyLink,
        onCancelInvoice,
        onEditInvoice,
        onUpdateMarkAsPaid,
        onIssueInvoice,
        onMarkInvoiceAsUnpaid,
        onMarkInvoiceAsPaid,
      };

      setAnalyticsProperties({
        InvoiceId: expandedInvoice?.id,
        InvoiceStatus: expandedInvoice?.displayStatus,
        DueDate: expandedInvoice?.dueDate,
      });

      return (
        <InvoiceDrawerScreen
          isOpen={!isClosing && (!!expandedInvoice || isFetchingInvoice)}
          onCloseButtonClick={handleCloseDrawer}
          onClose={onClose}
          isLoading={isFetchingInvoice || isLoadingCustomers || isLoadingTimeline}
          customer={customer}
          expandedInvoice={expandedInvoice}
          invoiceActions={invoiceActions}
          invoiceTimeline={invoiceTimline}
          ref={ref}
          {...props}
        />
      );
    }
  )
);

InvoiceDrawerActivity.displayName = 'InvoiceDrawerScreen';
