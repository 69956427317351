import { Text } from '@chakra-ui/react';
import { Badge, Card, Group, Icon, Image, LoadingContainer, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BillProvider } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useProviderDisplayProps } from '../hooks/useProviderDisplayProps';

type Props = {
  provider: BillProvider;
};

export const ModalCard = ({ provider }: Props) => {
  const { track } = useAnalytics();
  const { formatMessage, formatDate } = useMelioIntl();
  const { redirectURL, isLoading, displayName, logoSVG } = useProviderDisplayProps(provider);
  const [isConnecting, setIsConnecting] = useState(false);

  const isConnected = provider.isConnected;
  const formattedDateTime = provider.lastCompletionTime
    ? formatDate(provider.lastCompletionTime, { timeStyle: 'medium', dateStyle: 'full' })
    : '';

  const label = isConnected
    ? formatMessage('activities.ebillsImport.modal.lastSyncStatus', { date: formattedDateTime })
    : formatMessage(`activities.eBillsImport.modal.providers.${provider.name}.description`);

  const cta = isConnected ? displayName : formatMessage('activities.ebillsImport.modal.cta', { provider: displayName });

  const onConnect = async () => {
    track('Bill', 'Click', {
      PageName: 'import-bills-automatically',
      Flow: 'dashboard',
      Intent: 'bill-import-connect-account',
      Cta: `connect-${provider.name}`,
      EntryPoint: ' dashboard-bills',
    });
    setIsConnecting(true);
    try {
      await provider.connect(redirectURL);
    } catch (error) {
      track('Bill', 'Status', {
        PageName: 'import-bills-automatically',
        Flow: 'dashboard',
        Intent: 'bill-import-connect-account',
        EntryPoint: 'dashboard-bills',
        BillImportAccountType: provider.name,
        Status: 'failure',
        Reason: (error as Error).message,
      });
      setIsConnecting(false);
    }
  };

  return (
    <Card variant="flat" isDisabled={isConnected} width="full" onClick={onConnect}>
      <LoadingContainer isLoading={isLoading || isConnecting}>
        <Group variant="horizontal" alignItems="center">
          <Image alt={`${displayName} Logo`} width="25px" src={logoSVG} />
          <Group variant="vertical" spacing="none">
            <Group variant="horizontal" spacing="xs" alignItems="center">
              <Text textStyle="body2Semi">{cta}</Text>
              {isConnected && <Badge label="Connected" status="success" />}
            </Group>
            <Typography.Description label={label} />
          </Group>
          <Icon type="chevron-right" />
        </Group>
      </LoadingContainer>
    </Card>
  );
};
