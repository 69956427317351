import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CompanyIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      d="M18 0C18.5128 0 18.9355 0.38604 18.9933 0.883379L19 1V6C19 6.55228 18.5523 7 18 7C17.4872 7 17.0645 6.61396 17.0067 6.11662L17 6V2H7V9C7 9.51284 6.61396 9.93551 6.11662 9.99327L6 10C5.48716 10 5.06449 9.61396 5.00673 9.11662L5 9V1C5 0.487164 5.38604 0.0644928 5.88338 0.00672773L6 0H18Z"
      fill="#212124"
    />
    <path
      d="M6 18C6.55228 18 7 18.4477 7 19C7 19.5128 6.61396 19.9355 6.11662 19.9933L6 20H5C4.44772 20 4 19.5523 4 19C4 18.4872 4.38604 18.0645 4.88338 18.0067L5 18H6Z"
      fill="#212124"
    />
    <path
      d="M7 16C7 15.4477 6.55228 15 6 15H5L4.88338 15.0067C4.38604 15.0645 4 15.4872 4 16C4 16.5523 4.44772 17 5 17H6L6.11662 16.9933C6.61396 16.9355 7 16.5128 7 16Z"
      fill="#212124"
    />
    <path
      d="M20 13C20 12.4477 19.5523 12 19 12H18L17.8834 12.0067C17.386 12.0645 17 12.4872 17 13C17 13.5523 17.4477 14 18 14H19L19.1166 13.9933C19.614 13.9355 20 13.5128 20 13Z"
      fill="#212124"
    />
    <path
      d="M19 15C19.5523 15 20 15.4477 20 16C20 16.5128 19.614 16.9355 19.1166 16.9933L19 17H18C17.4477 17 17 16.5523 17 16C17 15.4872 17.386 15.0645 17.8834 15.0067L18 15H19Z"
      fill="#212124"
    />
    <path
      d="M20 19C20 18.4477 19.5523 18 19 18H18L17.8834 18.0067C17.386 18.0645 17 18.4872 17 19C17 19.5523 17.4477 20 18 20H19L19.1166 19.9933C19.614 19.9355 20 19.5128 20 19Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 22H13V9C13 8.44771 13.4477 8 14 8H23C23.5523 8 24 8.44771 24 9V23C24 23.5523 23.5523 24 23 24H1C0.447715 24 0 23.5523 0 23V12C0 11.4477 0.447715 11 1 11H10C10.5523 11 11 11.4477 11 12V22ZM9 13V22H2V13H9ZM22 10H15V22H22V10Z"
      fill="#212124"
    />
  </Icon>
);
