import { Link } from '@melio/penny';
import { PaymentFullyExpanded, useBill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { MouseEventHandler, RefObject } from 'react';

import { SectionBlock } from './SectionBlock';

type Props = { payment: PaymentFullyExpanded; billDetailsRef: RefObject<HTMLDivElement> };
export const PaidToSection = ({ payment, billDetailsRef }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { data: bill } = useBill({
    id: payment.paymentBillsInfo?.[0]?.id,
    enabled: !!payment.paymentBillsInfo?.[0]?.id,
  });

  const vendorName = payment.vendor.name;
  const vendorDeletedDate = payment.vendor?.history?.deletedAt;

  const invoiceNumber = bill?.invoice.number ?? null;
  return (
    <SectionBlock
      title={{ label: formatMessage('widgets.paymentDetails.paidTo.title') }}
      body={{
        label: vendorName,
        ...(vendorDeletedDate && { badgeProps: { type: 'secondary', label: 'Deleted', status: 'neutral' } }),
      }}
      description={{
        label: invoiceNumber
          ? formatMessage('widgets.paymentDetails.paidTo.description.full', {
              invoiceNumber,
              link: <BillDetailsLink billDetailsRef={billDetailsRef} />,
            })
          : formatMessage('widgets.paymentDetails.paidTo.description.empty', {
              link: <BillDetailsLink billDetailsRef={billDetailsRef} />,
            }),
      }}
    />
  );
};

function BillDetailsLink({ billDetailsRef }: Pick<Props, 'billDetailsRef'>) {
  const { formatMessage } = useMelioIntl();

  const handleClick: MouseEventHandler<HTMLLinkElement> = (e) => {
    e.preventDefault();
    billDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Link
      href="#"
      size="medium"
      color="secondary"
      onClick={handleClick}
      label={formatMessage('widgets.paymentDetails.paidTo.description.link')}
    />
  );
}
