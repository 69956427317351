import { useRoleText } from '@melio/ap-domain';
import { Badge, Card, Container, Form, Group, SectionBanner, Text, Typography } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { useFormContext } from '../../../FormContext';
import { FormFieldsSettings } from '../../../types';
import { useAllowedActions } from '../../../useAllowedActions';

type Props = { formFieldsSettings: Pick<FormFieldsSettings, 'roleUniqueName'> };
export const RolesSection = ({ formFieldsSettings }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { registerField, getValues, formState } = useFormContext();
  const { getRoleText } = useRoleText();
  const {
    settings: {
      collaborator: { showRoleTransferDisclaimer },
    },
  } = useConfig();
  const { canEditCollaborator, rolesPermittedToInvite } = useAllowedActions();

  const roleUniqueNameField = registerField('roleUniqueName');

  const isReadonly = formFieldsSettings.roleUniqueName.readonly;
  return (
    <Container overflow="initial">
      <Group
        variant="vertical"
        spacing="m"
        role="radiogroup"
        aria-label={formatMessage('activities.collaboratorDrawer.body.rolesSection.heading')}
      >
        <Group.Item>
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('activities.collaboratorDrawer.body.rolesSection.heading')}
          </Text>
        </Group.Item>

        {isReadonly && canEditCollaborator({ allowAnySubject: true }) && showRoleTransferDisclaimer ? (
          <Group.Item>
            <SectionBanner
              data-testid="roles-section-transfer-disclaimer"
              variant="neutral"
              icon="info-fill"
              description={formatMessage(
                'activities.collaboratorDrawer.body.rolesSection.transferDisclaimer.description',
                { roleUniqueName: getRoleText(formState.defaultValues?.roleUniqueName ?? '') }
              )}
            />
          </Group.Item>
        ) : null}

        <Group.Item>
          {isReadonly ? (
            <Card
              variant="flat"
              paddingX="s"
              paddingY="s"
              data-testid={`roles-section-card-${getValues('roleUniqueName')}`}
            >
              <Group variant="vertical" spacing="xxs">
                <Group.Item>
                  <Group alignItems="center" spacing="xxs">
                    <Group.Item>
                      <Typography.MainLabel label={getRoleText(getValues('roleUniqueName'))} variant="bold" />
                    </Group.Item>

                    <Group.Item>
                      <Badge
                        type="secondary"
                        status="informative"
                        label={formatMessage('activities.collaboratorDrawer.body.rolesSection.activeIndication')}
                      />
                    </Group.Item>
                  </Group>
                </Group.Item>

                <Group.Item>
                  <Typography.Description
                    label={formatMessage(
                      `activities.collaboratorDrawer.body.rolesSection.${getValues('roleUniqueName')}` as MessageKey
                    )}
                  />
                </Group.Item>
              </Group>
            </Card>
          ) : (
            <Group variant="vertical" spacing="m">
              {rolesPermittedToInvite.map((value) => (
                <Card variant="flat" paddingX="s" paddingY="s" key={value} data-testid={`roles-section-card-${value}`}>
                  <Form.RadioGroup
                    {...roleUniqueNameField}
                    role={undefined}
                    padding="none"
                    options={[
                      {
                        value,
                        mainLabelProps: {
                          label: getRoleText(value),
                          badgeProps:
                            formState.defaultValues?.roleUniqueName === value
                              ? {
                                  label: formatMessage(
                                    'activities.collaboratorDrawer.body.rolesSection.activeIndication'
                                  ),
                                  status: 'informative',
                                  type: 'secondary',
                                }
                              : undefined,
                        },
                        descriptionProps: {
                          label: formatMessage(
                            `activities.collaboratorDrawer.body.rolesSection.${value}` as MessageKey
                          ),
                        },
                      },
                    ]}
                  />
                </Card>
              ))}
            </Group>
          )}
        </Group.Item>
      </Group>
    </Container>
  );
};
