import { Table, Text } from '@melio/penny';
import { Bill, Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { getTotalBillsBalance } from '../utils';
import { VendorCurrencyTooltip } from './VendorCurrencyTooltip';
import { VendorsTabAmountInputCell } from './VendorsTabAmountInputCell';

export type VendorsTabOpenBalanceCellProps = {
  vendor: Vendor;
  unpaidBills: Bill[];
  value?: string;
  onAmountChange: (vendor: Vendor, value: string) => void;
};
export const VendorsTabOpenBalanceCell = ({
  vendor,
  unpaidBills,
  value,
  onAmountChange,
}: VendorsTabOpenBalanceCellProps) => {
  const { formatCurrency } = useMelioIntl();
  const [isVendorsBatchPaymentsEnabled] = useFeature<boolean>(
    FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled,
    false,
    {
      shouldTrack: true,
    }
  );

  const openBalance = getTotalBillsBalance(unpaidBills);
  return unpaidBills.length === 0 && isVendorsBatchPaymentsEnabled ? (
    <VendorsTabAmountInputCell value={value} vendor={vendor} onAmountChange={onAmountChange} />
  ) : (
    <Table.Cell data-testid={`vendors-tab-row-${vendor.id}-open-balance-cell`} textAlign="end">
      <VendorCurrencyTooltip vendor={vendor}>
        <Text
          textStyle="body3Semi"
          color={openBalance === 0 ? 'neutral.darker' : 'neutral.black'}
          data-testid={`vendors-tab-row-${vendor.id}-open-balance-cell-text`}
        >
          {formatCurrency(openBalance, vendor.currency ?? undefined)}
        </Text>
      </VendorCurrencyTooltip>
    </Table.Cell>
  );
};
