import { useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { AccountingPlatformInitQuickBooksDesktopResponseData } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { ConnectToQuickBooksDesktopScreen } from '../screens/ConnectToQuickBooksDesktop';

type Props = {
  isError: boolean;
  isLoading: boolean;
  isVerifyLoading: boolean;
  initQuickBooksDesktopData?: AccountingPlatformInitQuickBooksDesktopResponseData;
  onCancel: VoidFunction;
  onVerifyConnection: () => Promise<void>;
  initQuickBooksDesktop: () => Promise<void>;
  onDownloadWebConnector: VoidFunction;
};

export const ConnectToQuickBooksDesktopStep = ({
  isError,
  isLoading,
  isVerifyLoading,
  initQuickBooksDesktopData,
  onCancel,
  onVerifyConnection,
  initQuickBooksDesktop,
  onDownloadWebConnector,
}: Props) => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const { createTrackHandler } = useAnalytics();
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'cancel' | 'verify' | 'download-web-connector' | 'copy-password';
  }>('SyncAccountingSoftware', 'Click');

  useAnalyticsView('ConnectToQuickBooksDesktop');

  useEffect(() => {
    if (!initQuickBooksDesktopData) {
      initQuickBooksDesktop();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initQuickBooksDesktopData]);

  const onCancelClick = () => {
    trackAndHandleClick({ Cta: 'cancel' }, onCancel);
  };

  const onSubmitClick = async () => {
    trackAndHandleClick({ Cta: 'verify' }, await onVerifyConnection());
  };

  const onDownloadWebConnectorClick = () => {
    trackAndHandleClick({ Cta: 'download-web-connector' }, onDownloadWebConnector());
  };

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast({
          type: 'informative',
          title: formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.copyPassword.toast.success'
          ),
        });
      })
      .catch(() =>
        toast({
          type: 'error',
          title: formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.copyPassword.toast.error'
          ),
        })
      );
  };

  const onCopyPassword = (value?: string) => {
    if (value) {
      trackAndHandleClick({ Cta: 'copy-password' }, handleCopyToClipboard(value));
    }
  };

  return (
    <ConnectToQuickBooksDesktopScreen
      isError={isError}
      isLoading={isLoading}
      isVerifyLoading={isVerifyLoading}
      initQuickBooksDesktopData={initQuickBooksDesktopData}
      onCancel={onCancelClick}
      onCopyPassword={onCopyPassword}
      onVerifyConnection={onSubmitClick}
      onDownloadWebConnector={onDownloadWebConnectorClick}
    />
  );
};
