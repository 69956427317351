import { isCollaboratorBlocked, useRoleText } from '@melio/ap-domain';
import { Badge, Table, Text, Tooltip } from '@melio/penny';
import { Collaborator } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

type Props = { actor: Collaborator<'user'>; collaborator: Collaborator<'user'> };
export const RoleCell = ({ actor, collaborator }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { getRoleText } = useRoleText();

  return (
    <Table.Cell data-testid={`collaborators-table-row-${collaborator.id}-role-cell`}>
      {collaborator.roleUniqueName && !isCollaboratorBlocked(collaborator) ? (
        <Tooltip
          label={formatMessage(
            `activities.settings.collaborators.table.cells.role.tooltip.${collaborator.roleUniqueName}` as MessageKey
          )}
        >
          <Badge
            type={collaborator.id === actor.id ? 'primary' : 'secondary'}
            status="neutral"
            label={getRoleText(collaborator.roleUniqueName)}
          />
        </Tooltip>
      ) : (
        <Text textStyle="body3" color="neutral.700">
          {formatMessage('activities.settings.collaborators.table.cells.role.inactive')}
        </Text>
      )}
    </Table.Cell>
  );
};
