import { Group, Text, useMelioForm, useToast } from '@melio/penny';
import { useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethod, InternationalAccountDeliveryMethod, useDeliveryMethods } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { defaults } from 'lodash';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { CreationBadRequestBanner } from '../../components';
import { FxBankDetailsForm, useVendorAccountFormSchema } from './fxBankDetailsForm';
import { getCountriesOptions, getRequireFieldsForBankDetails } from './fxBankDetailsForm/fxBankDetailsForm.utils';
import { FxVendorAccountFieldsFormFields } from './fxBankDetailsForm/types';
import { useRequireFields } from './useRequireFields';
import { createFxDmFormToRequest } from './utils';
import { VendorLegalDetailsForm } from './vendorLegalDetails/VendorLegalDetailsForm';

type FxVendorAccountActivityProps = {
  onClose: VoidFunction;
  onBack: VoidFunction;
  vendorId: string;
  shouldShowFailedCreationBanner: boolean;
  fxBankDetailsDefaultValues?: Partial<FxVendorAccountFieldsFormFields>;
  onDone: (data: InternationalAccountDeliveryMethod) => void;
  vendorName?: string;
};

export const FxVendorAccountActivity = withAnalyticsContext(
  ({
    onBack,
    onClose,
    vendorId,
    shouldShowFailedCreationBanner,
    onDone,
    vendorName,
    fxBankDetailsDefaultValues,
  }: FxVendorAccountActivityProps) => {
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const onCreateDeliveryMethod = (data: DeliveryMethod) => {
      endAction('fx_account_details_submit');
      onDone(data as InternationalAccountDeliveryMethod);
    };
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const { create: createDeliveryMethod, isCreating: isCreatingDM } = useDeliveryMethods({
      vendorId,
      onCreate: onCreateDeliveryMethod,
      enabled: false,
      onCreateError: () => {
        toast({
          // @ts-expect-error missing type in DS
          'data-testid': 'fx-vendor-account-creation-failed-toast',
          type: 'error',
          title: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.commons.errors.general-error'
          ),
        });
      },
    });

    const getDefaultCountry = () => {
      if (fxBankDetailsDefaultValues?.foreignCurrency) {
        const countries = getCountriesOptions(fxBankDetailsDefaultValues.foreignCurrency);
        if (countries.length === 1) {
          return countries[0]?.value;
        }
      }
      return;
    };

    const defaultValues = defaults(fxBankDetailsDefaultValues, {
      legalBusinessName: '',
      line1: '',
      line2: '',
      city: '',
      country: '',
      state: '',
      postalCode: '',
      fxCountrySelection: getDefaultCountry(),
    });

    const onVendorAccountSubmit = (data: FxVendorAccountFieldsFormFields) => {
      startAction('fx_account_details_submit');
      const requiredFieldsForBankDetails = getRequireFieldsForBankDetails(requiredFields);
      const postRequest = createFxDmFormToRequest(data, requiredFieldsForBankDetails.payeeAccountType);
      createDeliveryMethod(postRequest);
    };

    const form = useMelioForm<FxVendorAccountFieldsFormFields>({
      schema: useVendorAccountFormSchema(),
      onSubmit: onVendorAccountSubmit,
      defaultValues,
      subscribeToDefaultValuesChanges: true,
    });
    const { cancelButtonProps, submitButtonProps, watch } = form;

    useAnalyticsView('DeliveryMethod', true, true);
    const { foreignCurrency: currency } = watch();
    const { requiredFields } = useRequireFields({ currency });

    return (
      <NewSinglePaymentStepLayout
        data-component="InternationalBankDetailsActivity.FxBankDetailsScreen"
        data-testid="international-bank-details-activity-fx-bank-details-screen"
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton
              onClick={() => {
                onClose();
              }}
            />
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              isLoading={isCreatingDM}
              label={formatMessage('activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.continue')}
              data-testid="activities-fx-bankDetails-continue"
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.title', {
            vendorName,
          })}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <Group spacing="l" variant="vertical" width="full" alignItems="center">
            <Text textStyle="body2" color="neutral.black">
              {formatMessage('activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.subTitle')}
            </Text>
            {shouldShowFailedCreationBanner && <CreationBadRequestBanner />}
            <FxBankDetailsForm form={form} defaultValues={defaultValues} />
            <VendorLegalDetailsForm form={form} requiredFields={requiredFields} />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

FxVendorAccountActivity.displayName = 'FxVendorAccountActivity';
