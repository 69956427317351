import { Account, UseVendorsProps, VendorManagedByEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

export const useVendorSelectFilterQuery = ({
  searchTerm,
  account,
  isViewMode,
}: {
  searchTerm: string;
  account?: Account;
  isViewMode?: boolean;
}): UseVendorsProps['params'] => {
  const [isSbbExclusiveView] = useFeature(FeatureFlags.SbbExclusiveUserView, false);

  const SBB_EXCLUSIVE_VIEW_SEARCH_VENDOR_QUERY = {
    $or: {
      $and: [
        {
          'vendor.managedBy': VendorManagedByEnum.Fiserv,
        },
        {
          'vendor.createdById': account?.userId || '',
        },
      ],
      $or: [
        {
          'vendor.managedBy': 'null',
        },
      ],
    },
  };

  const params: UseVendorsProps['params'] = {
    ...(searchTerm.length && { searchTerm }),
    ...(isSbbExclusiveView && !isViewMode && { search: SBB_EXCLUSIVE_VIEW_SEARCH_VENDOR_QUERY }),
    limit: 50,
  };

  return Object.values(params).length ? params : undefined;
};
