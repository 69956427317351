import { ComponentThemeType } from '@/cl/theme/theme.hooks';

type InfoCardThemeProps = {
  label?: string;
};

export const InfoCardTheme: ComponentThemeType<InfoCardThemeProps> = (currentTheme) => ({
  baseStyle: {
    container: {
      flex: 1,
      display: 'flex',
      backgroundColor: currentTheme.colors.neutral.lighter,
      color: currentTheme.colors.neutral.darkest,
      borderRadius: '8px',
      alignItems: 'center',
      margin: '0 auto',
      padding: '0 16px' as never,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    label: {
      color: currentTheme.colors.neutral.darkest,
      lineHeight: ['inherit', '30px'],
    },
  },
});
