import { useMelioIntl } from '@melio/platform-i18n';

import { useStepManager } from '../../../hooks/useStepManager';
import { BasicDetailsStep, BillingDetailsStep, PlanSelectionStep } from '../components/NewClientFormSteps';
import { NewFirmClientFormStepDetails, NewFirmClientFormSteps } from '../types';

export const useNewFirmClientStepManager = () => {
  const { formatMessage } = useMelioIntl();
  const { steps, expandSelectedStep, nextStep } = useStepManager<NewFirmClientFormStepDetails>({
    data: [
      {
        title: formatMessage(`activities.accountants.newClient.form.basicDetails.title.text`),
        description: formatMessage(`activities.accountants.newClient.form.basicDetails.description.text`),
        stepId: NewFirmClientFormSteps.BasicDetails,
        component: BasicDetailsStep,
      },
      {
        title: formatMessage(`activities.accountants.newClient.form.billingDetails.title.text`),
        description: formatMessage(`activities.accountants.newClient.form.billingDetails.description.text`),
        stepId: NewFirmClientFormSteps.BillingDetails,
        component: BillingDetailsStep,
      },
      {
        title: formatMessage(`activities.accountants.newClient.form.planSelection.title.text`),
        description: formatMessage(`activities.accountants.newClient.form.planSelection.description.text`),
        stepId: NewFirmClientFormSteps.PlanSelection,
        component: PlanSelectionStep,
      },
    ],
    defaultActiveId: NewFirmClientFormSteps.BasicDetails,
  });

  return { steps, expandSelectedStep, nextStep };
};
