import { Box } from '@chakra-ui/react';
import { Container, Group, IconButton, Loader, Text, useBreakpoint, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount, useCollaborators, useFundingSources, useOrganizations } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionBillingCycleEnum, useSubscriptionMe, useSubscriptionsPreview } from '../../../api';
import {
  useIsSubscriptionsEnabled,
  usePlanInfo,
  usePlansTiers,
  useRedirects,
  useSubscription,
  useWizardParams,
} from '../../../hooks';
import { SUBSCRIPTION_SETTINGS_PLANS_ROUTE } from '../../constants';
import { useSubscriptionRouter } from '../../utils';
import { SubscriptionCheckoutSelection } from './components/SubscriptionCheckoutSelection';
import { SubscriptionCheckoutSummary } from './components/SubscriptionCheckoutSummary';

export type SubscriptionCheckoutActivityProps = {
  returnUrl?: string;
  planId: string;
};

export const SubscriptionCheckoutActivity = withAnalyticsContext<SubscriptionCheckoutActivityProps>(
  ({ planId, setAnalyticsProperties }) => {
    const [searchParams] = useSearchParams();
    const { promoCodeQueryString, billingCycle } = useWizardParams();
    const [fundingSourceId, setFundingSourceId] = useState<string | null>(searchParams.get('fundingSourceId')); // only when redirected from plaid
    const [promoCode, setPromoCode] = useState<string | undefined>(promoCodeQueryString);
    const [cycle, setCycle] = useState<SubscriptionBillingCycleEnum>(
      billingCycle || SubscriptionBillingCycleEnum.Monthly
    );
    const [shouldPreValidatePromoCode, setShouldPreValidatePromoCode] = useState<boolean>(!!promoCodeQueryString);

    const subscription = useSubscription();
    const { getPlan } = usePlansTiers();
    const { planName } = usePlanInfo(planId);
    const { goToSuccessPage, goToSettingsPage } = useRedirects();
    const { goToSubscriptionPlans } = useSubscriptionRouter();
    const { formatMessage } = useMelioIntl();
    const { data: me } = useAccount({ id: 'me' });
    const { toast } = useToast();
    const {
      create: createSubscription,
      update: updateSubscription,
      isMutating: isSubscriptionMutating,
    } = useSubscriptionMe({ enabled: false });
    const { data: collaborators, isLoading: isCollaboratorsLoading } = useCollaborators({});
    const { data: fundingSources, isFetching: isFundingSourcesFetching } = useFundingSources();
    const { data: organizations = [] } = useOrganizations();

    const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
    const { data: preview, isFetching: isPreviewFetching } = useSubscriptionsPreview({
      enabled: isSubscriptionsEnabled && !!collaborators?.length && !shouldPreValidatePromoCode,
      params: {
        planId,
        fundingSourceId: fundingSourceId || undefined,
        planCycle: cycle,
        unitQuantity: collaborators?.length || 0,
        promoCode,
      },
    });
    const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();
    const isVerticalLayout = isExtraSmallScreen || isSmallScreen;
    const { track } = useAnalytics();
    const currentOrg = organizations.find((org) => org.id === me?.organizationId);
    setAnalyticsProperties({
      PageName: 'checkout',
      Flow: 'subscription',
      Intent: 'subscribe',
      ChosenPlan: planName,
    });

    useEffect(() => {
      if (fundingSources?.[0]) {
        setFundingSourceId(fundingSources[0].id);
      }
    }, [fundingSources]);

    const getUpdatedAnalyticsProperties = () => ({
      BillingCycle: cycle,
      HasPromo: !!promoCode,
    });

    const onUpdateSubscription = () => {
      if (!fundingSourceId) {
        toast({
          id: 'subscription-checkout-error',
          type: 'error',
          title: formatMessage('activities.subscription.checkout.summary.toast.error'),
        });
        return;
      }
      void updateSubscription({ planId, fundingSourceId, promoCode })
        .then(() => {
          track('Organization', 'Status', {
            Intent: 'upgrade-plan',
          });
          goToSuccessPage();
        })
        .catch(() => {
          toast({
            id: 'subscription-checkout-error',
            type: 'error',
            title: formatMessage('activities.subscription.checkout.summary.toast.error'),
          });
        });
    };

    const onCreateSubscription = () => {
      if (!fundingSourceId || !collaborators?.length) {
        toast({ type: 'error', title: formatMessage('activities.subscription.checkout.summary.toast.error') });
        return;
      }
      void createSubscription({
        fundingSourceId,
        planId,
        numberOfSeats: collaborators.length,
        planCyclePeriod: cycle,
        promoCode,
      })
        .then(() => {
          track('Organization', 'Status', {
            StatusType: 'success',
            ...getUpdatedAnalyticsProperties(),
          });
          goToSuccessPage();
        })
        .catch((error) => {
          track('Organization', 'Status', {
            StatusType: 'failure',
            ErrorType: error,
            ...getUpdatedAnalyticsProperties(),
          });
          toast({ type: 'error', title: formatMessage('activities.subscription.checkout.summary.toast.error') });
        });
    };

    const onSubmit = () => {
      track('Organization', 'Click', {
        Intent: 'subscribe',
        Cta: 'subscribe',
        ...getUpdatedAnalyticsProperties(),
      });

      if (subscription) {
        onUpdateSubscription();
      } else {
        onCreateSubscription();
      }
    };

    const plan = getPlan(planId);
    const isLoading = isPreviewFetching || isFundingSourcesFetching || !preview;
    const isPromoCodeFromQS = !!promoCodeQueryString && promoCode === promoCodeQueryString;

    useAnalyticsView('Organization', true, false, {
      NumberOfSeats: collaborators?.length,
      NumberOfPaymentMethods: fundingSources?.length,
      isMultiOrg: organizations?.length > 1,
      CompanyType: currentOrg?.companyType,
      ...getUpdatedAnalyticsProperties(),
    });

    const onDoneFetchingPromoCodeData = () => setShouldPreValidatePromoCode(false);

    if (isCollaboratorsLoading || !plan) {
      return (
        <Container paddingY="xxxl" data-testid="subscription-checkout-screen-loader">
          <Loader />
        </Container>
      );
    }

    return (
      <Container
        paddingX="xxs"
        paddingY="xxs"
        data-testid="subscription-checkout-screen"
        data-component="SubscriptionCheckoutScreen"
      >
        <Group alignItems="center" variant="vertical" width="full" spacing="xxl">
          <Group alignItems="center" justifyContent="space-between" width="full">
            <Group alignItems="center">
              <IconButton
                variant="primary"
                size="large"
                aria-label={formatMessage('app.settings.backButton.ariaLabel')}
                data-testid="back-button"
                icon="arrow-left"
                onClick={() => goToSubscriptionPlans({ returnUrl: SUBSCRIPTION_SETTINGS_PLANS_ROUTE, promoCode })}
              />
              <Text as="h1" textStyle="heading1Semi">
                {formatMessage('activities.subscription.checkout.title', { plan: planName })}
              </Text>
            </Group>
            <IconButton
              variant="primary"
              size="large"
              aria-label={formatMessage('app.settings.backButton.ariaLabel')}
              data-testid="back-button"
              icon="close"
              onClick={goToSettingsPage}
            />
          </Group>
          <Group variant={isVerticalLayout ? 'vertical' : 'horizontal'} justifyContent="space-between" spacing="l">
            <Box flex={{ xs: 'none', m: 4 }} width="full">
              <SubscriptionCheckoutSelection
                selectedPlan={plan}
                isSubscribing={isSubscriptionMutating}
                fundingSourceId={fundingSourceId}
                setFundingSourceId={setFundingSourceId}
                cycle={cycle}
                setCycle={setCycle}
              />
            </Box>
            <Box flex={{ xs: 'none', m: 3 }} width="full">
              <SubscriptionCheckoutSummary
                preview={preview}
                isLoading={isLoading}
                selectedPlan={plan}
                isSubscribing={isSubscriptionMutating}
                onSubmit={onSubmit}
                promoCode={promoCode}
                isPromoCodeFromQS={isPromoCodeFromQS}
                onChangePromoCode={setPromoCode}
                onDoneFetchingPromoCodeData={onDoneFetchingPromoCodeData}
                currentCycle={cycle}
              />
            </Box>
          </Group>
        </Group>
      </Container>
    );
  }
);
