import { Box } from '@chakra-ui/react';
import { Group, Illustration } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { BillSubscription, DeliveryMethod, FundingSource, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { RecurringPaymentDetailsCard } from '../../../RecurringPaymentScheduled/NewRecurringPaymentScheduled/screens/RecurringPaymentScheduled/components/RecurringPaymentDetailsCard';
import { PaymentUpdatedActionsButtons } from '../../components/paymentUpdatedActionsButtons';

type PaymentUpdatedScreenProps = {
  billSubscription: BillSubscription;
  onBackToDashboard: VoidFunction;
  onScreenLoad?: () => void;
};

export const SeriesPaymentUpdatedSuccessfullyScreen = withAnalyticsContext<PaymentUpdatedScreenProps>(
  ({ setAnalyticsProperties, billSubscription, onBackToDashboard, onScreenLoad, ...props }) => {
    const { formatMessage } = useMelioIntl();

    setAnalyticsProperties({
      PageName: 'payment-updated',
    });

    const { track } = useAnalytics();

    useEffect(() => {
      track('Payment', 'View');
    }, [track]);

    if (!billSubscription?.vendor) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const onContainerRefChanged = (ref: HTMLDivElement | null) => {
      if (ref) {
        onScreenLoad?.();
      }
    };
    const subscriptionDetails = {
      endDate: billSubscription.endDate,

      fundingSource: billSubscription.fundingSource as FundingSource,
      deliveryMethod: billSubscription.deliveryMethod as DeliveryMethod,
      paymentAmount: billSubscription.amount,
      occurrences: billSubscription.occurrences?.length,
      lastPaymentAmount: billSubscription.lastAmount,
      billSubscriptionId: billSubscription.id,
      amount: billSubscription.amount,
      currency: billSubscription.currency,
      intervalType: billSubscription.intervalType,
      approvalDecisionStatus: billSubscription.approvalDecisionStatus,
      vendor: billSubscription.vendor as Vendor,
      nextOccurrence: billSubscription.nextOccurrence,
      scheduledDate: billSubscription.startDate,
    };

    return (
      <NewSinglePaymentStepLayout
        data-component="EditBillSubscriptionActivity.SeriesUpdatedSuccessfullyScreen"
        data-testid="edit-bill-subscription-activity-series-payment-updated-successfully-screen"
        {...props}
        ref={onContainerRefChanged}
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <PaymentUpdatedActionsButtons onBackToDashboard={onBackToDashboard} isNewSuccessScreen />
          </NewSinglePaymentStepLayout.Actions>
        }
        isCentered
        spacing="l"
      >
        <Group variant="vertical" alignItems="center" spacing="xs">
          <Group justifyContent="center">
            <Illustration type="success" size="medium" />
          </Group>
          <NewSinglePaymentStepLayout.Title>
            <Box textAlign="center">
              {formatMessage('activities.editBillSubscription.screens.recurringPaymentUpdates.title')}
            </Box>
          </NewSinglePaymentStepLayout.Title>
          <RecurringPaymentDetailsCard subscriptionDetails={subscriptionDetails} />
        </Group>
      </NewSinglePaymentStepLayout>
    );
  }
);

SeriesPaymentUpdatedSuccessfullyScreen.displayName =
  'EditBillSubscriptionActivity.SeriesPaymentUpdatedSuccessfullyScreen';
