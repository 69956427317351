/* eslint-disable react-hooks/exhaustive-deps */
import { PaymentRequestDetailsWidget } from '@melio/ap-widgets';
import { Button, Drawer, Group, Loader, Text, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { usePaymentRequest } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../utils';

type PaymentRequestDrawerProps = {
  id: string;
  onClose: () => void;
  onRejectClick: (id: string) => void;
};

export const PaymentRequestDrawer = withAnalyticsContext(
  ({ id, onClose, onRejectClick }: PaymentRequestDrawerProps) => {
    const { track } = useAnalytics();
    const [isClosing, setIsClosing] = useState(false);
    const {
      data: paymentRequest,
      isLoading: isWaitingForPaymentRequest,
      isUpdating: isUpdatingForPaymentRequest,
      approve,
    } = usePaymentRequest({
      id,
      refetchOnMount: 'always',
    });
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const { pathname } = useLocation();
    const { navigateToSchedulePayment } = useActivitiesNavigate();

    const approveAndSchedulePaymentRequest = async () => {
      try {
        track('PaymentRequest', 'Click', {
          Intent: 'approve-bill',
        });
        const approvedPaymentRequest = await approve();
        if (approvedPaymentRequest.billId) {
          navigateToSchedulePayment({ billId: approvedPaymentRequest.billId, returnUrl: pathname });
        }
      } catch (e) {
        toast({ type: 'error', title: formatMessage('widgets.paymentRequestDetails.approveButton.failureToast') });
      }
    };

    useAnalyticsContext({
      globalProperties: {
        PageName: 'payment-request-view',
        PaymentRequestId: id,
        FileAttached: !!paymentRequest?.file,
        Amount: paymentRequest?.totalAmount,
      },
    });

    useEffect(() => {
      if (isWaitingForPaymentRequest) {
        return;
      }
      track('PaymentRequest', 'View', {
        Intent: 'view-payment-request',
        FileAttached: !!paymentRequest?.file,
        Amount: paymentRequest?.totalAmount,
      });
    }, [isWaitingForPaymentRequest]);

    const handleCloseClick = () => {
      track('PaymentRequest', 'Click', {
        Intent: 'exit',
        Cta: 'exit',
      });
      setIsClosing(true);
    };

    return (
      <Drawer
        isOpen={!isClosing && (!!paymentRequest || isWaitingForPaymentRequest)}
        onClose={handleCloseClick}
        onCloseComplete={onClose}
        body={
          paymentRequest ? <PaymentRequestDetailsWidget showOpenBalance paymentRequest={paymentRequest} /> : <Loader />
        }
        closeButtonAriaLabel={formatMessage(
          'activities.payDashboard.drawer.header.paymentRequest.closeButtonAriaLabel'
        )}
        header={
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage('activities.payDashboard.drawer.header.paymentRequest.title')}
          </Text>
        }
        footer={
          <PaymentRequestDrawerFooter
            approveAndSchedulePaymentRequest={approveAndSchedulePaymentRequest}
            isUpdatingForPaymentRequest={isUpdatingForPaymentRequest}
            onRejectClick={() => onRejectClick(id)}
          />
        }
        data-testid="payment-request-drawer"
      />
    );
  }
);

const PaymentRequestDrawerFooter = ({
  approveAndSchedulePaymentRequest,
  isUpdatingForPaymentRequest,
  onRejectClick,
}: {
  approveAndSchedulePaymentRequest: () => void;
  isUpdatingForPaymentRequest: boolean;
  onRejectClick: () => void;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group width="full" justifyContent="space-between">
      <Button
        data-testid="payment-request-drawer-footer-reject-button"
        label={formatMessage('activities.payDashboard.drawer.footer.paymentRequest.reject')}
        onClick={onRejectClick}
        isLoading={isUpdatingForPaymentRequest}
      />
      <Button
        data-testid="payment-request-drawer-footer-pay-button"
        variant="primary"
        label={formatMessage('activities.payDashboard.drawer.footer.paymentRequest.pay')}
        onClick={approveAndSchedulePaymentRequest}
      />
    </Group>
  );
};
