import React from 'react';
import { Modal } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';
export interface TaxReportModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}
export const TaxReportModal = ({ isOpen, onClose }: TaxReportModalProps) => {
  const { formatMessage } = usePlatformIntl();
  const title = formatMessage('widgets.paymentClassification.modal.title');
  const content = formatMessage('widgets.paymentClassification.modal.content');
  const btnText = formatMessage('widgets.paymentClassification.modal.button');

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      header={title}
      description={content}
      primaryButton={{ onClick: onClose, label: btnText, variant: 'primary' }}
    />
  );
};
