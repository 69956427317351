/* eslint-disable react-hooks/exhaustive-deps */
import { StatusModal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import React, { useCallback, useEffect } from 'react';

export type DemoRequestAckWidgetProps = {
  isOpen: boolean;
  isPhoneAck?: boolean;
  onClose: VoidFunction;
  pageEntryPoint?: string;
};
export const DemoRequestAckWidget = forwardRef<DemoRequestAckWidgetProps, 'div'>(
  ({ isOpen, isPhoneAck, onClose, pageEntryPoint }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    useEffect(() => {
      track('Demo', 'Viewed', {
        pageName: 'request-a-demo-sent',
        intent: 'notify-user-of-success',
        pageEntryPoint,
      });
    }, []);

    const _onClose = useCallback(() => {
      track('Demo', 'Clicked', {
        pageName: 'request-a-demo-sent',
        intent: 'notify-user-of-success',
        pageEntryPoint,
        cta: 'close',
      });
      onClose();
    }, []);

    const header = formatMessage('widgets.demoRequest.ackModal.header');
    const description = formatMessage(`widgets.demoRequest.ackModal.${isPhoneAck ? 'phone' : 'email'}Description`);
    return (
      <StatusModal
        ref={ref}
        variant="success"
        header={header}
        description={description}
        secondaryButton={{
          label: formatMessage('widgets.demoRequest.ackModal.button'),
          onClick: _onClose,
          variant: 'tertiary',
        }}
        isOpen={isOpen}
        onClose={onClose}
      ></StatusModal>
    );
  }
);
DemoRequestAckWidget.displayName = 'DemoRequestAckWidget';
