import { useMelioIntl } from '@melio/ar-domain';
import { Group, IconButton, Tooltip } from '@melio/penny';

type ButtonsProps = {
  onCreateInvoice: VoidFunction;
  onVisitSupportSettingsPage: VoidFunction;
};

export const HeaderToolBarMobile: React.FC<ButtonsProps> = ({ onCreateInvoice, onVisitSupportSettingsPage }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group spacing="xxs" variant="horizontal">
      <Tooltip label={formatMessage('ar.dashboard.activities.tabsLayout.supportButton.tooltip.label')}>
        <IconButton
          onClick={onVisitSupportSettingsPage}
          icon="help-circle"
          data-testid="dashboard-support-icon-button"
          variant="naked"
        />
      </Tooltip>
      <IconButton onClick={onCreateInvoice} icon="add" variant="brand" data-testid="dashboard-create-invoice-button" />
    </Group>
  );
};

HeaderToolBarMobile.displayName = 'HeaderToolBarMobile';
