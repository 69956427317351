import { useNavigationWithQueryParams, useVendorNotPermitted } from '@melio/ap-domain';
import { EbillCellBadge, LockedVendorIcon, VendorAvatar } from '@melio/ap-widgets';
import { Container, Group, NakedButton, Table, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { InboxItemBillTypeEnum, VendorEBillStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../../utils';
import { FocusSkipToComponent } from '../../../FocusSkipToComponent';
import {
  getBillsTabItemVendor,
  getBillsTabItemVendorId,
  getInboxItemExternalVendorLogo,
  getInboxItemVendorName,
  getIsManagedVendor,
} from '../BillsTab.utils';
import { BILL_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID } from '../consts';
import { BillsTabItem } from '../types';

export type BillsTabVendorNameCellProps = {
  billsTabItem: BillsTabItem;
  rowSelectedCount?: number;
};

export const BillsTabVendorNameCell = ({ billsTabItem, rowSelectedCount = 0 }: BillsTabVendorNameCellProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToEbillActivationForm, navigateToAutopayForm } = useActivitiesNavigate();
  const { isExtraSmallScreen } = useBreakpoint();
  const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
  const { track } = useAnalytics();
  const billsTabItemVendor = getBillsTabItemVendor(billsTabItem);
  const vendorName = getInboxItemVendorName(billsTabItem);
  const vendorExternalLogoUrl = getInboxItemExternalVendorLogo(billsTabItem);
  const vendorId = getBillsTabItemVendorId(billsTabItem);
  const isVendorNotPermitted = useVendorNotPermitted(billsTabItemVendor);

  const onVendorNameClick = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!vendorId) {
      return;
    }

    track('Dashboard', 'Click', {
      Intent: 'vendor-details',
      Cta: 'vendor-name-click',
    });
    handleNavigationWithQueryParams({ newPath: `vendor/${vendorId}` });
  };

  const isReadOnly = billsTabItem.type === InboxItemBillTypeEnum.Bill && isVendorNotPermitted;
  const label = vendorName || formatMessage('activities.payDashboard.billsTab.table.cells.vendorName.missing');
  const testId = `bills-tab-row-${billsTabItem.payload.id}-vendor-name`;
  const isVendorManaged = getIsManagedVendor(billsTabItem);

  return (
    <Table.Cell data-testid={`bills-tab-row-${billsTabItem.payload.id}-vendor-name-cell`}>
      <Group alignItems="center">
        {!isExtraSmallScreen && (
          <VendorAvatar name={vendorName || '?'} logoUrl={vendorExternalLogoUrl} isManaged={isVendorManaged} />
        )}
        <Group variant="vertical" spacing="xxs">
          <Group alignItems="center" spacing="xxs">
            {isReadOnly ? <LockedVendorIcon /> : null}
            {vendorId ? (
              <NakedButton
                shouldSupportEllipsis
                variant="secondary"
                label={label}
                data-testid={testId}
                onClick={onVendorNameClick}
                aria-haspopup="dialog"
              />
            ) : (
              <Container width="full">
                <Text textStyle="inline" color="inherit" shouldSupportEllipsis data-testid={testId}>
                  {label}
                </Text>
              </Container>
            )}
            {billsTabItemVendor && billsTabItemVendor.eBillStatus !== VendorEBillStatusEnum.NotCapable && (
              <EbillCellBadge
                vendor={billsTabItemVendor}
                onEBillSubscribeClick={(vendorId) => navigateToEbillActivationForm({ vendorId })}
                onAutoPayActivationClick={(vendorId) => navigateToAutopayForm({ vendorId })}
                isReadOnly={isReadOnly}
              />
            )}
          </Group>
          <FocusSkipToComponent
            disable={rowSelectedCount === 0}
            text="activities.payDashboard.billsTab.selectionFooter.reviewAndPay.skipTo"
            href={`#${BILL_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID}`}
            dataTestid="bills-pay-and-review"
          />
        </Group>
      </Group>
    </Table.Cell>
  );
};
