import { FieldValues, UseMelioFormResults } from '@melio/penny';
import {
  AccountAddress,
  Industry,
  InternationalAddress,
  OrganizationBusinessType,
  TaxIdTypeEnum,
} from '@melio/platform-api';

export type WithInternationalAddress<T> = T extends { address: InternationalAddress } ? T : never;

export type BusinessDetailsForm = {
  companyName: string;
  companyLegalName: string;
  legalBusinessAddress: AccountAddress;
  phone: string;
  businessIndustry: Industry;
  businessType: OrganizationBusinessType;
  taxIdType: TaxIdTypeEnum;
  businessTaxId: string;
};

export type AccountHolderForm = WithInternationalAddress<{
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: InternationalAddress;
  dayOfBirth: Date;
  taxId: string;
  isUbo: boolean;
}>;

export type DecisionMakerForm = {
  uboId: string | null;
  companyRole: string;
  decisionMakerFirstName: string;
  decisionMakerLastName: string;
  decisionMakerDayOfBirth: Date;
  decisionMakerAddress: InternationalAddress;
  decisionMakerSsn: string;
  termsAndConditions: boolean;
};

export type OwnershipDetailsForm = {
  ubos: OwnershipDetails[];
};

export type OwnershipDetails = {
  uboId: string;
  ownerFirstName: string;
  ownerLastName: string;
  ssn: string;
  ownershipPercentage: string;
  address: InternationalAddress;
  dateOfBirth?: Date;
};

export enum FXBusinessDetailsFormSteps {
  BUSINESS_DETAILS = 0,
  ACCOUNT_HOLDER = 1,
  UBO = 2,
  DECISION_MAKER = 3,
}

export type FXFormSectionCardBaseProps<T extends FieldValues> = {
  form: UseMelioFormResults<T>;
  isExpanded: boolean;
  onExpandChange: (state: boolean) => void;
  isCompleted?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const OTHER_DECISION_MAKER_KEY = 'OTHER';
export const ACCOUNTHOLDER_DECISION_MAKER_KEY = 'ACCOUNT_HOLDER';
export const UBO_FROM_ACCOUNT_HOLDER_ID = 'ubo-from-account-holder';
export enum DecisionMakerRoles {
  CEO = 'CEO',
  CFO = 'CFO',
  COO = 'COO',
  MANAGING_MEMBER = 'MANAGING_MEMBER',
  GENERAL_PARTNER = 'GENERAL_PARTNER',
  PRESIDENT = 'PRESIDENT',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
  TREASURER = 'TREASURER',
}
