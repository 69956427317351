import { Group, Illustration } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { useReconciliationModal } from '../../../FundingSourceSelection';
import { ReconciliationModalActivity } from '../../../Reconciliation';

export type BankAccountCreatedScreenProps = {
  onDone: VoidFunction;
  onClose: VoidFunction;
  isReceivingMethodFlow?: boolean;
  fundingSource?: FundingSource;
  isLoading?: boolean;
};

export const BankAccountCreatedScreen = forwardRef<BankAccountCreatedScreenProps, 'div'>(
  ({ onDone, onClose, fundingSource, isReceivingMethodFlow, isLoading = false, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    useAnalyticsView('BankSuccessfullyAdded');
    const { track } = useAnalytics();

    const {
      shouldRunReconciliation,
      shouldShowReconciliationModal,
      onCloseReconciliationFlow,
      onDoneReconciliationFlow,
      runReconciliationFlow,
    } = useReconciliationModal();

    const isReconciliationNeeded = shouldRunReconciliation(fundingSource);

    const onDoneReconciliation = () => {
      onDoneReconciliationFlow();
      onDone();
    };

    const isAccountVerified = fundingSource ? fundingSource.isVerified : true;
    const messageKeySuffix = isAccountVerified ? '' : '.unverified';

    return (
      <NewSinglePaymentStepLayout
        data-component="AddBankAccountPlaidActivity.BankAccountCreatedScreen"
        data-testid="add-bank-account-plaid-activity-bank-account-created-screen"
        {...props}
        ref={ref}
        isLoading={isLoading}
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              label={formatMessage(
                `activities.addBankAccountPlaid.screens.bankAccountCreated.continue${messageKeySuffix}`
              )}
              isDisabled={shouldShowReconciliationModal}
              onClick={() => {
                track('BankSuccessfullyAdded', 'Submitted');
                if (isReconciliationNeeded) {
                  runReconciliationFlow();
                } else {
                  onDone();
                }
              }}
            />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <Group justifyContent="center">
          <Illustration type={isAccountVerified ? 'bank-success' : 'pending'} size="large" />
        </Group>
        <NewSinglePaymentStepLayout.Title>
          {formatMessage(`activities.addBankAccountPlaid.screens.bankAccountCreated.title${messageKeySuffix}`)}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description>
          {isReceivingMethodFlow
            ? formatMessage('activities.addBankAccountPlaid.screens.bankAccountCreated.description.receivingMethod')
            : formatMessage(
                `activities.addBankAccountPlaid.screens.bankAccountCreated.description.fundingSource${messageKeySuffix}`
              )}
        </NewSinglePaymentStepLayout.Description>

        {!!fundingSource && isReconciliationNeeded && (
          <ReconciliationModalActivity
            isOpen={shouldShowReconciliationModal}
            onClose={onCloseReconciliationFlow(onClose)}
            onDone={onDoneReconciliation}
            selectedFundingSourceId={fundingSource.id}
            isNewFundingSource
          />
        )}
      </NewSinglePaymentStepLayout>
    );
  }
);

BankAccountCreatedScreen.displayName = 'AddBankAccountPlaidActivity.BankAccountCreatedScreen';
