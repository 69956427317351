import { Box, Spinner, Text } from '@chakra-ui/react';
import { Card, Group, Icon, Image, Theme, useTheme } from '@melio/penny';
import { BillProvider, BillsSyncStatusEnum, useMelioQueryClient } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useBillProviders } from '../hooks/useBillProviders';
import { syncProviderStorage, useEbillsImportState } from '../hooks/useEbillsImportState';
import { useProviderDisplayProps } from '../hooks/useProviderDisplayProps';

type DisplayState = {
  title: string;
  StatusIcon: React.FC<{ theme: Theme }>;
};

type InternalStatus = 'in-progress' | 'done' | 'error';

type StatusMap = Record<BillsSyncStatusEnum, InternalStatus>;

const statusMap: StatusMap = {
  'not-started': 'in-progress',
  'in-progress': 'in-progress',
  disconnected: 'in-progress',
  error: 'error',
  done: 'done',
};

const getStatusComponents = (status: InternalStatus, provider: BillProvider, loadingText: string): DisplayState => {
  switch (status) {
    case 'error':
      return {
        title: 'Something went wrong',
        StatusIcon: ({ theme }) => (
          <IconWrapper color={theme.colors.warning.main}>
            <Icon color="inherit" type="warning-fill" />
          </IconWrapper>
        ),
      };
    case 'in-progress':
      return {
        title: loadingText,
        StatusIcon: ({ theme }) => (
          <Spinner label="loading" emptyColor={theme.colors.neutral.light} color={theme.colors.brand.main} size="sm" />
        ),
      };
    case 'done':
      return {
        title: provider.itemsSynced ? `${provider.itemsSynced} bills added` : 'Nothing to import',
        StatusIcon: ({ theme }) => (
          <IconWrapper color={theme.colors.success.main}>
            <Icon color="inherit" type="checked-circle-fill" />
          </IconWrapper>
        ),
      };
  }
};

const IconWrapper = ({ children, color }: { children: ReactNode; color: string }) => (
  <Box sx={{ alignItems: 'center', color, display: 'flex', justifyContent: 'center' }}>{children}</Box>
);

const Indicator = ({ provider, setIsHidden }: { provider: BillProvider; setIsHidden: VoidFunction }) => {
  const theme = useTheme();
  const queryClient = useMelioQueryClient();
  const [internalStatus, setInternalStatus] = useState<InternalStatus>('in-progress');
  const { formatMessage } = useMelioIntl();

  const { status, itemsSynced, isStatusError } = provider;

  useEffect(() => {
    if (status == 'done' && itemsSynced) {
      queryClient.invalidateQueries('InboxItemsApi').then(() => {
        setInternalStatus(statusMap[status]);
      });
    } else if (isStatusError) {
      return setInternalStatus('error');
    } else if (!status) {
      return setInternalStatus('in-progress');
    } else {
      setInternalStatus(statusMap[status]);
    }
  }, [queryClient, status, itemsSynced, isStatusError]);

  const { logoSVG, displayName } = useProviderDisplayProps(provider);
  const loadingText = formatMessage('activities.eBillsImport.importStatusIndicator.loadingProgess', {
    provider: displayName,
  });
  const { title, StatusIcon } = getStatusComponents(internalStatus, provider, loadingText);

  return (
    <Box boxShadow="0px 5px 15px 0px rgba(33, 33, 36, 0.1)" position="fixed" bottom="48px" right="60px" zIndex="1000">
      <Card paddingY="none" paddingX="none" width="full" variant="flat">
        <Box minWidth="328px" paddingX="4" paddingY="4">
          <Group alignItems="center" justifyContent="space-between">
            <Group height="full" alignItems="center" justifyContent="flex-start">
              <Image alt={`${displayName} Logo`} height="20px" src={logoSVG} />
              <Text textStyle="body2">{title}</Text>
            </Group>
            <Group>
              <StatusIcon theme={theme} />
            </Group>
          </Group>
        </Box>
        <Box
          onClick={() => setIsHidden()}
          cursor="pointer"
          padding="0 16px"
          color={theme.colors.neutral.darker}
          display="flex"
          alignItems="center"
          justifyContent="center"
          justifySelf="stretch"
          alignSelf="stretch"
          borderLeft={`1px solid ${theme.colors.neutral.light}`}
        >
          <Icon type="close-mini" color="inherit" />
        </Box>
      </Card>
    </Box>
  );
};

export const ImportStatusIndicator = () => {
  const providers: BillProvider[] = useBillProviders();
  const { redirectState, searchParamState, removeRedirectStateFromUrl } = useEbillsImportState();
  const [billProvider, setBillProvider] = useState(redirectState?.provider);

  const provider = providers.find((p) => p.name === billProvider);
  const [isManuallyHidden, setIsManuallyHidden] = useState(false);

  // Use sync provider query param only once, but allow to switch to a new one
  useEffect(() => {
    if (searchParamState) {
      syncProviderStorage.set(searchParamState);
      setBillProvider(searchParamState.provider);
      removeRedirectStateFromUrl();
    }
  }, [removeRedirectStateFromUrl, searchParamState]);

  // If settled, user shouldn't see the indicator next visit
  useEffect(() => {
    if ((provider?.status && provider?.status !== 'in-progress') || isManuallyHidden) {
      syncProviderStorage.remove();
    }
  }, [provider?.status, isManuallyHidden]);

  if (!provider || isManuallyHidden) {
    return null;
  }

  return <Indicator setIsHidden={() => setIsManuallyHidden(true)} provider={provider} />;
};

const shouldNotShowIndicatorPathnameSet = new Set(['/bills-sync-redirect']);
export const ImportEbillsStatusIndicatorProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  return (
    <>
      {children}
      {!shouldNotShowIndicatorPathnameSet.has(location.pathname) && <ImportStatusIndicator />}
    </>
  );
};
