/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@chakra-ui/react';
import { getFundingSourceIconType, getFundingSourceImageProps } from '@melio/ap-domain';
import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { Divider, Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { addBusinessDays } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { useGetFundingSourceLabel } from '@/utils/FundingSources.utils';
type PaymentSourceSectionProps = {
  fundingSource: FundingSource;
  initiatedDate?: Date;
};

export const PayFromSectionRefund = ({ fundingSource, initiatedDate }: PaymentSourceSectionProps) => {
  const { formatMessage, formatDate, formatDateTimeRange } = usePlatformIntl();
  const { displayName, nickname, type: fundingSourceType } = fundingSource;

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);
  const minDeliveryDate = addBusinessDays(initiatedDate as Date, 3);
  const maxDeliveryDate = addBusinessDays(initiatedDate as Date, 5);

  return (
    <>
      <Stack data-testid="pay-from-section-refund" flexDirection="column" gridGap={'1rem'} spacing={0}>
        <Text data-testid="pay-from-section-refund-title" textStyle="body4SemiUpper" color="neutral.darker">
          {formatMessage('widgets.paymentDetails.payFrom.refundTitle')}
        </Text>
        <PaymentReviewLineItem
          labelProps={{ label: fundingSourceLabel }}
          mainLabelProps={{ label: nickname || displayName }}
          {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
        />

        <Divider />

        <PaymentReviewLineItem
          labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.initiatedDate.label') }}
          mainLabelProps={{ label: formatDate(initiatedDate, { dateStyle: 'medium' }) }}
          icon={{ type: 'scheduled' }}
        />

        <Divider />

        <PaymentReviewLineItem
          labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.refundEta.label') }}
          icon={{ type: 'calendar-move' }}
          descriptionProps={
            fundingSourceType === 'bank-account'
              ? { label: formatMessage('widgets.deliveryDateListItemByPayee.bankHelperText') }
              : undefined
          }
          mainLabelProps={{
            label: formatDateTimeRange(minDeliveryDate, maxDeliveryDate, {
              dateStyle: 'medium',
            }),
          }}
        />
      </Stack>
    </>
  );
};
