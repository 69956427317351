import { MissingField } from '@melio/ap-widgets';
import { AccountLimitations, useAccountMeLimitations } from '@melio/platform-api';

const getMissingFields = (accountLimitations: AccountLimitations): Array<MissingField> => {
  const { user, company } = accountLimitations.entitiesInformation;
  const companyFields = company?.missingFields || [];
  const userFields = user?.missingFields || [];

  return [
    ...userFields.map<MissingField>((field) => ({ ...field, entity: 'user' })),
    ...companyFields.map<MissingField>((field) => ({ ...field, entity: 'company' })),
  ];
};

export const useCompleteComplianceInformation = () => {
  const {
    data: accountLimitations,
    isLoading: _isLoadingLimitations,
    isFetching: isFetchingLimitations,
    error: fetchLimitationsError,
  } = useAccountMeLimitations();
  const isLoadingLimitations = _isLoadingLimitations || isFetchingLimitations;
  const { isGracePeriodStarted, isGracePeriodEnded, numberOfPaymentsLeftInGracePeriod } = accountLimitations || {};
  const missingFields = accountLimitations ? getMissingFields(accountLimitations) : [];

  return {
    missingFields,
    isLoading: isLoadingLimitations,
    error: fetchLimitationsError,
    isGracePeriodStarted,
    isGracePeriodEnded,
    numberOfPaymentsLeftInGracePeriod,
  };
};
