import { Flex } from '@chakra-ui/react';
import { CardProps, Currency, Text } from '@melio/penny';

import { Theme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { ContactSummary } from '../ContactSummary/ContactSummary.component';
import { HighlightedText } from '../HighlightedText/HighlightedText.component';

type ScannedInvoicePayCardProps = {
  vendorName?: string;
  dueDate?: Date;
  amount: number | null;
  invoiceNumber?: string;
  highlightedText?: string;
  theme: Theme;
} & CardProps;

export const ScannedInvoicePayCard = (props: ScannedInvoicePayCardProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { vendorName, invoiceNumber, dueDate, amount, theme, highlightedText } = props;
  const missingDetails = !invoiceNumber || !dueDate || !amount || !vendorName;

  const Top = () => (
    <Flex sx={theme['topContainer']}>
      <ContactSummary
        title={vendorName || formatMessage('widgets.scannedInvoicePayCard.missingDetails.vendorName')}
        avatarName={vendorName ? undefined : '–'}
        size="small"
        highlightedText={highlightedText}
      />
    </Flex>
  );

  const dateText =
    !missingDetails &&
    formatMessage('widgets.scannedInvoicePayCard.dueDateText', {
      dueDate: formatDate(dueDate, { dateStyle: 'medium' }),
    });

  const Body = () => (
    <Flex data-component={'body-pay-card'} sx={theme['bodyBaseLine']}>
      <Flex direction={'column'}>
        {!missingDetails ? (
          <Text>{dateText}</Text>
        ) : (
          <Text textStyle="body4" color="neutral.darkest">
            {formatMessage('widgets.scannedInvoicePayCard.missingDetails.dueDate')}
          </Text>
        )}
        {!missingDetails && (
          <Text>
            <HighlightedText highlightedText={highlightedText} text={`#${invoiceNumber}`} />
          </Text>
        )}
      </Flex>
      <Flex alignItems={'center'}>
        <Text as="div">
          {amount ? <Currency data-testid="scanned-invoice-pay-card-currency-amount" amount={amount} /> : '$--'}
        </Text>
      </Flex>
    </Flex>
  );

  return (
    <>
      <Top />
      <Body />
    </>
  );
};

ScannedInvoicePayCard.displayName = 'ScannedInvoicePayCard';
