import { Box } from '@chakra-ui/react';
import { Badge } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const LateBanner = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Box data-testid="is-late-banner">
      <Badge
        type="secondary"
        status="warning"
        label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.lateBadge')}
      />
    </Box>
  );
};
