import { FileUpload, Group, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { TaxpayerInfo } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export type TaxPayerFileUploadProps = {
  isLoading?: boolean;
  onSelectFile: (file: File | null) => void;
  value?: TaxpayerInfo['file'] | null;
  onDelete: () => void;
  isInvalidFile?: boolean;
};

export const TaxPayerFileUpload = ({
  onSelectFile,
  value,
  isLoading,
  onDelete,
  isInvalidFile,
}: TaxPayerFileUploadProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const showDescription = !!value?.history && !isInvalidFile;
  const { createTrackHandler } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');

  useAnalyticsView('Vendor', true, true, {
    TaxId: 'file',
    PageName: 'vendor-details',
    Intent: 'view-vendor',
  });

  return (
    <Group data-testid="taxpayer-form-file-upload" width="full" spacing="xs" variant="vertical">
      <FileUpload
        value={value}
        onDelete={onDelete}
        onChange={onSelectFile}
        acceptTypes={['pdf']}
        onReplace={() =>
          trackActionClick({
            Intent: 'view-vendor',
            TaxId: 'file',
            Cta: 'replace',
            PageName: 'vendor-details',
          })
        }
        isLoading={isLoading}
        isDisabled={isLoading}
        isInvalid={isInvalidFile}
        placeholder={formatMessage('widgets.taxPayerInfo.form.upload.placeholder')}
      />
      {showDescription && (
        <Text data-testid="tax-payer-info-attachment-fileOwner-payor" textStyle="body4" color={'neutral.darker'}>
          {formatMessage('widgets.taxPayerInfo.attachmentByPayor.description.byYou', {
            date: (
              <Text textStyle="body4Semi" color={'neutral.darker'}>
                {formatDate(value?.history?.createdAt ?? undefined, { dateStyle: 'medium' })}
              </Text>
            ),
          })}
        </Text>
      )}
      {isInvalidFile && (
        <Text data-testid="tax-payer-info-attachment-invalid-error" textStyle="body4" color="critical.main">
          {formatMessage('widgets.taxPayerInfo.uploadW9.error.fileSize')}
        </Text>
      )}
    </Group>
  );
};

TaxPayerFileUpload.displayName = 'TaxPayerFileUpload';
