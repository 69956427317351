import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const AppLayoutTheme: ComponentThemeType<{ isStickyHeader: boolean | undefined }> = (
  currentTheme,
  { isStickyHeader },
) => ({
  baseStyle: {
    layoutContainer: {
      width: '100vw',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    rightLayoutContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowX: 'hidden',
    },
    downLayoutContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      backgroundColor: currentTheme.colors.neutral.lightest,
      overflowY: 'hidden',
    },
    appScreen: {
      flex: 1,
      position: 'relative',
      width: 'full',
      ...(!isStickyHeader && { overflowY: 'hidden', overflowX: 'hidden' }),
    },
  },
});
