import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Avatar, Group, Typography } from '@melio/penny';
import { useState } from 'react';

import { toFullName } from '../utils/toFullName';

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  badgeText: string;
  isExpired?: boolean;
  menuItems?: ActionsDropdownMenuItemProps[];
};

export const InvitationItem = ({ badgeText, firstName, lastName, email, isExpired = false, menuItems }: Props) => {
  const fullName = toFullName(firstName, lastName);
  const colorProp = isExpired ? { isDisabled: true } : { isReadOnly: true };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItemsWithOnClick = menuItems?.map((item) => ({
    ...item,
    onClick: () => {
      item.onClick?.();
      setIsMenuOpen(false);
    },
  }));

  return (
    <Group data-testid="collaborator-item">
      <Group.Item alignSelf="flex-start">
        <Avatar name={fullName} isDisabled={isExpired} />
      </Group.Item>
      <Group.Item grow={1}>
        <Group variant="vertical" spacing="xxs">
          <Typography.MainLabel
            label={fullName}
            variant="bold"
            badgeProps={{ label: badgeText, status: 'neutral' }}
            {...colorProp}
          />
          <Typography.Description label={email} {...colorProp} />
        </Group>
      </Group.Item>
      {menuItemsWithOnClick?.length && (
        <Group.Item alignSelf="flex-start">
          <ActionsDropdownMenu
            size="small"
            label="actions"
            items={menuItemsWithOnClick || []}
            isOpen={isMenuOpen}
            onOpenChange={setIsMenuOpen}
          />
        </Group.Item>
      )}
    </Group>
  );
};
