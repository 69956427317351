import { EditSinglePaymentActivity, PaymentFlowDoneAction } from '@melio/ap-activities';
import { useNavigate } from '@melio/platform-utils';

import { usePaymentFlowIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useIsMissingKycComplianceInformation } from '@/hooks/useIsMissingKycComplianceInformation';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { PayDashboardTabs } from '@/types/payDashboard.types';

export const EditPaymentScreen = ({ paymentId, returnUrl }: { paymentId: string; returnUrl?: string }) => {
  const navigate = useNavigate();
  const { replaceToPayDashboardTab, generateNPEDashboardLink } = useRouter();
  const { loginToAccountPlatformAuth, isLoginToAccountPlatformAuthLoading } = usePaymentFlowIntuitConnect({
    redirectMethod: 'replace',
  });
  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();
  const { missingKycComplianceInformation, onKycComplianceFormDone, setKycComplianceFormState } =
    useIsMissingKycComplianceInformation();

  const handleClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, paymentId);
  };

  const handleDone = (paymentId: string, action: PaymentFlowDoneAction) => {
    onKycComplianceFormDone();
    if (action === 'syncQBOAccountingPlatform') {
      return loginToAccountPlatformAuth();
    }
    if (shouldRedirectToNewDashboard) {
      return navigate(generateNPEDashboardLink('payments'));
    }

    replaceToPayDashboardTab(PayDashboardTabs.Scheduled);
  };

  return (
    <EditSinglePaymentActivity
      paymentId={paymentId}
      onBack={handleClose}
      onClose={handleClose}
      onDone={handleDone}
      isLoading={isLoginToAccountPlatformAuthLoading}
      missingKycComplianceInformation={missingKycComplianceInformation}
      setKycComplianceFormState={setKycComplianceFormState}
    />
  );
};
