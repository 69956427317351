import { Group, Popover, StatusIconOutlined, Table, TableCellProps, Text } from '@melio/penny';
import { Bill, PaymentIntent, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

export type BillDetailsCellProps = TableCellProps & {
  vendor: Vendor;
  paymentIntent: PaymentIntent;
  bills: Bill[];
  onViewBillDetailsClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
};

export const BillDetailsCell = forwardRef<BillDetailsCellProps, 'div'>(
  ({ paymentIntent, bills, vendor, onViewBillDetailsClick, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const shouldShowInvoiceNumberTooltip = !!bills[0] && !!bills[0].invoice.number && bills[0].paymentRequest;

    const showBillDetails = () => {
      if (bills[0]) {
        onViewBillDetailsClick(paymentIntent.id, bills[0]);
      }
    };

    const onClick = bills.length === 1 ? showBillDetails : undefined;

    return (
      <Table.Cell
        data-testid="bill-details-cell"
        {...props}
        data-component="BillDetailsCell"
        onClick={onClick}
        ref={ref}
      >
        <Group width="full" variant="vertical" spacing="xxs">
          <Text shouldSupportEllipsis textStyle="body3" color="inherit">
            {vendor.name}
          </Text>
          {bills.length === 1 && bills[0] ? (
            <Group spacing="xs" alignItems="center">
              <Text
                shouldSupportEllipsis
                textStyle="body4"
                color={bills[0].invoice.number ? 'neutral.darkest' : 'neutral.darker'}
              >
                {bills[0].invoice.number
                  ? formatMessage(
                      'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.number',
                      { invoiceNumber: bills[0].invoice.number }
                    )
                  : formatMessage(
                      'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.placeholder'
                    )}
              </Text>
              {shouldShowInvoiceNumberTooltip && (
                <Popover
                  description={formatMessage(
                    'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.description'
                  )}
                  actionProps={{
                    onClick: showBillDetails,
                    label: formatMessage(
                      'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.billNumber.tooltip.actionLabel'
                    ),
                  }}
                  triggerEvent="hover"
                >
                  <StatusIconOutlined variant="informative" size="small" data-testid="invoice-number-tooltip" />
                </Popover>
              )}
            </Group>
          ) : (
            <Text textStyle="body4" color="neutral.darkest">
              {formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.numberOfBills', {
                numberOfBills: bills.length,
              })}
            </Text>
          )}
        </Group>
      </Table.Cell>
    );
  }
);

BillDetailsCell.displayName = 'BillDetailsCell';
