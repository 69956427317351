import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string } from 'yup';

import { isValidOptionalPhone } from '../utils';
import { VendorFormFields } from './types';
import {
  useAccountNumberSchema,
  useAddressLine1Schema,
  useAddressLine2Schema,
  useBankAccountSchema,
  useCitySchema,
  useCompanyNameSchema,
  useNicknameSchema,
  useZipCodeSchema,
} from './VendorDetailsBaseSchema';

export const useUnmanagedVendorFormExtendedSchema = ({
  showCompanyField,
  isEmailRequired = false,
}: {
  showCompanyField: boolean;
  isEmailRequired?: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const companyNameSchema = useCompanyNameSchema({ showCompanyField, isManaged: false });
  const accountNumberSchema = useAccountNumberSchema();
  const nicknameSchema = useNicknameSchema();
  const zipCodeSchema = useZipCodeSchema();
  const AddressLine1Schema = useAddressLine1Schema();
  const AddressLine2Schema = useAddressLine2Schema();
  const citySchema = useCitySchema();
  const bankAccountSchema = useBankAccountSchema();

  const emailSchema = string().email(formatMessage('widgets.vendors.email.validation.format'));

  return object()
    .shape({
      email: isEmailRequired
        ? emailSchema.required(formatMessage('widgets.vendors.email.validation.required'))
        : emailSchema,
      phone: string()
        .required(formatMessage('widgets.vendors.phone.validation.required'))
        .test('valid-phone', formatMessage('widgets.vendors.phone.validation.format'), isValidOptionalPhone)
        .matches(/^[2-9]{1}/, formatMessage('widgets.vendors.phone.validation.beginsWithOne')),
      state: string().required(formatMessage('widgets.vendors.state.validation.required')),
    })
    .concat(AddressLine1Schema)
    .concat(AddressLine2Schema)
    .concat(citySchema)
    .concat(zipCodeSchema)
    .concat(companyNameSchema)
    .concat(nicknameSchema)
    .concat(accountNumberSchema)
    .concat(bankAccountSchema) as SchemaOf<Omit<VendorFormFields, 'fullName'>>;
};
