import { AccountingPlatformSyncConnectionErrorEnum, SyncFlowEnum } from '@melio/ap-activities';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useSubscriptionFeature } from '@melio/subscriptions';

import { useAccountingPlatformConnect, useAccountingPlatformDisconnect } from '@/hooks/accountingPlatforms.hooks';
import { AccountingPlatformCard } from './AccountingPlatformCard.widget';

type Props = {
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  organizationId: string;
  accountingPlatform: AccountingPlatform;
};

export const AccountingPlatformCardXero = ({ syncError, organizationId, accountingPlatform }: Props) => {
  const { accountingSlug } = accountingPlatform;

  const { isLoading: isLoadingConnect, connectToAccountingPlatform } = useAccountingPlatformConnect({
    flowToReturn: SyncFlowEnum.Setting,
    accountingPlatformId: accountingPlatform.id,
  });

  const { isLoading: isLoadingDisconnect, disconnectAccountingPlatform } = useAccountingPlatformDisconnect({
    accountingPlatformId: accountingPlatform.id,
    accountingSlug,
    organizationId,
  });

  const { tryUseFeature: tryUseConnectXeroFeature } = useSubscriptionFeature({ featureName: 'accountingPlatformXero' });

  if (accountingSlug !== AccountingPlatformSlug.Xero) {
    return null;
  }

  return (
    <AccountingPlatformCard
      accountingPlatform={accountingPlatform}
      onConnectClicked={() => tryUseConnectXeroFeature({ onFeatureIsEligible: connectToAccountingPlatform })}
      onDisconnectClicked={disconnectAccountingPlatform}
      isLoading={isLoadingDisconnect || isLoadingConnect}
      syncError={syncError}
    />
  );
};
