import { Navigate } from 'react-router-dom';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { RedirectType, useAppRedirectLink } from '@/hooks/useAppRedirect.hooks';

export const AppRedirectRoute = () => {
  const { link, type, isLoading } = useAppRedirectLink();

  if (!link || isLoading) {
    return <Loader isAbsoluteCenter />;
  }

  if (type === RedirectType.External) {
    window.location.href = link;
    return null;
  }

  return <Navigate to={link} replace={true} />;
};
