import { useNavigationWithQueryParams, useVendorNotPermitted } from '@melio/ap-domain';
import {
  EbillCellBadge,
  LockedVendorIcon,
  useVendorDirectoryInfoComplete,
  VendorAvatar,
  VendorMissingInfoIcon,
} from '@melio/ap-widgets';
import { Group, NakedButton, Table, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor, VendorEBillStatusEnum } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';

import { useActivitiesNavigate } from '../../../../../utils';
import { FocusSkipToComponent } from '../../../FocusSkipToComponent';
import { VENDORS_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID } from '../consts';

export type VendorsTabVendorNameCellProps = {
  vendor: Vendor;
  rowSelectedCount?: number;
  onEditClick: VoidFunction;
};

export const VendorsTabVendorNameCell = ({
  vendor,
  rowSelectedCount = 0,
  onEditClick,
}: VendorsTabVendorNameCellProps) => {
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();
  const isVendorNotPermitted = useVendorNotPermitted(vendor);
  const { navigateToEbillActivationForm, navigateToAutopayForm } = useActivitiesNavigate();
  const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
  const { formatMessage } = useMelioIntl();

  const onVendorNameClick = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    e.stopPropagation();
    track('Dashboard', 'Click', {
      Intent: 'view-vendor',
      Cta: 'vendor-details',
      VendorId: vendor.id,
    });
    handleNavigationWithQueryParams({ newPath: `vendor/${vendor.id}` });
  };

  const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);

  const showEBillBadge = vendor.eBillStatus !== VendorEBillStatusEnum.NotCapable;
  const description = [vendor.nickname, vendor.accountNumber].filter(identity).join(' | ');
  const { name: vendorName, externalLogoUrl: vendorExternalLogoUrl, isManaged: isVendorManaged } = vendor;
  return (
    <Table.Cell data-testid={`vendors-tab-row-${vendor.id}-vendor-name-cell`}>
      <Group alignItems="center">
        {!isExtraSmallScreen && (
          <VendorAvatar name={vendorName} logoUrl={vendorExternalLogoUrl} isManaged={isVendorManaged} />
        )}
        <Group variant="vertical" spacing="xxs">
          <Group alignItems="center" spacing="xxs">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}
            <NakedButton
              shouldSupportEllipsis
              variant="secondary"
              label={vendor.name}
              data-testid={`vendors-tab-row-${vendor.id}-vendor-name-cell-button`}
              aria-haspopup="dialog"
              aria-label={
                showEBillBadge
                  ? formatMessage('activities.payDashboard.vendorsTab.table.cells.vendorName.link.ariaLabel', {
                      vendorName: vendor.name,
                      eBillStatus: formatMessage(
                        `activities.payDashboard.vendorsTab.table.cells.vendorName.eBillStatus.${vendor.eBillStatus}.ariaLabel` as MessageKey
                      ),
                    })
                  : undefined
              }
              onClick={onVendorNameClick}
            />
            {!isVendorDirectoryInfoCompleted ? <VendorMissingInfoIcon onEditClick={onEditClick} /> : null}
            {showEBillBadge && (
              <EbillCellBadge
                vendor={vendor}
                onEBillSubscribeClick={(vendorId) => navigateToEbillActivationForm({ vendorId })}
                onAutoPayActivationClick={(vendorId) => navigateToAutopayForm({ vendorId })}
                isReadOnly={isVendorNotPermitted}
              />
            )}
          </Group>
          <Text textStyle="body4" color="neutral.darker">
            {description}
          </Text>
          <FocusSkipToComponent
            disable={rowSelectedCount === 0}
            text="activities.payDashboard.vendorsTab.selectionFooter.reviewAndPay.skipTo"
            href={`#${VENDORS_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID}`}
            dataTestid="vendors-pay-and-review"
          />
        </Group>
      </Group>
    </Table.Cell>
  );
};
