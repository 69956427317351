import { RoleUniqueNames } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';

export const useAllowedActions = () => {
  const { can } = usePermissions();
  const { settings } = useConfig();

  const canEditCollaborator = ({ roleUniqueName }: { roleUniqueName: RoleUniqueNames | null }) =>
    can({ action: 'update', subject: 'collaborator', subjectData: { roleUniqueName } });

  const canInviteCollaborator =
    can({ action: 'create', subject: 'invitation' }) && settings.collaborator.isAddCollaboratorEnabled;

  const canViewPaymentApprovalLimit =
    settings.isPaymentApprovalLimitEnabled && can({ action: 'read', subject: 'approvalWorkflow:approvalLimit' });

  return { canInviteCollaborator, canEditCollaborator, canViewPaymentApprovalLimit };
};
