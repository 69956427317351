import { ApiPagination, CustomersDashboardSortQueryParam, DashboardCustomer, useIsMobile } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { CustomersTable, CustomersTableMobileToolbar, CustomersTableToolbar, EmptyTableState } from '../components';
import { CustomerActions, SetCustomersDashboardParams } from '../types';

export type CustomersTableScreenProps = {
  customers: DashboardCustomer[];
  tableActions: CustomerActions;
  pagination: ApiPagination;
  isLoading?: boolean;
  search?: string;
  sortParams?: CustomersDashboardSortQueryParam;
  onParamsChange: SetCustomersDashboardParams;
  onCreateCustomer?: VoidFunction;
};
export const CustomersTabScreen = forwardRef<CustomersTableScreenProps>(
  (
    {
      customers,
      tableActions,
      pagination,
      isLoading,
      onCreateCustomer,
      search = '',
      onParamsChange,
      sortParams,
      ...props
    },
    ref
  ) => {
    const noData = customers.length === 0 && !isLoading;
    const shouldDisplayNoResults = !search && noData;
    const shouldDisplayEmptySearchResult = !!(search && noData);

    useAnalyticsView('Dashboard', !isLoading, true, {
      ProductName: 'ar',
      PageName: 'dashboard-customers',
      TabName: 'customers',
      Flow: 'dashboard',
      CountInTab: pagination.totalCount,
      Intent: 'view-ar-customers',
    });

    const { track } = useAnalytics();
    const isMobile = useIsMobile();

    return (
      <Group
        width="full"
        variant="vertical"
        data-component={CustomersTabScreen.displayName}
        data-testid="customers-tab-screen"
        {...props}
        ref={ref}
      >
        {isMobile ? (
          <CustomersTableMobileToolbar
            search={search}
            onSearch={(searchTerm) => onParamsChange({ searchTerm })}
            sortParams={sortParams}
            onSort={(sortBy, sortOrder) => onParamsChange({ sortBy, sortOrder })}
          />
        ) : (
          <CustomersTableToolbar
            search={search}
            onSearch={(searchTerm) => onParamsChange({ searchTerm })}
            onCreateCustomer={() => {
              track('Dashboard', 'Click', {
                ProjectName: 'ar',
                PageName: 'dashboard',
                TabName: 'customers',
                Flow: 'add-customer',
                Intent: 'create-customer',
                Cta: 'new-customer',
              });
              onCreateCustomer?.();
            }}
          />
        )}
        <CustomersTable
          customers={customers}
          tableActions={tableActions}
          pagination={pagination}
          isLoading={isLoading}
          sortParams={sortParams}
          onSort={(sortBy, sortOrder) => onParamsChange({ sortBy, sortOrder })}
        />

        {shouldDisplayNoResults && (
          <EmptyTableState onCreateCustomer={onCreateCustomer} variant="no-data" ref={ref} {...props} />
        )}
        {shouldDisplayEmptySearchResult && (
          <EmptyTableState
            onClearSearch={() => onParamsChange({ searchTerm: '' })}
            variant="no-search-results"
            ref={ref}
            {...props}
          />
        )}
      </Group>
    );
  }
);
CustomersTabScreen.displayName = 'CustomersTabScreen';
