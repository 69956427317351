import { Icon, IconProps } from '@chakra-ui/react';

export const InvoiceItemsIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M13.1786 7.28571C13.1786 6.63481 12.6509 6.10714 12 6.10714H9.64286C8.99195 6.10714 8.46429 6.63481 8.46429 7.28571C8.46429 7.93662 8.99195 8.46429 9.64286 8.46429H12C12.6509 8.46429 13.1786 7.93662 13.1786 7.28571Z"
      fill="#212124"
    />
    <path
      d="M12 10.8214C12.6509 10.8214 13.1786 11.3491 13.1786 12C13.1786 12.6509 12.6509 13.1786 12 13.1786H9.64286C8.99195 13.1786 8.46429 12.6509 8.46429 12C8.46429 11.3491 8.99195 10.8214 9.64286 10.8214H12Z"
      fill="#212124"
    />
    <path
      d="M18.3348 7.28571C18.3348 6.63481 17.8072 6.10714 17.1562 6.10714H16.3378C15.6869 6.10714 15.1592 6.63481 15.1592 7.28571C15.1592 7.93662 15.6869 8.46429 16.3378 8.46429H17.1562C17.8072 8.46429 18.3348 7.93662 18.3348 7.28571Z"
      fill="#212124"
    />
    <path
      d="M17.1562 10.8214C17.8072 10.8214 18.3348 11.3491 18.3348 12C18.3348 12.6509 17.8072 13.1786 17.1562 13.1786H16.3378C15.6869 13.1786 15.1592 12.6509 15.1592 12C15.1592 11.3491 15.6869 10.8214 16.3378 10.8214H17.1562Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.27256 15.7503L4.25984 3.75292C4.25823 2.23299 5.48992 1 7.00984 1H20.25C21.7688 1 23 2.23122 23 3.75V19.6389C23 20.561 22.5447 21.3899 21.9753 21.9628C21.4049 22.5366 20.5744 23 19.6389 23H3.86224C2.83678 23 2.10664 22.3065 1.70822 21.7383C1.29233 21.1453 1 20.3681 1 19.6389V18.5003C1 16.9815 2.23122 15.7503 3.75 15.7503H4.27256ZM20.25 3.35714C20.467 3.35714 20.6429 3.53303 20.6429 3.75V19.6389C20.6429 19.7882 20.5525 20.0506 20.3035 20.3011C20.0554 20.5507 19.7947 20.6429 19.6389 20.6429C19.4775 20.6429 19.2166 20.5499 18.9721 20.3053C18.7275 20.0604 18.6349 19.7993 18.6349 19.6389V18.5003C18.6349 16.9815 17.4037 15.7503 15.8849 15.7503H6.6297L6.61698 3.75042C6.61675 3.53329 6.79271 3.35714 7.00984 3.35714H20.25ZM15.8849 18.1074C16.1019 18.1074 16.2778 18.2833 16.2778 18.5003V19.6389C16.2778 19.9904 16.3428 20.328 16.4548 20.6429H3.8925C3.88643 20.6396 3.87793 20.6346 3.86692 20.6272C3.81114 20.5896 3.72769 20.5127 3.63809 20.3849C3.44364 20.1077 3.35714 19.7936 3.35714 19.6389V18.5003C3.35714 18.2833 3.53303 18.1074 3.75 18.1074H15.8849Z"
      fill="#212124"
    />
  </Icon>
);
