import { useInvoicePreviewPDF } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { usePdfDownloader } from './hooks';
import { PreviewInvoiceScreen } from './screens';

export type PreviewInvoiceActivityProps = {
  onError?: ARErrorFunction;
  onClose: VoidFunction;
  invoiceId: string;
};

export const PreviewInvoiceActivity = forwardRef<PreviewInvoiceActivityProps>(
  ({ invoiceId, onError, ...props }, ref) => {
    const { data, isLoading, error } = useInvoicePreviewPDF({ id: invoiceId });
    useEffect(() => {
      if (error) {
        onError?.(error);
      }
    }, [onError, error]);

    const { downloadPdf } = usePdfDownloader();

    return (
      <PreviewInvoiceScreen
        isLoading={isLoading}
        onDownload={() => downloadPdf(data?.split(',')[1] || '', `${invoiceId}.pdf`)}
        previewContent={data || ''}
        ref={ref}
        error={error}
        {...props}
      />
    );
  }
);

PreviewInvoiceActivity.displayName = 'PreviewInvoiceActivity';
