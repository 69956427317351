/* eslint-disable max-lines */
import { isCollaboratorBlocked } from '@melio/ap-domain';
import { Drawer, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  Collaborator,
  FundingSourceEntitlementType,
  PermissionLevelEnum,
  RoleUniqueNames,
  useApprovalLimit,
  useApprovalWorkflows,
  useCollaborator,
  useCollaborators,
  useFundingSources,
  useUpdateCollaborator,
  useUpdateCollaboratorsFundingSourceEntitlements,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useIsSubscriptionsEnabled, useSubscriptionFeature } from '@melio/subscriptions';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';

import { useCollaboratorsApprovalLimit } from '../../settings/Collaborators/components/hooks/useCollaboratorsApprovalLimit';
import { UnsavedChangesModalActivity } from '../../unsaved-changes-modal';
import { CollaboratorDrawerBody } from '../components/body/CollaboratorDrawerBody';
import { CollaboratorDrawerFooter } from '../components/footer/CollaboratorDrawerFooter';
import { CollaboratorDrawerHeader } from '../components/header/CollaboratorDrawerHeader';
import { FormFields } from '../types';
import { useAllowedActions } from '../useAllowedActions';
import { useSchema } from './useSchema';
import { useUnsavedChanges } from './useUnsavedChanges';

export type EditCollaboratorDrawerActivityProps = {
  collaboratorId: Collaborator['id'];
  onClose: VoidFunction;
  onSuccess: (collaboratorId: Collaborator['id']) => void;
  onError: (collaboratorId: Collaborator['id'], error: PlatformError) => void;
};
export const EditCollaboratorDrawerActivity = withAnalyticsContext<EditCollaboratorDrawerActivityProps>(
  ({ collaboratorId, onClose, onSuccess, onError, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { onSubmissionStateChange, submitButtonProps, cancelButtonProps, getValues, formState } =
      useFormSubmissionController<FormFields>();

    const {
      canEditCollaborator,
      canViewFundingSourcesEntitlements,
      canUpdateFundingSourcesEntitlements,
      canEditPaymentApprovalLimit,
      canViewPaymentApprovalLimit,
    } = useAllowedActions();
    const {
      settings: { isPaymentApprovalLimitEnabled },
    } = useConfig();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState<RoleUniqueNames | undefined>();

    const { data: actor, isLoading: isLoadingActor } = useCollaborator({ id: 'me' });
    const { data: collaborator, isLoading: isLoadingCollaborator } = useCollaborator<'user'>({
      id: collaboratorId,
      params: { expand: canViewFundingSourcesEntitlements ? ['user', 'fundingSourceEntitlements'] : ['user'] },
    });
    const { data: collaborators, isLoading: isLoadingCollaborators } = useCollaborators({});
    const { data: fundingSources, isLoading: isLoadingFundingSources } = useFundingSources({
      enabled: canViewFundingSourcesEntitlements,
    });
    const { update: updateCollaborator, isUpdating: isUpdatingCollaborator } = useUpdateCollaborator({
      id: collaboratorId,
    });
    const {
      data: approvalWorkflows,
      isLoading: isLoadingApprovalWorkflows,
      refetch: refetchApprovalWorkflows,
    } = useApprovalWorkflows({
      enabled: isPaymentApprovalLimitEnabled,
    });
    const { getApprovalThresholdValue } = useCollaboratorsApprovalLimit({
      approvalWorkflows,
      userId: collaborator?.userId || '',
    });
    const { setApprovalLimit, isMutating: isUpdatingApprovalLimit } = useApprovalLimit();
    const { update: updateCollaboratorEntitlements, isUpdating: isUpdatingCollaboratorEntitlements } =
      useUpdateCollaboratorsFundingSourceEntitlements();
    const paymentApprovalLimit = getApprovalThresholdValue();

    const isSubscriptionEnabled = useIsSubscriptionsEnabled();
    const collaboratorsInSubscriptionPlan = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];
    const { tryUseFeature, quota } = useSubscriptionFeature({
      featureName: 'collaborators',
      requirements: { totalUnits: collaboratorsInSubscriptionPlan.length + 1 },
    });

    const hasEligibleRoleForApprovalLimit = (selectedRole?: RoleUniqueNames) =>
      canViewPaymentApprovalLimit({
        collaborator,
        collaboratorRoleUniqueName: selectedRole || collaborator?.roleUniqueName,
      });

    const schema = useSchema({ showPaymentApprovalLimit: hasEligibleRoleForApprovalLimit(selectedRole) });

    const {
      isCollaboratorDataChanged,
      isCollaboratorEntitlementChanged,
      isPaymentApprovalLimitChanged,
      hasUnsavedChanges,
    } = useUnsavedChanges({
      actor,
      collaborator,
      fundingSources,
      paymentApprovalLimit: hasEligibleRoleForApprovalLimit(selectedRole) ? paymentApprovalLimit : undefined,
    });

    setAnalyticsProperties({
      Product: 'ap',
      PageName: 'user-details',
      Flow: 'user-management',
      ManagedUserId: collaborator?.userId,
      UserUniqueRole: actor?.roleUniqueName,
    });

    useEffect(() => {
      if (actor && collaborator) {
        track('User', 'View', {
          ToggleState: collaborator.permissionLevel === PermissionLevelEnum.Full ? 'on' : 'off',
          ManagedUserRole: collaborator.roleUniqueName,
          Intent:
            canEditCollaborator({ roleUniqueName: collaborator.roleUniqueName }) && actor.id !== collaborator.id
              ? 'edit-user'
              : 'view-user',
          PaymentMethodAccess: collaborator.fundingSourceEntitlements?.entitlementType
            ? collaborator.fundingSourceEntitlements.entitlementType === FundingSourceEntitlementType.All
              ? 'full'
              : 'partial'
            : null,
          PaymentApprovalLimit: paymentApprovalLimit !== null,
          PaymentApprovalLimitAmount: paymentApprovalLimit,
        });
      }
    }, [actor, canEditCollaborator, collaborator, paymentApprovalLimit, track]);

    const submitChanges = async (data: FormFields) => {
      if (!collaborator) {
        return;
      }

      const commonAnalyticsProps = {
        Intent: 'update-user-permissions',
        Cta: 'save',
        ToggleState: data.permissionLevel === PermissionLevelEnum.Full ? 'on' : 'off',
        ManagedUserRole: data.roleUniqueName,
        PaymentMethodAccess:
          data.entitlementsFundingSourceType === FundingSourceEntitlementType.All ? 'full' : 'partial',
        PaymentApprovalLimit: data.paymentApprovalLimit !== null,
        PaymentApprovalLimitAmount: data.paymentApprovalLimit,
      };

      try {
        await Promise.all(
          compact([
            isCollaboratorEntitlementChanged(getValues?.()) &&
              fundingSources &&
              updateCollaboratorEntitlements({
                entitlements: [
                  {
                    userId: collaborator.userId,
                    entitlementType: data.entitlementsFundingSourceType,
                    fundingSourceIds:
                      data.entitlementsFundingSourceType === FundingSourceEntitlementType.All
                        ? fundingSources.map((fs) => fs.id)
                        : data.entitlementsFundingSourceIds,
                  },
                ],
              }),

            isCollaboratorDataChanged(getValues?.()) &&
              updateCollaborator({ roleUniqueName: data.roleUniqueName, permissionLevel: data.permissionLevel }),
          ])
        );

        if (isPaymentApprovalLimitChanged(getValues?.()) && hasEligibleRoleForApprovalLimit(selectedRole)) {
          await setApprovalLimit({ userId: collaborator.userId, approvalLimit: data.paymentApprovalLimit ?? null });
        }

        track('User', 'Status', {
          ...commonAnalyticsProps,
          StatusType: 'success',
          ErrorType: 'user-updated',
          AlertShown: willSurchargeAdditionalSeat(data.permissionLevel) ? 'additional-user-surcharge' : null,
        });

        if (isUnsavedChangesModalOpen) {
          setIsUnsavedChangesModalOpen(false);
        }

        refetchApprovalWorkflows();
        onClose();
        onSuccess(collaboratorId);
      } catch (e) {
        track('User', 'Status', {
          ...commonAnalyticsProps,
          StatusType: 'failure',
          ErrorType: 'user-updated',
          AlertShown: willSurchargeAdditionalSeat(data.permissionLevel) ? 'additional-user-surcharge' : null,
        });

        onError(collaboratorId, e as PlatformError);
      }
    };

    const handleSubmit = async (data: FormFields) => {
      track('User', 'Click', {
        Intent: 'update-user-permissions',
        Cta: 'save',
        AlertShown: willSurchargeAdditionalSeat(data.permissionLevel) ? 'additional-user-surcharge' : null,
        ToggleState: data.permissionLevel === PermissionLevelEnum.Full ? 'on' : 'off',
        ManagedUserRole: data.roleUniqueName,
        PaymentMethodAccess:
          data.entitlementsFundingSourceType === FundingSourceEntitlementType.All ? 'full' : 'partial',
        PaymentApprovalLimit: data.paymentApprovalLimit !== null,
        PaymentApprovalLimitAmount: data.paymentApprovalLimit,
      });

      if (collaborator) {
        if (isCollaboratorBlocked(collaborator) && data.permissionLevel === PermissionLevelEnum.Full) {
          tryUseFeature({ onFeatureIsEligible: () => submitChanges(data) });
        } else {
          await submitChanges(data);
        }
      }
    };

    const handleCancel = () => {
      track('User', 'Click', {
        Intent: 'update-user-permissions',
        Cta: 'cancel',
        AlertShown:
          getValues?.('permissionLevel') && willSurchargeAdditionalSeat(getValues('permissionLevel'))
            ? 'additional-user-surcharge'
            : null,
      });

      if (isUnsavedChangesModalOpen) {
        setIsUnsavedChangesModalOpen(false);
      }
      setIsDrawerOpen(false);
    };

    const handleDrawerClose = () => {
      track('User', 'Click', {
        Intent: 'update-user-permissions',
        Cta: 'exit',
        AlertShown:
          getValues?.('permissionLevel') && willSurchargeAdditionalSeat(getValues('permissionLevel'))
            ? 'additional-user-surcharge'
            : null,
      });

      if (hasUnsavedChanges(getValues?.())) {
        setIsUnsavedChangesModalOpen(true);
      } else {
        setIsDrawerOpen(false);
      }
    };

    const handleFormChange = () => {
      setSelectedRole(getValues?.('roleUniqueName'));
    };

    const willSurchargeAdditionalSeat = (permissionLevelIntention: PermissionLevelEnum) =>
      isSubscriptionEnabled &&
      quota?.excessUnitFee &&
      collaboratorsInSubscriptionPlan.length >= quota.freeUnitsLimit &&
      permissionLevelIntention === PermissionLevelEnum.Full &&
      formState?.defaultValues?.permissionLevel === PermissionLevelEnum.Blocked;

    const isLoading =
      isLoadingActor ||
      isLoadingCollaborator ||
      isLoadingCollaborators ||
      isLoadingApprovalWorkflows ||
      isLoadingFundingSources;
    return (
      <>
        <Drawer
          data-testid="edit-collaborator-drawer"
          isOpen={isDrawerOpen}
          onClose={handleDrawerClose}
          onCloseComplete={onClose}
          isLoading={isLoading}
          closeButtonAriaLabel={formatMessage('activities.collaboratorDrawer.closeButtonAriaLabel')}
          header={<CollaboratorDrawerHeader />}
          body={
            collaborator && actor ? (
              <CollaboratorDrawerBody
                isLoading={isLoading}
                isSaving={isUpdatingCollaborator || isUpdatingApprovalLimit || isUpdatingCollaboratorEntitlements}
                onSubmissionStateChange={onSubmissionStateChange}
                onSubmit={handleSubmit}
                schema={schema}
                onChange={handleFormChange}
                formFieldsSettings={{
                  firstName: { readonly: true, required: true, hidden: false },
                  lastName: { readonly: true, required: true, hidden: false },
                  email: { readonly: true, required: true, hidden: false },
                  permissionLevel: {
                    readonly: false,
                    required: true,
                    hidden:
                      !canEditCollaborator({ roleUniqueName: collaborator.roleUniqueName }) ||
                      actor.id === collaborator.id,
                  },
                  roleUniqueName: {
                    readonly:
                      !canEditCollaborator({ roleUniqueName: collaborator.roleUniqueName }) ||
                      actor.id === collaborator.id,
                    required: true,
                    hidden: false,
                  },
                  paymentApprovalLimit: {
                    readonly: !canEditPaymentApprovalLimit,
                    required: canEditPaymentApprovalLimit && hasEligibleRoleForApprovalLimit(selectedRole),
                    hidden: !hasEligibleRoleForApprovalLimit(selectedRole),
                  },
                  entitlementsFundingSourceIds: {
                    readonly: !canUpdateFundingSourcesEntitlements || actor.id === collaborator.id,
                    required: true,
                    hidden: !canViewFundingSourcesEntitlements && actor.id !== collaborator.id,
                  },
                  entitlementsFundingSourceType: {
                    readonly: !canUpdateFundingSourcesEntitlements || actor.id === collaborator.id,
                    required: true,
                    hidden: !canViewFundingSourcesEntitlements || actor.id === collaborator.id,
                  },
                }}
                defaultValues={{
                  firstName: collaborator.user.firstName,
                  lastName: collaborator.user.lastName,
                  email: collaborator.user.email ?? undefined,
                  permissionLevel: collaborator.permissionLevel ?? undefined,
                  roleUniqueName: collaborator.roleUniqueName ?? undefined,
                  paymentApprovalLimit: paymentApprovalLimit ?? null,
                  entitlementsFundingSourceIds:
                    actor.id === collaborator.id
                      ? fundingSources?.map(({ id }) => id) ?? []
                      : collaborator.fundingSourceEntitlements?.fundingSourceIds ?? [],
                  entitlementsFundingSourceType: collaborator.fundingSourceEntitlements?.entitlementType,
                }}
              />
            ) : null
          }
          footer={
            collaborator &&
            actor &&
            canEditCollaborator({ roleUniqueName: collaborator.roleUniqueName }) &&
            actor.id !== collaborator.id ? (
              <CollaboratorDrawerFooter
                submitLabel={formatMessage('activities.collaboratorDrawer.edit.footer.cta')}
                submitButtonProps={submitButtonProps}
                cancelLabel={formatMessage('activities.collaboratorDrawer.edit.footer.tertiary')}
                cancelButtonProps={{ ...cancelButtonProps, onClick: handleCancel }}
              />
            ) : null
          }
        />

        <UnsavedChangesModalActivity
          isOpen={isUnsavedChangesModalOpen}
          onClose={() => setIsUnsavedChangesModalOpen(false)}
          submitButtonProps={submitButtonProps}
          cancelButtonProps={{ ...cancelButtonProps, onClick: handleCancel }}
        />
      </>
    );
  }
);
