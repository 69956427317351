import { useModelApi, UseModelApiProps } from '@melio/api-client';
import {
  FxCurrency,
  InternationalApiClient,
  InternationalApiGetInternationalRateRequest,
} from '@melio/platform-api-axios-client';

const getInternationalRate = (foreignCurrency?: string, melioAccount?: string, foreignAmount?: number) =>
  InternationalApiClient.getInternationalRate(foreignCurrency as FxCurrency, melioAccount, foreignAmount);

export type UseInternationalRateProps = UseModelApiProps<typeof getInternationalRate> &
  InternationalApiGetInternationalRateRequest;

export const useInternationalRate = (props: UseInternationalRateProps) => {
  const { isLoading, isFetching, ...rest } = useModelApi({
    ...props,
    enabled: props?.enabled !== false && !!props.foreignCurrency && props.foreignCurrency !== 'USD',
    id: 'InternationalApiRate',
    cacheTime: 0,
    queryKey: ['InternationalApi', props.foreignCurrency, props?.foreignAmount],
    queryFn: () => getInternationalRate(props.foreignCurrency, props.melioAccount, props.foreignAmount),
  });

  return {
    ...rest,
    isLoading: isLoading || isFetching,
  };
};
