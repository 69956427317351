import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLocation } from '@melio/platform-utils';

import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { CreatePaymentScreen } from '@/screens/schedule-payment/payment-flow/CreatePayment.screen';
import { EditPaymentScreen } from '@/screens/schedule-payment/payment-flow/EditPayment.screen';

type PaymentRouteState = {
  amount?: string;
  vendorId?: string;
  deliveryMethodId?: string;
  returnUrl?: string;
};

export const PaymentRoute = () => {
  const { state } = useLocation();
  const { vendorId, deliveryMethodId, amount, returnUrl } = (state as PaymentRouteState) || {};

  return (
    <Routes>
      <Route
        path="/new/*"
        element={
          <CreatePaymentScreen
            vendorId={vendorId}
            deliveryMethodId={deliveryMethodId}
            amountToPay={amount}
            returnUrl={returnUrl}
          />
        }
      />
      <Route
        path="/:paymentId/edit/*"
        element={
          <RouterComponentWrapper
            Component={EditPaymentScreen}
            componentProps={{
              returnUrl,
              paymentId: {
                _pathParam: 'paymentId',
              },
            }}
          />
        }
      />
      <Route path="*" element={<Navigate replace to="new" />} />
    </Routes>
  );
};
