import { UnilateralFormFields } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  DeliveryMethod,
  useDeliveryMethods,
  useUnilateralRequests,
  useVendor,
  Vendor,
  VirtualAccountDeliveryMethod,
} from '@melio/platform-api';
import { isEmpty } from 'lodash';

import { RequestVendorUnilateralDetailsScreen } from './screens';
import { RequestVendorUnilateralDetailsActivityProps, SubmissionTargetType } from './types';

export const RequestVendorUnilateralDetailsActivity = withAnalyticsContext<RequestVendorUnilateralDetailsActivityProps>(
  ({ vendorId, onBack, onClose, onError, onDone }) => {
    const deliveryMethodsCollection = useDeliveryMethods({ vendorId, enabled: false });
    const vendorModel = useVendor({ id: vendorId });
    const unilateralRequestsCollection = useUnilateralRequests({ vendorId });

    const { toast } = useToast();

    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message });
      onError?.(error);
    };

    const handleSuccess = (...onDoneData: Parameters<typeof onDone>) => {
      toast({ type: 'success', title: 'Request sent' });
      onDone(...onDoneData);
    };

    const { createTrackHandler } = useAnalytics();
    const submitHandler = createTrackHandler<
      | { Status: 'failed' }
      | {
          Status: 'succeeded';
          vendorId: Vendor['id'];
          deliveryMethod: DeliveryMethod;
        }
    >('RequestDM', 'Sent');
    const submitFailedHandler = (error: PlatformError) => submitHandler({ Status: 'failed' }, handleFail(error));
    const submitSucceededHandler = (...onDoneData: Parameters<typeof onDone>) =>
      submitHandler({ Status: 'succeeded', vendorId, deliveryMethod: onDoneData[0] }, handleSuccess(...onDoneData));

    const createDeliveryMethod = (data: UnilateralFormFields) => {
      const newVendorContactData = {
        ...(!vendorModel.data?.contact?.email ? { email: data.email } : null),
        ...(!vendorModel.data?.contact?.phoneNumber && data.phoneNumber ? { phoneNumber: data.phoneNumber } : null),
      };

      if (!isEmpty(newVendorContactData)) {
        void vendorModel.update({
          contact: newVendorContactData,
        });
      }

      return deliveryMethodsCollection.create({ type: 'virtual-account', details: data });
    };

    const sendUnilateralRequest = (virtualDeliveryMethodId: DeliveryMethod['id']) =>
      unilateralRequestsCollection.create({ originalDeliveryMethodId: virtualDeliveryMethodId });

    const handleDone = (data: UnilateralFormFields, target?: SubmissionTargetType) => {
      createDeliveryMethod(data)
        .then((data) => {
          sendUnilateralRequest(data.id)
            .then(() => {
              submitSucceededHandler(data as VirtualAccountDeliveryMethod, target);
            })
            .catch((error) => submitFailedHandler(error));
        })
        .catch((error) => submitFailedHandler(error));
    };

    return (
      <RequestVendorUnilateralDetailsScreen
        isLoading={vendorModel.isLoading}
        onBack={onBack}
        onClose={onClose}
        onDone={(data, _event, target) => handleDone(data, target)}
        isSaving={deliveryMethodsCollection.isMutating || unilateralRequestsCollection.isMutating}
        defaultValues={{
          email: vendorModel.data?.contact?.email || '',
          phoneNumber: vendorModel.data?.contact?.phoneNumber || '',
        }}
        vendorName={vendorModel.data?.name || ''}
      />
    );
  }
);
