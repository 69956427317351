import { AddBillFormWidget, AddBillFormWidgetFields } from '@melio/ap-widgets';
import { Container, Group, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { AccountingPlatform } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

type AddManualBillScreenProps = {
  isSaving?: boolean;
  isLoading?: boolean;
  defaultValues?: Partial<AddBillFormWidgetFields>;
  activeAccountingPlatform?: AccountingPlatform;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: AddBillFormWidgetFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
  isInternationalSupportedForRecurringFlow?: boolean;
};

export const AddManualBillScreen = withAnalyticsContext<AddManualBillScreenProps>(
  ({
    setAnalyticsProperties,
    isSaving,
    defaultValues,
    onBack,
    onClose,
    onDone,
    isLoading,
    isInternationalSupportedForRecurringFlow,
    activeAccountingPlatform,
    ...props
  }) => {
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
      useFormSubmissionController<AddBillFormWidgetFields>();
    const [isRecurringFrequency, setIsRecurringFrequency] = useState<boolean>(false);
    const [isRecurringPaymentsEnabled] = useFeature<boolean>(FeatureFlags.RecurringPayments, false);

    useAnalyticsView('AddBillInfo');

    setAnalyticsProperties({
      PageName: 'add-bill-details',
    });

    return (
      <NewSinglePaymentStepLayout
        data-component="AddManualBillActivity.AddManualBillScreen"
        data-testid="add-manual-bill-activity-add-manual-bill-screen"
        isLoading={isLoading}
        {...props}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <Container width="full">
              <Group variant="vertical">
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  onClick={handleSubmit?.('continue')}
                  label={formatMessage('activities.addManualBill.screens.addManualBill.continue')}
                />
                {!isRecurringFrequency && (
                  <NewSinglePaymentStepLayout.SecondaryNextButton
                    {...submitButtonProps}
                    onClick={handleSubmit?.('close')}
                    label={formatMessage('activities.addManualBill.screens.addManualBill.close')}
                  />
                )}
              </Group>
            </Container>
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <NewSinglePaymentStepLayout.Title>
          {formatMessage('activities.addManualBill.screens.addManualBill.title')}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          <AddBillFormWidget
            supportRecurringPayment={isRecurringPaymentsEnabled}
            isInternationalSupportedForRecurringFlow={isInternationalSupportedForRecurringFlow}
            onSubmit={onDone}
            isSaving={isSaving}
            defaultValues={defaultValues}
            onUpdateFrequency={setIsRecurringFrequency}
            onSubmissionStateChange={onSubmissionStateChange}
            activeAccountingPlatform={activeAccountingPlatform}
          />
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddManualBillScreen.displayName = 'AddManualBillActivity.AddManualBillScreen';
