import React from 'react';
import { defaults } from 'lodash';
import { date, object, SchemaOf, string } from 'yup';
import { FormWidgetProps, VendorSelectWidget } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';

export type ReviewScannedInvoiceFormProps = FormWidgetProps<ReviewScannedInvoiceFormFields> & {
  editMode?: boolean;
};

export type ReviewScannedInvoiceFormFields = {
  vendorId: string;
  totalAmount: string; //it's should be number but platform not support number input
  dueDate: Date | null;
  billNumber: string;
  noteToSelf: string;
};

export const useSchema = () => {
  const { formatMessage } = usePlatformIntl();

  return object().shape({
    vendorId: string().required(formatMessage('widgets.ReviewScannedInvoice.form.vendor.validation.required')),
    totalAmount: string()
      .required(formatMessage('widgets.ReviewScannedInvoice.form.amount.validation.required'))
      .test(
        'validMoreThenZero',
        formatMessage('widgets.ReviewScannedInvoice.form.amount.validation.gtZero'),
        (value?: string) => {
          const number = Number(value);
          return number > 0;
        },
      ),
    dueDate: date().nullable().required(formatMessage('widgets.ReviewScannedInvoice.form.dueDate.validation.required')),
    billNumber: string(),
    noteToSelf: string(),
  }) as SchemaOf<ReviewScannedInvoiceFormFields>;
};

export const ReviewScannedInvoiceForm = forwardRef<ReviewScannedInvoiceFormProps, 'form'>(
  (
    { onSubmit, defaultValues: _defaultValues, onSubmissionStateChange, isSaving, isDisabled, editMode, ...props },
    ref,
  ) => {
    const defaultValues = defaults(_defaultValues, {
      vendorId: '',
      totalAmount: undefined,
      dueDate: null,
      billNumber: '',
      noteToSelf: '',
    });

    const { formatMessage } = usePlatformIntl();

    const { formProps, registerField } = useMelioForm<ReviewScannedInvoiceFormFields>({
      onSubmit,
      isSaving,
      schema: useSchema(),
      defaultValues,
      onSubmissionStateChange,
    });

    return (
      <Form
        size="large"
        columns={2}
        isViewMode={!editMode}
        ref={ref}
        {...props}
        {...formProps}
        isDisabled={isDisabled || formProps.isDisabled}
      >
        <VendorSelectWidget
          {...registerField('vendorId')}
          placeholder={formatMessage('widgets.ReviewScannedInvoice.form.vendor.placeholder')}
          labelProps={{ label: formatMessage('widgets.ReviewScannedInvoice.form.vendor.label') }}
          colSpan={2}
          eventContextName="ReviewScannedInvoice"
        />
        <Form.AmountField
          {...registerField('totalAmount')}
          labelProps={{ label: formatMessage('widgets.ReviewScannedInvoice.form.amount.label') }}
          colSpan={2}
        />
        <Form.TextField
          {...registerField('billNumber')}
          labelProps={{ label: formatMessage('widgets.ReviewScannedInvoice.form.billNumber.label') }}
          placeholder={formatMessage('widgets.ReviewScannedInvoice.form.billNumber.placeholder')}
        />
        <Form.DateField
          {...registerField('dueDate')}
          labelProps={{ label: formatMessage('widgets.ReviewScannedInvoice.form.dueDate.label') }}
          placeholder={formatMessage('widgets.ReviewScannedInvoice.form.dueDate.placeholder')}
        />
        <Form.TextField
          {...registerField('noteToSelf')}
          labelProps={{ label: formatMessage('widgets.ReviewScannedInvoice.form.note.label') }}
          placeholder={formatMessage('widgets.ReviewScannedInvoice.form.note.placeholder')}
          colSpan={2}
        />
      </Form>
    );
  },
);

ReviewScannedInvoiceForm.displayName = 'ReviewScannedInvoiceForm';
