import { Button, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, useSubscription } from '../../../../hooks';
import { SUBSCRIPTION_SETTINGS_PLANS_ROUTE } from '../../../constants';
import { useSubscriptionRouter } from '../../../utils';

export const FreePlanCardHeaderFiserv = () => {
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const { planName } = usePlanInfo(subscription?.planId);

  const onClickHandler = () => {
    goToSubscriptionPlans({ returnUrl: SUBSCRIPTION_SETTINGS_PLANS_ROUTE });
  };

  return (
    <Group width="full" justifyContent="space-between" alignItems="center" spacing="s">
      <Container alignItems="center">
        <Group variant="vertical" spacing="xxxs">
          <Text textStyle="body2Semi" as="h4">
            {planName}
          </Text>
        </Group>
      </Container>

      <Button
        variant="primary"
        data-testid="subscription-basic-plan-upgrade"
        label={formatMessage('activities.settings.subscriptionPlanSettings.upgradePlan')}
        onClick={onClickHandler}
        link={{
          href: '#',
        }}
      />
    </Group>
  );
};
