import React from 'react';
import { Center } from '@chakra-ui/react';
import { Loader, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useFeeReceipts } from '@melio/platform-api';

import { ReceiptsTable } from '@/cl/components/ReceiptsTable/ReceiptsTable.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePlatformIntl } from '@/translations/Intl';
import { createPdfFile } from '@/utils/fees.utils';

export const ReceiptsTableWidget = () => {
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const content = formatMessage('widgets.billingSettings.content.empty');
  const dateLabel = formatMessage('widgets.billingSettings.content.table.header.date');
  const amountLabel = formatMessage('widgets.billingSettings.content.table.header.amount');
  const receiptLabel = formatMessage('widgets.billingSettings.content.table.header.receipt');
  const errorMessage = formatMessage('widgets.billingSettings.content.error');
  const labels = {
    date: dateLabel,
    amount: amountLabel,
    receipt: receiptLabel,
  };
  const { isLoading, error, data, get } = useFeeReceipts();

  const onDownload = (id: string) => {
    track('BillingSettingDownloadPaymentsHistory', 'Chose');
    get(id).then((pdfData) => createPdfFile(pdfData, `Melio-Receipt-${id}`));
  };

  if (error) {
    return <div>{errorMessage}</div>;
  }
  if (!data) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <WithLoading isLoading={isLoading}>
      {data?.length === 0 && <Text>{content}</Text>}
      {data?.length > 0 && <ReceiptsTable receipts={data} labels={labels} onDownload={onDownload} />}
    </WithLoading>
  );
};
