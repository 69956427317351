import { useRoleText } from '@melio/ap-domain';
import { Form, Typography } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

import type { RoleUniqueNames } from '../../../../../types';

type Props = Omit<ComponentProps<typeof Form.RadioGroup>, 'options' | 'aria-label'> & {
  availableRoleOptions: RoleUniqueNames[];
  selectedRoleUniqueName: RoleUniqueNames;
  title: string;
};
export const RoleRadioGroup = ({ availableRoleOptions, title, selectedRoleUniqueName, ...props }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { getRoleText } = useRoleText();

  return (
    <>
      <Form.RadioGroup
        labelProps={{
          label: title,
        }}
        options={availableRoleOptions.map((roleUniqueName) => ({
          value: roleUniqueName,
          mainLabelProps: {
            label: getRoleText(roleUniqueName),
          },
        }))}
        {...props}
      />
      <Form.ContentBox colSpan={props.colSpan}>
        <Typography.Description
          label={formatMessage(
            `activities.manageCollaborators.screens.userDetailsForm.permissions.${selectedRoleUniqueName}` as MessageKey
          )}
        />
      </Form.ContentBox>
    </>
  );
};
