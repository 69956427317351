import { Box } from '@chakra-ui/react';
import { CatalogItem, FormInputs, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Form, GridItem, Group, IconButton, SimpleGrid, TextField, Typography, useFormContext } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useUpdateEffect } from '@melio/platform-utils';
import Big from 'big.js';
import { useCallback, useEffect } from 'react';

import { useInvoiceFormContext } from '../../../utils';

type InvoiceItemFormProps = {
  index: number;
  catalogItems?: CatalogItem[];
  onTaxable?: VoidFunction;
  hasDeleteColumn?: boolean;
};

export const InvoiceLineItemForm = forwardRef<InvoiceItemFormProps>(
  ({ index, catalogItems, onTaxable, hasDeleteColumn, ...props }, ref) => {
    const { formatMessage, formatCurrency } = useMelioIntl();
    const { lineItemsFieldArray, watch, setValue, registerField, cancelButtonProps, clearErrors } =
      useInvoiceFormContext();

    const { isDisabled } = useFormContext();

    const { track } = useAnalytics();

    const field = lineItemsFieldArray.fields[index];

    // convenience constants
    const FieldName = {
      catalogItemId: `lineItems.${index}.catalogItemId`,
      quantity: `lineItems.${index}.quantity`,
      price: `lineItems.${index}.price`,
      taxable: `lineItems.${index}.taxable`,
      amount: `lineItems.${index}.amount`,
    } as const;

    // current values
    const [catalogItemId, taxable, price, quantity] = watch([
      FieldName.catalogItemId,
      FieldName.taxable,
      FieldName.price,
      FieldName.quantity,
    ]);
    const lineItems = watch('lineItems');

    const currentCatalogItemIds = lineItems.map((item) => item.catalogItemId);

    useEffect(() => {
      const catalogItem = catalogItems?.find((item) => item.id === catalogItemId);
      setValue(FieldName.quantity, 1);
      setValue(FieldName.price, catalogItem?.price ?? 0);
      setValue(FieldName.taxable, catalogItem?.taxable ?? false);
      clearErrors(FieldName.quantity);
      clearErrors(FieldName.price);
      clearErrors(FieldName.taxable);
    }, [catalogItemId]); // eslint-disable-line react-hooks/exhaustive-deps

    useUpdateEffect(() => {
      if (taxable) {
        onTaxable?.();
      }
    }, [taxable]); // eslint-disable-line react-hooks/exhaustive-deps

    const total = new Big(price || 0).mul(quantity || 1).toNumber();

    const TaxCheckBox = useCallback(
      () => <Form.Checkbox aria-label="is taxable" {...registerField(FieldName.taxable)} isDisabled={!catalogItemId} />,
      [FieldName.taxable, catalogItemId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const DeleteButton = useCallback(
      () => (
        <IconButton
          icon="delete"
          size="small"
          aria-label="Remove line item"
          variant="naked"
          onClick={() => {
            lineItemsFieldArray.remove(index);
            track('Invoice', 'Click', {
              Intent: 'delete-invoice-item',
              Cta: 'remove-item',
            });
          }}
          isDisabled={cancelButtonProps.isDisabled || isDisabled}
          data-testid={`remove-item-button.${index}`}
        />
      ),
      [cancelButtonProps.isDisabled, index, isDisabled] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const isMobile = useIsMobile();
    return (
      <SimpleGrid
        columns={[12, 12, 24]}
        spacing="s"
        key={field?.id}
        data-testid="line-item-form-wrapper"
        {...props}
        ref={ref}
      >
        <GridItem colSpan={12}>
          {field?._current ? (
            <TextField value={field._current.name} size="small" isReadOnly />
          ) : (
            <FormInputs.CatalogItemSelect
              {...registerField(FieldName.catalogItemId)}
              placeholder={formatMessage(
                'ar.invoiceLifecycle.activities.invoiceTable.inputs.selectCatalog.placeholder.text'
              )}
              aria-label={formatMessage(
                'ar.invoiceLifecycle.activities.invoiceTable.inputs.selectCatalog.placeholder.text'
              )}
              labelProps={
                isMobile
                  ? { label: formatMessage('ar.invoiceLifecycle.activities.invoiceTable.columns.catalogItem.label') }
                  : undefined
              }
              excludeIds={currentCatalogItemIds}
            />
          )}
        </GridItem>
        <GridItem colSpan={isMobile ? 3 : 2}>
          <FormInputs.NumberField
            aria-label="quantity"
            allowDecimal
            step={1}
            {...registerField(FieldName.quantity)}
            isDisabled={!catalogItemId}
            labelProps={
              isMobile
                ? { label: formatMessage('ar.invoiceLifecycle.activities.invoiceTable.columns.quantity.label') }
                : undefined
            }
          />
        </GridItem>
        <GridItem colSpan={isMobile ? 5 : 4}>
          <FormInputs.AmountField
            aria-label="price"
            {...registerField(FieldName.price)}
            isDisabled={!catalogItemId}
            labelProps={
              isMobile
                ? { label: formatMessage('ar.invoiceLifecycle.activities.invoiceTable.columns.price.label') }
                : undefined
            }
          />
        </GridItem>
        <GridItem
          colSpan={isMobile ? 4 : 3}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          height="full"
          textStyle="body3Semi"
          opacity={catalogItemId ? 1 : 0.3}
          data-testid={FieldName.amount}
        >
          {isMobile ? (
            <Group variant="vertical" height="full">
              <Typography.Label
                label={formatMessage('ar.invoiceLifecycle.activities.invoiceTable.columns.amount.label')}
              />
              <Box>{formatCurrency(total)}</Box>
            </Group>
          ) : (
            formatCurrency(total)
          )}
        </GridItem>

        {isMobile ? (
          <GridItem colSpan={hasDeleteColumn ? 10 : 4} display="flex" height="full">
            <Group variant="horizontal" alignItems="center" spacing="xs">
              <TaxCheckBox />
              <Box opacity={catalogItemId ? 1 : 0.3}>
                <Typography.Label
                  label={formatMessage('ar.invoiceLifecycle.activities.invoiceTable.columns.tax.mobile.label')}
                />
              </Box>
            </Group>
          </GridItem>
        ) : (
          <GridItem
            colSpan={hasDeleteColumn ? 2 : 3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="full"
          >
            <TaxCheckBox />
          </GridItem>
        )}
        {hasDeleteColumn && (
          <GridItem colSpan={1} display="flex" justifyContent="center" alignItems="center" height="full">
            {isMobile ? (
              <Group variant="horizontal" height="full">
                <Typography.Label label="&nbsp;" />
                <DeleteButton />
              </Group>
            ) : (
              <DeleteButton />
            )}
          </GridItem>
        )}
      </SimpleGrid>
    );
  }
);
InvoiceLineItemForm.displayName = 'InvoiceItemForm';
