import { Flex } from '@chakra-ui/react';
import { Badge, Button, Group, Icon, IconKey, Text } from '@melio/penny';
import * as React from 'react';

type SectionHeaderWithButtonProps = {
  title: string;
  buttonText?: string;
  buttonIcon?: IconKey;
  buttonTestId?: string;
  badgeText?: React.ReactNode;
  titleAs?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  onClick?: VoidFunction;
};

export const SectionHeaderWithButton = ({
  title,
  onClick,
  badgeText,
  buttonText,
  buttonIcon,
  buttonTestId,
  titleAs = 'h2',
}: SectionHeaderWithButtonProps) => (
  <Flex mb="m">
    <Group alignItems="center" justifyContent="space-between" width="full">
      <Group alignItems="center">
        <Text as={titleAs} textStyle="heading3Semi">
          {title}
        </Text>
        {badgeText && <Badge status="brand" label={badgeText as string} />}
      </Group>
      {onClick && (
        <Button
          leftElement={buttonIcon ? <Icon size="small" type={buttonIcon} color="inherit" aria-hidden /> : undefined}
          onClick={onClick}
          label={buttonText}
          data-testid={buttonTestId}
        />
      )}
    </Group>
  </Flex>
);
