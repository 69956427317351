import { CustomersDashboardSortQueryParam, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, SearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useCustomersTableSortableColumns } from '../hooks';
import { OnSort } from '../types';
import { SortMenu } from './SortMenu.mobile';

export type CustomersTableToolbarProps = {
  search?: string;
  onSearch: (value: string) => void;
  sortParams?: CustomersDashboardSortQueryParam;
  onSort: OnSort;
};

export const CustomersTableMobileToolbar = forwardRef<CustomersTableToolbarProps>(
  ({ search, onSearch, onSort, sortParams, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const sortableColumns = useCustomersTableSortableColumns();
    const sortMenuItems = Object.values(sortableColumns);

    return (
      <Group
        data-component={CustomersTableMobileToolbar.displayName}
        width="full"
        justifyContent="space-between"
        data-testid="customers-table-mobile-toolbar"
        {...props}
        ref={ref}
      >
        <SearchBar
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          placeholder={formatMessage('ar.dashboard.activities.customersTable.searchInput.label')}
          onClear={() => onSearch('')}
        />
        <Container width="fit-content">
          <SortMenu items={sortMenuItems} onSortChange={onSort} sortParams={sortParams} />
        </Container>
      </Group>
    );
  }
);
CustomersTableMobileToolbar.displayName = 'CustomersTableMobileToolbar';
