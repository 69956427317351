import { useMelioForm, UseMelioFormProps, UseMelioFormResults } from '@melio/penny';
import { FormEvent } from 'react';
import { Path } from 'react-hook-form';

import { useBannerApiError, useInlineApiError } from './apiErrorsUtils';
import { VendorFormBannerApiErrorCode, VendorFormFields, VendorFormInlineApiErrorCode } from './types';

type UseVendorFormParams<T extends VendorFormFields> = UseMelioFormProps<T> & {
  inlineApiErrorCodes?: VendorFormInlineApiErrorCode[];
  bannerApiErrorCodes?: VendorFormBannerApiErrorCode[];
};

export const useVendorForm = <T extends VendorFormFields>({
  inlineApiErrorCodes,
  bannerApiErrorCodes,
  onSubmit,
  ...useMelioFormParams
}: UseVendorFormParams<T>): UseMelioFormResults<T> => {
  const {
    registerField: baseRegisterField,
    formState,
    formProps: baseFormProps,
    ...rest
  } = useMelioForm<T>({ onSubmit, ...useMelioFormParams });

  const { fieldToApiErrorMessageMap } = useInlineApiError<T>(inlineApiErrorCodes);
  const { error } = useBannerApiError(bannerApiErrorCodes);

  const registerField = (field: Path<T>) => {
    const apiErrorMessage = fieldToApiErrorMessageMap[field];
    const fieldData = baseRegisterField(field);
    let error = fieldData.error;

    if (apiErrorMessage) {
      error = { message: apiErrorMessage, type: 'api-error' };
    }

    return {
      ...baseRegisterField(field),
      error,
    };
  };

  const formProps = {
    ...baseFormProps,
    error,
    onSubmit: (e: FormEvent) => {
      e.stopPropagation();
      baseFormProps.onSubmit(e);
    },
  };

  return { registerField, formProps, formState, ...rest };
};
