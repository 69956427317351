import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useFundingSourcePermissions } from '@melio/ap-domain';
import { NakedButton, SectionBanner } from '@melio/penny';
import { BankAccount, DeliveryMethodType, FundingSourceType } from '@melio/platform-api';

import { PaymentFullyExpanded } from '@/api/payments/payments.api.types';
import { MAX_WAITING_FOR_VENDOR_DELIVERY_METHOD_IN_DAYS } from '@/consts/PayDashboardConsts';
import { usePlatformIntl } from '@/translations/Intl';
import { addDays } from '@/utils/dates.util';
import { MicroDepositModal, MicroDepositModalRefHandlers } from '@/widgets/microDepositModal/MicroDepositModal.widget';

export const PaymentScheduledActionAlert = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { fundingSource, deliveryMethod, vendor } = payment;
  const microDepositRef = React.useRef<MicroDepositModalRefHandlers>(null);

  const onClick = () => microDepositRef.current?.openModal();

  let alertDescription = '';
  if (deliveryMethod.type === DeliveryMethodType.VirtualAccount) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.unilateral', {
      vendorName: vendor.name,
      date: formatDate(addDays(new Date(payment.scheduledDate), MAX_WAITING_FOR_VENDOR_DELIVERY_METHOD_IN_DAYS), {
        dateStyle: 'medium',
      }),
    });
  }

  const { canUpdate } = useFundingSourcePermissions({ fundingSource });

  // payment can't have virtual deliveryMethod and unverified paymentMethod at once (risk wise)
  if (canUpdate && fundingSource.type === FundingSourceType.BankAccount && !fundingSource.isVerified) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.text', {
      '4digits': (fundingSource.details as BankAccount).accountNumber.slice(-4),
      date: formatDate(new Date(payment.scheduledDate), {
        month: 'short',
        day: 'numeric',
      }),
      link: (
        <NakedButton
          variant="secondary"
          onClick={onClick}
          label={formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.link')}
        />
      ),
    });
  }

  return alertDescription ? (
    <Stack flexDirection="column" spacing={0}>
      <SectionBanner
        variant="warning"
        description={alertDescription}
        title={formatMessage('widgets.paymentDetails.scheduled.actionRequired.title')}
      />
      {fundingSource.type === FundingSourceType.BankAccount && (
        <MicroDepositModal ref={microDepositRef} fundingSourceId={payment.fundingSourceId} />
      )}
    </Stack>
  ) : null;
};
