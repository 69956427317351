import { DeliveryMethod, DeliveryMethodType, Vendor } from '@melio/platform-api';
import React from 'react';

import { PaymentFlowOnChangeHandlers } from '../../../../types';
import { SelectedDeliveryMethodCard } from './components/DeliveryMethodCard/SelectedDeliveryMethodCard';
import { Loading } from './components/Loading';
import { NotSelected } from './components/NotSelected';

type DeliveryMethodSectionProps = {
  selectedVendor: Vendor | undefined;
  selectedDeliveryMethod: DeliveryMethod | undefined;
  isRecurring: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'];
  onEditDeliveryMethod: (type: DeliveryMethodType) => void;
};
export const DeliveryMethodSection = ({
  selectedVendor,
  selectedDeliveryMethod,
  isRecurring,
  isDisabled,
  isLoading,
  onDeliveryMethodChange,
  onEditDeliveryMethod,
}: DeliveryMethodSectionProps) => {
  if (selectedVendor && selectedDeliveryMethod) {
    return (
      <SelectedDeliveryMethodCard
        selectedVendor={selectedVendor}
        selectedDeliveryMethod={selectedDeliveryMethod}
        isRecurring={isRecurring}
        isDisabled={isDisabled}
        onDeliveryMethodChange={onDeliveryMethodChange}
        onEditDeliveryMethod={onEditDeliveryMethod}
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <NotSelected
      onBankClick={() => onEditDeliveryMethod('bank-account')}
      onPaperCheckClick={() => onEditDeliveryMethod('paper-check')}
    />
  );
};
