import { SectionBanner, Text } from '@melio/penny';

export type BannerModalContent = {
  key: string;
  type: 'banner';
  title: string;
  explanation: string;
};

export type BannerTypeContentProps = Omit<BannerModalContent, 'key'>;

export const BannerTypeContent: React.FC<BannerTypeContentProps> = ({ title, explanation }) => (
  <SectionBanner description={<Text textStyle="body3">{explanation}</Text>} title={title} variant="informative" />
);
