import { useToast } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { FileType, useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useImperativeHandle, useRef } from 'react';

const MAX_FILE_COUNT = 10;

export type UploadBillFilesWidgetProps = {
  onUploadABill: (files: File[]) => void;
};

export const UploadBillFilesWidget = forwardRef<UploadBillFilesWidgetProps, 'input'>(
  ({ onUploadABill, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { fileSizeLimit, fileAllowedFormats } = useConfig().settings;
    const { toast } = useToast();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [isMultipleBillsUploadEnabled] = useFeature<boolean>(FeatureFlags.PlatformMultipleBillsUpload, false, {
      shouldTrack: true,
    });

    useImperativeHandle(ref, () => fileInputRef.current as HTMLInputElement, []);

    const isFileTypeNotAllowed = (file: File) => !fileAllowedFormats.includes(file.type?.split('/')[1] as FileType);

    const validateFileList = (list: File[]) => ({
      size: list.reduce((acc, file) => acc + file.size, 0) > fileSizeLimit,
      count: list.length > MAX_FILE_COUNT,
      type: list.every(isFileTypeNotAllowed),
    });

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const files = Array.from(e.target.files).map((file: File) => file);
        const validation = validateFileList(files);

        if (validation.size || validation.count) {
          let title = formatMessage(
            `widgets.uploadBillFilesWidget.validation.${files.length > 1 ? 'multiFileSize' : 'fileSize'}`
          );
          if (validation.count) {
            title = formatMessage('widgets.uploadBillFilesWidget.validation.fileCount');
          }
          if (validation.type) {
            title = formatMessage('widgets.uploadBillFilesWidget.validation.fileFormat');
          }
          toast({
            type: 'error',
            title,
          });
          // clear input so same file can be chosen again (if user wants)
          if (fileInputRef.current?.value) {
            fileInputRef.current.value = '';
          }
        } else {
          onUploadABill(files);
        }
      }
    };

    return (
      <input
        data-testid="upload-bill-files-widget"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileUpload}
        type="file"
        multiple={!!isMultipleBillsUploadEnabled}
        accept="image/png,image/jpeg,application/pdf"
        {...props}
      />
    );
  }
);

UploadBillFilesWidget.displayName = 'UploadBillFilesWidget';
