import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { GuestPaymentRequestErrorScreen } from './screens';

type GuestPaymentRequestErrorsActivityProps = {
  paymentRequestLink: string;
};

export const GuestPaymentRequestErrorsActivity = withAnalyticsContext<GuestPaymentRequestErrorsActivityProps>(
  forwardRef(({ setAnalyticsProperties, paymentRequestLink }, ref) => {
    setAnalyticsProperties({
      ProductName: 'ar',
      Flow: 'payment-request',
      PaymentRequestLink: paymentRequestLink,
    });

    return <GuestPaymentRequestErrorScreen errorType="network-error" ref={ref} />;
  })
);

GuestPaymentRequestErrorsActivity.displayName = 'GuestPaymentRequestErrorsActivity';
