/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AutoPaymentCancellationModalActivity,
  CancelEBillSubscriptionModalActivity,
  MonitoredAction,
} from '@melio/ap-activities';
import { DeleteVendorModal } from '@melio/ap-activities/src/components/PayDashboard/components/VendorsTab/components/DeleteVendor/DeleteVendorModal';
import { useVendorActions } from '@melio/ap-domain';
import { ErrorTypeToErrorCodesMap, getErrorsByType, useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { Drawer, LoadingContainer, Text, useDisclosure, useFormSubmissionController, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  LinkVendorToDirectoryParams,
  ModelError,
  UpdateVendorParams,
  useMelioQueryClient,
  useVendor,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';

import { usePlatformIntl } from '@/translations/Intl';
import { VendorDetailsFormFields } from '@/types/vendors.types';
import { useAnalyticsTaxIdType } from './hooks/useAnalyticsTaxIdType';
import { useVendorDrawerAnalytics } from './hooks/useVendorDrawerAnalytics';
import { VendorDrawerBody } from './VendorDrawerBody.widget';
import { VendorDrawerFooter } from './VendorDrawerFooter.widget';

const LOADING_CONTAINER_ID = 'VendorDrawerLoadingContainer';

export const VendorDrawer = withAnalyticsContext(
  ({
    onClose,
    vendorId,
    onEditBillSubscription,
  }: {
    onClose: VoidFunction;
    vendorId: string;
    onEditBillSubscription: ({ id }: { id: string }) => void;
  }) => {
    const { formatMessage } = useMelioIntl();
    const [isClosing, setIsClosing] = useState(false);

    // The expand here is to avoid a separate react query api call for the analytics
    const { data: vendor } = useVendor({ id: vendorId, params: { expand: ['billSubscriptions.nextOccurrence'] } });
    const analyticsTaxIdType = useAnalyticsTaxIdType(vendor);
    const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
    const {
      isOpen: isCancelEBillSubscriptionModalOpen,
      onOpen: onCancelEBillSubscriptionModalOpen,
      onClose: onCancelEBillSubscriptionModalClose,
    } = useDisclosure();
    const {
      isOpen: isAutoPayCancellationModalOpen,
      onOpen: onAutoPayCancellationModalOpen,
      onClose: onAutoPayCancellationModalClose,
    } = useDisclosure();
    const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
    const queryClient = useMelioQueryClient();

    const { isEditMode, setIsEditMode } = useEditMode();
    const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);
    const { submitButtonProps, cancelButtonProps, onSubmissionStateChange, onSubmit, handleResetForm, apiErrorsCodes } =
      useEditVendor({
        vendorId,
        isVendorDirectoryInfoCompleted,
      });

    const vendorActions = useVendorActions(vendor);
    const hasActions = Object.values(vendorActions).some(Boolean);

    const { track } = useAnalytics();
    useVendorDrawerAnalytics();

    const handleClose = () => {
      setIsEditMode(false);
      onClose();
    };

    const handleSuccess = () => {
      void queryClient.invalidateQueries('InboxItemsApi');
      void queryClient.invalidateQueries('PaymentsApi');
      void queryClient.invalidateQueries('delivery-method-type-options');
      handleClose();
    };

    useEffect(() => {
      if (!isEditMode) {
        handleResetForm();
      }
      if (vendor) {
        track('Vendor', 'View', {
          Intent: isEditMode ? 'edit-vendor' : 'view-vendor',
          PageName: 'vendor-details',
          RecurringPayments: vendor.billSubscriptions?.map((it) => ({ [it.id]: it.intervalType })),
          TaxId: analyticsTaxIdType,
        });
      }
    }, [isEditMode, vendor]);

    const handleCloseClick = () => {
      track('Vendor', 'Click', {
        Cta: 'exit',
      });

      setIsClosing(true);
    };

    const handleDeleteVendorClick = (vendor: Vendor) => {
      setSelectedVendor(vendor);
      onDeleteModalOpen();
    };

    const handleDeleteModalClose = () => {
      setSelectedVendor(null);
      onDeleteModalClose();
    };

    const onEditClick = () => {
      track('Vendor', 'Click', { Intent: 'edit-vendor', Cta: 'cancel' });
      setIsEditMode(true);
    };
    const onCancelEditClick = () => {
      track('Vendor', 'Click', { Intent: 'edit-vendor', Cta: 'cancel' });
      setIsEditMode(false);
    };

    return (
      <>
        <Drawer
          isOpen={!isClosing}
          onClose={handleCloseClick}
          onCloseComplete={handleClose}
          data-testid="pay-dashboard-vendor-drawer"
          closeButtonAriaLabel={formatMessage('widgets.vendorDrawer.closeButtonAriaLabel')}
          closeButtonAriaLabelledBy={LOADING_CONTAINER_ID}
          header={
            <Text as="h2" textStyle="heading2Semi">
              {formatMessage('widgets.vendorDrawer.header.title')}
            </Text>
          }
          body={
            <Suspense fallback={<LoadingContainer isLoading id={LOADING_CONTAINER_ID} />}>
              <VendorDrawerBody
                vendorId={vendorId}
                onSubmit={onSubmit}
                onSubmissionStateChange={onSubmissionStateChange}
                isEditMode={isEditMode}
                onCancelEBillSubscription={onCancelEBillSubscriptionModalOpen}
                onAutoPaymentCancellation={onAutoPayCancellationModalOpen}
                onEditBillSubscription={onEditBillSubscription}
                inlineApiErrorCodes={apiErrorsCodes?.inline}
                loadingContainerId={LOADING_CONTAINER_ID}
                onEditClick={onEditClick}
              />
            </Suspense>
          }
          footer={
            vendor && hasActions ? (
              <Suspense fallback={<LoadingContainer isLoading />}>
                <VendorDrawerFooter
                  vendor={vendor}
                  submitButtonProps={submitButtonProps}
                  cancelButtonProps={cancelButtonProps}
                  onDeleteClick={handleDeleteVendorClick}
                  isEditMode={isEditMode}
                  onEditClick={onEditClick}
                  onCancelClick={onCancelEditClick}
                />
              </Suspense>
            ) : null
          }
        />
        {selectedVendor && (
          <DeleteVendorModal
            vendor={selectedVendor}
            isOpen={selectedVendor && isDeleteModalOpen}
            onClose={handleDeleteModalClose}
            onSuccess={handleSuccess}
          />
        )}
        {vendor?.id && isAutoPayCancellationModalOpen ? (
          <AutoPaymentCancellationModalActivity
            isOpen={isAutoPayCancellationModalOpen}
            onClose={onAutoPayCancellationModalClose}
            vendorId={vendor.id}
            vendorName={vendor?.name || ''}
          />
        ) : null}
        {vendor?.id && (
          <CancelEBillSubscriptionModalActivity
            isOpen={isCancelEBillSubscriptionModalOpen}
            vendorId={vendor.id}
            onClose={onCancelEBillSubscriptionModalClose}
          />
        )}
      </>
    );
  },
);

const useEditVendor = ({
  vendorId,
  isVendorDirectoryInfoCompleted,
}: {
  vendorId: string;
  isVendorDirectoryInfoCompleted: boolean;
}) => {
  const { formatMessage } = usePlatformIntl();
  const { toast } = useToast();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { track } = useAnalytics();
  const { update: updateVendor, linkVendorToDirectory } = useVendor({
    id: vendorId,
    enabled: false,
    refetchOnMount: 'always',
  });
  const { setIsEditMode } = useEditMode();
  const { onSubmissionStateChange, submitButtonProps, cancelButtonProps, reset } =
    useFormSubmissionController<VendorDetailsFormFields>();
  const queryClient = useMelioQueryClient();
  const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();

  const handleResetForm = () => {
    reset?.();
    setApiErrorsCodes(undefined);
  };

  const makeUpdateVendorRequest = async (payload: UpdateVendorParams) => {
    if (isVendorDirectoryInfoCompleted) {
      return await updateVendor(payload);
    }

    return await linkVendorToDirectory(payload as LinkVendorToDirectoryParams);
  };

  const onSubmit = async (payload: UpdateVendorParams, throwErrors = false) => {
    setApiErrorsCodes(undefined);

    try {
      if (!vendorId) {
        return;
      }
      startAction('vendor_edit');
      track('Vendor', 'Click', { Intent: 'edit-vendor', Cta: 'save' });
      const payloadWithValidatedEmail = {
        ...payload,
        contact: payload.contact ? { ...payload.contact, email: payload.contact.email || null } : {},
      };

      const updatedVendor = await makeUpdateVendorRequest(payloadWithValidatedEmail);

      toast({
        type: 'success',
        title: formatMessage('widgets.editVendor.toast.success.withName', {
          vendorName: updatedVendor.name,
        }),
        id: 'edit-vendor-success',
      });

      track('Vendor', 'Status', { Intent: 'edit-vendor', Status: 'success' });
      void queryClient.invalidateQueries('InboxItemsApi');
      void queryClient.invalidateQueries('PaymentsApi');
      void queryClient.invalidateQueries('delivery-method-type-options');
      endAction('vendor_edit');
      setIsEditMode(false);
    } catch (e) {
      const { inline, banner } = getErrorsByType(e as ModelError, false);
      setApiErrorsCodes({ inline, banner });

      if (throwErrors) {
        throw e;
      }

      track('Vendor', 'Status', { Intent: 'edit-vendor', Status: 'failure' });

      if (!inline) {
        if (banner) {
          toast({
            type: 'error',
            title: formatMessage(`widgets.vendors.apiErrors.${banner[0]}`),
            id: 'edit-vendor-error',
          });
        } else {
          toast({
            type: 'error',
            title: formatMessage('widgets.editVendor.toast.error', {
              companyName: payload.name,
            }),
            id: 'edit-vendor-error',
          });

          setIsEditMode(false);
        }
      }
    }
  };

  return {
    onSubmissionStateChange,
    submitButtonProps,
    cancelButtonProps,
    onSubmit,
    handleResetForm,
    apiErrorsCodes,
  };
};

const useEditMode = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditMode = searchParams.get('edit') === 'true';
  const setIsEditMode = useCallback(
    (isEditable: boolean) =>
      setSearchParams(
        (params) => {
          if (isEditable) {
            params.set('edit', String(isEditable));
          } else {
            params.delete('edit');
          }
          return params;
        },
        { replace: true },
      ),
    [setSearchParams],
  );

  return { isEditMode, setIsEditMode };
};
