import { useInvoice, useMelioIntl } from '@melio/ar-domain';
import { Checkbox, Modal, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';

export type CancelInvoiceModalActivityProps = {
  onError?: ARErrorFunction;
  onClose: VoidFunction;
  onDone: VoidFunction;
  invoiceId?: string;
};

export const CancelInvoiceModalActivity = withAnalyticsContext<CancelInvoiceModalActivityProps>(
  forwardRef(({ setAnalyticsProperties, invoiceId, onClose, onError, onDone, ...props }, ref) => {
    const {
      data: invoice,
      isLoading,
      cancel: cancelInvoice,
      isMutating: isCancelingInvoice,
    } = useInvoice({ id: invoiceId, onError });
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const [shouldNotifyCustomer, { toggle: setShouldNotifyCustomer }] = useBoolean();

    setAnalyticsProperties({
      ProductName: 'ar',
      PageName: 'cancel-invoice',
      Flow: 'manage-invoice',
      Intent: 'cancel-invoice',
      InvoiceId: invoice?.id,
      InvoiceStatus: invoice?.displayStatus,
      DueDate: invoice?.dueDate,
    });

    const { track } = useAnalytics();

    useAnalyticsView('Invoice', true, true);

    const onCancelInvoice = () => {
      toast({
        type: 'informative',
        title: formatMessage('ar.dashboard.activities.invoiceTable.toasts.cancel.processing.label'),
      });
      track('Invoice', 'Click', {
        Cta: 'cancel-invoice',
      });
      void cancelInvoice({ shouldNotifyCustomer })
        .then(() => {
          toast({
            type: 'success',
            title: formatMessage('ar.dashboard.activities.invoiceTable.toasts.cancel.success.label'),
          });
          track('Invoice', 'Status', {
            StatusType: 'success',
            Cta: 'cancel-invoice',
          });
          onDone();
        })
        .catch((err: ARPlatformError) => {
          toast({ type: 'error', title: err.message });
          track('Invoice', 'Status', {
            StatusType: 'failure',
            Cta: 'cancel-invoice',
          });
          onError?.(err);
        });
    };

    return (
      <Modal
        data-testid="cancel-invoice-modal-activity"
        data-component={CancelInvoiceModalActivity.displayName}
        isLoading={isLoading}
        header={formatMessage('ar.invoiceLifecycle.activities.cancelInvoice.modal.header.text', {
          invoiceNumber: invoice?.invoiceNumber,
        })}
        description={formatMessage('ar.invoiceLifecycle.activities.cancelInvoice.modal.description.title.text')}
        isOpen={!!invoiceId}
        onClose={() => !isCancelingInvoice && onClose()}
        // TODO: https://meliorisk.atlassian.net/browse/ME-57709
        // disable close icon when `isCancelingInvoice`
        secondaryButton={{
          isDisabled: isCancelingInvoice,
          label: formatMessage('ar.invoiceLifecycle.activities.cancelInvoice.modal.buttons.cancel.label'),
          onClick: () => {
            track('Invoice', 'Click', {
              Intent: 'keep-invoice',
              Cta: 'cancel',
            });
            onClose();
          },
          variant: 'tertiary',
        }}
        primaryButton={{
          isLoading: isCancelingInvoice,
          label: formatMessage('ar.invoiceLifecycle.activities.cancelInvoice.modal.buttons.confirm.label'),
          variant: 'critical',
          onClick: onCancelInvoice,
        }}
        {...props}
        ref={ref}
      >
        <Checkbox
          isChecked={shouldNotifyCustomer}
          onChange={setShouldNotifyCustomer}
          data-testid="notify-customer-checkbox"
          label={formatMessage('ar.invoiceLifecycle.activities.cancelInvoice.modal.description.checkbox.text')}
        />
      </Modal>
    );
  })
);

CancelInvoiceModalActivity.displayName = 'CancelInvoiceModalActivity';
