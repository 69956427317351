import { useAnalyticsContext, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { FileInfo, FxCurrency, PaymentIntent, useFiles } from '@melio/platform-api';
import React from 'react';

import { usePaymentPurposeShowFields } from './PaymentPurpose.utils';
import { InternationalPaymentPurposeFormFields, InternationalPaymentPurposeScreen } from './screens';
import { InternationalPaymentPurposeModalScreen } from './screens/InternationalPaymentPurposeModalScreen';

export type PaymentPurposeUpdateData = {
  paymentPurpose: string;
  billInfo?: {
    invoice: {
      fileId: string;
    };
  };
};

type InternationalPaymentPurposeCommonProps = {
  onClose: VoidFunction;
  onDone: (data: PaymentPurposeUpdateData) => void;
  defaultValues?: Partial<InternationalPaymentPurposeFormFields>;
  vendorId: PaymentIntent['billInfo']['vendorId'];
  internationalCountryCode?: string;
  step?: number;
  totalSteps?: number;
  hasInvoice: boolean;
  currency?: FxCurrency;
};

type InternationalPaymentPurposeActivityProps = InternationalPaymentPurposeCommonProps & {
  onBack: VoidFunction;
  variation: 'screen';
};

type InternationalPaymentPurposeActivityModalProps = InternationalPaymentPurposeCommonProps & {
  isOpen: boolean;
  variation: 'modalScreen';
  isSaving: boolean;
};

type ActivityProps = InternationalPaymentPurposeActivityProps | InternationalPaymentPurposeActivityModalProps;

const isModal = (props: ActivityProps): props is InternationalPaymentPurposeActivityModalProps =>
  props.variation === 'modalScreen';

export const InternationalPaymentPurposeActivity: React.VFC<ActivityProps> = withAnalyticsContext<ActivityProps>(
  (props: ActivityProps) => {
    const {
      defaultValues,
      vendorId,
      onDone,
      step,
      totalSteps,
      onClose,
      internationalCountryCode,
      hasInvoice,
      currency,
    } = props;
    const { create: createFile, isMutating } = useFiles({ enabled: false });
    const {
      shouldShowInvoice,
      shouldShowDescription,
      shouldShowPaymentPurpose,
      isLoading: isShowInvoiceLoading,
      selectedCountryDetails,
    } = usePaymentPurposeShowFields({
      internationalCountryCode,
      vendorId,
      hasInvoice,
      currency,
    });

    const onFormSubmit = async ({
      invoice,
      paymentPurpose,
      purposeDescription,
    }: InternationalPaymentPurposeFormFields) => {
      let uploadedInvoice: FileInfo | undefined;
      if (invoice) {
        uploadedInvoice = await createFile(invoice);
      }

      const data = {
        paymentPurpose: purposeDescription ? `${paymentPurpose} ${purposeDescription}` : paymentPurpose,
        billInfo: uploadedInvoice?.id
          ? {
              invoice: {
                fileId: uploadedInvoice.id,
              },
            }
          : undefined,
      };

      onDone(data);
    };

    useAnalyticsContext({
      globalProperties: {
        PageName: 'whats-the-purpose-of-your-payment',
        Intent: 'add-delivery-method',
        DeliveryCountry: selectedCountryDetails?.name,
        CountryRiskScore: selectedCountryDetails?.risk,
      },
    });

    useAnalyticsView('Payment', true);

    return isModal(props) ? (
      <InternationalPaymentPurposeModalScreen
        isOpen={props.isOpen}
        onClose={onClose}
        onSubmit={onFormSubmit}
        isLoading={isShowInvoiceLoading}
        isSaving={props.isSaving}
        shouldShowInvoice={shouldShowInvoice}
        shouldShowDescription={shouldShowDescription}
        shouldShowPaymentPurpose={shouldShowPaymentPurpose}
        defaultValues={defaultValues}
        currency={currency}
      />
    ) : (
      <InternationalPaymentPurposeScreen
        onBack={props.onBack}
        onClose={onClose}
        step={step}
        totalSteps={totalSteps}
        onDone={onFormSubmit}
        isLoading={isShowInvoiceLoading}
        isSaving={isMutating}
        shouldShowInvoice={shouldShowInvoice}
        defaultValues={defaultValues}
        shouldShowDescription={shouldShowDescription}
        shouldShowPaymentPurpose={shouldShowPaymentPurpose}
        currency={currency}
      />
    );
  }
);
