import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useTheme } from '@melio/penny';

export const PaymentDetailsSection: React.FC<{
  gridGap?: number | string;
}> = ({ gridGap, children }) => {
  const theme = useTheme();

  return (
    <Stack
      flexDirection="column"
      data-component="PaymentDetailsSection"
      px={'40px'}
      pb={'24px'}
      mb={'24px'}
      spacing={0}
      gridGap={gridGap}
      borderBottom={`1px solid ${theme.colors.neutral.light}`}
      _last={{ borderBottom: 0, mb: 0, pb: 0 }}
    >
      {children}
    </Stack>
  );
};
