/* eslint-disable react-hooks/exhaustive-deps */
import { Box, keyframes } from '@chakra-ui/react';
import { useAccountingPlatformPollingSync } from '@melio/ap-domain';
import { Group, StatusIndicator, Text, useBreakpoint, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { AccountingPlatformConnectionStatus, AccountingPlatformSlug } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import { SyncIcon } from './SyncIcon';
import { SyncNowWidgetProps } from './types';

export const SyncNowWidget = withAnalyticsContext<SyncNowWidgetProps>(
  ({ accountingPlatform, analyticsProperties, setAnalyticsProperties, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { isExtraSmallScreen } = useBreakpoint();
    const { track, trackMarketing } = useAnalytics();
    const { accountingSlug, connectionStatus } = accountingPlatform;
    const isQuickBooksDesktopAccountingPlatform = accountingSlug === AccountingPlatformSlug.QuickBooksDesktop;

    setAnalyticsProperties(analyticsProperties);

    const getAccountingPlatformName = useCallback(() => {
      switch (accountingSlug) {
        case AccountingPlatformSlug.QuickBooksDesktop:
          return formatMessage('widgets.syncNow.accountingPlatformNames.quickBooksDesktop');
        case AccountingPlatformSlug.QuickBooksOnline:
          return formatMessage('widgets.syncNow.accountingPlatformNames.quickBooks');
        case AccountingPlatformSlug.Xero:
          return formatMessage('widgets.syncNow.accountingPlatformNames.xero');
        default:
          throw new Error('unkown accounting platform');
      }
    }, [accountingSlug]);

    const handleSyncDone = useCallback(() => {
      track('SyncAccountingSoftware', 'Status', {
        Status: 'success',
      });
      trackMarketing('active-accounting-software-sync', {
        Status: 'success',
      });

      const isConnected = connectionStatus === AccountingPlatformConnectionStatus.Connected;

      isConnected &&
        toast({
          type: 'success',
          title: formatMessage('widgets.syncNow.success.toast', {
            accountingPlatformName: getAccountingPlatformName(),
          }),
        });
    }, [track, trackMarketing, toast, formatMessage, getAccountingPlatformName, connectionStatus]);

    const handleSyncError = useCallback(() => {
      track('SyncAccountingSoftware', 'Status', {
        Status: 'failure',
      });
      toast({
        type: 'error',
        title: formatMessage('widgets.syncNow.error.toast', {
          accountingPlatformName: getAccountingPlatformName(),
        }),
      });
    }, [track, toast, formatMessage, getAccountingPlatformName]);

    const {
      triggerSync,
      isRunning: isSyncRunning,
      isError: isSyncError,
    } = useAccountingPlatformPollingSync({
      accountingPlatformId: accountingPlatform.id,
      isQuickBooksDesktop: isQuickBooksDesktopAccountingPlatform,
      onSyncDone: handleSyncDone,
      onSyncError: handleSyncError,
    });

    const handleSyncButtonClicked = useCallback(() => {
      track('SyncAccountingSoftware', 'Click', {
        Cta: 'sync',
      });
      void triggerSync();
    }, [track, triggerSync]);

    const syncIconProps = {
      isSyncRunning,
      accountingPlatform,
      isExtraSmallScreen,
      onClick: handleSyncButtonClicked,
    };

    const getStartSyncButton = () => {
      const { lastCompletionTime } = accountingPlatform;
      const tooltipLabel = lastCompletionTime
        ? formatMessage('widgets.syncNow.initial.tooltip.label', {
            lastSyncDate: `${lastCompletionTime.toLocaleString(undefined, {
              dateStyle: 'long',
            })}, ${lastCompletionTime.toLocaleString(undefined, {
              timeStyle: 'short',
            })}`,
          })
        : '';

      const tooltipTitle = formatMessage('widgets.syncNow.initial.tooltip.title', {
        accountingPlatformName: getAccountingPlatformName(),
      });

      return (
        <SyncIcon
          {...syncIconProps}
          ariaLabel={tooltipTitle}
          isError={false}
          tooltipTitle={tooltipTitle}
          tooltipLabel={tooltipLabel}
          tooltipTestId="sync-now-button-tooltip"
        />
      );
    };

    const getSyncErrorButton = () => {
      const tooltipTitle = formatMessage('widgets.syncNow.error.tooltip.title', {
        accountingPlatformName: getAccountingPlatformName(),
      });
      return (
        <StatusIndicator data-testid="sync-now-button-error-indicator" status="critical">
          <SyncIcon
            {...syncIconProps}
            ariaLabel={tooltipTitle}
            isError
            tooltipTitle={tooltipTitle}
            tooltipLabel={formatMessage('widgets.syncNow.error.tooltip.label')}
            tooltipTestId="sync-now-button-tooltip"
          />
        </StatusIndicator>
      );
    };

    const getSyncRunningButton = () => {
      const spin = keyframes`
    to {
      transform: rotate(360deg);
    }`;

      const runningIconAriaLabel = formatMessage('widgets.syncNow.running.icon.ariaLabel');

      return (
        <Box data-testid="sync-now-button-wrapper" css={{ animation: `${spin} 1.3s linear infinite` }}>
          <SyncIcon {...syncIconProps} ariaLabel={runningIconAriaLabel} isError={false} />
        </Box>
      );
    };

    const getSyncButton = () => {
      if (isSyncError) {
        return getSyncErrorButton();
      } else if (isSyncRunning) {
        return getSyncRunningButton();
      } else {
        return getStartSyncButton();
      }
    };

    return (
      <Group data-component="SyncNowWidget" data-testid="sync-now-wrapper" alignItems="center" {...props}>
        {getSyncButton()}
        {isSyncRunning && (
          <Text data-testid="sync-now-button-text" color="neutral.darker">
            {formatMessage('widgets.syncNow.running.text')}
          </Text>
        )}
      </Group>
    );
  }
);

SyncNowWidget.displayName = 'SyncNowWidget';
