import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { PaymentRequestSelectCompanyActivity } from '@melio/ap-activities';
import { useGuestPayorOnboarding } from '@melio/ar-domain/src/api-hooks';
import { ExternalLayout } from '@melio/penny';
import { useAccount, useOrganizations, usePaymentRequest } from '@melio/platform-api';

import { getAutoSelectedOrganizationId } from '@/utils/paymentRequests.utils';
import { useRouter } from '../../hooks/router.hooks';
import { useSwitchOrganization } from '../../hooks/useSwitchOrganization.hooks';
import { PayDashboardTabs } from '../../types/payDashboard.types';

type PaymentRequestEntryPointScreenProps = { paymentRequestId: string };

export const PaymentRequestEntryPointScreen: React.FC<PaymentRequestEntryPointScreenProps> = ({ paymentRequestId }) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>();
  const { goToPayDashboardTabLinkAndRefresh } = useRouter();
  const { onboarding } = useGuestPayorOnboarding();
  const { switchOrganization } = useSwitchOrganization();
  const { data: account, isLoading: isLoadingAccount } = useAccount({ id: 'me' });
  const { data: organizations, isLoading: isLoadingOrganizations } = useOrganizations();
  const { data: paymentRequest, isLoading: isLoadingPaymentRequest } = usePaymentRequest({ id: paymentRequestId });

  const redirectToPaymentRequestInTheDashboard = () =>
    goToPayDashboardTabLinkAndRefresh(PayDashboardTabs.Inbox, paymentRequestId);

  const handleOrganizationSelected = async () => {
    const shouldSwitchOrganization = selectedOrganizationId !== account?.organizationId;
    if (shouldSwitchOrganization) {
      await switchOrganization({
        organizationId: selectedOrganizationId as string,
        isAccountingFirm: false,
        skipPayDashboardRedirect: true,
      });
    }
    await onboarding({ paymentRequestLink: paymentRequest?.link as string });
    redirectToPaymentRequestInTheDashboard();
  };

  useEffect(() => {
    if (!selectedOrganizationId && organizations && paymentRequest) {
      setSelectedOrganizationId(getAutoSelectedOrganizationId(organizations, paymentRequest));
    }
  }, [selectedOrganizationId, organizations, paymentRequest]);

  useEffect(() => {
    if (selectedOrganizationId) {
      handleOrganizationSelected();
    }
    // selectedOrganizationId is the only dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  if (selectedOrganizationId || isLoadingOrganizations || isLoadingPaymentRequest || isLoadingAccount) {
    return <ExternalLayout isLoading />;
  }

  if (!paymentRequest || !organizations?.length) {
    return <Navigate to="/404" />;
  }

  return (
    <PaymentRequestSelectCompanyActivity
      organizations={organizations}
      vendorName={paymentRequest.vendor?.name as string}
      selectOrganization={(orgId) => setSelectedOrganizationId(orgId)}
    />
  );
};

PaymentRequestEntryPointScreen.displayName = 'PaymentRequestEntryPointScreen';
