import { _TableHeaderCellDescription, Group, Switch, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { SchedulePaymentIntent } from '../../../../types';
import { useCombinePaymentsToggle } from './CombinedPaymentsToggle.utils';

export type CombinedPaymentsToggleProps = {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  arePaymentsCombined?: boolean;
  onToggleCombinedPayments: (isChecked: boolean) => void;
  isToggling?: boolean;
};
export const CombinedPaymentsToggle = forwardRef<CombinedPaymentsToggleProps, 'div'>(
  ({ isToggling, paymentIntentsWithDerivatives, arePaymentsCombined, onToggleCombinedPayments }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { isDisabled: isToggleDisabled, getTooltip: getToggleTooltip } =
      useCombinePaymentsToggle(paymentIntentsWithDerivatives);

    const combinedTooltip = getToggleTooltip();

    return (
      <Group spacing="s" data-testid="combine-payment-toggle-wrapper" ref={ref}>
        <_TableHeaderCellDescription
          text={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.label'
          )}
        />
        <Tooltip label={combinedTooltip} shouldAddTriggerFocus={isToggleDisabled || isToggling}>
          <Switch
            size="small"
            data-testid="combine-payment-toggle"
            defaultIsChecked={arePaymentsCombined}
            onChange={onToggleCombinedPayments}
            isDisabled={isToggleDisabled || isToggling}
            aria-label={formatMessage(
              'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.switch.ariaLabel',
              { switchDescription: combinedTooltip }
            )}
          />
        </Tooltip>
      </Group>
    );
  }
);

CombinedPaymentsToggle.displayName = 'CombinedPaymentsToggle';
