import { Route } from 'react-router-dom';

import { SubscriptionTabsEnum } from '../constants';
import { TAB_PREFIX } from '../utils';
import { SubscriptionSelectFundingSource } from './SubscriptionPaymentMethodSelect/SubscriptionSelectFundingSource.activity';
import { SubscriptionSuccessActivity } from './SubscriptionSuccess';
import { SubscriptionWizardActivity } from './SubscriptionWizard';

export const SubscriptionPlansRoute = () => (
  <>
    <Route path={`/${TAB_PREFIX}/*`} element={<SubscriptionWizardActivity />} />
    <Route path={`/${TAB_PREFIX}/${SubscriptionTabsEnum.Success}/*`} element={<SubscriptionSuccessActivity />} />
    <Route path={`/${TAB_PREFIX}/payment-method/select`} element={<SubscriptionSelectFundingSource />} />
  </>
);
