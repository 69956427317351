import { Flex } from '@chakra-ui/react';
import { ActionsDropdownMenu, Badge, Container, Group, Icon, Text, useBreakpoint } from '@melio/penny';
import {
  BillSubscription,
  BillSubscriptionIntervalTypeEnum,
  BillSubscriptionManagedBy,
  BillSubscriptionStatus,
  Vendor,
} from '@melio/platform-api';
import { convertAmountToCurrency } from '@melio/platform-utils';

import { cancelSubscriptionById } from '@/api/billSubscriptions.api';
import { queryClient } from '@/queries/reactQueryClient';
import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';
import { CancelPaymentDialog } from '@/widgets/pay-dashboard/payment-details/components/general/CancelPaymentDialog';
import { useRecurringPaymentCardActions } from './useRecurringPaymentCardActions';

type RecurringPaymentCardProps = {
  billSubscription: WithRequiredProperty<BillSubscription, 'nextOccurrence'>;
  vendor: Vendor;
  onEditBillSubscription: ({ id }: { id: string }) => void;
};

const intervalTypeCapitalize = (intervalType: BillSubscriptionIntervalTypeEnum) =>
  intervalType.charAt(0).toUpperCase() + intervalType.slice(1).replaceAll('_', ' ');

export const RecurringPaymentCard = ({
  billSubscription,
  vendor,
  onEditBillSubscription,
}: RecurringPaymentCardProps) => {
  const { formatMessage, formatCurrency, formatDate } = usePlatformIntl();
  const { isCancelDialogOpen, closeCancelDialog, actionMenuItems, isMenuOpen, setIsMenuOpen } =
    useRecurringPaymentCardActions({ billSubscription, vendor, onEditBillSubscription });
  const { isExtraSmallScreen } = useBreakpoint();

  const isBillSubscriptionManagedByFiserv = billSubscription.managedBy === BillSubscriptionManagedBy.Fiserv;

  // 1. sync from fiserv knows to properly sync subscription status but not the occurrenceNumber of occurrences
  // 2. fiserv subscriptions have only one outstanding payment so if the status is done it means the next occurrence is the last one
  const isLastPayment = isBillSubscriptionManagedByFiserv
    ? billSubscription.status === BillSubscriptionStatus.Done
    : billSubscription.nextOccurrence.occurrenceNumber === billSubscription.numOfOccurrences;
  // Note that deliveryDate is updated on an occurrence only upon payment creation
  const nextDate = billSubscription.nextOccurrence.deliveryDate || billSubscription.nextOccurrence!.dueDate;

  return (
    <>
      <Container
        data-testid={`recurring-payment-${billSubscription.id}-card`}
        border={'regular'}
        paddingX={isExtraSmallScreen ? 's' : 'm'}
        paddingY={isExtraSmallScreen ? 's' : 'm'}
        data-component={DataComponentEnum.RECURRING_PAYMENT}
      >
        <Group justifyContent="space-between">
          <Group>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              {<Icon type="repeat" />}
            </Flex>
            <Group variant="vertical" spacing="none">
              <Group alignItems="center" spacing="xxs">
                <Text as="h2" textStyle="body2Semi">
                  {formatCurrency(
                    convertAmountToCurrency(
                      billSubscription.lastAmount && isLastPayment
                        ? billSubscription.lastAmount
                        : billSubscription.amount,
                    ),
                  )}
                </Text>
                <Badge
                  type="secondary"
                  status={isLastPayment ? 'informative' : 'neutral'}
                  label={
                    isLastPayment
                      ? formatMessage('widgets.recurringPayments.lastOccurrenceBadge')
                      : intervalTypeCapitalize(billSubscription.intervalType)
                  }
                />
              </Group>
              <Text textStyle="body4" color="neutral.darker">
                {!isBillSubscriptionManagedByFiserv && !isLastPayment && billSubscription.endDate
                  ? formatMessage('widgets.recurringPayments.nextWithIndex', {
                      nextDate: formatDate(nextDate, { dateStyle: 'medium' }),
                      nextOccurrenceNumber: billSubscription.nextOccurrence.occurrenceNumber,
                      numOfOccurrences: billSubscription.numOfOccurrences,
                    })
                  : formatMessage('widgets.recurringPayments.next', {
                      nextDate: formatDate(nextDate, { dateStyle: 'medium' }),
                    })}
              </Text>
              {!isLastPayment && (
                <Text textStyle="body4" color="neutral.darker">
                  {billSubscription.endDate
                    ? billSubscription.lastAmount
                      ? formatMessage('widgets.recurringPayments.lastWithLastAmount', {
                          lastDate: formatDate(billSubscription.endDate, { dateStyle: 'medium' }),
                          lastAmount: formatCurrency(convertAmountToCurrency(billSubscription.lastAmount!)),
                        })
                      : formatMessage('widgets.recurringPayments.last', {
                          lastDate: formatDate(billSubscription.endDate, { dateStyle: 'medium' }),
                        })
                    : formatMessage('widgets.recurringPayments.endless')}
                </Text>
              )}
              {isBillSubscriptionManagedByFiserv && billSubscription.endDate && (
                <Text textStyle="body4" color="neutral.darker">
                  {formatMessage('widgets.recurringPayments.totalPayments', {
                    numOfOccurrences: billSubscription.numOfOccurrences,
                  })}
                </Text>
              )}
            </Group>
          </Group>
          {actionMenuItems.length > 0 && (
            <ActionsDropdownMenu
              data-testid={`recurring-payment-${billSubscription.id}-card-menu`}
              size="small"
              items={actionMenuItems}
              isOpen={isMenuOpen}
              onOpenChange={setIsMenuOpen}
            />
          )}
        </Group>
      </Container>
      <CancelPaymentDialog
        isOpen={isCancelDialogOpen}
        onClose={closeCancelDialog}
        deleteEntity={async (deleteId) => {
          if (deleteId) {
            await cancelSubscriptionById(deleteId);
            await queryClient.invalidateQueries('VendorsApi');
          }
        }}
        origin="recurringSeries"
        bodyMessage={formatMessage('widgets.paymentDetails.cancelPaymentDialog.recurringSeries.body')}
        vendorName={vendor.name}
        deleteId={billSubscription.id}
      />
    </>
  );
};

RecurringPaymentCard.displayName = 'RecurringPaymentCard';
