/* eslint-disable max-lines */
import { getIsChangingDeliveryMethodIsDisabled, useBillActions } from '@melio/ap-domain';
import { useAccountingPlatformName, useShowMemoToVendor } from '@melio/ap-widgets';
import {
  Button,
  Container,
  Divider,
  Form,
  Group,
  IconButton,
  Link,
  SectionBanner,
  useBreakpointValue,
  UseMelioFormResults,
} from '@melio/penny';
import {
  AccountingPlatform,
  ApprovalRequirementStatus,
  Bill,
  CreateVendorAnalyticsMetadataFlow,
  DeliveryMethodType,
  DeliveryPreference,
  FileInfo,
  FundingSource,
  Payment,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { PageTitle } from '../../../PageTitle/PageTitle';
import { getSkipVendorEmail } from '../../../single-payment/utils/scheduleFlowStepsUtils';
import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../types';
import { AmountInput } from './components/AmountInput/AmountInput';
import { BillDetailsButton } from './components/BillDetailsButton/BillDetailsButton';
import { DeliveryDateInput } from './components/DeliveryDateInput/DeliveryDateInput';
import { DeliveryMethodSection } from './components/DeliveryMethodSection/DeliveryMethodSection';
import { FeesSection } from './components/FeesSection/FeesSection';
import { FrequencySelector } from './components/FrequencySelector';
import { FundingSourceSection } from './components/FundingSourceSection/FundingSourceSection';
import { MandatoryFieldDisclaimer } from './components/MandatoryFieldDisclaimer';
import { BankAccountFormModal } from './components/modals/BankAccountFormModal/BankAccountFormModal';
import { useBankAccountDetailsFormModal } from './components/modals/BankAccountFormModal/useBankAccountDetailsFormModal';
import { PaperCheckFormModal } from './components/modals/PaperCheckFormModal/PaperCheckFormModal';
import { usePaperCheckFormModal } from './components/modals/PaperCheckFormModal/usePaperCheckFormModal';
import { NoteToVendorInput } from './components/NoteToVendorInput';
import { RecurringFields } from './components/RecurringFields/RecurringFields';
import { SectionHeader } from './components/SectionHeader';
import { AriaLive } from './components/util/AriaLive';
import { VendorEmailInput } from './components/VendorEmailInput';
import { VendorInput } from './components/VendorInput';

export type PaymentFlowFormProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  handlers: PaymentFlowOnChangeHandlers;
  onBillButtonClick: VoidFunction;
  onNewFundingSourceClick: VoidFunction;
  onDeliveryMethodClick: VoidFunction;
  fundingSources: FundingSource[];
  selectedVendor?: Vendor;
  selectedDeliveryPreference?: DeliveryPreference;
  payment?: Payment;
  bill?: Bill;
  file?: FileInfo;
  isLoading: boolean;
  isSubmitting: boolean;
  isSubmitButtonDisabled: boolean;
  onClose: VoidFunction;
  title: string;
  activeAccountingPlatform?: AccountingPlatform;
  shouldRunReconciliation: (fundingSource: FundingSource | undefined) => boolean;
  runReconciliationFlow: VoidFunction;
  approvalRequirementStatus?: ApprovalRequirementStatus;
};

export const PaymentFlowForm = ({
  form,
  handlers: {
    onVendorChange,
    onAmountChange,
    onFundingSourceChange,
    onDeliveryMethodChange,
    onRecurrenceTypeChange,
    onDeliveryDateChange,
    onStartDateChange,
  },
  onBillButtonClick,
  fundingSources,
  selectedVendor,
  selectedDeliveryPreference,
  payment,
  bill,
  isLoading,
  isSubmitting,
  isSubmitButtonDisabled,
  onClose,
  title,
  activeAccountingPlatform,
  shouldRunReconciliation,
  runReconciliationFlow,
  approvalRequirementStatus,
}: PaymentFlowFormProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { createDate, getPreviousDay } = useDateUtils();
  const { getBillActions } = useBillActions();
  const { shouldDisplayMemoToVendor, allowSendingEmailToVendorWhenAddingMemo } = useShowMemoToVendor();
  const {
    settings: {
      payment: {
        scheduling: { showEarlierApprovalDate, recurringDeliveryMethodBlackList },
      },
    },
  } = useConfig();

  const isMobile = useBreakpointValue({ xs: true, s: false });

  const { formProps, submitButtonProps, watch, formState } = form;
  const [
    vendorId,
    deliveryMethodId,
    fundingSourceId,
    recurrenceType,
    deliveryDate,
    scheduleDate,
    amountToPay,
    lastAmount,
  ] = watch([
    'vendorId',
    'deliveryMethodId',
    'fundingSourceId',
    'recurrenceType',
    'deliveryDate',
    'scheduleDate',
    'amountToPay',
    'lastAmount',
  ]);

  const selectedFundingSource = fundingSources.find((fundingSource) => fundingSource.id === fundingSourceId);
  const selectedDeliveryMethod = selectedVendor?.deliveryMethods.find(
    (deliveryMethod) => deliveryMethod.id === deliveryMethodId
  );

  const isOneTimePayment = recurrenceType === 'one_time';
  const isPendingApproval = approvalRequirementStatus === ApprovalRequirementStatus.Pending;
  const isEarliestAsPossible = scheduleDate && !isPendingApproval ? isSameDay(scheduleDate, createDate()) : false;
  const isBillDetailsButtonDisabled = !!payment || !isOneTimePayment || (!!bill && !getBillActions(bill).actions.edit);
  const isDeliveryMethodDisabled =
    !!payment || (!!selectedDeliveryMethod && getIsChangingDeliveryMethodIsDisabled(selectedDeliveryMethod));
  const isFrequencySelectorDisabled = !selectedVendor || !selectedDeliveryMethod;
  const isDeliveryMethodNotAllowedForRecurring =
    selectedDeliveryMethod && recurringDeliveryMethodBlackList.includes(selectedDeliveryMethod.type);

  const approvalDate = showEarlierApprovalDate && scheduleDate ? getPreviousDay(scheduleDate) : scheduleDate;
  const showFrequencySelector = !bill && !payment;
  const showVendorEmail =
    allowSendingEmailToVendorWhenAddingMemo && selectedVendor && !getSkipVendorEmail(selectedVendor);
  const showMemoToVendor =
    selectedVendor && selectedDeliveryMethod && shouldDisplayMemoToVendor(selectedDeliveryMethod);

  const showFeeSection = selectedDeliveryPreference && deliveryMethodId && fundingSourceId && amountToPay;

  const bankAccountModal = useBankAccountDetailsFormModal({ vendor: selectedVendor, onDone: onDeliveryMethodChange });
  const paperCheckModal = usePaperCheckFormModal({ vendor: selectedVendor, onDone: onDeliveryMethodChange });

  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const shouldShowReconciliationBanner = shouldRunReconciliation(selectedFundingSource);
  const vendorInputAnalyticsProperties = {
    PageName: 'pay',
    Flow: 'vendor',
    Intent: 'add-a-vendor',
    VendorCreatedOrigin: 'single-payment',
  };

  return (
    <NewSinglePaymentStepLayout
      data-testid="payment-flow-form"
      headerContent={
        isMobile ? (
          <Group variant="vertical" spacing="none" data-testid="payment-flow-form-header">
            <Container width="full" paddingX="s" paddingY="s">
              <Group variant="horizontal" spacing="s" alignItems="center">
                <IconButton
                  data-testid="payment-flow-form-header-cancel"
                  icon="arrow-left"
                  size="medium"
                  variant="naked"
                  aria-label={formatMessage('activities.paymentFlow.form.header.back')}
                  onClick={onClose}
                />
                <PageTitle textStyle="heading2Semi" data-testid="payment-flow-form-header-title">
                  {title}
                </PageTitle>
              </Group>
            </Container>
            <Divider variant="horizontal" />
          </Group>
        ) : undefined
      }
      isStickyFooter
      footerContent={
        <Group variant="vertical" spacing="none" data-testid="payment-flow-form-footer">
          <Divider variant="horizontal" />
          <Container width="full" paddingX="s" paddingY="s">
            <Group variant="horizontal" spacing="s" justifyContent="flex-end">
              {!isMobile && (
                <Button
                  data-testid="payment-flow-form-footer-cancel"
                  label={formatMessage('activities.paymentFlow.form.footer.cancel')}
                  size="medium"
                  variant="tertiary"
                  onClick={onClose}
                />
              )}
              <Button
                {...submitButtonProps}
                isDisabled={submitButtonProps.isDisabled || isSubmitButtonDisabled}
                data-testid="payment-flow-form-footer-submit"
                label={formatMessage('activities.paymentFlow.form.footer.submit')}
                size="medium"
                isFullWidth={isMobile}
              />
            </Group>
          </Container>
        </Group>
      }
    >
      {!isMobile && (
        <NewSinglePaymentStepLayout.Title textAlign="start" data-testid="payment-flow-form-content-title">
          {title}
        </NewSinglePaymentStepLayout.Title>
      )}
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" spacing="xs" width="full">
          <MandatoryFieldDisclaimer />
          <Group variant="vertical" spacing="l" data-testid="payment-flow-form-content">
            <Form {...formProps}>
              <Group width="full" variant="vertical" spacing="xxl">
                <Group width="full" variant="vertical" spacing="s">
                  <SectionHeader data-testid="payment-flow-form-content-pay-to">
                    {formatMessage('activities.paymentFlow.form.content.pay.to.label')}
                  </SectionHeader>
                  <Group width="full" variant="vertical" spacing="m">
                    <VendorInput
                      form={form}
                      onChange={onVendorChange}
                      payment={payment}
                      bill={bill}
                      analyticsProperties={vendorInputAnalyticsProperties}
                      createVendorFlow={CreateVendorAnalyticsMetadataFlow.Payment}
                      isSubmitted={formState.isSubmitted}
                    />
                    <Group variant="vertical" spacing="s">
                      <AmountInput form={form} bill={bill} payment={payment} onChange={onAmountChange} />
                      <BillDetailsButton
                        bill={bill}
                        onClick={onBillButtonClick}
                        isDisabled={isBillDetailsButtonDisabled}
                      />
                    </Group>
                  </Group>
                </Group>
                <Group width="full" variant="vertical" spacing="s">
                  <SectionHeader id="pay-from" data-testid="payment-flow-form-content-pay-from">
                    {formatMessage('activities.paymentFlow.form.content.pay.from.label')}
                  </SectionHeader>
                  <FundingSourceSection
                    fundingSourceId={fundingSourceId}
                    onFundingSourceChange={onFundingSourceChange}
                    fundingSources={fundingSources}
                    isLoading={false}
                    isReadOnly={isSubmitting}
                  />
                  <AriaLive shown={shouldShowReconciliationBanner}>
                    <SectionBanner
                      data-testid="payment-flow-form-reconciliation-banner"
                      variant="warning"
                      description={formatMessage(
                        'activities.paymentFlow.form.content.reconciliationBanner.description',
                        {
                          accountingPlatformName,
                          link: (
                            <Link
                              data-testid="payment-flow-form-reconciliation-banner-link"
                              href="#"
                              label={formatMessage('activities.paymentFlow.form.content.reconciliationBanner.link')}
                              onClick={runReconciliationFlow}
                            />
                          ),
                        }
                      )}
                    />
                  </AriaLive>
                </Group>
                <Group width="full" variant="vertical" spacing="s">
                  <SectionHeader data-testid="payment-flow-form-content-deliver-to">
                    {formatMessage('activities.paymentFlow.form.content.deliver.to.label')}
                  </SectionHeader>
                  <Group width="full" variant="vertical" spacing="m">
                    {vendorId && (
                      <DeliveryMethodSection
                        selectedVendor={selectedVendor}
                        selectedDeliveryMethod={selectedDeliveryMethod}
                        isRecurring={!isOneTimePayment}
                        isDisabled={isDeliveryMethodDisabled || isSubmitting}
                        isLoading={isLoading}
                        onDeliveryMethodChange={onDeliveryMethodChange}
                        onEditDeliveryMethod={(type) => {
                          const dmTypeToAction: Partial<Record<DeliveryMethodType, VoidFunction>> = {
                            'bank-account': bankAccountModal.open,
                            'paper-check': paperCheckModal.open,
                          };
                          dmTypeToAction[type]?.();
                        }}
                      />
                    )}
                    {showFrequencySelector && (
                      <FrequencySelector
                        form={form}
                        onChange={onRecurrenceTypeChange}
                        isDisabled={isFrequencySelectorDisabled}
                        disabledText={{
                          recurring: isDeliveryMethodNotAllowedForRecurring
                            ? formatMessage(
                                'activities.paymentFlow.form.content.recurring.recurrenceType.options.recurring.disabled.deliveryMethodNotAllowedForRecurring'
                              )
                            : undefined,
                        }}
                      />
                    )}
                    <Group width="full" variant="vertical" spacing="s">
                      <AriaLive shown={!!selectedVendor && isEarliestAsPossible}>
                        <SectionBanner
                          data-testid="payment-flow-form-content-banners-earliestAsPossible"
                          description={formatMessage('activities.paymentFlow.form.content.banners.earliestAsPossible', {
                            date: formatDate(deliveryDate ?? undefined, { dateStyle: 'medium' }),
                          })}
                          variant="neutral"
                          size="small"
                          icon="error-fill"
                        />
                      </AriaLive>
                      <AriaLive shown={!!selectedVendor && isPendingApproval}>
                        <SectionBanner
                          data-testid="payment-flow-form-content-banners-pendingApproval"
                          description={formatMessage('activities.paymentFlow.form.content.banners.pendingApproval', {
                            date: formatDate(approvalDate, { dateStyle: 'medium' }),
                          })}
                          variant="informative"
                          size="small"
                        />
                      </AriaLive>
                      {isOneTimePayment ? (
                        <DeliveryDateInput
                          form={form}
                          vendor={selectedVendor}
                          fundingSource={selectedFundingSource}
                          deliveryMethod={selectedDeliveryMethod}
                          deliveryPreference={selectedDeliveryPreference}
                          onChange={onDeliveryDateChange}
                          isLoading={isLoading}
                        />
                      ) : (
                        <RecurringFields
                          form={form}
                          deliveryPreference={selectedDeliveryPreference}
                          onStartDateChange={onStartDateChange}
                          isLoading={isLoading}
                        />
                      )}
                    </Group>
                    {showMemoToVendor ? <NoteToVendorInput form={form} /> : null}
                    {showVendorEmail ? <VendorEmailInput form={form} vendor={selectedVendor} /> : null}
                  </Group>
                </Group>
                {showFeeSection && (
                  <FeesSection
                    amount={amountToPay}
                    deliveryPreference={selectedDeliveryPreference}
                    deliveryMethodId={deliveryMethodId}
                    fundingSourceId={fundingSourceId}
                    lastAmount={lastAmount}
                  />
                )}
              </Group>
            </Form>
          </Group>
        </Group>
      </NewSinglePaymentStepLayout.Content>

      <BankAccountFormModal onClose={bankAccountModal.close} {...bankAccountModal} />
      <PaperCheckFormModal onClose={paperCheckModal.close} {...paperCheckModal} />
    </NewSinglePaymentStepLayout>
  );
};
