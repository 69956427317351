import { useFile } from '@melio/ar-domain';
import { useEffect, useState } from 'react';

export const useDownloadInvoice = () => {
  const [fileToDownload, setFileToDownload] = useState<{ id: string } | undefined>();

  const { data: file, isLoading: isLoadingDownloadFile } = useFile({
    id: fileToDownload?.id,
    enabled: !!fileToDownload,
  });

  useEffect(() => {
    if (file) {
      window.location.href = file.url;
    }
  }, [fileToDownload, file]);

  const downloadFile = (fileId: string) => {
    setFileToDownload({ id: fileId });
  };

  return { downloadFile, isLoadingDownloadFile };
};
