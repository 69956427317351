import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
import { useTheme } from '@melio/penny';

export const InvoiceIcon = (props: IconProps) => {
  const { colors } = useTheme();
  return (
    <Icon width="176px" height="224px" viewBox="0 0 176 224" fill="none" {...props}>
      <rect width="176" height="224" fill={colors.neutral.white} />
      <path
        d="M16.972 36H18.928V27.42H16.972V36ZM20.8263 36H22.5543V29.892L26.1303 36H28.0743V27.42H26.3463V33.072L23.0823 27.42H20.8263V36ZM32.0921 36H34.3241L37.1801 27.42H35.3561L33.3161 34.008L31.2761 27.42H29.2241L32.0921 36ZM41.9074 36.132C44.5834 36.132 46.3354 34.272 46.3354 31.716V31.62C46.3354 29.04 44.4994 27.3 41.9194 27.3C39.3754 27.3 37.5034 29.112 37.5034 31.668V31.764C37.5034 34.272 39.2194 36.132 41.9074 36.132ZM41.9434 34.584C40.5394 34.584 39.5314 33.504 39.5314 31.752V31.656C39.5314 29.928 40.4314 28.836 41.9194 28.836C43.4194 28.836 44.3074 29.976 44.3074 31.644V31.74C44.3074 33.468 43.3954 34.584 41.9434 34.584ZM47.7923 36H49.7483V27.42H47.7923V36ZM55.5107 36.132C57.6227 36.132 59.1467 35.04 59.3627 32.916H57.4547C57.2987 34.02 56.5547 34.56 55.4867 34.56C54.0707 34.56 53.2547 33.528 53.2547 31.752V31.656C53.2547 29.88 54.1187 28.824 55.4747 28.824C56.5547 28.824 57.1307 29.376 57.2747 30.408H59.2667C59.0627 28.296 57.5267 27.288 55.4627 27.288C53.0267 27.288 51.2027 29.064 51.2027 31.668V31.764C51.2027 34.404 52.7027 36.132 55.5107 36.132ZM60.6232 36H66.4072V34.488H62.5552V32.352H65.4832V30.936H62.5552V28.932H66.2152V27.42H60.6232V36ZM70.4681 36H72.4241V27.42H70.4681V36ZM74.3224 36H76.0624V29.976L78.4504 36H79.0864L81.4624 29.976V36H83.3944V27.42H80.8744L78.8944 32.76L76.8904 27.42H74.3224V36ZM84.584 36H86.408L86.996 34.092H90.104L90.692 36H92.72L89.936 27.42H87.404L84.584 36ZM88.556 29.004L89.684 32.712H87.416L88.556 29.004ZM97.3597 36.132C99.9277 36.132 101.392 34.608 101.392 32.316V31.404H97.5397V32.82H99.4717C99.4357 33.804 98.8477 34.632 97.4077 34.632C95.7277 34.632 95.0197 33.444 95.0197 31.788V31.692C95.0197 29.94 95.8957 28.824 97.3837 28.824C98.4157 28.824 99.0757 29.28 99.2437 30.288H101.176C100.972 28.164 99.2677 27.288 97.3597 27.288C94.8277 27.288 92.9917 29.088 92.9917 31.668V31.764C92.9917 34.308 94.5517 36.132 97.3597 36.132ZM102.799 36H108.583V34.488H104.731V32.352H107.659V30.936H104.731V28.932H108.391V27.42H102.799V36Z"
        fill={colors.neutral.dark}
      />
      <rect x="16" y="68" width="56" height="4" rx="2" fill={colors.neutral.lighter} />
      <rect x="16" y="56" width="80" height="8" rx="4" fill={colors.neutral.light} />
      <rect x="16" y="105" width="40" height="4" rx="2" fill={colors.neutral.lighter} />
      <rect x="136" y="96" width="24" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="16" y="96" width="56" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="16" y="126" width="40" height="4" rx="2" fill={colors.neutral.lighter} />
      <rect x="136" y="117" width="24" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="16" y="117" width="56" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="16" y="147" width="40" height="4" rx="2" fill={colors.neutral.lighter} />
      <rect x="136" y="138" width="24" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="16" y="138" width="56" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="16" y="168" width="40" height="4" rx="2" fill={colors.neutral.lighter} />
      <rect x="136" y="159" width="24" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="16" y="159" width="56" height="4" rx="2" fill={colors.neutral.light} />
      <rect x="120" y="192" width="40" height="8" rx="4" fill={colors.neutral.light} />
      <rect x="16" y="192" width="80" height="8" rx="4" fill={colors.neutral.lighter} />
    </Icon>
  );
};
