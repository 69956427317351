import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ProfileIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      d="M19 16C19.6 16 20 16.4 20 17C20 17.5 19.6 17.9 19.1 18H15C14.4 18 14 17.6 14 17C14 16.5 14.4 16.1 14.9 16H19Z"
      fill="#212124"
    />
    <path
      d="M19 12C19.6 12 20 12.4 20 13C20 13.5 19.6 13.9 19.1 14H15C14.4 14 14 13.6 14 13C14 12.5 14.4 12.1 14.9 12H19Z"
      fill="#212124"
    />
    <path d="M4 19C4 17.3 5.3 16 7 16H9C10.7 16 12 17.3 12 19H4Z" fill="#212124" />
    <path
      d="M10 13C10 14.1046 9.10457 15 8 15C6.89543 15 6 14.1046 6 13C6 11.8954 6.89543 11 8 11C9.10457 11 10 11.8954 10 13Z"
      fill="#212124"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C13.7 0 15 1.3 15 3V5H21C22.6 5 23.9 6.2 24 7.8V21C24 22.6 22.8 23.9 21.2 24H3C1.4 24 0.1 22.8 0 21.2V8C0 6.4 1.2 5.1 2.8 5H9V3C9 1.5 10.1 0.2 11.6 0H12ZM9 7H3C2.5 7 2.1 7.4 2 7.9V21C2 21.5 2.4 21.9 2.9 22H21C21.5 22 21.9 21.6 22 21.1V8C22 7.5 21.6 7.1 21.1 7H15V8C15 8.5 14.6 8.9 14.1 9H10C9.4 9 9 8.6 9 8V7ZM12 2C11.4 2 11 2.5 11 3V7H13V3C13 2.5 12.6 2.1 12.1 2H12Z"
      fill="#212124"
    />
  </Icon>
);
