import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { AccountLimitations, AccountsApiClient, ApiKeys } from '@melio/platform-api-axios-client';

type QueryFunc = (id: string) => ReturnType<typeof AccountsApiClient.getAccountsMeLimitations>;

const ACCOUNT_GET_ME_LIMITATIONS_KEY = 'ACCOUNT_GET_ME_LIMITATIONS_KEY';
export const useAccountMeLimitations = (props?: UseModelApiProps<QueryFunc>) =>
  useModelApi<QueryFunc, never, never, never, AccountLimitations>({
    enabled: true,
    id: ACCOUNT_GET_ME_LIMITATIONS_KEY,
    queryKey: [ApiKeys.AccountsApi, ACCOUNT_GET_ME_LIMITATIONS_KEY],
    queryFn: () => AccountsApiClient.getAccountsMeLimitations(),
    keepPreviousData: false,
    refetchOnMount: 'always',
    ...props,
  });
