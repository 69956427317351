import React from 'react';
import { saveAs } from 'file-saver';
import { Modal } from '@melio/penny';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { usePaymentsReport } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { usePlatformIntl } from '@/translations/Intl';
import { ExportFileFormWidgetFields, exportFormat } from './CompanySettings.types';
import { CreateReportFormatWidget } from './CreateReportFormat.widget';

export interface CreateReportModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const CreateReportModal = ({ isOpen, onClose }: CreateReportModalProps) => {
  const [includeMultipleFormats] = useFeature(FeatureFlags.ExportPaymentsMultipleFormats, false);
  const { formatMessage } = usePlatformIntl();
  const title = formatMessage('widgets.createReportModal.title');
  const today = new Date();
  const defaultFromDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  const { createReport: createPaymentsReport } = usePaymentsReport();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<ExportFileFormWidgetFields>();
  const { track } = useAnalytics();

  // str of format: "attachment; filename="...""
  const parseContentDisposition = (str: string, suffix: string) => {
    if (str.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*\2?|[^;\n]*)/;
      const matches = filenameRegex.exec(str);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }

    return `paymentsReport.${suffix}`;
  };

  const convertDateToStartDay = (date: Date) => {
    return new Date(date.setHours(0, 0, 0, 0));
  };

  const convertDateToEndDay = (date: Date) => {
    return new Date(date.setHours(24, 0, 0, 0));
  };

  //TODO: ME-28144-add-test-to-check-the-payments-report-content
  const onClickButton = async ({
    fileFormatRadioValue,
    fromDate,
    toDate,
    includePayments,
  }: ExportFileFormWidgetFields) => {
    track('Settings', 'Click', {
      PageName: 'download-report',
      IsScheduledPayments: includePayments,
      Cta: 'download-report',
    });
    const data = {
      startDate: convertDateToStartDay(fromDate),
      endDate: convertDateToEndDay(toDate),
      includeScheduledPayments: includePayments,
      ...(includeMultipleFormats && { fileFormat: fileFormatRadioValue }),
    };
    onClose();
    const res = await createPaymentsReport(data);
    const blob = new Blob([res.data], { type: 'text/csv' });
    const filename = parseContentDisposition(
      res.headers['content-disposition'] || '',
      fileFormatRadioValue.toLocaleLowerCase(),
    );
    saveAs(blob, filename);
  };

  return (
    <Modal
      header={title}
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        label: formatMessage('widgets.createReportModal.form.submitText'),
        variant: 'primary',
        ...submitButtonProps,
      }}
      secondaryButton={{
        label: formatMessage('widgets.createReportModal.form.cancelText'),
        variant: 'tertiary',
        onClick: onClose,
      }}
      data-testid="create-report-modal"
    >
      <CreateReportFormatWidget
        onSubmit={onClickButton}
        onSubmissionStateChange={onSubmissionStateChange}
        defaultValues={{
          fileFormatRadioValue: exportFormat.CSV,
          fromDate: defaultFromDate,
          toDate: today,
          includePayments: false,
        }}
        includeMultipleFormats={includeMultipleFormats}
      />
    </Modal>
  );
};
