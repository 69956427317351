import { Box } from '@chakra-ui/react';
import { BrandSymbol, Container, Group, Skeleton, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { capitalize } from 'lodash';

import { Plan, SubscriptionBillingCycleEnum, SubscriptionPreview } from '../../../../../../api';
import { usePlanInfo } from '../../../../../../hooks';
import { getSubtotalByCycle } from '../../../../../utils';

type SubscriptionCheckoutSummaryPlanProps = {
  selectedPlan: Plan;
  preview?: SubscriptionPreview;
  currentCycle: SubscriptionBillingCycleEnum;
  isLoading: boolean;
};

export const SubscriptionCheckoutSummaryPlan = ({
  selectedPlan,
  preview,
  currentCycle,
  isLoading,
}: SubscriptionCheckoutSummaryPlanProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const quantity = preview?.billingItems[0]?.measurementInfo?.quantity || 1;
  const subtotal = getSubtotalByCycle({
    plan: selectedPlan,
    quantity,
    cycle: currentCycle,
  });
  const { planName } = usePlanInfo(selectedPlan.id);

  return (
    <Container alignItems="center" width="full" justifyContent="space-between">
      <Group spacing="s" variant="horizontal" alignItems="center">
        <BrandSymbol type="melio" size="extra-large" />

        <Group spacing="xxxs" variant="vertical">
          <Text textStyle="body2Semi" data-testid="subscription-checkout-plan-title">
            {formatMessage('activities.subscription.plans.summary.description', { planName })}
          </Text>
          <Text color="neutral.darker" textStyle="body4">
            {capitalize(currentCycle)}
          </Text>
        </Group>
      </Group>

      <Group spacing="xxxs" variant="vertical" textAlign="end">
        <Text textStyle="body2Semi">{formatCurrency(subtotal)}</Text>
        <Text color="neutral.darker" textStyle="body4">
          {isLoading ? (
            <Box width="100px">
              <Skeleton height="12px" />
            </Box>
          ) : (
            formatMessage('activities.subscription.checkout.summary.users.price', {
              quantity,
              unitPrice: selectedPlan.cycles[currentCycle]?.unitPrice || 0,
            })
          )}
        </Text>
      </Group>
    </Container>
  );
};
