import { Container, Group, NakedButton, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Company, formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { EBillsSusbscriptionFormEditCompany } from './EBillsSusbscriptionFormEditCompany';
import { RegisterFormField } from './types';

export const EBillsSubscriptionFormCompany = ({
  company,
  registerField,
}: {
  company: Company;
  registerField: RegisterFormField;
}) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const [isEditMode, setIsEditMode] = useState(false);
  const { formatMessage } = useMelioIntl();
  const {
    contactFirstName,
    contactLastName,
    address: { line1, city, state, postalCode },
  } = company;
  const nameNotExists = !contactFirstName || !contactLastName;
  const addressNotExists = !line1 || !city || !state || !postalCode;
  const { track } = useAnalytics();
  const shouldShowEditMode = isEditMode || nameNotExists || addressNotExists;

  const handleEditClick = () => {
    track('Vendor', 'Click', { Cta: 'edit' });
    setIsEditMode(true);
  };

  return (
    <>
      <Container
        border="regular"
        paddingX={isExtraSmallScreen ? 's' : 'm'}
        paddingY={isExtraSmallScreen ? 's' : 'm'}
        hidden={shouldShowEditMode}
      >
        <Group variant="vertical" spacing="xs" width="full">
          <Group variant="horizontal" justifyContent="space-between">
            <Text color="neutral.darker" textStyle="body4Semi">
              {formatMessage('activities.ebillsSubscriptionForm.form.sections.company.title')}
            </Text>
            <NakedButton
              label={formatMessage('activities.ebillsSubscriptionForm.form.sections.company.edit.label')}
              onClick={handleEditClick}
              variant="secondary"
              aria-label={formatMessage('activities.ebillsSubscriptionForm.form.sections.company.edit.ariaLabel')}
            />
          </Group>
          <Group variant="vertical" spacing="xxs">
            <Text color="neutral.darkest" textStyle="body3Semi">
              {`${company.contactFirstName ?? ''} ${company.contactLastName ?? ''}`}
            </Text>
            <Text color="neutral.darkest" textStyle="body3">
              {formatAddress(company.address)}
            </Text>
          </Group>
        </Group>
      </Container>
      <EBillsSusbscriptionFormEditCompany
        registerField={registerField}
        hidden={!shouldShowEditMode}
        isEditMode={isEditMode}
      />
    </>
  );
};
