import { useEffect } from 'react';
import { StatusModal, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useOrgBillingFeeSettings } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isOpen: boolean;
  billingSettingId: string;
  setIsOpen: (value: boolean) => void;
};

export const RemoveBillingFeeSetting = withAnalyticsContext(({ isOpen, billingSettingId, setIsOpen }: Props) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { delete: removeBillingSetting, isMutating } = useOrgBillingFeeSettings({ enabled: false });

  useAnalyticsContext({
    globalProperties: {
      PageName: 'remove-billing-fee-setting',
      Intent: 'remove-billing-fee-setting',
      BillingSettingId: billingSettingId,
    },
  });

  useEffect(() => {
    if (isOpen) {
      track('RemoveBillingFeeSetting', 'View');
    }
  }, [track, isOpen]);

  const onConfirm = async () => {
    try {
      track('RemoveBillingFeeSetting', 'Click', {
        Cta: 'remove',
      });
      await removeBillingSetting(billingSettingId);
      setIsOpen(false);
      toast({
        type: 'informative',
        title: formatMessage('widgets.billingSettings.paymentMethod.removeModal.toast.success'),
      });
    } catch (e) {
      setIsOpen(false);
      toast({
        type: 'error',
        title: formatMessage('widgets.billingSettings.paymentMethod.removeModal.toast.error'),
      });
    }
  };

  const onExit = () => {
    track('RemoveBillingFeeSetting', 'Click', {
      Cta: 'exit',
    });
    setIsOpen(false);
  };

  const onCancel = () => {
    track('RemoveBillingFeeSetting', 'Click', {
      Cta: 'cancel',
    });
    setIsOpen(false);
  };

  return (
    <StatusModal
      data-testid="remove-billing-setting-modal"
      isOpen={isOpen}
      onClose={onExit}
      variant="cancel"
      header={formatMessage('widgets.billingSettings.paymentMethod.removeModal.header')}
      description={formatMessage('widgets.billingSettings.paymentMethod.removeModal.description')}
      primaryButton={{
        label: formatMessage('widgets.billingSettings.paymentMethod.removeModal.primaryButton'),
        onClick: onConfirm,
        variant: 'primary',
        isLoading: isMutating,
      }}
      secondaryButton={{
        label: formatMessage('widgets.billingSettings.paymentMethod.removeModal.secondaryButton'),
        onClick: onCancel,
        variant: 'tertiary',
        isDisabled: isMutating,
      }}
    />
  );
});
