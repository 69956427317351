export { RoleUniqueNames } from '@melio/platform-api';

import type { Collaborator as ApiCollaborator, Invitation as ApiInvitation } from '@melio/platform-api';
import { RoleUniqueNames as _RoleUniqueNameEnum } from '@melio/platform-api';

export const RoleUniqueNameEnum = _RoleUniqueNameEnum;

export type CollaboratorAction = 'edit' | 'delete' | 'owner';
export type InvitationAction = 'resend' | 'cancel';

export type Collaborator = Omit<ApiCollaborator, 'user'> &
  ApiCollaborator<'user'>['user'] & { allowedActions: CollaboratorAction[] };
export type Invitation = Omit<ApiInvitation, 'status'> & {
  status: ApiInvitation['status'] | 'expired';
  allowedActions: InvitationAction[];
};
