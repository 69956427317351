import { useVendorActions } from '@melio/ap-domain';
import { Link, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { useLocation } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../../utils';

export type VendorsTabActionButtonPropsType = {
  vendor: Vendor;
  unpaidBills: Bill[];
  isDisabled?: boolean;
  newPaymentAmount?: string;
  onNewPaymentClick: (vendorId: string, amount?: string) => void;
  onViewVendorClick: (vendorId: string) => void;
};

export const VendorsTabActionButtonCell = ({
  vendor,
  unpaidBills,
  isDisabled,
  newPaymentAmount,
  onNewPaymentClick,
  onViewVendorClick,
}: VendorsTabActionButtonPropsType) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { track } = useAnalytics();
  const vendorActions = useVendorActions(vendor);
  const { navigateToBatchPayment, navigateToSchedulePayment, getBatchPaymentsLink, getSchedulePaymentLink } =
    useActivitiesNavigate();
  const { pathname } = useLocation();

  const { tryUseFeature: tryUseBatchPayments } = useSubscriptionFeature({ featureName: 'batchPayments' });

  const openBalance = unpaidBills.reduce((acc, bill) => acc + bill.balance, 0);
  const noUnpaidBills = unpaidBills.length === 0;
  const unpaidBillIds = unpaidBills.map((bill) => bill.id);

  const handleNewPaymentClick: React.MouseEventHandler<HTMLLinkElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    track('Dashboard', 'Click', {
      Intent: 'pay-vendor',
      Cta: 'new-payment',
      VendorId: vendor.id,
    });
    if (Number(newPaymentAmount)) {
      onNewPaymentClick(vendor.id, newPaymentAmount);
    } else {
      onNewPaymentClick(vendor.id);
    }
  };

  const handlePayBalanceClick: React.MouseEventHandler<HTMLLinkElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    track('Dashboard', 'Click', {
      Intent: 'pay-vendor',
      Cta: 'pay-balance',
      BillIds: unpaidBillIds,
      VendorId: vendor.id,
      OpenBalance: openBalance,
    });

    if (unpaidBills.length === 1 && unpaidBillIds[0] /* for ts check */) {
      return navigateToSchedulePayment({ billId: unpaidBillIds[0], returnUrl: pathname });
    }

    tryUseBatchPayments({
      onFeatureIsEligible: () => navigateToBatchPayment({ billIds: unpaidBillIds, returnUrl: pathname }),
    });
  };

  const handleViewVendorClick: React.MouseEventHandler<HTMLLinkElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    track('Dashboard', 'Click', { Cta: 'view-details', Intent: 'view-vendor', VendorId: vendor.id });
    onViewVendorClick(vendor.id);
  };

  if (vendorActions.newPayment && !noUnpaidBills) {
    return (
      <ActionButton
        vendor={vendor}
        action={handlePayBalanceClick}
        href={unpaidBills.length > 1 ? getBatchPaymentsLink(unpaidBillIds) : getSchedulePaymentLink(unpaidBillIds[0])}
        label={formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.payBalance')}
        isDisabled={isDisabled}
        aria-label={formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.payBalance.link.ariaLabel', {
          vendorName: vendor.name,
          amount: formatCurrency(openBalance),
        })}
      />
    );
  }

  if (vendorActions.newBill) {
    return (
      <ActionButton
        vendor={vendor}
        action={handleNewPaymentClick}
        href={getSchedulePaymentLink()}
        label={formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.newPayment')}
        isDisabled={isDisabled}
        aria-label={formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.newPayment.link.ariaLabel', {
          vendorName: vendor.name,
        })}
      />
    );
  }

  return (
    <ActionButton
      vendor={vendor}
      action={handleViewVendorClick}
      label={formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.viewDetails')}
      isDisabled={isDisabled}
      aria-label={formatMessage('activities.payDashboard.vendorsTab.table.cells.actions.viewDetails.link.ariaLabel')}
    />
  );
};

const ActionButton = ({
  vendor,
  action,
  href,
  label,
  'aria-label': ariaLabel,
  isDisabled,
}: {
  vendor: Vendor;
  action: React.MouseEventHandler<HTMLLinkElement>;
  href?: string;
  label: string;
  'aria-label': string;
  isDisabled?: boolean;
}) => (
  <Table.Cell textAlign="end" data-tourid={`vendors-tab-action-cell-${vendor.id}`}>
    <Link
      isBold
      variant="standalone"
      onClick={action}
      href={href ?? '#'}
      data-testid={`vendors-tab-action-cell-${vendor.id}`}
      color="secondary"
      size="medium"
      label={label}
      aria-label={ariaLabel}
      isDisabled={isDisabled}
    />
  </Table.Cell>
);
