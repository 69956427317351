import { Modal } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

const useConfirmApprovalWorkflowChangesModalLabels = () => {
  const { formatMessage } = useMelioIntl();
  return {
    confirmLabel: formatMessage('activities.edit-approval-workflows.confirmApprovalWorkflowChangesModal.confirm'),
    cancelLabel: formatMessage('activities.edit-approval-workflows.confirmApprovalWorkflowChangesModal.cancel'),
    headerLabel: formatMessage('activities.edit-approval-workflows.confirmApprovalWorkflowChangesModal.title'),
    bodyLabel: formatMessage('activities.edit-approval-workflows.confirmApprovalWorkflowChangesModal.body'),
  };
};

export type ConfirmApprovalWorkflowChangesModalProps = {
  onConfirm: VoidFunction;
  onClose: (intent: 'exit' | 'cancel') => void;
  isOpen: boolean;
  isSaving: boolean;
};
export const ConfirmApprovalWorkflowChangesModal = ({
  onConfirm,
  onClose,
  isOpen,
  isSaving,
}: ConfirmApprovalWorkflowChangesModalProps) => {
  const { headerLabel, bodyLabel, confirmLabel, cancelLabel } = useConfirmApprovalWorkflowChangesModalLabels();
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose('exit')}
      header={headerLabel}
      description={bodyLabel}
      primaryButton={{ label: confirmLabel, onClick: onConfirm, isLoading: isSaving, variant: 'primary' }}
      secondaryButton={{ label: cancelLabel, onClick: () => onClose('cancel'), variant: 'tertiary' }}
      data-testid="confirm-approval-workflow-changes-modal"
    />
  );
};
