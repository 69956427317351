import {
  EmailPreviewer,
  PDFPreviewer,
  useInvoicePreviewEmail,
  useInvoicePreviewPDF,
  useMelioIntl,
} from '@melio/ar-domain';
import { Card, Group, Link, SectionBanner } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TabsNames } from '../types';

type PreviewTabsProps = {
  invoiceId: string;
  activeTab: TabsNames;
  onUploadCompanyLogo?: VoidFunction;
  shouldDisplayUploadCompanyLogoBanner: boolean;
  customerName?: string;
};

export const PreviewTabs = forwardRef<PreviewTabsProps>(
  ({ invoiceId, activeTab, shouldDisplayUploadCompanyLogoBanner, customerName, onUploadCompanyLogo }, ref) => {
    const { data: previewEmailContent, isLoading: isLoadingEmailPreview } = useInvoicePreviewEmail({
      id: invoiceId,
    });
    const { data: previewPDFContent, isFetching: isFetchingPDFContent } = useInvoicePreviewPDF({ id: invoiceId });
    const uploadLogoBannerDescription = useUploadLogoBannerDescription(onUploadCompanyLogo);

    const tabs: Record<TabsNames, JSX.Element> = {
      PDF: <PDFPreviewer previewContent={previewPDFContent} isLoadingContent={isFetchingPDFContent} ref={ref} />,
      Email: (
        <EmailPreviewer
          previewContent={previewEmailContent?.html}
          isLoading={isLoadingEmailPreview}
          emailHeaderData={
            customerName && previewEmailContent?.subject
              ? { emailSubject: previewEmailContent.subject, customerName }
              : undefined
          }
          ref={ref}
        />
      ),
    };

    return (
      <Group variant="vertical" spacing="s" ref={ref}>
        {shouldDisplayUploadCompanyLogoBanner ? (
          <SectionBanner
            description={uploadLogoBannerDescription}
            variant="informative"
            data-testid="upload-logo-banner"
          />
        ) : null}
        <Card paddingX="none" paddingY="none">
          {tabs[activeTab]}
        </Card>
      </Group>
    );
  }
);

const useUploadLogoBannerDescription = (onUploadCompanyLogo?: VoidFunction) => {
  const { formatMessage } = useMelioIntl();
  return formatMessage('ar.invoiceLifecycle.activities.issueInvoice.uploadLogoBanner.description.text', {
    link: (
      <Link
        href="#"
        data-testid="upload-logo-banner-action-button"
        variant="inline"
        label={formatMessage('ar.invoiceLifecycle.activities.issueInvoice.uploadLogoBanner.description.link')}
        onClick={(e) => {
          e.preventDefault();
          onUploadCompanyLogo?.();
        }}
      />
    ),
  });
};
