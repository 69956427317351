import { useEffect, useMemo, useState } from 'react';
import {
  AddressSearchWidget,
  AddressSearchWidgetProps,
  useStateSelect,
  useUnmanagedVendorFormExtendedSchema,
  useVendorDirectoryInfoComplete,
  useVendorForm,
  ZipCodeField,
} from '@melio/ap-widgets';
import { Container, Form, Group, LoadingContainer, Text, useBreakpoint } from '@melio/penny';
import { Address, US_STATES, useDeliveryMethod, useVendor, useVendorEditableFields } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';
import { VendorDetailsFormInnerProps } from '../types';
import { preventFormSubmissionOnEnter } from '../utils';
import { VendorDetailsExtendedUnmanagedFields } from './types';
import { getUpdateVendorPayload } from './utils';

const maskAccountNumber = (accountNumber?: string) =>
  accountNumber ? `****${accountNumber.slice(-4)}` : accountNumber;

export type VendorDetailsExtendedUnmanagedFormProps = VendorDetailsFormInnerProps<VendorDetailsExtendedUnmanagedFields>;

export const VendorDetailsExtendedUnmanagedForm = forwardRef<VendorDetailsExtendedUnmanagedFormProps, 'form'>(
  (
    {
      vendorId,
      defaultValues,
      isSaving,
      onSubmit,
      isEditable,
      onSubmissionStateChange,
      inlineApiErrorCodes,
      loadingContainerId,
      ...props
    },
    ref,
  ) => {
    const { formatMessage } = usePlatformIntl();
    const { isExtraSmallScreen } = useBreakpoint();

    const [isLoadingUnmaskedValues, setIsLoadingUnmaskedValues] = useState(false);
    const [unmaskedValues, setUnmaskedValues] = useState<{
      vendorUnmaskedAccountNumber?: string;
      unmaskedBankAccountNumber?: string;
    }>();
    const { getVendorUnmaskedAccountNumber, data: vendor } = useVendor({ id: vendorId });
    const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);
    const { data: editableFieldsFromApi, isLoading: isLoadingEditableFields } = useVendorEditableFields({
      disabled: !isEditable || !isVendorDirectoryInfoCompleted,
      vendorId,
    });

    const editableFields = isVendorDirectoryInfoCompleted
      ? editableFieldsFromApi
      : ['name', 'nickname', 'accountNumber', 'contactPhoneNumber', 'emailAddress', 'bankAccount', 'address'];

    const bankAccount = useMemo(
      () => vendor?.deliveryMethods.find((method) => method.type === 'bank-account'),
      [vendor],
    );
    const { getDeliveryMethodUnmaskedAccountNumber } = useDeliveryMethod({
      id: bankAccount?.id,
      enabled: false,
    });

    const { formProps, registerField, reset, setValue, trigger, formState } =
      useVendorForm<VendorDetailsExtendedUnmanagedFields>({
        onSubmit: (data) => onSubmit(getUpdateVendorPayload(data, defaultValues)),
        schema: useUnmanagedVendorFormExtendedSchema({
          showCompanyField: true,
          isEmailRequired: !!defaultValues?.email,
        }),
        isSaving,
        defaultValues,
        onSubmissionStateChange,
        inlineApiErrorCodes,
      });
    const isFormSubmitted = formState.isSubmitted;

    useEffect(() => {
      if (!isVendorDirectoryInfoCompleted && isEditable && vendor) {
        trigger();
      }
    }, [isVendorDirectoryInfoCompleted, trigger, isEditable, vendor]);

    useEffect(() => {
      const unmaskValues = async () => {
        setIsLoadingUnmaskedValues(true);

        try {
          const [vendorUnmaskedAccountNumber, unmaskedBankAccountNumber] = await Promise.all([
            getVendorUnmaskedAccountNumber(),
            getDeliveryMethodUnmaskedAccountNumber(),
          ]);

          setUnmaskedValues({
            vendorUnmaskedAccountNumber: vendorUnmaskedAccountNumber.accountNumber,
            unmaskedBankAccountNumber: unmaskedBankAccountNumber?.accountNumber,
          });
        } finally {
          setIsLoadingUnmaskedValues(false);
        }
      };

      if (isEditable && isVendorDirectoryInfoCompleted) {
        unmaskValues();
      }
    }, [
      isEditable,
      getVendorUnmaskedAccountNumber,
      getDeliveryMethodUnmaskedAccountNumber,
      defaultValues?.accountNumber,
      defaultValues?.bankAccountNumber,
      isVendorDirectoryInfoCompleted,
    ]);

    useEffect(() => {
      const accountNumber = isEditable ? unmaskedValues?.vendorUnmaskedAccountNumber : defaultValues?.accountNumber;
      const bankAccountNumber = isEditable
        ? unmaskedValues?.unmaskedBankAccountNumber
        : maskAccountNumber(defaultValues?.bankAccountNumber);

      reset({
        ...defaultValues,
        accountNumber: accountNumber ?? defaultValues?.accountNumber,
        confirmAccountNumber: accountNumber ?? defaultValues?.accountNumber,
        bankAccountNumber: bankAccountNumber ?? defaultValues?.bankAccountNumber,
      });
    }, [defaultValues, isEditable, unmaskedValues, reset]);

    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address;

      const setFormFieldValue = (field: keyof Omit<Address, 'countryCode'>) => {
        setValue(field, address ? address[field] : '', {
          shouldValidate: true,
        });
      };
      setFormFieldValue('line1');
      setFormFieldValue('line2');
      setFormFieldValue('city');
      setFormFieldValue('state');
      setFormFieldValue('postalCode');
    };

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setIsLoading(isLoadingUnmaskedValues || isLoadingEditableFields); // because of screen reader
    }, [isLoadingEditableFields, isLoadingUnmaskedValues]);

    const stateOptions = useMemo(
      () =>
        US_STATES.map((state) => ({
          value: state,
          label: formatMessage(`local.USA.states.${state}`),
        })),
      [formatMessage],
    );
    const { filteredStateOptions, handleChange: handleStateSearch } = useStateSelect(stateOptions);

    return (
      <LoadingContainer aria-live="polite" isLoading={isLoading} id={loadingContainerId}>
        {!isLoading && (
          <Form
            data-component={DataComponentEnum.VENDOR_DETAILS_FORM}
            {...props}
            {...formProps}
            ref={ref}
            isViewMode={!isEditable}
            columns={2}
            {...preventFormSubmissionOnEnter()}
          >
            {/* @ts-ignore Form.ContentBox should be changee to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
            <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
              <Group variant="vertical" spacing="xs">
                {isEditable && (
                  <Text color="neutral.darkest" textStyle="body4">
                    {formatMessage('activities.addBillV2.billForm.requiredFields')}
                  </Text>
                )}
                <Text as="h3" textStyle="heading3Semi">
                  {formatMessage('widgets.vendorDetails.form.business.title')}
                </Text>
              </Group>
            </Form.ContentBox>
            <Form.TextField
              colSpan={2}
              {...registerField('companyName')}
              labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
              autoFocus={isEditable}
              isTruncated={!isEditable}
              isDisabled={isEditable && !editableFields?.includes('name')}
            />
            <Form.TextField
              colSpan={2}
              {...registerField('nickname')}
              labelProps={{ label: formatMessage('widgets.vendorDetails.form.nickname.label') }}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.nickname.viewModePlaceholder')}
              placeholder={formatMessage('widgets.vendorDetails.form.nickname.placeholder')}
              isTruncated={!isEditable}
              hidden={!isEditable && !defaultValues?.nickname}
              isDisabled={isEditable && !editableFields?.includes('nickname')}
            />
            <Form.TextField
              colSpan={isEditable ? 1 : 2}
              {...registerField('accountNumber')}
              labelProps={{
                tooltipProps: isEditable
                  ? {
                      label: formatMessage('widgets.vendorDetails.form.accountNumber.labelTooltip'),
                      triggerAriaLabel: formatMessage('widgets.vendorDetails.form.accountNumber.ariaLabelTooltip'),
                    }
                  : undefined,
                label: formatMessage('widgets.vendorDetails.form.accountNumber.label'),
              }}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
              isTruncated={!isEditable}
              isDisabled={isEditable && !editableFields?.includes('accountNumber')}
              helperTextProps={
                isEditable
                  ? {
                      label: formatMessage('widgets.vendorDetails.form.accountNumber.helperText'),
                    }
                  : undefined
              }
            />
            {isEditable && (
              <Form.TextField
                colSpan={1}
                {...registerField('confirmAccountNumber')}
                labelProps={{
                  label: formatMessage('widgets.vendorDetails.form.confirmAccountNumber.label'),
                }}
                isDisabled={isEditable && !editableFields?.includes('accountNumber')}
              />
            )}
            <Form.PhoneField
              colSpan={2}
              {...registerField('phone')}
              labelProps={{ label: formatMessage('widgets.vendorDetails.form.phone.label') }}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
              placeholder={formatMessage('widgets.vendorDetails.form.phone.placeholder')}
              isDisabled={isEditable && !editableFields?.includes('contactPhoneNumber')}
              autoComplete="on"
            />
            <Form.TextField
              colSpan={2}
              {...registerField('email')}
              labelProps={{ label: formatMessage('widgets.vendorDetails.form.email.label') }}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.email.viewModePlaceholder')}
              placeholder={formatMessage('widgets.vendorDetails.form.email.placeholder')}
              isTruncated={!isEditable}
              isHidden={!isEditable && !defaultValues?.email}
              isDisabled={isEditable && !editableFields?.includes('emailAddress')}
              autoComplete="on"
            />
            {/* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
            <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
              <Container paddingTop="m">
                <Text as="h3" textStyle="heading3Semi">
                  {formatMessage(
                    isEditable
                      ? 'widgets.vendorDetails.form.electronic.title.editMode'
                      : 'widgets.vendorDetails.form.electronic.title.viewMode',
                  )}
                </Text>
              </Container>
            </Form.ContentBox>
            <Form.TextField
              colSpan={isEditable ? 1 : 2}
              labelProps={{
                label: formatMessage('widgets.vendorDetails.form.bankRoutingNumber.label'),
              }}
              {...registerField('bankRoutingNumber')}
              onChange={(e) => {
                setValue('bankRoutingNumber', e.target.value, {
                  shouldValidate: isFormSubmitted,
                });

                if (isFormSubmitted) {
                  void trigger('bankAccountNumber');
                }
              }}
              isTruncated={!isEditable}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.bankRoutingNumber.viewModePlaceholder')}
              isDisabled={isEditable && !editableFields?.includes('bankAccount')}
            />
            <Form.TextField
              colSpan={isEditable ? 1 : 2}
              labelProps={{
                label: formatMessage('widgets.vendorDetails.form.bankAccountNumber.label'),
              }}
              {...registerField('bankAccountNumber')}
              onChange={(e) => {
                setValue('bankAccountNumber', e.target.value, {
                  shouldValidate: isFormSubmitted,
                });

                if (isFormSubmitted) {
                  void trigger('bankRoutingNumber');
                }
              }}
              isTruncated={!isEditable}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.bankAccountNumber.viewModePlaceholder')}
              isDisabled={isEditable && !editableFields?.includes('bankAccount')}
            />
            {/* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
            <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
              <Container paddingTop="m">
                <Text as="h3" textStyle="heading3Semi">
                  {formatMessage('widgets.vendorDetails.form.address.title')}
                </Text>
              </Container>
            </Form.ContentBox>
            <AddressSearchWidget
              colSpan={2}
              {...registerField('line1')}
              onChange={handleAddressChange}
              // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
              formatSelectedValue={(option) =>
                (option.value as unknown as Address).line1 || (option.value as unknown as string)
              }
              labelProps={{
                label: formatMessage('widgets.vendorDetails.form.addressLine1.label'),
              }}
              placeholder={formatMessage('widgets.vendorDetails.form.addressLine1.placeholder')}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
              clearButtonAriaLabel={formatMessage('widgets.vendors.addressLine1.clearButtonAriaLabel')}
              isDisabled={isEditable && !editableFields?.includes('address')}
              autoComplete="on"
            />
            <Form.TextField
              colSpan={2}
              labelProps={{
                label: formatMessage('widgets.vendorDetails.form.addressLine2.label'),
              }}
              placeholder={formatMessage('widgets.vendorDetails.form.addressLine2.placeholder')}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.addressLine2.viewModePlaceholder')}
              {...registerField('line2')}
              isTruncated={!isEditable}
              isDisabled={isEditable && !editableFields?.includes('address')}
              autoComplete="on"
            />
            <Form.TextField
              colSpan={isEditable ? 1 : 2}
              labelProps={{
                label: formatMessage('widgets.vendorDetails.form.city.label'),
              }}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
              {...registerField('city')}
              isTruncated={!isEditable}
              isDisabled={isEditable && !editableFields?.includes('address')}
            />
            <Form.SelectNew
              colSpan={isEditable ? 1 : 2}
              labelProps={{
                label: formatMessage('widgets.vendorDetails.form.state.label'),
              }}
              placeholder={formatMessage('widgets.vendorDetails.form.state.placeholder')}
              {...registerField('state')}
              options={US_STATES.map((state) => ({
                value: state,
                label: formatMessage(`local.USA.states.${state}`),
              }))}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
              isDisabled={isEditable && !editableFields?.includes('address')}
              searchBarProps={{
                placeholder: formatMessage('widgets.vendors.state.placeholder'),
                options: filteredStateOptions,
                onChange: handleStateSearch,
              }}
              shouldHideClearButton
            />
            <ZipCodeField
              colSpan={2}
              labelProps={{
                label: formatMessage('widgets.vendorDetails.form.postalCode.label'),
              }}
              placeholder={formatMessage('widgets.vendorDetails.form.postalCode.placeholder')}
              viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
              {...registerField('postalCode')}
              isTruncated={!isEditable}
              isDisabled={isEditable && !editableFields?.includes('address')}
              helperTextProps={{
                label: formatMessage('widgets.vendors.postalCode.description'),
              }}
            />
          </Form>
        )}
      </LoadingContainer>
    );
  },
);
