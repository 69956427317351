import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const IconLabelTheme: ComponentThemeType<{ splitContactsTab: boolean }> = (
  currentTheme,
  { splitContactsTab },
) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: { xs: 'row', s: 'column' } as never,
      alignItems: 'center',
      padding: { xs: '8px', s: 0 } as never,
      gap: { xs: '16px', s: '4px' } as never,
    },
    label: {
      ...currentTheme.textStyles.caption1Semi,
    } as never,
  },
  variants: {
    active: {
      container: {
        color: currentTheme.colors.brand.main,
      },
      icon: {
        fill: currentTheme.colors.brand.main,
      },
    },
    default: {
      container: {
        color: splitContactsTab ? currentTheme.colors.neutral.darker : currentTheme.colors.neutral.darkest,
        _hover: {
          color: currentTheme.colors.neutral.darkest,
        },
      },
      icon: {
        fill: splitContactsTab ? currentTheme.colors.neutral.darker : currentTheme.colors.neutral.darkest,
      },
    },
  },
});
