import { Box } from '@chakra-ui/react';
import { Avatar, Container, Group, Icon, LoadingContainer, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useMelioIntl } from './i18n';

export type EmailHeaderProps = {
  emailSubject: string;
  customerName: string;
};

type EmailPreviewerProps = {
  isLoading: boolean;
  previewContent?: string;
  emailHeaderData?: EmailHeaderProps;
};

const formatContent = (previewContent: string) => previewContent.replace(/href=["'][^"']*["']/gi, '');

export const EmailPreviewer = forwardRef<EmailPreviewerProps>(
  ({ previewContent, isLoading, emailHeaderData, ...props }, ref) => (
    <LoadingContainer isLoading={isLoading}>
      <Container
        border="regular"
        paddingLeft={emailHeaderData ? 's' : 'none'}
        data-component={EmailPreviewer.displayName}
        data-testid="email-previewer-container"
        {...props}
        ref={ref}
      >
        {emailHeaderData && <EmailHeader {...emailHeaderData} />}
        <Container paddingLeft={emailHeaderData ? 'xxl' : 'none'} paddingRight={emailHeaderData ? 'm' : 'none'}>
          <Box
            height={isLoading ? '50vh' : 'auto'}
            dangerouslySetInnerHTML={{ __html: previewContent ? formatContent(previewContent) : '' }}
          />
        </Container>
      </Container>
    </LoadingContainer>
  )
);
EmailPreviewer.displayName = 'EmailPreviewer';

const EmailHeader = forwardRef<EmailHeaderProps>(({ emailSubject, customerName, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container paddingY="xs-s" {...props} ref={ref}>
      <Container paddingLeft="xxl">
        <Text textStyle="body3" shouldSupportEllipsis>
          {emailSubject}
        </Text>
      </Container>
      <Group variant="horizontal" spacing="xs-s" alignItems="center">
        <Avatar name={customerName} size="medium" />
        <Group variant="vertical" spacing="xxs">
          <Text textStyle="body4">
            {formatMessage('ar.invoiceLifecycle.activities.issueInvoice.preview.tabs.Email.header.sender')}
          </Text>
          <Group variant="horizontal" spacing="xxs" alignItems="center">
            <Text textStyle="caption1" color="neutral.dark">
              {formatMessage('ar.invoiceLifecycle.activities.issueInvoice.preview.tabs.Email.header.addressee', {
                customerName,
              })}
            </Text>
            <Icon type="caret-down" size="small" isReadOnly />
          </Group>
        </Group>
      </Group>
    </Container>
  );
});
