import { Group } from '@melio/penny';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../../api';
import { SubscriptionBillingCycle } from './components/SubscriptionBillingCycle';
import { SubscriptionPaymentMethod } from './components/SubscriptionPaymentMethod';

type SubscriptionCheckoutSelectionProps = {
  selectedPlan: Plan;
  isSubscribing: boolean;
  fundingSourceId: string | null;
  setFundingSourceId: (id: string) => void;
  cycle: SubscriptionBillingCycleEnum;
  setCycle: (cycle: SubscriptionBillingCycleEnum) => void;
};

export const SubscriptionCheckoutSelection = ({
  selectedPlan,
  isSubscribing,
  fundingSourceId,
  setFundingSourceId,
  cycle,
  setCycle,
}: SubscriptionCheckoutSelectionProps) => (
  <Group variant="vertical" width="full" spacing="l">
    <SubscriptionBillingCycle
      selectedPlan={selectedPlan}
      isSubscribing={isSubscribing}
      selectedBillingCycle={cycle}
      onBillingCycleChange={setCycle}
    />
    <SubscriptionPaymentMethod fundingSourceId={fundingSourceId} setFundingSourceId={setFundingSourceId} />
  </Group>
);
