import { StatusModal, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onConfirm: () => Promise<void>;
};

export const ReplaceBillingFeeSetting = withAnalyticsContext<Props>(
  ({ isOpen, isLoading, onClose, onConfirm, setAnalyticsProperties }) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();

    setAnalyticsProperties({
      globalProperties: {
        PageName: 'replace-billing-fee-setting',
        Intent: 'replace-billing-fee-setting',
      },
    });

    useAnalyticsView('ReplaceBillingFeeSetting', isOpen);

    const handleConfirm = async () => {
      try {
        track('ReplaceBillingFeeSetting', 'Click', {
          Cta: 'replace',
        });
        await onConfirm();
        onClose();
      } catch (e) {
        onClose();
        toast({
          type: 'error',
          title: formatMessage('widgets.billingSettings.paymentMethod.replaceModal.toast.error'),
        });
      }
    };

    const handleExit = () => {
      track('ReplaceBillingFeeSetting', 'Click', {
        Cta: 'exit',
      });
      onClose();
    };

    const handleCancel = () => {
      track('ReplaceBillingFeeSetting', 'Click', {
        Cta: 'cancel',
      });
      onClose();
    };

    return (
      <StatusModal
        data-testid="replace-billing-setting-modal"
        isOpen={isOpen}
        onClose={handleExit}
        variant="warning"
        header={formatMessage('widgets.billingSettings.paymentMethod.replaceModal.header')}
        description={formatMessage('widgets.billingSettings.paymentMethod.replaceModal.description')}
        primaryButton={{
          label: formatMessage('widgets.billingSettings.paymentMethod.replaceModal.primaryButton'),
          onClick: handleConfirm,
          variant: 'primary',
          isLoading,
        }}
        secondaryButton={{
          label: formatMessage('widgets.billingSettings.paymentMethod.replaceModal.secondaryButton'),
          onClick: handleCancel,
          variant: 'tertiary',
          isDisabled: isLoading,
        }}
      />
    );
  }
);
