import { useMelioIntl } from '@melio/ar-domain';
import { Modal } from '@melio/penny';
import { FundingSourceBankAccount } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { FundingSourceCardList } from '../components';

export type ImportReceivingMethodScreenProps = {
  onClose: VoidFunction;
  onAdd: (type: 'plaid' | 'funding-source') => void;
  isOpen: boolean;
  isSaving: boolean;
  isLoading: boolean;
  fundingSources: FundingSourceBankAccount[];
  onSelectedFundingSource: (fundingSourceId?: string) => void;
  selectedFundingSourceId?: string;
};

export const ImportReceivingMethodModalScreen = forwardRef<ImportReceivingMethodScreenProps>(
  (
    { onClose, onAdd, isSaving, fundingSources = [], onSelectedFundingSource, selectedFundingSourceId, ...props },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    return (
      <Modal
        data-testid="import-receiving-method-modal-screen"
        data-component={ImportReceivingMethodModalScreen.displayName}
        onClose={isSaving ? () => null : onClose}
        // TODO: https://meliorisk.atlassian.net/browse/ME-57709
        // disable close icon when `isSaving`
        header={formatMessage('ar.invoiceLifecycle.activities.receivingMethods.modal.importReceivingMethod.title')}
        description={formatMessage(
          'ar.invoiceLifecycle.activities.receivingMethods.modal.importReceivingMethod.description'
        )}
        primaryButton={{
          label: formatMessage(
            'ar.invoiceLifecycle.activities.receivingMethods.modal.importReceivingMethod.primaryButton.label'
          ),
          onClick: () => onAdd('funding-source'),
          variant: 'primary',
          isLoading: isSaving,
          isDisabled: !selectedFundingSourceId,
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.invoiceLifecycle.activities.receivingMethods.modal.importReceivingMethod.secondaryButton.label'
          ),
          onClick: onClose,
          variant: 'tertiary',
          isDisabled: isSaving,
        }}
        {...props}
        ref={ref}
      >
        <FundingSourceCardList
          fundingSources={fundingSources}
          onAddPlaidAccount={() => onAdd('plaid')}
          onSelectedFundingSource={onSelectedFundingSource}
          selectedFundingSourceId={selectedFundingSourceId}
          isDisabled={isSaving}
        />
      </Modal>
    );
  }
);
ImportReceivingMethodModalScreen.displayName = 'ImportReceivingMethodScreen';
