import { StatusModal, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment, usePayment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

export type CancelRepaymentModalProps = {
  payment: Payment;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const CancelRepaymentModal = withAnalyticsContext(
  ({ payment, isOpen, onClose, onSuccess }: CancelRepaymentModalProps) => {
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { track, createTrackHandler } = useAnalytics();

    const trackAndHandleClick = createTrackHandler<{
      PageName: 'cancel-repayment';
      Cta: 'confirm' | 'exit';
    }>('Dashboard', 'Click', { PageName: 'cancel-repayment' });

    const { isMutating: isUpdatingPayment, delete: deletePayment } = usePayment({ id: payment.id, enabled: false });

    useEffect(() => {
      if (isOpen) {
        track('Dashboard', 'View', { PageName: 'cancel-repayment' });
      }
    }, [isOpen, track]);

    const onConfirm = async () => {
      try {
        await deletePayment();
        toast({
          type: 'informative',
          title: formatMessage('activities.payDashboard.drawer.footer.loan.cancelRepaymentModal.toast.success'),
        });
        onSuccess();
        onClose();
      } catch (error) {
        toast({
          type: 'error',
          title: formatMessage('activities.payDashboard.drawer.footer.loan.cancelRepaymentModal.toast.error'),
        });
        onClose();
      }
    };

    return (
      <StatusModal
        variant="cancel"
        isOpen={isOpen}
        header={formatMessage('activities.payDashboard.drawer.footer.loan.cancelRepaymentModal.title')}
        description={formatMessage('activities.payDashboard.drawer.footer.loan.cancelRepaymentModal.description', {
          vendorName: payment.vendor?.name,
        })}
        primaryButton={{
          label: formatMessage('activities.payDashboard.drawer.footer.loan.cancelRepaymentModal.confirm'),
          onClick: () => trackAndHandleClick({ Cta: 'confirm' }, onConfirm),
          variant: 'primary',
          isLoading: isUpdatingPayment,
        }}
        onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
        data-testid="cancel-repayment-modal"
      />
    );
  }
);
