import { Box } from '@chakra-ui/react';
import { ControlProps, Form } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { useEffect, useRef } from 'react';

type Props = ControlProps & {
  onClick?: VoidFunction;
};

export const SearchToggle = ({ name, control, onClick }: Props) => {
  const { formatMessage } = useMelioIntl();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      if (ref?.current?.contains(event.target as Node)) {
        event.preventDefault();
        ref.current.blur();
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  return (
    <Box cursor="pointer" onClick={onClick}>
      <Form.Search
        ref={ref}
        data-component="BusinessSearchWidgetToggle"
        options={[]}
        emptyState={{ label: '' }}
        labelProps={{ label: formatMessage('widgets.businessSearch.label') }}
        placeholder={formatMessage('widgets.businessSearch.placeholder')}
        name={name}
        control={control}
      />
    </Box>
  );
};
