/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import {
  ActionsDropdownMenu,
  BrandSymbol,
  BrandSymbolKey,
  brandSymbolsMap,
  Icon,
  IconKey,
  NakedButton,
  Text,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethodType } from '@melio/platform-api';

import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { DeliveryMethodCardTheme } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component.theme';

type NavigationParams = { path: string; options?: NavigateOptions };

type DeliveryMethodCardProps = {
  deliveryMethodExists?: boolean;
  navigationParams: NavigationParams;
  iconType: IconKey | BrandSymbolKey;
  displayName: string;
  helperText: string | JSX.Element;
  caption: string;
  type: DeliveryMethodType;
  info?: {
    info1: string | JSX.Element;
    info2: string | JSX.Element;
  };
  addMethodAriaLabel?: string;
  testId?: string;
};

export const DeliveryMethodCard = ({
  deliveryMethodExists = false,
  navigationParams,
  iconType,
  displayName,
  helperText,
  caption,
  info,
  type,
  addMethodAriaLabel,
  testId,
}: DeliveryMethodCardProps) => {
  const styles = useAppTheme(DeliveryMethodCardTheme, {});
  const { formatMessage } = usePlatformIntl();
  const navigate = useNavigate();
  const { track } = useAnalytics();

  const onClickHandler = React.useCallback(() => {
    if (type === DeliveryMethodType.BankAccount) {
      track('VendorsAddACHMethod', 'Chose');
    } else if (type === DeliveryMethodType.PaperCheck) {
      track('VendorsAddCheckMethod', 'Chose');
    } else if (type === DeliveryMethodType.VirtualCard) {
      track('VendorsAddVirtualCardMethod', 'Chose');
    } else if (type === 'international-account') {
      track('DeliveryMethod', 'Click', {
        DeliveryMethodType: 'international',
        Cta: 'add-method',
      });
    }
    const { path, options } = navigationParams;
    navigate(path, options);
  }, [track, navigate, type]);
  return (
    <MethodCard
      testId={testId}
      type={type}
      isDeliveryMethodExists={deliveryMethodExists}
      actionElement={
        deliveryMethodExists ? (
          type === DeliveryMethodType.InternationalAccount ? undefined : (
            <EditDeliveryMethod navigationParams={navigationParams} />
          )
        ) : (
          <NakedButton
            variant="secondary"
            onClick={onClickHandler}
            label={formatMessage('widgets.addOrUpdateDeliveryMethod.link')}
            aria-label={addMethodAriaLabel}
            data-testid={`add-delivery-method-card-${type}`}
          />
        )
      }
      icon={
        Object.keys(brandSymbolsMap).includes(iconType as BrandSymbolKey) ? (
          <BrandSymbol type={iconType as BrandSymbolKey} />
        ) : (
          <Icon type={iconType as IconKey} />
        )
      }
      displayName={displayName}
      helperText={helperText}
    >
      {info ? (
        <Stack flexDirection="column" sx={styles['container']}>
          <Text data-testid="delivery-method-card-info1" textStyle="body4">
            {info.info1}
          </Text>
          <Text data-testid="delivery-method-card-info2" textStyle="body4" color="neutral.darker">
            {info.info2}
          </Text>
        </Stack>
      ) : (
        <Text textStyle="body4" color="neutral.darker">
          {caption}
        </Text>
      )}
    </MethodCard>
  );
};

export const EditDeliveryMethod = ({ navigationParams }: { navigationParams: NavigationParams }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { formatMessage } = usePlatformIntl();
  const { track } = useAnalytics();
  const { path, options } = navigationParams;

  const onClickHandler = React.useCallback(() => {
    if (path.endsWith(DeliveryMethodType.BankAccount)) {
      track('VendorsEditACHMethod', 'Chose');
    } else if (path.endsWith(DeliveryMethodType.VirtualCard)) {
      track('VendorsEditVirtualCardMethod', 'Chose');
    } else {
      track('VendorsEditChecksMethod', 'Chose');
    }
    navigate(path, options);
  }, [path, navigate]);

  return (
    <ActionsDropdownMenu
      size="small"
      label={formatMessage('widgets.addOrUpdateDeliveryMethod.actionsMenu.label')}
      items={[
        {
          label: formatMessage('widgets.addOrUpdateDeliveryMethod.edit'),
          onClick: onClickHandler,
          dataTestId: 'edit-delivery-method-item',
        },
      ]}
      data-testid="delivery-method-menu"
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
    />
  );
};
