import { useInternationalPaymentCardRestrictionsText } from '@melio/ap-domain';
import { Group, Tooltip } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  Account,
  DeliveryMethodByPayor,
  FreeChecksData,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
  Vendor,
} from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { useGetSupportedInternationalDMs } from '../../utils/delivery-method-utils';
import { AddDeliveryMethodCard, DeliveryMethodCard } from '../cards';
import { PaperCheckMethodCard } from './components/PaperCheckMethodCard/PaperCheckMethodCard';

export type DeliveryMethodListProps = {
  account?: Account;
  data?: DeliveryMethodByPayor[];
  selectedId?: DeliveryMethodByPayor['id'];
  vendor?: Vendor;
  fundingSource?: FundingSource;
  freeChecks?: FreeChecksData;
  deliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
  onSelect?: (deliveryMethodId: DeliveryMethodByPayor['id']) => void;
  onEdit?: (type: Omit<DeliveryMethodByPayor['type'], 'virtual-account'>) => void;
  onAdd: (onAddArgs: { type: DeliveryMethodByPayor['type']; isInternationalFx?: boolean }) => void;
};

export const DeliveryMethodList = forwardRef<DeliveryMethodListProps>(
  (
    {
      fundingSource,
      freeChecks,
      data,
      vendor,
      selectedId,
      deliveryMethodTypeOptions,
      onSelect,
      onEdit,
      onAdd,
      account,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const {
      settings: { deliveryMethodTypeOrder },
    } = useConfig();
    const internationalPaymentRestrictionsLabelProvider = useInternationalPaymentCardRestrictionsText();
    const supportedInternationalDeliveryMethod = useGetSupportedInternationalDMs({
      vendorCurrency: vendor?.currency,
      businessType: account?.company.businessType,
    });

    const getAddDeliveryMethodCard = (
      type: DeliveryMethodByPayor['type'],
      supported: boolean,
      tooltip: string | false,
      reason?: SupportedDeliveryMethodTypeOption['reason'],
      isInternationalFx = false
    ) => {
      const newDeliveryMethodCard = (
        <AddDeliveryMethodCard
          type={type}
          supported={supported}
          reason={reason}
          key={`${type}-${isInternationalFx ? 'fx' : ''}`}
          isInternationalFx={isInternationalFx}
          fundingSourceType={fundingSource?.type}
          freeChecks={freeChecks}
          onClick={() => {
            track('AddDeliveryMethod', 'Chose', { DeliveryMethodChosen: type });
            onAdd({ type, isInternationalFx });
          }}
        />
      );
      return tooltip ? (
        <Tooltip data-testid={`new-delivery-method-tooltip-${type}`} label={tooltip} key={type}>
          {newDeliveryMethodCard}
        </Tooltip>
      ) : (
        newDeliveryMethodCard
      );
    };
    const getInternationalAddDMCards = (
      type: DeliveryMethodByPayor['type'],
      supported: boolean,
      tooltip: string | false,
      reason?: SupportedDeliveryMethodTypeOption['reason']
    ) => {
      const internationalFxCard = getAddDeliveryMethodCard(type, supported, tooltip, reason, true);
      const usdInternationalCard = getAddDeliveryMethodCard(type, supported, tooltip, reason);
      const cards = [];

      if (supportedInternationalDeliveryMethod.includes('international-account')) {
        cards.push(usdInternationalCard);
      }
      if (supportedInternationalDeliveryMethod.includes('international-fx')) {
        cards.push(internationalFxCard);
      }

      return cards;
    };

    return (
      <Group variant="vertical" spacing="s" data-component="DeliveryMethodList" {...props} ref={ref}>
        {deliveryMethodTypeOptions
          .sort(({ type: a }, { type: b }) => deliveryMethodTypeOrder[a] - deliveryMethodTypeOrder[b])
          .map(({ type, supported, reason }): JSX.Element | JSX.Element[] => {
            const deliveryMethod = data?.find((deliveryMethod) => deliveryMethod.type === type);
            const tooltip =
              !supported && reason === 'cardFundingSourceNotSupported'
                ? internationalPaymentRestrictionsLabelProvider(fundingSource)
                : false;

            const newDeliveryMethodCard = getAddDeliveryMethodCard(type, supported, tooltip, reason);

            if (deliveryMethod) {
              switch (deliveryMethod.type) {
                case 'paper-check':
                  return (
                    <PaperCheckMethodCard
                      deliveryMethod={deliveryMethod}
                      fundingSource={fundingSource}
                      freeChecks={freeChecks}
                      isSelected={deliveryMethod.id == selectedId}
                      ref={ref}
                      key={deliveryMethod.type}
                      onSelect={onSelect}
                      onEdit={onEdit}
                      isDisabled={!supported}
                    />
                  );
                default:
                  return (
                    <DeliveryMethodCard
                      key={deliveryMethod.type}
                      vendorName={vendor?.name || ''}
                      deliveryMethod={deliveryMethod}
                      fundingSource={fundingSource}
                      freeChecks={freeChecks}
                      onClick={() => {
                        track('DeliveryMethod', 'Chose', { DeliveryMethodChosen: deliveryMethod.type });
                        onSelect?.(deliveryMethod.id);
                      }}
                      isSelected={deliveryMethod.id == selectedId}
                      onEditClick={
                        onEdit && deliveryMethod.type !== 'virtual-account'
                          ? () => {
                              track('EditDeliveryMethod', 'Chose', { DeliveryMethodChosen: deliveryMethod.type });
                              onEdit(deliveryMethod.type);
                            }
                          : undefined
                      }
                      isDisabled={!supported}
                      disabledReason={reason}
                    />
                  );
              }
            } else {
              if (type === 'international-account') {
                return getInternationalAddDMCards(type, supported, tooltip, reason);
              }
              return newDeliveryMethodCard;
            }
          })
          .flat()}
      </Group>
    );
  }
);

DeliveryMethodList.displayName = 'DeliveryMethodList';
