import { DeprecatedSelectProps, Form, UseMelioFormResults } from '@melio/penny';
import { MccItem } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import { VendorDetailsFormFields } from './types';
import { useMccOptions } from './useMccOptions';

export const VendorMccFields = ({
  form,
  mccIndustryList,
  mccGroupsSupported,
}: {
  form: UseMelioFormResults<VendorDetailsFormFields>;
  mccIndustryList: MccItem[];
  mccGroupsSupported: boolean;
}) => {
  const { formatMessage } = useMelioIntl();

  const watchGroup = form.watch('group');
  useEffect(() => {
    form.setValue('mcc', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchGroup, form.setValue]);

  const { groupOptions, mccOptions } = useMccOptions(mccIndustryList, watchGroup, mccGroupsSupported);

  const noOptionsMessageCallback: DeprecatedSelectProps['noOptionsMessage'] = (obj) =>
    formatMessage('activities.fundingSources.vendorDetailsForm.fields.mcc.noResultsMessage', {
      value: obj?.inputValue,
    });

  const mccFieldLabel = mccGroupsSupported
    ? 'activities.fundingSources.vendorDetailsForm.fields.subcategory.label'
    : 'activities.fundingSources.vendorDetailsForm.fields.category.label';

  const mccFieldPlaceholder = mccGroupsSupported
    ? 'activities.fundingSources.vendorDetailsForm.fields.subcategory.placeholder'
    : 'activities.fundingSources.vendorDetailsForm.fields.category.placeholder';

  return (
    <>
      {mccGroupsSupported && (
        <Form.DeprecatedSelect
          size="small"
          colSpan={2}
          options={groupOptions}
          noOptionsMessage={noOptionsMessageCallback}
          labelProps={{ label: formatMessage('activities.fundingSources.vendorDetailsForm.fields.category.label') }}
          placeholder={formatMessage('activities.fundingSources.vendorDetailsForm.fields.category.placeholder')}
          data-testid="vendor-details-mcc-group-select"
          {...form.registerField('group')}
          isRequired
        />
      )}
      <Form.DeprecatedSelect
        size="small"
        colSpan={2}
        noOptionsMessage={noOptionsMessageCallback}
        options={mccOptions}
        labelProps={{ label: formatMessage(mccFieldLabel) }}
        placeholder={formatMessage(mccFieldPlaceholder)}
        data-testid="vendor-details-mcc-select"
        isHidden={mccGroupsSupported && isEmpty(watchGroup)}
        {...form.registerField('mcc')}
      />
    </>
  );
};
