import {
  AddVendorFormFields,
  AddVendorFormWidget,
  AddVendorFormWidgetProps,
  VendorFormBannerApiErrorCode,
  VendorFormInlineApiErrorCode,
} from '@melio/ap-widgets';
import { Group, Text, useBreakpointValue, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CreateVendorParams } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { FooterContent, SubmitTarget } from '../../components/FooterContent';
import { HeaderContent } from '../../components/HeaderContent';

export { SubmitTarget } from '../../components/FooterContent';

export type AddVendorScreenProps = {
  inlineApiErrorCodes?: VendorFormInlineApiErrorCode[];
  bannerApiErrorsCodes?: VendorFormBannerApiErrorCode[];
  defaultFormValues?: Partial<AddVendorFormFields>;
  managed?: AddVendorFormWidgetProps['managed'];
  onSelectCompany: (data: { companyName?: string; managed?: AddVendorFormWidgetProps['managed'] }) => void;
  isSaving?: boolean;
  isLoading?: boolean;
  onClose: VoidFunction;
  onDone: (data: CreateVendorParams, target?: `${SubmitTarget}`) => void;
  onClearCompanyNameSearchField?: AddVendorFormWidgetProps['onClearCompanyNameSearchField'];
};

const EMPTY_DEFAULT_VALUES = {};

export const AddVendorDetailsScreen = withAnalyticsContext<AddVendorScreenProps>(
  ({
    onClearCompanyNameSearchField,
    setAnalyticsProperties,
    bannerApiErrorsCodes,
    inlineApiErrorCodes,
    defaultFormValues = EMPTY_DEFAULT_VALUES,
    managed: initialManaged,
    isSaving,
    isLoading,
    onClose,
    onDone,
    onSelectCompany,
    ...props
  }) => {
    const { track } = useAnalytics();
    const isMobile = useBreakpointValue({ xs: true, s: false });

    useEffect(() => {
      track('Vendor', 'View', { VendorType: initialManaged ? 'directory' : 'local' });
    }, [initialManaged, track]);

    const handleSelectCompanyName: AddVendorFormWidgetProps['onSelectCompanyName'] = ({ companyName, business }) => {
      onSelectCompany({
        companyName,
        managed: business
          ? {
              managedBy: business.directoryName,
              self: business.business.self,
              isZipCodeNeeded: business.business.merchantZipRequired,
            }
          : undefined,
      });
    };

    const { formatMessage } = useMelioIntl();

    const [isSearchBusinessesInDirectoriesSupported] = useFeature<boolean>(
      FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
      false
    );

    const { onSubmissionStateChange, submitButtonProps, handleSubmit } =
      useFormSubmissionController<AddVendorFormFields>();

    setAnalyticsProperties({
      PageName: 'add-a-vendor',
      Intent: 'add-vendor-details',
    });

    const onSubmit = (submitTarget: SubmitTarget.CONTINUE | SubmitTarget.CLOSE) => {
      track('Vendor', 'Click', {
        Cta: submitTarget,
        VendorType: initialManaged ? 'directory' : 'local',
      });

      handleSubmit?.(submitTarget)();
    };

    return (
      <NewSinglePaymentStepLayout
        data-component="AddVendorActivity.AddVendorDetailsScreen"
        data-testid="add-vendor-activity-add-vendor-details-screen"
        {...props}
        isLoading={isLoading}
        headerContent={<HeaderContent onClose={onClose} />}
        isStickyFooter
        footerContent={
          <FooterContent
            onClose={onClose}
            onSubmit={onSubmit}
            submitButtonProps={submitButtonProps}
            managed={initialManaged}
          />
        }
      >
        {isMobile ? null : (
          <NewSinglePaymentStepLayout.Title textAlign="start" autoFocus={false}>
            {formatMessage('activities.addVendor.screens.addVendorDetails.title')}
          </NewSinglePaymentStepLayout.Title>
        )}
        {isSearchBusinessesInDirectoriesSupported && (
          <NewSinglePaymentStepLayout.Description
            as="p"
            paddingTop={isMobile ? 'xs' : undefined}
            textStyle={isMobile ? 'body3' : undefined}
            textAlign="start"
          >
            {formatMessage('activities.addVendor.searchAndAddVendorDetails.description')}
          </NewSinglePaymentStepLayout.Description>
        )}
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" spacing="xs" width="full">
            <Text color="neutral.darkest" textStyle={isMobile ? 'caption1' : 'body4'}>
              {formatMessage('activities.addVendor.searchAndAddVendorDetails.requiredFields')}
            </Text>
            <AddVendorFormWidget
              defaultValues={defaultFormValues}
              managed={initialManaged}
              onSelectCompanyName={handleSelectCompanyName}
              onClearCompanyNameSearchField={onClearCompanyNameSearchField}
              inlineApiErrorCodes={inlineApiErrorCodes}
              bannerApiErrorCodes={bannerApiErrorsCodes}
              onSubmit={(data, target) => {
                onDone(data, target as `${SubmitTarget}`);
              }}
              isSaving={isSaving}
              onSubmissionStateChange={onSubmissionStateChange}
            />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddVendorDetailsScreen.displayName = 'AddVendorActivity.AddVendorDetailsScreen';
