import { useAccountingPlatformPollingSync } from '@melio/ap-domain';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { AccountingPlatformSlug, PartnerName, useAccount, useAccountingPlatform } from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { useWizard, UseWizardArgs } from '@melio/platform-utils';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { MonitoredAction } from '../../monitoring';
import { useInitialStep } from './hooks/useInitialStep';
import { useInitQuickBooksDesktop } from './hooks/useInitQuickBooksDesktop';
import { useParseAuthSearchParams } from './hooks/useParseAuthSearchParams';
import { useRegisterOrganization } from './hooks/useRegisterOrganization';
import { useVerifyQuickBooksDesktop } from './hooks/useVerifyQuickBooksDesktop';
import { ConfirmConnectStep } from './steps/ConfirmConnectStep';
import { ConnectToQuickBooksDesktopStep } from './steps/ConnectToQuickBooksDesktopStep';
import { InitialStep } from './steps/InitialStep';
import { SelectOrganizationStep } from './steps/SelectOrganizationStep';
import { SyncErrorStep } from './steps/SyncErrorStep';
import { SyncStep } from './steps/SyncStep';
import {
  AccountingPlatformSyncConnectionErrorEnum,
  ActivityStepsEnum,
  InitialStepProps,
  Steps,
  SyncFlowEnum,
} from './types';

export const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  [ActivityStepsEnum.Initial]: ActivityStepsEnum.Initial,
  [ActivityStepsEnum.SelectOrganization]: ActivityStepsEnum.SelectOrganization,
  [ActivityStepsEnum.ConnectToQuickBooksDesktop]: ActivityStepsEnum.ConnectToQuickBooksDesktop,
  [ActivityStepsEnum.ConfirmConnect]: ActivityStepsEnum.ConfirmConnect,
  [ActivityStepsEnum.Sync]: ActivityStepsEnum.Sync,
  [ActivityStepsEnum.SyncError]: ActivityStepsEnum.SyncError,
};

const navigationMap = {
  [ActivityStepsEnum.Initial]: ({
    isQuickBooksDesktop,
    isMultiOrgsAvailableToConnect,
    isRegistrableQuickBooksDesktopOrganizationExists,
  }: InitialStepProps) => {
    if (isQuickBooksDesktop) {
      if (isRegistrableQuickBooksDesktopOrganizationExists) {
        return ActivityStepsEnum.ConfirmConnect;
      }
      return ActivityStepsEnum.ConnectToQuickBooksDesktop;
    }

    if (isMultiOrgsAvailableToConnect) {
      return ActivityStepsEnum.SelectOrganization;
    }

    return ActivityStepsEnum.ConfirmConnect;
  },
  [ActivityStepsEnum.SelectOrganization]: () => ActivityStepsEnum.Sync,
  [ActivityStepsEnum.ConnectToQuickBooksDesktop]: () => ActivityStepsEnum.Sync,
  [ActivityStepsEnum.ConfirmConnect]: () => ActivityStepsEnum.Sync,
  [ActivityStepsEnum.Sync]: () => ActivityStepsEnum.SyncError,
  [ActivityStepsEnum.SyncError]: () => ActivityStepsEnum.Sync,
};

const firstXeroSyncMarkerName = 'first_accounting_software_sync_xero';

type Props = {
  partnerName: PartnerName;
  onDone: (flow: SyncFlowEnum, accountingPlatformName: string) => void;
  onError: (error: AccountingPlatformSyncConnectionErrorEnum, accountingPlatformId?: string) => void;
  onClose: (flow: SyncFlowEnum) => void;
};

export const AccountingPlatformConnectActivity = withAnalyticsContext<Props>(
  ({ partnerName, onDone, onError, onClose, setAnalyticsProperties }) => {
    const { goNextMap, completeFlow } = useWizard<Steps, typeof navigationMap>({
      firstStep: ActivityStepsEnum.Initial,
      flowName: 'AccountingPlatformConnect',
      locationsMap,
      navigationMap,
      cancelUrlFallback: '',
    });
    const { isLoading: isLoadingAccount, data: currentAccount } = useAccount({ id: 'me' });
    const { authError, accountingPlatformId, isQuickBooksDesktop, flow, authParams } = useParseAuthSearchParams() || {};
    const { data: accountingPlatform, isLoading: isLoadingAccountingPlatform } = useAccountingPlatform({
      id: accountingPlatformId,
    });
    const accountingPlatformName = useAccountingPlatformName(accountingPlatform?.accountingSlug);
    const { registerOrganization, isRegisteringOrganization, isOrganizationRegistered } = useRegisterOrganization({
      accountingPlatformId,
      authParams,
    });
    const {
      initQuickBooksDesktop,
      isMutating: initQuickBooksDesktopLoading,
      initQuickBooksDesktopData,
    } = useInitQuickBooksDesktop({
      accountingPlatformId,
      organizationId: currentAccount?.organizationId || '',
    });
    const {
      verifyQuickBooksDesktop,
      isMutating: verifyQuickBooksDesktopLoading,
      isUnavailable: isQuickBooksDesktopUnavailable,
      registrableQuickBooksDesktopOrganization,
    } = useVerifyQuickBooksDesktop({
      accountingPlatformId,
      organizationId: currentAccount?.organizationId || '',
      onSuccess: () => {
        if (initQuickBooksDesktopData?.externalOrganizationId) {
          goNextMap[ActivityStepsEnum.Initial]({
            navArgs: [
              {
                isQuickBooksDesktop,
                isRegistrableQuickBooksDesktopOrganizationExists:
                  !!registrableQuickBooksDesktopOrganization?.externalId,
                isMultiOrgsAvailableToConnect: false,
              },
            ],
          });
        }
      },
    });
    const companyName = currentAccount?.company?.name;
    const { startAction, endAction } = useMonitoring<MonitoredAction>();

    const { triggerSync, isRunning } = useAccountingPlatformPollingSync({
      enabled: isOrganizationRegistered,
      accountingPlatformId: accountingPlatformId || '',
      isQuickBooksDesktop,
      onSyncDone: () => {
        if (accountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero) {
          endAction(firstXeroSyncMarkerName);
        }
        completeFlow(() => {
          onDone(flow || SyncFlowEnum.Setting, accountingPlatformName);
        });
      },
      onSyncError: () => {
        goNextMap[ActivityStepsEnum.Sync]();
      },
    });
    setAnalyticsProperties({
      Intent: 'sync',
      EntryPoint: 'settings-page',
      Flow: accountingPlatform?.accountingSlug ? `${accountingPlatform?.accountingSlug}-sync` : undefined,
    });

    useEffect(() => {
      if (isRunning && accountingPlatform?.accountingSlug === AccountingPlatformSlug.Xero) {
        startAction(firstXeroSyncMarkerName);
      }
    }, [isRunning, accountingPlatform, startAction]);

    const handleCancelClick = () => {
      onClose(flow || SyncFlowEnum.Setting);
    };

    const { registrableExternalOrganizations } = useInitialStep({
      authError,
      authParams,
      isQuickBooksDesktop,
      accountingPlatformId,
      onError: (error: AccountingPlatformSyncConnectionErrorEnum) => {
        onError(error, accountingPlatformId);
      },
      onOrganizationsToConnectFound(isQuickBooksDesktop, organizationsCount) {
        goNextMap[ActivityStepsEnum.Initial]({
          navArgs: [{ isQuickBooksDesktop, isMultiOrgsAvailableToConnect: organizationsCount > 1 }],
        });
      },
    });

    const handleConnectSelectedOrganization = async (externalOrganizationId: string) => {
      await registerOrganization(externalOrganizationId);
      triggerSync();
      goNextMap[ActivityStepsEnum.SelectOrganization]();
    };

    const handleConfirmConnectOrganization = async (externalOrganizationId: string) => {
      await registerOrganization(externalOrganizationId);
      triggerSync();
      goNextMap[ActivityStepsEnum.ConfirmConnect]();
    };

    const handleTrySyncAgain = () => {
      triggerSync();
      goNextMap[ActivityStepsEnum.SyncError]();
    };

    const onDownloadWebConnector = () => {
      const link = document.createElement('a');
      const file = new Blob([initQuickBooksDesktopData?.qwcFileXMLContent || ''], { type: 'text/xml' });
      link.href = URL.createObjectURL(file);
      link.download = `${companyName || 'Melio'}-${partnerName}.qwc`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <Routes>
        <Route path={locationsMap[ActivityStepsEnum.Initial]} element={<InitialStep />} />
        <Route
          path={locationsMap[ActivityStepsEnum.SelectOrganization]}
          element={
            <SelectOrganizationStep
              isSubmitting={isRegisteringOrganization}
              externalOrganizations={registrableExternalOrganizations}
              accountingPlatformName={accountingPlatformName}
              onCancel={handleCancelClick}
              onSubmitOrganization={handleConnectSelectedOrganization}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.ConnectToQuickBooksDesktop]}
          element={
            <ConnectToQuickBooksDesktopStep
              isError={isQuickBooksDesktopUnavailable}
              isLoading={initQuickBooksDesktopLoading}
              isVerifyLoading={verifyQuickBooksDesktopLoading}
              onCancel={handleCancelClick}
              initQuickBooksDesktopData={initQuickBooksDesktopData}
              onVerifyConnection={verifyQuickBooksDesktop}
              initQuickBooksDesktop={initQuickBooksDesktop}
              onDownloadWebConnector={onDownloadWebConnector}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.ConfirmConnect]}
          element={
            <ConfirmConnectStep
              isLoading={isRegisteringOrganization || isLoadingAccount}
              companyName={companyName}
              externalOrganizations={
                isQuickBooksDesktop ? [registrableQuickBooksDesktopOrganization] : registrableExternalOrganizations
              }
              accountingPlatformName={accountingPlatformName}
              onCancel={handleCancelClick}
              onSubmitOrganization={handleConfirmConnectOrganization}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.Sync]}
          element={
            <SyncStep
              accountingPlatformName={accountingPlatformName}
              isLoadingAccountingPlatform={isLoadingAccountingPlatform}
            />
          }
        />
        <Route
          path={locationsMap[ActivityStepsEnum.SyncError]}
          element={<SyncErrorStep onCancel={handleCancelClick} onTryAgain={handleTrySyncAgain} />}
        />
      </Routes>
    );
  }
);
