import { Money } from '@melio/money';
import { Form, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { getAvailableBalance } from '../../../../util/amount';
import { AmountInputProps } from '../types';

type BasicAmountInputProps = AmountInputProps & {
  hideLabel?: boolean;
  hideHelperText?: boolean;
};

export const BasicAmountInput = ({ form, onChange, bill, payment, hideHelperText = false }: BasicAmountInputProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { registerField, watch, setValue } = form;
  const [amountToPay] = watch(['amountToPay']);
  const isDisabled = Boolean((payment?.bills?.length || 0) > 1);

  let partialAmountText;
  if (bill && amountToPay) {
    const current = Money.fromNaturalUnit(amountToPay, bill.currency);
    const availableBalance = getAvailableBalance(bill, payment);
    const expectedBalance = availableBalance.minus(current).toNaturalUnit();

    if (expectedBalance > 0 && expectedBalance < availableBalance.toNaturalUnit()) {
      partialAmountText = formatMessage('activities.paymentFlow.form.content.amountToPay.helperText.partialPayment', {
        remainingAmount: formatCurrency(expectedBalance),
        invoiceNumber: bill?.invoice.number || undefined,
      });
    }
  }

  return (
    <Group width="full" variant="vertical" spacing="s">
      <Form.AmountField
        {...registerField('amountToPay')}
        labelProps={{ label: formatMessage('activities.paymentFlow.form.content.amountToPay.label') }}
        integerLimit={6}
        decimalLimit={2}
        allowNegativeValue={false}
        isDisabled={isDisabled}
        onBlur={async () => {
          const valid = await form.trigger('amountToPay');
          if (amountToPay && valid) {
            onChange(amountToPay);
          }
        }}
        onChange={(event) => setValue('amountToPay', event.target.value, { shouldValidate: true })}
      />
      {!hideHelperText && partialAmountText && (
        <Text textStyle="body3" color="neutral.darker" data-testid="amountToPay-partial-helperText">
          {partialAmountText}
        </Text>
      )}
    </Group>
  );
};
