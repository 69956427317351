import { useMelioIntl } from '@melio/platform-i18n';

import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';

export const PaymentsTabClearedState = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <PayDashboardClearedState
      title={formatMessage('activities.payDashboard.paymentsTab.clearedState.title')}
      aria-label={formatMessage('activities.payDashboard.paymentsTab.clearedState.description')}
      description={formatMessage('activities.payDashboard.paymentsTab.clearedState.ariaLabel')}
      illustrationSrc="pay-zero"
    />
  );
};
