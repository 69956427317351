import { TableColumnDef } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { useTableColumnsSort } from '../../hooks/useTableColumnsSort';
import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PaymentsTabActionButtonCell } from './components/PaymentsTabActionButtonCell';
import { PaymentsTabAmountCell } from './components/PaymentsTabAmountCell';
import { PaymentsTabBillsOriginCell } from './components/PaymentsTabBillsOriginCell';
import { PaymentsTabDebitDateCell } from './components/PaymentsTabDebitDateCell';
import { PaymentsTabDeliveryDateCell } from './components/PaymentsTabDeliveryDateCell';
import { PaymentsTabDeliveryMethodCell } from './components/PaymentsTabDeliveryMethodCell';
import { PaymentsTabStatusCell } from './components/PaymentsTabStatusCell';
import { PaymentsTabVendorNameCell } from './components/PaymentsTabVendorNameCell';
import { CELLS_IDS, PaymentsTabFilter, PaymentsTableMeta, PaymentsTabSortableCells } from './types';
import { usePaymentsTabSortableColumns } from './usePaymentsTabSortableColumns';

type Props = {
  filter: PaymentsTabFilter;
  onSortChange: VoidFunction;
};

export const usePaymentsTableColumns = ({ filter, onSortChange }: Props) => {
  const { formatMessage } = useMelioIntl();

  const sorting = usePayDashboardSorting();
  const { getSortableHeader } = useTableColumnsSort<PaymentsTabSortableCells>({
    sorting,
    onSortChange,
  });
  const { sortableColumns } = usePaymentsTabSortableColumns();

  return useMemo<TableColumnDef<Payment, PaymentsTableMeta>[]>(() => {
    const vendorCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.VENDOR_NAME,
      isPinnedToLeft: true,
      size: 'm',
      header: getSortableHeader(sortableColumns[CELLS_IDS.VENDOR_NAME]),
      cell: ({ row, meta }) => (meta ? <PaymentsTabVendorNameCell payment={row} /> : null),
    };
    const deliveryMethodCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.DELIVERY_METHOD,
      size: 's',
      header: formatMessage('activities.payDashboard.paymentsTab.table.headers.deliveryMethod'),
      cell: ({ row }) => <PaymentsTabDeliveryMethodCell payment={row} />,
    };
    const billCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.BILL,
      size: 's',
      header: formatMessage('activities.payDashboard.paymentsTab.table.headers.bill'),
      cell: ({ row }) => (row.bills?.[0] ? <PaymentsTabBillsOriginCell payment={row} /> : null),
    };
    const debitDateCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.DEBIT_DATE,
      size: 's',
      header: getSortableHeader(sortableColumns[CELLS_IDS.DEBIT_DATE]),
      cell: ({ row }) => <PaymentsTabDebitDateCell payment={row} />,
    };
    const deliveryDateCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.DELIVERY_DATE,
      size: 's',
      header: getSortableHeader(sortableColumns[CELLS_IDS.DELIVERY_DATE]),
      cell: ({ row }) => <PaymentsTabDeliveryDateCell payment={row} />,
    };
    const statusCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.STATUS,
      size: 140,
      header: formatMessage('activities.payDashboard.paymentsTab.table.headers.status'),
      cell: ({ row }) => <PaymentsTabStatusCell payment={row} />,
    };
    const amountCell: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.AMOUNT,
      size: 180,
      header: getSortableHeader({ ...sortableColumns[CELLS_IDS.AMOUNT], textAlign: 'end' }),
      textAlign: 'end',
      cell: ({ row }) => <PaymentsTabAmountCell payment={row} />,
    };
    const actionButtonColumn: TableColumnDef<Payment, PaymentsTableMeta> = {
      id: CELLS_IDS.ACTION_BUTTON,
      size: 140,
      header: formatMessage('activities.payDashboard.table.headers.action'),
      textAlign: 'end',
      cell: ({ row, meta }) =>
        meta ? <PaymentsTabActionButtonCell payment={row} onViewClick={meta.onViewClick} /> : null,
    };

    return [
      vendorCell,
      billCell,
      deliveryMethodCell,
      debitDateCell,
      deliveryDateCell,
      statusCell,
      amountCell,
      actionButtonColumn,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, filter, sorting?.sortingState]);
};
