import { Button, Container, Divider, Group, useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionMe } from '../../../api';
import { usePartnerGroups, useRedirects, useSubscription, useSubscriptionPlan } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';

type SubscriptionSelectFundingSourceFooterProps = {
  selectedFundingSourceId: string;
};

export const SubscriptionSelectFundingSourceFooter = ({
  selectedFundingSourceId,
}: SubscriptionSelectFundingSourceFooterProps) => {
  const { formatMessage } = useMelioIntl();
  const { goToSubscriptionPlansSettings } = useSubscriptionRouter();
  const { currentPlan } = useSubscriptionPlan();
  const subscription = useSubscription();
  const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
  const { goToSettingsPage } = useRedirects();
  const { toast } = useToast();
  const { isFiservPartner } = usePartnerGroups();

  const onUpdateSuccess = () => {
    goToSettingsPage();

    toast({
      type: 'success',
      title: formatMessage('activities.subscription.paymentMethod.select.success', {}),
      id: 'subscription-paymentMethod-change-success',
    });
  };

  const onUpdateError = () => {
    goToSettingsPage();

    toast({
      type: 'error',
      title: formatMessage('activities.subscription.paymentMethod.select.error', {}),
      id: 'subscription-paymentMethod-change-error',
    });
  };

  const updateFundingSource = (fundingSourceId: string) => {
    if (!currentPlan) {
      return;
    }
    void updateSubscription({ fundingSourceId })
      .then(() => {
        onUpdateSuccess();
      })
      .catch(() => {
        onUpdateError();
      });
  };

  const SaveButton = () => (
    <Button
      size="large"
      variant="primary"
      data-testid="subscription-payment-method-select-save-button"
      isDisabled={selectedFundingSourceId === subscription?.fundingSourceId}
      isLoading={isUpdatingSubscription}
      label={formatMessage('activities.subscription.paymentMethod.select.save')}
      onClick={() => updateFundingSource(selectedFundingSourceId)}
    />
  );

  return isFiservPartner ? (
    <>
      <Divider />
      <Container paddingX="m" paddingY="m">
        <Group justifyContent="space-between">
          <Button
            size="large"
            data-testid="add-bill-v2-cancel-button"
            variant="tertiary"
            label={formatMessage('activities.addBillV2.footer.buttons.cancel')}
            onClick={goToSubscriptionPlansSettings}
          />
          <SaveButton />
        </Group>
      </Container>
    </>
  ) : (
    <Container width="full" paddingX="m" paddingY="m" justifyContent="center">
      <SaveButton />
    </Container>
  );
};
