import { SelectableDropdownMenu, SelectableDropdownMenuItem, Table } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { noop } from 'lodash';
import { useState } from 'react';

import { DeliveryDateHeaderCellOption } from '../../../../types';
import { useDeliveryDateDescription } from './DeliveryDateHeaderCell.utils';

type DeliveryDateHeaderCellProps = {
  deliveryDateHeaderCellOption: DeliveryDateHeaderCellOption;
  onSelectByDueDate: VoidFunction;
  onSelectByEarliestDate: VoidFunction;
};

export const DeliveryDateHeaderCell = forwardRef<DeliveryDateHeaderCellProps, 'div'>(
  ({ deliveryDateHeaderCellOption, onSelectByDueDate, onSelectByEarliestDate }, ref) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { formatMessage } = useMelioIntl();
    const { getDescription } = useDeliveryDateDescription();
    const description = getDescription(deliveryDateHeaderCellOption);

    const triggerComponent = (
      <Table._MenuTriggerHeaderCell
        label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliveryDateCell.header')}
        onClick={noop}
        description={description}
        data-testid="delivery-date-header-cell"
      />
    );

    const options: SelectableDropdownMenuItem[] = [
      {
        onClick: onSelectByEarliestDate,
        label: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateHeaderCell.description.earliestPossible'
        ),
        value: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateHeaderCell.description.earliestPossible'
        ),
        dataTestId: 'earliest-possible',
      },
      {
        onClick: onSelectByDueDate,
        label: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateHeaderCell.description.byDueDate'
        ),
        value: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliveryDateHeaderCell.description.byDueDate'
        ),
        dataTestId: 'by-due-date',
      },
    ];

    return (
      <SelectableDropdownMenu
        trigger={triggerComponent}
        items={options}
        ref={ref}
        isOpen={isMenuOpen}
        onOpenChange={setIsMenuOpen}
      />
    );
  }
);
