import { Button, Container, Group } from '@melio/penny';
import React from 'react';

import { StepFormProps } from '../../types';

export const PlanSelectionStep: React.FC<StepFormProps> = ({ submitLabel, nextStep }) => {
  const verifyPlanSelection = () => {
    nextStep?.();
  };

  return (
    <Group spacing="m" variant="vertical" width="full">
      <span>placeholder</span>
      <Container justifyContent="flex-end">
        <Button variant="primary" size="medium" label={submitLabel} onClick={verifyPlanSelection} />
      </Container>
    </Group>
  );
};
