import { CountryData, Currency, getTransferRequiredFields } from '@melio/international-payments-utils';
import { Money } from '@melio/money';
import {
  Bill,
  BillInfo,
  CountryInternationalDeliveryDetails,
  DeliveryMethod,
  DeliveryMethodType,
  ExchangeRateBase,
  FxCurrency,
  OrganizationBusinessType,
  Payment,
  PaymentIntent,
} from '@melio/platform-api';

export const FX_ALLOWED_BUSINESS_TYPES: OrganizationBusinessType[] = [
  OrganizationBusinessType.SoleProprietorship,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.MunicipalCorporation,
  OrganizationBusinessType.TrustOrEstate,
];

export const isBusinessTypeAllowedForFX = (businessType?: OrganizationBusinessType | null) =>
  businessType ? FX_ALLOWED_BUSINESS_TYPES.includes(businessType) : true;

export const convertFxAmountToUsdAmount = ({
  paymentAmount,
  currency,
  fxRate,
}: {
  paymentAmount: number;
  currency?: string;
  fxRate: ExchangeRateBase;
}) => {
  const amountInUsd = Money.fromBaseUnit(paymentAmount, currency ?? FxCurrency.Usd).convert(
    'USD',
    fxRate.usdToForeignRate
  );
  return amountInUsd.amount.toNumber();
};

export const isFXCurrency = (currency?: FxCurrency | null) => !!(currency && currency !== FxCurrency.Usd);

export const isFxBill = (bill?: Bill | BillInfo) => isFXCurrency(bill?.currency);

export const isFxPaymentIntent = (paymentIntent: PaymentIntent) => isFxBill(paymentIntent.billInfo);

export const getDMCurrency = (deliveryMethod?: DeliveryMethod | null) =>
  deliveryMethod?.type === DeliveryMethodType.InternationalAccount ? deliveryMethod.details.currency : FxCurrency.Usd;

export const isFxDM = (deliveryMethod: DeliveryMethod) => isFXCurrency(getDMCurrency(deliveryMethod));

export const fxMemoRegex = /^[A-Za-z0-9\-./?:(), +"]*$/;

export const isTransferRequireForCurrency = (currency: Currency) =>
  getTransferRequiredFields(currency).alternatives.length > 0;

export const checkCountryRiskLevel = ({
  country,
  paymentsData,
  hasInvoice,
  vendorId,
}: {
  country: CountryData | CountryInternationalDeliveryDetails | undefined;
  paymentsData: Payment[] | undefined;
  hasInvoice: boolean;
  vendorId?: string;
}) => {
  if (!country || hasInvoice) {
    return false;
  }

  if (country.risk === 'high') {
    return true;
  }

  if (country.risk === 'medium') {
    return !paymentsData?.some((payment) => payment.vendorId === vendorId);
  }

  return false;
};
