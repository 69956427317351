import { Group, Illustration, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { SectionHeaderWithButton } from '../ManageCollaborators/screens/components/SectionHeaderWithButton';

export const ApprovalWorkflowSettingsErrorState = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <>
      <SectionHeaderWithButton title={formatMessage('activities.settings.approval-workflow-settings.title')} />
      <Group variant="vertical" alignItems="center" data-testid="workflow-settings-root-network-error">
        <Illustration type="network-error" size="small" />
        <Text textStyle="body3">{formatMessage('activities.settings.approval-workflow-settings.network-error')}</Text>
      </Group>
    </>
  );
};
