import { AccountingPlatformBillLabelOption } from '@melio/platform-api';

import { BillLabelOption } from './AccountingPlatformBillLalbelSelect.widget.types';

export const generateBillLabelSelectOptions = (
  options: AccountingPlatformBillLabelOption[] = []
): BillLabelOption[] => {
  const optionsMap = options.reduce<
    Record<string, BillLabelOption & Pick<AccountingPlatformBillLabelOption, 'parentOptionId'>>
  >(
    (acc, curr) => ({
      ...acc,
      [curr.id]: {
        label: curr.name,
        value: curr.id,
        subOptions: [] as BillLabelOption[],
        parentOptionId: curr.parentOptionId,
      },
    }),
    {}
  );

  return options.reduce<BillLabelOption[]>((acc, { id }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const option = optionsMap[id]!;
    const parentOption = option.parentOptionId && optionsMap[option.parentOptionId];
    if (parentOption) {
      parentOption.subOptions?.push(option);
    } else {
      acc.push(option);
    }

    return acc;
  }, []);
};
