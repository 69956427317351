import React from 'react';
import { Group, Switch, Text } from '@melio/penny';

export type NotificationItemProps = {
  title: string;
  enabled: boolean;
  notificationName: string;
  onChange?: (isChecked: boolean) => void;
};

export const NotificationItem: React.FC<NotificationItemProps> = ({ title, enabled, notificationName, onChange }) => (
  <Group justifyContent="space-between">
    <Text>{title}</Text>
    <Switch
      data-testid={`notification-switch-${notificationName}`}
      size="large"
      defaultIsChecked={enabled}
      onChange={onChange}
    />
  </Group>
);

NotificationItem.displayName = 'NotificationItem';
