import { Box } from '@chakra-ui/react';
import { Badge, BadgeProps, Grid, Group, Text } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

import { useIsMobile } from '../../../../../../utils/viewport-utils/useIsMobile';

export const PaymentDetailsCardRow = ({
  fieldName,
  titleMessage,
  value,
  badges,
}: {
  fieldName: string;
  titleMessage: MessageKey;
  value: string;
  badges?: BadgeProps[];
}) => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useIsMobile();

  return (
    <Grid
      as="li"
      gridTemplateColumns={isMobile ? 'none' : '2fr 3fr'}
      data-testid={`payment-details-card-row-${fieldName}`}
    >
      <Box paddingRight="xs" display="flex" alignItems="center">
        <Text data-testid={`payment-details-card-row-title-${fieldName}`} textStyle="body3Semi">
          {formatMessage(titleMessage)}
        </Text>
      </Box>
      <Text textStyle="body3">
        <Group spacing="xxs" data-testid={`payment-details-card-row-value-${fieldName}`}>
          <Group alignItems="center" spacing="xxs">
            {value}
          </Group>
          {badges?.length ? <RowBadges badgeProps={badges} /> : null}
        </Group>
      </Text>
    </Grid>
  );
};

const RowBadges = ({ badgeProps }: { badgeProps: BadgeProps[] }) => (
  <Box paddingTop="xxxs">
    <Group spacing="xxs">
      {badgeProps.map((badge, i) => (
        <Badge
          type="secondary"
          key={i}
          status={badge.status}
          label={badge.label}
          data-testid={`${badge.label}-badge`}
        />
      ))}
    </Group>
  </Box>
);
