import { Button, Group, Icon, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAllowedActions } from '../useAllowedActions';

type Props = { onAddCollaboratorClick: VoidFunction };
export const Header = ({ onAddCollaboratorClick }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { canInviteCollaborator } = useAllowedActions();

  return (
    <Group variant="horizontal">
      <Group.Item grow={1}>
        <Text as="h3" textStyle="heading3Semi">
          {formatMessage('activities.settings.collaborators.heading.title')}
        </Text>
      </Group.Item>

      {canInviteCollaborator ? (
        <Group.Item>
          <Button
            data-testid="add-collaborator-button"
            leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
            size="small"
            label={formatMessage('activities.settings.collaborators.heading.button')}
            onClick={onAddCollaboratorClick}
          />
        </Group.Item>
      ) : null}
    </Group>
  );
};
