import { NakedButton } from '@melio/penny';
import { Card, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

type AddFundingSourceButtonProps = {
  fundingType: FundingSource['type'];
  cardType?: Card['type'];
  onClick: VoidFunction;
  isDisabled?: boolean;
};

export const AddFundingSourceButton: React.VFC<AddFundingSourceButtonProps> = ({
  fundingType,
  cardType,
  onClick,
  isDisabled,
}) => {
  const { formatMessage } = useMelioIntl();

  const getLabel = (fundingType: FundingSource['type'], cardType?: Card['type']): string => {
    if (fundingType === 'bank-account') {
      return formatMessage('widgets.fundingSources.cardList.addBank');
    } else if (cardType === 'credit') {
      return formatMessage('widgets.fundingSources.cardList.addCredit');
    } else if (cardType === 'debit') {
      return formatMessage('widgets.fundingSources.cardList.addDebit');
    } else if (fundingType === 'card') {
      return formatMessage('widgets.fundingSources.cardList.addCard');
    }

    return `Label for ${fundingType as string}:${cardType ?? ''} not implemented`;
  };

  const label = `+ ${getLabel(fundingType, cardType)}`;
  return (
    <NakedButton
      data-testid={`add-${fundingType as string}-funding-source-button`}
      data-component="AddFundingSourceButton"
      variant="secondary"
      onClick={onClick}
      isDisabled={isDisabled}
      label={label}
    />
  );
};
