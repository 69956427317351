import { EventProperties } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-provider';
import React from 'react';

import { PlatformTourtip, usePlatformTourtip } from '../../../../platform-tourtip';
import { PayDashboardSortingProvider } from '../../../PayDashboardSortingProvider';
import { PayDashboardPaginationProvider } from '../../Pagination';
import { VENDORS_TAB_DEFAULT_SORT } from '../../VendorsTab/VendorsTab';
import { FISERV_DASHBOARD_TOUR_PREFERENCE_KEY } from '../consts';
import { useFiservDashboardTourSteps } from './useFiservDashboardTourSteps';

export const FiservDashboardTourComponent = () => {
  const [isFiservDashboardTourEnabled] = useFeature<boolean>(FeatureFlags.IsFiservDashboardTourEnabled, false);

  const { formatMessage } = useMelioIntl();

  const { steps, isExistingUser, isLoading } = useFiservDashboardTourSteps({
    enabled: isFiservDashboardTourEnabled,
  });

  const { isTourActive, ...tourtipProps } = usePlatformTourtip({
    tourName: 'fiservDashboardTour',
    enabled: isFiservDashboardTourEnabled && !isLoading,
    userPreferenceKey: FISERV_DASHBOARD_TOUR_PREFERENCE_KEY,
    steps,
    defaultLabels: {
      nextButtonLabel: formatMessage('activities.payDashboard.tour.nextButton.label'),
      prevButtonLabel: formatMessage('activities.payDashboard.tour.backButton.label'),
    },
  });

  const analyticsProperties: EventProperties = {
    Flow: isExistingUser ? 'fte-existing-user' : 'fte-new-user',
    Intent: 'fte-guide',
    EntryPoint: 'login-page',
  };

  return <PlatformTourtip {...tourtipProps} steps={steps} analyticsProperties={analyticsProperties} />;
};

export const FiservDashboardTour = () => (
  <PayDashboardPaginationProvider>
    <PayDashboardSortingProvider defaultSort={VENDORS_TAB_DEFAULT_SORT}>
      <FiservDashboardTourComponent />
    </PayDashboardSortingProvider>
  </PayDashboardPaginationProvider>
);
