import { Box } from '@chakra-ui/react';
import { Container, Group, Icon, Link, Text, Tooltip, useBreakpoint } from '@melio/penny';

export type TodoSectionProps = {
  type: string;
  icon: JSX.Element;
  title: string;
  description: { label: string; value?: string; popover?: string }[];
  action: { label: string; onClick: () => void };
};

export const TodoSection = ({ icon, title, description, action, type }: TodoSectionProps) => {
  const { isExtraSmallScreen } = useBreakpoint();

  const renderTitle = () => {
    if (isExtraSmallScreen) {
      return (
        <Text textStyle="body3Semi">
          <Link href="#" onClick={action.onClick} label={title} size="medium" color="secondary" data-testid="title" />
        </Text>
      );
    }
    return (
      <Text textStyle="body3Semi" data-testid="title">
        {title}
      </Text>
    );
  };

  const renderPopover = (text: string) => (
    <Container alignItems="center" paddingLeft="xxs" paddingBottom="xxxs" width="fit-content">
      <Tooltip label={text}>
        <Box
          as={Icon}
          role="tooltip"
          type="info"
          size="small"
          data-testid="description-popover"
          tabIndex={0}
          aria-label={text}
        />
      </Tooltip>
    </Container>
  );

  return (
    <Container data-testid={`todo-section-${type}`}>
      <Group variant="horizontal" justifyContent="space-between" width="full" height="full" alignItems="center">
        <Group.Item>
          <Group alignItems="center">
            {icon}
            <Group variant="vertical" spacing="xxs">
              {renderTitle()}
              {description.length ? (
                <Group
                  variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                  alignItems={isExtraSmallScreen ? 'flex-start' : 'center'}
                  spacing={isExtraSmallScreen ? 'none' : 'xs'}
                  data-testid="description"
                >
                  {description.map((d, i) => (
                    <Group spacing={isExtraSmallScreen ? 'none' : 'xs'} key={`desc-${i}`}>
                      <Group spacing="xxs" alignItems="center">
                        <Text textStyle="body3" color="neutral.darkest">
                          {d.label}
                        </Text>
                        {d.value && (
                          <Text textStyle="body3Semi" color="neutral.darkest">
                            {d.value}
                          </Text>
                        )}
                        {d.popover && renderPopover(d.popover)}
                      </Group>
                      {!isExtraSmallScreen && i < description.length - 1 && (
                        <Text textStyle="body3" color="neutral.darkest">
                          |
                        </Text>
                      )}
                    </Group>
                  ))}
                </Group>
              ) : null}
            </Group>
          </Group>
        </Group.Item>

        <Group.Item shrink={0}>
          <Group alignItems="center">
            {isExtraSmallScreen ? null : (
              <Text textStyle="body3Semi">
                <Link
                  href="#"
                  onClick={action.onClick}
                  label={action.label}
                  size="medium"
                  color="secondary"
                  data-testid="action"
                />
              </Text>
            )}
          </Group>
        </Group.Item>
      </Group>
    </Container>
  );
};
