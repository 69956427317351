import { Box, Flex, Image, Link as ChakraLink } from '@chakra-ui/react';
import { Icon, Text, useBreakpoint, useTheme } from '@melio/penny';
import { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

type SplashBannerTopContentCardProps = {
  description: string;
  mode: 'light' | 'dark';
  imageUrl?: string;
  url: string;
  urlText: string;
  newTab?: 'true' | 'false';
  onCardView: VoidFunction;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const SplashBannerTopContentCard = ({
  description,
  mode,
  imageUrl,
  url,
  urlText,
  newTab,
  onCardView,
  onClick,
  onClose,
}: SplashBannerTopContentCardProps) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const { colors } = useTheme();
  const { isExtraSmallScreen } = useBreakpoint();

  const descriptionTextStyle = isExtraSmallScreen ? 'body4' : 'body3';
  const descriptionTextColor = mode === 'dark' ? 'neutral.lighter' : 'neutral.black';
  const iconColor = mode === 'dark' ? 'inverted' : 'default';
  const linkColor = mode === 'dark' ? colors.neutral.white : colors.link.rest;

  useEffect(() => {
    onCardView();
  }, [onCardView]);

  const iconDimension = '24px';
  const image = imageUrl && (
    <Flex direction="column" height="full" alignItems="flex-end">
      <Image
        src={imageUrl}
        height={iconDimension}
        width={iconDimension}
        minWidth={iconDimension}
        minHeight={iconDimension}
        color={iconColor}
      />
    </Flex>
  );
  const backgroundColor = mode === 'light' ? colors.brand.lightest : colors.neutral.black;

  return showBanner ? (
    <Flex
      width="100%"
      background={backgroundColor}
      minHeight={isExtraSmallScreen ? '64px' : '48px'}
      alignItems="center"
      top="s-m"
      left="s-m"
      padding="12px 16px 12px 16px"
      justifyContent="space-between"
    >
      <Flex height="full" gap="s" alignItems="center" width="100%" justifyContent="center">
        {image}
        <Text textStyle={descriptionTextStyle} color={descriptionTextColor}>
          {`${description} `}
          <ChakraLink
            as={ReactRouterLink}
            to={url}
            isExternal={newTab === 'true'}
            onClick={onClick}
            color={linkColor}
            textStyle="body3Semi"
          >
            {urlText}
          </ChakraLink>
          .
        </Text>
      </Flex>
      <Box
        width="s"
        height="s"
        onClick={() => {
          setShowBanner(false);
          onClose();
        }}
        marginRight="s"
        paddingLeft="s"
        cursor="pointer"
        display="flex"
        justifyContent="right"
        justifySelf="stretch"
        alignSelf="center"
        margin={0}
      >
        <Icon type="close-mini" size="small" color={iconColor} />
      </Box>
    </Flex>
  ) : null;
};
