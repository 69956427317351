import { Drawer, LoadingContainer, Text, useFormSubmissionController, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { LinkVendorToDirectoryParams, ModelError, UpdateVendorParams, useVendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Suspense, useEffect, useState } from 'react';

import { ErrorTypeToErrorCodesMap, getErrorsByType } from '..';
import { MissingVendorInfoExtendedUnmanagedFields } from './components/MissingVendorInfoExtendedUnmanagedForm/types';
import { MissingVendorInfoDrawerBody } from './MissingVendorInfoDrawerBody.widget';
import { MissingVendorInfoDrawerFooter } from './MissingVendorInfoDrawerFooter.widget';

const LOADING_CONTAINER_ID = 'VendorDrawerLoadingContainer';

export const MissingVendorInfoDrawer = withAnalyticsContext(
  ({ onClose, vendorId }: { onClose: VoidFunction; vendorId: string }) => {
    const { formatMessage } = useMelioIntl();
    const [isClosing, setIsClosing] = useState(false);

    const { data: vendor } = useVendor({ id: vendorId });

    const handleClose = () => {
      onClose();
    };

    const { submitButtonProps, cancelButtonProps, onSubmissionStateChange, onSubmit, apiErrorsCodes } =
      useLinkVendorToDirectory({ vendorId, onClose: handleClose });

    const { track } = useAnalytics();

    useEffect(() => {
      if (vendor) {
        track('Vendor', 'View', {
          Intent: 'edit-vendor',
          PageName: 'vendor-details',
        });
      }
    }, [track, vendor]);

    const handleCloseClick = () => {
      track('Vendor', 'Click', {
        Cta: 'exit',
      });

      setIsClosing(true);
    };

    return (
      <>
        <Drawer
          isOpen={!isClosing}
          onClose={handleCloseClick}
          onCloseComplete={handleClose}
          data-testid="missing-vendor-info-drawer"
          closeButtonAriaLabel={formatMessage('widgets.missingVendorInfoDrawer.closeButtonAriaLabel')}
          closeButtonAriaLabelledBy={LOADING_CONTAINER_ID}
          header={
            <Text as="h2" textStyle="heading2Semi">
              {formatMessage('widgets.missingVendorInfoDrawer.header.title')}
            </Text>
          }
          body={
            <Suspense fallback={<LoadingContainer isLoading id={LOADING_CONTAINER_ID} />}>
              <MissingVendorInfoDrawerBody
                vendorId={vendorId}
                onSubmit={onSubmit}
                onSubmissionStateChange={onSubmissionStateChange}
                inlineApiErrorCodes={apiErrorsCodes?.inline}
                loadingContainerId={LOADING_CONTAINER_ID}
              />
            </Suspense>
          }
          footer={
            vendor ? (
              <Suspense fallback={<LoadingContainer isLoading />}>
                <MissingVendorInfoDrawerFooter
                  submitButtonProps={submitButtonProps}
                  cancelButtonProps={cancelButtonProps}
                  onCancelClick={handleCloseClick}
                />
              </Suspense>
            ) : null
          }
        />
      </>
    );
  }
);

const useLinkVendorToDirectory = ({ vendorId, onClose }: { vendorId: string; onClose: VoidFunction }) => {
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { track } = useAnalytics();
  const { linkVendorToDirectory } = useVendor({
    id: vendorId,
    enabled: false,
    refetchOnMount: 'always',
  });
  const { onSubmissionStateChange, submitButtonProps, cancelButtonProps, reset } =
    useFormSubmissionController<MissingVendorInfoExtendedUnmanagedFields>();
  const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();

  const handleResetForm = () => {
    reset?.();
    setApiErrorsCodes(undefined);
  };

  const onSubmit = async (payload: UpdateVendorParams, throwErrors = false) => {
    setApiErrorsCodes(undefined);

    try {
      if (!vendorId) {
        return;
      }
      track('Vendor', 'Click', { Intent: 'edit-vendor', Cta: 'save' });
      const payloadWithValidatedEmail = {
        ...payload,
        contact: payload.contact ? { ...payload.contact, email: payload.contact.email || null } : {},
      };

      const updatedVendor = await linkVendorToDirectory(payloadWithValidatedEmail as LinkVendorToDirectoryParams);

      toast({
        type: 'success',
        title: formatMessage('widgets.missingVendorInfoDrawer.toast.success', {
          vendorName: updatedVendor.name,
        }),
        id: 'edit-vendor-success',
      });

      track('Vendor', 'Status', { Intent: 'edit-vendor', Status: 'success' });
      onClose();
    } catch (e) {
      const { inline, banner } = getErrorsByType(e as ModelError, false);
      setApiErrorsCodes({ inline, banner });

      if (throwErrors) {
        throw e;
      }

      track('Vendor', 'Status', { Intent: 'edit-vendor', Status: 'failure' });

      if (!inline) {
        if (banner?.[0]) {
          toast({
            type: 'error',
            title: formatMessage(`widgets.vendors.apiErrors.${banner[0]}`),
            id: 'edit-vendor-error',
          });
        } else {
          toast({
            type: 'error',
            title: formatMessage('widgets.missingVendorInfoDrawer.toast.error', {
              companyName: payload.name,
            }),
            id: 'edit-vendor-error',
          });
        }
      }
    }
  };

  return {
    onSubmissionStateChange,
    submitButtonProps,
    cancelButtonProps,
    onSubmit,
    handleResetForm,
    apiErrorsCodes,
  };
};
