import { PaymentFeeDetails } from '@melio/ap-domain';
import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const FeeItem = ({ type, amount, helperText, description }: PaymentFeeDetails) => {
  const { formatCurrency } = useMelioIntl();

  return (
    <Group justifyContent="space-between" data-testid={`fee-item-${type}`}>
      <Group variant="vertical" spacing="xxs">
        <Text data-testid={`fee-item-${type}-description`}>{description}</Text>
        {helperText && (
          <Text textStyle="body4" color="neutral.darkest" data-testid={`fee-item-${type}-helperText`}>
            {helperText}
          </Text>
        )}
      </Group>
      <Text data-testid={`fee-item-${type}-amount`}>{formatCurrency(amount)}</Text>
    </Group>
  );
};
