import { ApiError, useMelioQueryClient } from '@melio/api-client';
import {
  PayorApiClient,
  PayorVerifyOTPResponseProperties,
  PostPayorOnboardingRequest,
  PostPayorOnboardingResponse,
  PostPayorPayPaymentRequest,
  PostPayorPayPaymentResponse,
  PostSendOTPRequest,
  PostVerifyOTPRequest,
  PublicPayorApiClient,
} from '@melio/platform-api-axios-client';
import { useMutation, useQuery } from 'react-query';

import { useModel, UseModelProps } from '../../api-client';

type UseGuestPayorPaymentRequestDetailsProps = Omit<
  UseModelProps<typeof PublicPayorApiClient.getPaymentRequestDetails>,
  'id'
> & {
  paymentRequestLink: string;
};

export { OTPVerifyForbiddenErrorCode as OTPVerifyErrorCodes } from '@melio/platform-api-axios-client';
export type GuestAuthResponse = PostPayorOnboardingResponse;
export type GuestAuthRequest = PostPayorOnboardingRequest;

export const useGuestPayorPaymentRequestDetails = (props: UseGuestPayorPaymentRequestDetailsProps) =>
  useModel({
    ...props,
    id: props.paymentRequestLink,
    queryKey: 'PublicPayorApi',
    queryFn: PublicPayorApiClient.getPaymentRequestDetails,
  });

export const useGuestPayorOnboarding = () => {
  const { mutateAsync, ...rest } = useMutation<void, ApiError, PostPayorOnboardingRequest>(
    async ({ paymentRequestLink }) => {
      await PayorApiClient.postPayorOnboarding({ paymentRequestLink });
    }
  );

  return { ...rest, onboarding: mutateAsync };
};

export const useGuestPayorOtp = () => {
  const { mutateAsync: sendVerificationCodeMutateAsync } = useMutation<unknown, ApiError, PostSendOTPRequest>(
    ({ email, partnerName }) => PublicPayorApiClient.postPublicPayorOtpStart({ email, partnerName })
  );

  const { mutateAsync: verifyCodeMutateAsync, ...restVerify } = useMutation<
    PayorVerifyOTPResponseProperties,
    ApiError,
    PostVerifyOTPRequest
  >(async ({ email, partnerName, otp }) => {
    const response = await PublicPayorApiClient.postPublicPayorOtpVerify({
      email,
      partnerName,
      otp,
    });
    return response.data.data;
  });

  return {
    sendVerificationCode: sendVerificationCodeMutateAsync,
    verifyCode: verifyCodeMutateAsync,
    isLoadingVerifyCode: restVerify.isLoading,
  };
};

export const useGuestPayorPayment = () => {
  const queryClient = useMelioQueryClient();
  const { mutateAsync, ...rest } = useMutation<PostPayorPayPaymentResponse, ApiError, PostPayorPayPaymentRequest>(
    async ({ fundingSourceId, paymentRequestLink }) => {
      const response = await PayorApiClient.postPayorPayPayment({ fundingSourceId, paymentRequestLink });
      return response.data;
    },
    {
      mutationKey: 'PayorApiClient:postPayorPayPayment',
      onSuccess: () => {
        void queryClient.invalidateQueries('PublicPayorApi');
      },
    }
  );

  return { createPayment: mutateAsync, ...rest };
};

export const useUpdatedPaymentRequestLink = ({
  paymentRequestLink,
  enabled,
}: {
  paymentRequestLink: string;
  enabled: boolean;
}) =>
  useQuery(
    ['updatedPaymentRequestLink', paymentRequestLink],
    () =>
      PublicPayorApiClient.getUpdatedPaymentRequestLink(paymentRequestLink).then(
        (response) => response.data.data.updatedPaymentRequestLink
      ),
    { enabled }
  );
