import { useNavigate } from 'react-router-dom';
import { DeliveryMethod, FundingSource } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { useActiveFlowRoute } from '@/hooks/useActiveFlowRoute.hooks';

export const usePaymentMethodActivityEvents = (returnUrl?: string | null) => {
  const { navigateToActiveFlow } = useActiveFlowRoute();
  const { goBack } = useRouter();
  const navigate = useNavigate();
  const onBack = () => {
    goBack();
  };

  const onClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    navigateToActiveFlow();
  };

  const onDone = (data: DeliveryMethod | FundingSource) => {
    if (returnUrl) {
      navigate(`${returnUrl}?fundingSourceId=${data.id}`);
      return;
    }
    navigateToActiveFlow();
  };
  return {
    onDone,
    onBack,
    onClose,
  };
};
