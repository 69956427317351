import { Collaborator, RoleUniqueNames } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';
import { XOR } from '@melio/platform-utils';
import { useCallback } from 'react';

export const useAllowedActions = () => {
  const { can, roles } = usePermissions();
  const { settings } = useConfig();

  /* collaborator allowed actions */
  const canEditCollaborator = useCallback(
    (params: XOR<{ roleUniqueName: RoleUniqueNames | null }, { allowAnySubject: true }>) =>
      can({
        action: 'update',
        subject: 'collaborator',
        ...(params.allowAnySubject ? undefined : { subjectData: { roleUniqueName: params.roleUniqueName } }),
      }),
    [can]
  );

  const rolesPermittedToInvite = roles.filter((roleUniqueName) =>
    can({ subject: 'invitation', action: 'create', subjectData: { roleUniqueName } })
  );

  /* entitlements allowed actions */
  const canViewFundingSourcesEntitlements =
    can({ action: 'read', subject: 'collaborator:entitlements:fundingSource' }) && settings.isEntitlementsEnabled;

  const canUpdateFundingSourcesEntitlements =
    can({
      action: 'update',
      subject: 'collaborator:entitlements:fundingSource',
    }) && settings.isEntitlementsEnabled;

  /* payment approval limit allowed actions */
  const canViewPaymentApprovalLimit = ({
    collaborator,
    collaboratorRoleUniqueName,
  }: {
    collaborator?: Collaborator;
    collaboratorRoleUniqueName?: RoleUniqueNames | null;
  }) =>
    settings.isPaymentApprovalLimitEnabled &&
    can({
      action: 'read',
      subject: 'approvalWorkflow:approvalLimit:collaborator',
      subjectData: collaboratorRoleUniqueName
        ? { ...collaborator, roleUniqueName: collaboratorRoleUniqueName }
        : collaborator,
    });

  const canEditPaymentApprovalLimit =
    settings.isPaymentApprovalLimitEnabled && can({ action: 'update', subject: 'approvalWorkflow' });

  return {
    canEditCollaborator,
    rolesPermittedToInvite,
    canViewPaymentApprovalLimit,
    canEditPaymentApprovalLimit,
    canViewFundingSourcesEntitlements,
    canUpdateFundingSourcesEntitlements,
  };
};
