import { getIsVoidAndRefundEnabled } from '@melio/ap-domain';
import { Container, StatusIconSolid, Tooltip, Tracker } from '@melio/penny';
import { ApprovalDecision, PaymentFullyExpanded } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

import {
  isPaymentScheduledVoidToRefund,
  isPaymentScheduledVoidToResend,
} from '../../../../../utils/pay-flow/Payment.utils';
import { paymentDetailsSectionTypeFactory } from '../utils';

type TrackerStepData = {
  title: string;
  description?: string;
  invalid?: string;
};

type PaymentTimelineTracker = {
  shouldShowPaymentTimeLineTracker?: boolean;
  steps?: TrackerStepData[];
  currentStepIndex?: number;
};

type TimeLineStepDates = {
  firstStepDate?: Date;
  secondStepDate?: Date;
  thirdStepDate?: Date;
  isLateDeposit?: boolean;
};

export const getStepLabelPrefix = (payment: PaymentFullyExpanded): string => {
  const isRefund = payment?.metadata?.isRefund;
  const isVoidingToRefund = isPaymentScheduledVoidToRefund(payment);
  const isVoidingToResend = isPaymentScheduledVoidToResend(payment);

  if (isVoidingToResend) {
    return 'resend';
  }

  if (isRefund || isVoidingToRefund) {
    return 'refund';
  }

  const deliveryMethodType = payment.deliveryMethod?.type;

  if (deliveryMethodType === 'virtual-card') {
    return deliveryMethodType;
  }

  return 'default';
};

const extractStepDates = (payment: PaymentFullyExpanded): TimeLineStepDates => {
  const isRefund = payment.metadata?.isRefund;
  const isVoidCheck = payment.metadata?.isVoidCheck;

  if (isVoidCheck) {
    return {
      firstStepDate: payment.processedTimeline?.voidCheckInitiated,
      secondStepDate: payment.processedTimeline?.refundSent,
      thirdStepDate: payment.processedTimeline?.refundCompleted,
      isLateDeposit: payment.processedTimeline?.isLateDeposit,
    };
  }

  if (isRefund) {
    return {
      firstStepDate: payment.processedTimeline?.refundInitiated,
      secondStepDate: payment.processedTimeline?.refundSent,
      thirdStepDate: payment.processedTimeline?.refundCompleted,
      isLateDeposit: payment.processedTimeline?.isLateDeposit,
    };
  }

  return {
    firstStepDate: payment.processedTimeline?.collectDate,
    secondStepDate: payment.processedTimeline?.inTransitDate,
    thirdStepDate: payment.processedTimeline?.depositedDate,
    isLateDeposit: payment.processedTimeline?.isLateDeposit,
  };
};

const usePaymentTimelineTrackerProps = (
  payment: PaymentFullyExpanded
): Pick<PaymentTimelineTracker, 'steps' | 'currentStepIndex'> | undefined => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { firstStepDate, secondStepDate, thirdStepDate, isLateDeposit } = extractStepDates(payment);
  const isVoidAndRefundEnabled = getIsVoidAndRefundEnabled(payment);

  const missingFirstStep = !firstStepDate;
  const missingSecondStep = firstStepDate && !secondStepDate && thirdStepDate;

  const missingAllSteps = !firstStepDate && !secondStepDate && !thirdStepDate;

  if (missingFirstStep || missingSecondStep || missingAllSteps) {
    return;
  }

  const stepLabelPrefix = getStepLabelPrefix(payment);
  const thirdStepDescriptionPrefix = isLateDeposit ? 'late' : 'default';

  const firstStep = {
    title: formatMessage(`widgets.paymentDetails.timelineTracker.label.${stepLabelPrefix}.firstStep` as MessageKey),
    description: formatDate(firstStepDate, { dateStyle: 'medium' }),
  };

  const secondStep = {
    title: formatMessage('widgets.paymentDetails.timelineTracker.label.default.secondStep'),
    description: secondStepDate ? formatDate(secondStepDate, { dateStyle: 'medium' }) : undefined,
  };

  const thirdStep = {
    title: formatMessage(`widgets.paymentDetails.timelineTracker.label.${stepLabelPrefix}.thirdStep` as MessageKey),
    description: thirdStepDate
      ? formatMessage(`widgets.paymentDetails.timelineTracker.description.${thirdStepDescriptionPrefix}`, {
          date: thirdStepDate,
        })
      : undefined,
    invalid: isVoidAndRefundEnabled
      ? formatMessage('widgets.paymentDetails.timelineTracker.tooltip.undepositedCheck.description')
      : undefined,
  };

  const steps = [firstStep, secondStep, thirdStep];
  const currentStepIndex = thirdStepDate ? 2 : secondStepDate ? 1 : 0;
  return { steps, currentStepIndex };
};

const usePaymentTimelineTracker = (payment: PaymentFullyExpanded): PaymentTimelineTracker => {
  const [isTimelineTrackerEnabled] = useFeature(FeatureFlags.TimelineTrackerEnabled, false);

  const trackerProps = usePaymentTimelineTrackerProps(payment);

  const shouldShowPaymentTimeLineTracker = isTimelineTrackerEnabled || Boolean(trackerProps);

  return {
    shouldShowPaymentTimeLineTracker,
    steps: trackerProps?.steps,
    currentStepIndex: trackerProps?.currentStepIndex,
  };
};

type Props = { payment: PaymentFullyExpanded; approvalDecisions?: ApprovalDecision[] };
export const PaymentTimelineTrackerSection = ({ payment, approvalDecisions }: Props) => {
  const { shouldShowPaymentTimeLineTracker, steps, currentStepIndex } = usePaymentTimelineTracker(payment);

  if (!shouldShowPaymentTimeLineTracker) {
    return null;
  }

  const isActive = (index: number) => Boolean(currentStepIndex && index === currentStepIndex);

  const isCompleted = (index: number) => Boolean(currentStepIndex && index < currentStepIndex);

  switch (paymentDetailsSectionTypeFactory(payment, approvalDecisions)) {
    case 'payment-in-progress':
    case 'refund-in-progress':
    case 'payment-completed':
    case 'refund-completed':
      return (
        <Container border="regular" paddingY="m">
          <Tracker data-testid="payment-timeline-tracker">
            {steps?.map((step, index) => (
              <Tracker.Step
                key={`tracker-step-${index}`}
                data-testid={`tracker-step-${index}`}
                isActive={isActive(index)}
                isCompleted={isCompleted(index)}
              >
                <Tracker.StepTitle label={step.title} />
                {step.description && <Tracker.StepDescription label={step.description} />}
                {step.invalid && (
                  <Tooltip label={step.invalid}>
                    <StatusIconSolid variant="warning" size="small" data-testid="icon-indicator" />
                  </Tooltip>
                )}
              </Tracker.Step>
            ))}
          </Tracker>
        </Container>
      );
    default:
      return null;
  }
};
