import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { NewVendorDetailsMangedByFiservForm } from './forms/NewVendorDetailsMangedByFiservForm/NewVendorDetailsMangedByFiservForm';
import { RppsVendorDetailsForm } from './forms/RppsVendorDetailsForm/RppsVendorDetailsForm';
import { VendorDetailsFormInnerProps } from './forms/types';
import { VendorDetailsExtendedUnmanagedForm } from './forms/VendorDetailsExtendedUnmanagedForm/VendorDetailsExtendedUnmanagedForm';
import { VendorDetailsMangedByFiservForm } from './forms/VendorDetailsMangedByFiservForm/VendorDetailsManagedByFiservForm';
import { VendorDetailsUnmanagedForm } from './forms/VendorDetailsUnmanagedForm/VendorDetailsUnmanagedForm';

export type VendorDetailsFormProps = VendorDetailsFormInnerProps & {
  isRPPSVendor?: boolean;
  managedBy?: Vendor['managedBy'];
};

export const VendorDetailsForm = forwardRef<VendorDetailsFormProps, 'form'>(
  ({ isRPPSVendor, managedBy, ...props }, ref) => {
    const [isNewEditVendorExperienceEnabled] = useFeature(FeatureFlags.FiservNewEditVendorExperience, false);
    const {
      settings: {
        vendor: { collectedDetails: vendorCollectedDetails },
      },
    } = useConfig();

    if (isRPPSVendor) {
      return <RppsVendorDetailsForm ref={ref} {...props} />;
    }

    if (managedBy === 'fiserv') {
      if (isNewEditVendorExperienceEnabled) {
        return <NewVendorDetailsMangedByFiservForm ref={ref} {...props} />;
      }
      return <VendorDetailsMangedByFiservForm ref={ref} {...props} />;
    }

    if (vendorCollectedDetails === 'extended') {
      return <VendorDetailsExtendedUnmanagedForm ref={ref} {...props} />;
    }

    return <VendorDetailsUnmanagedForm ref={ref} {...props} />;
  },
);
