import { useToast } from '@melio/penny';
import {
  AccountingPlatformParseAuthParamsResponseDataCompanies,
  useAccountingPlatformVerifyQuickBooksDesktop,
  VerifyQuickBooksDesktopConnectionStatus,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

type Props = {
  organizationId?: string;
  accountingPlatformId?: string;
  onSuccess: VoidFunction;
};

export const useVerifyQuickBooksDesktop = ({ organizationId, accountingPlatformId, onSuccess }: Props) => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const {
    isMutating,
    verifyQuickBooksDesktop: verifyQuickBooksDesktopAction,
    verifyQuickBooksDesktopData,
  } = useAccountingPlatformVerifyQuickBooksDesktop({
    id: accountingPlatformId,
    enabled: false,
  });
  const isUnavailable =
    !isEmpty(verifyQuickBooksDesktopData) &&
    verifyQuickBooksDesktopData?.connectionStatus !== VerifyQuickBooksDesktopConnectionStatus.Available;
  const isAvailable =
    !isEmpty(verifyQuickBooksDesktopData) &&
    verifyQuickBooksDesktopData?.connectionStatus === VerifyQuickBooksDesktopConnectionStatus.Available;
  const registrableQuickBooksDesktopOrganization: AccountingPlatformParseAuthParamsResponseDataCompanies = {
    externalId: verifyQuickBooksDesktopData?.externalOrganizationId,
    companyName: verifyQuickBooksDesktopData?.qbdtCompanyName || undefined,
    isRegistrable: true,
  };

  useEffect(() => {
    if (isAvailable) {
      onSuccess();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  const verifyQuickBooksDesktop = async () => {
    try {
      const result = await verifyQuickBooksDesktopAction(organizationId || '');

      if (result?.connectionStatus === VerifyQuickBooksDesktopConnectionStatus.Available) {
        toast({
          type: 'success',
          title: formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.verifySuccess'
          ),
        });
      }
    } catch (e) {
      toast({
        type: 'error',
        title: formatMessage(
          'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.verifyError'
        ),
      });
      throw e;
    }
  };

  return {
    isMutating,
    isUnavailable,
    verifyQuickBooksDesktop,
    verifyQuickBooksDesktopData,
    registrableQuickBooksDesktopOrganization,
  };
};
