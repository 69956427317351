/* eslint-disable react-hooks/exhaustive-deps */
import { usePaymentActions } from '@melio/ap-domain';
import { Drawer, useDisclosure } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  Payment,
  PaymentFullyExpanded,
  PostApprovalDecisionEnum,
  usePaymentApprovalDecisions,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useNavigate } from '@melio/platform-utils';
import { compact, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MonitoredAction } from '../../monitoring';
import { PaymentApprovalWorkflowDeclineModalActivity } from '../payment-approval-workflow';
import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { useBatchPaymentApprovalDecision } from './hooks/useBatchPaymentApprovalDecision';
import { usePaymentDrawerData } from './paymentDrawer.utils';
import { PaymentDrawerContext } from './PaymentDrawerContext';
import { usePaymentDrawerAnalytics } from './usePaymentDrawerAnalytics';

export type PaymentDrawerActivityProps = {
  onClose: VoidFunction;
  onRetryFailedToDeliverPayment: (paymentId: Payment['id']) => void;
  onRetryFailedToCollectPayment: (paymentId: Payment['id']) => void;
  onRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndRefundPayment: (paymentId: Payment['id']) => void;
  onVoidAndResendPayment: (paymentId: Payment['id']) => void;
  onEditPayment: (paymentId: Payment['id']) => void;
  onMarkAsUnpaid: (payment: Payment) => void;
  onApprovalDecision: () => void;
  onEditBillSubscription?: (billSubscriptionId: Payment['id']) => void;
};

export const PaymentDrawerActivity = withAnalyticsContext(
  ({
    onClose,
    onRetryFailedToDeliverPayment,
    onRetryFailedToCollectPayment,
    onRefundPayment,
    onVoidAndRefundPayment,
    onVoidAndResendPayment,
    onEditPayment,
    onMarkAsUnpaid,
    onApprovalDecision,
    onEditBillSubscription,
  }: PaymentDrawerActivityProps) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const { paymentId } = useParams();
    const navigate = useNavigate();

    const [closed, setClosed] = useState(false);

    const billDetailsRef = useRef<HTMLDivElement>(null);

    const { data, isLoading: isPaymentLoading } = usePaymentDrawerData(paymentId);
    const { isLoading: isApprovalDecisionsLoading } = usePaymentApprovalDecisions({
      paymentId,
    });

    const payment = data as PaymentFullyExpanded;

    const isLoading = isPaymentLoading || isApprovalDecisionsLoading;

    const {
      isOpen: isDeclinePaymentModalOpen,
      onOpen: onDeclinePaymentModalOpen,
      onClose: onDeclinePaymentModalClose,
    } = useDisclosure();

    const paymentActions = usePaymentActions(payment);

    usePaymentDrawerAnalytics({ payment });
    useEffect(() => {
      if (isLoading) {
        return;
      }
      track('Payment', 'View', { Intent: 'payment-details' });
    }, [isLoading]);

    const { batchApprovalDecision } = useBatchPaymentApprovalDecision({
      onToastActionClick: () => navigate('../../payments'),
    });

    const triggerClose = () => {
      setClosed(true);
    };

    const handleApprovalDecision = async (decision: PostApprovalDecisionEnum) => {
      if (decision === PostApprovalDecisionEnum.Approved && paymentId) {
        startAction('payment_approve');
        await batchApprovalDecision([paymentId], PostApprovalDecisionEnum.Approved);
        onApprovalDecision();
        triggerClose();
        endAction('payment_approve');
      } else if (decision === PostApprovalDecisionEnum.Declined) {
        onDeclinePaymentModalOpen();
      }
    };

    const areActionsExist = !isEmpty(compact(Object.values(paymentActions.actions)));
    const shouldShowFooter = payment && areActionsExist;

    return (
      <PaymentDrawerContext.Provider
        value={{
          onRetryFailedToDeliverPayment,
          onRetryFailedToCollectPayment,
          onRefundPayment,
          onVoidAndRefundPayment,
          onVoidAndResendPayment,
          onEditPayment,
          onMarkAsUnpaid,
          onEditBillSubscription,
        }}
      >
        <Drawer
          data-testid="pay-dashboard-payments-tab-drawer"
          isOpen={!closed}
          onClose={triggerClose}
          onCloseComplete={onClose}
          closeButtonAriaLabel={formatMessage('activities.payDashboard.drawer.header.payment.closeButtonAriaLabel')}
          header={<Header />}
          body={paymentId ? <Body paymentId={paymentId} billDetailsRef={billDetailsRef} /> : null}
          footer={
            shouldShowFooter ? (
              <>
                <Footer payment={payment} onClose={triggerClose} onApprovalDecision={handleApprovalDecision} />
                {isDeclinePaymentModalOpen && paymentId && (
                  <PaymentApprovalWorkflowDeclineModalActivity
                    isOpen
                    paymentsIds={[paymentId]}
                    onClose={onDeclinePaymentModalClose}
                    onSuccess={triggerClose}
                    navigatePath="../../payments"
                  />
                )}
              </>
            ) : null
          }
        />
      </PaymentDrawerContext.Provider>
    );
  }
);
