import { addWildcardToRoutes, useMelioIntl, useNavigate, withOutlet, withRouteId } from '@melio/ar-domain';
import { CreateCustomerActivity, UpdateCustomerActivity as _UpdateCustomerActivity } from '@melio/ar-modals';
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  CustomersTabActivity,
  DeleteCustomerModalActivity as _DeleteCustomerModalActivity,
  InvoiceDashboardQueryParams,
} from '../../activities';

const UpdateCustomerActivity = withRouteId(_UpdateCustomerActivity, 'customerId');
const DeleteCustomerModalActivity = withRouteId(_DeleteCustomerModalActivity, 'customerId');

export type CustomersTableFlowProps = {
  onError?: ARErrorFunction;
  onCreateInvoice: (customerId: string) => unknown;
  onDisplayCustomerInvoices: (params: InvoiceDashboardQueryParams) => unknown;
};

export const CustomersTableFlow = forwardRef<CustomersTableFlowProps>(
  ({ onCreateInvoice, onDisplayCustomerInvoices, onError, ...props }, ref) => {
    const { Paths, goToTable, goToCreateCustomer, goToEditCustomer, goToDeleteCustomer } = useCustomersTableFlow({
      withSearchparams: true,
    });
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();

    const onCustomerCreated = (_: string, customerName: string) => {
      goToTable();
      toast({
        type: 'success',
        title: formatMessage('ar.dashboard.activities.customersTable.toasts.customerCreated.success.label', {
          customerName,
        }),
      });
    };

    const onCustomerUpdated = (customerName: string) => {
      goToTable();
      toast({
        type: 'success',
        title: formatMessage('ar.dashboard.activities.customersTable.toasts.customerUpdated.success.label', {
          customerName,
        }),
      });
    };

    const onCustomerDeleted = (customerName: string) => {
      goToTable();
      toast({
        type: 'informative',
        title: formatMessage('ar.dashboard.activities.customersTable.toasts.customerDeleted.success.label', {
          customerName,
        }),
      });
    };

    return (
      <Routes>
        <Route
          path={Paths.Home}
          element={withOutlet(
            <CustomersTabActivity
              onCreateCustomer={goToCreateCustomer}
              onEditCustomer={goToEditCustomer}
              onDeleteCustomer={goToDeleteCustomer}
              onCreateInvoice={onCreateInvoice}
              onDisplayInvoices={onDisplayCustomerInvoices}
              {...props}
              ref={ref}
            />
          )}
        >
          <Route
            path={Paths.CreateCustomer}
            element={<CreateCustomerActivity isOpen onClose={goToTable} onDone={onCustomerCreated} onError={onError} />}
          />
          <Route
            path={Paths.EditCustomer}
            element={<UpdateCustomerActivity isOpen onClose={goToTable} onDone={onCustomerUpdated} onError={onError} />}
          />
          <Route
            path={Paths.DeleteCustomer}
            element={
              <DeleteCustomerModalActivity isOpen onClose={goToTable} onDone={onCustomerDeleted} onError={onError} />
            }
          />
        </Route>
      </Routes>
    );
  }
);

enum Paths {
  Home = '',
  CreateCustomer = 'create',
  EditCustomer = ':id/edit',
  DeleteCustomer = ':id/delete',
}
export const useCustomersTableFlow = (...args: Parameters<typeof useNavigate<Paths>>) => {
  const navigate = useNavigate<Paths>(...args);
  const createNavigateWithId = (path: Paths) => (id: string) => navigate(path, { pathParams: { id } });

  const goToCreateCustomer = () => navigate(Paths.CreateCustomer);
  const goToEditCustomer = createNavigateWithId(Paths.EditCustomer);
  const goToDeleteCustomer = createNavigateWithId(Paths.DeleteCustomer);
  const goToTable = () => navigate(Paths.Home);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToCreateCustomer,
    goToEditCustomer,
    goToDeleteCustomer,
    goToTable,
  };
};
