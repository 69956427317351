import { useState } from 'react';
import { EbillsImportRedirectStateEntryPoint, useBillProviders, useRedirectURL } from '@melio/ap-activities';

import { useAppToast } from '@/hooks/useAppToast.hooks';
import { usePlatformIntl } from '@/translations/Intl';

export const useGmailConnect = (options?: {
  toastErrorMessage?: string;
  entryPoint?: EbillsImportRedirectStateEntryPoint;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const gmailSyncProvider = useBillProviders().find((provider) => provider.name === 'gmail');

  const { errorToast } = useAppToast();
  const { formatMessage } = usePlatformIntl();

  const { toastErrorMessage, entryPoint } = options || {};
  const returnURL = useRedirectURL({ provider: 'gmail', entryPoint });

  const connect = async () => {
    if (!gmailSyncProvider) {
      throw new Error('Missing gmail sync provider');
    }

    try {
      setIsLoading(true);
      await gmailSyncProvider.connect(returnURL);
    } catch (e) {
      errorToast(toastErrorMessage || formatMessage('screens.serverError.title'));
      setIsLoading(false);
    } finally {
      // Don't set isLoading to false, user still see the ui during the location.href updating, so wwe should view the loader
    }
  };

  return {
    connect,
    isLoading,
  };
};
