import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const AccountingSoftwareSyncIcon = (props: IconProps) => (
  <Icon width="22px" height="22px" viewBox="0 0 22 22" fill="currentColor" {...props}>
    <path
      d="M11 2C6.02944 2 2 6.02944 2 11C2 11.5523 1.55228 12 1 12C0.447715 12 0 11.5523 0 11C0 4.92487 4.92487 0 11 0C14.1528 0 16.9953 1.32695 19 3.45025V1C19 0.4 19.4 0 20 0C20.6 0 21 0.4 21 1V6C21 6.55228 20.5523 7 20 7H15C14.4 7 14 6.6 14 6C14 5.4 14.4 5 15 5H17.7084C16.0595 3.15751 13.6647 2 11 2Z"
      fill="#212124"
    />
    <path
      d="M11 20.0003C15.9706 20.0003 20 15.9708 20 11.0003C20 10.448 20.4477 10.0003 21 10.0003C21.5523 10.0003 22 10.448 22 11.0003C22 17.0754 17.0751 22.0003 11 22.0003C7.8472 22.0003 5.00465 20.6733 3 18.55L3 21.0003C3 21.6003 2.6 22.0003 2 22.0003C1.4 22.0003 1 21.6003 1 21.0003L1 16.0003C1 15.448 1.44772 15.0003 2 15.0003L7 15.0003C7.6 15.0003 8 15.4003 8 16.0003C8 16.6003 7.6 17.0003 7 17.0003H4.29157C5.94053 18.8428 8.33526 20.0003 11 20.0003Z"
      fill="#212124"
    />
  </Icon>
);
