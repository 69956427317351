import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const AccountingPlatformCardTheme: ComponentThemeType = (currentTheme) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      p: 'm',
      borderStyle: 'solid',
      borderColor: currentTheme.colors.neutral.light,
      borderWidth: '1px',
      justifyContent: 'center',
      borderRadius: 'l',
    },
    titleContainer: {
      marginTop: '8px',
    },
    bodyContainer: {
      marginTop: '32px',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },
    buttonsContainer: {
      marginTop: '24px',
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
    },
    footerContainer: {
      marginTop: '16px',
    },
  },
});
