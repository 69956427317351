import { Icon, Popover } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onEditClick: VoidFunction;
};

export const VendorMissingInfoIcon = ({ onEditClick }: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Popover
      title={formatMessage('widgets.vendorMissingInfoIcon.tooltip.title')}
      actionProps={{
        label: formatMessage('widgets.vendorMissingInfoIcon.tooltip.cta'),
        onClick: onEditClick,
      }}
      description={formatMessage('widgets.vendorMissingInfoIcon.tooltip.description')}
      data-testid="vendor-missing-info-icon-widget-popover"
    >
      <Icon
        size="small"
        type="info"
        aria-label="Missing details icon"
        data-testid="vendor-missing-info-icon-widget-icon"
      />
    </Popover>
  );
};
