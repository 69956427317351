import { Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

type InvoiceFormDesktopHeaderProps = {
  headerText: string;
};
export const InvoiceFormHeader = forwardRef<InvoiceFormDesktopHeaderProps>(({ headerText }) => (
  <Text textStyle="heading1Semi">{headerText}</Text>
));
InvoiceFormHeader.displayName = 'InvoiceFormHeader';
