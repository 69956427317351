import { Badge, BadgeProps, Table } from '@melio/penny';
import {
  InboxItemBillTypeEnum,
  InboxItemPaymentRequestTypeEnum,
  InboxItemScannedInvoiceTypeEnum,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { addDays, differenceInCalendarDays, isWithinInterval } from 'date-fns';
import { useCallback } from 'react';

import { BillsTabItem } from '../types';

export enum BillStatus {
  DueLater = 'dueLater',
  DueToday = 'dueToday',
  DueDateInNext7Days = 'dueNext7Days',
  Overdue = 'overdue',
}
export const BillsTabStatusCell = ({ billsTabItem }: { billsTabItem: BillsTabItem }) => {
  const getBillsTabStatusCell = useBillsTabStatusCell();
  const { status, label } = getBillsTabStatusCell({ billsTabItem });

  return (
    <Table.Cell>
      <Badge type="secondary" label={label} status={status} data-testid="bills-tab-status-cell" />
    </Table.Cell>
  );
};

export const isDateInNext7Days = (dueDate: Date, todayDate: Date) => {
  const endDate = addDays(todayDate, 7);
  return isWithinInterval(dueDate, { start: todayDate, end: endDate });
};

type UseBillsTabStatusCellProps = {
  status: Extract<NonNullable<BadgeProps['status']>, 'neutral' | 'warning' | 'attention'>;
  label: string;
};

const getBillsTabStatusCellInfo = (
  billsTabItem: BillsTabItem
): {
  dueDate: Date | undefined | null;
} => {
  switch (billsTabItem.type) {
    case InboxItemBillTypeEnum.Bill:
      return {
        dueDate: billsTabItem.payload.dueDate,
      };
    case InboxItemScannedInvoiceTypeEnum.ScannedInvoice:
      return {
        dueDate: billsTabItem.payload.dueDate,
      };
    case InboxItemPaymentRequestTypeEnum.PaymentRequest:
      return {
        dueDate: billsTabItem.payload.dueDate,
      };
  }
};

export const useBillsTabStatusCell = (): (({
  billsTabItem,
}: {
  billsTabItem: BillsTabItem;
}) => UseBillsTabStatusCellProps) => {
  const { createDate, isDatePartEqual } = useDateUtils();
  const { formatMessage } = useMelioIntl();
  return useCallback(
    ({ billsTabItem }: { billsTabItem: BillsTabItem }) => {
      const todayDate = createDate();
      const { dueDate } = getBillsTabStatusCellInfo(billsTabItem);

      if (!dueDate) {
        return {
          status: 'neutral',
          label: formatMessage('activities.payDashboard.billsTab.table.cells.status.dueLater'),
        };
      }

      const isTodayEqualDueDate = isDatePartEqual(dueDate, todayDate);
      const status = getBillsTabStatus({ dueDate, todayDate, isTodayEqualDueDate });

      switch (status) {
        case BillStatus.DueToday:
          return {
            status: 'neutral',
            label: formatMessage('activities.payDashboard.billsTab.table.cells.status.dueToday'),
          };
        case BillStatus.DueDateInNext7Days: {
          const differenceDays = differenceInCalendarDays(dueDate, todayDate);
          if (differenceDays === 1) {
            return {
              status: 'neutral',
              label: formatMessage('activities.payDashboard.billsTab.table.cells.status.dueInOneDay'),
            };
          }
          return {
            status: 'neutral',
            label: formatMessage('activities.payDashboard.billsTab.table.cells.status.dueInDays', {
              days: differenceDays,
            }),
          };
        }
        case BillStatus.Overdue:
          return {
            status: 'warning',
            label: formatMessage('activities.payDashboard.billsTab.table.cells.status.overdue'),
          };
        default:
          return {
            status: 'neutral',
            label: formatMessage('activities.payDashboard.billsTab.table.cells.status.dueLater'),
          };
      }
    },
    [createDate, formatMessage, isDatePartEqual]
  );
};

export const getBillsTabStatus = ({
  dueDate,
  todayDate,
  isTodayEqualDueDate,
}: {
  dueDate: Date;
  todayDate: Date;
  isTodayEqualDueDate: boolean;
}): BillStatus => {
  if (!dueDate) {
    return BillStatus.DueLater;
  }

  if (isTodayEqualDueDate) {
    return BillStatus.DueToday;
  }

  if (isDateInNext7Days(dueDate, todayDate)) {
    return BillStatus.DueDateInNext7Days;
  }
  if (dueDate < todayDate) {
    return BillStatus.Overdue;
  }

  return BillStatus.DueLater;
};
