import { Container, Group, Illustration, Loader, Text, useBreakpoint } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import {
  Todo,
  TodoActivateCollaborators,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPayment,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItems,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApprove,
  TodoPaymentsToApproveTypeEnum,
  todosOrder,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { TodoSectionActivateCollaborators } from './section-types/TodoSectionActivateCollaborators';
import { TodoSectionFailedPayments } from './section-types/TodoSectionFailedPayments';
import { TodoSectionOverdueInboxItems } from './section-types/TodoSectionOverdueInboxItems';
import { TodoSectionOverdueInFutureInboxItems } from './section-types/TodoSectionOverdueInFutureInboxItems';
import { TodoSectionPaymentsToApprove } from './section-types/TodoSectionPaymentsToApprove';
import { isEmptySection } from './utils';

type TodosDrawerBodyProps = {
  todos: Todo[] | undefined;
  isLoading: boolean;
  totalCount: number;
};

export const TodosDrawerBody = withAnalyticsContext(({ todos, isLoading, totalCount }: TodosDrawerBodyProps) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const renderFailedPaymentSection = (todos: Todo[]) => {
    const failedPaymentTodo = todos.find(
      (t): t is TodoFailedPayment => t.type === TodoFailedPaymentTypeEnum.FailedPayments
    );
    if (isEmptySection(failedPaymentTodo)) {
      return null;
    }
    return <TodoSectionFailedPayments todo={failedPaymentTodo} />;
  };

  const renderPaymentsToApproveSection = (todos: Todo[]) => {
    const paymentsToApprove = todos.find(
      (t): t is TodoPaymentsToApprove => t.type === TodoPaymentsToApproveTypeEnum.PaymentsToApprove
    );
    if (isEmptySection(paymentsToApprove)) {
      return null;
    }
    return <TodoSectionPaymentsToApprove todo={paymentsToApprove} />;
  };

  const renderOverdueInboxItemsSection = (todos: Todo[]) => {
    const overdueInboxItems = todos.find(
      (t): t is TodoOverdueInboxItems => t.type === TodoOverdueInboxItemsTypeEnum.OverdueInboxItems
    );
    if (isEmptySection(overdueInboxItems)) {
      return null;
    }
    return <TodoSectionOverdueInboxItems todo={overdueInboxItems} />;
  };

  const renderOverdueInFutureInboxItemsSection = (todos: Todo[]) => {
    const overdueIn7Days = todos.find(
      (t): t is TodoOverdueIn7DaysInboxItems => t.type === TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems
    );
    const overdueInMoreThan7Days = todos.find(
      (t): t is TodoOverdueInMoreThan7DaysInboxItems =>
        t.type === TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems
    );
    if (isEmptySection(overdueIn7Days) && isEmptySection(overdueInMoreThan7Days)) {
      return null;
    }
    return (
      <TodoSectionOverdueInFutureInboxItems
        overdueIn7Days={overdueIn7Days}
        overdueInMoreThan7Days={overdueInMoreThan7Days}
      />
    );
  };

  const renderActivateCollaboratorsSection = (todos: Todo[]) => {
    const activateCollaborator = todos.find(
      (t): t is TodoActivateCollaborators => t.type === TodoActivateCollaboratorsTypeEnum.ActivateCollaborators
    );
    if (isEmptySection(activateCollaborator)) {
      return null;
    }
    return <TodoSectionActivateCollaborators todo={activateCollaborator} />;
  };

  const renderTodosSection = () => {
    if (!todos) {
      return null;
    }
    let isOverdueInFutureInboxItemsHandled = false;
    return todosOrder
      .map((type) => {
        switch (type) {
          case TodoFailedPaymentTypeEnum.FailedPayments:
            return renderFailedPaymentSection(todos);
          case TodoPaymentsToApproveTypeEnum.PaymentsToApprove:
            return renderPaymentsToApproveSection(todos);
          case TodoOverdueInboxItemsTypeEnum.OverdueInboxItems:
            return renderOverdueInboxItemsSection(todos);
          case TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems:
          case TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems: {
            if (isOverdueInFutureInboxItemsHandled) {
              return null;
            }
            isOverdueInFutureInboxItemsHandled = true;
            return renderOverdueInFutureInboxItemsSection(todos);
          }
          case TodoActivateCollaboratorsTypeEnum.ActivateCollaborators:
            return renderActivateCollaboratorsSection(todos);
          default:
            return null;
        }
      })
      .filter((section) => !!section);
  };

  const renderEmptyState = () => (
    <Container paddingY={isExtraSmallScreen ? 's' : 'none'} data-testid="todos-drawer-empty-state">
      <Group
        height="full"
        width="full"
        alignItems="center"
        justifyContent="center"
        variant="vertical"
        textAlign="center"
      >
        <Illustration size="medium" type="no-items" />
        <Text textStyle="heading2Semi">{formatMessage('widgets.todosDrawer.section.emptyState.title')}</Text>
        <Text textStyle="body4" color="neutral.darkest">
          {formatMessage('widgets.todosDrawer.section.emptyState.description')}
        </Text>
      </Group>
    </Container>
  );

  if (isLoading) {
    return (
      <Container justifyContent="center" alignItems="center">
        <Loader />
      </Container>
    );
  }

  if (totalCount === 0) {
    return renderEmptyState();
  }

  return (
    <Container paddingY={isExtraSmallScreen ? 's' : 'none'}>
      <Group variant="vertical" hasDivider spacing="s">
        {renderTodosSection()}
      </Group>
    </Container>
  );
});
