import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { Expand, TodosApiClient } from '@melio/platform-api-axios-client';

import { convertTodosCentsToDollars, getTotalCount, todosOrder } from './utils';

export type UseTodosProps = UseCollectionApiProps<typeof TodosApiClient.getTodos> & {
  params?: {
    expand?: Expand;
  };
};

const getTodos = (params: UseTodosProps['params'] = {}): ReturnType<typeof TodosApiClient.getTodos> =>
  TodosApiClient.getTodos(
    params.expand || ['payment.vendor', 'inboxItem.bill.vendor', 'inboxItem.paymentRequest.vendor']
  );

export const useTodos = ({ params, ...props }: UseTodosProps = {}) => {
  const query = useCollectionApi({
    ...props,
    queryKey: ['TodosApi', params],
    queryFn: () => getTodos(params),
  });

  const data = query.data?.filter((todo) => todosOrder.includes(todo.type));

  return {
    ...query,
    totalCount: getTotalCount(data),
    data: convertTodosCentsToDollars(data),
  };
};
