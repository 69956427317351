import {
  ActionsDropdownMenu,
  ActionsDropdownMenuItemProps,
  Badge,
  BrandSymbol,
  Group,
  GroupProps,
  Link,
  Text,
  useBreakpointValue,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { differenceInCalendarDays } from 'date-fns';
import { useState } from 'react';

import { SubscriptionBillingCycleEnum } from '../../../api';
import { usePendingSubscription, usePlanInfo, useRedirects, useSubscription } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';
import { SectionSettingCard } from '../SectionSettingCard';
import { CurrentPlanCell } from './CurrentPlanCell';
import { PendingSubscriptionPlanChange } from './PendingSubscriptionPlanChange';

type EditablePlanCardProps = {
  onOpenCancelModal: () => void;
  onOpenSwitchBillingCycleModal: () => void;
};

export const EditablePlanCard = ({ onOpenCancelModal, onOpenSwitchBillingCycleModal }: EditablePlanCardProps) => {
  const [accountManagerAccessCollaboratorsEnabled] = useFeature<boolean>(
    FeatureFlags.AccountManagerAccessCollaborators,
    false
  );
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { goToSettingsCollaboratorsPage } = useRedirects();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const subscription = useSubscription();
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const groupVariant: GroupProps['variant'] = useBreakpointValue({ xs: 'vertical', s: 'horizontal' });
  const { createDate } = useDateUtils();

  const { planName } = usePlanInfo(subscription?.planId);
  const { isReactivationAllowed } = usePendingSubscription();

  const { track } = useAnalytics();
  const onManagePlan = () => {
    track('Settings', 'Click', {
      Intent: 'manage-subscription',
      Cta: 'manage-subscription',
    });
    goToSubscriptionPlans({ returnUrl: '/settings/subscription-plans' });
  };

  const actions: ActionsDropdownMenuItemProps[] = [
    {
      label: formatMessage('activities.settings.editablePlanCard.action.managePlan'),
      dataTestId: 'subscription-plans-manage',
      onClick: onManagePlan,
    },
  ];

  if (!isReactivationAllowed) {
    actions.push(
      {
        label:
          subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
            ? formatMessage('activities.settings.editablePlanCard.action.changeBillingCycle.switchToAnnual')
            : formatMessage('activities.settings.editablePlanCard.action.changeBillingCycle.switchToMonthly'),
        dataTestId: 'subscription-plans-switch-billing-cycle',
        onClick: () => {
          const cycle = subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly ? 'yearly' : 'monthly';
          const intent = `switch-to-${cycle}`;
          track('Settings', 'Click', {
            Intent: intent,
            Cta: intent,
          });
          onOpenSwitchBillingCycleModal();
        },
      },
      {
        label: formatMessage('activities.settings.editablePlanCard.action.removePlan'),
        dataTestId: 'subscription-plans-remove',
        variant: 'critical',
        onClick: () => {
          const intent = 'cancel-my-plan';
          track('Settings', 'Click', {
            Intent: intent,
            Cta: intent,
          });
          onOpenCancelModal();
        },
      }
    );
  }

  const toggleMenu = (open: boolean) => {
    if (open) {
      const intent = 'subscription-menu';
      track('Settings', 'Click', {
        Intent: intent,
        Cta: intent,
      });
    }
    setIsMenuOpen(open);
  };

  const onManageSeats = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    track('Settings', 'Click', {
      Intent: 'manage-seats',
      Cta: 'manage',
      NumberOfSeats: subscription?.measurementInfo?.quantity,
    });

    goToSettingsCollaboratorsPage();
  };

  const remainingDaysForNextBilling = subscription
    ? differenceInCalendarDays(createDate(subscription.nextBillingDate), createDate())
    : undefined;

  return (
    <SectionSettingCard data-testid="subscription-current-plan">
      <Group width="full" variant="vertical" spacing="m" alignItems="flex-start">
        <Group width="full" alignItems="center" justifyContent="space-between">
          <Group spacing="s" alignItems="center">
            <BrandSymbol type="melio" size="extra-large" />
            <Group variant="vertical" spacing="xxxs">
              <Group alignItems="center" spacing="xs">
                <Text textStyle="body2Semi" as="h4">
                  {planName}
                </Text>
                {subscription?.isFreeTrial && (
                  <>
                    <Badge
                      status="success"
                      label={formatMessage('activities.settings.plans.editablePlanCard.freeTrial.freeTrialBadge')}
                      type="secondary"
                    />
                    <Badge
                      status="neutral"
                      label={formatMessage('activities.settings.plans.editablePlanCard.freeTrial.remainingDaysBadge', {
                        remainingDays: remainingDaysForNextBilling,
                      })}
                      type="secondary"
                    />
                  </>
                )}
              </Group>
              <PendingSubscriptionPlanChange />
            </Group>
          </Group>

          <ActionsDropdownMenu
            isOpen={isMenuOpen}
            onOpenChange={toggleMenu}
            size="small"
            label="menu action"
            data-testid="subscription-current-plan-actions-menu"
            items={actions}
          />
        </Group>

        <Group hasDivider spacing="m" width="full" variant={groupVariant}>
          {subscription && (
            <CurrentPlanCell label={formatMessage('activities.settings.editablePlanCard.title.paymentFrequency')}>
              <Text textStyle="body2" data-testid="plans-settings-page-payment-frequency">
                {formatMessage(`activities.settings.editablePlanCard.paymentFrequency.${subscription.planCyclePeriod}`)}
              </Text>
              <Text textStyle="body4" color="neutral.darker">
                {formatMessage('activities.settings.editablePlanCard.action.plan.nextBilling', {
                  nextBillingDate: formatDate(subscription.nextBillingDate, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  }),
                })}
              </Text>
            </CurrentPlanCell>
          )}

          <CurrentPlanCell label={formatMessage('activities.settings.editablePlanCard.title.seats')}>
            <Text textStyle="body2" data-testid="subscription-measurement-info-quantity">
              {subscription?.measurementInfo?.quantity}
            </Text>
            {accountManagerAccessCollaboratorsEnabled && (
              <Text textStyle="body4">
                <Link
                  href="#"
                  label={formatMessage('activities.settings.editablePlanCard.seats.manage')}
                  onClick={onManageSeats}
                  color="secondary"
                />
              </Text>
            )}
          </CurrentPlanCell>

          {subscription?.nextBillingAmount && subscription?.nextBillingDate && (
            <CurrentPlanCell label={formatMessage('activities.settings.editablePlanCard.title.estimatedTotal')}>
              <Text textStyle="body2" data-testid="plans-settings-page-next-billing-amount">
                {formatCurrency(subscription.nextBillingAmount)}
              </Text>
            </CurrentPlanCell>
          )}
        </Group>
      </Group>
    </SectionSettingCard>
  );
};
