import { Organization } from '@melio/platform-api';

import { SelectCompanyScreen } from './screens/SelectCompany.screen';

type PaymentRequestSelectCompanyActivityProps = {
  organizations: Organization[];
  vendorName: string;
  selectOrganization: (orgId: string) => void;
};

export const PaymentRequestSelectCompanyActivity: React.FC<PaymentRequestSelectCompanyActivityProps> = ({
  organizations,
  vendorName,
  selectOrganization,
}) => (
  <SelectCompanyScreen organizations={organizations} vendorName={vendorName} selectOrganization={selectOrganization} />
);

PaymentRequestSelectCompanyActivity.displayName = 'PaymentRequestSelectCompanyActivity';
