import { StatusModal } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export type AutoPaymentCancellationModalScreenProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onCancel: VoidFunction;
  vendorName: string;
  isLoading: boolean;
};

export const AutoPaymentCancellationModalScreen = ({
  isOpen,
  onClose,
  onCancel,
  vendorName,
  isLoading,
}: AutoPaymentCancellationModalScreenProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <StatusModal
      isOpen={isOpen}
      onClose={onClose}
      variant="cancel"
      header={formatMessage('activities.payDashboard.autoPaymentCancellationModal.confirm.title')}
      primaryButton={{
        label: formatMessage('activities.payDashboard.autoPaymentCancellationModal.confirm.confirm'),
        variant: 'critical',
        onClick: onCancel,
        isLoading,
      }}
      description={formatMessage('activities.payDashboard.autoPaymentCancellationModal.confirm.body', { vendorName })}
      data-testid="auto-pay-cancellation-modal-screen"
      role="alertdialog"
    />
  );
};
