import { Notification, NotificationsNames, useMelioIntl } from '@melio/ar-domain';
import { Group, Link, LoadingContainer, Switch, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type InvoiceEmailNotificationsScreenProps = {
  notifications?: Notification[];
  onChangeEnabled: (notificationsName: NotificationsNames, enabled: boolean) => void;
  onClickCustomMessage: (notificationsName: NotificationsNames) => void;
  isLoading?: boolean;
  isUpdating?: boolean;
  updatingId?: NotificationsNames;
};

export const InvoiceEmailNotificationsScreen = forwardRef<InvoiceEmailNotificationsScreenProps>(
  ({ notifications, onChangeEnabled, isLoading, isUpdating, updatingId, onClickCustomMessage, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const notificationLabels: Record<NotificationsNames, string> = {
      invoiceDueDateApproachingReminder: formatMessage(
        'ar.settings.activities.invoiceEmailPreferences.options.dateApproaching.label'
      ),
      invoiceDueDateTodayReminder: formatMessage(
        'ar.settings.activities.invoiceEmailPreferences.options.dateDueToday.label'
      ),
      invoiceOverdueReminder: formatMessage('ar.settings.activities.invoiceEmailPreferences.options.dateOverdue.label'),
    };

    return (
      <LoadingContainer
        isLoading={isLoading}
        data-component={InvoiceEmailNotificationsScreen.displayName}
        data-testid="invoice-email-notifications-settings-container"
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="s" hasDivider>
          {notifications?.map(({ notificationType, enabled }) => (
            <Group
              key={notificationType}
              variant="horizontal"
              spacing="m"
              width="full"
              justifyContent="space-between"
              alignItems="center"
              data-testid={`invoice-email-notifications-row-${notificationType}`}
            >
              <Text as="div" textStyle="body3">
                {notificationLabels[notificationType]}
                &nbsp;
                <Link
                  href="javascript:void(0)"
                  onClick={() => onClickCustomMessage(notificationType)}
                  label={formatMessage('ar.settings.activities.invoiceEmailPreferences.customizeButton.label')}
                  isDisabled={isUpdating}
                  data-testid="customize-button"
                  variant="standalone"
                  color="secondary"
                  size="medium"
                  isBold
                />
              </Text>
              <Switch
                data-testid={`invoice-email-notifications-row-switch-${notificationType}`}
                value={updatingId == notificationType ? !enabled : !!enabled}
                onChange={(enabled) => onChangeEnabled(notificationType, enabled)}
                isDisabled={isUpdating}
              />
            </Group>
          ))}
        </Group>
      </LoadingContainer>
    );
  }
);
InvoiceEmailNotificationsScreen.displayName = 'InvoiceEmailNotificationsScreen';
