import { DashboardCustomer, useMelioIntl } from '@melio/ar-domain';
import { NakedButton, Table, Text } from '@melio/penny';

export type InvoicesCellProps = {
  customer: DashboardCustomer;
  onClick?: VoidFunction;
};

export const InvoicesCell = ({ customer: { invoicesOverview }, onClick }: InvoicesCellProps) => {
  const { formatMessage } = useMelioIntl();

  const invoicesCount = invoicesOverview.paid.count + invoicesOverview.open.count;

  const handleClick = (e: React.MouseEvent<HTMLLinkElement>) => {
    e.preventDefault();
    e.stopPropagation();
    return onClick?.();
  };

  return (
    <Table.Cell data-testid="customer-invoices">
      {invoicesCount === 0 ? (
        <Text textStyle="body3" color="neutral.darker" data-testid="customer-invoices-text">
          {formatMessage('ar.dashboard.activities.customersTable.cells.invoices.noInvoices.text')}
        </Text>
      ) : (
        <NakedButton
          variant="secondary"
          label={
            invoicesCount === 1
              ? formatMessage('ar.dashboard.activities.customersTable.cells.invoices.invoicesCount.single.text')
              : formatMessage('ar.dashboard.activities.customersTable.cells.invoices.invoicesCount.plural.text', {
                  invoicesCount,
                })
          }
          data-testid="customer-invoices-link"
          onClick={handleClick}
        />
      )}
    </Table.Cell>
  );
};

InvoicesCell.displayName = 'InvoicesCell';
