import { Table } from '@melio/penny';
import { SelectableDropdownMenuItem } from '@melio/penny/dist/types/containers';
import { DeliveryMethod, DeliveryMethodByPayor, PaymentIntent, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { BankAccountFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/BankAccountFormModal/BankAccountFormModal';
import { useBankAccountDetailsFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/BankAccountFormModal/useBankAccountDetailsFormModal';
import { PaperCheckFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/PaperCheckFormModal/PaperCheckFormModal';
import { usePaperCheckFormModal } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/modals/PaperCheckFormModal/usePaperCheckFormModal';
import { useGetDeliveryMethodWarning } from '../../PaymentIntentsTable.screen.utils';

type DeliveryMethodSelectCellProps = {
  paymentIntent: PaymentIntent;
  vendor?: Vendor;
  selectedId?: DeliveryMethod['id'];
  deliveryMethods: DeliveryMethodByPayor[];
  onSelect: (selectedId: DeliveryMethod['id']) => void;
};

const getDeliveryMethodOptions = (
  formatMessage: ReturnType<typeof useMelioIntl>['formatMessage'],
  deliveryMethods: DeliveryMethodByPayor[]
): SelectableDropdownMenuItem[] => {
  const findIdByDeliveryMethodType = (type: DeliveryMethod['type']) => {
    const deliveryMethod = deliveryMethods.find((dm) => dm.type === type);
    return deliveryMethod?.id || type;
  };

  return [
    {
      label: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.title'),
      value: findIdByDeliveryMethodType('bank-account'),
      icon: 'bank-out',
    },
    {
      label: formatMessage(
        'activities.addDeliveryMethodByPayeeWithoutPayment.screens.selectDeliveryMethodType.check.title'
      ),
      value: findIdByDeliveryMethodType('paper-check'),
      icon: 'paper-check',
    },
  ];
};

export const DeliveryMethodFixedListSelectCell = forwardRef<DeliveryMethodSelectCellProps, 'div'>(
  ({ paymentIntent, deliveryMethods, selectedId, onSelect, vendor, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const bankAccountModal = useBankAccountDetailsFormModal({
      vendor,
      onDone: (deliveryMethod) => onSelect(deliveryMethod.id),
    });

    const paperCheckModal = usePaperCheckFormModal({
      vendor,
      onDone: (deliveryMethod) => onSelect(deliveryMethod.id),
    });

    const { warningInfo } = useGetDeliveryMethodWarning({
      paymentIntent,
      selectedDeliveryMethodId: selectedId,
    });

    const options = getDeliveryMethodOptions(formatMessage, deliveryMethods);

    const handleSelect = (selectedId: DeliveryMethod['id']) => {
      const selectedDeliveryMethod = vendor?.deliveryMethods.find((dm) => dm.id === selectedId);

      if (!selectedDeliveryMethod) {
        if (selectedId === 'bank-account') {
          bankAccountModal.open();
        } else if (selectedId === 'paper-check') {
          paperCheckModal.open();
        }
      } else {
        const requireConfirmation = selectedDeliveryMethod.requireConfirmationForPayment;
        if (selectedDeliveryMethod.type === 'bank-account' && requireConfirmation) {
          bankAccountModal.open();
        } else if (selectedDeliveryMethod.type === 'paper-check' && requireConfirmation) {
          paperCheckModal.open();
        }
      }
    };

    return (
      <>
        <Table.SelectCell
          data-testid="delivery-method-fiserv-select-cell"
          {...props}
          placeholder={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.placeholder'
          )}
          ref={ref}
          options={options}
          value={selectedId}
          onSelect={handleSelect}
          isInvalid={warningInfo.shouldShowWarning}
          tooltipProps={warningInfo.tooltipProps}
          popoverProps={warningInfo.popoverProps}
        />{' '}
        <BankAccountFormModal onClose={bankAccountModal.close} {...bankAccountModal} />
        <PaperCheckFormModal onClose={paperCheckModal.close} {...paperCheckModal} />
      </>
    );
  }
);

DeliveryMethodFixedListSelectCell.displayName = 'DeliveryMethodFixedListSelectCell';
