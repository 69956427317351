import React from 'react';

import { ErrorPage } from '@/cl/components/ErrorPage/ErrorPage.component';
import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

export const ExpiredSessionScreen = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { goToStart } = useRouter();

  return (
    <FullScreenContainer centered>
      <ErrorPage
        icon={partnerConfig.icons.ExpiredSessionIcon}
        title={formatMessage('screens.expiredSession.title')}
        message={formatMessage('screens.expiredSession.message', { partner: partnerConfig.displayName })}
        primaryButton={{
          text: formatMessage('screens.expiredSession.button', { partner: partnerConfig.displayName }),
          onClick: goToStart,
        }}
      />
    </FullScreenContainer>
  );
};
