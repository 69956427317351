import { Box } from '@chakra-ui/react';
import { Skeleton, Text } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionPreview } from '../../../../../../api';

type SubscriptionCheckoutBillingTaxProps = {
  preview?: SubscriptionPreview;
  isLoading: boolean;
};

export const SubscriptionCheckoutBillingTax = ({ preview, isLoading }: SubscriptionCheckoutBillingTaxProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { data } = useAccount({ id: 'me' });
  const { legalAddress } = data?.company || {};

  if (isLoading) {
    return (
      <Box width="100px">
        <Skeleton height="12px" />
      </Box>
    );
  }

  if (!legalAddress) {
    return (
      <Text textStyle="body2" color="neutral.darker" data-testid="subscription-checkout-no-taxes">
        {formatMessage('activities.subscription.checkout.summary.noTax')}
      </Text>
    );
  }

  return (
    <Text textStyle="body2" color="neutral.darker" data-testid="subscription-checkout-taxes">
      {formatCurrency(preview?.taxAmount || 0)}
    </Text>
  );
};
