import { isBusinessTypeAllowedForFX } from '@melio/ap-domain';
import { useMelioForm, useToast } from '@melio/penny';
import { Account, AccountAddressParams, PatchAccountsAccountIdRequestCompany, useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';

import { MonitoredAction } from '../../../../../../monitoring';
import { getDirtyValuesFromForm } from '../formUtils';
import { BusinessDetailsForm } from '../types';
import { useBusinessDetailsFormSchema } from './useBusinessDetailsFormSchema';

export const useFxBusinessDetailsForm = ({
  onDone,
  onUnsupportedBusinessType,
}: {
  onDone: (data: Account) => void;
  onUnsupportedBusinessType: VoidFunction;
}) => {
  const { formatMessage } = useMelioIntl();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { toast } = useToast();

  const {
    data: account,
    update: updateAccount,
    isMutating,
    isLoading,
  } = useAccount({
    id: 'me',
    onUpdate: (res) => {
      endAction('fx_business_details_submit');
      onDone(res);
    },
    onUpdateError: () => {
      toast({
        // @ts-expect-error Missing type on DS side
        'data-testid': 'fx-update-business-failed-toast',
        type: 'error',
        title: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.errors.general-error'
        ),
      });
    },
  });
  const defaultTaxId = account?.company?.taxInfo?.identifier ?? '';
  const businessDetailsFormSchema = useBusinessDetailsFormSchema({ defaultTaxId });
  const form = useMelioForm<BusinessDetailsForm>({
    onSubmit: () => {
      startAction('fx_business_details_submit');

      const dirtyBusinessDetails = getDirtyValuesFromForm({
        form,
      });

      if (!!dirtyBusinessDetails.businessType && !isBusinessTypeAllowedForFX(dirtyBusinessDetails.businessType)) {
        onUnsupportedBusinessType();
        return;
      }

      const taxInfo = {
        identifier: dirtyBusinessDetails.businessTaxId,
        type: dirtyBusinessDetails.taxIdType,
      };
      const updateCompanyRequest: PatchAccountsAccountIdRequestCompany = {
        name: dirtyBusinessDetails.companyName,
        legalName: dirtyBusinessDetails.companyLegalName,
        businessType: dirtyBusinessDetails.businessType,
        industry: dirtyBusinessDetails.businessIndustry,
        taxInfo,
        legalAddress: dirtyBusinessDetails.legalBusinessAddress
          ? (dirtyBusinessDetails.legalBusinessAddress as AccountAddressParams)
          : undefined,
        phoneNumber: dirtyBusinessDetails.phone,
      };

      updateAccount({
        company: updateCompanyRequest,
      });
    },
    defaultValues: {
      businessType: account?.company?.businessType ?? undefined,
      businessIndustry: account?.company?.industry ?? undefined,
      companyName: account?.company.name ?? '',
      companyLegalName: account?.company.legalName ?? '',
      legalBusinessAddress: account?.company.legalAddress,
      phone: account?.company.phoneNumber ?? '',
      businessTaxId: account?.company.taxInfo?.identifier ?? '',
      taxIdType: account?.company.taxInfo?.type ?? undefined,
    },
    subscribeToDefaultValuesChanges: true,
    schema: businessDetailsFormSchema,
  });

  return {
    form,
    isLoading,
    isMutating,
  };
};
