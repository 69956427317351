import { Box } from '@chakra-ui/react';
import { ReconciliationForm, ReconciliationFormFields, useAccountingPlatformName } from '@melio/ap-widgets';
import { LoadingContainer, Modal, ModalProps, SectionBanner, useFormSubmissionController } from '@melio/penny';
import { AccountingPlatform, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback, useState } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { FundingSourceFlatContainerCard } from '../FundingSourceFlatContainerCard/FundingSourceFlatContainerCard.widget';

export type ReconciliationModalScreenProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  onLinkFundingSource?: (accountingPlatformPaymentAccountId: string) => Promise<void>;
  onCreateNewPaymentAccount?: (newPaymentAccountName: string) => Promise<void>;
  fundingSource?: FundingSource;
  activeAccountingPlatform?: AccountingPlatform;
  isLoading?: boolean;
  isSaving?: boolean;
};

export const ReconciliationModalScreen: React.VFC<ReconciliationModalScreenProps> = (props) => {
  const {
    fundingSource,
    activeAccountingPlatform,
    isLoading,
    isSaving,
    onLinkFundingSource,
    onCreateNewPaymentAccount,
    ...rest
  } = props;

  const { formatMessage } = useMelioIntl();
  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const [shouldCreateNewPaymentAccount, setCreateNewPaymentAccount] = useState(false);
  const newPaymentAccountName = fundingSource?.displayName || '';
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
  }, [endAction]);

  const onSubmit = ({ accountingPlatformPaymentAccountId }: { accountingPlatformPaymentAccountId: string }) => {
    startAction('reconciliation_flow');

    if (shouldCreateNewPaymentAccount) {
      onCreateNewPaymentAccount?.(newPaymentAccountName);
    } else {
      onLinkFundingSource?.(accountingPlatformPaymentAccountId);
    }
  };

  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<ReconciliationFormFields>();

  const accountTypeText =
    fundingSource?.type === 'bank-account'
      ? 'bank account'
      : fundingSource?.type === 'card'
      ? `${fundingSource?.details?.type} card`
      : 'account';

  return (
    <Modal
      ref={endActionMonitoring}
      {...rest}
      data-testid="funding-source-selection-activity-reconciliation-selection-modal-screen"
      header={formatMessage('activities.reconciliationModalScreen.header', {
        accountingPlatform: accountingPlatformName,
        accountType: accountTypeText,
      })}
      description={formatMessage('activities.reconciliationModalScreen.description', {
        accountingPlatform: accountingPlatformName,
      })}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label:
          fundingSource?.type === 'bank-account'
            ? formatMessage('activities.reconciliationModalScreen.continue.bankAccount')
            : formatMessage('activities.reconciliationModalScreen.continue.card'),
      }}
    >
      {shouldCreateNewPaymentAccount && (
        <SectionBanner
          variant="informative"
          description={formatMessage('activities.reconciliationModalScreen.infoBanner', {
            fundingSourceDisplayName: newPaymentAccountName,
            accountingPlatform: accountingPlatformName,
          })}
        />
      )}
      <LoadingContainer isLoading={isLoading}>
        <Box py="s">{fundingSource && <FundingSourceFlatContainerCard fundingSource={fundingSource} />}</Box>
        <ReconciliationForm
          newPaymentAccountName={newPaymentAccountName}
          onCreateOption={() => setCreateNewPaymentAccount(true)}
          onSubmit={onSubmit}
          isSaving={isSaving}
          onSubmissionStateChange={onSubmissionStateChange}
          defaultValues={{ accountingPlatformPaymentAccountId: fundingSource?.accountingPlatformPaymentAccountId }}
        />
      </LoadingContainer>
    </Modal>
  );
};

ReconciliationModalScreen.displayName = 'FundingSourceSelectionActivity.ReconciliationModalScreen';
