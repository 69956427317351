import { Bill } from '@melio/platform-api';
import { FormatMessage } from '@melio/platform-i18n';
import { sortBy } from 'lodash';

import { getDate, getStatus } from './PaymentAmountLine/utils';

export const sortPaymentsByDate = (payments: Bill['payments']) =>
  sortBy(payments, (payment) => {
    const status = getStatus(payment);
    return getDate(payment, status);
  });

export const getBillOriginLabel = (
  bill: Partial<Bill>,
  accountingPlatformName: string,
  formatMessage: FormatMessage
) => {
  switch (bill.origin) {
    case 'local':
      return formatMessage('widgets.billDetails.form.origin.description.manual');
    case 'fiserv':
      return formatMessage('widgets.billDetails.form.origin.description.eBill');
    case 'intuit':
      return accountingPlatformName;
    default:
      return '';
  }
};
