import { useInvalidFundingSources } from '@melio/ap-domain';
import { BillSubscriptionOptions, FsTypeOption, useFundingSources } from '@melio/platform-api';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

export const useFilteredFundingSources = ({
  fundingSourceTypesOptions,
  billSubscriptionOptions,
  isRecurring,
}: {
  fundingSourceTypesOptions: FsTypeOption[] | undefined;
  billSubscriptionOptions: BillSubscriptionOptions | undefined;
  isRecurring: boolean;
}) => {
  const { getIsValidFundingSource } = useInvalidFundingSources(fundingSourceTypesOptions);
  const { data: fundingSources = [], isLoading: isFundingSourcesLoading } = useFundingSources();

  const filteredFundingSources = useMemo(() => {
    let validFundingSources = fundingSources;
    if (isRecurring && billSubscriptionOptions?.eligibleFundingSources) {
      const eligibleFundingSourceMap = keyBy(billSubscriptionOptions.eligibleFundingSources, 'id');
      validFundingSources = fundingSources.filter((fundingSource) => eligibleFundingSourceMap[fundingSource.id]);
    }

    return validFundingSources.filter(getIsValidFundingSource);
  }, [fundingSources, isRecurring, billSubscriptionOptions, getIsValidFundingSource]);

  return {
    fundingSources: filteredFundingSources,
    isLoading: isFundingSourcesLoading,
  };
};
