import { BillSubscriptionEndPolicyEnum, useBillSubscription } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { KycComplianceFormState } from '../../complete-required-details';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { RecurringPaymentScheduledActivity } from '../../RecurringPaymentScheduled';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { PaymentFlowFormFields } from '../types';

type EditRecurringActivityProps = {
  billSubscriptionId: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
  missingKycComplianceInformation: boolean;
  setKycComplianceFormState: (state: KycComplianceFormState) => void;
};

export const EditRecurringActivity = ({
  billSubscriptionId,
  onDone,
  onClose,
  missingKycComplianceInformation,
  setKycComplianceFormState,
}: EditRecurringActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const navigate = useNavigate();

  const {
    data: billSubscription,
    update: updateBillSubscription,
    isLoading: isLoadingBillSubscription,
    isMutating: isUpdatingBillSubscription,
  } = useBillSubscription({
    id: billSubscriptionId,
    params: { expand: ['nextOccurrence', 'vendor', 'fundingSource', 'deliveryMethod', 'occurrences'] },
  });

  const defaultValues: ComponentProps<typeof PaymentFlowActivity>['defaultValues'] = {
    vendorId: billSubscription?.vendorId,
    deliveryMethodId: billSubscription?.deliveryMethodId,
    fundingSourceId: billSubscription?.fundingSourceId,
    amountToPay: billSubscription?.amount?.toString(),
    noteToVendor: billSubscription?.memoToVendor,
    intervalType: billSubscription?.intervalType,
    endPolicy: billSubscription?.endPolicy,
    deliveryDate: billSubscription?.nextOccurrence?.deliveryDate || billSubscription?.nextOccurrence?.dueDate,
    startDate: billSubscription?.nextOccurrence?.deliveryDate || billSubscription?.nextOccurrence?.dueDate,
    endDate: billSubscription?.endDate || null,
    lastAmount: billSubscription?.lastAmount?.toString() || undefined,
    numOfOccurrences: billSubscription?.numOfOccurrences || null,
    recurrenceType: 'recurring',
  };

  const onSubmit = (data: PaymentFlowFormFields) =>
    updateBillSubscription({
      amount: Number(data.amountToPay),
      fundingSourceId: data.fundingSourceId || void 0,
      deliveryMethodId: data.deliveryMethodId || void 0,
      memoToVendor: data.noteToVendor || void 0,
      intervalType: data.intervalType,
      startDate: data.startDate || void 0,
      numOfOccurrences: data.numOfOccurrences,
      ...(data.endPolicy === BillSubscriptionEndPolicyEnum.NoEndDate
        ? {
            endPolicy: BillSubscriptionEndPolicyEnum.NoEndDate,
          }
        : data.endPolicy === BillSubscriptionEndPolicyEnum.EndDate
        ? {
            endPolicy: BillSubscriptionEndPolicyEnum.EndDate,
            endDate: data.endDate,
            lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
          }
        : {
            endPolicy: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
            numOfOccurrences: data.numOfOccurrences,
            lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
          }),
    }).then(() => navigate('success'));

  if (isLoadingBillSubscription) {
    return <NewSinglePaymentStepLayout isLoading />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            billSubscription={billSubscription}
            title={formatMessage('activities.paymentFlow.form.header.title.editPayment')}
            isSubmitting={isUpdatingBillSubscription}
            onSubmit={onSubmit}
            onClose={onClose}
            missingKycComplianceInformation={missingKycComplianceInformation}
            setKycComplianceFormState={setKycComplianceFormState}
          />
        }
      />
      <Route
        path="/success"
        element={
          <RecurringPaymentScheduledActivity
            billSubscriptionId={billSubscriptionId}
            onClose={onClose}
            onDone={onDone}
          />
        }
      />
    </Routes>
  );
};
