import {
  getDeliveryMethodIconKey,
  useGetDeliveryMethodDisplayText,
  usePaymentSchedulingPreference,
} from '@melio/ap-domain';
import {
  PaymentReviewLineItem,
  useGetDeliveryMethodHelperText,
  useGetDeliveryMethodLabelText,
  usePaymentProcessingInfo,
  useShowMemoToVendor,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import {
  DeliveryMethod as DeliveryMethodPlatform,
  DeliveryMethodType,
  Payment,
  PaymentStatusEnum,
  PaymentTimelineItem,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { useInternationalCountryDisplayName } from '../../../../../utils/pay-flow/deliveryMethods';
import { useVendorReceivesTexts } from '../../../hooks/useVendorReceivesTexts';
import { SectionBlock } from './SectionBlock';

export type VendorReceivesSectionProps = {
  vendorName: string;
  deliveryMethod: DeliveryMethodPlatform;
  deliveryPreference: Payment['deliveryPreferenceType'];
  estimatedDelivery: Date;
  maxEstimatedDelivery: Date;
  memoToVendor?: string | null;
  timeline: Array<PaymentTimelineItem>;
  paymentStatus?: PaymentStatusEnum;
  processedTimeline: Payment['processedTimeline'] | null;
};

export const VendorReceivesSection = ({
  vendorName,
  deliveryMethod,
  deliveryPreference,
  estimatedDelivery,
  maxEstimatedDelivery,
  memoToVendor,
  timeline,
  paymentStatus,
  processedTimeline,
}: VendorReceivesSectionProps) => {
  const { formatMessage, formatDateTimeRange, formatDate } = useMelioIntl();
  const { settings } = useConfig();
  const { isByDeliveryDate } = usePaymentSchedulingPreference();
  const { countryDisplayName } = useInternationalCountryDisplayName(deliveryMethod);

  const deliveryMethodLabelText = useGetDeliveryMethodLabelText(deliveryMethod, timeline, deliveryPreference);
  const deliveryMethodDisplayText = useGetDeliveryMethodDisplayText(deliveryMethod, vendorName);
  const deliveryMethodIconKey = getDeliveryMethodIconKey(deliveryMethod, settings.icons.deliveryMethod);
  const deliveryMethodHelperText = useGetDeliveryMethodHelperText(deliveryMethod, timeline, countryDisplayName);
  const deliveryDateMainLabel = isByDeliveryDate
    ? formatDate(estimatedDelivery, { dateStyle: 'medium' })
    : formatDateTimeRange(estimatedDelivery, maxEstimatedDelivery, {
        dateStyle: 'medium',
      });
  const { shouldDisplayMemoToVendor } = useShowMemoToVendor();

  const showMemoToVendor = shouldDisplayMemoToVendor(deliveryMethod);

  const { label: deliveryDateLabel, description: deliveryDateDescription } = useVendorReceivesTexts({
    estimatedDelivery,
    paymentStatus,
    processedTimeline,
    type: deliveryMethod.type,
  });

  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
  const isPaymentProcessedInternally = !isPaymentProcessedByCapitalOne(deliveryMethod.type);

  const labelProps = deliveryDateLabel
    ? {
        label: deliveryDateLabel,
        'data-testid': 'delivery-date-label',
      }
    : undefined;

  const descriptionProps = deliveryDateDescription
    ? {
        label: deliveryDateDescription,
        'data-testid': 'delivery-date-description',
      }
    : undefined;

  return (
    <Group variant="vertical" spacing="s">
      <SectionBlock.Title label={formatMessage('widgets.paymentDetails.deliveryMethod.title')} />

      {deliveryMethod.type !== DeliveryMethodType.VirtualAccount && (
        <>
          <PaymentReviewLineItem
            data-testid="delivery-method-check-scheduled-label"
            contentTestId="delivery-method-details"
            labelProps={{ label: deliveryMethodLabelText }}
            mainLabelProps={{ label: deliveryMethodDisplayText }}
            icon={{ type: deliveryMethodIconKey }}
            descriptionProps={{ label: deliveryMethodHelperText }}
          />
        </>
      )}

      {isPaymentProcessedInternally && (
        <>
          <PaymentReviewLineItem
            contentTestId="delivery-eta-content"
            labelProps={labelProps}
            mainLabelProps={{
              label: deliveryDateMainLabel,
              'data-testid': 'delivery-eta-content-delivery-date',
            }}
            descriptionProps={descriptionProps}
            icon={{ type: 'scheduled' }}
          />
          {showMemoToVendor && (
            <>
              <PaymentReviewLineItem
                labelProps={{ label: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.label') }}
                mainLabelProps={{
                  label: memoToVendor ?? undefined,
                  placeholder: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.empty'),
                }}
                contentTestId="memo-to-vendor-content"
                icon={{ type: 'note-add' }}
              />
            </>
          )}
        </>
      )}
    </Group>
  );
};
