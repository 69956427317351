import { Form, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';
import * as yup from 'yup';

import { FormWidgetProps } from '../../../types';

export type UnilateralFormFields = {
  email: string;
  phoneNumber?: string;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    email: yup
      .string()
      .email(formatMessage('widgets.deliveryMethods.unilateralForm.email.validation.format'))
      .required(formatMessage('widgets.deliveryMethods.unilateralForm.email.validation.required')),
    phoneNumber: yup.string(),
  });
};

export type UnilateralFormProps = FormWidgetProps<UnilateralFormFields>;

export const UnilateralForm: React.FC<UnilateralFormProps> = forwardRef<UnilateralFormProps, 'form'>(
  ({ defaultValues: _defaultValues, onSubmit, onSubmissionStateChange, isSaving, ...props }, ref) => {
    const defaultValues = defaults(_defaultValues, {
      email: '',
      phoneNumber: '',
    });

    const { formatMessage } = useMelioIntl();

    const { formProps, registerField } = useMelioForm<UnilateralFormFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    return (
      <Form data-component="UnilateralForm" {...props} {...formProps} ref={ref}>
        <Form.TextField
          labelProps={{ label: formatMessage('widgets.deliveryMethods.unilateralForm.email.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.unilateralForm.email.placeholder')}
          autoFocus
          {...registerField('email')}
        />
        <Form.PhoneField
          labelProps={{ label: formatMessage('widgets.deliveryMethods.unilateralForm.phoneNumber.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.unilateralForm.phoneNumber.placeholder')}
          {...registerField('phoneNumber')}
        />
      </Form>
    );
  }
);

UnilateralForm.displayName = 'UnilateralForm';
