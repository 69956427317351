import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { useFxCommonValidations } from '../formUtils';
import { OwnershipDetails, OwnershipDetailsForm } from '../types';

export const useOwnershipDetailsFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { firstNameSchema, lastNameSchema, ssnSchema, dateOfBirthSchema, addressSchema } = useFxCommonValidations();
  const uboSchema = yup.object().shape({
    uboId: yup.string().required(),
    ownerFirstName: firstNameSchema,
    ownerLastName: lastNameSchema,
    ssn: ssnSchema,
    ownershipPercentage: yup
      .string()
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.required'
        )
      )
      .test(
        'in-range',
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.inRange'
        ),
        (val) => {
          if (!val) {
            return false;
          }

          const parsedVal = parseInt(val, 10);
          return parsedVal >= 25 && parsedVal <= 100;
        }
      ),
    address: addressSchema,
    dateOfBirth: dateOfBirthSchema,
  }) as yup.SchemaOf<OwnershipDetails>;

  const schema = yup.object().shape({
    ubos: yup.array().of(uboSchema),
  }) as yup.SchemaOf<OwnershipDetailsForm>;

  return schema;
};
