import { ComponentThemeType } from '@/cl/theme/theme.hooks';
import { GroupItemType } from '@/types/payDashboard.types';

const getCardHeightByItemType = (itemType: GroupItemType) => {
  switch (itemType) {
    case GroupItemType.PAID:
    case GroupItemType.UNPAID:
    case GroupItemType.SCANNED_INVOICE:
    case GroupItemType.FAILED:
      return 120;
    case GroupItemType.SCHEDULED:
      return 169;
  }
};

export const GroupItemTheme: ComponentThemeType<{ itemType: GroupItemType }> = (currentTheme, { itemType }) => ({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gridGap: 0,
    },
    cardContainer: {
      minHeight: getCardHeightByItemType(itemType),
    },
    textContainer: {
      flexDirection: 'column',
      flex: 1,
      padding: 0,
      textAlign: 'left',
    },
    content: {
      ...currentTheme.textStyles.body2Semi,
      color: currentTheme.colors.neutral.black,
    } as never,
    helperText: {
      ...currentTheme.textStyles.body4,
      color: currentTheme.colors.neutral.darkest,
    } as never,
    bodyBaseLine: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '16px',
      alignItems: 'center',
    },
    footerContainer: {
      paddingTop: '16px',
      marginTop: '16px',
      borderTop: `1px solid ${currentTheme.colors.neutral.lighter}`,
    },
  },
});
