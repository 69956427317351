import { InvoicePreferencesApiClient } from '@melio/platform-api-axios-client';
import { useMemo } from 'react';

import { useModel, UseModelProps } from '../../api-client';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInvoicePreference = (_id: string) => InvoicePreferencesApiClient.getInvoicePreferences();

export type UseInvoicePreferenceProps = UseModelProps<typeof getInvoicePreference>;

export const useInvoicePreference = (props: UseInvoicePreferenceProps = {}) => {
  const { data, ...query } = useModel({
    ...props,
    id: 'invoice-preferences',
    queryKey: 'InvoicePreferencesApi',
    queryFn: getInvoicePreference,
    cacheTime: 0,
  });

  return {
    ...query,
    data,
    displayInvoiceNumber: useMemo(() => `${data?.prefix ?? 'INV'}${data?.invoiceNumber ?? '1'}`, [data]),
  };
};

export type InvoicePreferenceModel = ReturnType<typeof useInvoicePreference>;
