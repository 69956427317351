import { Box } from '@chakra-ui/react';
import { Badge, Grid, Group, Icon, Text, Tooltip } from '@melio/penny';
import { BillingFeeSetting } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { SchedulePaymentIntent } from '../../../../types';
import { getBalanceSum, getFeesSum, useAmountHeaderFees, useFreeChecks, useGetFeesTooltip } from './AmountHeader.utils';

export type AmountHeaderProps = {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  orgBillingFeeSettings: BillingFeeSetting[];
};

export const AmountHeader = forwardRef<AmountHeaderProps, 'div'>(
  ({ orgBillingFeeSettings, paymentIntentsWithDerivatives, ...props }, ref) => {
    const { formatCurrency } = useMelioIntl();
    const [shouldUseFeesBreakdownApi] = useFeature(FeatureFlags.CheckFeesPromotionEnabled, false);

    const balanceSum = getBalanceSum(paymentIntentsWithDerivatives);

    return (
      <Group variant="vertical" alignItems="flex-end" spacing="xs" data-component="AmountHeader" {...props} ref={ref}>
        <Text textStyle="heading1Semi">{formatCurrency(balanceSum)}</Text>
        {shouldUseFeesBreakdownApi ? (
          <FeesBreakdown
            orgBillingFeeSettings={orgBillingFeeSettings}
            paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
          />
        ) : (
          <PaymentsFees paymentIntentsWithDerivatives={paymentIntentsWithDerivatives} />
        )}
      </Group>
    );
  }
);

const PaymentsFees = ({
  paymentIntentsWithDerivatives,
}: {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const totalFees = getFeesSum(paymentIntentsWithDerivatives) || 0;

  return (
    <Grid
      gap="xxs"
      gridTemplateColumns="auto auto"
      justifyItems="flex-end"
      color={totalFees ? 'neutral.darkest' : 'neutral.light'}
    >
      <Text color="inherit" textStyle="body4Semi">
        {formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.label', {
          amount: totalFees,
          formattedAmount: formatCurrency(totalFees),
        })}
      </Text>
      {totalFees ? (
        <Tooltip
          label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.tooltip')}
        >
          <Box as={Icon} type="info" size="small" color="inherit" tabIndex={0} />
        </Tooltip>
      ) : undefined}
    </Grid>
  );
};

const FeesBreakdown = ({
  paymentIntentsWithDerivatives,
  orgBillingFeeSettings,
}: {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  orgBillingFeeSettings: BillingFeeSetting[];
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const { feesBreakdown } = useAmountHeaderFees(paymentIntentsWithDerivatives);

  const { freeChecksToApply } = useFreeChecks(paymentIntentsWithDerivatives);
  const totalFees = feesBreakdown?.feesToPay || 0;

  const tooltip = useGetFeesTooltip(freeChecksToApply, feesBreakdown, orgBillingFeeSettings);

  return (
    <Group variant="vertical" spacing="xs" alignItems="flex-end">
      {freeChecksToApply ? (
        <Badge
          status="informative"
          data-testid="batch-payments-amount-header-free-checks-badge"
          label={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.amountHeader.freeCheckBadge.label',
            {
              amount: freeChecksToApply,
            }
          )}
        />
      ) : null}
      <Grid
        gap="xxs"
        gridTemplateColumns="auto auto"
        justifyItems="flex-end"
        data-testid="batch-payments-amount-header-total-fees"
      >
        <Text textStyle="body4Semi" color={totalFees ? 'neutral.darkest' : 'neutral.light'}>
          {formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountHeader.totalFees.label', {
            amount: totalFees,
            formattedAmount: formatCurrency(totalFees),
          })}
        </Text>
        {totalFees || freeChecksToApply ? (
          <Tooltip label={tooltip} data-testid="batch-payments-amount-header-total-fees-tooltip">
            <Box as={Icon} type="info" size="small" color="inherit" tabIndex={0} />
          </Tooltip>
        ) : undefined}
      </Grid>
    </Group>
  );
};

AmountHeader.displayName = 'AmountHeader';
