/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { Big } from 'big.js';
import { ActionsDropdownMenu, Button, IconButton, StatusModal, Text, useFormSubmissionController } from '@melio/penny';
import { Traits, useAnalytics } from '@melio/platform-analytics';
import { useAccountingPlatforms, useBills, useScannedInvoice } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useDateUtils } from '@melio/platform-utils';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useScannedInvoiceReviewAnalytics } from '@/hooks/analytics/scannedInvoiceReviewAnalytics.jsx';
import { useRouter } from '@/hooks/router.hooks';
import { useAppToast } from '@/hooks/useAppToast.hooks';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { dateFormatToOpenApiSpec } from '@/utils/dates.util';
import {
  ReviewScannedInvoiceForm,
  ReviewScannedInvoiceFormFields,
} from '@/widgets/pay-dashboard/review-scanned-invoice/components/ReviewScannedInvoiceForm';
import { ScannedInvoiceFilePreview } from '@/widgets/pay-dashboard/review-scanned-invoice/components/ScannedInvoiceFilePreview';
import { MarkAsPaidDialog } from '../bill-details/components/MarkAsPaidDialog';
import { ReviewScannedInvoiceScreenWidgetTheme } from './ReviewScannedInvoiceScreen.widget.theme';

enum SubmitAction {
  MarkAsPaid = 'mark_as_paid',
  Continue = 'continue',
  SaveAndClose = 'save_and_close',
}

export const ReviewScannedInvoiceScreen = ({ scannedInvoiceId }: { scannedInvoiceId: string }) => {
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { navigateToSchedulePayment, replaceToPayDashboardTab } = useRouter();
  const { state } = useLocation();
  const { formatMessage } = usePlatformIntl();
  const { create: createBill } = useBills({ enabled: false });
  const { isFetching: accountingPlatformIsLoading, hasAccountingPlatform } = useAccountingPlatforms();
  const { infoToast, errorToast } = useAppToast();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const {
    isOpen: isMarkAsPaidModalOpen,
    onOpen: onMarkAsPaidModalOpen,
    onClose: onMarkAsPaidModalClose,
  } = useDisclosure();
  const {
    delete: deleteScannedInvoice,
    markAsPaid,
    isMutating: isUpdating,
    data: scannedInvoice,
    isLoading: isScannedInvoiceLoading,
  } = useScannedInvoice({ id: scannedInvoiceId });
  const [markAsPaidFeatureFlag] = useFeature<boolean>(FeatureFlags.MarkAsPaid, false);

  const [onSubmit, setOnSubmit] = React.useState<ReturnType<typeof useCallback>>(() => () => {});
  const { createDate } = useDateUtils();
  const { setTraits, track } = useAnalytics();
  const setBillTraits = () => {
    const traits: Traits = {
      create_a_bill: true,
      last_bill_added_date: createDate().toISOString(),
    };

    setTraits(traits);
  };
  const styles = useAppTheme(ReviewScannedInvoiceScreenWidgetTheme, {});

  const {
    submitButtonProps,
    onSubmissionStateChange,
    getValues: getFormValues,
  } = useFormSubmissionController<ReviewScannedInvoiceFormFields>();

  const { eventProperties } = useScannedInvoiceReviewAnalytics({ scannedInvoice });

  const { returnUrl } = (state as { returnUrl?: string }) || {};

  useEffect(() => {
    if (eventProperties) {
      track('DraftBillReview', 'Viewed', eventProperties);
    }
  }, [eventProperties]);

  useEffect(() => {
    if (isDeleteModalOpen && eventProperties) {
      track('DraftBillReviewDeleteApproval', 'Viewed', eventProperties);
    }
  }, [eventProperties, isDeleteModalOpen]);

  const handleSubmit = useCallback(
    async ({ totalAmount, dueDate, noteToSelf, vendorId, billNumber }: ReviewScannedInvoiceFormFields) => {
      if (!scannedInvoice || !dueDate) throw Error();
      setIsSaving(true);
      try {
        const billCreationPayload = {
          invoice: {
            number: billNumber,
            fileId: scannedInvoice.fileId,
          },
          amount: Number(totalAmount),
          dueDate: dueDate?.toISOString(),
          vendorId,
          note: noteToSelf,
          scannedInvoiceId: scannedInvoice.id,
        };

        return await createBill(billCreationPayload);
      } catch (e) {
        errorToast(formatMessage('widgets.ReviewScannedInvoice.toast.error'));
        setIsSaving(false);
        throw e;
      }
    },
    [scannedInvoice, setIsSaving, errorToast],
  );

  const onContinue = useCallback(
    (params: ReviewScannedInvoiceFormFields) => {
      track('DraftBillReviewContinue', 'Submitted', eventProperties);
      handleSubmit(params)
        .then(({ id: billId }) => {
          track('DraftBillReviewContinue', 'Saved', { ...eventProperties, BillId: billId });
          setBillTraits();
          navigateToSchedulePayment({ billId, returnUrl });
        })
        .catch(() => {});
    },
    [handleSubmit, track, eventProperties],
  );

  const onSaveAndClose = useCallback(
    (params: ReviewScannedInvoiceFormFields) => {
      track('DraftBillReviewSaveAndClose', 'Submitted', eventProperties);
      handleSubmit(params)
        .then(({ id }) => {
          track('DraftBillReviewSaveAndClose', 'Saved', { ...eventProperties, BillId: id });
          setBillTraits();
          replaceToPayDashboardTab(PayDashboardTabs.Inbox, id);
        })
        .catch(() => {});
    },
    [handleSubmit, track, eventProperties],
  );

  const onSubmitButtonClicked = useCallback(
    (submitAction: SubmitAction) => {
      switch (submitAction) {
        case SubmitAction.Continue:
          setOnSubmit(() => onContinue);
          break;
        case SubmitAction.MarkAsPaid:
          setOnSubmit(() => onMarkAsPaid);
          break;
        case SubmitAction.SaveAndClose:
          setOnSubmit(() => onSaveAndClose);
          break;
      }
      setTimeout(() => {
        submitButtonProps?.onClick?.();
      }, 0);
    },
    [setOnSubmit, submitButtonProps, onContinue, onSaveAndClose],
  );

  const onDeleteModalClose = useCallback(() => {
    track('DraftBillReviewDeleteApprovalClose', 'Chose', eventProperties);
    setIsDeleteModalOpen(false);
  }, [track, eventProperties, setIsDeleteModalOpen]);

  const onDeleteModalCancel = useCallback(() => {
    track('DraftBillReviewDeleteApprovalCancel', 'Chose', eventProperties);
    setIsDeleteModalOpen(false);
  }, [track, eventProperties, setIsDeleteModalOpen]);

  const onDeleteButtonClicked = useCallback(() => {
    track('DraftBillReviewDelete', 'Chose', eventProperties);
    setIsDeleteModalOpen(true);
  }, [eventProperties, setIsDeleteModalOpen]);

  const onMarkAsPaid = useCallback(() => {
    track('DraftBillReviewMarkAsPaid', 'Chose', eventProperties);
    onMarkAsPaidModalOpen();
  }, [track, eventProperties]);

  const onMarkAsPaidSubmit = useCallback(
    async (accountingPlatformPaymentAccountId?: string) => {
      const { billNumber, dueDate, noteToSelf, totalAmount, vendorId } = getFormValues!();
      const markAsPaidParams = {
        note: noteToSelf,
        vendorId,
        invoiceNumber: billNumber,
        totalAmount: new Big(totalAmount).mul(100).toNumber(),
        dueDate: dateFormatToOpenApiSpec(dueDate!),
        categoryId: accountingPlatformPaymentAccountId,
      };
      await markAsPaid(markAsPaidParams);
    },
    [getFormValues],
  );

  const onDelete = useCallback(async () => {
    if (!scannedInvoice) return;
    track('DraftBillReviewDeleteApprovalDelete', 'Submitted', eventProperties);
    setIsSaving(true);
    try {
      await deleteScannedInvoice();
      track('DraftBillReviewDeleteApprovalDelete', 'Saved', eventProperties);
      infoToast(formatMessage('widgets.ReviewScannedInvoice.toast.delete.success'));
      replaceToPayDashboardTab(PayDashboardTabs.Inbox);
    } catch (e) {
      errorToast(formatMessage('widgets.ReviewScannedInvoice.toast.delete.error'));
      setIsSaving(false);
      setIsDeleteModalOpen(false);
    }
  }, [deleteScannedInvoice, scannedInvoice, setIsSaving, infoToast, errorToast]);

  if (!scannedInvoice) {
    return null;
  }

  const { invoiceNumber, dueDate, amount, file } = scannedInvoice;
  if (accountingPlatformIsLoading) return <Loader isAbsoluteCenter />;

  return (
    <>
      <Stack flexDirection="column" sx={styles['container']} gridGap={0} spacing={0}>
        <Box sx={styles['toolbar']}>
          <Box padding={'8px'}>
            <IconButton
              size="medium"
              icon="close"
              variant={'naked'}
              onClick={() => {
                replaceToPayDashboardTab(PayDashboardTabs.Inbox, scannedInvoice.id);
              }}
              aria-label={'button'}
            />
          </Box>
        </Box>
        <Stack flexDirection="column" sx={styles['contentContainer']} gridGap={0} spacing={0}>
          <Box sx={styles['fileContainer']}>
            {file && <ScannedInvoiceFilePreview scannedInvoice={scannedInvoice} fileInfo={file} />}
          </Box>
          <Stack flexDirection="column" sx={styles['formContainer']} gridGap={'40px'} spacing={0}>
            <Stack flexDirection="column" gridGap={'16px'}>
              <Text textStyle="heading1Semi">{formatMessage('widgets.ReviewScannedInvoice.title')}</Text>
              <Text>{formatMessage('widgets.ReviewScannedInvoice.description')}</Text>
            </Stack>
            <ReviewScannedInvoiceForm
              editMode={true}
              defaultValues={{
                totalAmount: amount ? amount.toString() : undefined,
                dueDate: dueDate ? new Date(dueDate) : null,
                billNumber: invoiceNumber || '',
              }}
              onSubmissionStateChange={onSubmissionStateChange}
              onSubmit={onSubmit}
              isSaving={isSaving}
              isDisabled={isScannedInvoiceLoading}
            />
          </Stack>
        </Stack>
        <HStack sx={styles['actionBar']} gridGap="16px">
          <ActionsDropdownMenu
            data-testid="review-scanned-invoice-actions-dropdown-menu"
            items={[
              {
                label: formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.actions.saveAndClose.label'),
                onClick: () => onSubmitButtonClicked(SubmitAction.SaveAndClose),
                dataTestId: 'save-and-close-scanned-invoice',
              },
              ...(markAsPaidFeatureFlag
                ? [
                    {
                      label: formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.actions.markAsPaid.label'),
                      onClick: () => onSubmitButtonClicked(SubmitAction.MarkAsPaid),
                      dataTestId: 'mark-scanned-invoice-as-paid',
                    },
                  ]
                : []),
              {
                label: formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.actions.delete.label'),
                onClick: () => onDeleteButtonClicked(),
                variant: 'critical',
                dataTestId: 'delete-scanned-invoice',
              },
            ]}
            label={formatMessage('widgets.ReviewScannedInvoice.buttons.moreActions.label')}
            isOpen={isMenuOpen}
            onOpenChange={setIsMenuOpen}
          />
          <Button
            data-testid="submit"
            variant="primary"
            size="medium"
            {...submitButtonProps}
            onClick={() => onSubmitButtonClicked(SubmitAction.Continue)}
            label={formatMessage('widgets.ReviewScannedInvoice.buttons.next.label')}
          />
        </HStack>
      </Stack>
      <StatusModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        variant="cancel"
        header={formatMessage('widgets.ReviewScannedInvoice.deleteModal.title')}
        description={formatMessage('widgets.ReviewScannedInvoice.deleteModal.content')}
        primaryButton={{
          label: formatMessage('widgets.ReviewScannedInvoice.deleteModal.confirmText'),
          onClick: onDelete,
          variant: 'primary',
        }}
        secondaryButton={{
          label: formatMessage('widgets.ReviewScannedInvoice.deleteModal.cancelText'),
          onClick: onDeleteModalCancel,
          variant: 'tertiary',
        }}
      />
      {markAsPaidFeatureFlag && (
        <MarkAsPaidDialog
          isOpen={isMarkAsPaidModalOpen}
          onClose={onMarkAsPaidModalClose}
          onMarkAsPaidSubmit={onMarkAsPaidSubmit}
          isLoading={isUpdating}
          hasAccountingPlatform={hasAccountingPlatform}
          amount={scannedInvoice.amount!}
        />
      )}
    </>
  );
};
