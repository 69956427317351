import { useMelioIntl } from '@melio/ar-domain';
import { TBTFormWidget, TBTFormWidgetFields } from '@melio/form-controls';
import { Button, Group, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMonitoring } from '@melio/platform-monitoring';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import {
  CardHolderAddressDetails,
  CardHolderDetailsFormFields,
  CardHolderEmailDetails,
  CardHolderNameDetails,
} from '../../types';
import { CardHolderAddressDetailsForm, CardHolderEmailDetailsForm, CardHolderNameDetailsForm } from '../components';

export type AddCardFormScreenProps = {
  onSubmit: (data: CardHolderDetailsFormFields) => void;
  isSaving: boolean;
  amount: number;
  onEmailFieldBlur: (email: string) => void;
  emailBanner: React.ReactNode | null;
};

export const AddCardFormScreen = forwardRef<AddCardFormScreenProps>(
  ({ onSubmit, isSaving, amount, emailBanner, onEmailFieldBlur }, ref) => {
    const {
      onSubmissionStateChange: onCardHolderEmailDetailsFormStateChange,
      submitButtonProps: cardHolderEmailDetailsSubmitButtonProps,
      formState: cardHolderEmailDetailsFormState,
    } = useFormSubmissionController<CardHolderEmailDetails>();
    const {
      onSubmissionStateChange: onCardHolderNameDetailsFormStateChange,
      submitButtonProps: cardHolderNameDetailsSubmitButtonProps,
      formState: cardHolderNameDetailsFormState,
    } = useFormSubmissionController<CardHolderNameDetails>();
    const { formatCurrency, formatMessage } = useMelioIntl();
    const {
      onSubmissionStateChange: onCardDetailsFormStateChange,
      submitButtonProps: cardDetailsSubmitButtonProps,
      formState: tbtFormState,
    } = useFormSubmissionController<TBTFormWidgetFields>();
    const {
      onSubmissionStateChange: onCardHolderAddressDetailsFormStateChange,
      submitButtonProps: cardHolderAddressDetailsSubmitButtonProps,
      formState: cardHolderAddressDetailsFormState,
    } = useFormSubmissionController<CardHolderAddressDetails>();

    useAnalyticsView('PaymentRequest', true, true, {
      PaymentMethodType: 'card',
    });
    const { routeReady } = useMonitoring();

    const onClick = () => {
      cardHolderEmailDetailsSubmitButtonProps?.onClick();
      cardHolderNameDetailsSubmitButtonProps?.onClick();
      cardDetailsSubmitButtonProps?.onClick();
      cardHolderAddressDetailsSubmitButtonProps?.onClick();
    };

    const [isReady, ready] = useBoolean(false);
    const [cardDetails, setCardDetails] = useState<TBTFormWidgetFields>();
    const [cardHolderAddressDetails, setAddressCardHolderDetails] = useState<CardHolderAddressDetails>();
    const [cardHolderEmailDetails, setCardHolderEmailDetails] = useState<CardHolderEmailDetails>();
    const [cardHolderNameDetails, setCardHolderNameDetails] = useState<CardHolderNameDetails>();

    useEffect(() => {
      if (
        cardHolderEmailDetails &&
        cardHolderEmailDetailsFormState?.isValid &&
        cardHolderNameDetails &&
        cardHolderNameDetailsFormState?.isValid &&
        cardDetails &&
        cardHolderAddressDetails &&
        cardHolderAddressDetailsFormState?.isValid &&
        tbtFormState?.isValid
      ) {
        onSubmit({
          ...cardDetails,
          ...cardHolderAddressDetails,
          ...cardHolderEmailDetails,
          ...cardHolderNameDetails,
          email: cardHolderEmailDetails.email.trim(),
        });
      }
    }, [cardDetails, cardHolderAddressDetails, cardHolderEmailDetails, cardHolderNameDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    const isSubmitButtonDisabled =
      cardDetailsSubmitButtonProps?.isDisabled ||
      cardHolderAddressDetailsSubmitButtonProps?.isDisabled ||
      cardHolderEmailDetailsSubmitButtonProps?.isDisabled ||
      cardHolderNameDetailsSubmitButtonProps?.isDisabled ||
      isSaving;

    const isSubmitButtonLoading =
      cardDetailsSubmitButtonProps?.isLoading ||
      cardHolderAddressDetailsSubmitButtonProps?.isLoading ||
      cardHolderEmailDetailsSubmitButtonProps?.isLoading ||
      cardHolderNameDetailsSubmitButtonProps?.isLoading ||
      isSaving;

    return (
      <Group variant="vertical" ref={ref} spacing="l">
        <Group variant="vertical" spacing="m">
          <Text textStyle="body2Semi">{formatMessage('ar.guestPayment.activities.cardHolder.form.title')}</Text>
          {isReady && (
            <>
              <Group variant="vertical" spacing="xs">
                <CardHolderEmailDetailsForm
                  onSubmit={setCardHolderEmailDetails}
                  onSubmissionStateChange={onCardHolderEmailDetailsFormStateChange}
                  isSaving={isSaving}
                  size="small"
                  onEmailFieldBlur={onEmailFieldBlur}
                />
                {emailBanner}
              </Group>
              <CardHolderNameDetailsForm
                onSubmit={setCardHolderNameDetails}
                onSubmissionStateChange={onCardHolderNameDetailsFormStateChange}
                isSaving={isSaving}
                size="small"
              />
            </>
          )}
          <TBTFormWidget
            onSubmissionStateChange={onCardDetailsFormStateChange}
            onSubmit={setCardDetails}
            isSaving={isSaving}
            onReady={ready.on}
            size="small"
            hideCardLogos
          />
          {isReady && (
            <CardHolderAddressDetailsForm
              onSubmit={setAddressCardHolderDetails}
              onSubmissionStateChange={onCardHolderAddressDetailsFormStateChange}
              isSaving={isSaving}
              size="small"
            />
          )}
        </Group>
        {isReady && (
          <Button
            ref={routeReady}
            data-testid="add-card-submit-button"
            isDisabled={isSubmitButtonDisabled}
            isLoading={isSubmitButtonLoading}
            onClick={onClick}
            size="large"
            label={formatMessage('ar.guestPayment.activities.cardHolder.form.buttons.submit.text', {
              amount: formatCurrency(amount),
            })}
          />
        )}
      </Group>
    );
  }
);
AddCardFormScreen.displayName = 'AddCardFormScreen';
