/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SyncFlowEnum } from '@melio/ap-activities';
import { useDisclosure } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { useFundingSources } from '@melio/platform-api';
import { CardType } from '@melio/platform-api';

import { SwiperWizard } from '@/cl/components/Swiper/SwiperWizard.component';
import { useIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { classifyFundingSources } from '@/utils/FundingSources.utils';
import { MelioTutorialVideoModal } from '../melioTutorileVideo/MelioTutorialVideoModal.widget';
import { CreditCardCard } from './CreditCardCard.widget';
import { PayBillCard } from './PayBillCard.widget';
import { QuickBooksCard } from './QuickBooksCard.widget';

type EmptyCardSwiperProps = {
  qboAccountingId?: string;
};

export const EmptyCardSwiper = ({ qboAccountingId }: EmptyCardSwiperProps) => {
  const { data: fundingSources = [] } = useFundingSources();
  const { creditCards } = classifyFundingSources(fundingSources);
  const { formatMessage } = usePlatformIntl();
  const { isOpen: isTutorialModalOpen, onOpen: onOpenTutorialModal, onClose: onCloseTutorialModal } = useDisclosure();
  const { isLoading, loginToQuickBooks } = useIntuitConnect(SyncFlowEnum.PayBoard, qboAccountingId);
  const { goToAddCard } = useRouter();
  const { track } = useAnalytics();

  const addCardHandler = React.useCallback(() => {
    goToAddCard({ cardType: CardType.Credit });
    track('PayDashboardAddSparkCard', 'Chose');
  }, [goToAddCard]);

  const onQuickBooksClick = React.useCallback(() => {
    loginToQuickBooks();
    track('PayDashboardConnectWithQB', 'Chose');
  }, [loginToQuickBooks]);

  const shouldShowCreditCardStep = creditCards.length === 0;
  const steps = [
    {
      actionCallback: onOpenTutorialModal,
      actionText: formatMessage('widgets.payDashboardCards.payBillCard.actionText'),
    },
  ];
  if (qboAccountingId) {
    steps.push({
      actionCallback: onQuickBooksClick,
      actionText: formatMessage('widgets.payDashboardCards.quickBooksCard.actionText'),
    });
  }

  if (shouldShowCreditCardStep) {
    steps.push({
      actionCallback: addCardHandler,
      actionText: formatMessage('widgets.payDashboardCards.creditCardCard.actionText'),
    });
  }
  return (
    <>
      <SwiperWizard isButtonLoading={isLoading} steps={steps}>
        <PayBillCard />
        {qboAccountingId && <QuickBooksCard />}
        {shouldShowCreditCardStep && <CreditCardCard />}
      </SwiperWizard>
      <MelioTutorialVideoModal
        isOpen={isTutorialModalOpen}
        onModalClose={onCloseTutorialModal}
        origin={OriginFlow.Carrousel}
      />
    </>
  );
};
