/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { useIsNewBillExperienceEnabled } from '@melio/ap-domain';
import { AddBillFormWidgetFields } from '@melio/ap-widgets';
import { useBreakpointValue, useToast } from '@melio/penny';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  FileInfo,
  isAcceptedScannedInvoiceFileType,
  PaymentIntent,
  PostScannedInvoicesBulkResponse,
  useAccountingPlatforms,
  useBills,
  useFiles,
  usePaymentIntents,
  useScannedInvoices,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import platform from 'platform';
import { useEffect, useState } from 'react';

import { AmountsMismatchModal } from '../../ReviewScannedInvoice/components/AmountsMismatchModal';
import { AddBillV2Activity } from '../AddBillV2/AddBillV2.activity';
import { AddBillV2DFormInitialValues } from '../AddBillV2Form/types';
import {
  CreateBillFormTarget,
  getInitialValueLineItemWithAmount,
  isSinglePaymentFields,
  useGetBillFileOcrData,
  useTrackBillCreation,
} from '../bill-utils';
import { AddOcrBillScreen, BillFileProcessingScreen } from './screens';

export type AddOcrBillActivityProps = {
  filesList: File[];
  defaultBillDetails?: Partial<AddBillFormWidgetFields>;
  onDone: (data: AddBillFormWidgetFields, id?: PaymentIntent['id'], target?: CreateBillFormTarget) => void;
  onClose: VoidFunction;
  onBack: VoidFunction;
  onError?: ErrorFunction;
  onUploadMultipleInvoicesSuccess?: ({
    results,
    files,
  }: {
    files: File[];
    results: PostScannedInvoicesBulkResponse;
  }) => void;
  onUploadMultipleInvoicesNavigate?: () => void;
};

export const AddOcrBillActivity = withAnalyticsContext<AddOcrBillActivityProps>(
  ({
    filesList,
    defaultBillDetails,
    onBack,
    onClose,
    onDone,
    onError,
    onUploadMultipleInvoicesSuccess,
    onUploadMultipleInvoicesNavigate,
  }) => {
    const [files, setFiles] = useState<File[]>(filesList);
    const [billFileInfo, setBillFileInfo] = useState<FileInfo>();
    const [billDetails, setBillDetails] = useState<
      Partial<AddBillFormWidgetFields | AddBillV2DFormInitialValues> | undefined
    >(defaultBillDetails);
    const [amountMismatch, setAmountMismatch] = useState<number>(0);
    const { formatMessage } = useMelioIntl();
    const { create: uploadBillFile, isLoading: isUploadingBillFile } = useFiles({ enabled: false });
    const [isLineItemsEnabledOnMobile] = useFeature<boolean>(FeatureFlags.IsLineItemsEnabledOnMobile, true);
    const { createMultiple, reset } = useScannedInvoices();
    const { isNewBillExperienceEnabled } = useIsNewBillExperienceEnabled();

    const { isMutating: isCreatingPaymentIntent } = usePaymentIntents({ enabled: false });
    const { create: createBill, isMutating: isCreatingBill } = useBills({ enabled: false });

    const { toast } = useToast();
    const { activeAccountingPlatform } = useAccountingPlatforms();
    const isMobile = useBreakpointValue({ xs: true, s: false }, { ssr: false } as never);

    const isLineItemsAvailable = isMobile ? isLineItemsEnabledOnMobile : true;

    const { createDate } = useDateUtils();
    const { trackBillCreation } = useTrackBillCreation();
    const { setTraits, track, createTrackHandler } = useAnalytics();
    const setBillTraits = () => {
      const traits: Traits = {
        create_a_bill: true,
        last_bill_added_date: createDate().toISOString(),
      };

      setTraits(traits);
    };

    const handleFail = (error: PlatformError) => {
      createTrackHandler<{ os: string }>('ProcessUploadedBillFailed', 'Viewed')({ os: platform.os?.toString() });
      toast({ type: 'error', title: error.message });
      reset();
      onError?.(error);
      onBack();
    };
    useEffect(() => {
      if (files?.length) {
        uploadFiles();
      } else {
        setBillFileInfo(undefined);
      }
    }, [files]);

    const { getBillFileOcrData } = useGetBillFileOcrData({
      billFileInfo,
      isLineItemsEnabled: isNewBillExperienceEnabled,
      defaultBillDetails,
      onFail: handleFail,
    });

    useEffect(() => {
      if (billFileInfo) {
        const handleUploadFileFinished = async () => {
          const billFileOcrDataResult = await getBillFileOcrData();
          if (billFileOcrDataResult) {
            const { values, mismatchedAmount } = billFileOcrDataResult;
            if (!isLineItemsAvailable) {
              values.lineItems = [
                getInitialValueLineItemWithAmount(
                  Number(values.amount ?? '') ?? 0,
                  formatMessage('widgets.ReviewScannedInvoice.amountsMismatch.lineItem.desc')
                ),
              ];
            }

            setBillDetails(values);
            if (isNewBillExperienceEnabled && mismatchedAmount) {
              setAmountMismatch(mismatchedAmount);
            }
          }
        };

        handleUploadFileFinished();
      }
    }, [billFileInfo, isLineItemsAvailable]);

    const uploadFiles = async () => {
      try {
        const allFilesFormatUnaccepted = files.every((file) => !isAcceptedScannedInvoiceFileType(file));

        if (allFilesFormatUnaccepted) {
          onUploadMultipleInvoicesNavigate?.();
          setTimeout(() => {
            const errorMessage =
              files.length > 1
                ? formatMessage('widgets.ReviewScannedInvoice.uploadInvoices.errors.unsupportedFilesFormat')
                : formatMessage('widgets.ReviewScannedInvoice.uploadInvoices.errors.unsupportedFileFormat');
            handleFail({
              message: errorMessage,
            });
          }, 0);
          return;
        }

        if (onUploadMultipleInvoicesSuccess && files.length > 1) {
          const propsMultipleUploadSentAnalytics = {
            PageName: 'bill-batch-upload',
            Intent: 'bill-batch-upload-started',
            Platform: 'platform',
            Status: 'Successful',
            NumberOfFilesUploaded: files.length,
          };
          track('Bill', 'Status', propsMultipleUploadSentAnalytics);
          createMultiple(files)
            .then((results) => {
              const propsMultipleUploadedAnalytics = {
                ...propsMultipleUploadSentAnalytics,
                ScannedInvoicesUploaded: results?.data?.success?.map((res) => res?.scannedInvoiceId),
                NumberOfScannedBillsCreated: results?.data?.success?.length,
                Intent: 'bill-batch-upload-ended',
                success: results?.data?.success?.length ? 'Successful' : 'Failure',
              };
              track('Bill', 'Status', propsMultipleUploadedAnalytics);
              onUploadMultipleInvoicesSuccess({ results, files });
            })
            .catch(handleFail);
          onUploadMultipleInvoicesNavigate?.();
        } else {
          try {
            const propsSingleUploadSentAnalytics = {
              PageName: 'how-to-add-a-bill',
              Intent: 'invoice-upload-started',
              Platform: 'platform',
              Status: 'Successful',
            };
            track('Bill', 'Status', propsSingleUploadSentAnalytics);

            const singleFile = Array.isArray(files) ? files[0] : files;
            const fileInfo = await uploadBillFile(singleFile as File);

            track('Bill', 'Status', {
              ...propsSingleUploadSentAnalytics,
              ScannedInvoiceUploaded: fileInfo?.id,
              Intent: 'invoice-upload-ended',
            });

            setBillFileInfo(fileInfo);
          } catch (e) {
            track('Bill', 'Status', {
              PageName: 'how-to-add-a-bill',
              Platform: 'platform',
              Intent: 'invoice-upload-ended',
              Status: 'Failure',
            });

            throw e;
          }
        }
      } catch (e) {
        handleFail(e as PlatformError);
      }
    };

    if (files.length && !billDetails) {
      return <BillFileProcessingScreen numberOfFilesUploaded={1} />;
    }

    const handleDone = async (data: AddBillFormWidgetFields, target?: CreateBillFormTarget) => {
      if (isSinglePaymentFields(data)) {
        try {
          const billCreationPayload = {
            amount: parseFloat(data.billAmount),
            dueDate: data.dueDate?.toISOString(),
            vendorId: data.vendorId,
            invoice: {
              number: data.billNumber,
              fileId: billFileInfo?.id,
            },
            note: data.note,
            categoryId: data.categoryId,
          };
          const createdBill = await createBill(billCreationPayload);
          trackBillCreation(createdBill, target);
          setBillTraits();
          onDone(data, createdBill.id, target);
        } catch (error) {
          handleFail(error as PlatformError);
        }
      }
    };

    const handleAutoAddLines = () => {
      if (!billDetails) {
        return;
      }
      const values = { ...billDetails } as AddBillV2DFormInitialValues;
      values.lineItems = [
        ...(values.lineItems ?? []),
        getInitialValueLineItemWithAmount(
          amountMismatch,
          formatMessage('widgets.ReviewScannedInvoice.amountsMismatch.lineItem.desc')
        ),
      ];

      setBillDetails(values);
      setAmountMismatch(0);
    };
    const handleRemoveLines = () => {
      if (!billDetails) {
        return;
      }
      const values = { ...billDetails } as AddBillV2DFormInitialValues;
      values.lineItems = [
        getInitialValueLineItemWithAmount(
          Number(values.amount ?? '') ?? 0,
          formatMessage('widgets.ReviewScannedInvoice.amountsMismatch.lineItem.desc')
        ),
      ];
      setBillDetails(values);
      setAmountMismatch(0);
    };

    if (isNewBillExperienceEnabled) {
      return (
        <>
          <AddBillV2Activity
            onDone={onDone}
            onClose={onClose}
            onBack={onBack}
            initialValues={billDetails}
            initialFileInfo={billFileInfo}
            initialFile={files?.[0]}
          />
          <AmountsMismatchModal
            mismatchedValue={`$${amountMismatch}`}
            isOpen={!!amountMismatch && isLineItemsAvailable}
            onAdd={handleAutoAddLines}
            onRemove={handleRemoveLines}
          />
        </>
      );
    }
    return (
      <AddOcrBillScreen
        activeAccountingPlatform={activeAccountingPlatform}
        isBillImageLoading={isUploadingBillFile}
        defaultBillDetails={billDetails}
        isDefaultBillDetailsLoading={!!files && !billDetails}
        isSavingBillDetails={isCreatingPaymentIntent || isCreatingBill}
        onBack={onBack}
        onClose={onClose}
        onDone={(data, _event, target) => handleDone(data, target)}
        onSelectFile={(file) => {
          if (file) {
            setFiles([file]);
          }
        }}
        value={files?.[0] ?? null}
      />
    );
  }
);

AddOcrBillActivity.displayName = 'AddOcrBillActivity';
