import { Box } from '@chakra-ui/react';
import { Link, SectionBanner } from '@melio/penny';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

export const ErrorBanner = () => {
  const { formatMessage } = useMelioIntl();
  const { supportEmail } = useConfig().settings;
  const mailToSupportEmail = `mailto:${supportEmail}`;

  return (
    <Box pt="m">
      <SectionBanner
        title={formatMessage(
          'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.title'
        )}
        description={formatMessage(
          'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.description',
          {
            link: (
              <Link
                // TODO: Replace with the link to the QuickBooks Desktop Web Connector documentation
                // https://meliorisk.atlassian.net/browse/ME-65043
                href="#"
                isBold
                label={formatMessage(
                  'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.link'
                )}
                variant="standalone"
                size="medium"
                data-testid="connecting-melio-and-qbdt-guide-link"
              />
            ),
            supportEmail: (
              <Link href={mailToSupportEmail} label={supportEmail} isBold variant="standalone" size="medium" newTab />
            ),
          }
        )}
        variant="critical"
        data-testid="verify-connection-error-banner"
      />
    </Box>
  );
};
