import { InvoiceTimelineApiClient } from '@melio/platform-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseInvoiceTimelineProps = UseModelProps<typeof InvoiceTimelineApiClient.getInvoiceTimeline>;

export const useInvoiceTimeline = (props: UseInvoiceTimelineProps = {}) =>
  useModel({
    ...props,
    queryKey: 'InvoiceTimelineApi',
    queryFn: InvoiceTimelineApiClient.getInvoiceTimeline,
  });

export type InvoiceTimelineModel = ReturnType<typeof useInvoiceTimeline>;
