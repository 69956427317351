import React from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { CreateReportModal } from '@/widgets/settings-page/CompanySettingPage/CreateReportModal.widget';

type SettingsContextValue = {
  openCreateReportModal: () => void;
};

export const SettingsContext = React.createContext<SettingsContextValue>({
  openCreateReportModal: () => undefined,
});

type SettingsProviderProps = {
  children: React.ReactNode;
};
export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const value = {
    openCreateReportModal: onOpen,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
      <CreateReportModal isOpen={isOpen} onClose={onClose} />
    </SettingsContext.Provider>
  );
};
