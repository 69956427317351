import { Box } from '@chakra-ui/react';
import { AddBillFormWidget, AddBillFormWidgetFields, UploadBillWidget } from '@melio/ap-widgets';
import { Button, Container, Group, IconButton, SplitScreen, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { AccountingPlatform } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

type AddOcrBillScreenProps = {
  defaultBillDetails?: Partial<AddBillFormWidgetFields>;
  isDefaultBillDetailsLoading?: boolean;
  isSavingBillDetails?: boolean;
  isBillImageLoading?: boolean;
  activeAccountingPlatform?: AccountingPlatform;
  value?: File | null;
  onSelectFile: (file: File | null) => void;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: AddBillFormWidgetFields,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
};

export const AddOcrBillScreen = forwardRef<AddOcrBillScreenProps, 'div'>(
  (
    {
      defaultBillDetails,
      isDefaultBillDetailsLoading,
      isSavingBillDetails,
      activeAccountingPlatform,
      isBillImageLoading,
      onSelectFile,
      onBack,
      onClose,
      onDone,
      value,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
      useFormSubmissionController<AddBillFormWidgetFields>();

    useAnalyticsView('AddUploadedBill');

    return (
      <SplitScreen
        data-component="AddOcrBillActivity.AddOcrBillScreen"
        data-testid="add-ocr-bill-activity-add-ocr-bill-screen"
        variant="2:1"
        {...props}
        ref={ref}
        header={
          <Box
            data-component="SplitScreenLayout.Toolbar"
            data-testid="layout-toolbar"
            display="flex"
            justifyContent="space-between"
            height="100px"
            alignItems="center"
            paddingX="xl"
          >
            <IconButton
              data-component="SplitScreenLayout.BackButton"
              data-testid="layout-back-button"
              aria-label="back"
              icon="chevron-left"
              variant="naked"
              size="medium"
              {...cancelButtonProps}
              onClick={onBack}
            />
            <IconButton
              data-component="SplitScreenLayout.CloseButton"
              data-testid="layout-close-button"
              aria-label="close"
              icon="close"
              variant="naked"
              size="medium"
              onClick={onClose}
            />
          </Box>
        }
        panelA={{
          content: <UploadBillWidget onSelectFile={onSelectFile} value={value} isLoading={isBillImageLoading} />,
        }}
        panelB={{
          content: (
            <Group variant="vertical" justifyContent="center">
              <Container textAlign="center">
                <Text
                  as="h1"
                  data-testid="layout-title"
                  data-component="SplitScreenLayout.Title"
                  textStyle="heading1Semi"
                >
                  {formatMessage('activities.addOcrBill.screens.addOcrBill.title')}
                </Text>
              </Container>
              <AddBillFormWidget
                activeAccountingPlatform={activeAccountingPlatform}
                shouldRequireInvoice={false}
                isDisabled={isDefaultBillDetailsLoading}
                isSaving={isSavingBillDetails}
                defaultValues={defaultBillDetails}
                onSubmissionStateChange={onSubmissionStateChange}
                onSubmit={onDone}
              />
              <Group variant="vertical" data-component="SplitScreenLayout.Actions" width="full" spacing="xs">
                <Button
                  data-component="SplitScreenLayout.NextButton"
                  data-testid="layout-next-button"
                  isFullWidth
                  {...submitButtonProps}
                  isDisabled={isDefaultBillDetailsLoading || submitButtonProps?.isDisabled}
                  onClick={handleSubmit?.('continue')}
                  label={formatMessage('activities.addOcrBill.screens.addOcrBill.continue')}
                />
                <Button
                  data-component="SplitScreenLayout.NextButton"
                  data-testid="layout-secondary-next-button"
                  isFullWidth
                  variant="secondary"
                  {...submitButtonProps}
                  isDisabled={isDefaultBillDetailsLoading || submitButtonProps?.isDisabled}
                  onClick={handleSubmit?.('close')}
                  label={formatMessage('activities.addOcrBill.screens.addOcrBill.saveAndClose')}
                />
              </Group>
            </Group>
          ),
        }}
      />
    );
  }
);

AddOcrBillScreen.displayName = 'AddOcrBillActivity.AddOcrBillScreen';
