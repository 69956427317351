import { MicroDepositsVerificationFormFields } from '@melio/ap-widgets';
import { DeliveryMethod, FundingSource } from '@melio/platform-api';

export type MicroDepositsVerificationStep =
  | 'MICRO_DEPOSITS_VERIFICATION'
  | 'MICRO_DEPOSITS_VERIFICATION_CONFIRMATION'
  | 'MICRO_DEPOSITS_VERIFICATION_EXCEEDING_FAILURE';

export type MicroDepositsVerificationModalActivityProps = {
  isOpen: boolean;
  fundingSourceId?: FundingSource['id'];
  deliveryMethodId?: DeliveryMethod['id'];
  onClose: VoidFunction;
  onDone: VoidFunction;
  onSuccessMicroDepositVerified?: VoidFunction;
  onError?: ErrorFunction;
};

export const isMicroDepositsPayload = (
  data: MicroDepositsVerificationFormFields | null
): data is MicroDepositsVerificationFormFields => !!data;
