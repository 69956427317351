import { Box } from '@chakra-ui/react';
import { FormattedMessage, PDFPreviewer, useMelioIntl } from '@melio/ar-domain';
import { Group, SectionBanner, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useRef } from 'react';

import { Footer, InvoiceFormLayout } from '../../../layouts';

export type PreviewInvoiceScreenProps = {
  error: ARPlatformError | null;
  onClose: VoidFunction;
  onDownload: VoidFunction;
  previewContent: string;
  isLoading: boolean;
};

export const PreviewInvoiceScreen = forwardRef<PreviewInvoiceScreenProps>(
  ({ isLoading, previewContent, onClose, error, onDownload, ...props }, ref) => {
    const [isBannerDismissed, dismissBanner] = useBoolean();
    const { formatMessage } = useMelioIntl();

    const pdfRef = useRef<HTMLDivElement>(null);
    const containerPdf = useRef<HTMLImageElement>(null);

    useAnalyticsView('Invoice', true, true, {
      ProductName: 'ar',
      PageName: 'preview-invoice',
      Flow: 'manage-invoice',
      Intent: 'preview-invoice',
    });

    const SectionBannerComp = () =>
      error && !isBannerDismissed ? (
        <SectionBanner
          data-testid="preview-invoice-error-banner"
          description={formatMessage('ar.invoiceLifecycle.activities.previewInvoice.failure.text')}
          showCloseIcon
          variant="critical"
          onClose={dismissBanner.on}
        />
      ) : null;
    return (
      <>
        <InvoiceFormLayout
          {...{
            sx: {
              '@media print': {
                display: 'none',
              },
            },
          }}
          variant="10Columns"
          isLoading={isLoading}
          onClose={onClose}
          data-component="PreviewInvoiceScreen"
          data-testid="preview-invoice-screen"
          footer={
            <Footer
              primaryButtonProps={{
                label: formatMessage('ar.invoiceLifecycle.activities.previewInvoice.buttons.download.label'),
                onClick: onDownload,
              }}
              secondaryButtonProps={{
                label: formatMessage('ar.invoiceLifecycle.activities.previewInvoice.buttons.print.label'),
                onClick: () => {
                  const pdfImage = pdfRef.current?.querySelector('canvas')?.toDataURL('image/png');
                  if (containerPdf.current && pdfImage) {
                    containerPdf.current.src = pdfImage;
                    containerPdf.current.onload = () => {
                      window.print();
                    };
                  }
                },
              }}
              tertiaryButtonProps={{
                label: formatMessage('ar.invoiceLifecycle.activities.previewInvoice.buttons.close.label'),
                onClick: onClose,
              }}
            />
          }
          {...props}
          ref={ref}
        >
          <Group variant="vertical" alignItems="flex-start" spacing="l">
            <Group variant="vertical" alignItems="flex-start" width="full">
              <Text textStyle="heading1Semi" data-testid="preview-invoice-header-text">
                <FormattedMessage id="ar.invoiceLifecycle.activities.previewInvoice.title.text"></FormattedMessage>
              </Text>
              <SectionBannerComp />
            </Group>
            {!error && <PDFPreviewer ref={pdfRef} previewContent={previewContent} />}
          </Group>
        </InvoiceFormLayout>
        <Box
          sx={{
            '@media print': {
              display: 'block',
            },
          }}
          as="img"
          display="none"
          ref={containerPdf}
        ></Box>
      </>
    );
  }
);

PreviewInvoiceScreen.displayName = 'PreviewInvoiceScreen';
