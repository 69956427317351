import { Container, FormSelectNewOption, Group, RadioGroup, SelectNew } from '@melio/penny';
import { FundingSource, FundingSourceType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useState } from 'react';

import { PaymentFlowOnChangeHandlers } from '../../../../types';
import { useFundingSourcesSearchBar } from '../FundingSourceInput/useFundingSourceSearchBar';
import { FundingSourceCard } from './FundingSourceCard/FundingSourceCard';

type FundingSourceSectionProps = {
  fundingSourceId: string | undefined;
  fundingSources: FundingSource[];
  onFundingSourceChange: PaymentFlowOnChangeHandlers['onFundingSourceChange'];
  isLoading: boolean;
  isReadOnly: boolean;
};

export const FundingSourceSection = ({
  fundingSourceId,
  fundingSources,
  onFundingSourceChange,
  isLoading,
  isReadOnly,
}: FundingSourceSectionProps) => {
  const { formatMessage } = useMelioIntl();
  const [type, setType] = useState<FundingSource['type']>();

  const fundingSourcesByType = fundingSources.filter((fundingSource) => fundingSource.type === type);
  const selectedFundingSource = fundingSources.find((fundingSource) => fundingSource.id === fundingSourceId);

  const {
    props: searchProps,
    show: showSearch,
    filtered: filteredFundingSources,
  } = useFundingSourcesSearchBar(fundingSourcesByType);

  const options = fundingSourcesByType.map(toOption);
  const filteredOptions = filteredFundingSources.map(toOption);
  const selectedOption = selectedFundingSource && toOption(selectedFundingSource);

  useEffect(() => {
    setType(selectedFundingSource?.type);
  }, [selectedFundingSource]);

  const createTypeId = (type: FundingSource['type']) => `${type}-option`;

  return (
    <Group variant="vertical" spacing="xs">
      <RadioGroup
        data-testid="funding-source-type"
        variant="horizontal"
        value={type}
        isReadOnly={isReadOnly}
        onChange={(event) => {
          const existingFundingSource = fundingSources.find(
            (fundingSource) => fundingSource.type === event.target.value
          );
          if (existingFundingSource) {
            setType(existingFundingSource.type);
            onFundingSourceChange(existingFundingSource.id);
          }
        }}
        options={[
          {
            value: FundingSourceType.BankAccount,
            mainLabelProps: {
              label: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.type'),
            },
            id: createTypeId(FundingSourceType.BankAccount),
            ariaLabel: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.aria'),
            disabled: {
              isDisabled: !fundingSources.find((fundingSource) => fundingSource.type === FundingSourceType.BankAccount),
              message: formatMessage(
                'activities.paymentFlow.form.content.fundingSourceCard.bank-account.type.disabled'
              ),
            },
          },
          {
            value: FundingSourceType.Card,
            mainLabelProps: {
              label: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type'),
            },
            id: createTypeId(FundingSourceType.Card),
            ariaLabel: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.aria'),
            disabled: {
              isDisabled: !fundingSources.find((fundingSource) => fundingSource.type === FundingSourceType.Card),
              message: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.disabled'),
            },
          },
        ]}
      />
      <SelectNew
        isReadOnly={isReadOnly}
        aria-describedby={type ? createTypeId(type) : undefined}
        aria-labelledby="pay-from"
        data-testid="funding-source-dropdown"
        value={selectedOption?.value}
        options={options}
        valueRenderer={valueRenderer}
        optionRenderer={optionRenderer}
        searchBarProps={showSearch ? { ...searchProps, options: filteredOptions } : undefined}
        onChange={(event) => {
          const fundingSource = event.target.value as unknown as FundingSource;
          onFundingSourceChange(fundingSource.id);
        }}
        size="large"
        isLoading={isLoading}
        shouldHideClearButton
      />
    </Group>
  );
};

const valueRenderer = (option: FormSelectNewOption<FundingSource>) => (
  <Container width="full" paddingY="s" data-testid={`funding-source-dropdown-selected-${option.value.id}`}>
    <FundingSourceCard fundingSource={option.value} isValue />
  </Container>
);
const optionRenderer = (option: FormSelectNewOption<FundingSource>) => (
  <Container width="full" data-testid={`funding-source-dropdown-item-${option.value.id}`}>
    <FundingSourceCard fundingSource={option.value} isValue={false} />
  </Container>
);

const toOption = (fundingSource: FundingSource): FormSelectNewOption<FundingSource> => ({
  value: fundingSource,
  label: fundingSource.displayName,
});
