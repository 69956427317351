import { StatusModal, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useEBillsSubscription, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useEffect } from 'react';

import { MonitoredAction } from '../../../monitoring';

export const CancelEBillSubscriptionModalActivity = withAnalyticsContext<{
  vendorId: Vendor['id'];
  isOpen: boolean;
  onClose: () => void;
}>(({ vendorId, isOpen, onClose, setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { cancelEBillsSubscription, isMutating } = useEBillsSubscription({ id: vendorId, enabled: false });
  const { track } = useAnalytics();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();

  const { toast } = useToast();

  setAnalyticsProperties({
    PageName: 'cancel-ebill-subscription',
    EntryPoint: 'vendor-summary',
    Flow: 'dashboard',
    Intent: 'cancel-ebill-subscription',
    VendorId: vendorId,
  });

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View');
    }
  }, [track, isOpen]);

  const handleClose = () => {
    track('Vendor', 'Click', { Cta: 'exit' });
    onClose();
  };

  const onCancelSubscriptionSuccess = () => {
    track('Vendor', 'Status', { Status: 'success' });
    endAction('ebills_subscription_cancel');

    toast({
      type: 'informative',
      title: formatMessage('activities.ebillCancelSubscription.toast.success'),
    });
    onClose();
  };

  const onCancelSubscriptionFailure = () => {
    track('Vendor', 'Status', { Status: 'failure', ErrorType: 'ebills-subscription-cancellation-failed' });

    toast({
      type: 'error',
      title: formatMessage('activities.ebillCancelSubscription.toast.error'),
    });
    onClose();
  };

  const onCancelSubscription = async () => {
    track('Vendor', 'Click', { Cta: 'cancel-subscription' });
    startAction('ebills_subscription_cancel');

    try {
      await cancelEBillsSubscription({ vendorId });
      onCancelSubscriptionSuccess();
    } catch (e) {
      onCancelSubscriptionFailure();
    }
  };

  return (
    <StatusModal
      variant="alert"
      isOpen={isOpen}
      onClose={handleClose}
      header={formatMessage('activities.ebillCancelSubscription.modal.title')}
      description={formatMessage('activities.ebillCancelSubscription.modal.description')}
      primaryButton={{
        onClick: () => onCancelSubscription(),
        variant: 'primary',
        label: formatMessage('activities.ebillCancelSubscription.modal.action.confirm'),
        isLoading: isMutating,
      }}
      data-testid="cancel-ebill-subscription-modal"
      role="alertdialog"
    ></StatusModal>
  );
});
