import { TableRowSelectionState, useBreakpoint, useTable } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import uniq from 'lodash/uniq';
import { useCallback, useMemo, useState } from 'react';

type UseVendorsTabSelectionResult = { rowSelections: string[]; areAllSelected: boolean } & Pick<
  Parameters<typeof useTable<Vendor>>[0],
  'selectedRows' | 'onRowSelectionChange' | 'onAllRowsSelectionChange' | 'rowSelectionTooltips'
>;

export const useVendorsTabSelection = (vendorsData: Vendor[]): UseVendorsTabSelectionResult => {
  const [rowSelections, setRowSelections] = useState<string[]>([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const [isVendorsBatchPaymentsEnabled] = useFeature<boolean>(
    FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled,
    false,
    {
      shouldTrack: true,
    }
  );

  const onRowSelectionChange = useCallback((selectionState: TableRowSelectionState<Vendor>) => {
    const { rowId, isSelected } = selectionState;
    setRowSelections((selectedRows) =>
      isSelected ? uniq([...selectedRows, rowId]) : selectedRows.filter((selectedRowId) => selectedRowId !== rowId)
    );
  }, []);

  const onAllRowsSelectionChange = useCallback(
    (areAllSelected: boolean) => {
      setAreAllSelected(areAllSelected);
      const rowSelections = areAllSelected ? vendorsData?.map((vendor) => vendor.id) ?? [] : [];
      setRowSelections(rowSelections);
    },
    [vendorsData]
  );

  const rowSelectionTooltips = useMemo(
    () => ({
      header: {
        label: !areAllSelected
          ? formatMessage('activities.payDashboard.vendorsTab.table.tooltips.selectAll')
          : formatMessage('activities.payDashboard.vendorsTab.table.tooltips.deselectAll'),
      },
      row: ({ isSelectionDisabled }: { isSelectionDisabled: boolean }) =>
        isSelectionDisabled
          ? {
              label: formatMessage('activities.payDashboard.vendorsTab.table.fx.disabled.tooltips'),
            }
          : undefined,
    }),
    [areAllSelected, formatMessage]
  );

  if (isExtraSmallScreen || !isVendorsBatchPaymentsEnabled) {
    return { rowSelections: [], areAllSelected: false };
  }

  return {
    rowSelections,
    selectedRows: rowSelections.reduce<Record<string, boolean>>((obj, rowId) => ({ ...obj, [rowId]: true }), {}),
    onRowSelectionChange,
    onAllRowsSelectionChange,
    rowSelectionTooltips,
    areAllSelected,
  };
};
