import { Spinner } from '@chakra-ui/react';
import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Avatar, Button, Container, Group, Icon, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

export type PayByBankAccountScreenProps = {
  onSubmit: () => void;
  fundingSourceName?: string;
  isSaving: boolean;
  amount: number;
  logo?: string;
};

export const PayByBankAccountScreen = forwardRef<PayByBankAccountScreenProps>(
  ({ logo, fundingSourceName, onSubmit, isSaving, amount }, ref) => {
    const { formatCurrency, formatMessage } = useMelioIntl();

    return (
      <Group variant="vertical" spacing="l" data-testid="pay-by-bank-account-screen" ref={ref}>
        <Group variant="vertical" spacing="m">
          <Group variant="vertical" spacing="xxs">
            <Text textStyle="body2Semi">
              <FormattedMessage id="ar.guestPayment.payByBank.form.title.text" />
            </Text>
            <Text textStyle="body3">
              <FormattedMessage id="ar.guestPayment.payByBank.form.description.text" />
            </Text>
          </Group>
          <Container border="regular" paddingX="m" paddingY="m">
            {isSaving ? (
              <Group justifyContent="center">
                <Spinner />
              </Group>
            ) : (
              <Group alignItems="center">
                {logo ? <Avatar name={fundingSourceName || ''} src={logo} /> : <Icon type="bank" />}
                <Group spacing="none" variant="vertical">
                  <Text textStyle="body2Semi">
                    <FormattedMessage id="ar.guestPayment.payByBank.accountCard.subTitle.text" />
                  </Text>
                  <Text color="neutral.darker" textStyle="body4">
                    {fundingSourceName}
                  </Text>
                </Group>
              </Group>
            )}
          </Container>
        </Group>
        <Button
          isLoading={isSaving}
          data-testid="add-bank-submit-button"
          size="large"
          label={formatMessage('ar.guestPayment.activities.cardHolder.form.buttons.submit.text', {
            amount: formatCurrency(amount),
          })}
          onClick={onSubmit}
        />
      </Group>
    );
  }
);
PayByBankAccountScreen.displayName = 'PayByBankAccountScreen';
