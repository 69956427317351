import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { BillFileProcessingScreen } from '@melio/ap-activities/src/components/add-bill/AddOcrBill/screens';
// eslint-disable-next-line no-restricted-imports
import { SyncMigrationProgressIndication, SyncNowWidget } from '@melio/ap-widgets';
import { BrazeContentCard, BrazeContentCardType } from '@melio/in-app-marketing';
import {
  AccountingPlatformConnectionStatus,
  useAccount,
  useAccountingPlatforms,
  useScannedInvoices,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';

import { UploadIndicator } from '@/cl/components/UploadIndicator/UploadIndicator.component';
import { AppLayout } from '@/cl/layouts/app-layout/appLayout.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { useNavigationTabLinks } from '@/hooks/useNavigationTabLinks';
import { useZendesk } from '@/utils/zendesk';

const disconnectedAccountingPlatformStatuses: AccountingPlatformConnectionStatus[] = [
  AccountingPlatformConnectionStatus.Unlinked,
  AccountingPlatformConnectionStatus.Disconnected,
];

export const DashboardLayout: FC = () => {
  const { goToSettingsSupport } = useRouter();
  const { data: account } = useAccount({ id: 'me' });
  const { partnerConfig } = usePartnerConfig();
  const { links } = useNavigationTabLinks();
  const config = useConfig();
  const { isBatchMutating: isUploadingMultipleBills, multipleInvoices } = useScannedInvoices();

  const [isSyncNowEnabled] = useFeature<boolean>(FeatureFlags.SyncNow, false);
  const [isMultipleBillsUploadEnabled] = useFeature(FeatureFlags.PlatformMultipleBillsUpload, false, {
    shouldTrack: true,
  });
  const [isSyncMigrationProgressIndicationEnabled] = useFeature(
    FeatureFlags.PlatformSyncMigrationProgressIndication,
    false,
  );

  const { data: accountingPlatforms } = useAccountingPlatforms();
  const accountingPlatform = accountingPlatforms?.find(
    (accountingPlatform) => !disconnectedAccountingPlatformStatuses.includes(accountingPlatform.connectionStatus),
  );

  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();
  const hasMultipleFilesUploadProcess =
    isMultipleBillsUploadEnabled &&
    config.settings.uploadBill?.shouldShowMultipleBillUploadIndication &&
    (isUploadingMultipleBills || (multipleInvoices?.files?.length ?? 0) > 1);

  const { can } = usePermissions();
  const syncNowIcon =
    isSyncNowEnabled &&
    !shouldRedirectToNewDashboard &&
    accountingPlatform &&
    can({ subject: 'accountingPlatform:sync', action: 'create' })
      ? () => <SyncNowWidget accountingPlatform={accountingPlatform} />
      : undefined;

  const syncMigrationProgressIndication =
    isSyncMigrationProgressIndicationEnabled && config.settings.syncProgressIndicationEnabled
      ? SyncMigrationProgressIndication
      : undefined;

  //we use it here to initial the zendesk script on all dashboard screens
  useZendesk();

  const onSupportClicked = () => {
    goToSettingsSupport();
  };

  const userName = account ? `${account.user.firstName} ${account.user.lastName}` : '';
  const backIcon = partnerConfig.config.links?.backButtonUrl ? partnerConfig.icons.BackToPartnerIcon : undefined;
  const isInnerLoading = !!isUploadingMultipleBills;

  return (
    <>
      <AppLayout
        topNav={
          config.settings.isEmbeddedExperience
            ? undefined
            : {
                TopNavIcon: partnerConfig.icons.LogoIcon,
                TopNavSmallIcon: partnerConfig.icons.SmallLogoIcon,
                SyncNowIcon: syncNowIcon,
                BackToPartnerIcon: backIcon,
                SyncMigrationProgressIndication: syncMigrationProgressIndication,
              }
        }
        leftLayout={
          config.settings.isEmbeddedExperience
            ? undefined
            : {
                links,
                userName,
                onSupportClicked: onSupportClicked,
              }
        }
        notificationHeader={() => <BrazeContentCard cardType={BrazeContentCardType.SPLASH_BANNER_TOP} />}
      >
        {isInnerLoading ? (
          <BillFileProcessingScreen numberOfFilesUploaded={multipleInvoices?.files?.length} />
        ) : (
          <Outlet />
        )}
        {hasMultipleFilesUploadProcess && <UploadIndicator multipleInvoices={multipleInvoices} />}
      </AppLayout>
    </>
  );
};
