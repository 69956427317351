import { Button, Icon, SortDirection, SortDropdownMenu } from '@melio/penny';
import { MessageKey } from '@melio/platform-i18n';
import { useMelioIntl } from '@melio/platform-provider';

import { usePayDashboardMobileSort } from './usePayDashboardMobileSort';

export const MobileSortMenu = ({
  items,
  onSortChange,
  title = 'activities.payDashboard.sort.title',
}: {
  items: { label: string; cellId: string; defaultSortOrder: SortDirection }[];
  onSortChange?: VoidFunction;
  title?: MessageKey;
}) => {
  const { sortMenuItems, isMenuOpen, setIsMenuOpen, selectedItemIndex } = usePayDashboardMobileSort({
    sortableItems: items,
    onSortChange,
  });
  const { formatMessage } = useMelioIntl();

  const label = formatMessage('sort.button.text');

  return (
    <SortDropdownMenu
      items={sortMenuItems}
      selectedItemIndex={selectedItemIndex}
      sortDirection={sortMenuItems[selectedItemIndex]?.sortDirection}
      trigger={
        <Button
          leftElement={<Icon size="small" type="sort" color="inherit" aria-hidden />}
          label={label}
          variant="tertiary"
          size="medium"
          aria-label="icon-button-sort"
          isFullWidth
        />
      }
      onOpenChange={setIsMenuOpen}
      isOpen={isMenuOpen}
      title={formatMessage(title)}
    />
  );
};
