import { usePermissions } from '@melio/platform-permissions';
import { useCallback } from 'react';

import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';

export const useSubscriptionPermissions = () => {
  const { can } = usePermissions();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const hasAccessToSubscriptionSettings = useCallback(() => {
    if (!isSubscriptionsEnabled) {
      return false;
    }
    const canReadSubscription = can({ action: 'read', subject: 'subscriptions:me' });
    const canModifySubscription =
      can({ action: 'update', subject: 'subscriptions:me' }) && can({ action: 'delete', subject: 'subscriptions:me' });
    const canReadBillings = can({ action: 'read', subject: 'subscriptions:billings' });

    return canReadSubscription && canModifySubscription && canReadBillings;
  }, [isSubscriptionsEnabled, can]);

  return { hasAccessToSubscriptionSettings };
};
