import { useGuestPayorFundingSource, useGuestPayorPayment, useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import React, { useEffect } from 'react';

import { PayByBankAccountScreen } from './screens';

type PayByBankActivityProps = {
  onCreatePayment: VoidFunction;
  onError: ARErrorFunction;
  fundingSourceId: string;
  paymentRequestLink: string;
  goToBankRoute: VoidFunction;
  onFormLoading: (isLoading: boolean) => void;
};

export const PayByBankActivity = forwardRef<PayByBankActivityProps>(
  ({ paymentRequestLink, onFormLoading, onCreatePayment, onError, fundingSourceId, goToBankRoute }, ref) => {
    const { data: paymentRequestDetails, isLoading: isLoadingPaymentRequestLink } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
    });
    const {
      data: fundingSource,
      isLoading: isLoadingFundingSource,
      isError,
    } = useGuestPayorFundingSource({ id: fundingSourceId });
    const { createPayment, isLoading: isLoadingCreatePayment } = useGuestPayorPayment();

    const handleOnSubmit = async () => {
      try {
        await createPayment({ fundingSourceId, paymentRequestLink });
        onCreatePayment();
      } catch (error) {
        onError(error as ARPlatformError);
      }
    };

    const isSaving = isLoadingFundingSource || isLoadingPaymentRequestLink || isLoadingCreatePayment;

    useEffect(() => {
      onFormLoading(isSaving);
    }, [isSaving]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isError) {
      goToBankRoute();
    }

    return (
      <PayByBankAccountScreen
        onSubmit={handleOnSubmit}
        fundingSourceName={fundingSource?.displayName}
        logo={fundingSource?.logo}
        isSaving={isSaving}
        amount={paymentRequestDetails?.invoice.totalAmount || 0}
        ref={ref}
      />
    );
  }
);
PayByBankActivity.displayName = 'PayByBankActivity';
