import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Icon, Text } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { DeliveryMethodIconsEnum } from '@/types/vendors.types';
import { DeliveryMethodCardTheme } from '@/widgets/vendorsDashboard/DeliveryMethod/components/DeliveryMethodCard.component.theme';
import { useManagedAchMessages } from '@/widgets/vendorsDashboard/DeliveryMethod/hooks/useManagedAchMessages';

export const ManagedAchDeliveryMethodCard = forwardRef((props, ref) => {
  const styles = useAppTheme(DeliveryMethodCardTheme, {});
  const { displayName, helperText, additionalInfo } = useManagedAchMessages();

  return (
    <MethodCard
      type={DeliveryMethodType.BankAccount}
      icon={<Icon type={DeliveryMethodIconsEnum.BANK} />}
      displayName={displayName}
      helperText={helperText}
      data-component="ManagedAchDeliveryMethodCard"
      ref={ref}
      {...props}
    >
      <Stack flexDirection="column" sx={styles['container']}>
        <Text textStyle="body4" color="neutral.darker">
          {additionalInfo}
        </Text>
      </Stack>
    </MethodCard>
  );
});

ManagedAchDeliveryMethodCard.displayName = 'ManagedAchDeliveryMethodCard';
