import { Table } from '@melio/penny';
import { Vendor } from '@melio/platform-api';

import { VendorCurrencyTooltip } from './VendorCurrencyTooltip';

type VendorsTabAmountInputCellProps = {
  value?: string;
  vendor: Vendor;
  onAmountChange: (vendor: Vendor, value: string) => void;
};

export const VendorsTabAmountInputCell = ({ value, vendor, onAmountChange }: VendorsTabAmountInputCellProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue !== value) {
      onAmountChange(vendor, newValue);
    }
  };

  return (
    <Table.Cell data-testid={`vendors-tab-amount-input-cell-${vendor.id}`}>
      <VendorCurrencyTooltip vendor={vendor}>
        <Table.AmountInput
          currency={vendor.currency ?? undefined}
          aria-label="Total amount"
          data-testid={`vendors-tab-amount-input-${vendor.id}`}
          testKey={vendor.id}
          value={value}
          onChange={handleChange}
          allowNegativeValue={false}
        />
      </VendorCurrencyTooltip>
    </Table.Cell>
  );
};
