/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { object, SchemaOf, string } from 'yup';
import { Form, FormControl, Text, Typography, useMelioForm, useTheme } from '@melio/penny';
import { InboxEmail } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';

type ScannedInvoiceEmailDetailsFormFields = {
  vendorEmail: string;
  date: string;
  subject: string;
  content: string;
};

type ScannedInvoiceEmailDetailsProps = {
  inboxEmail: InboxEmail;
};

const useSchema = () => {
  return object().shape({
    vendorEmail: string(),
    date: string(),
    subject: string(),
    content: string(),
  }) as SchemaOf<ScannedInvoiceEmailDetailsFormFields>;
};

export const ScannedInvoiceEmailDetails = ({ inboxEmail }: ScannedInvoiceEmailDetailsProps) => {
  const { formatMessage } = usePlatformIntl();
  const theme = useTheme();

  const defaultValues = useMemo(
    () => ({
      vendorEmail: inboxEmail.sender,
      date: new Date(inboxEmail.history.createdAt).toLocaleString(),
      subject: inboxEmail.subject,
      content: inboxEmail.content,
    }),
    [inboxEmail],
  );

  const { formProps, registerField, reset } = useMelioForm<ScannedInvoiceEmailDetailsFormFields>({
    onSubmit: () => {},
    schema: useSchema(),
    defaultValues,
    onSubmissionStateChange: () => {},
  });

  useEffect(() => reset(defaultValues), [defaultValues]);

  return (
    <Box border={`1px solid ${theme.colors.neutral.light}`} borderRadius={'8px'} overflow="hidden">
      <Stack flexDirection="column" p="32px 40px" gridGap="24px" spacing={0}>
        <HStack alignItems={'center'} width={'full'} gridGap={'4px'}>
          <Text textStyle={'heading2Semi'}>{formatMessage('widgets.ScannedInvoiceEmailDetails.title')}</Text>
        </HStack>
        <HStack gridGap={'40px'}>
          <Form size="small" isViewMode {...formProps}>
            <Form.TextField
              {...registerField('vendorEmail')}
              labelProps={{ label: formatMessage('widgets.ScannedInvoiceEmailDetails.form.vendorEmail.label') }}
            />
            <Form.TextField
              {...registerField('date')}
              labelProps={{ label: formatMessage('widgets.ScannedInvoiceEmailDetails.form.date.label') }}
            />
            <Form.TextField
              {...registerField('subject')}
              labelProps={{ label: formatMessage('widgets.ScannedInvoiceEmailDetails.form.subject.label') }}
              viewModePlaceholder={formatMessage('widgets.ScannedInvoiceEmailDetails.form.subject.viewModePlaceholder')}
            />
            <FormControl>
              <Typography.Label label={formatMessage('widgets.ScannedInvoiceEmailDetails.form.content.label')} />
              {inboxEmail?.content ? (
                <Text>{inboxEmail.content}</Text>
              ) : (
                <Form.TextField
                  {...registerField('content')}
                  size={'small'}
                  isViewMode
                  placeholder={formatMessage('widgets.ScannedInvoiceEmailDetails.form.content.viewModePlaceholder')}
                  aria-label="content"
                />
              )}
            </FormControl>
          </Form>
        </HStack>
      </Stack>
    </Box>
  );
};
