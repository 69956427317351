import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useAnalytics } from '@melio/platform-analytics';
import { useCollaborator, useFundingSources, useIsAccountingFirm } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { useIsOrganizationInMsnOnly } from '@/hooks/useIsOrganizationInMsnOnly.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { ReceiptsTableWidget } from '@/widgets/settings-page/BillingSettingsPage/ReceiptsTable.widget';
import { SectionHeaderWithButton } from '@/widgets/settings-page/Common/SectionHeaderWithButton.widget';
import { isAdmin } from '../../../utils/user.utils';
import { BillingMethodWidget } from './BillingMethod/BillingMethod.widget';

export const BillingSettings = () => {
  const { formatMessage } = usePlatformIntl();
  const { data: actor } = useCollaborator({ id: 'me' });
  const { data: fundingSources = [], isFetching: isFundingSourcesLoading = false } = useFundingSources({
    params: { include: 'firmBillingFeeMethod' },
  });
  const isPureMSNOrganization = useIsOrganizationInMsnOnly();
  const isAccountingFirm = useIsAccountingFirm();
  const isUserOwnerOrAdmin = actor?.roleUniqueName ? isAdmin(actor.roleUniqueName) : false;
  const [isBillingFeesEnabled] = useFeature(FeatureFlags.BillingFees, false);
  const { track } = useAnalytics();
  const shouldShowBillingMethodSection =
    !isEmpty(fundingSources) &&
    isBillingFeesEnabled &&
    isUserOwnerOrAdmin &&
    !isPureMSNOrganization &&
    !isAccountingFirm;

  useEffect(() => {
    track('Settings', 'View', { PageName: SettingsCardIdEnum.BillingSetting });
  }, [track]);

  return (
    <WithLoading isLoading={isFundingSourcesLoading} isAbsoluteCenter>
      {shouldShowBillingMethodSection ? (
        <SettingsSectionContainer data-component="BillingMethodSettings" data-testid="billing-method-section">
          <Box mb="s">
            <SectionHeaderWithButton
              title={formatMessage('widgets.billingSettings.paymentMethod.header.title')}
              titleAs="h3"
            />
          </Box>
          <BillingMethodWidget />
        </SettingsSectionContainer>
      ) : null}
      <SettingsSectionContainer data-component="BillingSettings">
        <Box mb="m">
          <SectionHeaderWithButton title={formatMessage('widgets.billingSettings.header.title')} titleAs="h3" />
        </Box>
        <ReceiptsTableWidget />
      </SettingsSectionContainer>
    </WithLoading>
  );
};

BillingSettings.displayName = 'BillingSettings';
