import { Box } from '@chakra-ui/react';
import { useRecurringPaymentFeesDetails, UseRecurringPaymentFeesDetailsProps } from '@melio/ap-domain';
import { Container, Group, Loader, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { FeeItem } from './components/FeeItem';
import { TotalFees } from './components/TotalFees';

export type FeeSectionProps = UseRecurringPaymentFeesDetailsProps & { onError?: ErrorFunction };

export const FeeSection = ({ onError, ...recurringPaymentData }: FeeSectionProps) => {
  const { isLoading, error, feeItems, total } = useRecurringPaymentFeesDetails(recurringPaymentData);

  const { formatMessage } = useMelioIntl();

  const title = formatMessage('activities.editBillSubscription.screens.editBillSubscription.feesSection.title');

  useEffect(() => {
    if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (error) {
    return null;
  }

  return (
    <Box>
      <Group variant="vertical" spacing="xs" data-testid="fee-section">
        <Container paddingBottom="xs">
          <Text textStyle="body4Semi" color="neutral.darker">
            {title}
          </Text>
        </Container>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Group variant="vertical" spacing="xs">
              {feeItems?.map((fee) => (
                <FeeItem key={fee.type} {...fee} />
              ))}
            </Group>
            <TotalFees {...total} />
          </>
        )}
      </Group>
    </Box>
  );
};
