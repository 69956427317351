import { InvoiceSortFieldsEnum as InvoiceSortFieldsenumExtended, InvoiceSortQueryParam } from '@melio/ar-domain';

export type Filter = 'all' | 'draft' | 'paid' | 'overdue' | 'open' | 'canceled';

type GetQueryParam<T extends keyof InvoiceSortQueryParam> = NonNullable<InvoiceSortQueryParam[T]>;
type InvoiceSortFieldsEnum = Exclude<InvoiceSortFieldsenumExtended, 'invoiceNumber'>;

type Params = {
  field: InvoiceSortFieldsEnum;
  order: GetQueryParam<'order'>;
};

export const InvoiceSortFieldsEnum: Record<InvoiceSortFieldsEnum, InvoiceSortFieldsEnum> = {
  createdAt: 'createdAt',
  dueDate: 'dueDate',
  totalAmount: 'totalAmount',
} as const;

type GetParam<T extends keyof Params> = NonNullable<Params[T]>;

export type SortFields = GetParam<'field'>;
export type Order = GetParam<'order'>;

export const Sort: Record<SortFields, string> = {
  createdAt: 'Date created',
  dueDate: 'Due date',
  totalAmount: 'Amount',
} as const;

export type SortParams = Params;
export type OnSort = (sort: SortFields, order: Order) => void;

export type UseSortableInvoicesParams = {
  invoiceStatus?: Filter;
  searchTerm?: string;
  sort?: SortFields;
  order?: Order;
  pageNumber?: number;
  customerId?: string;
};

export type SetSortableInvoicesParams = (params: Partial<UseSortableInvoicesParams>) => void;

export type InvoiceDashboardQueryParams = {
  searchTerm?: string;
  customerId?: string;
  invoiceStatus?: Filter;
};
