import { Link, StatusModal } from '@melio/penny';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

type ExceedingFailureModalScreenProps = {
  isOpen: boolean;
  onDone: VoidFunction;
};

export const ExceedingFailureModalScreen: React.VFC<ExceedingFailureModalScreenProps> = ({ onDone, isOpen }) => {
  const { formatMessage } = useMelioIntl();

  const { supportEmail } = useConfig().settings;
  const mailToSupportEmail = `mailto:${supportEmail}`;

  return (
    <StatusModal
      data-testid="micro-deposits-verification-modal-activity-exceeding-failure-modal-screen"
      variant="cancel"
      header={formatMessage('activities.microDepositsVerification.screens.exceedingFailure.title')}
      isOpen={isOpen}
      onClose={onDone}
      description={formatMessage('activities.microDepositsVerification.screens.exceedingFailure.content', {
        link: <Link href={mailToSupportEmail} label={supportEmail} newTab />,
      })}
    />
  );
};

ExceedingFailureModalScreen.displayName = 'MicroDepositsVerificationModalActivity.ExceedingFailureModalScreen';
