import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useMemo } from 'react';

export const useVendorDirectoryInfoComplete = (vendor?: Vendor) => {
  const {
    settings: {
      vendor: { requiredDirectoryFields },
    },
  } = useConfig();

  const [isCollectMissingVendorInfoEnabled] = useFeature<boolean>(FeatureFlags.CollectMissingVendorInfoEnabled, false);

  return useMemo(() => {
    if (!isCollectMissingVendorInfoEnabled || !requiredDirectoryFields.length || !vendor) {
      return true;
    }

    return requiredDirectoryFields.every((requiredFieldName) => !!vendor[requiredFieldName as keyof Vendor]);
  }, [isCollectMissingVendorInfoEnabled, requiredDirectoryFields, vendor]);
};
